<template>
  <div class="">
    <h2>
      评审商标<span v-if="domesticList.length != 0">{{ pageSize }} </span>
    </h2>
    <!-- 表单 -->
    <form ref="filters" action="" v-on:submit.prevent>
      <el-row :class="{on:formOpen}" class="input formInput">
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>争议商标</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-input
                  v-model="filters.tmname"
                  clearable
                  placeholder="请输入争议商标"
                  size="small"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>争议商标号</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-input

                  v-model="filters.tmcode"
                  clearable
                  placeholder="请输入争议商标号"
                  size="small"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>争议类别</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-select
                  v-model="filters.intcls"
                  clearable
                  collapse-tags
                  filterable
                  multiple
                  placeholder="请选择争议类别"
                  size="small"
              >
                <el-option
                    v-for="item in intclsSelect.select"
                    :key="item.id"
                    :label="item.text"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>申请日期</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-date-picker
                  v-model="filters.deliverdate"

                  :picker-options="pickerOptions"
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>裁定日期</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-date-picker

                  v-model="filters.judgedate"
                  :picker-options="pickerOptions"
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>裁定结果</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-select
                  v-model="filters.result"

                  clearable
                  filterable
                  placeholder="请选择裁定结果"
                  size="small"
              >
                <el-option
                    v-for="item in resultSelect"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>申请人</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-select
                  v-model="filters.appname"

                  clearable
                  filterable
                  placeholder="请选择申请人"
                  size="small"
              >
                <el-option
                    v-for="item in appnameSelect"
                    :key="item.text"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>被申请人</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-select
                  v-model="filters.resname"

                  clearable
                  filterable
                  placeholder="请选择被申请人"
                  size="small"
              >
                <el-option
                    v-for="item in resnameSelect"
                    :key="item.text"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>

        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>代理机构</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-select
                  v-model="filters.agentname"

                  clearable
                  filterable
                  placeholder="请选择代理机构"
                  size="small"
              >
                <el-option
                    v-for="item in agentnameSelect"
                    :key="item.text"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="formOpen">
        <el-col :class="{on:formOpen}" :xs="24">
          <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
        </el-col>
      </el-row>
      <el-button class="search" icon="el-icon-search" size="small" type="primary"
                 @click="perindexList(filters.page = 1)"
      >查询
      </el-button>
      <el-button :class="{on:formOpen}" class="reset" icon="el-icon-refresh-left" size="small" type="reset"
                 @click="reset"
      >重置
      </el-button
      >
    </form>
    <!-- 列表 -->
    <div class="mainrb">
      <div class="listT clearfix">
        <el-button  :loading="down" icon="el-icon-document" :disabled="domesticList.length == 0 || loading" size="small" type="primary" @click="downloadDia = true">
          {{ down ? '导出中' : '导出数据' }}
        </el-button>
        <el-radio-group class="m-l10" v-model="filters.type" @change="perindexList" size="mini">
          <el-radio label="评审商标">全部</el-radio>
          <el-radio label="驳回复审">驳回复审</el-radio>
          <el-radio label="无效宣告">无效宣告</el-radio>
          <el-radio label="无效宣告复审">无效宣告复审</el-radio>
          <el-radio label="撤销复审">撤销复审</el-radio>
          <el-radio label="不予注册复审">不予注册复审</el-radio>
        </el-radio-group>

        <div class="listTr fr">
          <strong :class="{ on: liston == 0 }" @click="liston = 0"
          ><img
              v-if="liston == 0"
              alt=""
              src="@/assets/img/search/icon-list2-1.png"/>
            <img v-else alt="" src="@/assets/img/search/icon-list2.png"
            /></strong>
          <strong :class="{ on: liston == 1 }" @click="liston = 1">
            <img
                v-if="liston == 1"
                alt=""
                src="@/assets/img/search/icon-list1.png"
            />
            <img v-else alt="" src="@/assets/img/search/icon-list1-1.png"/>
          </strong>


        </div>
      </div>
      <!--       表格列表-->
      <el-table
          ref="table"
          v-show="liston == 1"
          :class="{on:!formOpen,ios:$store.state.heights}"
          :data="domesticList"
          height="90%"
          @sort-change="sort_change"

      >
        <template slot="empty">
          <el-empty :image-size="80"></el-empty>
        </template>
        <el-table-column label="序号" width="50">
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item class="w50" variant="p"/>
              </template>
              <span>{{ scope.$index + 1 }} </span>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column label="争议商标" min-width="120" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>

              <template >
                <div class="ellipsis">
                  {{ scope.row.tmname || '图形'}}
                </div>
              </template>
            </el-skeleton>
          </template>

        </el-table-column>
        <el-table-column label="争议商标号" width="90">
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>
              <template>
                {{ scope.row.tmcode }}
              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column label="争议类别" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>
              <template>
                <div class="ellipsis">
                  {{ scope.row.clstext }}
                </div>

              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column label="评审类型" :show-overflow-tooltip="true" min-width="80">
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>
              <template>
                <div class="ellipsis">
                  {{ scope.row.type || '-' }}
                </div>

              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column
            label="申请人" min-width="120"
            :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated class="ellipsis">
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>
              <template>
                <span :class="{'col-blue':scope.row.appname}" >
                  {{ scope.row.appname || '-' }}
                </span>

              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column label="裁定结果" min-width="100">
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>
              <template>
                <el-tag
                    :class="{tag1:scope.row.result == '驳回' || scope.row.result == '不予注册' ,tag3:scope.row.result == '部分驳回' || scope.row.result == '部分不予注册' ,tag7:scope.row.result == '准予注册' || scope.row.result == '维持注册',tag2:scope.row.result == '初步审定',tag5:scope.row.result == '部分撤销' || scope.row.result == '部分无效',tag4:scope.row.result == '无效宣告'  }"
                    type=""
                    size="mini"
                >
                  {{ scope.row.result }}
                </el-tag>
              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column
            label="裁定日期"
            width="120"
            sortable="custom"
        >
          <template slot-scope="scope" class="sort-jub">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>
              <template >
                {{ scope.row.judgedate || '-' }}
              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column
            label="申请日期"
            width="120"
            sortable="custom"
        >
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>
              <template>
                {{ scope.row.deliverdate || '-' }}
              </template>
            </el-skeleton>
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="170">
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item class="w50" variant="p"/>
              </template>
              <template>
                <router-link
                    :to="{ name: 'reviewDetails', params: { tmcode: scope.row.tmcode, intcls: scope.row.intcls } }"
                    target="_blank">
                  <el-button icon="el-icon-edit-outline" size="small" type="text">详情</el-button>
                </router-link>
                <el-button icon="el-icon-upload2" class="upload2" size="small" type="text" @click="upload(scope.row.tmcode)">
                  上传
                </el-button>
              </template>
            </el-skeleton>
          </template>
        </el-table-column>
      </el-table>
      <!-- 块状列表 -->
      <div v-show="liston == 0" :class="{empty:domesticList.length == 0,on:!formOpen,ios:$store.state.heights}" class="list-list">
        <ul class="list_t flex flex-cen">

          <li >
            <span class="pointer" @click="sort == '' ? (filters.sort = 'deliverdate|asc',sort = 'deliverdateAsc') : (sort == 'deliverdateAsc' ? (filters.sort = 'deliverdate|desc',sort = 'deliverdateDes') : ((sort.indexOf('deliverdate') == -1) ?  (filters.sort = 'deliverdate|asc',sort = 'deliverdateAsc') : (filters.sort = '',sort = '')) ),perindexList() ">申请日期</span>
            <span class="caret-wrapper">
              <i class="sort-caret ascending" @click.stop="sort == 'deliverdateAsc' ? (sort = '',filters.sort = '') : (sort = 'deliverdateAsc',filters.sort = 'deliverdate|asc'),perindexList() " :class="{on:sort == 'deliverdateAsc'}"></i>
              <i class="sort-caret descending" @click.stop="sort == 'deliverdateDes' ? (sort = '',filters.sort = '') : (sort = 'deliverdateDes',filters.sort = 'deliverdate|desc'),perindexList() " :class="{on:sort == 'deliverdateDes'}"></i>
            </span>
          </li>
          <li>
            <span class="pointer" @click="sort == '' ? (filters.sort = 'judgedate|asc',sort = 'judgedateAsc') : (sort == 'judgedateAsc' ? (filters.sort = 'judgedate|desc',sort = 'judgedateDes') : ((sort.indexOf('judgedate') == -1) ?  (filters.sort = 'judgedate|asc',sort = 'judgedateAsc') : (filters.sort = '',sort = ''))),perindexList() ">裁定日期</span>
            <span class="caret-wrapper">
              <i class="sort-caret ascending" @click.stop="sort == 'judgedateAsc' ? (sort = '',filters.sort = '') : (sort = 'judgedateAsc',filters.sort = 'judgedate|asc'),perindexList()  " :class="{on:sort == 'judgedateAsc'}"></i>
              <i class="sort-caret descending" @click.stop="sort == 'judgedateDes' ? (sort = '',filters.sort = '') : (sort = 'judgedateDes',filters.sort = 'judgedate|desc') ,perindexList() " :class="{on:sort == 'judgedateDes'}"></i>
            </span>
          </li>

        </ul>
        <el-empty v-if="domesticList.length == 0" :image-size="80"></el-empty>
        <div v-else class="list-cont">
          <el-skeleton :class="{on:!formOpen,ios:$store.state.heights}" :cont="1" :loading="loading" animated>
            <template slot="template">
              <div v-for="(item, index) in loadList" :key="index" class="listb">
                <div class="content">
                  <div class="">
                    <el-skeleton-item
                        style="width: 110px;height:110px"
                        variant="image"
                    />
                  </div>
                  <div class="contentC">
                    <p class="m-b20">
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                    </p>
                    <p class="m-b20">
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>

                    </p>
                    <p class="m-b20">
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                    </p>
                    <p>
                      <el-skeleton-item style="margin-bottom: 0" variant="text"/>
                    </p>
                  </div>
                  <div class="details">
                    <el-skeleton-item
                        style="width: 100px"
                        variant="p"
                    />
                  </div>
                </div>
              </div>
            </template>
            <div v-for="(item, index) in domesticList" :key="index" class="listb">
              <div class="content">
                <div class="content-index">
                  {{index + 1}}
                </div>
                <div class="content-img ">
                  <div class="img-img  flex align-cen ju-con-cen">
                    <el-image
                        :src="item.tmimageorg"
                        :preview-src-list="[item.tmimageorg]">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                  <div class="img-tmlaw "
                       :class="{'tag-primary':item.result == '撤销',tag1:item.result == '驳回' || item.result == '不予注册' ,tag3:item.result == '部分驳回' || item.result == '部分不予注册' ,tag7:item.result == '准予注册' || item.result == '维持注册',tag2:item.result == '初步审定',tag5:item.result == '部分撤销' || item.result == '部分无效',tag4:item.result == '无效宣告'  }"                  >
                    {{item.result}}
                  </div>
                </div>
                <div class="contentC">
                  <p>

                    <span class="flex align-cen" ><strong class="font-16 font-wbold">{{ item.type || '-'}}：</strong><font class="col-blue ellipsis">{{ item.tmname || '图形'}}</font> </span>
                    <span><font >争议商标号：{{ item.tmcode || '-'}}</font> <i class="el-icon-copy-document col-blue pointer m-l5" v-copy="item.tmcode"></i></span>
                    <span>争议类别：{{ item.clstext || '-'}} </span>

                  </p>
                  <p>
                    <span>申请日期：{{ item.deliverdate || '-' }}</span>
                    <span>裁定日期：{{ item.judgedate || '-' }}</span>
                    <span>商标注册日期：{{ item.enddate || '-' }}</span>


                  </p>
                  <p>

                    <span class=" flex align-cen" >
                      申请人：
                       <el-tooltip  class="item" effect="dark" v-showtip :content="item.appname" placement="top-start">
                         <span  class="w80 flex align-cen">

                           <font :class="{'col-blue':item.appname }" class="ellipse-text"> {{ item.appname || '-'}} </font>
                         </span>
                       </el-tooltip>

                    </span>

                    <span class="flex align-cen">申请人代理机构：
                      <el-tooltip class="item" effect="dark" v-showtip :content="item.agentname" placement="top-start">
                        <font class=" ellipsis w80">
                          <font class="ellipse-text " :class="{'col-blue':item.agentname}">
                            {{ item.agentname || '-' }}
                          </font>
                        </font>
                      </el-tooltip>

                    </span>
                    <span>被申请人：
                      <el-tooltip class="item" effect="dark" v-showtip :content="item.resname" placement="top-start">
                        <font class=" ellipsis w80">
                          <font class="ellipse-text " :class="{'col-blue':item.resname}">
                            {{ item.resname || '-' }}
                          </font>
                        </font>
                      </el-tooltip></span>
                  </p>
                  <p>
                    <span class=" flex align-cen">评审文书：
                      <el-tooltip  class="item" effect="dark" v-showtip :content="item.review" placement="top-start">
                         <span  class="w80 flex align-cen">
                           <font  class="ellipse-text ellipsis pointer" @click="insDel(item)" :class="{'col-blue':item.review }"> {{ item.review || '-'}} </font>
                         </span>
                       </el-tooltip>
                    </span>
                    <span class="flex align-cen">案件号：
                      <el-tooltip  class="item" effect="dark" v-showtip :content="item.caseno" placement="top-start">
                         <span  class="w80 flex align-cen">
                           <font  class="ellipse-text ellipsis"> {{ item.caseno || '-'}} </font>
                         </span>
                       </el-tooltip>
                    </span>
                  </p>
                  <p class="disblk ellipsis">
                    引用商标法：
                    <el-tooltip  v-for="(law,lawInx) in replace.law[index]" popper-class="max-wTip" effect="dark"  placement="bottom-start">
                      <span slot="content" v-html="law.content"></span>
                      <span class="col-blue pointer">{{ law.clause }} <font v-if="lawInx != replace.law[index].length - 1" class="col-606">、</font></span>
                    </el-tooltip>
                    <span v-if="replace.law[index] == false">-</span>

                  </p>
                </div>
                <div class="details">
                  <router-link :to="{name: 'reviewDetails',params: { tmcode: item.tmcode, intcls: item.intcls },}"target="_blank">
                    <el-button icon="el-icon-edit-outline" size="small" type="primary">详情</el-button>
                  </router-link>
                  <el-button icon="el-icon-upload2" plain size="small" type="primary" @click="upload(item.tmcode)"
                  >上传
                  </el-button
                  >

                </div>
              </div>
            </div>
          </el-skeleton>
        </div>
      </div>

      <!-- 分页 -->
      <el-pagination
          :current-page.sync="filters.page"
          :page-size="filters.per_page"
          :page-sizes="[30, 50, 100]"
          :total="pageSize"
          layout="total, sizes, prev, pager, next, jumper,slot"
          @size-change="changeRowNum"
          @current-change="perindexList(backTop)"
      >
      </el-pagination>
    </div>

    <!-- 上传 -->
    <el-dialog
        :closeOnClickModal="false"
        :visible.sync="fileSH"
        class="file"
        title="上传文件"
        width="335px"
        @close="empty"
        @closed="empty"
    >
      <!--      <div class="cont-tab">-->
      <!--        <p @click="tabtNum = 1">-->
      <!--          <span :class="{ checked: tabtNum == 1 }" class="el-checkbox__input">-->
      <!--            <span class="el-checkbox__inner"> </span>-->
      <!--            <input-->
      <!--                aria-hidden="false"-->
      <!--                class="el-checkbox__original"-->
      <!--                type="checkbox"-->
      <!--                value="手动添加"-->
      <!--            />-->
      <!--          </span>-->

      <!--          <font>手动添加</font>-->
      <!--        </p>-->
      <!--        <p @click="tabtNum = 2">-->
      <!--          <span :class="{ checked: tabtNum == 2 }" class="el-checkbox__input">-->
      <!--            <span class="el-checkbox__inner"> </span>-->
      <!--            <input-->
      <!--                aria-hidden="false"-->
      <!--                class="el-checkbox__original"-->
      <!--                type="checkbox"-->
      <!--                value="手动添加"-->
      <!--            />-->
      <!--          </span>-->
      <!--          <font>智能识别</font>-->
      <!--        </p>-->
      <!--      </div>-->

      <div class="cont-tabc">
        <el-form ref="ruleForm" :model="manual" :rules="manualRules" label-position="right" label-width="80px"
                 size="mini">
          <el-form-item label="官文类型" prop="matter">
            <el-select
                v-model="manual.matter"
                clearable
                filterable
                placeholder="请选择官文类型"
                size="small"
            >
              <el-option
                  v-for="item in typeSelect"
                  :key="item.text"
                  :label="item.text"
                  :value="item.text"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商标号">
            <el-input
                v-model="fileTmcode.tmcode"
                clearable
                placeholder="请输入商标号"
                readonly
                size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="收文日期" prop="made_at">
            <el-date-picker
                v-model="manual.made_at"
                placeholder="请选择收文日期"
                size="small"
                type="date"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <div class="tabc-update">

            <el-upload
                ref="upload"
                :before-upload="beforeUpload"
                :file-list="fileList"
                :http-request="uploadImg"
                :limit="1"
                :on-exceed="handleExceed"
                action="/api/upload"
                class="upload-demo"
            >
              <el-form-item prop="name">
                <el-input v-model="manual.name" placeholder="请点击按钮上传文件" readonly size="small"></el-input>
              </el-form-item>
              <el-button
                  v-if="uploadBtn"
                  slot="trigger"
                  :disabled="distinguishDis"
                  circle
                  icon="el-icon-plus"
                  size="mini"
                  type="primary"
              ></el-button>
              <el-button v-else slot="trigger" :loading="true" circle size="mini" type="primary"></el-button>
            </el-upload>

          </div>
          <div class="batch">
            官文太多？马上
            <router-link to="/personal/trademarkAdmin/upload">批量上传</router-link>
          </div>
          <div class="submitBtn">
            <el-button class="cancel" icon="el-icon-close" size="small" @click="fileSH = false">取 消</el-button>
            <el-button :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' : 'el-icon-folder-checked'"
                       class="confirm" size="small" type="primary"
                       @click="submit('ruleForm')">确 定
            </el-button>
          </div>

        </el-form>
      </div>

    </el-dialog>

    <el-dialog
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        :visible.sync="downloadDia"
        title="导出信息"
        class="openDia"
        width="576px"
        @open="openDia">
      <template>
        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="filters.fields" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="city in cities" :key="city" :label="city">{{ city }}</el-checkbox>
        </el-checkbox-group>
      </template>
      <span slot="footer" class="dialog-footer">
    <el-button icon="el-icon-close" size="small" @click="downloadDia = false">取 消</el-button>
    <el-button :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' : 'el-icon-folder-checked'" class="confirm"
               size="small" type="primary" @click="download">确 定</el-button>
  </span>
    </el-dialog>


    <el-dialog

        :closeOnClickModal="false"
        title="文书详情"
        :visible.sync="insDelVisible"
        class="ins_del_dia"
    >
      <div class="dia_body line-h22">
        <div class="body_title">
          <h3 class="font-16 font-wbold col-303 text-cen">{{ insDetail.title || '-' }}</h3>
          <p class="font-12 col-909 text-cen">{{insDetail.caseno || '-'}}</p>
        </div>
        <div class="body_flows ba-col-EFE p10 col-red m-tb10">
          <p>{{ insDetail.judgedate || '-' }}<span class="m-l5">裁定结果：</span></p>
          <p>{{ replace.judgement.result || '-' }}</p>
        </div>
        <div class="body_cont">
          <h4 class="col-303 font-wbold"><font></font> 主体信息</h4>
          <p v-html="(insDetail.litigant || '-')"></p>
          <h4 class="col-303 font-wbold"><font></font>审理过程</h4>
          <p v-html="(insDetail.process || '-')"></p>
          <h4 class="col-303 font-wbold"><font></font>裁判理由</h4>
          <p v-html="(insDetail.cause || '-')"></p>
          <h4 class="col-303 font-wbold"><font></font>裁定结果</h4>
          <p v-html="(replace.judgement.law || '-')"></p>
          <p v-html="(replace.judgement.result || '-')"></p>
          <h4 class="col-303 font-wbold"><font></font>合议组员</h4>
          <p v-html="(insDetail.officer || '-')"></p>
          <h4 class="col-303 font-wbold"><font></font>{{replace.review.indexOf('裁定') == -1 ? '决定' : '裁定'}}日期</h4>
          <p v-html="(insDetail.judgedate || '-')"></p>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button icon="el-icon-close" size="small"  @click="insDelVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import { exportData, fileDistinguish, fileSubmit, fileUpload,tmReview,tmReviewExport,judgeInfo} from '@/api/personal.js'
const cityOptions = ['商标图样', '争议商标','争议商标号', '争议类别', '引证商标', '申请人', '申请人代理机构', '被申请人', '被申请人代理机构', '裁定结果', '引用商标法', '申请日期', '裁定日期', '评审文书','案件号'];
export default {
  name: "Review",
  data() {
    return {
      insDetail:[],//文书详情数据
      insDelVisible:false,//控制文书详情对话框显示与隐藏
      replace:{//替代字段集合
        law:[],
        lawCont:[],
        judgement:{},
        review:'',
      },

      sort:'',//记录排序
      formOpen: false,//控制搜索栏展开或收起
      law:[],

      downloadDia: false,//控制导出对话框的显示与隐藏
      checkAll: false,//全选
      cities: cityOptions,//导出的全部选项
      isIndeterminate: true,//控制全选状态

      backTop: true,
      down: false,//控制导出加载的显示与隐藏
      loading: true,//控制骨架屏的显示与隐藏
      pickerOptions: {//时间选择器的便利选择对象
        shortcuts: storage.getShortcuts()
      },
      domesticList: [],//官文文件数组
      loadList: storage.getList(),//骨架屏的起始数组（10条数据）
      //官文文件查询表单字段
      filters: {
        tmname: "", //争议商标
        tmcode: "", //争议商标号
        intcls: "", //争议类别
        deliverdate:'',//申请日期
        judgedate:"",//裁定日期
        result:"",//裁定结果
        appname: "", //申请人
        resname:"",//被申请人
        agentname:'',//代理机构
        type:'评审商标',//评审类型
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
        sort: "",//排序

        fields: ['争议商标', '争议商标号', '争议类别', '引证商标', '申请人', '申请人代理机构', '被申请人', '裁定结果', '引用商标法', '裁定日期'],//导出的默认选择

      },
      //商标状态，下拉框的所需内容
      tmlawSelect: {
        select: [
          {id: "01", value: "待审中"},
          {id: "02", value: "申请中"},
          {id: "03", value: "已初审"},
          {id: "04", value: "已注册"},
          {id: "05", value: "已无效"},
          {id: "06", value: "已驳回"},
        ],
      },
      //商标类别，下拉框的所需内容
      intclsSelect: {
        select: storage.getIntcls(),
      },
      appnameSelect: [],//申请人，下拉框的所需内容
      agentnameSelect: [],//申请人代理机构，下拉框的所需内容
      resnameSelect:[],//被申请人，下拉框的所需内容
      resultSelect:[
        '驳回',
        '部分驳回',
        '准予注册',
        '不予注册',
        '部分不予注册',
        '初步审定',
        '撤销',
        '部分撤销',
        '维持注册',
        '部分无效',
        '无效宣告',
      ],//裁定结果，下拉框的所需内容
      last_page: 1,
      pageSize: 0, //总条数
      liston: 0,//控制是那种列表的tab切换(默认是table列表)

      fileTmcode: {
        tmcode: ''
      },
      uploadBtn: true,//控制上传文件加载中按钮的显示与隐藏
      fileList: [],//记录上传文件成功的数组
      loadingUp: true,//控制上传文件中识别文件的骨架屏
      fileSH: false,//控制上传官文弹窗的显示与隐藏
      tabtNum: 1,//控制手动和自动上传的tab切换
      file: {
        name: "",//上传官文的名称
        path: '',//上传官文的路径
      },
      distinguishSh: true,//控制识别按钮显示与隐藏
      distinguishDis: false,
      submitDis: false,
      contSH: false,//控制识别中的骨架屏
      typeSelect: [],//官文类型的下拉数据
      automatic: {//自动上传的字段
        matter: "",
        made_at: "",
        name: "",//上传官文的名称
      },
      automaticRules: {
        matter: [
          {required: true, message: '请选择官文类型', trigger: 'change'},
        ],
        made_at: [
          {required: true, message: '请选择收文日期', trigger: 'change'},
        ],
        name: [
          {required: true, message: '请点击按钮上传文件', trigger: 'change'},
        ],
      },
      manual: {//手动上传的字段
        matter: "", //官文名称
        made_at: "",
        name: ''
      },
      manualRules: {
        matter: [
          {required: true, message: '请选择官文类型', trigger: 'change'},
        ],
        made_at: [
          {required: true, message: '请选择收文日期', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请点击按钮上传文件', trigger: 'change'},
        ],
      },
    };
  },

  created() {

    this.perindexList();
    this.select();
  },

  methods: {
    //文书详情
    async insDel(item){

      try {
        const res = await judgeInfo({intcls:item.intcls,tmcode:item.tmcode,type:item.type})
        if(res && res.err_code === 0){
          this.insDetail = {...res.data}
          this.replace.judgement = {...res.data.judgement}
          this.replace.review = item.review
          this.insDelVisible = true
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      }finally {

      }
    },
    //全选改变
    handleCheckAllChange(val) {
      this.filters.fields = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    //单选改变
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },

    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.perindexList(this.backTop);
    },
    //渲染列表事件
     async perindexList(backTop) {
       this.replace.law = []
      this.loading = true;
      this.domesticList = storage.getList()
      try {
        const res = await tmReview(this.filters)
        if (res && res.err_code === 0) {
          if (backTop === true) {
            storage.backTop()
          }
          this.loading = false
          this.pageSize = res.data.total
          this.domesticList = res.data.list
          res.data.list.forEach(item =>{
            let lawContent ='';
            if(item.law){
              if( item.law.length == 0){
              item.law = false
            }else{
              item.law.forEach(itemLaw =>{
                let pages = new RegExp("\n\n","g");
                itemLaw.content = itemLaw.content.replace(pages,"</br>")
                lawContent += itemLaw.clause
              })
            }
            }
            
            this.replace.law.push(item.law)
            this.replace.lawCont.push(lawContent)
          })

          
        } else {
          this.domesticList = []
        }
      } finally {
        this.loading = false
      }
    },

    // 下拉
    async select() {
      let s = await storage.getSelects('review_app_names', 'review_res_names', 'review_agent_names')
      this.appnameSelect = s.review_app_names
      this.resnameSelect = s.review_res_names
      this.agentnameSelect = s.review_agent_names
      this.typeSelect = storage.getReviewTypes()
    },
    //导出事件
    openDia() {
      this.filters.fields = this.$options.data().filters.fields;
      this.checkAll = false
      this.isIndeterminate = true
    },
    // 确认导出
    async download() {
      this.down = true
      this.downloadDia = false
      if (this.filters.fields.length != 0) {
        try {
          const res = await tmReviewExport(this.filters)
          if(res && res.err_code === 0){
            location.href = res.data.url
            this.down = false
          }else{
            this.$message({
              message: res.err_msg,
              type: 'warning'
            });
          }

        } finally {
          this.down = false
        }
      } else {
        this.$message({
          message: '至少选择一个',
          type: 'warning'
        });
      }

    },

    //重置事件
    reset() {
      this.filters = this.$options.data().filters;
      this.sort = ''
      this.$refs.table.clearSort()
      this.perindexList()
    },
    //排序事件
    sort_change(column, prop, order) {
      console.log(column, prop, order)
      if (column.column.label == '申请日期') {
        if (column.order === "descending") {
          this.filters.sort = "deliverdate|desc";
        } else if(order === "ascending") {
          this.filters.sort = "deliverdate|asc";
        }else{
          this.filters.sort = ''
        }
      } else {
        if (column.order === "descending") {
          this.filters.sort = "judgedate|desc";
        }else if(order === "ascending") {
          this.filters.sort = "judgedate|asc";
        }else{
          this.filters.sort = ''
        }
      }

      this.perindexList();
    },

    upload(val) {
      this.submitDis = false
      this.fileSH = true
      this.fileTmcode.tmcode = val
      this.$store.commit('setscroll',{
        scroll:this.fileSH,
      });
    },
    //文件上传时事件
    beforeUpload() {
      this.uploadBtn = false
    },
    //文件上传数量超过1个提示事件
    handleExceed(files, fileList) {
      this.$message.warning(
          `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`
      );
    },

    //文件上传后事件(自动上传)
    async uploadImg(item) {
      let formData = new FormData();
      formData.append("file", item.file);
      formData.append("is_base64", 0);
      try {
        const res = await fileUpload(formData)
        if (res && res.err_code === 0) {
          this.uploadBtn = true
          this.file.name = this.automatic.name = this.manual.name = res.data.name;
          this.file.path = res.data.path;
          this.$refs.upload.clearFiles()
        } else {
          this.$message({
            type: "warning",
            message: res.err_msg,
          })
        }
      } finally {
        this.uploadBtn = true;
      }
    },

    //识别按钮点击事件
    distinguishBtn(automatic) {
      this.$refs[automatic].validate(async (valid) => {
        try {
          if (this.automatic.name != '') {
            this.distinguishDis = true
            this.contSH = true;
            this.loadingUp = true
            const res = await fileDistinguish({...this.filters, ...this.file})
            if (res && res.err_code === 0) {
              this.distinguishDis = false
              this.loadingUp = false
              this.automatic.made_at = res.data.made_at;
              this.distinguishSh = false;
              if (this.typeSelect.indexOf(res.data.matter) == -1) {
                this.automatic.matter = res.data.matter;
              } else {
                this.$message({
                  type: "warning",
                  message: '上传文件的官文类型不存在，请选择成已有的官文类型',
                })
                this.automatic.matter = ''
              }
            } else {
              this.$message({
                message: res.err_msg,
                type: 'warning'
              })
              this.contSH = false
              this.fileList = []
              this.distinguishDis = false
              this.distinguishSh = true
            }
          }
        } catch (e) {
          this.contSH = false
          this.fileList = []
          this.distinguishDis = false
          this.distinguishSh = true
        }

      })
    },
    //上传官文提交事件
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.distinguishDis = this.submitDis = true
            const res = await fileSubmit({...this.file, ...this.manual, ...this.fileTmcode})
            if (res && res.err_code === 0) {
              this.$refs[formName].resetFields();
              this.success()
            } else {
              this.$message({
                type: "warning",
                message: res.err_msg,
              })
              this.fileList = []
              this.distinguishDis = this.submitDis = false
            }
          } catch (e) {
            this.fileList = []
            this.distinguishDis = this.submitDis = false
          }
        } else {
          return false;
        }
      })


    },
    submitAutomatic(automatic) {
      this.$refs[automatic].validate(async (valid) => {
        if (valid) {
          try {
            this.distinguishDis = this.submitDis = true
            const res = await fileSubmit({...this.file, ...this.automatic, ...this.fileTmcode})
            if (res && res.err_code === 0) {
              this.success()
            } else {
              this.$message({
                type: "warning",
                message: res.err_msg,
              })
              this.distinguishDis = this.submitDis = false
            }
          } catch (e) {
            this.fileList = []
            this.distinguishDis = this.submitDis = false
          }
        } else {
          return false
        }
      })


    },
    //上传官文提交成功事件
    success() {
      this.$message({
        type: "success",
        message: "提交成功!",
      });
      this.fileSH = false;
      this.$store.commit('setscroll',{
        scroll:this.fileSH,
      });
      this.manual = this.$options.data().manual; //清空表单
      this.automatic = this.$options.data().automatic; //清空表单
      this.distinguishSh = true;
      this.contSH = false;
      this.tabtNum = 1
      this.fileList = []
      this.file.name = ''
      this.distinguishDis = this.submitDis = false
      this.domesticDetails()
    },
    //上传官文取消事件
    empty() {
      this.fileSH = false;
      this.$store.commit('setscroll',{
        scroll:this.fileSH,
      });
      this.$refs.ruleForm.resetFields()
      // this.$refs.automatic.resetFields()

      this.tabtNum = 1
      this.distinguishSh = true;
      this.contSH = false;
      this.$refs.upload.clearFiles()
      this.file = this.$options.data().file; //清空表单
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/domestic.less";
div{
  .mainrb{
    .list-list {
      .listb {
        .content{
          .contentC {
            p:nth-last-of-type(1),p:nth-last-of-type(2) {
              margin-bottom: 0;

            }
            p:nth-last-of-type(1){
              justify-content: unset;
              span{
                width: 85%;
              }
            }
            p:nth-last-of-type(2){
              span{
                width: 50%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
