<template>
  <div class="home">

    <!-- 背景图 -->
    <div class="banner">
      <div class="search">
        <!-- 搜索框头部切换按钮 -->
        <div class="searchT">
          <span :class="{ on: num == 0 }" @click="num = 0">商标综合查询</span>
          <span :class="{ on: num == 1, on2: num == 2 }" @click="num = 1"
            >商标近似查询</span
          >
          <span :class="{ on: num == 2 }" @click="num = 2">商标状态查询</span>
        </div>
        <!-- 搜索框 -->
        <div class="searchB" :class="{ on2: num == 1, on3: num == 2 }">
          <div class="" v-show="num == 0">
            <p :class="{ disnone: isDisnone0 }">* 请输入正确的商标名称</p>
            <form action="" ref="comprehensiveRef" class="clearfix">
              <div class="input">
                <select class="choice">
                  <option value="">选择分类</option>
                </select>
                <input type="text" placeholder="请输入商标名称" />
                <img src="@/assets/img/index/icon-search.png" alt="" />
              </div>
            </form>
          </div>

          <div class="" v-show="num == 1">
            <p :class="{ disnone: isDisnone0 }">* 请输入正确的商标名称</p>
            <form action="" ref="comprehensiveRef" class="clearfix">
              <div class="input">
                <select class="choice">
                  <option value="">选择分类</option>
                </select>
                <input type="text" placeholder="请输入商标名称" />
                <img src="@/assets/img/index/icon-search.png" alt="" />
              </div>
            </form>
          </div>
          <div class="" v-show="num == 2">
            <p :class="{ disnone: isDisnone0 }">* 请输入正确的商标名称</p>
            <form action="" ref="comprehensiveRef" class="clearfix">
              <div class="input fl">
                <select class="choice">
                  <option value="">选择分类</option>
                </select>
                <input type="text" placeholder="请输入商标名称" />
                <img src="@/assets/img/index/icon-search.png" alt="" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- 热门服务 -->
    <div class="services">
      <div class="services-t container">
        <div class="title">
          <h2>热门服务</h2>
        </div>
        <div class="services-tL fl" @mouseleave="(isList = true), (num1 = -1)">
          <div
            class="services-tL-nav clearfix"
            v-for="(item, index) in servicesList"
            :key="index"
            @mousemove="navListOn(index)"
            :class="{ on: num1 == index }"
          >
            <div class="navL fl">
              <img :src="item.path" alt="" />
              <p>{{ item.name }}</p>
            </div>
            <ul class="navR fl">
              <li v-for="(item1, index1) in item.navlist" :key="index1">
                {{ item1.navName }}
              </li>
            </ul>
          </div>
          <div
            class="services-tL-list"
            :class="{ disnone: isList }"
            @mouseenter="isList = false"
            @mouseleave="(isList = true), (num1 = -1)"
          >
            <div class="" v-for="(item2, index2) in listTxt" :key="index2">
              <h3>{{ item2.listTxtName }}</h3>
              <div class="">
                <a
                  href=""
                  v-for="(item3, index3) in item2.listList"
                  :key="index3"
                  ><span>{{ item3.listListName }}</span> |</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="services-tR fl">
          <ul>
            <li>
              <div class="liT">
                <h2>
                  <img
                    src="@/assets/img/index/icon-services1.png"
                    alt=""
                  />商标注册
                </h2>
              </div>
              <div class="liB">
                <router-link to="/">商标注册</router-link>
              </div>
            </li>
            <li>
              <div class="liT">
                <h2>
                  <img
                    src="@/assets/img/index/icon-services2.png"
                    alt=""
                  />版权登记
                </h2>
              </div>
              <div class="liB">
                <router-link to="/">美术作品版权登记</router-link>
                <router-link to="/">美术作品版权登记</router-link>
              </div>
            </li>
            <li>
              <div class="liT">
                <h2>
                  <img
                    src="@/assets/img/index/icon-services3.png"
                    alt=""
                  />专利申请
                </h2>
              </div>
              <div class="liB">
                <router-link to="/">外观专利设计</router-link>
                <router-link to="/">发明专利</router-link>
              </div>
            </li>
            <li>
              <div class="liT">
                <h2>
                  <img
                    src="@/assets/img/index/icon-services4.png"
                    alt=""
                  />标志设计
                </h2>
              </div>
              <div class="liB">
                <router-link to="/">字体标志设计</router-link>
                <router-link to="/">图形标志设计</router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="services-b"></div>
    </div>
    <!-- 热门行业 -->
    <div class="industries">
      <div class="industriesB"></div>
    </div>
    <!-- 客户展示 -->
    <div class="customer container">
      <div class="title">
        <div class="lineL"></div>
        <div class="lineR"></div>
        <h2>客户展示</h2>
      </div>

      <img src="@/assets/img/banner-cooperation.png" alt="" />
    </div>

  </div>
</template>

<script>
import industries1 from "@/assets/img/index/icon-industries1.png";
import industries2 from "@/assets/img/index/icon-industries2.png";
import industries3 from "@/assets/img/index/icon-industries3.png";
import industries4 from "@/assets/img/index/icon-industries4.png";
import industries5 from "@/assets/img/index/icon-industries5.png";
import industries6 from "@/assets/img/index/icon-industries6.png";
import industries7 from "@/assets/img/index/icon-industries7.png";
import industries8 from "@/assets/img/index/icon-industries8.png";
import industries9 from "@/assets/img/index/icon-industries9.png";
import industries10 from "@/assets/img/index/icon-industries10.png";
export default {
  name: 'Index',

  data() {
    return {
      num: 0, //搜索款切换
      num1: -1,
      isDisnone0: true, //搜索款1报错提示
      isDisnone1: true, //搜索款2报错提示
      isDisnone2: true, //搜索款3报错提示
      servicesList: [
        {
          path: industries1,
          name: "衣",
          navlist: [
            {
              navName: "马毛",
            },
            {
              navName: "马毛2",
            },
            {
              navName: "马毛3",
            },
            {
              navName: "马毛4",
            },
            {
              navName: "马毛5",
            },
            {
              navName: "马毛6",
            },
            {
              navName: "马毛7",
            },
            {
              navName: "马毛8",
            },
          ],
        },
        {
          path: industries2,
          name: "食",
          navlist: [
            {
              navName: "马毛",
            },
            {
              navName: "马毛2",
            },
            {
              navName: "马毛3",
            },
            {
              navName: "马毛4",
            },
            {
              navName: "马毛5",
            },
            {
              navName: "马毛6",
            },
            {
              navName: "马毛7",
            },
            {
              navName: "马毛8",
            },
          ],
        },
        {
          path: industries3,
          name: "住",
          navlist: [
            {
              navName: "马毛",
            },
            {
              navName: "马毛2",
            },
            {
              navName: "马毛3",
            },
            {
              navName: "马毛4",
            },
            {
              navName: "马毛5",
            },
            {
              navName: "马毛6",
            },
            {
              navName: "马毛7",
            },
            {
              navName: "马毛8",
            },
          ],
        },
        {
          path: industries4,
          name: "用",
          navlist: [
            {
              navName: "马毛",
            },
            {
              navName: "马毛2",
            },
            {
              navName: "马毛3",
            },
            {
              navName: "马毛4",
            },
            {
              navName: "马毛5",
            },
            {
              navName: "马毛6",
            },
            {
              navName: "马毛7",
            },
            {
              navName: "马毛8",
            },
          ],
        },
        {
          path: industries5,
          name: "医",
          navlist: [
            {
              navName: "马毛",
            },
            {
              navName: "马毛2",
            },
            {
              navName: "马毛3",
            },
            {
              navName: "马毛4",
            },
            {
              navName: "马毛5",
            },
            {
              navName: "马毛6",
            },
            {
              navName: "马毛7",
            },
            {
              navName: "马毛8",
            },
          ],
        },
        {
          path: industries6,
          name: "农业",
          navlist: [
            {
              navName: "马毛",
            },
            {
              navName: "马毛2",
            },
            {
              navName: "马毛3",
            },
            {
              navName: "马毛4",
            },
            {
              navName: "马毛5",
            },
            {
              navName: "马毛6",
            },
            {
              navName: "马毛7",
            },
            {
              navName: "马毛8",
            },
          ],
        },
        {
          path: industries7,
          name: "机械",
          navlist: [
            {
              navName: "马毛",
            },
            {
              navName: "马毛2",
            },
            {
              navName: "马毛3",
            },
            {
              navName: "马毛4",
            },
            {
              navName: "马毛5",
            },
            {
              navName: "马毛6",
            },
            {
              navName: "马毛7",
            },
            {
              navName: "马毛8",
            },
          ],
        },
        {
          path: industries8,
          name: "金融",
          navlist: [
            {
              navName: "马毛",
            },
            {
              navName: "马毛2",
            },
            {
              navName: "马毛3",
            },
            {
              navName: "马毛4",
            },
            {
              navName: "马毛5",
            },
            {
              navName: "马毛6",
            },
            {
              navName: "马毛7",
            },
            {
              navName: "马毛8",
            },
          ],
        },
        {
          path: industries9,
          name: "科技",
          navlist: [
            {
              navName: "马毛",
            },
            {
              navName: "马毛2",
            },
            {
              navName: "马毛3",
            },
            {
              navName: "马毛4",
            },
            {
              navName: "马毛5",
            },
            {
              navName: "马毛6",
            },
            {
              navName: "马毛7",
            },
            {
              navName: "马毛8",
            },
          ],
        },
        {
          path: industries10,
          name: "文教",
          navlist: [
            {
              navName: "马毛",
            },
            {
              navName: "马毛2",
            },
            {
              navName: "马毛3",
            },
            {
              navName: "马毛4",
            },
            {
              navName: "马毛5",
            },
            {
              navName: "马毛6",
            },
            {
              navName: "马毛7",
            },
            {
              navName: "马毛8",
            },
          ],
        },
      ],
      listTxt: [
        {
          listTxtName: "纺织业",
          listList: [
            {
              listListName: "纺织品上光化学品",
            },
            {
              listListName: "纺织品上光化学品1",
            },
          ],
        },
        {
          listTxtName: "服装配饰",
          listList: [
            {
              listListName: "纺织品上光化学品2",
            },
            {
              listListName: "纺织品上光化学品22",
            },
          ],
        },
      ],
      isList: true,


    };
  },

  methods: {
    navListOn(index) {
      this.num1 = index;
      this.isList = false;
    },

  },
};
</script>

<style lang="less" scoped>
@import "../../style/home.less";
</style>
