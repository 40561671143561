<template>
 <div class="p-b40 atlas-detail" :class="{ios:$store.state.heights}">
    <div class="">
      <h2>
        商标图谱
      </h2>
      <div class="atlas p15 col-606 ba-col-fff grid grid-row-gap15">
        <div class="flex align-cen flex-gap12">
          <span class="font-wbold">申请人名称：</span>
          <el-select v-model="filters.appname" placeholder="请选择申请人" size="mini" @change="initChart">
            <el-option v-for="item in appnameSelect" :key="item.id" :label="item.text" :value="item.text">
            </el-option>
          </el-select>
        </div>


        <div class="num flex flex-cen flex-gap15">
          <div class="item grid grid-row-gap10 ba-col-ECF">
            <p class="font-wbold">总量</p>
            <p v-if="numLoading"><i class="el-icon-loading font-22"></i></p>

            <p v-else class="col-blue font-22">
              {{ summary.total || '0' }}
            </p>
          </div>
          <div class="item grid grid-row-gap10 ba-col-ECF">
            <p class="font-wbold">已注册</p>
            <p v-if="numLoading"><i class="el-icon-loading font-22"></i></p>
            <p v-else class="col-blue font-22">{{ summary.total_reg || '0' }}</p>
          </div>
          <div class="item grid grid-row-gap10 ba-col-ECF">
            <p class="font-wbold">申请中</p>
            <p v-if="numLoading"><i class="el-icon-loading font-22"></i></p>
            <p v-else class="col-blue font-22">{{ summary.total_app || '0' }}</p>
          </div>
          <div class="item grid grid-row-gap10 ba-col-ECF">
            <p class="font-wbold">核心商标</p>
            <p v-if="numLoading"><i class="el-icon-loading font-22"></i></p>
            <p v-else class="col-blue font-22 ellipsis">
              <span v-for="(item, index) in summary.core_tm">{{ item }}<font v-if="index != summary.core_tm.length - 1">、
                </font> </span>
            </p>
          </div>
          <div class="item grid grid-row-gap10 ba-col-ECF">
            <p class="font-wbold">核心类别</p>
            <p v-if="numLoading"><i class="el-icon-loading font-22"></i></p>
            <p v-else class="col-blue font-22 ellipsis">
              <span v-for="(item, index) in summary.core_cls">{{ item }}<font v-if="index != summary.core_tm.length - 1">、
                </font> </span>
            </p>
          </div>
        </div>
      </div>

    </div>

    <div class=" bar">
      <div class="country ba-col-fff bor-rad5">
        <div class="country_t m-t15 flex flex-cen p15 bor-bottom">
          <span class="font-18 font-wbold col-002">国家/地区统计</span>

          <el-checkbox v-model="countryCheck" @change="countryChange" class="fr">隐藏未覆盖国家/地区</el-checkbox>
        </div>
        <div class="bar_select ">
          <div class="flex  flex-gap10 align-cen ">
            <div class="flex align-cen  flex-gap10 col-606">
              <span class="font-wbold">商标名称：</span>
              <el-input v-model.trim="filters.conName" clearable placeholder="多个商标以“，”相隔" size="mini" @clear="country">
                <i slot="suffix" class="el-icon-search el-input__icon" @click="country">
                </i>
              </el-input>
            </div>
            <div class="flex align-cen  flex-gap10">
              <span class=" font-wbold">商标类别：</span>
              <el-select v-model="filters.conIntcls" placeholder="请选择商标类别" size="mini" @change="country">
                <el-option v-for="item in intclsSelect" :key="item.id" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="flex align-cen  flex-gap10">
              <span class=" font-wbold">法律状态：</span>
              <span v-for="item in stateSelect"
                :class="{ 'col-blue': filters.conType == item.id, 'font-wbold': filters.conType == item.id }" class="pointer"
                @click="filters.conType = item.id, filters.conTmlaw = [], filters.conTmlaw = item.array, country()">{{
                  item.text }}</span>

            </div>
            <div class="flex align-cen  flex-gap10">
              <span class=" font-wbold">申请途径：</span>
              <span v-for="item in roadSelect"
                :class="{ 'col-blue': filters.road == item.id, 'font-wbold': filters.road == item.id }" class="pointer"
                @click="roadChange(item)">{{
                  item.text }}</span>

            </div>
          </div>
        </div>
        <div>
          <div class="p15" v-for="(state, stateIns) in countryList">
            <p @click="countryArr.indexOf(stateIns) != -1 ? countryArr.splice(countryArr.indexOf(stateIns), 1) : countryArr.push(stateIns)"
              class="plf15 pointer">
              {{ state.state }}
              <i :class="countryArr.indexOf(stateIns) != -1 ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
            </p>
            <div class="p15 flex flex-warp  flex-gap15 countryList" v-if="countryArr.indexOf(stateIns) == -1">
              <div v-for="(item, index) in state.data" v-if="item.show"
                :class="{ num0: item.value === 0, num20: (item.value <= 20 && item.value != 0), num100: (item.value <= 100 && item.value - 20 > 0), nummore: (item.value > 100 && item.value != 0) }"
                class="p8 col-blue flex flex-direction flex-gap5 bor-rad5 country">

                <div class="flex flex-cen">
                  <img :src="require(`@/assets/img/country/${item.short_name}.png`)" alt="">
                  <p class="m-b5 w70 tc">{{ item.name }}</p>
                </div>
                <p class="tc">{{ item.value }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" ">
        <h2 class="font-18">
          类别统计
        </h2>
        <div class="ba-col-fff">
          <div class="bar_select ">
            <div class="flex  flex-gap10 align-cen ">
              <div class="flex align-cen  flex-gap15 col-606">
                <span class="font-wbold">商标名称：</span>
                <el-input v-model="filters.intname" clearable placeholder="多个商标以“，”相隔" size="mini"
                  @clear="inclsEcharts">
                  <i slot="suffix" class="el-icon-search el-input__icon" @click="inclsEcharts">
                  </i>
                </el-input>
              </div>
              <div class="flex align-cen  flex-gap15">
                <span class=" font-wbold">国家/地区：</span>
                <el-select v-model="filters.catCountryname" placeholder="请选择国家/地区" size="mini" @change="(e) => inclsEcharts(e,filters.catCountryname)">
                  <el-option v-for="item in catCountrynameSelect" :key="item.id" :label="item.name"
                    :value="item.short_name">
                  </el-option>
                </el-select>
              </div>
              <div class="flex align-cen  flex-gap15">
                <span class=" font-wbold">法律状态：</span>
                <span v-for="item in stateSelect"
                  :class="{ 'col-blue': filters.type == item.id, 'font-wbold': filters.type == item.id }" class="pointer"
                  @click="filters.type = item.id, filters.catTmlaw = [], filters.catTmlaw = item.array, inclsEcharts()">{{
                    item.text }}</span>
              </div>

            </div>
          </div>
          <div class="p15 flex flex-warp  flex-gap15">
            <div v-for="(item, index) in incls_barSelect"
              :class="{ num0: item.value === 0, num20: (item.value <= 20 && item.value != 0), num100: (item.value <= 100 && item.value - 20 > 0), nummore: (item.value > 100 && item.value != 0) }"
              class="p8 col-blue flex align-cen ju-con-around flex-gap5 bor-rad5">
              <i :class="item.icon" class=" iconfont"></i>
              <div class="tc ">
                <p class="m-b5">{{ item.text.replace('类 -', '') }}</p>
                <p>{{ item.value }}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class=" ">
        <h2 class="font-18">
          状态统计
        </h2>
        <div class="ba-col-fff">
          <div class="bar_select">
            <div class="flex  flex-gap10 align-cen ">
              <div class="flex align-cen  flex-gap15 col-606">
                <span class="font-wbold">商标名称：</span>
                <el-input v-model="filters.typname" clearable placeholder="多个商标以“，”相隔" size="mini"
                  @clear="tmLawEcharts">
                  <i slot="suffix" class="el-icon-search el-input__icon" @click="tmLawEcharts">
                  </i>
                </el-input>
              </div>
              <div class="flex align-cen  flex-gap15">
                <span class=" font-wbold">国家/地区：</span>
                <el-select v-model="filters.typeCountryname" placeholder="请选择国家/地区" size="mini" @change="(e) => tmLawEcharts(e,filters.typeCountryname)">
                  <el-option v-for="item in catCountrynameSelect" :key="item.id" :label="item.name"
                    :value="item.short_name">
                  </el-option>
                </el-select>
              </div>
              <div class="flex align-cen  flex-gap15">
                <span class=" font-wbold">商标类别：</span>
                <el-select v-model="filters.intcls" placeholder="请选择商标类别" size="mini" @change="tmLawEcharts">
                  <el-option v-for="item in intclsSelect" :key="item.id" :label="item.text" :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="p15 flex flex-cen">
            <div id="type_pie" style="height: 420px;width: 25%"></div>
            <div id="type_bar" style="height: 390px;width: 75%" class="p15"></div>
          </div>
        </div>

      </div>

    </div>
    <div class="">
      <h2 class="font-18">
        商标布局
      </h2>
      <div class="incls p15 ba-col-fff ">
        <div class="m-b15">
          <div class="flex align-cen flex-gap10 col-606">
            <div class="flex align-cen flex-gap12">
              <span class="font-wbold">商标名称：</span>
              <el-input v-model="filters.tmname" clearable placeholder="多个商标以“，”相隔" size="mini"
                @clear="tmnameChange">
                <i slot="suffix" class="el-icon-search el-input__icon" @click="tmnameChange">
                </i>
              </el-input>
            </div>
            <div class="flex align-cen  flex-gap15">
              <span class=" font-wbold">国家/地区：</span>
              <el-select v-model="filters.layoutCountryname" placeholder="请选择国家/地区" size="mini" @change="(e) => tmnameChange(e,filters.layoutCountryname)">
                <el-option v-for="item in catCountrynameSelect" :key="item.id" :label="item.name"
                  :value="item.short_name">
                </el-option>
              </el-select>
            </div>
            <div class="flex align-cen flex-gap10">
              <span class="font-wbold">法律状态：</span>
              <div class="flex align-cen flex-gap10 col-303">
                <span v-for="item in stateSelect" :class="{ 'col-blue': state == item.id, 'font-wbold': state == item.id }"
                  class="pointer" @click="state = item.id, filters.tmlaw = [], filters.tmlaw = item.array, tmnameChange()">
                  {{ item.text }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <el-skeleton :cont="1" :loading="inclsLoading" animated>
          <template slot="template">
            <el-table :data="loadList" border class="over_hid" height="435" style="width: 100%">
              <el-table-column label="图样" width="80">
                <template>
                  <el-skeleton-item class="w80" variant="p" />
                </template>
              </el-table-column>
              <el-table-column label="名称" width="80">
                <template>
                  <el-skeleton-item class="w80" variant="p" />
                </template>
              </el-table-column>
              <el-table-column label="类别">
                <template>
                  <el-skeleton-item class="w80" variant="p" />
                </template>
              </el-table-column>
            </el-table>
          </template>
          <el-table :data="tmDist" border class="incls_tab" height="590" style="width: 100%">
            <template slot="empty">
              <el-empty :image-size="80"></el-empty>
            </template>
            <el-table-column fixed label="图样" width="80">
              <template slot-scope="scope">
                <el-image :preview-src-list="[scope.row.tm_image]" :src="scope.row.tm_image">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline font-30"></i>
                  </div>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column fixed label="名称"  width="80">
              <el-tooltip slot-scope="scope" class="item" effect="dark" v-showtip :content="scope.row.tm_name" placement="top-start">
                <font class=" ellipsis w80 disinblk">
                  <font class="ellipse-text ">
                    {{ scope.row.tm_name || '图形' }}
                  </font>
                </font>
              </el-tooltip>
            </el-table-column>
            <!-- <el-table-column fixed label="名称" width="80">
              <template slot-scope="scope">
                {{ scope.row.tm_name || '图形' }}
              </template>
            </el-table-column> -->
            <el-table-column label="类别" :width="alitWidth ? 1200 : ''">
              <template slot-scope="scope">
                <div class="flex flex-cen ">

                  <span v-for="item in 45" :class="{ on: scope.row.int_cls.indexOf(item.toString()) != -1 }"
                    class="flex-item disblk h100">
                    {{ (item < 10) ? ('0' + item) : item }} </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-skeleton>

      </div>
    </div>
  </div>
</template>
<script>
import storage from '@/utils/storage'
import { globalTmsSummary, globalTmsTmDist, globalTmsCountryList, globalTmsTypeList, globalTmCount } from '@/api/personal.js'
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core'
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, LineChart, PieChart } from 'echarts/charts'
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  TransformComponent,
} from 'echarts/components'
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features'
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers'

// 注册必须的组件
echarts.use([
  ToolboxComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  PieChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LineChart
]);
export default {
  data() {
    return {
      alitWidth: false,
      countryCheck: false,
      countryArr: [],
      catCountrynameSelect: [],
      countryList: storage.getCountryList(),
      incls_barSelect: storage.getInicon(),
      intclsSelect: storage.getIntcls(),
      filters: {
        countryname: [],
        conName: '',
        conTmlaw: ["申请中", "已注册"],
        conType: 0,
        conIntcls: '',
        appname: storage.getUser().company,
        tmname: '',
        intname: '',
        typname: '',
        tmlaw: ["申请中", "已注册"],
        appdate: '全部',
        intcls: '',
        noinvalid: 1,
        year: 0,
        type: 0,
        road: -1,

        catCountryname: '',
        catTmlaw: ["申请中", "已注册"],

        typeCountryname: '',

        layoutCountryname: '',
      },
      loadList: storage.getList(),//骨架屏的起始数组（10条数据）
      applyEmpty: false,//商标申请量数据为空时
      numLoading: true,
      loading: true,//控制骨架屏的显示与隐藏
      inclsLoading: true,
      tmlaw: ["申请中", "已注册"],//类别分布的法律状态
      stateSelect: [
        { id: 0, text: '申请中+已注册', array: ["申请中", "已注册"] },
        { id: 1, text: '申请中', array: ["申请中"] },
        { id: 2, text: '已注册', array: ["已注册"] },
      ],//法律状态
      roadSelect: [
        { id: 0, text: '单一国', array: ["SINGLE"] },
        { id: 1, text: '马德里', array: ["WIPO"] },
        { id: 2, text: '欧盟', array: ["EU"] },
      ],//申请途径
      state: 0,//当前法律状态
      appnameSelect: [],//申请人下拉
      summary: {},//商标统计
      tmDist: [],//商标分布
      replace: {
        conTmlaw: '申请中+已注册',
        countryname: '',
        catTmlaw: '申请中+已注册',
        tmlaw: '申请中+已注册',
      },

    }
  },
  mounted() {
    if (document.body.offsetWidth < 1200) {
      this.alitWidth = true
    } else {
      this.alitWidth = false
    }
    window.onresize = () => {
      if (document.body.offsetWidth < 1200) {
        this.alitWidth = true
      } else {
        this.alitWidth = false
      }

    }
    this.select();
    this.initChart()

  },
  methods: {
    //隐藏未覆盖国家
    countryChange() {
      this.countryList.forEach(item => {
        item.data.forEach(conutry => {
          if (conutry.value === 0) {

            conutry.show = !this.countryCheck
          }
        })
      })

    },

    async initChart() {
      this.numLoading = true
      try {
        const res = await globalTmsSummary({ appname: this.filters.appname })
        if (res && res.err_code === 0) {
          this.numLoading = false
          this.summary = res.data

          this.country()
          this.tmnameChange()
          this.inclsEcharts()
          this.tmLawEcharts()
        }
      } finally {
        this.numLoading = false
      }

    },
    roadChange(item) {
      this.filters.countryname = []
      if (this.replace.countryname == item.text) {
        this.replace.countryname = ''
        this.filters.road = -1
      } else {
        this.replace.countryname = item.text
        this.filters.road = item.id
        this.filters.countryname = item.array
      }
      this.country()
    },
    // 国家地区
    async country() {

      let tmname = this.filters.conName ? this.filters.conName.split(/，|;|；|,/) : []

      try {
        const res = await globalTmsCountryList({ type: this.filters.conIntcls, appname: this.filters.appname, tmname: tmname, tmlaw: this.filters.conTmlaw, countryname: this.filters.countryname })
        if (res && res.err_code === 0) {
          this.countryList.forEach(item => {
            item.data.forEach(state => {
              state.value = 0
              if (res.data) {
                res.data.forEach(country => {
                  if (state.name == country.name) {
                    if (country.short_name == 'Uk' || country.short_name == 'GB') {
                      state.value += country.value
                    } else {
                      state.value = country.value
                    }

                  }
                })
              }

            })
          })

        } else {

        }
      } finally {

      }

    },
    // 商标布局
    async tmnameChange(e,item) {
      this.inclsLoading = true
      let tmname = this.filters.tmname ? this.filters.tmname.split(/，|;|；|,/) : []
      try {

        const res = await globalTmsTmDist({ appname: this.filters.appname, tmlaw: this.filters.tmlaw, tmname: tmname, countryname: ['UK', 'GB'].indexOf(item) != -1 ? 'UK/GB' : item })
        if (res && res.err_code === 0) {
          this.inclsLoading = false
          this.tmDist = res.data
        } else {
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {

      }
    },
    //类别分布
    async inclsEcharts(e,item) {

      let tmname = this.filters.intname ? this.filters.intname.split(/，|;|；|,/) : []

      try {
        const res = await globalTmsTypeList({ appname: this.filters.appname, tmlaw: this.filters.catTmlaw, tmname: tmname, countryname: ['UK', 'GB'].indexOf(item) != -1 ? 'UK/GB' : item })
        if (res && res.err_code === 0) {
          if(res.data){
            res.data.forEach(item => {
            this.incls_barSelect.forEach(item1 => {
              if (item.type == parseFloat(item1.text.substring(0, 2))) {
                item1.value = item.value
              }
            })
          })
          }else{
            this.incls_barSelect.forEach(item => {
              item.value = 0
            })
          }
        } else {
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {

      }
    },


    //状态分布
    async tmLawEcharts(e,item) {
      let typeBar = echarts.init(document.getElementById('type_bar'));
      typeBar.showLoading()
      let typePie = echarts.init(document.getElementById('type_pie'));
      typePie.showLoading()
      let tmname = this.filters.typname ? this.filters.typname.split(/，|;|；|,/) : []
      try {
        const res = await globalTmCount({
          appname: this.filters.appname,
          countryname: ['UK', 'GB'].indexOf(item) != -1 ? 'UK/GB' : item,
          tmname: tmname,
          type: this.filters.intcls
        })
        if (res && res.err_code === 0) {
          let name = []
          let value = []
          res.data.forEach(item => {
            name.push(item.name)
            value.push(item.value)
          })

          typeBar.setOption(this.typeBar(name, value))
          typePie.setOption(this.typePie(res.data))
        } else {
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {
        typeBar.hideLoading()
        typePie.hideLoading()
      }
      window.addEventListener("resize", () => {
        typeBar.resize()
        typePie.resize()
      });
    },
    //状态分布图表
    typeBar(...item) {
      let empty = '';
      if (item[0].length == 0) {
        empty = '暂无数据'
      }
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        title: {
          subtext: empty,
          x: "center",
          y: "center",
          subtextStyle: {
            fontSize: 20,
          },

        },
        grid: {
          left: '2%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            axisLabel: {
              interval: 0,//横轴信息全部显示
              rotate: -60,//-15度角倾斜显示
              overflow: 'truncate',
              formatter: function (value) {
                if (value.length > 6) {
                  return value.slice(0, 6) + '...';
                } else {
                  return value;
                }
              }
            },
            type: 'category',
            data: item[0],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            itemStyle: {
              color: '#409EFF'
            },
            name: '数量',
            type: 'bar',
            barWidth: '40%',
            label: {
              show: true,
              position: 'top'
            },
            data: item[1]
          }
        ]
      };
    },

    typePie(item) {
      return {

        tooltip: {
          trigger: 'item'
        },

        series: [
          {
            label: {
              show: true,
              formatter: `{d}%`,
              color: "#ffffff", //颜色
              fontSize: 12, //字体大小
              position: "inside",
            },
            type: 'pie',
            data: item,

          },
          {
            type: 'pie',
            data: item,

          },
        ]
      }
    },
    // 下拉
    async select() {
      let s = await storage.getSelects('app_names')
      this.appnameSelect = s.app_names
      this.countryList.forEach(item => {
        this.catCountrynameSelect = this.catCountrynameSelect.concat(item.data)
      })
    },


  }
}
</script>
<style lang="less" scoped>
.atlas-detail{
  height: 829px;
  overflow-y: auto;
}
.atlas-detail::-webkit-scrollbar {
    width : 5px;
    height: 15px;

}
.atlas-detail::-webkit-scrollbar-thumb{
    box-shadow   : inset 0px 0px 0px rgb(0, 0, 0,.1);
    background: rgba(0, 0, 0,0.1);
    border-radius: 5px;
}
.atlas-detail::-webkit-scrollbar-track{
    background: #F4F4F4;
    //box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
}
h2 {
  margin-top: 10px !important;
}

.atlas {
  ::v-deep .el-input {
    width: 250px;
  }

  .num {
    .item {
      flex: 1;
      padding: 12px 24px;
      border-radius: 12px;
    }
  }
}

.incls {
  ::v-deep .el-input {
    width: 200px;

    .el-input__suffix-inner {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  ::v-deep .incls_tab {
    overflow: hidden;

    td {
      padding: 0;

      .cell {
        padding: 0;

        .flex {
          height: 80px;


          .flex-item {
            border-right: 1px solid #E4E7ED;
            color: #409EFF;
            line-height: 80px;
          }

          .flex-item.on {
            border-right: 1px solid #FFFFFF;
            color: #FFFFFF;
            background: #409EFF;
          }

          .flex-item:nth-last-of-type(1) {
            border: none;
          }
        }

        .el-image {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 79px;

          img {
            width: unset;
            height: unset;
            max-width: 75px !important;
            max-height: 75px !important;
          }

        }
      }

    }
  }
}

.incls>div:nth-last-of-type(1) {
  width: 100%;

}

.applyNum {
  .num_tabs {
    padding: 15px 0px 0px 15px;

    ::v-deep .el-tabs {

      .el-tabs__header {
        margin: 0;

        .el-tabs__nav-wrap::after,
        .el-tabs__active-bar {
          height: 0;
        }

        .el-tabs__item {
          height: unset;
          font-weight: 400;
          padding: 0 5px;
          line-height: unset;
        }

        .el-tabs__item.is-active {
          font-weight: bold;
        }
      }
    }
  }

  .num_earch {
    #apply_echarts {
      height: 390px;
    }
  }
}

.bar {

  .countryList {
    gap: 15px 163px;
    img{
      width: 32px;
    }
  }

  .bar_select {
    padding: 15px 15px 0px;

  }

  ::v-deep .el-input__suffix-inner {
    display: flex;
    flex-direction: row-reverse;
  }

  .p15>div {
    min-width: 173px;

    i {

      font-size: 32px;
    }

  }

  .p15>.country {
    min-width: 136px;
  }

  .p15>div.num0 {
    background: var(--neutral-color-white, #FFF);
    opacity: 0.2;
  }

  .p15>div.num20 {
    background: var(--primary-100, #ECF5FF);

  }

  .p15>div.num100 {
    background: #BCDEFF;

  }

  .p15>div.nummore {
    background: var(--primary-300, #409EFF);
    color: #fff;

  }

  .p15>div.num20:hover,
  .p15>div.num20:hover,
  .p15>div.num100:hover,
  .p15>div.nummore:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  }

  ::v-deep .el-checkbox {
    .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #606266;
    }
  }
}

@media screen and (min-width: 1360px) {
  .bar {
    .bar_select {

      .el-input,
      .el-select {
        width: 200px;
      }
    }
  }
}

@media screen and (max-width: 1360px) {
  .bar {
    .bar_select {

      .el-input,
      .el-select {
        width: 150px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .bar {
    .bar_select {

      .el-input,
      .el-select {
        width: 130px;
      }
    }
  }
}
</style>