<template>
  <div class="">
    <div class="search-ban">
      <div class="search">
        <!-- 搜索框头部切换按钮 -->
        <div class="searchT">
          <span :class="{ on: num == 0 }" @click="num = 0">商标综合查询</span>
          <span :class="{ on: num == 1, on2: num == 2 }" @click="num = 1"
            >商标近似查询</span
          >
          <span :class="{ on: num == 2 }" @click="num = 2">商标状态查询</span>
        </div>
        <!-- 搜索框 -->
        <div class="searchB" :class="{ on2: num == 1, on3: num == 2 }">
          <div class="" v-show="num == 0">
            <p :class="{ disnone: isDisnone0 }">* 请输入正确的商标名称</p>
            <form action="" ref="comprehensiveRef" class="clearfix">
              <div class="input">
                <select class="choice">
                  <option value="">选择分类</option> 
                </select>
                <input type="text" placeholder="请输入商标名称" />
                <img src="../../assets/img/index/icon-search.png" alt="" />
              </div>
            </form>
          </div>

          <div class="" v-show="num == 1">
            <p :class="{ disnone: isDisnone0 }">* 请输入正确的商标名称</p>
            <form action="" ref="comprehensiveRef" class="clearfix">
              <div class="input">
                <select class="choice">
                  <option value="">选择分类</option>
                </select>
                <input type="text" placeholder="请输入商标名称" />
                <img src="../../assets/img/index/icon-search.png" alt="" />
              </div>
            </form>
          </div>
          <div class="" v-show="num == 2">
            <p :class="{ disnone: isDisnone0 }">* 请输入正确的商标名称</p>
            <form action="" ref="comprehensiveRef" class="clearfix">
              <div class="input fl">
                <select class="choice">
                  <option value="">选择分类</option>
                </select>
                <input type="text" placeholder="请输入商标名称" />
                <img src="../../assets/img/index/icon-search.png" alt="" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
<router-view></router-view>
    
  </div>
</template>


<script>
export default {
 
  data() {
    return {
       num: 0, //搜索款切换
      isDisnone0: true, //搜索款1报错提示
      isDisnone1: true, //搜索款2报错提示
      isDisnone2: true, //搜索款3报错提示
    };
  },
 
};
</script>

<style lang="less" scoped>
@import "../../style/search.less";
</style>