<template>
<div class="incls p-b40 ba-col-fff">
  <h2>
    商品分类表
  </h2>
  <div class="incls_inp ">
    <el-input
        v-model="keyword"
        size="small"
        placeholder="请输入主营业务或者行业关键词进行查询">
      <i
          class="el-icon-search el-input__icon"
          slot="prefix">
      </i>
      <el-button size="small" slot="suffix" type="primary" @click="groupSearch">搜索</el-button>
    </el-input>
  </div>
  <div  >
    <el-skeleton :loading="classLoad" animated class="text_cont">
      <template slot="template">
        <div class=" cont">
          <div class="cont_t ">
            <p class="col-blue font-16 font-wbold m-b20"><el-skeleton-item class="w50" variant="p"/></p>
            <p class="col-606"><el-skeleton-item  variant="p"/></p>
          </div>
          <div class="cont_b flex ju-con-bet">
            <div class="cont_b_l font-16 col-303">
              <div class="flex flex-cen ptb10 bor-bottom pointer" v-for="item in loadList">
                <p class=" w90 ellipsis"><el-skeleton-item  variant="p"/></p>
              </div>

            </div>
            <DIV  class="cont_b_r p-10-20">

              <div >
                <p class="font-16 m-b10 col-E6A"><el-skeleton-item  variant="p"/></p>
                <div class="grid grid-row-gap8 col-606">
                  <p  v-for="item in loadList"><el-skeleton-item  variant="p"/></p>
                </div>
              </div>
            </DIV>

          </div>

        </div>
      </template>
      <div class=" cont">
        <div class="cont_t ">
          <p class="col-blue font-16 font-wbold m-b20">{{'第' + textCont.incls + '类'}} {{ textCont.name }}</p>
          <p class="col-606">{{ textCont.desc }}</p>
        </div>
        <div class="cont_b flex ju-con-bet">
          <div class="cont_b_l font-16 col-303">
            <div class="flex flex-cen ptb10 bor-bottom pointer" @click="searchGroup({intcls:parseFloat(textCont.incls),group:item.key})" v-for="item in replace.group">
              <p class=" w90 ellipsis">【{{ item.key }}】 {{ item.value }}</p>
              <i class="el-icon-arrow-right font-22"></i>
            </div>

          </div>
          <el-skeleton :loading="loading" animated class="cont_b_r p-10-20">
            <template slot="template">
              <div >
                <p class="font-16 m-b10 col-E6A">
                  <el-skeleton-item variant="text" />
                </p>
                <div class="grid grid-row-gap8 col-606">
                  <p  v-for="item in loadList"><el-skeleton-item variant="text" style="width: 30%;" /></p>
                </div>
              </div>
            </template>
            <div >
              <p class="font-16 m-b10 col-E6A">{{ groupCont.group }}</p>
              <div class="grid grid-row-gap8 col-606">
                <p  v-for="item in groupCont.goods">{{ item.code }} {{item.name}}</p>
              </div>
            </div>
          </el-skeleton>

        </div>

      </div>
    </el-skeleton>

  </div>
</div>
</template>

<script>
import storage from '@/utils/storage'
import { tmSearchClass,tmSearchGroup} from '@/api/personal.js'
export default {
  name: "class",
  data(){
    return{
      loadList: storage.getList(),//骨架屏的起始数组（10条数据）
      loading:false,
      classLoad:true,
      replace:{//替换字段


        incls:'',
        group:[],
      },
      textCont:{
        incls:'',
        desc:'',
        group:{},
        name:''
      },
      groupCont:{
        group:'',
        goods:[]
      },

      keyword:'',//商品分类表的关键字
    }
  },
  created() {
    this.searchClass(this.$route.params.intcls)
  },
  methods:{
    groupSearch(){
      const {href} = this.$router.resolve({
        name: 'queryGoods',
        params: {keyword:this.keyword},
      })
      window.open(href, '_blank');
    },

    //类似群组
    async searchGroup(item){
      this.loading = true
      try {
        const res = await tmSearchGroup(item)
        if(res && res.err_code === 0){

          this.groupCont = {...res.data}
          this.loading = false
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      }finally {
        this.loading = false
      }
    },
    //类别搜索
    async searchClass(index){
      try {
        const res = await tmSearchClass({intcls:index})
        if(res && res.err_code === 0){
          this.textCont = {...res.data}
          this.classLoad =false
          this.textCont.incls = index
          let arr = []
          let key = Object.keys(this.textCont.group)
          let val = Object.values(this.textCont.group)
          arr = key.map((item,index) =>{
            return{
              value:val[index],
              key:item
            }
          })
          this.replace.group = arr
          this.searchGroup({intcls:parseFloat(this.textCont.incls),group:this.replace.group[0].key})
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      }finally {

      }
    },
  }
}
</script>


<style scoped lang="less">
.incls{
  height: calc(100vh - 80px);
  ::v-deep .incls_inp{
    width: 500px;
    margin: 20px auto;
    .el-input__inner{
      border-radius: 16px;
      background: #F4F4F5;
      border: none;
    }
    .el-input__suffix{
      right: 0;
      .el-button{
        border-radius: 16px;
      }
    }
  }
  ::v-deep .text_cont{
    .cont{
      width: 58%;
      margin: auto;
      .m-b15{
        width: 20%;
      }
      .cont_t{
        margin-bottom: 15px;
      }
      .cont_b{
        height: 500px;
        .cont_b_l,.cont_b_r{
          overflow-y: auto;
          flex: 1;
        }
        .cont_b_r{
          border-left: 1px solid #E4E7ED;
          overflow-y: auto;

        }
        .cont_b_l::-webkit-scrollbar,.cont_b_r::-webkit-scrollbar {
          width : 5px;
          height: 10px;

        }
        .cont_b_l::-webkit-scrollbar-thumb,.cont_b_r::-webkit-scrollbar-thumb{
          box-shadow   : inset 0px 0px 0px rgb(0, 0, 0,.1);
          background: rgba(0, 0, 0,0.1);
          border-radius: 5px;
        }
        .cont_b_l::-webkit-scrollbar-track,.cont_b_r::-webkit-scrollbar-track {
          background: #F4F4F4;
          //box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
</style>