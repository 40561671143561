<template>
  <div class="detail ba-col-fff">
    <h2>商标详情</h2>
    <el-skeleton :loading="loading" animated>
      <template slot="template">
        <div class="plf20 col-606">
          <div class=" bor">
            <div class="detail_l ">
              <div class="img w100 flex align-cen ju-con-cen pos-rel">
                <el-skeleton-item
                    class="img-logo"
                    style="width: 190px;height: 190px"
                    variant="image"
                />
              </div>
              <div class="flows p20">
                <h3 class="col-303 font-wbold m-b10">商标流程</h3>
                <p class="line-h30 ellipsis">
                  <el-skeleton-item class="w50" variant="p"/>
                </p>
                <p class="line-h30 ellipsis">
                  <el-skeleton-item class="w50" variant="p"/>
                </p>
                <p class="line-h30 ellipsis">
                  <el-skeleton-item class="w50" variant="p"/>
                </p>
              </div>
            </div>
            <div class="detail_r">
              <div class="r-cont">
                <h3 class="p10 col-303 font-wbold">
                  <el-skeleton-item class="w50" variant="p"/>

                  <div class="disinblk">
                    <el-skeleton-item class="w50" variant="p"/>
                  </div>

                </h3>
                <div class="cont-text p20 line-h30">
                  <div class="flex flex-cen">
                    <p><strong>商标号：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p>
                      <strong>商标类型：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p>
                      <strong>代理机构：</strong> <span class="col-blue"><el-skeleton-item class="w50" variant="p"/></span>
                    </p>
                  </div>
                  <div class="flex flex-cen">
                    <p><strong>申请日期：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p><strong>初审日期：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p><strong>注册日期：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                  </div>
                  <div class="flex flex-cen">
                    <p>
                      <strong>专用期限至：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p>
                      <strong>初审公告期号：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p>
                      <strong>注册公告期号：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                  </div>

                  <div class="flex flex-cen">

                    <p>
                      <strong>申请人名称（中）：</strong>
                      <el-skeleton-item class="w50" variant="p"/>

                    </p>
                    <p>
                      <strong>申请人名称（英）：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                  </div>
                  <div class="flex flex-cen">

                    <p>
                      <strong>申请人地址（中）：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p>
                      <strong>申请人地址（英）：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                  </div>


                  <div class="">
                    <strong>类似群组：</strong>
                    <el-skeleton-item class="w50" variant="p"/>
                  </div>
                  <div class="">
                    <strong>商品/服务项目：</strong>
                    <el-skeleton-item class="w50" variant="p"/>
                  </div>
                </div>

              </div>
              <div class="r-list">
                <h3 class="p10 line-h30 col-303 font-wbold bor-bottom">商标公告 </h3>
                <div class="p20 line-h32 col-606 grid grid-row-gap15">
                  <p class="flex align-cen pointer"  v-for="item in skeletonList"> <el-skeleton-item class="w50" variant="p"/> </p>
                </div>
              </div>
            </div>
          </div>

        </div>

      </template>
      <template>
        <div ref="bor" class="plf20 m-b40  col-606">
          <div class=" bor">
            <div class="detail_l ">
              <div class="img w100 flex align-cen ju-con-cen pos-rel">
                <el-image
                    :preview-src-list="[detail.tmimage]"
                    :src="detail.tmimage">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline "></i>
                  </div>
                </el-image>
                <div
                    :class="{flag1:['注册申请中','驳回复审中','行政诉讼中','无效程序中','无效程序中','撤销复审中','撤三程序中','异议程序中'].indexOf(detail.tmlaw) != -1,flag2:detail.tmlaw == '撤三申请不予受理',flag3:['驳回复审完成','无效宣告完成','异议程序完成'].indexOf(detail.tmlaw) != -1,flag4:detail.tmlaw == '初审公告',flag5:detail.tmlaw == '已注册',flag6:detail.tmlaw == '商标无效',flag7:detail.tmlaw == '驳回待复审',flag8:detail.tmlaw == '行政诉讼撤销' || detail.tmlaw == '续展不予核准' || detail.tmlaw == '其他状态'}"
                    class="flag pos-abs pos-l0 pos-t0">
                  <span>
                    {{ detail.tmlaw || '-' }}
                  </span>
                </div>
              </div>
              <div class="flows p20">
                <h3 class="col-303 font-wbold m-b10">商标流程</h3>
                <div class="">
                  <p v-for="item in (flowsUp ? detail.flows : replace.flows)" class="line-h20">
                    {{ item.flowdate }} &nbsp;&nbsp;
                    <span v-html="item.flowname"></span>
                  </p>
                </div>
              </div>
              <p v-if="replace.flowsInit.length > 5 " class="text-cen pointer p-b10" @click="flowsUp = !flowsUp">
                {{ flowsUp ? '收起' : '查看全部' }}<i
                  :class="{'el-icon-arrow-down':flowsUp == false,'el-icon-arrow-up':flowsUp == true}"
                  class=" m-l5"></i>
              </p>

            </div>
            <div class="detail_r">
              <div class="r-cont">
                <h3 class="p10 col-303 line-h30 font-wbold flex align-cen">
                  {{ detail.clstext }}：<span class="col-blue m-r5"> {{ detail.tmname ||'图形' }} </span>

                </h3>
                <div class="cont-text p20 line-h32">
                  <div class="flex ju-con-bet">
                    <p><strong>商标号：</strong>{{ detail.tmcode || '-' }} <i
                        v-copy="detail.tmcode" class="el-icon-copy-document col-blue pointer m-l5"></i></p>
                    <p>
                      <strong>商标类型：</strong>{{ detail.tmsblx || '-' }}
                    </p>
                    <p><strong>代理机构：</strong> <span class="col-blue">{{ detail.agentname || '-' }}</span></p>

                  </div>
                  <div class="flex ju-con-bet">
                    <p><strong>申请日期：</strong>{{ detail.appdate || '-' }}</p>

                    <p><strong>初审日期：</strong>{{ detail.csdate || '-' }}</p>
                    <p><strong>注册日期：</strong>{{ detail.regdate || '-' }}</p>
                  </div>
                  <div class="flex ju-con-bet">
                    <p>
                      <strong>专用期限至：</strong>{{ detail.enddate || '-' }}
                    </p>
                    <p>
                      <strong>初审公告期号：</strong>{{ detail.csggqh || '-' }}
                    </p>
                    <p>
                      <strong>注册公告期号：</strong>{{ detail.zcggqh || '-' }}
                    </p>
                  </div>

                  <div class="flex ju-con-bet">

                    <p>
                      <strong>申请人名称（中）：</strong>
                      <router-link v-if="detail.client_id != 0" :class="{'col-blue':detail.appname}" :to="{
                  name: 'newlyBuild',
                    params: { id: detail.client_id  },
                }" target="_blank">
                        {{ detail.appname || '-' }}
                      </router-link>
                      <template v-else>
                        {{ detail.appname || '-' }}
                      </template>
                    </p>
                    <p>
                      <strong>申请人名称（英）：</strong>
                      <router-link v-if="detail.client_id != 0" :class="{'col-blue':detail.appnameen}" :to="{
                  name: 'newlyBuild',
                    params: { id: detail.client_id  },
                }" target="_blank">
                        {{ detail.appnameen || '-' }}
                      </router-link>
                      <template v-else>
                        {{ detail.appnameen || '-' }}
                      </template>
                    </p>
                  </div>
                  <div class="flex ju-con-bet">
                    <p>
                      <strong>申请人地址（中）：</strong>{{ detail.appaddr || '-' }}
                    </p>
                    <p>
                      <strong>申请人地址（英）：</strong>{{ detail.appaddren || '-' }}
                    </p>
                  </div>


                  <div class="word-break">
                    <strong>类似群组：</strong> {{ detail.tmgroup || '-' }}
                  </div>
                  <div class="">
                    <strong>商品/服务项目：</strong>
                    <span v-for="(item,index) in replace.tmgoodsdetail">
                       <s v-if="item.status != 0" class="grey">{{ item.name }}</s>
                            <font v-else>{{ item.name }}</font>
                      <font v-if="index != replace.tmgoodsdetail.length - 1">、</font>
                    </span>
                    <span v-if="replace.tmgoodsdetail == false">-</span>
                  </div>
                </div>
              </div>



              <div class="r-list">
                <h3 class="p10 line-h30 col-303 font-wbold bor-bottom">商标公告 </h3>
                <div class="p20 line-h32 col-606">

                  <p class="flex align-cen "  v-for="item in detail.notice">{{item.noticeDate}}{{'第' + item.issue + '期'}}《{{item.noticeName}}》{{'第'+ item.page + '页'}} <i @click="open(item.imgUrl)" class="pointer el-icon-search m-l5 col-blue"></i> </p>
                </div>
              </div>
            </div>
          </div>

        </div>

      </template>
    </el-skeleton>



  </div>
</template>

<script>
import storage from '@/utils/storage'
import {
  tmSearchDetail,
} from '@/api/personal.js'

export default {

  data() {
    return {
      skeletonList:storage.getList(),
      flowsUp: false,//商标流程的展开与收起
      replace: {//替代字段
        flows: [],
        flowsInit: [],
        tmgoodsdetail: [],
      },
      loading: true,//控制整个页面的骨架屏
      detail: [],//整个详情的数据
    };
  },

  created() {
    this.domesticDetails();

  },

  methods: {

    //返回顶部事件
    backTop() {
      const that = this;
      let ispeed = Math.floor(-that.scrollTop / 5);
      document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
    },
    //渲染详情事件
    async domesticDetails() {
      try {
        const res = await tmSearchDetail({id:this.$route.params.id})
        if (res && res.err_code === 0) {
          this.loading = false
          this.detail = res.data;
          if (this.detail.flows) {
            this.replace.flowsInit = this.detail.flows
          } else {
            this.replace.flowsInit = []
          }
          if (this.detail.tmgoods) {
            this.replace.tmgoodsdetail = this.detail.tmgoods
          } else {
            this.replace.tmgoodsdetail = false
          }

          if (res.data.flows.length > 5) {
            this.replace.flows = this.detail.flows.slice(0, 5)
          } else if (this.detail.flows.length != 0) {
            this.replace.flows = this.detail.flows
          } else {
            this.replace.flows = []
          }
          this.flowsSH = this.detail.flows != null;
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {
        this.loading = false
      }
    },

    open(url) {
      window.open(url, '_blank');
    },


  },
};
</script>

<style lang="less" scoped>

</style>
