<template>
    <div class="">
        纸质发票
    </div>
</template>
<script>
export default {
    
}
</script>

<style lang="less" scoped>
@import "../../style/InvoicePaper.less";
</style>