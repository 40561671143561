<template>
  <div class="home">

    <el-row>
      <el-col :lg="18" :sm="24" :xs="24">
        <ul class="home-t">
          <li>
            <router-link to="/personal/trademarkAdmin/">
              <el-row align="middle" justify="space-between" type="flex">
                <el-col>
                <span class="font-22 font-wbold ">
                  国内商标
                </span>
                  <h3>
                    <span>
                      <i v-if="loadingNum && user.type != 0" class="el-icon-loading"></i>
                      {{ (user.type == 0) ? '?' : tms }}
                    </span>件
                  </h3>
                </el-col>
                <el-col>
                  <p>
                    <img alt="" src="@/assets/img/personal/icon-home1.png">
                  </p>
                </el-col>
              </el-row>
            </router-link>

          </li>
          <li>
            <router-link to="/personal/parentAdmin/">
              <el-row align="middle" justify="space-between" type="flex">
                <el-col>
                <span class="font-22 font-wbold ">
                  专利
                </span>
                  <h3>
                    <span>
                      <i v-if="loadingNum && user.type != 0"
                               class="el-icon-loading"></i>
                      {{ user.type == 0 ? '?' : patents }}
                    </span>
                    项
                  </h3>
                </el-col>
                <el-col>
                  <p>
                    <img alt="" src="@/assets/img/personal/icon-home2.png">
                  </p>
                </el-col>
              </el-row>

            </router-link>
          </li>
          <li>
            <router-link to="/personal/copyrightAdmin/">
              <el-row align="middle" justify="space-between" type="flex">
                <el-col>
                <span class="font-22 font-wbold ">
                  著作权
                </span>
                  <h3><span><i v-if="loadingNum && user.type != 0" class="el-icon-loading"></i>  {{
                      user.type == 0 ? '?' : copyrights
                    }}</span>件</h3>
                </el-col>
                <el-col>
                  <p>
                    <img alt="" src="@/assets/img/personal/icon-home3.png">
                  </p>
                </el-col>
              </el-row>

            </router-link>

          </li>
          <li>
            <router-link to="/personal/apply/">
              <el-row align="middle" justify="space-between" type="flex">
                <el-col>
                <span class="font-22 font-wbold ">
                  委托业务
                </span>
                  <h3><span><i v-if="loadingNum" class="el-icon-loading"></i>  {{ applies }}</span>件</h3>
                </el-col>
                <el-col>
                  <p>
                    <img alt="" src="@/assets/img/personal/icon-home4.png">
                  </p>
                </el-col>
              </el-row>

            </router-link>

          </li>
        </ul>
        <div class="container">
          <div v-if="user.type === 0" class="container-vague">
            <div class="vague">
              <h3><span>企业版</span> 专业更高效</h3>
              <div class="">
                <img alt="" src="@/assets/img/personal/upgrade1.png">
                <p>知产数据全面覆盖</p>
              </div>
              <p class="phone"><span>升级咨询：</span>400-1010-989</p>
            </div>
          </div>
          <h3>商标概况</h3>
          <el-tabs v-model="activeName" @tab-click="tabs">
            <el-tab-pane v-for="(item) in tabList" :key="item.text" :label="item.text" :name="item.text">
            </el-tab-pane>
          </el-tabs>
          <el-row>
            <el-col :lg="14" :md="24" :sm="24" :xs="24">
              <div id="bar" class="box" style="width: 100%"></div>

            </el-col>
            <el-col :lg="10" :md="24" :sm="24" :xs="24">
              <div id="pie" class="box"></div>
            </el-col>
          </el-row>
        </div>
        <div class="home-b">
          <div class="quick">
            <h3>快捷入口</h3>
            <ul>
              <li>
                <router-link to="/personal/trademarkAdmin/upload">
                  <i class="el-icon-upload2"></i>
                  <p>补充官文</p>
                </router-link>
              </li>
              <li>
                <router-link to="/personal/atlas/">
                  <i class="el-icon-pie-chart"></i>
                  <p>商标图谱</p>
                </router-link>
              </li>
              <li>
                <router-link to="/personal/apply/">
                  <i class="el-icon-s-order"></i>
                  <p>委托业务</p>
                </router-link>
              </li>
              <li>
                <router-link to="/personal/trademarkAdmin/">
                  <i class="el-icon-s-management"></i>
                  <p>国内商标</p>
                </router-link>
              </li>


              <li>
                <router-link to="/personal/copyrightAdmin/">
                  <i class="el-icon-s-open"></i>
                  <p>著作权管理</p>
                </router-link>
              </li>
              <li>
                <router-link to="/personal/myMessage/">
                  <i class="el-icon-s-platform"></i>
                  <p>账号设置</p>
                </router-link>
              </li>

            </ul>
          </div>
          <div class="information">
            <h3>消息中心
              <router-link to="/personal/myMessageCenter/">全部 <i class="el-icon-arrow-right"></i></router-link>
            </h3>
            <el-collapse accordion @change="accordionChange">
              <el-empty v-if="accordionArr.length == 0" :image-size="80" description="暂无消息"></el-empty>
              <el-skeleton :loading="loadingInfo" animated>
                <template slot="template">
                  <el-collapse-item v-for="(item,index) in accordionArr" :key="index">
                    <template slot="title">
                      <el-row>
                        <el-col :lg="18" :md="19" :sm="20" :xl="20" :xs="19">
                          <p>
                            <el-skeleton-item style="width: 50%;" variant="text"/>
                          </p>
                        </el-col>
                        <el-col :lg="4" :md="4" :sm="3" :xl="4" :xs="4">
                          <span><el-skeleton-item style="width: 100px;" variant="text"/></span>
                        </el-col>
                      </el-row>
                    </template>
                  </el-collapse-item>
                </template>

                <el-collapse-item v-for="(item,index) in accordionArr" :key="index+100" :class="{on: item.read_at == 1}"
                                  :name="item.id">
                  <template slot="title">
                    <el-row>
                      <el-col :lg="18" :md="19" :sm="20" :xl="20" :xs="19">
                        <img alt="" src="@/assets/img/personal/icon-spot.png">
                        <p>{{ item.title }}</p>
                      </el-col>
                      <el-col :lg="4" :md="4" :sm="3" :xl="4" :xs="4">
                        <span>{{ item.created_at }}</span>
                      </el-col>
                    </el-row>
                  </template>

                  <div class="" v-if="item.content" >
                      <table v-if="item.content.thead">
                        <thead>
                        <tr>
                          <th v-for="th in item.content.thead">{{th}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr  v-for="tb in item.content.tbody">
                          <td  v-for="(td,index) in tb" >
                            <p v-if="td.toString().indexOf('href')" v-html="td"></p>
                            <p v-else  >{{td || '-'}}</p>
                          </td>

                        </tr>
                        </tbody>
                      </table>
                    <div class="" v-html="item.content.html"></div>
                  </div>
                  <div v-else class="text-cen col-909">暂无数据</div>
                </el-collapse-item>
              </el-skeleton>
            </el-collapse>
          </div>
        </div>
      </el-col>

      <el-col class="homer" :lg="6" :sm="24" :xs="24">
        <el-calendar ref="calendarRef" v-model="value">
          <template
              slot="dateCell"
              slot-scope="{date, data}">
            <p :class="data.isSelected ? 'is-selected' : ''" class="day">
              <span>{{ Number(data.day.split('-')[2]) }}</span>
              <strong
                  v-if="deadline.indexOf(Number(data.day.split('-')[2]))  != -1 && Number(data.day.split('-')[1]) == new Date(value).getMonth() + 1"
                  :class="{prev:new Date() > Date.parse(data.day),next:new Date() < Date.parse(data.day),on:( new Date().getMonth() + 1) == Number(data.day.split('-')[1]) && ( new Date().getDate()) == Number(data.day.split('-')[2])  }">.</strong>
            </p>

          </template>
        </el-calendar>
        <div class="dealt">
          <h3>待办事项
            <router-link v-if="user.type > 0" to="/personal/dealtMatter/">全部 <i class="el-icon-arrow-right"></i></router-link>
          </h3>
          <el-skeleton :count="1" :loading="loading" animated>
            <template slot="template">
              <ul>
                <li v-for="(item,i) in List" :key="i + 1000000">
                  <el-row>
                    <el-col :lg="5" :sm="4" :xl="4" :xs="3">
                      <el-skeleton-item style="width: 100%;" variant="p"/>
                      <el-skeleton-item variant="p"/>
                    </el-col>
                    <el-col :lg="11" :sm="14" :xl="14" :xs="15">
                      <el-skeleton-item variant="p"/>
                      <el-skeleton-item variant="p"/>
                    </el-col>
                    <el-col :lg="6" :sm="4" :xl="4" :xs="3">
                      <el-skeleton-item variant="p"/>
                    </el-col>
                    <el-col :lg="2" :sm="1" :xs="1">
                      <el-skeleton-item variant="p"/>
                    </el-col>
                  </el-row>
                </li>
              </ul>
            </template>
            <template>
              <ul>
                <el-empty v-if="dealtList.length == 0" :image-size="80" description="暂无待办事项"></el-empty>
                <li v-for="item in dealtList" :key="item.id" @click="complete(item.id)">
                  <el-row>
                    <el-col :lg="6" :md="4" :sm="5" :xl="5" :xs="4" class="">
                      <el-tag
                          :type="item.state == 1 ?  '' : (item.state == 2 ? 'info' : 'success' ) "
                          size="mini">
                        {{ item.state == 1 ? '进行中' : (item.state == 2 ? '已关闭' : '已完结') }}
                      </el-tag>
                      <p class="p-l5">{{ item.creator.name }} &nbsp;</p>
                    </el-col>
                    <el-col :lg="17" :md="19" :sm="15" :xl="17" :xs="16">
                      <h4 class="ellipse">{{ item.name }}</h4>
                      <p>{{ item.deadline }} 截止</p>
                    </el-col>
                    <el-col  :sm="1" :xs="1">
                      <i class="el-icon-arrow-right"></i>
                    </el-col>
                  </el-row>
                </li>
              </ul>
            </template>
          </el-skeleton>
          <router-link v-if="user.type > 0" :to="{name:'dealtMatterDetail',query:{id:null}}" target="_blank" class="plus">
            <el-button type="primary" size="small" icon="el-icon-plus" circle></el-button>
          </router-link>
          <div class="pos-abs w100 flex align-cen ju-con-cen" v-if="user.type == 0">
            <div class="vague w80 ba-col-fff text-cen p10">
              <h4 class="font-16"><span class="font-20 font-wbold col-blue m-r15">企业版</span> 专业更高效</h4>
              <div class="m20">
                <img class="m-b10" alt="" src="@/assets/img/personal/upgrade3.png">
                <p class="font-18 font-wbold col-000">线上协作高效便捷</p>
              </div>
              <p class="phone"><span>升级咨询：</span>400-1010-989</p>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-dialog
        :closeOnClickModal="false"
        :title="finish ? '新建待办' : '完成待办' "
        :visible.sync="newDealt"
        width="570px"
        @closed="close"
        @open="empty"
    >
      <el-form ref="filtersRef" :model="filters" :rules="filtersRules">
        <el-form-item label="标题" prop="title">
          <el-input v-model="filters.title" :readonly="!finishCrea" clearable placeholder="例如：商标续展相关事宜"
                    size="small"></el-input>
        </el-form-item>
        <el-form-item label="优先级" prop="priority">
          <el-radio-group v-model="filters.priority" :disabled="!finishCrea">
            <el-radio :label="1">一般</el-radio>
            <el-radio :label="2">重要</el-radio>
            <el-radio :label="3">紧急</el-radio>

          </el-radio-group>
        </el-form-item>
        <el-form-item label="执行人" prop="executor_id">
          <el-select v-model="filters.executor_id" :disabled="!finishCrea" multiple placeholder="请选择执行人" size="small">
            <el-option
                v-for="item in executorSelect"
                :key="item.id"
                :label="item.text"
                :value="item.id"
                clearable
                size="small"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参与人">
          <el-select v-model="filters.participant_id" :disabled="!finishCrea" multiple placeholder="请选择参与人"
                     size="small">
            <el-option
                v-for="item in executorSelect"
                :key="item.id"
                :label="item.text"
                :value="item.id"
                clearable
                size="small"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="截止日期" prop="deadline">
          <el-date-picker
              v-model="filters.deadline"
              :readonly="!finishCrea"
              placeholder="请选择截止日期"
              size="small"
              type="date"
              value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="描述说明">
          <el-input
              v-model="filters.summary"
              :readonly="!finishCrea"
              :rows="3"
              clearable
              placeholder="请填写描述说明"
              type="textarea"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <template v-if="finish">
          <el-button :disabled="loadSave" :icon="loadSave ? 'el-icon-loading' : 'el-icon-folder-checked'" size="small"
                     type="primary" @click="save('filtersRef')">新建</el-button>
        </template>

        <span v-else-if="!finish && filters.status == 0" class="">
           <el-button :disabled="loadCancel" :icon="loadCancel ? 'el-icon-loading' : 'el-icon-time'" size="small"
                      type="primary" @click="cancel">完成待办</el-button>

          <el-button v-if="finishCrea" :disabled="loadSave"
                     :icon="loadSave ?'el-icon-loading' :  'el-icon-folder-checked'"
                     plain
                     size="small" type="primary" @click="save('filtersRef')">保存</el-button>
        </span>

<el-button v-else icon="el-icon-close" size="small" type="primary"
           @click="newDealt = false,finish = true,finishCrea = true">关闭</el-button>
  </span>
    </el-dialog>

    <el-dialog
        :closeOnClickModal="false"
        :visible.sync="vDia"
        class="updateDia"
        width="800px">
      <span slot="title" class="">
        <div class="">
          <h3>v<span>{{ vIntroduce.version }}</span>：{{ vIntroduce.title }}</h3>
        </div>
  </span>
      <div class="update-body">
        <div class="body-date"><span>{{ vIntroduce.created_at }}</span></div>
        <div class="body-introduce" v-html="'v' + vIntroduce.version + '以功能优化为主，主要包括以下内容：<br/>' + vIntroduce.summary">
        </div>
        <router-link :to="{name: 'journalDetail',params: { id:vIntroduce.id}}" target="_blank">查看更多<i class="el-icon-arrow-right"></i></router-link>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import storage from '@/utils/storage.js'
import {
  homeCalendar,
  homeIpcounts,
  homeMessages,
  homeTmsummary,
  messagesRead,
  schedulesComplete,
  schedulesSave,
  updateLogs
} from '@/api/personal.js'
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core'
// 引入柱状图图表，图表后缀都为 Chart
import {BarChart, PieChart} from 'echarts/charts'
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
} from 'echarts/components'
// 标签自动布局，全局过渡动画等特性
import {LabelLayout, UniversalTransition} from 'echarts/features'
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {CanvasRenderer} from 'echarts/renderers'

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  PieChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer
]);
export default {
  name: "",
  components: {},
  mounted() {
    this.select()
    let fristBtn = document.querySelector(
        '.el-calendar__button-group .el-button-group>button:nth-of-type(1)'
    );
    let lastBtn = document.querySelector(
        '.el-calendar__button-group .el-button-group>button:nth-of-type(3)'
    );
    let fristIcon = document.createElement('i')
    let lastIcon = document.createElement('i')
    fristIcon.className = 'el-icon-arrow-left'
    lastIcon.className = 'el-icon-arrow-right'
    fristBtn.appendChild(fristIcon)
    lastBtn.appendChild(lastIcon)
  },
  data() {
    return {
      version: storage.getVersion(),
      vDia: false,
      vIntroduce: '',
      loadSave: false,
      loadCancel: false,
      user: storage.getUser(),
      submitDis: false,
      loadingNum: false,
      loadingInfo: true,
      List: storage.getList(),
      loading: true,//控制骨架屏的显示与隐藏
      tabList: [],
      copyrights: '',
      patents: '',
      tms: '',
      applies: '',
      accordionName: [],
      storage: storage,
      activeName: '',
      bar: [],
      pie: [],
      newDealt: false,
      filters: {
        id: 0,
        title: '',
        priority: 1,
        executor_id: [],
        participant_id: [],
        deadline: '',
        summary: '',
      },
      array: {
        ids: [],
      },
      finish: true,
      finishCrea: false,
      filtersRules: {
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'},
        ],
        priority: [
          {required: true, message: '请选择优先级', trigger: 'change'}
        ],

        executor_id: [
          {type: 'array', required: true, message: ' ', trigger: 'blur'}
        ],

      },
      deadline: [],
      executorSelect: [],
      accordionArr: [],
      dealtList: [],
      homeTmsummaryid: {
        id: 0
      },
      monthfilters: {
        month: '',
      },
      value: new Date(),
      newDate: '',
      DateList: [],
    }
  },
  watch: {
    'value': function (newVal, oldVal) {
    if(this.user.type != 0){
      this.value = new Date(newVal).getFullYear() + '-' + (new Date(newVal).getMonth() + 1) + '-' + new Date(newVal).getDate() + ''
      this.filters.deadline = newVal
      if (new Date(newVal).getMonth() != new Date(oldVal).getMonth()) {
        this.deadline = []
        this.DateList = []
        this.loading = true
        this.calendarList(new Date(newVal).getDate())
      } else {
        if (this.deadline.indexOf(new Date(newVal).getDate()) == -1) {
          this.dealtList = []

        } else {
          this.DateList.forEach((item) => {
            if (new Date(newVal).getDate() == item.day) {
              this.dealtList = item.schedules
            }
          })
        }


      }
    }

    },
  },
  created() {
    this.init()
    this.ipcounts()
    if(this.user.type != 0){
      this.calendarList(new Date().getDate())
    }
    else{
      this.dealtList = [{
        state:1,
        creator:{
          name:'哈哈',
        },
        name:'哒哒哒哒',
        deadline:'2020-01-29'

      },{
        state:1,
        creator:{
          name:'哈哈',
        },
        name:'哒哒哒哒',
        deadline:'2020-01-29'

      },
        {
          state:1,
          creator:{
            name:'哈哈',
          },
          name:'哒哒哒哒',
          deadline:'2020-01-29'

        },
        {
          state:1,
          creator:{
            name:'哈哈',
          },
          name:'哒哒哒哒',
          deadline:'2020-01-29'

        },
        {
          state:1,
          creator:{
            name:'哈哈',
          },
          name:'哒哒哒哒',
          deadline:'2020-01-29'

        },
        {
          state:1,
          creator:{
            name:'哈哈',
          },
          name:'哒哒哒哒',
          deadline:'2020-01-29'

        },
        {
          state:1,
          creator:{
            name:'哈哈',
          },
          name:'哒哒哒哒',
          deadline:'2020-01-29'

        },
      ]
    }
  },
  methods: {
    async init() {
      try {
        const res = await updateLogs()
        if (res && res.err_code === 0 && res.data.list.length != 0) {
          this.vIntroduce = res.data.list[0]
          if (this.version != res.data.list[0].version ) {
            this.vDia = true
            storage.setVersion(res.data.list[0].version)
          }
        }
      } finally {

      }
    },
    // 下拉
    async select() {
      let s = await storage.getSelects('app_names', 'users')
      if(s.app_names.length > 10){
        this.tabList = s.app_names.splice(0,10)
      }else{
        this.tabList = s.app_names
      }

      this.executorSelect = s.users
      this.tabList.forEach((item, i) => {
        if (storage.getUser().company == item.text) {
          this.tabList.splice(i, 1, ...this.tabList.splice(0, 1, this.tabList[i]))
          this.activeName = item.text
          this.homeTmsummaryid.id = item.id
        }
      })
      if(this.tabList[0].text != storage.getUser().company){
        this.activeName =  this.tabList[0].text
        this.homeTmsummaryid.id = this.tabList[0].id
      }
      this.initChart()

    },
    // 图表数据
    async ipcounts() {
      this.loadingInfo = true
     this.loadingNum = true
      this.accordionArr = storage.getList()
      try {
        const res = await homeIpcounts()
        const result = await homeMessages()

        if (res && res.err_code === 0) {
          this.copyrights = res.data.copyrights
          this.patents = res.data.patents
          this.tms = res.data.tms
          this.applies = res.data.applies
          this.loadingNum = false
        }
        if (result && result.err_code === 0) {
          this.accordionArr = result.data
          this.loadingInfo = false
        }
      } finally {
        this.loadingInfo = false
         this.loadingNum = false
      }
    },
    // tab切换
    tabs(tab, el) {
      this.tabList.forEach((item, i) => {
        if (tab.index == i) {
          this.homeTmsummaryid.id = item.id
          this.initChart()
        }
      })

    },
    // 图表
    async initChart() {
      let myBar = echarts.init(document.getElementById('bar'));
      let myPie = echarts.init(document.getElementById('pie'));
      myBar.showLoading()
      myPie.showLoading()
      try {
        if (this.user.type !== 0) {
          const result = await homeTmsummary(this.homeTmsummaryid)
          if (result && result.err_code === 0) {
            this.bar = result.data.bar
            this.pie = result.data.pie
          }
        } else {
          this.bar = [
            {
              name: "张三",
              value: 80
            },
            {
              name: "张散",
              value: 45
            },
            {
              name: "张伞",
              value: 10
            },
            {
              name: "章三",
              value: 10
            },
            {
              name: "章叁",
              value: 8
            },
            {
              name: "张叁",
              value: 18
            },
            {
              name: "张弎",
              value: 17
            },
            {
              name: "章弎",
              value: 6
            },
            {
              name: "张糁",
              value: 5
            },
            {
              name: "章糁",
              value: 4
            },
          ]
          this.pie = [
            {
              name: "第35类",
              value: 12
            }, {
              name: "第02类",
              value: 11
            }, {
              name: "第40类",
              value: 11
            }, {
              name: "第03类",
              value: 19
            }, {
              name: "第25类",
              value: 9
            }, {
              name: "第16类",
              value: 18
            }, {
              name: "第38类",
              value: 8
            }, {
              name: "第26类",
              value: 8
            }, {
              name: "第29类",
              value: 7
            }, {
              name: "第31类",
              value: 15
            },

          ]
        }
        myBar.hideLoading()
        myPie.hideLoading()
        myBar.setOption(this.setOption('申请量排名Top 20'));
        myPie.setOption(this.setOption1('类别统计Top 15'));
      } finally {
        this.loading = false
      }

      window.addEventListener("resize", () => {
        myBar.resize();
      });
      window.addEventListener("resize", () => {
        myPie.resize();
      });
    },
    // 柱状图
    setOption(title) {
      let valX = []
      let valY = []
      let barMaxWidth = ''
      this.bar.forEach((item) => {
        valX.push(item.name)
        valY.push(item.value)
      })
      if(this.bar.length < 10){
        barMaxWidth = '20%'
      }else if(this.bar.length < 5){
        barMaxWidth = '30%'
      }
      return {
        title: {text: title},
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '1%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          axisLabel: {
            interval: 0,//横轴信息全部显示
            rotate: -20,//-15度角倾斜显示
            overflow: 'truncate',
            width: 50,
          },
          data: valX,

        },
        yAxis: {},
        series: [
          {
            name: "数量",
            type: "bar",
            barWidth:barMaxWidth,
            barMaxWidth:barMaxWidth,
            data: valY
          }
        ]
      };
    },
    // 饼图
    setOption1(title) {
      let option = {
        title: {
          text: title,
          left: '0',
        },
        tooltip: {
          trigger: 'item'
        },

        series: [
          {
            type: 'pie',
            radius: ['30%', '60%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },

            data: this.pie,
          }
        ]
      };
      return option;
    },
// 抽屉是否已读
    accordionChange(val) {
      this.accordionArr.forEach(async (item) => {
        if (item.id == val) {
          if (item.read_at == 0) {
            item.read_at = 1
            this.accordionName.push(val)
            try {
              const res = await messagesRead({ids: this.accordionName})
              if (res && res.err_code === 0) {
                console.log(res)
              }
            } finally {

            }
          }

        }
      })
    },

    // 日历
    async calendarList(val) {

        if (new Date(this.value).getMonth() < 9) {
          this.monthfilters.month = new Date(this.value).getFullYear() + '-' + '0' + (new Date(this.value).getMonth() + 1)
        } else {
          this.monthfilters.month = new Date(this.value).getFullYear() + '-' + (new Date(this.value).getMonth() + 1)
        }
        if (val) {
          this.filters.deadline = val
        } else {
          this.filters.deadline = this.value
        }
        try {
          const res = await homeCalendar(this.monthfilters)
          if (res && res.err_code === 0) {
            this.loading = false;
            res.data.forEach((item, i) => {
              if (item.schedules.length != 0) {
                this.deadline.push(item.day)
                this.DateList.push(item)
              } else {
                this.dealtList = []
              }
            })

            this.DateList.forEach((item) => {
              if (val == item.day) {
                this.dealtList = item.schedules
              }
            })
            // if (this.filters.title != '') {
            //   this.dealtList.push(this.filters)
            // }
          }
        } finally {
          this.loading = false;
        }


    },

    // 待办事项列表点击事件
    complete(item) {
      let routerJump = this.$router.resolve({name:"dealtMatterDetail",query:{id:item}})
      window.open(routerJump.href, '_blank')
      // this.newDealt = true
      // this.loadCancel = false
      // let copy = Object.assign({}, item)
      // this.filters = copy
      // this.finish = false
      // if (this.filters.status == 0 && this.filters.creator == storage.getUser().nickname) {
      //   this.finishCrea = true
      // } else {
      //   this.finishCrea = false
      // }
    },
    // 完成待办事项对话框
    async cancel() {
      this.array.ids.push(this.filters.id)
      try {
        this.loadCancel = true
        const res = await schedulesComplete(this.array)
        if (res && res.err_code === 0) {
          this.$message({
            type: "success",
            message: "完成待办！",
          });
          this.dealtList.forEach((item) => {
            if (this.array.ids.indexOf(item.id) != -1) {
              item.status = 1
            }
          })
          this.DateList.forEach((item) => {
            if (this.array.ids.indexOf(item.id) != -1) {
              item.status = 1
            }
          })
          this.loadCancel = false
          this.newDealt = false
        }
      } catch (e) {
        this.loadCancel = false
      }
    },

    // 新建待办事项
    save(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.loadSave = true
            const res = await schedulesSave(this.filters)
            if (res && res.err_code === 0) {
              this.loadSave = false
              this.newDealt = false
              if (this.finish) {
                // this.calendarList(this.filters.deadline)
                let strExecutor = []
                this.executorSelect.forEach((item) => {
                  if (this.filters.executor_id.indexOf(item.id) != -1) {
                    strExecutor.push(item.text)
                  }
                })
                this.filters.executor = strExecutor[0] + '等'
                this.filters.status = 0
                this.deadline.push(this.filters.deadline)
                this.DateList.push(this.filters)
                this.dealtList.push(this.filters)
                this.$message({
                  type: "success",
                  message: "新建成功！",
                });
              } else {
                let index;
                this.dealtList.forEach((item, i) => {
                  if (this.filters.id == item.id) {
                    this.$set(this.dealtList, i, this.filters)
                    if (this.filters.deadline != item.deadline) {
                      index = i
                      this.dealtList.splice(index, 1)
                      if (this.filters.deadline.split('-')[2] == this.value.split('-')[2]) {
                        if (this.deadline.indexOf(new Date(this.filters.deadline).getDate()) == -1) {
                          this.deadline.push(new Date(this.filters.deadline).getDate())
                          this.DateList.push({
                            day: new Date(this.filters.deadline).getDate(),
                            schedules: [this.filters]
                          })
                        } else {
                          this.DateList[this.deadline.indexOf(new Date(this.filters.deadline).getDate())].schedules.push(this.filters)
                        }
                      }

                      if (this.dealtList.length == 0) {
                        this.deadline.splice(this.deadline.indexOf(new Date(item.deadline).getDate()), 1)
                      }

                    }
                    this.$message({
                      type: "success",
                      message: "保存成功！",
                    });
                  }
                })
              }
            }
          } catch (e) {
            this.loadSave = false
          }
        } else {
          return false
        }
      })

    },
    // 清空
    empty() {
      if (this.finish) {
        this.filters = this.$options.data().filters;
        this.filters.deadline = new Date(this.value).getFullYear() + '-' + (new Date(this.value).getMonth() + 1) + '-' + new Date(this.value).getDate()

      }

    },
    close() {
      if (this.finish) {
        this.$refs.filtersRef.resetFields();
      }
    }

  }
};
</script>
<style lang="less" scoped>
@import "../../style/personalHome.less";
</style>
