
<template>
  <div class="">
    <h2 class="clearfix">
      网站备案<span v-if="websiteFilingList.length != 0">{{ pageSize }} </span>
    </h2>
    <div class="mainrb">
      <div class="page-up">
        <el-pagination
            background
            size="small"
            layout="prev, next"
            @current-change="websiteFilingLists"
            :current-page.sync="filters.page"
            :total="pageSize">
        </el-pagination>
      </div>
      <el-skeleton  :loading="loading" animated :count="1">
        <template slot="template">
          <el-table :data="websiteFilingList" >
            <el-table-column  width="50" label="序号">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column  label="网站备案/许可证号">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="网站名称">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="网址">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="域名">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column  label="公司名称">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column  label="审核日期">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-table :data="websiteFilingList" height="100%" :class="{ios:$store.state.heights}">
          <template slot="empty">
            <el-empty :image-size="80"></el-empty>
          </template>
          <el-table-column type="index" width="50" label="序号">
          </el-table-column>
          <el-table-column  label="网站备案/许可证号" :show-overflow-tooltip="true"  prop="license"> </el-table-column>
          <el-table-column label="网站名称" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{scope.row.web_name || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="网址">
            <template slot-scope="scope">
                <a :href="'http://' + scope.row.website" target="_black">
                  {{ scope.row.website }}
                </a>
            </template>
          </el-table-column>
          <el-table-column label="域名" :show-overflow-tooltip="true">
            <template slot-scope="scope">
                <a
                    v-for="item in scope.row.domains"
                    :href="'http://' + scope.row.domains"
                    target="_black"
                    :key="item.id"
                >
                  {{ item }}
                </a>
            </template>
          </el-table-column>
          <el-table-column  label="公司名称" :show-overflow-tooltip="true" prop="company_name"> </el-table-column>
          <el-table-column  label="审核日期" >
          <template slot-scope="scope">
            {{scope.row.examine_date || '-'}}
          </template>
          </el-table-column>
        </el-table>
      </el-skeleton>

      <el-pagination
        @size-change="changeRowNum"
        @current-change="websiteFilingLists(backTop)"
        :current-page.sync="filters.page"
        :page-sizes="[30, 50, 100]"
        :page-size="filters.per_page"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageSize"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import { websiteFiling } from '@/api/personal.js'
export default {
  data() {
    return {
      backTop:true,
      loading: true,//控制列表的骨架屏
      websiteFilingList: [],//列表数组
      pageSize: 0,
      filters:{
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
      },
    };
  },

  created() {
    this.websiteFilingLists();
  },
  methods: {
    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.websiteFilingLists(this.backTop);
    },
    //渲染列表事件
    async websiteFilingLists(backTop) {
      this.loading = true;
      this.websiteFilingList = storage.getList();
      try{
        const res = await websiteFiling(this.filters)
        if(res && res.err_code === 0){
          if(backTop === true){
            storage.backTop()
          }
          this.loading = false;
          this.pageSize = res.data.total;
          this.websiteFilingList = res.data.list;

        }else {
          this.websiteFilingList = []
        }
      }finally{
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/websiteFiling.less";
</style>
