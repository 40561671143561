<template>
  <div class="">
    <div  class="search ">
      <div class="search_inp pos-abs text-cen">
        <img src="@/assets/img/personal/search-logo.png" class="search_logo m-b50 " alt="">
        <div class="search  ba-col-fff bor-rad10"
        >

          <div class="search_tab plf20 flex flex-cen col-606 m-b15">
            <span :class="{on:item.type == filters.type}" @click="filters.type = item.type" class="pointer" v-for="item in tabs">{{ item.name }}</span>

          </div>
          <div class="inp" :class="{on:searchVid}">
            <el-input
                v-model.trim="filters.keyword"
                size="small"
                placeholder="请输入商标名称、商标号或申请人" >
              <i
                  class="el-icon-search el-input__icon"
                  slot="prefix">
              </i>

                  <el-button size="small" slot="suffix" type="primary" @click="searchKey">搜索</el-button>


            </el-input>
          </div>
        </div>

        <router-link :to="{name:'queryIntcls'}"  class="col-blue pointer" >商品分类表 >></router-link>
      </div>

      <div class="pos-abs tel">
        更多服务，请咨询 <span class="col-blue"> 400 1010 989</span>
      </div>
    </div>


  </div>
</template>

<script>
import storage from '@/utils/storage'
export default {
  name: "Objection",
  data() {
    return {
      searchVid:false,

      filters: {
        type:'name',//类型
        keyword: "", //关键字
      },
      tabs:[
        {name:'相同',type:'name'},
        {name:'近似',type:'all'},
        {name:'商标号',type:'data_id'},
        {name:'申请人',type:'applicant'},
      ],


    };
  },


  methods: {
    // 关键字搜索
    async searchKey() {
      if (this.filters.keyword) {
        this.searchVid = false
        this.$router.push({
          name: 'queryKeyword',
          params: {keyword:this.filters.keyword,type:this.filters.type},
        })

      } else {
        this.searchVid = true
      }

    },


  }
};
</script>

<style lang="less" scoped>
.search{
  .search_inp{

    left: 50%;
    top: 40%;
    transform: translate(-50%,-50%);
    .search_logo{
      width: 200px;
    }
    .search{
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
      width: 500px;
      margin-bottom: 35px;
      padding: 20px 10px;
      .search_tab{
        span{
          padding-bottom: 6px;
        }
        span.on{
          color: #409EFF;
          border-bottom: 2px solid #409EFF;
        }
      }
      ::v-deep .inp{

        .el-input__inner{
          border-radius: 16px;
          background: #F4F4F5;
          border: none;
        }
        .el-input__suffix{
          right: 1px;
          top: 1px;
          .el-button{
            border-radius: 16px;
            height: calc(100% - 2px);
          }
        }
      }
      ::v-deep  .inp.on{
        input{
          border: 1px solid #f56c6c;
        }

      }
    }

  }
  .tel{
    left: 50%;
    bottom: 10%;
    transform: translateX(-50%);
  }
}

</style>