<template>
  <div class="detail">
    <el-skeleton  :loading="loading" animated :count="1">
      <template slot="template">
        <h2 class="clearfix">
          <el-skeleton-item variant="h3" style="width:500px" />
        </h2>
        <div class="patentDetailsT">
          <a
          ><span>基本信息</span>
          </a>
          <a ><span

              :class="{ on: patentDetailsTNum == 2 }"
          >法律状态</span
          ></a>
          <a ><span

              :class="{ on: patentDetailsTNum == 3 }"
          >附图</span
          ></a>

        </div>
        <div class="patentDetails-information" >
          <h3>基本信息</h3>
          <table width="100%">
            <tr>
              <td>专利名称</td>
              <td><el-skeleton-item variant="p"  /></td>
              <td>申请号</td>
              <td><el-skeleton-item variant="p"/></td>
            </tr>
            <tr>
              <td>申请日</td>
              <td><el-skeleton-item variant="p"/></td>
              <td>公开(公告)号</td>
              <td><el-skeleton-item variant="p"/></td>

            </tr>
            <tr>

              <td>优先权日</td>
              <td><el-skeleton-item variant="p"/></td>
              <td>公开(公告)日</td>
              <td><el-skeleton-item variant="p"/></td>
            </tr>
            <tr>
              <td>优先权号</td>
              <td><el-skeleton-item variant="p"/></td>
              <td>主分类号</td>
              <td><el-skeleton-item variant="p"/></td>
            </tr>
            <tr>
              <td>专利类型</td>
              <td><el-skeleton-item variant="p"/></td>
              <td>简单法律状态</td>
              <td><el-skeleton-item variant="p"/></td>
            </tr>
            <tr>
              <td>全部分类号</td>
              <td><el-skeleton-item variant="p"/></td>
            </tr>
          </table>
          <h3>申请/代理机构</h3>
          <table width="100%">
            <tr>
              <td>申请(专利权)人</td>
              <td><el-skeleton-item variant="p"/></td>
              <td>发明人</td>
              <td><el-skeleton-item variant="p"/></td>
            </tr>
            <tr>
              <td>申请人地址</td>
              <td colspan='3'><el-skeleton-item variant="p"/></td>

            </tr>
            <tr>
              <td>代理机构</td>
              <td><el-skeleton-item variant="p"/></td>
              <td>代理人</td>
              <td><el-skeleton-item variant="p"/></td>
            </tr>
          </table>
          <h3>摘要</h3>
          <p>
            <el-skeleton-item variant="p"/>
          </p>
        </div>
        <div class="patentDetails-status" >
          <h3>法律状态</h3>
          <el-steps direction="vertical" :active="1">
            <el-skeleton-item variant="p"  />
          </el-steps>
        </div>
        <div class="patentDetails-drawings" style="background: #FFF;
  padding: 5px 20px;" >
          <h3>摘要附图</h3>
          <el-skeleton-item
              variant="image"
              style="width: 400px; height: 267px;"
          />
        </div>
      </template>
    <h2>专利详情
<!--      <el-button type="primary" class="fr" size="small" icon="el-icon-download">下载</el-button> -->
    </h2>
    <div class="patentDetailsT">
      <a
          href="#patentDetails-information"
        @click="patentDetailsTNum = 1"
        :class="{ on: patentDetailsTNum == 1 }"
        ><span>基本信息</span></a
      >
      <a
          href="#patentDetails-status"
          @click="patentDetailsTNum = 2"
        :class="{ on: patentDetailsTNum == 2 }"
        ><span>法律状态</span></a
      >
      <a
          href="#patentDetails-drawings"
          @click="patentDetailsTNum = 3"
        :class="{ on: patentDetailsTNum == 3 }"
        ><span>附图</span></a
      >
    </div>
    <div class="patentDetails-information"  id="patentDetails-information">
      <h3>基本信息</h3>
      <table width="100%">
        <tr>
          <td>专利名称</td>
          <td>{{patentDetails.title}}</td>
          <td>申请号</td>
          <td>{{patentDetails.appnumber}}</td>

        </tr>
        <tr>
          <td>申请日</td>
          <td>{{patentDetails.application_time}}</td>
          <td>公开(公告)号</td>
          <td>{{patentDetails.pubnumber}}</td>

        </tr>
        <tr>
          <td>优先权号</td>
          <td>-</td>
          <td>公开(公告)日</td>

          <td>{{patentDetails.pub_date}}</td>
        </tr>
        <tr>
          <td>优先权日</td>
          <td>-</td>
          <td>主分类号</td>
          <td>{{patentDetails.main_cat_num}}</td>
        </tr>
        <tr>
          <td>专利类型</td>
          <td>{{patentDetails.patent_type}}</td>
          <td>简单法律状态</td>
          <td><el-tag size="mini">{{patentDetails.law}}</el-tag></td>
        </tr>
        <tr>
          <td>全部分类号</td>
          <td colspan="3">{{patentDetails.all_cat_num}}</td>
        </tr>
      </table>
      <h3>申请/代理机构</h3>
      <table width="100%">
        <tr>
          <td>申请(专利权)人</td>
          <td> <router-link v-if="patentDetails.client_id  != 0" :to="{
                  name: 'newlyBuild',
                    params: { id: patentDetails.client_id  },
                }" target="_blank" style="color: #3291F8;">
            {{patentDetails.applicantname}}
          </router-link>
            <template v-else>
              {{patentDetails.applicantname}}
            </template>
          </td>
          <td>发明人</td>
          <td>{{patentDetails.inventor}}</td>
        </tr>
        <tr>
          <td>申请人地址</td>
          <td colspan='3'>{{patentDetails.address}}</td>
          
        </tr>
        <tr>
          <td>代理机构</td>
          <td><span>{{patentDetails.agency}} </span></td>
          <td>代理人</td>
          <td>{{patentDetails.agent}}</td>
        </tr>
      </table>
      <h3>摘要</h3>
      <p>
        {{patentDetails.abstracts}}
      </p>
    </div>
    <div class="patentDetails-status" id="patentDetails-status" >
      <h3>法律状态</h3>
      <el-steps direction="vertical" :active="1">
        <el-step v-for="(item,index) in patentDetails.law_status" :key="index" :title="item.detail" :description="item.date"></el-step>
      </el-steps>
    </div>
    <div class="patentDetails-drawings" id="patentDetails-drawings" style="background: #FFF;
  padding: 5px 20px;" >
      <h3>摘要附图</h3>
      <el-image
          v-if="imgUrl"
          :src="imgUrl"
          :preview-src-list="patentDetails.img_url">
      </el-image>
    </div>
    </el-skeleton>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import { patentDetai } from '@/api/personal.js'
export default {
  data() {
    return {
      loading:true,//控制详情的骨架屏
      patentDetailsTNum: 1,//控制整体的tab切换
      patentDetails:[],
      imgUrl:'',
      parameter: {
        id:''
      },
    };
  },
  created() {

    this.patentDetail();
  },
  methods: {
    async patentDetail() {
      this.parameter.id = this.$route.params.id
      try{
        const res = await patentDetai(this.parameter)
        if(res && res.err_code === 0){
          this.loading = false
          this.patentDetails = res.data
          this.imgUrl = res.data.img_url[0]
          console.log(res.data)

          storage.backTop()
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      }finally{
        this.loading = false
        storage.backTop()
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/patentDetails.less";
</style>