<template>
  <div class="detali">
    <div class="detali-body">
      <h3>
        <span>{{ detail.version }}</span>
        <span>{{ detail.title }}</span>
      </h3>
      <h4>
        <p>
          <span>{{ detail.created_at}}</span>
        </p>
        <strong></strong>
      </h4>

      <div class="content" v-html="detail.content">

      </div>
    </div>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import {updateLogDetail} from '@/api/personal.js'
export default {
  data(){
    return{
      detail:{}
    }
  },
  created() {
    this.details()
  },
  methods:{
    async details(){
      try {
        const res = await updateLogDetail({id:this.$route.params.id})
        if(res && res.err_code === 0){
          this.detail = res.data
          console.log(res)
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      }finally {

      }
    }
  }
}
</script>

<style lang="less" scoped>
.detali{
  min-height:  calc(100vh - 100px);
  padding-bottom: 100px;
  margin-top: 10px;
  background: url("../../assets/img/personal/ba-client.png");
  background-size: 100% 100%;
  .detali-body{
    padding: 60px 40px;
    margin: 60px auto 0 auto;
    background: #fff;
    box-shadow: 0px 0px 7px 1px rgba(211, 230, 249, 100);
    border-radius: 5px;
    h3{
      color: #3894FF;
      font-size: 22px;
      font-weight: bold;
      span{
        margin-right: 20px;
      }
    }
    h4{
      margin: 20px 0 30px 0;
      color: #C1C1C1;
      font-size: 12px;
      strong{
        display: inline-block;
        width: 100px;
        height: 1px;
        background: #C1C1C1;
      }
    }
    .content {
      line-height: 2em;
    }
    ::v-deep img{
      max-width: 100%;
    }
  }
}
@media screen and (max-width: 768px){
  .detali-body{
    width: 90%;
  }
}
@media screen and (min-width: 768px){
  .detali-body{
    width: 580px;
  }
}
@media screen and (min-width: 992px){
  .detali-body{
    width: 750px;
  }
}
//@media screen and (min-width: 1200px){
//  .detali-body{
//    width: 850px;
//  }
//}
@media screen and (min-width: 1200px){
  .detali-body{
    width: 1020px;
  }
}
</style>
