<template>
  <div class="">
    <h2>著作权登记<span v-if="applyList.length != 0">{{ pageSize }} </span></h2>
    <form ref="filters"  v-on:submit.prevent>
      <el-row :gutter="10" class="formInput" :class="{on:formOpen}">
        <el-col  :md="7" :sm="10" :xs="20">
          <el-row align="middle" type="flex" :gutter="10" justify="space-between">
            <el-col :xl="4" :lg="6" :md="7" :sm="7" :xs="5">
              <strong>作品名称</strong>
            </el-col>
            <el-col :xl="20" :lg="18" :md="17" :sm="17" :xs="19">
              <el-input v-model="filters.name" size="small" placeholder="请输入作品名称" clearable ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col  :md="7" :sm="10" :xs="20">
          <el-row align="middle" type="flex" :gutter="10" justify="space-between">
            <el-col :xl="4" :lg="6" :md="7" :sm="7" :xs="5">
              <strong>申请人</strong>
            </el-col>
            <el-col :xl="20" :lg="18" :md="17" :sm="17" :xs="19">
              <el-select
                  v-model="filters.appname"
                  clearable
                  filterable
                  placeholder="请选择申请人"
                  size="small"
              >
                <el-option
                    v-for="item in appSelect"
                    :key="item.id"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col  :md="7" :sm="10" :xs="20">
          <el-row align="middle" type="flex" :gutter="10" justify="space-between">
            <el-col :xl="4" :lg="6" :md="7" :sm="7" :xs="5">
              <strong>作品类型</strong>
            </el-col>
            <el-col :xl="20" :lg="18" :md="17" :sm="17" :xs="19">
              <el-select
                  v-model="filters.type"
                  clearable
                  filterable
                  placeholder="请选择作品类型"
                  size="small"
              >
                <el-option
                    v-for="item in typeSelect"
                    :key="item.id"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col  :md="7" :sm="10" :xs="20">
          <el-row align="middle" type="flex" :gutter="10" justify="space-between">
            <el-col :xl="4" :lg="6" :md="7" :sm="7" :xs="5">
              <strong>当前进度</strong>
            </el-col>
            <el-col :xl="20" :lg="18" :md="17" :sm="17" :xs="19">
              <el-select
                  v-model="filters.status"
                  clearable
                  filterable
                  placeholder="请选择当前进度"
                  size="small"
              >
                <el-option
                    v-for="item in intclsSelect"
                    :key="item.id"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col  :md="7" :sm="10" :xs="20">
          <el-row align="middle" type="flex" :gutter="10" justify="space-between">
            <el-col :xl="4" :lg="6" :md="7" :sm="7" :xs="5">
              <strong>登记号</strong>
            </el-col>
            <el-col :xl="20" :lg="18" :md="17" :sm="17" :xs="19">
              <el-input v-model="filters.register_number" size="small" placeholder="请输入登记号" clearable ></el-input>

            </el-col>
          </el-row>
        </el-col>
        <el-col  :md="7" :sm="10" :xs="20">
          <el-row align="middle" type="flex" :gutter="10" justify="space-between">
            <el-col :xl="4" :lg="6" :md="7" :sm="7" :xs="5">
              <strong>登记日期</strong>
            </el-col>
            <el-col :xl="20" :lg="18" :md="17" :sm="17" :xs="19">
              <el-date-picker
                  v-model="filters.register_at"
                  :picker-options="pickerOptions"
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>
        <el-col  :md="7" :sm="10" :xs="20">
          <el-row align="middle" type="flex" :gutter="10" justify="space-between">
            <el-col :xl="4" :lg="6" :md="7" :sm="7" :xs="5">
              <strong>报送日期</strong>
            </el-col>
            <el-col :xl="20" :lg="18" :md="17" :sm="17" :xs="19">
              <el-date-picker
                  v-model="filters.deliver_at"
                  :picker-options="pickerOptions"
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="formOpen">
        <el-col  :span="24" :class="{on:formOpen}" >
          <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
        </el-col>
      </el-row>

      <el-button class="search" size="small" type="primary" @click="perindexList(filters.page = 1)" icon="el-icon-search"
      >查询
      </el-button>
      <el-button class="reset" :class="{on:formOpen}" size="small" type="reset" @click="reset" icon="el-icon-refresh-left"
      >重置
      </el-button
      >
    </form>
    <div class="mainrb">
      <div class="listT p10" >
        <el-button :loading="down" icon="el-icon-document" :disabled="applyList.length == 0 || loading" size="small" type="primary" @click="diaClick">
          {{down ? '导出中' :  '导出数据' }}
        </el-button>

      </div>
      <el-skeleton :loading="loading" animated :count="1">
        <template slot="template">
          <el-table
              :class="{on:!formOpen,ios:$store.state.heights}"
              height="100%"
              :data="lists"
              style="width: 100%">
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table :data="props.row.documents" style="width: 80%">
                  <el-empty description="描述文字"></el-empty>
                  <el-table-column
                      label="序号"
                      type="index"
                      width="50">
                  </el-table-column>
                  <el-table-column
                      label="文件名称"
                      prop="name">
                  </el-table-column>
                  <el-table-column
                      label="创建日期"
                      prop="made_at">
                  </el-table-column>
                  <el-table-column
                      label="操作">
                    <template slot-scope="scope">
                      <router-link :to="scope.row.url">
                        <el-button size="small" type="primary">下载</el-button>
                      </router-link>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column
                label="序号" >
              <template>
                <el-skeleton-item variant="p" class="w50" />
              </template>
            </el-table-column>
            <el-table-column
                label="作品名称">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="作品类型">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column  label="金额" v-if="user.type != 0">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="当前进度">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="商标类别" >
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="登记号">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="登记日期" >
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="报送日期">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="操作">
              <template slot-scope="scope">
                <template>
                  <el-skeleton-item variant="p" class="w50"/>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-table
            :class="{on:!formOpen,ios:$store.state.heights}"
            :data="applyList"
            style="width: 100%"
            height="100%">
          <template slot="empty">
            <el-empty :image-size="80"></el-empty>
          </template>
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table :data="props.row.documents" style="width: 94%">
                <el-table-column
                    label="序号"
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column
                    label="官文类型" :show-overflow-tooltip="true">
                  <template slot-scope="scope">
                    <span v-if="user.type == 0 && Math.floor((new Date().getTime() - new Date(scope.row.created_at).getTime())/(24 * 60 * 60 * 1000)) > 30" class="col-blue" @click="over">{{ scope.row.matter }}</span>
                    <a
                        v-else
                        class="ellipsis"
                        :href="`${scope.row.url}&preview=true`"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                      {{ scope.row.matter }}
                    </a>
                  </template>
                </el-table-column>
                <el-table-column
                    label="收文日期"
                    prop="made_at">
                </el-table-column>
                <el-table-column
                    label="上传日期"
                    prop="created_at">
                </el-table-column>
                <el-table-column
                    label="操作">
                  <template slot-scope="scope">
                    <el-button v-if="user.type == 0 && Math.floor((new Date().getTime() - new Date(scope.row.created_at).getTime())/(24 * 60 * 60 * 1000)) > 30" icon="el-icon-download" size="small" type="text" @click="over">下载
                    </el-button>
                    <el-button v-else icon="el-icon-download" size="small" type="text" @click="open(props.row,scope.row)">下载
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column
              label="序号" type="index">
          </el-table-column>
          <el-table-column width="320"
              label="作品名称">
            <template slot-scope="scope">
              <div class="tmimage flex align-cen flex-gap5">
                <div class="">
                  <el-image
                      v-if="scope.row.picture"
                      :src="scope.row.picture"
                      :preview-src-list="[scope.row.picture]"
                  >
                  </el-image>
                  <img v-else-if="scope.row.type == '以类似摄制电影的方法创作的作品'" style="width: 70px" src="@/assets/img/personal/default2.png" class="" alt="">

                  <img v-else src="@/assets/img/personal/default1.png" style="width: 70px" class="" alt="">
                </div>
                <p class="ver-line3 vertical" >{{scope.row.name || '图形'}}  </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              label="申请人"
              min-width="150" >
            <template slot-scope="scope">
              <el-tooltip class="item" :content="scope.row.appname" effect="dark" v-showtip placement="top-start">

                <router-link v-if="scope.row.client_id != 0"
                             class=" ellipsis"
                             :to="{
                  name: 'newlyBuild',
                    params: { id:  scope.row.client_id  },
                }" target="_blank">
                  <span class="ellipse-text">{{ scope.row.appname || '-' }}</span>

                </router-link>
                <template v-else>
                  <p class="ellipsis">
                    <span class="ellipse-text ">{{scope.row.appname || '-'}}</span>
                  </p>

                </template>
              </el-tooltip>

            </template>
          </el-table-column>
          <el-table-column
              label="作品类型">
            <template slot-scope="scope">
              <el-tooltip class="item" :content="scope.row.type" effect="dark" v-showtip placement="top-start">
                <p class="ellipsis">
                  <span class="ellipse-text ellipsis">{{scope.row.type || '-'}}</span>
                </p>

              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
              label="金额"
              v-if="user.type != 0"
          >
            <template slot-scope="scope">
              ￥{{scope.row.subtotal}}
            </template>
          </el-table-column>
          <el-table-column
              label="当前进度" width="100">
            <template slot-scope="scope">
              <el-tag size="mini" :class="{green:scope.row.status == '已发证',purple:scope.row.status == '已报送'}" type="">
                {{ scope.row.status }}
              </el-tag>

            </template>
          </el-table-column>
          <el-table-column
              label="登记号" >
            <template slot-scope="scope">
              <el-tooltip class="item" :content="scope.row.register_number" effect="dark" v-showtip placement="top-start">
                <p class="ellipsis">
                  <span class="ellipse-text ">{{scope.row.register_number || '-'}}</span>
                </p>

              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
              label="登记日期" width="110" >
            <template slot-scope="scope">
              <el-tooltip class="item" :content="scope.row.register_at" effect="dark" v-showtip placement="top-start">
                <p class="ellipsis">
                  <span class="ellipse-text ">{{scope.row.register_at || '-'}}</span>
                </p>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
              label="报送日期"  width="110" >
            <template slot-scope="scope">
              <el-tooltip class="item" :content="scope.row.deliver_at" effect="dark" v-showtip placement="top-start">
                <p class="ellipsis">
                  <span class="ellipse-text ">{{scope.row.deliver_at || '-'}}</span>
                </p>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
              label="操作" >
            <template slot-scope="scope">
              <router-link :to="{
                         name: 'worksDetail',
            params: { id: scope.row.id},
            }" target="_blank">
                <el-button size="small" type="primary" icon="el-icon-edit-outline">详情</el-button>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </el-skeleton>

      <!-- 分页 -->
      <el-pagination
          :current-page.sync="filters.page"
          :page-size="filters.per_page"
          :page-sizes="[30, 50, 100]"
          :total="pageSize"
          layout="total, sizes, prev, pager, next, jumper,slot"
          @size-change="changeRowNum"
          @current-change="perindexList()"
      >
      </el-pagination>
    </div>
    <el-dialog
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        :visible.sync="downloadDia"
        title="导出信息"
        width="576px"
        class=" openDia"
        @open="openDia">
      <template>
        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="filters.fields" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="city in cities" :key="city" :label="city">{{ city }}</el-checkbox>
        </el-checkbox-group>
      </template>
      <span slot="footer" class="dialog-footer">
    <el-button icon="el-icon-close" size="small" @click="downloadDia = false">取 消</el-button>
    <el-button :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' : 'el-icon-folder-checked'" class="confirm"
               size="small" type="primary" @click="download">确 定</el-button>
  </span>
    </el-dialog>

    <el-dialog
        class="editionDia"
        title="提示"
        :visible.sync="editionDia"
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        width="390px">
      <div class="text-cen flex align-cen"><i class="el-icon-warning m-r10"></i> 当前账号没有此操作权限，请升级至<span
          class="font-wbold col-red ">“企业版”</span>！
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" size="small" @click="editionDia = false">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import {applyWorks,exportCopyrights} from '@/api/personal.js'
const cityOptions = ['样品图片', '作品名称',  '申请人', '作品类型',  '金额', '当前进度', '发表日期','完成日期','登记号','登记日期', '报送日期','作品性质'];
export default {
  name:'applyWorks',
  data(){
    return{
      user:storage.getUser(),
      down: false,//控制导出加载的显示与隐藏
      downloadDia: false,//控制导出的对话框
      checkAll: false,
      cities: cityOptions,
      isIndeterminate: true,
      submitDis: false,
      editionDia:false,

      formOpen:false,
      loading: true,//控制列表的骨架屏
      applyList:[],
      lists:storage.getList(),
      pickerOptions: {//日期选择器的便利选择对象
        shortcuts: storage.getShortcuts()
      },
      filters:{
        name:'',//作品名称
        type:'',//作品类型
        status:'',//当前进度
        register_number:'',//登记号
        register_at: '',//登记日期
        deliver_at:'',//报送日期
        appname:'',//申请人
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
        checkList: [],//记录满三年和可续展选中时的数组
        fields: ['作品名称',  '申请人', '作品类型',  '金额','当前进度','登记号','登记日期', '报送日期'],
      },
      //商标类别，下拉框的所需内容
      intclsSelect:[{ id: '1', text: '已报送' },{
        id: '2',
        text: '已发证'
      }],
      typeSelect:[],
      appSelect:[],
      pageSize: 0, //总条数
    }
  },
  created() {
    this.select()
    this.perindexList()
  },
  methods:{
    //全选事件
    handleCheckAllChange(val) {
      this.filters.fields = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    //单选事件
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    //弹窗事件
    openDia() {
      this.filters.fields = this.$options.data().filters.fields;
      this.checkAll = false
      this.isIndeterminate = true
    },
    diaClick(){
      if(storage.getUser().type != 0){
        this.downloadDia = true
      }else{
        this.editionDia = true
      }

    },
    //导出事件
    async download() {
        this.down = true
        this.downloadDia = false
        if (this.filters.fields.length != 0) {
          try {
            const res = await exportCopyrights(this.filters)
            if(res && res.err_code === 0){
              location.href = res.data.url
              this.down = false
            }else{
              this.$message({
                message: res.err_msg,
                type: 'warning'
              });
            }

          } finally {
            this.down = false
          }
        } else {
          this.$message({
            message: '至少选择一个',
            type: 'warning'
          });
        }



    },
    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.perindexList();
    },
    // 下拉
    async select() {
      let s = await storage.getSelects( 'copyright_types','app_names')
      this.typeSelect = s.copyright_types
      this.appSelect = s.app_names
    },
    //渲染列表事件
    async perindexList() {
      this.loading = true;
      try {
        const res = await applyWorks(this.filters)
        if (res && res.err_code === 0) {
          this.loading = false
          this.applyList = res.data.list
          this.pageSize = res.data.total
        } else {
          this.applyList = []
        }
      } finally {
        this.loading = false
      }
    },
    //重置事件
    reset() {
      this.filters = this.$options.data().filters;
      this.perindexList(this.filters.page = 1)
    },
    // 下载
    open(item,url) {
      let name = (item.name ? item.name : '图形') + '-' + url.matter + '.' + url.name.split('.')[1]
      location.href = url.url.replace(url.name,name)
    },

    over(){
      this.$confirm('已超出30天免费下载期，开通企业版可享无限期免费下载，详情请联系知产顾问！', '提示', {
        confirmButtonText: '确定',
        showCancelButton:false,
        type: 'warning'
      })
    },
  }
}
</script>

<style lang="less" scoped>
@import "../../style/apply.less";
@media screen and (max-width: 768px) {


  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 644px") !important;
  }
  .el-table.on {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 392px") !important;
    }
  }

}
</style>
