<template>
  <div class="">
    <router-view ></router-view>
  </div>
</template>
<script>
export default{
  data(){
    return {
      value:'',
    }
  },
  methods:{

  }
}
</script>