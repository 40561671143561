<template>
  <div class="detail ba-col-fff ">
    <h2>{{ alias == 1 ? '自有' : '' }}商标详情</h2>
    <el-skeleton :loading="loading" animated>
      <template slot="template">
        <div class="plf20 col-606">
          <div class=" bor">
            <div class="detail_l ">
              <div class="img w100 flex align-cen ju-con-cen pos-rel">
                <el-skeleton-item
                    class="img-logo"
                    style="width: 190px;height: 190px"
                    variant="image"
                />
              </div>
              <div class="flows p20">
                <h3 class="col-303 font-wbold m-b10">商标流程</h3>
                <p class="line-h30 ellipsis">
                  <el-skeleton-item class="w50" variant="p"/>
                </p>
                <p class="line-h30 ellipsis">
                  <el-skeleton-item class="w50" variant="p"/>
                </p>
                <p class="line-h30 ellipsis">
                  <el-skeleton-item class="w50" variant="p"/>
                </p>
              </div>
            </div>
            <div class="detail_r">
              <div class="r-cont">
                <h3 class="p10 col-303 font-wbold">
                  <el-skeleton-item class="w50" variant="p"/>

                  <div class="disinblk">
                    <el-skeleton-item class="w50" variant="p"/>
                  </div>

                </h3>
                <div class="cont-text p20 line-h30">
                  <div class="flex flex-cen">
                    <p><strong>商标号：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p>
                      <strong>商标类型：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p>
                      <strong>代理机构：</strong> <span class="col-blue"><el-skeleton-item class="w50" variant="p"/></span>
                    </p>
                  </div>
                  <div class="flex flex-cen">
                    <p><strong>申请日期：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p><strong>初审日期：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p><strong>注册日期：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                  </div>
                  <div class="flex flex-cen">
                    <p>
                      <strong>专用期限至：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p>
                      <strong>初审公告期号：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p>
                      <strong>注册公告期号：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                  </div>

                  <div class="flex flex-cen">

                    <p>
                      <strong>申请人名称（中）：</strong>
                      <el-skeleton-item class="w50" variant="p"/>

                    </p>
                    <p>
                      <strong>申请人名称（英）：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                  </div>
                  <div class="flex flex-cen">

                    <p>
                      <strong>申请人地址（中）：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p>
                      <strong>申请人地址（英）：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                  </div>


                  <div class="">
                    <strong>类似群组：</strong>
                    <el-skeleton-item class="w50" variant="p"/>
                  </div>
                  <div class="">
                    <strong>商品/服务项目：</strong>
                    <el-skeleton-item class="w50" variant="p"/>
                  </div>
                </div>
              </div>

              <el-tabs v-model="tabNum">
                <el-tab-pane label="商标官文" name="0">
                  <div class="r-list">
                    <div class="list_t ">
                      <el-skeleton-item style="width: 100px" class="m-r5" variant="p"/>

                      <el-skeleton-item style="width: 100px" variant="p"/>
                    </div>
                    <el-table
                        :data="docs"
                    >
                      <template slot="empty">
                        <el-empty :image-size="80"></el-empty>
                      </template>
                      <el-table-column type="selection" width="50">
                      </el-table-column>
                      <el-table-column label="序号"  width="50">
                        <template slot-scope="scope">
                          <el-skeleton-item  variant="p"/>
                        </template>
                      </el-table-column>
                      <el-table-column label="官文类型">
                        <template slot-scope="scope">
                          <el-skeleton-item  variant="p"/>
                        </template>
                      </el-table-column>
                      <el-table-column label="收文日期" >
                        <template slot-scope="scope">
                          <el-skeleton-item  variant="p"/>
                        </template>
                      </el-table-column>
                      <el-table-column label="上传日期" >
                        <template slot-scope="scope">
                          <el-skeleton-item  variant="p"/>
                        </template>
                      </el-table-column>
                      <el-table-column fixed="right" label="操作" width="140">
                        <template slot-scope="scope">
                          <el-skeleton-item style="width: 100px" variant="p"/>

                          <el-skeleton-item style="width: 100px" variant="p"/>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="关联业务" name="1">
                  <div class="r-list">
                    <div class="list_t ">
                      <el-skeleton-item style="width: 100px" variant="p"/>
                    </div>
                    <el-table :data="docs">
                      <template slot="empty">
                        <el-empty :image-size="80"></el-empty>
                      </template>
                      <el-table-column label="序号" type="index" width="50">
                        <template slot-scope="scope">
                          <el-skeleton-item class="w50" variant="p"/>
                        </template>
                      </el-table-column>
                      <el-table-column label="商标号" >
                        <template slot-scope="scope">
                          <el-skeleton-item class="w50" variant="p"/>
                        </template>
                      </el-table-column>
                      <el-table-column label="商标名称"  >
                        <template slot-scope="scope">
                          <el-skeleton-item class="w50" variant="p"/>
                        </template>
                      </el-table-column>
                      <el-table-column  label="类别" >
                        <template slot-scope="scope">
                          <el-skeleton-item class="w50" variant="p"/>
                        </template>
                      </el-table-column>
                      <el-table-column  label="申请类型" >
                        <template slot-scope="scope">
                          <el-skeleton-item class="w50" variant="p"/>
                        </template>
                      </el-table-column>
                      <el-table-column label="金额" >
                        <template slot-scope="scope">
                          <el-skeleton-item class="w50" variant="p"/>
                        </template>
                      </el-table-column>
                      <el-table-column label="上传日期" >
                        <template slot-scope="scope">
                          <el-skeleton-item class="w50" variant="p"/>
                        </template>
                      </el-table-column>
                      <el-table-column label="操作" >
                        <template slot-scope="scope">
                          <el-skeleton-item class="w50" variant="p"/>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="商标属性" name="2">
                  <div class="list_t  bor-bottom">
                    <el-skeleton-item style="width: 100px" variant="p"/>
                  </div>

                  <div class="attribute p20 line-h32 bor-bottom flex flex-warp">

                    <p v-for="(item,index) in docs"
                       :class="{w100:index == docs.length - 1,w30:index != docs.length - 1}"
                       class="flex">
                      <strong class="disinblk m-r5"><el-skeleton-item style="width: 100px" variant="p"/>：</strong>
                      <span><el-skeleton-item style="width: 100px" variant="p"/></span>
                    </p>
                  </div>

                </el-tab-pane>
                <el-tab-pane label="商标公告" name="3">
                  <div class="p20  col-606 grid grid-row-gap10">

                    <p class=" pointer "  v-for="item in docs">
                      <el-skeleton-item  variant="p"/>
                    </p>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>

        </div>

      </template>
      <template>
        <div ref="bor" class="plf20 m-b40  col-606">
          <div class=" bor">
            <div class="detail_l ">
              <div class="img w100 flex align-cen ju-con-cen pos-rel">
                <el-image
                    :preview-src-list="[detail.tmimage]"
                    :src="detail.tmimage">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline "></i>
                  </div>
                </el-image>
                <div
                    :class="{flag1:['注册申请中','驳回复审中','行政诉讼中','无效程序中','无效程序中','撤销复审中','撤三程序中','异议程序中'].indexOf(detail.tmlaw) != -1,flag2:detail.tmlaw == '撤三申请不予受理',flag3:['驳回复审完成','无效宣告完成','异议程序完成'].indexOf(detail.tmlaw) != -1,flag4:detail.tmlaw == '初审公告',flag5:detail.tmlaw == '已注册',flag6:detail.tmlaw == '商标无效',flag7:detail.tmlaw == '驳回待复审',flag8:detail.tmlaw == '行政诉讼撤销' || detail.tmlaw == '续展不予核准' || detail.tmlaw == '其他状态'}"
                    class="flag pos-abs pos-l0 pos-t0">
                  <span>
                    {{ detail.tmlaw || '-' }}
                  </span>
                </div>
              </div>
              <div class="flows p20">
                <h3 class="col-303 font-wbold m-b10">商标流程</h3>
                <div class="">
                  <p v-for="item in (flowsUp ? detail.flows : replace.flows)" class="line-h20">
                    {{ item.flowdate }} &nbsp;&nbsp;
                    <span v-html="item.flowname"></span>
                  </p>
                </div>
              </div>
              <p v-if="replace.flowsInit.length > 5 " class="text-cen pointer p-b10" @click="flowsUp = !flowsUp">
                {{ flowsUp ? '收起' : '查看全部' }}<i
                  :class="{'el-icon-arrow-down':flowsUp == false,'el-icon-arrow-up':flowsUp == true}"
                  class=" m-l5"></i>
              </p>

            </div>
            <div class="detail_r">
              <div class="r-cont">
                <h3 class="p10 col-303 line-h30 font-wbold flex align-cen">
                  {{ detail.clstext }}：
                  <div v-if="alias == 1" class="disinblk">
                    <p v-if="aliaSh" v-click-outside="handleClose" class="">
                      <el-input
                          v-model="filters.alias == null ? (detail.tmname ? (filters.alias = detail.tmname) : (filters.alias = '图形')) : filters.alias"
                          v-focus
                          placeholder="请输入别名"
                          size="mini"
                          @keyup.enter.native="input"
                      >
                        <template slot="suffix">
                          <i class="el-icon-check col-green pointer" @click="input"></i>
                        </template>
                      </el-input>
                    </p>
                    <p v-else class="">
                    <span class="col-blue m-r5" @dblclick="aliaSh = true">
                    {{ detail.alias == null ? (detail.tmname ? (detail.tmname) : '图形') : detail.alias }}
                    </span>
                      <el-tooltip class="item" content="双击文字编辑别名" effect="dark" placement="right">
                        <i class="el-icon-info col-E6A"></i>
                      </el-tooltip>
                      <span v-if="detail.alias && detail.alias != detail.tmname"
                            class="col-909">（原名：{{ detail.tmname || '图形' }}）</span>
                    </p>

                  </div>
                  <div v-else class="disinblk">
                  <span class="col-blue m-r5">
                    {{ detail.alias == null ? (detail.tmname ? (detail.tmname) : '图形') : detail.alias }}
                    </span>
                  </div>

                </h3>
                <div class="cont-text p20 line-h32">
                  <div class="flex ju-con-bet">
                    <p><strong>商标号：</strong>{{ detail.tmcode || '-' }} <i
                        v-copy="detail.tmcode" class="el-icon-copy-document col-blue pointer m-l5"></i></p>
                    <p>
                      <strong>商标类型：</strong>{{ detail.tmsblx || '-' }}
                    </p>
                    <p><strong>代理机构：</strong> <span class="col-blue">{{ detail.agentname || '-' }}</span></p>

                  </div>
                  <div class="flex ju-con-bet">
                    <p><strong>申请日期：</strong>{{ detail.appdate || '-' }}</p>

                    <p><strong>初审日期：</strong>{{ detail.csdate || '-' }}</p>
                    <p><strong>注册日期：</strong>{{ detail.regdate || '-' }}</p>
                  </div>
                  <div class="flex ju-con-bet">
                    <p>
                      <strong>专用期限至：</strong>{{ detail.enddate || '-' }}
                    </p>
                    <p>
                      <strong>初审公告期号：</strong>{{ detail.csggqh || '-' }}
                    </p>
                    <p>
                      <strong>注册公告期号：</strong>{{ detail.zcggqh || '-' }}
                    </p>
                  </div>

                  <div class="flex ju-con-bet">

                    <p>
                      <strong>申请人名称（中）：</strong>
                      <router-link v-if="detail.client_id != 0" :class="{'col-blue':detail.appname}" :to="{
                  name: 'newlyBuild',
                    params: { id: detail.client_id  },
                }" target="_blank">
                        {{ detail.appname || '-' }}
                      </router-link>
                      <template v-else>
                        {{ detail.appname || '-' }}
                      </template>
                    </p>
                    <p>
                      <strong>申请人名称（英）：</strong>
                      <router-link v-if="detail.client_id != 0" :class="{'col-blue':detail.appnameen}" :to="{
                  name: 'newlyBuild',
                    params: { id: detail.client_id  },
                }" target="_blank">
                        {{ detail.appnameen || '-' }}
                      </router-link>
                      <template v-else>
                        {{ detail.appnameen || '-' }}
                      </template>
                    </p>
                  </div>
                  <div class="flex ju-con-bet">
                    <p>
                      <strong>申请人地址（中）：</strong>{{ detail.appaddr || '-' }}
                    </p>
                    <p>
                      <strong>申请人地址（英）：</strong>{{ detail.appaddren || '-' }}
                    </p>
                  </div>


                  <div class="word-break">
                    <strong>类似群组：</strong> {{ detail.tmgroup || '-' }}
                  </div>
                  <div class="">
                    <strong>商品/服务项目：</strong>
                    <span v-for="(item,index) in replace.tmgoodsdetail">
                       <s v-if="item.status != 0" class="grey">{{ item.name }}</s>
                            <font v-else>{{ item.name }}</font>
                      <font v-if="index != replace.tmgoodsdetail.length - 1">、</font>
                    </span>
                    <span v-if="replace.tmgoodsdetail == false">-</span>
                  </div>
                </div>
              </div>

              <el-tabs v-model="tabNum">
                <el-tab-pane label="商标官文" name="0">
                  <div class="r-list">
                    <div class="list_t ">
                      <el-button icon="el-icon-upload2" size="small" type="primary" @click="upload" >
                        上传官文
                      </el-button>
                      <el-button v-if="downSH" :loading="!down" icon="el-icon-download" size="small" @click="download" >{{!down ? '下载中' : '批量下载'  }}</el-button>
                    </div>
                    <el-table
                        :data="detail.docs"
                        @selection-change="handleSelectionChange"
                    >
                      <template slot="empty">
                        <el-empty :image-size="80"></el-empty>
                      </template>
                      <el-table-column type="selection" width="50"></el-table-column>
                      <el-table-column label="序号" type="index" width="50"></el-table-column>
                      <el-table-column :show-overflow-tooltip="true" label="官文类型">
                        <template slot-scope="scope">
                          <a :href="`${scope.row.url}&preview=true`"
                             target="_blank">
                            {{ scope.row.matter }}
                          </a>
                        </template>
                      </el-table-column>
                      <el-table-column label="收文日期" prop="made_at" width="110"></el-table-column>
                      <el-table-column label="上传日期" prop="created_at" width="110"></el-table-column>
                      <el-table-column  label="操作" width="140">
                        <template slot-scope="scope">
                          <div class="operation">
                            <el-button icon="el-icon-download" size="small" type="text" @click="openPdf(scope.row)">下载
                            </el-button>
                            <el-button v-if="scope.row.is_custom == 1" class="del" icon="el-icon-delete" size="small"
                                       type="text" @click="deleteFile(scope.row.id)">删除
                            </el-button>

                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="关联业务" name="1">
                  <div class="r-list">
                    <div class="list_t ">
                      <el-button type="primary" icon="el-icon-document" :disabled="replace.cases.length == 0" :loading="exportDown" size="small" @click="downloadDia = true">{{ exportDown ? '导出中' :'导出数据'  }}</el-button>
                    </div>
                    <el-table :data="detail.cases">
                      <template slot="empty">
                        <el-empty :image-size="80"></el-empty>
                      </template>
                      <el-table-column label="序号" type="index" width="50"></el-table-column>
                      <el-table-column label="商标号" prop="tmcode" ></el-table-column>
                      <el-table-column label="商标名称"  >
                        <template slot-scope="scope">
                          <div class="tmimage flex align-cen ">
                            <div class="">
                              <el-image
                                  :preview-src-list="[scope.row.tmimage]"
                                  :src="scope.row.tmimage"
                              >
                                <div slot="error" class="image-slot">
                                  <i class="el-icon-picture-outline"></i>
                                </div>
                              </el-image>
                            </div>
                            <p class="vertical ver-line3">{{ scope.row.tmname || '图形' }}</p>
                          </div>

                        </template>
                      </el-table-column>
                      <el-table-column  label="类别" prop="intcls"></el-table-column>
                      <el-table-column  label="申请类型" prop="apptype"></el-table-column>
                      <el-table-column label="金额"  width="110">
                        <template slot-scope="scope">
                          ￥{{scope.row.subtotal || '0'}}

                        </template>
                      </el-table-column>
                      <el-table-column label="申请日期"  width="110">
                        <template slot-scope="scope">
                          {{scope.row.deliver_at || '-'}}

                        </template>
                      </el-table-column>
                      <el-table-column label="操作" >
                        <template slot-scope="scope">
                          <router-link
                              class="disblk" :to="{ name:'applyDetail',params: {id: scope.row.id}}"
                              target="_blank"
                          >
                            <el-button icon="el-icon-edit-outline" size="small" type="primary">详情</el-button>
                          </router-link>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="商标属性" name="2" v-if="detail.client_id != 0">
                  <div class="list_t  bor-bottom">
                    <div v-if="tempEdit" class="">
                      <el-button icon="el-icon-circle-plus-outline" plain size="small" type="primary"
                                 @click="attrOpen('add')">添加属性
                      </el-button>
                      <el-button :loading="savePropLoad" icon="el-icon-folder-checked" size="small" type="primary"
                                 @click="saveProp">
                        {{ savePropLoad ? '保存中' : '保存' }}
                      </el-button>
                      <el-button icon="el-icon-close" size="small" @click="tempClose ">
                        取消
                      </el-button>
                    </div>
                    <el-button v-else icon="el-icon-edit-outline" plain size="small" type="primary" @click="tempEdit = !tempEdit">
                      编辑
                    </el-button>
                  </div>
                  <div v-if="tempEdit" class="p20 tempEdit bor-bottom ">
                    <div class="flex align-cen flex-warp ">
                      <div v-for="(item,index) in tempSelect" class="w33 flex align-cen">
                        <span v-if="item.key.length <= 6" :class="{'text-right':item.key.length <= 6}" class="vertical ver-line3">
                          {{ item.key }}
                        </span>
                        <el-tooltip v-else :content="item.key" effect="dark" placement="top">
                          <span class="vertical ver-line3"> {{ item.key }}</span>
                        </el-tooltip>
                        <div class="mlf10">
                          <el-select v-if="item.type == 'select'" v-model="replace.tempArr[index].value" clearable placeholder="请选择" size="small">
                            <el-option v-for="select in item.value.split(',')" :label="select" :value="select"></el-option>
                          </el-select>
                          <el-date-picker
                              v-else-if="item.type == 'datetime'"
                              v-model="replace.tempArr[index].value"
                              clearable
                              format="yyyy 年 MM 月 dd 日"
                              placeholder="选择日期"
                              size="small"
                              type="date"
                              value-format="yyyy-MM-dd"
                          >
                          </el-date-picker>
                          <el-input v-else-if="item.type == 'textarea'" v-model="replace.tempArr[index].value" clearable
                                    maxlength="100"
                                    placeholder="请填写"
                                    show-word-limit size="small" type="textarea"
                                    @blur="blur(replace.tempArr[index].value,'type' + index)"></el-input>

                          <el-input v-else-if="item.type == 'input'" v-model="replace.tempArr[index].value" clearable
                                    placeholder="请输入内容"
                                    size="small"></el-input>
                        </div>
                        <div class="">
                          <i class="el-icon-edit-outline m-r5 pointer" @click="attrOpen(item,index)"></i>
                          <i v-if="index != 0" class="el-icon-delete pointer" @click="attrSave('del',index)"></i>
                        </div>
                      </div>
                    </div>

                    <div class="textarea flex align-cen">
                      <span class="text-right">备注</span>
                      <div class="mlf10 w80">
                        <el-input v-model="replace.tempArr[replace.tempArr.length - 1].value" clearable
                                  maxlength="100" placeholder="请填写备注"
                                  show-word-limit size="small" type="textarea"
                                  @blur="blur(replace.tempArr[replace.tempArr.length - 1].value,'textarea')"></el-input>
                      </div>
                    </div>

                  </div>
                  <div v-else class="attribute p20 line-h32 bor-bottom flex flex-warp">

                    <p v-for="(item,index) in replace.tempArr"
                       :class="{w100:index == replace.tempArr.length - 1,w30:index != replace.tempArr.length - 1}"
                       class="flex">
                      <strong class="disinblk m-r5">{{ item.key }}：</strong><span>{{ item.value || '-' }}</span>
                    </p>
                  </div>

                </el-tab-pane>
                <el-tab-pane label="商标公告" name="3">
                  <div class="p20 line-h32 col-606">
                    <el-empty v-if="replace.notice.length == 0" description="暂无数据"></el-empty>
                    <p class="flex align-cen "  v-for="item in detail.notice">
                      {{item.noticeDate}}{{'第' + item.issue + '期'}}《{{item.noticeName}}》{{'第'+ item.page + '页'}}
                      <i @click="open(item.imgUrl)" class="pointer el-icon-search m-l5 col-blue"></i>
                    </p>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>

        </div>

      </template>
    </el-skeleton>


    <!-- 上传 -->
    <el-dialog
        :closeOnClickModal="false"
        :visible.sync="fileSH"
        class="file"
        title="上传文件"
        width="335px"
        @close="empty"
        @closed="empty"
    >
      <!--      <div v-if="identification" class="cont-tab">-->
      <!--        <p @click="tabtNum = 1">-->
      <!--          <span :class="{ checked: tabtNum == 1 }" class="el-checkbox__input">-->
      <!--            <span class="el-checkbox__inner"> </span>-->
      <!--            <input-->
      <!--                aria-hidden="false"-->
      <!--                class="el-checkbox__original"-->
      <!--                type="checkbox"-->
      <!--                value="手动添加"-->
      <!--            />-->
      <!--          </span>-->

      <!--          <font>手动添加</font>-->
      <!--        </p>-->
      <!--        <p @click="tabtNum = 2">-->
      <!--          <span :class="{ checked: tabtNum == 2 }" class="el-checkbox__input">-->
      <!--            <span class="el-checkbox__inner"> </span>-->
      <!--            <input-->
      <!--                aria-hidden="false"-->
      <!--                class="el-checkbox__original"-->
      <!--                type="checkbox"-->
      <!--                value="手动添加"-->
      <!--            />-->
      <!--          </span>-->
      <!--          <font>智能识别</font>-->
      <!--        </p>-->
      <!--      </div>-->

      <div class="cont-tabc">
        <el-form ref="ruleForm" :model="manual" :rules="manualRules" label-position="right" label-width="80px"
                 size="mini">
          <el-form-item label="官文类型" prop="matter">
            <el-select
                v-model="manual.matter"
                clearable
                filterable
                placeholder="请选择官文类型"
                size="small"
            >
              <el-option
                  v-for="item in typeSelect"
                  :key="item.text"
                  :label="item.text"
                  :value="item.text"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商标号">
            <el-input
                v-model="filters.tmcode"
                clearable
                placeholder="请输入商标号"
                readonly
                size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="收文日期" prop="made_at">
            <el-date-picker
                v-model="manual.made_at"
                placeholder="请选择收文日期"
                size="small"
                type="date"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <div class="tabc-update">

            <el-upload
                ref="upload"
                :before-upload="beforeUpload"
                :file-list="fileList"
                :http-request="uploadImg"
                :limit="1"
                :on-exceed="handleExceed"
                action="/api/upload"
                class="upload-demo"
            >
              <el-form-item prop="name">
                <el-input v-model="manual.name" placeholder="请点击按钮上传文件" readonly size="small"></el-input>
              </el-form-item>
              <el-button
                  v-if="uploadBtn"
                  slot="trigger"
                  :disabled="distinguishDis"
                  circle
                  icon="el-icon-plus"
                  size="mini"
                  type="primary"
              ></el-button>
              <el-button v-else slot="trigger" :loading="true" circle size="mini" type="primary"></el-button>
            </el-upload>

          </div>
          <div class="batch">
            官文太多？马上
            <router-link to="/personal/trademarkAdmin/upload">批量上传</router-link>
          </div>
          <div class="submitBtn">
            <el-button class="cancel" icon="el-icon-close" size="small" @click="fileSH = false">取 消</el-button>
            <el-button :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' :  'el-icon-folder-checked'"
                       class="confirm" size="small"
                       type="primary" @click="submit('ruleForm')">确
              定
            </el-button>
          </div>

        </el-form>
      </div>
      <!--      <div v-show="tabtNum == 2" class="cont-tabc">-->
      <!--        <el-form ref="automatic" :model="automatic" :rules="automaticRules" label-position="right" label-width="80px"-->
      <!--                 size="mini">-->
      <!--          <div v-show="contSH" class="tabc-cont">-->
      <!--            <el-skeleton :count="1" :loading="loadingUp" animated>-->
      <!--              <template slot="template">-->
      <!--                <div class="el-form-item is-required el-form-item&#45;&#45;mini">-->
      <!--                  <label class="el-form-item__label">官文类型</label>-->
      <!--                  <div class="el-form-item__content" style="height: 32px; line-height: 32px">-->
      <!--                    <el-skeleton-item variant="p"/>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--                <div class="el-form-item is-required el-form-item&#45;&#45;mini">-->
      <!--                  <label class="el-form-item__label">商标号</label>-->
      <!--                  <div class="el-form-item__content" style="height: 32px; line-height: 32px">-->
      <!--                    <el-skeleton-item variant="p"/>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--                <div class="el-form-item is-required el-form-item&#45;&#45;mini">-->
      <!--                  <label class="el-form-item__label">收文日期</label>-->
      <!--                  <div class="el-form-item__content" style="height: 32px; line-height: 32px">-->
      <!--                    <el-skeleton-item variant="p"/>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </template>-->
      <!--              <template>-->

      <!--                <el-form-item label="官文类型" prop="matter">-->
      <!--                  <el-select-->
      <!--                      v-model="automatic.matter"-->
      <!--                      clearable-->
      <!--                      filterable-->
      <!--                      placeholder="请选择官文类型"-->
      <!--                      size="small"-->
      <!--                  >-->
      <!--                    <el-option-->
      <!--                        v-for="item in typeSelect"-->
      <!--                        :key="item.text"-->
      <!--                        :label="item.text"-->
      <!--                        :value="item.text"-->
      <!--                    >-->
      <!--                    </el-option>-->
      <!--                  </el-select>-->
      <!--                </el-form-item>-->
      <!--                <el-form-item label="商标号">-->
      <!--                  <el-input-->
      <!--                      v-model="filters.tmcode"-->
      <!--                      clearable-->
      <!--                      readonly-->
      <!--                      placeholder="请输入商标号"-->
      <!--                      size="small"-->
      <!--                  ></el-input>-->
      <!--                </el-form-item>-->
      <!--                <el-form-item label="收文日期" prop="made_at">-->
      <!--                  <el-date-picker-->
      <!--                      v-model="automatic.made_at"-->
      <!--                      placeholder="请选择收文日期"-->
      <!--                      size="small"-->
      <!--                      type="date"-->
      <!--                      value-format="yyyy-MM-dd"-->
      <!--                  >-->
      <!--                  </el-date-picker>-->
      <!--                </el-form-item>-->
      <!--              </template>-->
      <!--            </el-skeleton>-->
      <!--          </div>-->
      <!--          <div class="tabc-update">-->
      <!--            <el-upload-->
      <!--                ref="upload"-->
      <!--                :before-upload="beforeUpload"-->
      <!--                :file-list="fileList"-->
      <!--                :http-request="uploadImg"-->
      <!--                :limit="1"-->
      <!--                :on-exceed="handleExceed"-->
      <!--                action="/api/upload"-->
      <!--                class="upload-demo"-->
      <!--            >-->

      <!--              <el-form-item prop="name">-->
      <!--                <el-input v-model="automatic.name" readonly placeholder="请点击按钮上传文件" size="small"></el-input>-->
      <!--              </el-form-item>-->

      <!--              <el-button-->
      <!--                  v-if="uploadBtn"-->
      <!--                  slot="trigger"-->
      <!--                  :disabled="distinguishDis"-->
      <!--                  circle-->
      <!--                  icon="el-icon-plus"-->
      <!--                  size="mini"-->
      <!--                  type="primary"-->
      <!--              ></el-button>-->
      <!--              <el-button v-else slot="trigger" :loading="true" circle size="mini" type="primary"></el-button>-->
      <!--            </el-upload>-->
      <!--          </div>-->
      <!--          <div v-if="distinguishSh" class="tabc-distinguish">-->
      <!--            <el-button :disabled="distinguishDis" size="small" icon="el-icon-view" type="primary" @click="distinguishBtn('automatic')">识 别-->
      <!--            </el-button>-->
      <!--          </div>-->
      <!--          <el-form-item v-else class="submitBtn">-->
      <!--            <el-button class="cancel" size="small" @click="fileSH = false" icon="el-icon-close">取 消</el-button>-->
      <!--            <el-button :disabled="submitDis" class="confirm" size="small" type="primary"-->
      <!--                       @click="submitAutomatic('automatic')" icon="el-icon-folder-checked">确 定-->
      <!--            </el-button>-->
      <!--          </el-form-item>-->
      <!--        </el-form>-->
      <!--      </div>-->
    </el-dialog>

<!--    导出-->
    <el-dialog
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        :visible.sync="downloadDia"
        class="downloadDia openDia"
        title="导出信息"
        width="576px"
        @close="openDia">
      <template>
        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="exportArr.fields" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="city in fields" :key="city" :label="city">{{ city }}</el-checkbox>
        </el-checkbox-group>
      </template>
      <span slot="footer" class="dialog-footer">
    <el-button icon="el-icon-close" size="small" @click="downloadDia = false">取 消</el-button>
    <el-button :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' : 'el-icon-folder-checked'" class="confirm"
               size="small" type="primary" @click="exDown">确 定</el-button>
  </span>
    </el-dialog>


    <!--    添加属性-->
    <el-dialog
        :closeOnClickModal="false"
        :visible.sync="attributeDia"
        class="attribute_dia"
        title="编辑属性"
        width="474px"
        @close="attrClose"
        @closed="attrClose">
      <el-form ref="attributeRef" :model="attribute" :rules="attributeRules" class="grid grid-row-gap15 ">
        <el-form-item label="名称" prop="key">
          <el-input v-model="attribute.key" clearable maxlength="30" placeholder="请填写属性名称" size="small"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="attribute.type" clearable placeholder="请选择属性类型" size="small">
            <el-option v-for="item in attrTypeSelect" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="attribute.type == 'select'" label="内容" prop="value">
          <el-input v-model="attribute.value" clearable maxlength="100" placeholder="请填写属性内容"
                    show-word-limit size="small" type="textarea" @blur="blur(attribute.value,'attribute')"></el-input>
          <p class="font-12 col-COC ">选项内容用英文”,”分割，如：苹果,香蕉,梨子。</p>
        </el-form-item>

      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="attrClose('attributeRef') ">取 消</el-button>
        <el-button size="small" type="primary" @click="attrSave('attributeRef')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const fieldsOptions = ['商标图样', '商标名称', '商标号', '商标类别', '申请人', '申请类型', '金额', '当前进度', '申请日期', '商品项目'];
import storage from '@/utils/storage'
import {
  alias,
  exportData,
  batchDownload,
  fileDistinguish,
  fileSubmit,
  fileUpload,
  getPropSetting,
  managementDelete,
  saveProps,
  savePropSetting,
  trademarkDetail,
  exportTms,
} from '@/api/personal.js'

export default {

  data() {
    return {
      fields:fieldsOptions,
      isIndeterminate: true,//控制全选状态
      exportDown: false,//控制导出加载的显示与隐藏
      downloadDia: false,//控制导出的对话框
      editionDia: false,
      checkAll: false,
      exportArr:{
        fields:['商标名称', '商标号', '商标类别', '申请人', '申请类型', '金额', '当前进度', '申请日期'],
      },
      tabNum: 0,//控制tabs
      savePropLoad: false,
      pickerOptions: {//时间选择器的便利选择对象
        shortcuts: storage.getShortcuts()
      },
      add: '',
      chooseIns: '',
      tempEdit: false,
      tempSelect: [],
      attributeDia: false,
      attribute: {
        key: '',
        type: '',
        value: '',
      },
      attributeRules: {
        key: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
        type: [
          {required: true, message: '请选择', trigger: 'change'}
        ],
        value: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
      },
      attrTypeSelect: [{name: '文本', value: 'input'}, {name: '下拉', value: 'select'}, {
        name: '日期',
        value: 'datetime'
      }, {name: '文本域', value: 'textarea'},],
      flowsUp: false,//商标流程的展开与收起
      replace: {//替代字段
        flows: [],
        flowsInit: [],
        tmgoodsdetail: [],
        tempArr: [],
        notice:[],
        cases:[],
      },
      loading: true,//控制整个页面的骨架屏
      alias: '',//判断是否是从自有列表过来的
      filters: {//接收列表页传的参数来渲染页面数据
        alias: "",//别名
        tmcode: '',//注册商标
        intcls: '',//国际分类
      },
      docs: storage.getList(),//骨架屏的初始数据
      aliaSh: false,//控制编辑别名的显示与隐藏
      detail: [],//整个详情的数据
      srcList: [],//存储查看大图的数据
      id: {ids: []},//记录批量下载的数据},
      deleteId: {
        id: '',
      },
      downSH: true,//控制批量下载按钮的显示与隐藏
      flowsSH: true,
      down: true,//控制批量下载加载中按钮的显示与隐藏

      uploadBtn: true,//控制上传文件加载中按钮的显示与隐藏
      fileList: [],//记录上传文件成功的数组
      loadingUp: true,//控制上传文件中识别文件的骨架屏
      fileSH: false,//控制上传官文弹窗的显示与隐藏
      tabtNum: 1,//控制手动和自动上传的tab切换
      file: {
        name: "",//上传官文的名称
        path: '',//上传官文的路径
      },
      distinguishSh: true,//控制识别按钮显示与隐藏
      distinguishDis: false,
      submitDis: false,
      contSH: false,//控制识别中的骨架屏
      typeSelect: [],//官文类型的下拉数据
      automatic: {//自动上传的字段
        matter: "",
        made_at: "",
        name: "",//上传官文的名称
      },
      automaticRules: {
        matter: [
          {required: true, message: '请选择官文类型', trigger: 'change'},
        ],
        made_at: [
          {required: true, message: '请选择收文日期', trigger: 'change'},
        ],
        name: [
          {required: true, message: '请点击按钮上传文件', trigger: 'change'},
        ],
      },
      manual: {//手动上传的字段
        matter: "", //官文名称
        made_at: "",
        name: ''
      },
      manualRules: {
        matter: [
          {required: true, message: '请选择官文类型', trigger: 'change'},
        ],
        made_at: [
          {required: true, message: '请选择收文日期', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请点击按钮上传文件', trigger: 'change'},
        ],
      },
    };
  },

  created() {
    this.alias = this.$route.params.alias
    this.getPropSet()
    this.domesticDetails();

  },

  methods: {
    // 全选改变
    handleCheckAllChange(val) {
      this.exportArr.fields = val ? options : [];
      this.isIndeterminate = false;
    },
    // 单选改变
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.fields.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fields.length;
    },
    //导出事件
    openDia() {
      this.exportArr.fields = this.$options.data().exportArr.fields;
      this.checkAll = false
      this.isIndeterminate = true
    },
    // 确认导出
    async exDown() {
      this.exportDown = true
      this.downloadDia = false
      if (this.exportArr.fields.length != 0) {
        try {
          const res = await exportTms({tmcode:this.detail.tmcode,intcls:[this.detail.intcls]})//,fields:this.exportArr.fields
          if(res && res.err_code === 0){
            location.href = res.data.url
            this.exportDown = false
          }else{
            this.$message({
              message: res.err_msg,
              type: 'warning'
            });
          }

        } finally {
          this.exportDown = false
        }
      } else {
        this.$message({
          message: '至少选择一个',
          type: 'warning'
        });
      }

    },
    //获取详情属性设置下拉
    getPropSet() {
      getPropSetting().then(res => {
        this.tempSelect = res.data
        this.tempSelect.forEach(item => {
          let obj = {}
          obj.key = item.key
          obj.value = null
          this.replace.tempArr.push(obj)
        })
        this.replace.tempArr.push({key: '备注', value: null})
      })
    },

    // 输入框失去焦点事件
    blur(item, str) {
      let pages = new RegExp("\n", "g");
      if (str == 'textarea') {
        this.replace.tempArr[this.replace.tempArr.length - 1].value = item.replace(pages, '')
      } else if (str == 'attribute') {
        this.attribute.value = item.replace(pages, '')
      } else {
        this.replace.tempArr[str.substring(4)].value = item.replace(pages, '')
      }

    },
    // 取消编辑
    tempClose() {
      this.tempEdit = !this.tempEdit
      let arr = []
      let key = Object.keys(this.detail.tmprops) //取出当前对象的索引
      let values = Object.values(this.detail.tmprops) //取出当前对象的值

      arr = key.map((item, index) => {
        return {
          value: values[index],//values是一个数组，加index是为了拿到跟索引同个位置的值
          key: item
        }
      })
      this.replace.tempArr.forEach(item => {
        for (let k in arr) {
          if (arr[k].key == item.key) {
            item.value = arr[k].value
          }
        }
      })
    },
    // 打开修改属性对话框
    attrOpen(item, index) {
      this.attributeDia = true
      this.add = item
      this.chooseIns = index
      if (item != 'add') {
        this.attribute.key = item.key
        this.attribute.type = item.type
        if (item.type == 'select') {
          this.attribute.value = item.value.toString()
        } else {
          this.attribute.value = item.value
        }

      } else {

      }
    },
    // 取消修改商标属性
    attrClose(formName) {
      this.attributeDia = false
      this.attribute = this.$options.data().attribute;
      this.$nextTick(() => {
        this.$refs.attributeRef.resetFields();
      })
    },
    // 保存修改商标属性
    attrSave(formName, index) {
      if (formName == 'del') {
        this.$confirm('属性删除后将无法恢复，确定删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.tempSelect.splice(index, 1)
          this.replace.tempArr.splice(index, 1)
          savePropSetting({props: this.tempSelect}).then(res => {
            this.attribute = this.$options.data().attribute;
            this.add = ''
          })
        })

      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {

            if (this.add == 'add') {
              this.tempSelect.push(this.attribute)
              this.replace.tempArr.splice(this.replace.tempArr.length - 1, 0, {key: this.attribute.key, value: null})
            } else {
              this.tempSelect[this.chooseIns] = {...this.attribute}
              this.replace.tempArr[this.chooseIns].key = this.attribute.key
            }
            savePropSetting({props: this.tempSelect}).then(res => {
              this.attributeDia = false
              this.$nextTick(() => {
                this.$refs.attributeRef.resetFields();
              })

              this.attribute = this.$options.data().attribute;
              this.add = ''
            })
          } else {
            return false;


          }
        })

      }

    },
    //保存商标属性
    saveProp() {
      this.savePropLoad = true
      let obj = {}
      this.replace.tempArr.forEach(item => {
        obj[item.key] = item.value
      })

      saveProps({tmcode: this.detail.tmcode, intcls: this.detail.intcls, props: obj}).then(res => {
        this.savePropLoad = false
        this.tempEdit = !this.tempEdit
        // this.domesticDetails()
      })


    },
    // 下拉
    async select() {
      let s = await storage.getSelects('tm_doc_types', 'app_names')
      this.typeSelect = [...storage.getTrademarkTypes(),...storage.getReviewTypes()]
      // for (let i in s.app_names) {
      //   if (s.app_names[i].text == this.detail.appname) {
      //     this.typeSelect = [...storage.getTrademarkTypes(),...storage.getReviewTypes()]
      //     break
      //   } else {
      //     this.typeSelect = [...storage.getMaintainTypes(),...storage.getReviewTypes()]
      //   }
      // }
    },
    //返回顶部事件
    backTop() {
      const that = this;
      let ispeed = Math.floor(-that.scrollTop / 5);
      document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
    },
    //渲染详情事件
    async domesticDetails() {
      this.filters.tmcode = this.$route.params.tmcode
      this.filters.intcls = this.$route.params.intcls
      try {
        const res = await trademarkDetail(this.filters)
        if (res && res.err_code === 0) {
          this.loading = false
          this.srcList.push(res.data.tmimage);
          this.detail = res.data;
          // this.down = (this.detail.docs.length == 0) ? false : true
          this.replace.cases = this.detail.cases
          this.replace.notice = this.detail.notice
          let arr = []
          let key = Object.keys(this.detail.tmprops) //取出当前对象的索引
          let values = Object.values(this.detail.tmprops) //取出当前对象的值

          arr = key.map((item, index) => {
            return {
              value: values[index],//values是一个数组，加index是为了拿到跟索引同个位置的值
              key: item
            }
          })
          this.replace.tempArr.forEach(item => {
            for (let k in arr) {
              if (arr[k].key == item.key) {
                item.value = arr[k].value
              }
            }
          })


          if (this.detail.flows) {
            this.replace.flowsInit = this.detail.flows
          } else {
            this.replace.flowsInit = []
          }
          if (this.detail.tmgoods) {
            this.replace.tmgoodsdetail = this.detail.tmgoods
          } else {
            this.replace.tmgoodsdetail = false
          }

          if (res.data.flows.length > 5) {
            this.replace.flows = this.detail.flows.slice(0, 5)
          } else if (this.detail.flows.length != 0) {
            this.replace.flows = this.detail.flows
          } else {
            this.replace.flows = []
          }
          this.downSH = this.detail.docs.length != 0;
          this.flowsSH = this.detail.flows != null;
          this.filters.alias = res.data.alias
          await this.select()
        }
      } finally {
        this.loading = false
      }
    },
    //添加别名事件
    async input() {
      try {
        if (!this.filters.alias) {
          this.$message({
            message: '别名不能为空',
            type: 'warning'
          });
        } else {
          const res = await alias(this.filters)
          if (res && res.err_code === 0) {
            this.aliaSh = false
            this.detail.alias = this.filters.alias
          } else {
            this.$message({
              message: res.err_msg,
              type: 'warning'
            });
          }
        }

      } finally {
        // this.aliaSh = false
      }
    },
    //记录选择要下载的id事件
    handleSelectionChange(val) {
      this.id.ids = [];
      val.forEach((item) => {
        this.id.ids.push(item.id);
      });
    },
    //批量下载点击事件
    async download() {
      if (this.id.ids.length == 0) {
        this.$confirm("请选中要下载的官文序号", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      } else {

        this.down = false
        try {
          const res = await batchDownload(this.id)
          if (res && res.err_code === 0) {
            window.open(res.data.url)
            this.down = true
          } else {
            this.$message({
              message: res.err_msg,
              type: 'warning'
            });
          }
        } catch (error) {
          console.log("错误", error)
        } finally {
          this.down = true
        }

      }

    },
    open(url) {
      window.open(url)
    },
    openPdf(url){
      let name = this.detail.tmcode + '-' + this.detail.intcls + '-' + (this.detail.alias ? this.detail.alias : (this.detail.tmname ? this.detail.tmname : '图形'))  + '-' + url.matter + '.' + url.name.split('.')[1]
      location.href = url.url.replace(url.name,name)
    },

    //官文删除事件
    deleteFile(item) {
      this.deleteId.id = item
      try {

        this.$confirm('确定要删除该官文吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          await managementDelete(this.deleteId).then(res => {
            if (res && res.err_code === 0) {
              this.$message({
                type: 'success',
                message: "官文已删除！",
              })
              this.domesticDetails()
            } else {
              this.$message({
                type: 'error',
                message: res.err_msg,
              });
              this.domesticDetails()
            }
          })
        })
      } finally {

      }
    },

    upload() {
      this.fileSH = true
      this.$store.commit('setscroll', {
        scroll: this.fileSH,
      });
    },
    //文件上传时事件
    beforeUpload() {
      this.uploadBtn = false
    },
    //文件上传数量超过1个提示事件
    handleExceed(files, fileList) {
      this.$message.warning(
          `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`
      );
    },

    //文件上传后事件(自动上传)
    async uploadImg(item) {
      let formData = new FormData();
      formData.append("file", item.file);
      formData.append("is_base64", 0);
      try {
        const res = await fileUpload(formData)
        if (res && res.err_code === 0) {
          this.uploadBtn = true
          this.file.name = this.automatic.name = this.manual.name = res.data.name;
          this.file.path = res.data.path;
          this.$refs.upload.clearFiles()
        } else {
          this.$message({
            type: "warning",
            message: res.err_msg,
          })
        }
      } finally {
        this.uploadBtn = true;
      }
    },

    //识别按钮点击事件
    distinguishBtn(automatic) {
      this.$refs[automatic].validate(async (valid) => {
        try {
          if (this.automatic.name != '') {
            this.distinguishDis = true
            this.contSH = true;
            this.loadingUp = true
            const res = await fileDistinguish({...this.filters, ...this.file})
            if (res && res.err_code === 0) {
              this.distinguishDis = false
              this.loadingUp = false
              this.automatic.made_at = res.data.made_at;
              this.distinguishSh = false;
              if (this.typeSelect.indexOf(res.data.matter) == -1) {
                this.automatic.matter = res.data.matter;
              } else {
                this.$message({
                  type: "warning",
                  message: '上传文件的官文类型不存在，请选择成已有的官文类型',
                })
                this.automatic.matter = ''
              }
            } else {
              this.$message({
                message: res.err_msg,
                type: 'warning'
              })
              this.contSH = false
              this.fileList = []
              this.distinguishDis = false
              this.distinguishSh = true
            }
          }
        } catch (e) {
          this.contSH = false
          this.fileList = []
          this.distinguishDis = false
          this.distinguishSh = true
        }

      })
    },
    //上传官文提交事件
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.distinguishDis = this.submitDis = true
            const res = await fileSubmit({...this.file, ...this.manual, ...this.filters})
            if (res && res.err_code === 0) {
              this.$refs[formName].resetFields();
              this.success()
            } else {
              this.$message({
                type: "warning",
                message: res.err_msg,
              })
              this.fileList = []
              this.distinguishDis = this.submitDis = false
            }
          } catch (e) {
            this.fileList = []
            this.distinguishDis = this.submitDis = false
          }
        } else {
          return false;
        }
      })


    },
    // 识别提交
    submitAutomatic(automatic) {
      this.$refs[automatic].validate(async (valid) => {
        if (valid) {
          try {
            this.distinguishDis = this.submitDis = true
            const res = await fileSubmit({...this.file, ...this.automatic, ...this.filters})
            if (res && res.err_code === 0) {
              this.success()
            } else {
              this.$message({
                type: "warning",
                message: res.err_msg,
              })
              this.distinguishDis = this.submitDis = false
            }
          } catch (e) {
            this.fileList = []
            this.distinguishDis = this.submitDis = false
          }
        } else {
          return false
        }
      })


    },
    //上传官文提交成功事件
    success() {
      this.$message({
        type: "success",
        message: "提交成功!",
      });
      this.fileSH = false;
      this.$store.commit('setscroll', {
        scroll: this.fileSH,
      });
      this.manual = this.$options.data().manual; //清空表单
      this.automatic = this.$options.data().automatic; //清空表单
      this.distinguishSh = true;
      this.contSH = false;
      this.tabtNum = 1
      this.fileList = []
      this.file.name = ''
      this.distinguishDis = this.submitDis = false
      this.domesticDetails()
    },
    //上传官文取消事件
    empty() {
      this.fileSH = false;
      this.$store.commit('setscroll', {
        scroll: this.fileSH,
      });
      this.$refs.ruleForm.resetFields()
      // this.$refs.automatic.resetFields()
      this.tabtNum = 1
      this.distinguishSh = true;
      this.contSH = false;
      this.$refs.upload.clearFiles()
      this.file = this.$options.data().file; //清空表单
    },

    //编辑别名点击其他地方，取消编辑事件
    handleClose(e) {
      this.aliaSh = false;
    },
  },
};
</script>

<style lang="less" scoped>
.list_t{
  padding: 9px 10px;
}
.tempEdit {
  .w33 {
    margin-bottom: 15px;

    .mlf10 {
      .el-select {
        display: block;
      }
    }
  }

  .textarea {

    span {
      display: inline-block;
    }
  }

  .el-icon-edit-outline {
    color: #409EFF;
  }

  .el-icon-delete {
    color: #F56C6C;
  }

}

.attribute {

  p {
    margin-right: 1%;

    strong {
      max-width: 155px;
    }
  }
}

::v-deep .el-tabs{
  .el-tabs__header{
    margin: 0;
    .el-tabs__nav-wrap{
      padding: 0 10px;
    }

  }
  .el-table{
    .el-button--small{
      padding: 9px 15px !important;
    }
    .el-image{
      width: 70px;
      height: 70px;
      border: 1px solid #DDD;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      img{
        max-height: 68px;
        max-width: 68px;
        min-width: unset;
        width: unset;
        height: unset;
      }
    }
  }
}



.attribute_dia {
  ::v-deep .el-dialog {
    .el-dialog__header {
      padding: 15px 15px 10px;
    }

    .el-dialog__body {
      padding: 10px 20px 30px 10px;

      .el-form {
        padding: 0;

        .el-form-item {
          margin: 0;
          display: flex;
          align-items: center;

          .el-form-item__label {
            width: 15%;
            line-height: unset;
          }

          .el-form-item__content {
            width: 85%;
          }
        }

        .el-form-item:nth-last-of-type(1) {
          .el-form-item__content {
            line-height: unset;
          }
        }
      }

    }

    .el-dialog__footer {
      padding: 8px 16px 16px;
    }
  }

}

@media screen and (max-width: 992px) {

  .tempEdit {
    .w33 {
      width: 100% !important;

      span {
        width: 70px;
      }

      .mlf10 {
        width: 60%;
      }
    }

    .textarea {
      width: 100%;

      span {
        width: 70px;
      }
    }
  }

  .attribute {
    .w30 {
      width: 100% !important;
    }

    p {

      strong {
        max-width: 155px;
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .tempEdit {
    .w33 {
      width: 48% !important;

      span {
        width: 70px;
      }

      .mlf10 {
        width: 47%;
      }
    }

    .textarea {
      width: 75%;

      span {
        width: 70px;
      }
    }
  }

  .attribute {
    .w30 {
      width: 48% !important;
    }

    p {

      strong {
        max-width: 155px;
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .tempEdit {
    .w33 {
      width: 48% !important;

      span {
        width: 90px;
      }

      .mlf10 {
        width: 60%;
      }
    }

    .textarea {
      span {
        width: 90px;
      }
    }

  }

  .attribute {
    .w30 {
      width: 48% !important;
    }

    p {

      strong {
        max-width: 155px;
      }
    }
  }
}

@media screen and (min-width: 1440px) and (max-width: 1650px) {

  .tempEdit {
    .w33 {
      span {
        width: 70px;
      }

      .mlf10 {
        width: 55%;
      }
    }

    .textarea {
      span {
        width: 70px;
      }
    }
  }

  .attribute {
    p {
      strong {
        max-width: 125px;
      }
    }
  }
}

@media screen and (min-width: 1650px) and (max-width: 1920px) {
  .tempEdit {
    .w33 {
      span {

        width: 90px;

      }

      .mlf10 {
        width: 60%;

      }

    }

    .textarea {
      span {
        width: 90px;
      }
    }

  }

  .attribute {
    p {
      strong {
        max-width: 155px;
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .tempEdit {
    .w33 {
      span {

        width: 90px;

      }

      .mlf10 {
        width: 65%;

      }

    }

    .textarea {
      span {
        width: 90px;
      }
    }

  }
}

</style>
