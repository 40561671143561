<template>
  <div class="">
    <div class="toPaidt clearfix">
      <img src="@/assets/img/personal/icon-logo.png" alt="" />
      <div class="fr">
        <h3>AAAAAAAAA</h3>
        <ul>
          <li>
            <strong>商标状态：</strong>
            <span>申请中</span>
          </li>
          <li>
            <strong>商品类别：</strong>
            <span>25类 服装鞋帽</span>
          </li>
          <li>
            <strong>申请/注册号：</strong>
            <span>57804032</span>
          </li>
          <li>
            <strong>订单状态：</strong>
            <font v-if="status == 0" :class="{ on: status == 0 }">已支付</font>
            <font v-else>待支付</font>
          </li>
        </ul>
      </div>
    </div>
    <div class="toPaidc">
      <h3>订单信息</h3>
      <ul>
        <li>
          <strong>订单编号：</strong>
          <span>CN2021079863</span>
        </li>
        <li>
          <strong>订单金额：</strong>
          <span>￥99999</span>
        </li>
        <li>
          <strong>业务类型：</strong>
          <span>自助办理</span>
        </li>
      </ul>

      <ul>
        <li>
          <strong>委托方：</strong>
          <font>CN2021079863</font>
        </li>
        <li>
          <strong>受托方：</strong>
          <span>江苏省宁海商标事务所有限公司</span>
        </li>
      </ul>

      <ul>
        <li>
          <strong>代理顾问：</strong>
          <span>老六</span>
        </li>
        <li>
          <strong>创建时间：</strong>
          <span>2021-07-19</span>
        </li>
      </ul>
      <div class="btn-paid" v-if="status == 0">
        <button>合同</button>
      </div>
      <div class="btn" v-else>
        <div class="to-paid">
          <el-select v-model="paid" placeholder="请选择">
            <el-option
                v-for="item in paidSelect.select"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <button>取消</button>
      </div>
    </div>

    <div class="toPaidb">
      <h3>
        <img
          src="@/assets/img/personal/icon-xia.png"
          alt=""
          @click="matter = !matter"
          :class="{ on: !matter }"
        /><strong :class="{on:matter}">代理事项</strong>
      </h3>
      <div class="toPaid-list clearfix" v-show="matter">
        <img src="@/assets/img/personal/icon-logo.png" alt="" />
        <div class="toPaid-listr fr">
          <div class="">
            <span>申请人：温州艾芮卡电子商务有限公司</span>
            <span>事项：商标申请</span>
            <span>类别：25类 服装鞋帽</span>
          </div>
          <div class="">
            <span>申请/注册号：57804032</span>
            <span
              >商品项目<img
                src="@/assets/img/personal/icon-xia.png"
                :class="{ on: project }"
                alt=""
                @click="project = !project"
            /></span>
            <div class="project" v-show="project">
              <a href="">注册申请中</a>
              <a href="">注册申请中</a>
              <a href="">注册申请中</a>
              <a href="">注册申请中</a>
              <a href="">注册申请中</a>
              <a href="">注册申请中</a>
            </div>
          </div>

          <p>当前进度 <span>注册申请中</span></p>
        </div>
        <button>档案</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      status: 0,
      project: false,
      matter: true,
      paid: "",
      paidSelect: {
        input: "支付",
        select: [
          {
            name: "支付宝",
          },
          {
            name: "微信",
          },
          {
            name: "对公转账",
          },
        ],
      },
    };
  },
  methods: {
    paidSelects(item) {
      this.paid = item;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/toPaid.less";
</style>