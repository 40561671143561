<template>
  <div class="">
    <h2>商标注册</h2>
    <div class="registert">
      <ul>
        <li>填写商标信息</li>
        <li>填写申请人信息</li>
        <li>支付订单费用</li>
      </ul>
    </div>
    <div class="register-applicant">
      <h3>
        <span>申请人信息</span>
      </h3>
      <form>
        <div class="applicant-type">
          <strong>申请人类型</strong>

          <span @click="typeNum = 1" :class="{ on: typeNum == 1 }">企业</span>
          <span @click="typeNum = 2" :class="{ on: typeNum == 2 }">个人</span>
        </div>
        <div class="applicant-name">
          <strong>公司名称</strong>
          <div class=""></div>
          <span>+ 新建</span>
        </div>
        <div class="">
          <strong>统一社会信用代码</strong>
          <input
            type="text"
            placeholder="913200004660040703"
            v-model="information.code"
          />
        </div>
        <div class="">
          <strong>营业执照所在地区</strong>
          <input
            type="text"
            placeholder="江苏省南京市秦淮区"
            v-model="information.region"
          />
        </div>
        <div class="">
          <strong>邮政编码</strong>
          <input type="text" placeholder="210000" />
        </div>
        <div class="">
          <strong>营业执照详情地址</strong>
          <input type="text" placeholder="中山南路8号苏豪大厦15楼" />
        </div>
      </form>
      <h3>
        <span>联系人信息</span>
      </h3>
      <form>
        <div class="">
          <strong>联系人名称</strong>
          <input type="text" />
        </div>
        <div class="">
          <strong>联系电话</strong>
          <input type="text" placeholder="12312341234" />
        </div>
        <div class="">
          <strong>电子邮箱</strong>
          <input type="text" placeholder="1231231234@qq.com" />
        </div>
        <div class="">
          <strong>座机电话</strong>
          <input type="text" placeholder="区号 - 座机号码" />
        </div>
      </form>
      <h3>
        <span>材料信息</span>
        <p>
          （可支付后，在订单管理上传）
          <img
            src="@/assets/img/personal/fllApplicant-up.png"
            alt=""
            v-if="updateSH"
            @click="updateSH = !updateSH"
          />
          <img
            v-else
            @click="updateSH = !updateSH"
            src="@/assets/img/personal/fllApplicant-down.png"
            alt=""
          />
        </p>
      </h3>
      <div class="applicant-upload" v-show="!updateSH">
        <div class="">
          <strong>营业执照<br />副本复印件</strong>
          <div class="upload-img">
<!--            <file-upload></file-upload>-->
          </div>
          <div class="upload-example" @mouseleave="example1 = false">
            <img
              class="upload-exhibition"
              :src="exampleImg1"
              alt=""
              @mousemove="example1 = true"
            />
            <div class="" v-show="example1">
              <a :href="exampleImg1" target="_blank">查看示例</a>
            </div>
          </div>
        </div>
        <div class="">
          <strong>委托书<br />生成委托书</strong>
          <div class="upload-img">
<!--            <file-upload></file-upload>-->
          </div>
          <div class="upload-example" @mouseleave="example2 = false">
            <img
              class="upload-exhibition"
              :src="exampleImg2"
              alt=""
              @mousemove="example2 = true"
            />
            <div class="" v-show="example2">
              <a :href="exampleImg2" target="_blank">查看示例</a>
            </div>
          </div>
        </div>
        <div class="">
          <strong>优先权证明</strong>
          <div class="upload-img">
<!--            <file-upload></file-upload>-->
          </div>
          <div class="upload-example" @mouseleave="example3 = false">
            <img
            class="upload-exhibition"
            :src="exampleImg3"
            alt=""
            @mousemove="example3 = true"
          />
          <div class="" v-show="example3">
              <a :href="exampleImg3" target="_blank">查看示例</a>
            </div>
          </div>
          
        </div>
      </div>

      <div class="applicant-next">
        <span @click="per">上一步</span>
        <span class="on" @click="next">下一步</span>
      </div>
    </div>
  </div>
</template>

<script>
import storage from "@/utils/storage.js";
import exampleImg1 from "@/assets/img/personal/upload1.png";//营业执照副本复印件的示例图片
import exampleImg2 from "@/assets/img/personal/upload2.png";
import exampleImg3 from "@/assets/img/personal/upload3.png";
export default {
  data() {
    return {
      typeNum: 1, //申请人类型的tab切换
      updateSH: true, //控制材料信息的显示与隐藏
      //要存到storage里的数据字段信息
      information: {
        code: "", //统一社会信用代码
        region: "", //营业执照所在地区
      },
      exampleImg1:exampleImg1,//营业执照副本复印件的示例图片
      exampleImg2:exampleImg2,//委托书生成委托书的示例图片
      exampleImg3:exampleImg3,//优先权证明的示例图片
      example1:false,//控制营业执照副本复印件，查看示例大图的显示与隐藏
      example2:false,//控制委托书生成委托书，查看示例大图的显示与隐藏
      example3:false,//控制优先权证明，查看示例大图的显示与隐藏
    };
  },
  created() {
    //预加载存在storage里的数据，有就渲染
    if (storage.getItem("information")) {
      this.information.code = JSON.parse(storage.getItem("information")).code;
      this.information.region = JSON.parse(
        storage.getItem("information")
      ).region;
    } else {
      this.information.code = "";
      this.information.region = "";
    }
  },
  methods: {
    //下一步，并存储所填信息在storage里
    next() {
      storage.setItem("information", JSON.stringify(this.information));
      this.$router.push("/personal/handling/payOrder");
    },
    //上一步
    per() {
      this.$router.push("/personal/handling");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/fllApplicant.less";
</style>