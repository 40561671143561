<template>
  <div class="cashier">
    <h2>收银台</h2>
    <div class="cashier-total">
      <img src="@/assets/img/personal/icon-cashier-total.png" alt="" />
      <div class="totalr">
        <p>
          <strong>订单提交成功，应付金额：<font>￥2247.00</font></strong>
          <span
            >( 业务总价：<font>¥ 2,247.00</font>优惠：<font>¥0 </font> )</span
          >
        </p>
        <p>
          <span>订单编号：Z11103944771</span>
          <span>购买服务：智能自助注册</span>
        </p>
      </div>
    </div>
    <div class="cashier-method">
      <h3><strong>选择支付方式</strong></h3>
      <ul>
        <li @click="methodWec">
          <img
            src="@/assets/img/personal/icon-cashier-method1.png"
            alt=""
          /><span>微信支付</span>
        </li>
        <li @click="methodAlipay">
          <img
            src="@/assets/img/personal/icon-cashier-method2.png"
            alt=""
          /><span>支付宝支付</span>
        </li>
        <li @click="offline">
          <img
            src="@/assets/img/personal/icon-cashier-method3.png"
            alt=""
          /><span>线下支付</span>
        </li>
      </ul>
    </div>

    <div class="cashier-payment" v-show="paymentSH">
      <div class="payment-cont" v-if="contSH">
        <h4>
          <img :src="payment.img" alt="" />
          {{ payment.codeMsg }}支付
          <img
            src="@/assets/img/personal/icon-chose-delete.png"
            class="deteal"
            @click="paymentSH = false"
            alt=""
          />
        </h4>
        <div class="payment-code">
          <img src="@/assets/img/personal/cashier-code.png" alt="" />
          <p>打开手机{{ payment.codeMsg }}扫描</p>
          <p>二维码以完成支付</p>
        </div>
        <p>扫一扫付款</p>
        <strong>￥2247</strong>
      </div>
      <div class="payment-cont1 " v-else>
        <h4>
          线下支付
          <img
            src="@/assets/img/personal/icon-chose-delete.png"
            class="deteal"
            @click="paymentSH = false"
            alt=""
          />
        </h4>
        <ul>
          <li>
            <strong>收款人 </strong><span>江苏省宁海商标事务所有限公司</span>
          </li>
          <li><strong>开户行 </strong><span>中国工商银行股份有限公司</span></li>
          <li><strong>账 号 </strong><span>0302098009100071515</span></li>
        </ul>
        <h5>需支付金额</h5>
        <h3>￥2247</h3>
        <p>汇款时请备注订单号：<font>Z11103944771</font> ，有利于快速递交</p>
        <p>上传转账凭证后，将在1个工作日内确认到款，请您耐心等待</p>
        <span class="update">上传凭证</span>
      </div>
    </div>
  </div>
</template>

<script>
import title1 from "@/assets/img/personal/icon-cashier-method1.png";
import title2 from "@/assets/img/personal/icon-cashier-method2.png";
export default {
  data() {
    return {
      payment: {
        img: "",
        codeMsg: "",
      },
      paymentSH: false,
      contSH: true,
    };
  },
  methods: {
    methodWec() {
      this.paymentSH = true;
      this.payment.img = title1;
      this.payment.codeMsg = "微信";
    },
    methodAlipay() {
      this.paymentSH = true;
      this.payment.img = title2;
      this.payment.codeMsg = "支付宝";
    },
    offline() {
      this.paymentSH = true;
      this.contSH = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/cashier.less";
</style>