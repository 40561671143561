
<template>
  <div class="">
    <h2>
      干预商标官文<span v-if="managementList.length != 0">{{ pageSize }} </span>
    </h2>
    <!-- 表单 -->
    <form  class="acvhives" ref="filters" v-on:submit.prevent>
      <el-row class="formInput" :class="{on:formOpen}" >
        <el-col :xs="10" :sm="10" :md="7" :lg="7" :xl="7">
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="6" :sm="7" :md="7" :lg="6" :xl="4"><strong>商标名称</strong></el-col>
            <el-col :xs="18" :sm="17" :md="17" :lg="18" :xl="20">
              <el-input
                  clearable
                  size="small"
                  v-model="filters.tmname"
                  placeholder="请输入商标名称"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="10" :sm="10" :md="7" :lg="7" :xl="7">
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="6" :sm="7" :md="7" :lg="6" :xl="4"><strong>商标号</strong></el-col>
            <el-col :xs="18" :sm="17" :md="17" :lg="18" :xl="20">
              <el-input

                  clearable
                  size="small"
                  v-model="filters.tmcode"
                  placeholder="请输入商标号"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="10" :sm="10" :md="7" :lg="7" :xl="7">
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="6" :sm="7" :md="7" :lg="6" :xl="4"><strong>官文类型</strong></el-col>
            <el-col :xs="18" :sm="17" :md="17" :lg="18" :xl="20">
              <el-select
                  clearable
                  size="small"

                  v-model="filters.matter"
                  filterable
                  placeholder="请选择官文类型"
              >
                <el-option
                    v-for="item in typeFromSelect"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="10" :sm="10" :md="7" :lg="7" :xl="7">
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="6" :sm="7" :md="7" :lg="6" :xl="4"><strong>商标类别</strong></el-col>
            <el-col :xs="18" :sm="17" :md="17" :lg="18" :xl="20">
              <el-select
                  clearable
                  size="small"
                  multiple
                  collapse-tags
                  v-model="filters.intcls"
                  filterable
                  placeholder="请选择商标类别"
              >
                <el-option
                    v-for="item in categorySelect"
                    :key="item.id"
                    :label="item.text"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="10" :sm="10" :md="7" :lg="7" :xl="7">
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="6" :sm="7" :md="7" :lg="6" :xl="4"><strong>申请人</strong></el-col>
            <el-col :xs="18" :sm="17" :md="17" :lg="18" :xl="20">
              <el-select
                  clearable
                  size="small"

                  v-model="filters.appname"
                  filterable
                  placeholder="请选择申请人"
              >
                <el-option
                    v-for="item in applicantSelect"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>

      </el-row>
      <el-row class="formOpen">
        <el-col  :span="24"  :class="{on:formOpen}" >
          <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
        </el-col>
      </el-row>
      <el-button
          type="primary"
          size="small"
          class="search"
          icon="el-icon-search"
          @click="managementLists(filters.page = 1)"
      >查询</el-button
      >
      <el-button type="reset" :class="{on:formOpen}" size="small" class="reset" @click="reset" icon="el-icon-refresh-left"
      >重置</el-button
      >
    </form>

    <div class="mainrb">
      <div class="managementBt clearfix">
        <router-link to="/personal/trademarkAdmin/upload">
          <el-button
              type="primary"
              size="small"
              class="search"
              icon="el-icon-upload2"
          >
            批量上传
          </el-button>
        </router-link>
        <span v-if="managementList.length != 0">
          <el-button   size="small" @click="download" v-if="down" icon="el-icon-download" :disabled="managementList.length == 0">批量下载</el-button>
          <el-button  v-else size="small" :loading="true">下载中</el-button>
        </span>
        <div class="page-up">
          <el-pagination
              background
              size="small"
              layout="prev, next"
              @current-change="managementLists"
              :current-page.sync="filters.page"
              :page-sizes="[30, 50, 100]"
              :page-size="filters.per_page"
              :total="pageSize">
          </el-pagination>
        </div>
      </div>
      <!-- 表格 -->
      <el-skeleton  :loading="loading" animated :count="1">
        <template slot="template">
          <el-table
              :class="{on:!formOpen,ios:$store.state.heights}"
              :data="managementList"
              @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column  width="50" label="序号">
              <template>
                <el-skeleton-item variant="p" style="width: 100%"/>
              </template>
            </el-table-column>
            <el-table-column label="官文类型">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="商标名称">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="申请人">
              <template>
                <el-skeleton-item variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="商标类别">
              <template>
                <el-skeleton-item variant="p" />
              </template>
            </el-table-column>
            <el-table-column label="商标号">
              <template>
                <el-skeleton-item variant="p" />
              </template>
            </el-table-column>
            <el-table-column label="收文日期">
              <template>
                <el-skeleton-item variant="p" />
              </template>
            </el-table-column>
            <el-table-column label="上传日期">
              <template>
                <el-skeleton-item variant="p" />
              </template>
            </el-table-column>
            <el-table-column label="下载">
              <template>
                <el-skeleton-item variant="p" />
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-table
            :class="{on:!formOpen,ios:$store.state.heights}"
            :data="managementList"
            height="100%"
            @selection-change="handleSelectionChange"
        >
          <template slot="empty">
            <el-empty :image-size="80"></el-empty>
          </template>
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column type="index" width="50" label="序号"></el-table-column>
          <el-table-column label="官文类型" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <a target="_blank"
                 :href="`${scope.row.url}&preview=true`" >
                {{scope.row.matter}}
              </a>
            </template>
          </el-table-column>
          <el-table-column label="商标名称" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span v-if="!scope.row.tmname  || scope.row.tmname == '图形'">
                <el-tooltip  v-if="!scope.row.alias" class="item" effect="dark" content="可在详情中编辑别名" placement="right">
                <span>图形 <i class="el-icon-info"></i> </span></el-tooltip>
                <el-tooltip v-else  class="item" effect="dark" content="可在详情中编辑别名" placement="right">
                <span>{{ scope.row.alias }} <i class="el-icon-info"></i> </span>
              </el-tooltip>
              </span>

              <span v-else>{{ scope.row.tmname }}</span>
            </template>
          </el-table-column>
          <el-table-column label="申请人" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <router-link v-if="scope.row.client_id != 0" :to="{
                  name: 'newlyBuild',
                    params: { id:  scope.row.client_id  },
                }" target="_blank">
                {{scope.row.appname}}
              </router-link>
              <template v-else>
                {{scope.row.appname}}
              </template>
            </template>
          </el-table-column>
          <el-table-column label="商标类别" width="150" prop="clstext"> </el-table-column>
          <el-table-column label="商标号" width="150">
            <template slot-scope="scope">
              <router-link
                  target="_blank"
                  :to="{
                name: 'domesticDetails',
                params: { tmcode: scope.row.tmcode, intcls: scope.row.intcls },
              }">
                {{scope.row.tmcode}}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="收文日期" width="100" prop="made_at"></el-table-column>
          <el-table-column label="上传日期" width="100" prop="created_at"></el-table-column>
          <el-table-column label="下载" width="100" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="open(scope.row.url)" icon="el-icon-download">下载</el-button>

            </template>
          </el-table-column>
        </el-table>
      </el-skeleton>

      <!-- 分页 -->
      <el-pagination
          @size-change="changeRowNum"
          @current-change="managementLists(backTop)"
          :current-page.sync="filters.page"
          :page-sizes="[30, 50, 100]"
          :page-size="filters.per_page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageSize"
      >
      </el-pagination>
    </div>

  </div>
</template>


<script>
import storage from '@/utils/storage'
import { maintain,managementDown,fileUpload,managementDistinguish,managementSave } from '@/api/personal.js'
export default {
  name: 'Archives',
  data() {
    return {
      formOpen:false,
      down:true,//控制导出中的显示与隐藏
      loading: true,//控制列表的骨架屏
      loadingUp:true,//控制识别文件的骨架屏
      uploadBtn:true,//控制上传文件按钮加载中的显示与隐藏
      fileList: [],//记录上传文件成功的数组
      managementList: [],//列表数据
      array: {
        ids:[],
      }, //批量下载的数组
      tabtNum: 1, //控制，手动添加，还是智能识别
      fileSH: false, //控制上传的显示与隐藏
      file: {
        name: "", //上传文件的提示文本
        path: "", //上传文件的路径
      },
      butSH: true,//控制上传官文提交，取消按钮按钮显示与隐藏
      submitBtn:true,
      distinguishSh: true,//控制识别按钮的显示与隐藏
      contSH: false,//控制识别中的骨架屏
      //官文搜索表单字段
      filters: {
        matter: "", //官文名称
        tmname: "", //商标名称
        intcls: "", //商标类别
        tmcode: "", //商标号
        appname: "", //申请人
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
      },
      categorySelect:storage.getIntcls(),
      typeFromSelect:[],
      typeSelect: [{id: '01', text: '商标注册证'},{id: '02', text: '商标注册申请受理通知书'},{id: '03', text: '商标驳回通知书'},{id: '04', text: '商标部分驳回通知书'},{id: '05', text: '初步审定公告通知书'},],//官文类型的下拉数据
      applicantSelect: [],//申请人的下拉数据
      manual:{//手动上传答案字段
        made_at: "", //上传日期
        tmcode: "",
        matter: "",
      },
      automatic: {//自动上传答案字段
        matter: "",
        tmcode: "",
        made_at: "",
      },
      backTop:true,
      pageSize: 0,//总条数

    };
  },
  created() {
    this.managementLists();
    this.select()
  },
  methods: {
    //文件上传时事件
    beforeUpload(){
      this.uploadBtn = false
    },
    //文件上传数量超过1个提示事件
    handleExceed(files, fileList) {
      this.$message.warning(
          `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`
      );
    },
    //文件上传事件
    async uploadImg(item) {
      let formData = new FormData();
      formData.append("file", item.file);
      formData.append("is_base64", 0);
      try{
        const res  = await fileUpload(formData)
        if(res && res.err_code === 0){
          this.$message({
            message: '上传成功',
            type: 'success'
          })
          this.uploadBtn = true
          this.file.name = res.data.name;
          this.file.path = res.data.path;
          this.$refs.upload.clearFiles()
        }
      }finally{
        this.uploadBtn = true;
      }
    },
    //获取下拉数据事件
    async select () {
      let s = await storage.getSelects( 'right_app_names','tm_right_doc_types')
      this.applicantSelect = s.right_app_names
      this.typeFromSelect = s.tm_right_doc_types
    },
    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.managementLists(this.backTop);
    },
    //渲染列表事件
    async managementLists(backTop) {
      this.loading = true;
      this.managementList = storage.getList();
      try{
        const res = await maintain(this.filters)
        if(res && res.err_code === 0){
          if(backTop === true){
            storage.backTop()
          }
          this.loading = false;
          this.managementList = res.data.list;
          this.pageSize = res.data.total;
        }else {
          this.managementList = []
        }
      }finally{
        this.loading = false;
      }

    },
    //重置事件
    reset() {
      this.filters = this.$options.data().filters; //清空表单
      this.managementLists();
    },
    //批量下载点击事件
    async download() {
      if(this.array.ids.length == 0){
        this.$confirm("请选中要下载的官文序号", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
      }else{
        this.down = false
        try{
          const res = await managementDown(this.array)
          if(res && res.err_code === 0){
            window.open(res.data.url)
            this.down = true
          }

        }finally{
          this.down = true
        }
      }

    },
    //记录选择要下载的id事件
    handleSelectionChange(val) {
      this.array.ids = [];
      val.forEach((item) => {
        this.array.ids.push(item.id);
      });
    },
    open(url) {
      location.href = url
    },
    // 上传文件tab切换判断提交按钮是否隐藏事件
    intelligence(){
      this.tabtNum = 2
      if(this.distinguishSh){
        this.butSH = false
      }else{
        this.butSH = true
      }
    },
    //上传官文识别按钮点击事件
    async distinguishBtn() {
      if (this.file.name == "") {
        this.$confirm("请上传文件", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      } else {
        this.contSH = true;
        try{
          const res = await managementDistinguish(this.file)
          if(res && res.err_code === 0){
            this.loadingUp = false
            this.automatic.made_at = res.data.made_at;
            this.automatic.tmcode = res.data.tmcode;
            this.butSH = true;
            this.distinguishSh = false;
            if(this.typeSelect.indexOf(res.data.matter) == -1){
              this.automatic.matter = res.data.matter;
            }else{
              this.$confirm("上传文件的官文类型不存在，请选择成已有的官文类型", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              });
              this.automatic.matter = ''
            }
          }
        }finally{
          this.loadingUp = false
        }
      }
    },
    //上传官文提交事件
    async submit() {
      if (this.tabtNum == 1) {
        if (this.manual.tmcode == "") {
          this.$confirm("选项不能为空", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          });
        }
        else {
          this.submitBtn = false
          try{
            const res = await managementSave({...this.file,...this.manual})
            // if(res && res.err_code === 0){
            this.success()
            // }
          }finally{
            this.submitBtn = true
          }

        }
      } else {
        try{
          const res = await managementSave({...this.file,...this.automatic})
          // if(res && res.err_code === 0){
          this.success()
          // }
        }finally{
          this.submitBtn = true
        }
      }

    },
    //上传官文提交成功事件
    success(){
      this.$message({
        type: "success",
        message: "提交成功!",
      });
      this.managementLists()
      this.submitBtn = true
      this.fileSH = false;
      this.$store.commit('setscroll',{
        scroll:this.fileSH,
      });
      this.fileList = []
      this.file.name = ''
      this.manual = this.$options.data().manual; //清空表单
      this.automatic = this.$options.data().automatic; //清空表单
      this.distinguishSh = true;
      this.contSH = false;
      this.tabtNum =1
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/management.less";
</style>
