<template>
  <div class="">
    <h2>
      干预商标<span v-if="domesticList.length != 0">{{ pageSize }} </span>
    </h2>
    <!-- 表单 -->
    <form action="" ref="filters" v-on:submit.prevent>
      <el-row class="input formInput" :class="{on:formOpen}">
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row  align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="6" :md="6" :sm="6" :xl="4" :xs="4"><strong>商标名称</strong></el-col>
            <el-col :lg="18" :md="18" :sm="18" :xl="20" :xs="20">
              <el-input

                  v-model="filters.tmname"
                  clearable
                  placeholder="请输入商标名称或图形商标别名"
                  size="small"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row  align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="6" :md="6" :sm="6" :xl="4" :xs="4"><strong>商标号</strong></el-col>
            <el-col :lg="18" :md="18" :sm="18" :xl="20" :xs="20">
              <el-input

                  v-model="filters.tmcode"
                  clearable
                  placeholder="请输入商标号"
                  size="small"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row  align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="6" :md="6" :sm="6" :xl="4" :xs="4"><strong>商标类别</strong></el-col>
            <el-col :lg="18" :md="18" :sm="18" :xl="20" :xs="20">
              <el-select
                  v-model="filters.intcls"
                  multiple
                  collapse-tags
                  clearable
                  filterable
                  placeholder="请选择商标类别"
                  size="small"
              >
                <el-option
                    v-for="item in intclsSelect.select"
                    :key="item.id"
                    :label="item.text"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row  align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="6" :md="6" :sm="6" :xl="4" :xs="4"><strong>申请人</strong></el-col>
            <el-col :lg="18" :md="18" :sm="18" :xl="20" :xs="20">
              <el-select
                  v-model="filters.appname"

                  clearable
                  filterable
                  placeholder="请选择申请人"
                  size="small"
              >
                <el-option
                    v-for="item in appnameSelect"
                    :key="item.text"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row  align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="6" :md="6" :sm="6" :xl="4" :xs="4"><strong>被申请人</strong></el-col>
            <el-col :lg="18" :md="18" :sm="18" :xl="20" :xs="20">
              <el-select
                  v-model="filters.respondent"

                  clearable
                  filterable
                  placeholder="请选择被申请人"
                  size="small"
              >
                <el-option
                    v-for="item in agentnameSelect"
                    :key="item.text"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row  align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="6" :md="6" :sm="6" :xl="4" :xs="4"><strong>干预方式</strong></el-col>
            <el-col :lg="18" :md="18" :sm="18" :xl="20" :xs="20">
              <el-select
                  v-model="filters.intervene"

                  clearable
                  filterable
                  placeholder="请选择干预方式"
                  size="small"
              >
                <el-option
                    v-for="item in interveneSelect"
                    :key="item.text"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>

        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row  align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="6" :md="6" :sm="6" :xl="4" :xs="4"><strong>申请日期</strong></el-col>
            <el-col :lg="18" :md="18" :sm="18" :xl="20" :xs="20">
              <el-date-picker
                  v-model="filters.appdate"

                  :picker-options="pickerOptions"
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row  align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="6" :md="6" :sm="6" :xl="4" :xs="4"><strong>注册日期</strong></el-col>
            <el-col :lg="18" :md="18" :sm="18" :xl="20" :xs="20">
              <el-date-picker

                  v-model="filters.regdate"
                  :picker-options="pickerOptions"
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row  align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="6" :md="6" :sm="6" :xl="4" :xs="4"><strong>商标状态</strong></el-col>
            <el-col :lg="18" :md="18" :sm="18" :xl="20" :xs="20">
              <el-select

                  v-model="filters.tmlaw"
                  clearable
                  filterable
                  placeholder="请选择商标类别"
                  size="small"
              >
                <el-option
                    v-for="item in tmlawSelect.select"
                    :key="item.id"
                    :label="item.value"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="formOpen">
        <el-col  :xs="24"   :class="{on:formOpen}" >
          <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
        </el-col>
      </el-row>
      <el-button size="small" type="primary" class="search" @click="perindexList(filters.page = 1)" icon="el-icon-search"
      >查询
      </el-button>
      <el-button size="small" :class="{on:formOpen}" type="reset" @click="reset" class="reset" icon="el-icon-refresh-left"
      >重置</el-button
      >
    </form>
    <!-- 列表 -->
    <div class="mainrb">
      <div class="listT clearfix">
        <el-button type="primary" size="small" @click=" downloadDia = true" v-if="down" icon="el-icon-document">导出数据</el-button>
        <el-button type="primary"  v-else size="small" :loading="true">导出中</el-button>
        <el-upload
            class="upload-demo"
            action=""
            :before-upload="beforeAvatarUpload"
            :http-request="downloadAdd"
            :file-list="fileListAdd">
          <el-button type="primary" size="small"  icon="el-icon-document-add">导入数据</el-button>
        </el-upload>

        <el-button type="text" @click="open" size="small"  icon="el-icon-download">下载模板</el-button>
        <div class="listTr fr">
          <div class="pagination">
            <el-pagination
                background
                size="small"
                layout="prev, next"
                :page-sizes="[30, 50, 100]"
                :page-size="filters.per_page"
                @current-change="perindexList"
                :current-page.sync="filters.page"
                :total="pageSize">
            </el-pagination>
          </div>
          <strong @click="liston = 0" :class="{ on: liston == 0 }">
            <img
                v-if="liston == 0"
                src="@/assets/img/search/icon-list1.png"
                alt=""
            />
            <img v-else src="@/assets/img/search/icon-list1-1.png" alt="" />
          </strong>
          <strong @click="liston = 1" :class="{ on: liston == 1 }"
          ><img
              v-if="liston == 1"
              src="@/assets/img/search/icon-list2-1.png"
              alt="" />
            <img v-else src="@/assets/img/search/icon-list2.png" alt=""
            /></strong>

        </div>
      </div>
      <!--       表格列表-->
      <el-table
          :data="domesticList"
          :class="{on:!formOpen,ios:$store.state.heights}"
          @sort-change="sort_change"
          height="90%"
          v-if="liston == 0"
      >
        <template slot="empty">
          <el-empty :image-size="80"></el-empty>
        </template>
        <el-table-column  width="50" label="序号">
          <template slot-scope="scope">
            <el-skeleton  :loading="loading" animated :count="1">
              <template slot="template">
                <el-skeleton-item variant="p" style="width: 100%"/>
              </template>
              <span>{{scope.$index + 1}} </span>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column label="商标名称">
          <template slot-scope="scope" >
            <el-skeleton  :loading="loading" animated :count="1">
              <template slot="template">
                <el-skeleton-item variant="p" style="width: 60%"/>
              </template>
              <template v-if="!scope.row.tmname || scope.row.tmname == '图形'" >
                <template v-if="graphical != scope.row.tmcode">
                  <span @dblclick="edit(scope.row)">{{ !scope.row.alias ? '图形' : scope.row.alias }}</span>
                  <el-tooltip v-if="graphical != scope.row.tmcode"  class="item" effect="dark" content="双击文字编辑别名" placement="right">
                    <i class="el-icon-info"></i>
                  </el-tooltip>
                </template>
                <template v-else>
                  <span v-click-outside="handleClose">
                    <el-input
                        size="small"
                        v-model="alia.alias"
                        placeholder="请填写别名"
                        @keyup.enter.native="input(scope.row)"
                        v-focus
                    ></el-input>
                    <i class="el-icon-check" @click="input(scope.row)"></i>
                  </span>
                </template>
              </template>
              <template v-else>
                {{ scope.row.tmname }}
              </template>
            </el-skeleton>
          </template>

        </el-table-column>
        <el-table-column  label="商标号" width="100">
          <template slot-scope="scope">
            <el-skeleton  :loading="loading" animated :count="1">
              <template slot="template">
                <el-skeleton-item variant="p" style="width: 60%"/>
              </template>
              <template>
                {{scope.row.tmcode}}
              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column label="类别" min-width="100" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-skeleton  :loading="loading" animated :count="1">
              <template slot="template">
                <el-skeleton-item variant="p" style="width: 60%"/>
              </template>
              <template>
                <div class="ellipsis">
                  {{scope.row.clstext}}
                </div>

              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column label="商标状态">
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item variant="p" style="width: 60%"/>
              </template>
              <template>
                <el-tag size="mini"
                        :class="{green:scope.row.tmlaw == '已注册',classA:scope.row.tmlaw == '申请中'}"
                        :type="scope.row.tmlaw == '已初审' ? 'success' : (scope.row.tmlaw == '已销亡' ? 'info' : (scope.row.tmlaw == '已驳回' ? 'danger' : '')) ">
                  {{ scope.row.tmlaw }}
                </el-tag>
              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column label="申请日期" min-width="100" sortable="custom">
          <template slot-scope="scope">
            <el-skeleton  :loading="loading" animated :count="1">
              <template slot="template">
                <el-skeleton-item variant="p" style="width: 60%"/>
              </template>
              <template>
                {{scope.row.appdate}}
              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column label="注册日期" min-width="100" sortable="custom">
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item variant="p" style="width: 60%"/>
              </template>
              <template>
                {{ scope.row.regdate || '-' }}
              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column label="干预方式">
          <template slot-scope="scope">
            <el-skeleton  :loading="loading" animated :count="1">
              <template slot="template">
                <el-skeleton-item variant="p"/>
              </template>
              <template>
                {{ scope.row.intervene }}
              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column label="申请人 & 被申请人" min-width="140">
          <template slot-scope="scope">
            <el-skeleton  :loading="loading" animated :count="1">
              <template slot="template">
                <el-skeleton-item variant="p" style="width: 80%"/>
              </template>
              <template>
                <div>
                  <router-link :to="{ name: 'newlyBuild', params: { id: scope.row.client_id }}" target="_blank">
                    {{ scope.row.appname }}
                  </router-link>
                </div>
                <div>{{scope.row.respondent}}</div>
              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column label="操作"  width="120">
          <template slot-scope="scope">
            <el-skeleton  :loading="loading" animated :count="1">
              <template slot="template">
                <el-skeleton-item variant="p" style="width: 60%"/>
              </template>
              <template>
                <router-link
                    target="_blank"
                    :to="{
                name: 'domesticDetails',
                params: { tmcode: scope.row.tmcode, intcls: scope.row.intcls },
              }"
                >
                  <el-button size="small" type="primary" icon="el-icon-edit">查看</el-button>
                </router-link>
                <el-button icon="el-icon-upload2" size="small" type="primary" @click="upload(scope.row.tmcode)" plain
                >上传官文
                </el-button
                >


              </template>
            </el-skeleton>
          </template>
        </el-table-column>
      </el-table>
      <!-- 块状列表 -->
      <div class="list-list" v-else>
        <el-empty :image-size="80" v-if="domesticList.length == 0"></el-empty>
        <div class="" v-else>
          <el-skeleton  :loading="loading" animated  :cont="1" :class="{on:!formOpen,ios:$store.state.heights}">
            <template slot="template" >
              <div class="listb"  v-for="(item, index) in loadList" :key="index">
                <div class="content">
                  <div class="content-img">
                    <el-skeleton-item
                        variant="image"
                        style="width: 110px;height:110px"
                    />
                  </div>
                  <div class="contentC">
                    <p>
                      <span><el-skeleton-item variant="text" style="width: 60%;margin-bottom: 0"/></span>
                      <span><el-skeleton-item variant="text" style="width: 60%;margin-bottom: 0"/></span>
                      <span><el-skeleton-item variant="text" style="width: 60%;margin-bottom: 0"/></span>
                    </p>
                    <p>

                      <span><el-skeleton-item variant="text" style="width: 60%;margin-bottom: 0"/></span>
                      <span><el-skeleton-item variant="text" style="width: 60%;margin-bottom: 0"/></span>
                      <span><el-skeleton-item variant="text" style="width: 60%;margin-bottom: 0"/></span>
                    </p>
                    <p>
                      <span><el-skeleton-item variant="text" style="width: 60%;margin-bottom: 0"/></span>
                      <span><el-skeleton-item variant="text" style="width: 60%;margin-bottom: 0"/></span>
                      <span><el-skeleton-item variant="text" style="width: 60%;margin-bottom: 0"/></span>
                    </p>
                    <p>
                      <el-skeleton-item variant="text" style="margin-bottom: 0"/>
                    </p>
                  </div>
                  <div class="details">
                    <el-skeleton-item
                      variant="p"
                      style="width: 100px"
                    />
                  </div>
                </div>
              </div>
            </template>
            <div class="listb"  v-for="(item, index) in domesticList" :key="index">
              <div class="content">
                <div class="content-img">
                  <img :src="item.tmimage" alt="" />
                </div>
                <div class="contentC">
                  <p>
                    <span v-if="!item.tmname || item.tmname == '图形'">
                      <strong>商标名称：</strong>
                      <template v-if="Figure != item.tmcode">
                        <el-tooltip class="item" content="双击文字编辑别名" effect="dark" placement="right">
                        <font @dblclick="edit(item)">
                          {{ !item.alias ? '图形' : item.alias }}
                          <i class="el-icon-info"></i>
                        </font>
                      </el-tooltip>
                      </template>
                      <template v-else>
                        <span v-click-outside="handleClose" class="edit">
                          <el-input
                              v-model="alia.alias"
                              v-focus
                              placeholder="请输入别名"
                              size="mini"
                              @keyup.enter.native="input(item)"
                          ></el-input>
                          <i class="el-icon-check" @click="input(item)"></i>
                        </span>
                      </template>
                    </span>
                    <span v-else><strong>商标名称：</strong>{{ item.tmname }}</span>
                    <span>
                      <strong>状态：</strong>
                      <el-tag size="mini" :class="{green:item.tmlaw == '已注册',classA:item.tmlaw == '申请中'}" :type="item.tmlaw == '已初审' ? 'success' : (item.tmlaw == '已销亡' ? 'info' : (item.tmlaw == '已驳回' ? 'danger' : '')) ">
                        {{ item.tmlaw }}
                      </el-tag>
                    </span>
                    <span><strong>干预方式：</strong>{{ item.intervene }}</span>
                  </p>
                  <p>
                    <span><strong>商标号：</strong>{{ item.tmcode }} </span>
                    <span><strong>申请日期：</strong>{{ item.appdate }} </span>
                    <span>
                      <strong>申请人：</strong>
                      <router-link
                          v-if="item.client_id != 0"
                          :to="{
                  name: 'newlyBuild',
                    params: { id:  item.client_id },
                }"
                          target="_blank">
                  {{ item.appname }}
                </router-link>
                      <template v-else>
                        {{ item.appname }}
                      </template>
                    </span>

                  </p>
                  <p>
                    <span> <strong>类别：</strong>{{ item.clstext }}</span>
                    <span><strong>注册日期：</strong> {{ item.regdate || '-' }}</span>
                    <span><strong>被申请人：</strong>{{ item.respondent || '-' }}</span>
                    <!-- <span><strong>代理机构：</strong><a>{{ item.agentname || '-' }}</a></span>-->

                  </p>
                  <p><strong>商品项目：</strong>

                    <font>
                      {{ item.tmgoods || '-' }}
                    </font>
                  </p>
                </div>
                <div class="details">
                  <router-link
                      target="_blank"
                      :to="{
                  name: 'domesticDetails',
                  params: { tmcode: item.tmcode, intcls: item.intcls},
                }"
                  >
                    <el-button size="small" type="primary" icon="el-icon-edit">查看</el-button>
                  </router-link>
                  <el-button icon="el-icon-upload2" size="small" type="primary" @click="upload(item.tmcode)" plain
                  >上传官文
                  </el-button
                  >
                </div>
              </div>
            </div>
          </el-skeleton>
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination
          @size-change="changeRowNum"
          @current-change="perindexList(backTop)"
          :current-page.sync="filters.page"
          :page-sizes="[30, 50, 100]"
          :page-size="filters.per_page"
          layout="total, sizes, prev, pager, next, jumper,slot"
          :total="pageSize"
      >
      </el-pagination>
    </div>

    <!-- 上传 -->
    <el-dialog
        :closeOnClickModal="false"
        :visible.sync="fileSH"
        class="file"
        title="上传文件"
        width="335px"
        @close="empty"
        @closed="empty"
    >
      <div  class="cont-tabc">
        <el-form ref="ruleForm" :model="manual" :rules="manualRules" label-position="right" label-width="80px"
                 size="mini">
          <el-form-item label="官文类型" prop="matter">
            <el-select
                v-model="manual.matter"
                clearable
                filterable
                placeholder="请选择官文类型"
                size="small"
            >
              <el-option
                  v-for="item in typeSelect"
                  :key="item.text"
                  :label="item.text"
                  :value="item.text"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商标号">
            <el-input
                v-model="fileTmcode.tmcode"
                clearable
                readonly
                placeholder="请输入商标号"
                size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="收文日期" prop="made_at">
            <el-date-picker
                v-model="manual.made_at"
                placeholder="请选择收文日期"
                size="small"
                type="date"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <div class="tabc-update">

            <el-upload
                ref="upload"
                :before-upload="beforeUpload"
                :file-list="fileList"
                :http-request="uploadImg"
                :limit="1"
                :on-exceed="handleExceed"
                action="/api/upload"
                class="upload-demo"
            >
              <el-form-item prop="name">
                <el-input v-model="manual.name" readonly placeholder="请点击按钮上传文件" size="small"></el-input>
              </el-form-item>
              <el-button
                  v-if="uploadBtn"
                  slot="trigger"
                  :disabled="distinguishDis"
                  circle
                  icon="el-icon-plus"
                  size="mini"
                  type="primary"
              ></el-button>
              <el-button v-else slot="trigger" :loading="true" circle size="mini" type="primary"></el-button>
            </el-upload>

          </div>
          <div class="batch">
            官文太多？马上 <router-link to="/personal/trademarkAdmin/upload">批量上传</router-link>
          </div>
          <div class="submitBtn">
            <el-button class="cancel" size="small" @click="fileSH = false" icon="el-icon-close">取 消</el-button>
            <el-button :disabled="submitDis" class="confirm" :icon="submitDis ? 'el-icon-loading' : 'el-icon-folder-checked'" size="small" type="primary" @click="submit('ruleForm')">确
              定
            </el-button>
          </div>

        </el-form>
      </div>
    </el-dialog>
    <el-dialog
        :closeOnClickModal="false"
        title="导出信息"
        :visible.sync="downloadDia"
        width="30%"
        @open="openDia"
    @close="closeDia">
      <template>
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="filters.fields" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="city in cities" :label="city" :key="city">{{city}}</el-checkbox>
        </el-checkbox-group>
      </template>
      <span slot="footer" class="dialog-footer">
    <el-button size="small" icon="el-icon-close" @click="downloadDia = false">取 消</el-button>
    <el-button size="small" icon="el-icon-folder-checked" type="primary" @click="download">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import storage from '@/utils/storage'
import { maintainindex, maintainindexExport,maintainindexImport,alias,fileDistinguish, fileSubmit, fileUpload, } from '@/api/personal.js'
const cityOptions = ['商标图样','商标名称', '法律状态', '商标号', '商品类别 ','申请日期','申请人','申请人地址','初审公告期号', '初审日期', '注册公告期号', '注册日期' ,'专用权期限' ,'商品项目' ,'代理机构', '商标状态'];
export default {
  name: "Domestic",
  data() {
    return {
      formOpen:false,
      downloadDia:false,
      checkAll: false,
      cities: cityOptions,
      isIndeterminate: true,

      fileListAdd:[],
      editWidth:'',
      graphical:'',
      Figure:'',
      alia:{
        alias:'',
        tmcode:'',
        intcls:'',
      },
      backTop:true,
      down:true,//控制导出加载的显示与隐藏
      loading: true,//控制骨架屏的显示与隐藏
      pickerOptions: {//时间选择器的便利选择对象
        shortcuts:storage.getShortcuts()
      },
      interveneSelect:storage.getIntervene(),
      domesticList:[],//官文文件数组
      loadList: storage.getList(),//骨架屏的起始数组（10条数据）
      //官文文件查询表单字段
      filters: {
        tmname: "", //商标名称
        tmlaw: "", //商标状态
        intcls: "", //商标类别
        appname: "", //申请人
        tmcode: "", //商标号
        agentname: "", //代理机构
        appdate: "", //申请日期
        regdate: "",//注册日期
        respondent:'',
        intervene:'',
        sort: "appdate|desc",//排序
        overthree: "",//满三年
        extend: "",//可续展
        graphic:'',//图形
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
        checkList: [],//记录满三年和可续展选中时的数组
        fields: ['商标名称', '法律状态', '商标号', '商品类别', '申请日期', '申请人', '初审日期', '注册日期', '专用权期限', '商品项目', '代理机构', '商品类别 '],
      },
      //商标状态，下拉框的所需内容
      tmlawSelect: {
        select: [
          { id: "01", value: "申请中" },
          { id: "02", value: "已初审" },
          { id: "03", value: "已注册" },
          { id: "04", value: "已销亡" },
          { id: "05", value: "已驳回" },
          { id: "06", value: "待审中" },
        ],
      },
      //商标类别，下拉框的所需内容
      intclsSelect: {
        select: storage.getIntcls(),
      },
      appnameSelect: [],//申请人，下拉框的所需内容
      agentnameSelect: [],//代理机构，下拉框的所需内容
      last_page:1,
      pageSize: 0, //总条数
      liston: 0,//控制是那种列表的tab切换(默认是table列表)


      fileTmcode:{
        tmcode:''
      },
      uploadBtn: true,//控制上传文件加载中按钮的显示与隐藏
      fileList: [],//记录上传文件成功的数组
      loadingUp: true,//控制上传文件中识别文件的骨架屏
      fileSH: false,//控制上传官文弹窗的显示与隐藏
      tabtNum: 1,//控制手动和自动上传的tab切换
      file: {
        name: "",//上传官文的名称
        path: '',//上传官文的路径
      },
      distinguishSh: true,//控制识别按钮显示与隐藏
      distinguishDis: false,
      submitDis: false,
      contSH: false,//控制识别中的骨架屏
      typeSelect: [],//官文类型的下拉数据
      automatic: {//自动上传的字段
        matter: "",
        made_at: "",
        name: "",//上传官文的名称
      },
      automaticRules: {
        matter: [
          {required: true, message: '请选择官文类型', trigger: 'change'},
        ],
        made_at: [
          {required: true, message: '请选择收文日期', trigger: 'change'},
        ],
        name: [
          {required: true, message: '请点击按钮上传文件', trigger: 'change'},
        ],
      },
      manual: {//手动上传的字段
        matter: "", //官文名称
        made_at: "",
        name: ''
      },
      manualRules: {
        matter: [
          {required: true, message: '请选择官文类型', trigger: 'change'},
        ],
        made_at: [
          {required: true, message: '请选择收文日期', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请点击按钮上传文件', trigger: 'change'},
        ],
      },
    };
  },
  created() {
    this.perindexList();
    this.select();
  },
  methods: {
    handleCheckAllChange(val) {
      this.filters.fields = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    //编辑别名点击其他地方，取消编辑事件
    handleClose(e) {
      console.log(e)
      this.Figure = this.graphical = ''
    },

    edit(item){
      this.graphical = this.Figure = item.tmcode
      if(!item.alias){
        this.alia.alias = '图形'
      }else {
        this.alia.alias = item.alias
      }

    },
    //添加别名事件
    async input(item){
      try{
        this.alia.tmcode = item.tmcode
        this.alia.intcls = item.intcls
        if(!this.alia.alias){
          this.$message({
            message: '别名不能为空',
            type: 'warning'
          });
        }else{
          const res = await alias(this.alia)
          if(res && res.err_code === 0){
            this.Figure = this.graphical = ''
            item.alias = this.alia.alias
          }else{
            this.$message({
              message: res.err_msg,
              type: 'warning'
            });
          }
        }

      }finally{

      }
    },
    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.perindexList(this.backTop);
    },
    //渲染列表事件
    async perindexList(backTop){
      this.loading = true;
      this.domesticList = storage.getList()
      try {
        const res = await maintainindex(this.filters)
        if(res && res.err_code === 0) {
          if(backTop === true){
            storage.backTop()
          }
          this.loading = false
          this.domesticList = res.data.list
          this.pageSize = res.data.total
        }else {
          this.domesticList = []
        }
      }finally{
        this.loading = false
      }
    },

    // 下拉
    async select(){
      let s = await storage.getSelects('app_names', 'right_app_names','tm_doc_types')
      this.appnameSelect = s.app_names
      this.agentnameSelect = s.right_app_names
      this.typeSelect = storage.getMaintainTypes()
    },
    //导出事件
    openDia(){
      this.filters.fields = this.$options.data().filters.fields;
      this.checkAll = false
      this.isIndeterminate = true
      this.$store.commit('setscroll',{
        scroll:this.downloadDia,
      });
    },
    closeDia(){
      this.$store.commit('setscroll',{
        scroll:this.downloadDia,
      });
    },
    async download(){
      this.down = false
      this.downloadDia = false
      if(this.filters.fields.length != 0){
        try{
          const res = await maintainindexExport(this.filters)
          location.href = res.data.url
          this.down = true
        }finally{
          this.down = true
        }
      }else{
        this.$message({
          message: '至少选择一个',
          type: 'warning'
        });
      }

    },
    beforeAvatarUpload(file) {

      if(file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
        this.$message.success('导入成功');
      }else{
        this.$message.error('上传文件只能是 XSl或 XLSL 格式!');
      }
    },
//导入事件
    async downloadAdd(item) {
      let formData = new FormData();
      formData.append("file", item.file);
      try{
        const res = await maintainindexImport(formData)
        if(res && res.err_code === 0){
          this.perindexList()
        }else{
          this.$message({
            type: "warning",
            message: res.err_msg,
          })
        }
      }finally{

      }
    },
    open(){
      location.href = '/download?name=干预商标信息导入模板.xls&path=干预商标信息导入模板.xls'
    },
    //重置事件
    reset() {
      this.filters = this.$options.data().filters;
      this.perindexList()
    },
    //排序事件
    sort_change(column) {
      if (column.order === "descending") {
        this.filters.sort = "appdate|desc";
      } else {
        this.filters.sort = "appdate|asc";
      }
      this.perindexList();
    },

    upload(val) {
      this.fileSH = true
      this.fileTmcode.tmcode = val
      this.$emit('dialog',this.fileSH)
    },
    //文件上传时事件
    beforeUpload() {
      this.uploadBtn = false
    },
    //文件上传数量超过1个提示事件
    handleExceed(files, fileList) {
      this.$message.warning(
          `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`
      );
    },

    //文件上传后事件(自动上传)
    async uploadImg(item) {
      let formData = new FormData();
      formData.append("file", item.file);
      formData.append("is_base64", 0);
      try {
        const res = await fileUpload(formData)
        if (res && res.err_code === 0) {
          this.uploadBtn = true
          this.file.name = this.automatic.name = this.manual.name = res.data.name;
          this.file.path = res.data.path;
          this.$refs.upload.clearFiles()
        } else {
          this.$message({
            type: "warning",
            message: res.err_msg,
          })
        }
      } finally {
        this.uploadBtn = true;
      }
    },

    //识别按钮点击事件
    distinguishBtn(automatic) {
      this.$refs[automatic].validate(async (valid) => {
        try {
          if (this.automatic.name != '') {
            this.distinguishDis = true
            this.contSH = true;
            this.loadingUp = true
            const res = await fileDistinguish({...this.filters, ...this.file})
            if (res && res.err_code === 0) {
              this.distinguishDis = false
              this.loadingUp = false
              this.automatic.made_at = res.data.made_at;
              this.distinguishSh = false;
              if (this.typeSelect.indexOf(res.data.matter) == -1) {
                this.automatic.matter = res.data.matter;
              } else {
                this.$message({
                  type: "warning",
                  message: '上传文件的官文类型不存在，请选择成已有的官文类型',
                })
                this.automatic.matter = ''
              }
            } else {
              this.$message({
                message: res.err_msg,
                type: 'warning'
              })
              this.contSH = false
              this.fileList = []
              this.distinguishDis = false
              this.distinguishSh = true
            }
          }
        } catch (e) {
          this.contSH = false
          this.fileList = []
          this.distinguishDis = false
          this.distinguishSh = true
        }

      })
    },
    //上传官文提交事件
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.distinguishDis = this.submitDis = true
            const res = await fileSubmit({...this.file, ...this.manual, ...this.fileTmcode})
            if (res && res.err_code === 0) {
              this.$refs[formName].resetFields();
              this.success()
            } else {
              this.$message({
                type: "warning",
                message: res.err_msg,
              })
              this.fileList = []
              this.distinguishDis = this.submitDis = false
            }
          } catch (e) {
            this.fileList = []
            this.distinguishDis = this.submitDis = false
          }
        } else {
          return false;
        }
      })


    },
    submitAutomatic(automatic) {
      this.$refs[automatic].validate(async (valid) => {
        if (valid) {
          try {
            this.distinguishDis = this.submitDis = true
            const res = await fileSubmit({...this.file, ...this.automatic, ...this.fileTmcode})
            if (res && res.err_code === 0) {
              this.success()
            } else {
              this.$message({
                type: "warning",
                message: res.err_msg,
              })
              this.distinguishDis = this.submitDis = false
            }
          } catch (e) {
            this.fileList = []
            this.distinguishDis = this.submitDis = false
          }
        } else {
          return false
        }
      })


    },
    //上传官文提交成功事件
    success() {
      this.$message({
        type: "success",
        message: "提交成功!",
      });
      this.fileSH = false;
      this.$emit('dialog',this.fileSH)
      this.manual = this.$options.data().manual; //清空表单
      this.automatic = this.$options.data().automatic; //清空表单
      this.distinguishSh = true;
      this.contSH = false;
      this.tabtNum = 1
      this.fileList = []
      this.file.name = ''
      this.distinguishDis = this.submitDis = false
      this.domesticDetails()
    },
    //上传官文取消事件
    empty() {
      this.fileSH = false;
      this.$emit('dialog',this.fileSH)
      this.$refs.ruleForm.resetFields()
      this.tabtNum = 1
      this.distinguishSh = true;
      this.contSH = false;
      this.$refs.upload.clearFiles()
      this.file = this.$options.data().file; //清空表单
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/domestic.less";
div {
  .mainrb {
    .listT {
      padding: 10px;
      .table-checkbox{
        display: inline-block;
        margin-left: 10px;
        height: 32px;
        line-height: 32px;
        .el-checkbox {
          margin-right: 10px;
        }
      }
      .upload-demo{
        display: inline-block;
        margin: 0 10px;
        ::v-deep .el-upload-list{
          display: none;
        }
      }
      .listTr{
        display: flex;
        align-items: center;
        strong {
          display: inline-block;
          text-align: center;
          border: 1px solid rgba(187, 187, 187, 100);
          cursor: pointer;
          margin-left: 10px;

          img {
            width: 30px;
            padding: 5px;
          }

        }

        strong.on {
          background: #3998FF;
          border: none;
          img{
            margin: 1px;
          }
        }
        .pagination{
          display: inline-block;
          width: 80px;
          .el-pagination{
            width: 100%;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
    ::v-deep .caret-wrapper{
      height: 22px;
      .sort-caret.ascending{
        top: 1px;
      }
      .sort-caret.descending{
        bottom: 0;
      }
    }
    .el-table{
      ::v-deep .is-sortable{
        .cell{
          display: flex;
          justify-content: center;
        }
      }
      //a{
      //    .el-button{
      //        margin:0 5px 10px 0;
      //    }
      //
      //}
    }

    .list-list.empty{
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }
  .submitBtn {
    ::v-deep .confirm {
      right: 0 !important;
    }
    ::v-deep .cancel {
      right: 80px !important;
    }
  }
}
.list-list>div>div{
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: all 1s;
  -webkit-transition:all 1s;
}
.list-list>div>div::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 5px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
}

.list-list>div>div::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  box-shadow   : inset 0 0  5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
@media screen and (max-width: 768px){
  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 733px") !important;
  }
  .ios{
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 773px") !important;
    }
  }
  .el-table.on{
    ::v-deep .el-table__body-wrapper{
      height: calc(~"100vh - 397px") !important;
    }
  }
  .el-table.on.ios{
    ::v-deep .el-table__body-wrapper{
      height: calc(~"100vh - 437px") !important;
    }
  }
  .list-list>div>div{
    height: calc(~"100vh - 703px") ;
  }
  .list-list>div>div.on{
    height: calc(~"100vh - 367px") ;
  }
  .contentC{
    width: 60%;
  }
  .formInput.on{
    height: 378px;
  }
  .list-list.empty{
    height: calc(~"100vh - 686px");
  }
  .list-list.empty.ios{
    height: calc(~"100vh - 726px");
  }
  .list-list.empty.on{
    height: calc(~"100vh - 350px");
  }
  .list-list.empty.on.ios{
    height: calc(~"100vh - 390px");
  }
}
@media screen and (min-width: 768px){
  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 506px") !important;
  }

  .el-table.on{
    ::v-deep .el-table__body-wrapper{
      height: calc(~"100vh - 380px") !important;
    }
  }
  .el-table.ios.on{
    ::v-deep .el-table__body-wrapper{
      height: calc(~"100vh - 442px") !important;
    }
  }
  .el-table.ios{
    ::v-deep .el-table__body-wrapper{
      height: calc(~"100vh - 568px") !important;
    }
  }
  .list-list>div>div{
    height: calc(~"100vh - 476px") ;
  }
  .list-list>div>div.ios{
    height: calc(~"100vh - 516px") ;
  }
  .list-list>div>div.on{
    height: calc(~"100vh - 350px") ;
  }
  .list-list>div>div.on.ios{
    height: calc(~"100vh - 390px") ;
  }
  .contentC{
    width: 65%;
  }
  .formInput.on{
    height: 168px;
  }
  .list-list.empty{
    height: calc(~"100vh - 476px");
  }
  .list-list.empty.ios{
    height: calc(~"100vh - 516px");
  }
  .list-list.empty.on{
    height: calc(~"100vh - 350px");
  }
  .list-list.empty.on.ios{
    height: calc(~"100vh - 390px");
  }
}
@media screen and (min-width: 992px){

  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 464px") !important;
  }
  .el-table.ios{
    ::v-deep .el-table__body-wrapper {
      height: calc(~"var(--vh,1vh) * 100 - 503px") !important;
    }
  }

  .el-table.on{
    ::v-deep .el-table__body-wrapper{
      height: calc(~"100vh - 380px") !important;
    }
  }
  .el-table.on.ios{
    ::v-deep .el-table__body-wrapper{
      height: calc(~"var(--vh,1vh) * 100 - 420px") !important;
    }
  }
  .list-list>div>div{
    height: calc(~"100vh - 434px") ;
  }
  .list-list>div>div.ios{
    height: calc(~"100vh - 484px") ;
  }
  .list-list>div>div.on{
    height: calc(~"100vh - 350px") ;
  }
  .list-list>div>div.on.ios{
    height: calc(~"100vh - 390px") ;
  }
  .list-list.empty{
    height: calc(~"100vh - 434px");
  }
  .list-list.empty.ios{
    height: calc(~"100vh - 494px");
  }
  .contentC{
    width: 70%;
  }
  .formInput.on{
    height: 126px;
  }
}


@media screen and (min-width: 1200px){
  .contentC{
    width: 80%;
  }
}


</style>
