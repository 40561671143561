
<template>
  <div class="">
    <h2>商标注册</h2>
    <div class="registert">
      <ul>
        <li>填写商标信息</li>
        <li>填写申请人信息</li>
        <li>支付订单费用</li>
      </ul>
    </div>

    <div class="register-trademark">
      <h3>
        <span>商标信息</span>
      </h3>
      <div class="registerb-information">
        <div class="">
          <span>商标名称</span>
          <input type="text" placeholder="商标名称" v-model="name" />
        </div>
        <div class="">
          <span>商标说明</span>
          <textarea
            placeholder="商标说明..."
            name=""
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </div>
      </div>
      <h3>
        <span>商标图样</span>
      </h3>
      <div class="registerb-pattern">
        <strong>图样上传</strong>
        <div class="pattern-update">
          <div class="update">
            <span class="update-jia" v-if="chooseAdd">+</span>
            <div
              class="update-cont"
              v-if="!chooseAutomatic"
              @mouseleave="automaticImgSee = false"
            >
              <p v-if="textAutomatic">不大于200k的jpg</p>
              <img
                v-else
                :src="automaticSrc"
                @mousemove="automaticImgSee = true"
                alt=""
              />
              <div class="automaticImgSee" v-show="automaticImgSee">
                <a :href="automaticSrc" target="_blank">查看大图</a>
              </div>
            </div>
            <div
              class="update-cont"
              v-if="!chooseManual"
              @mouseleave="manualImgSee = false"
            >
              <img
                v-if="updateImgSH"
                :src="manualImg"
                alt=""
                @mousemove="manualImgSee = true"
              />
              <p v-else @click="updateImg">
                图片400-1600尺寸<br />
                不大于200k的jpg
              </p>
              <div class="" v-show="manualImgSee">
                <a :href="manualImg" target="_blank">查看大图</a>
                <span @click="updateImg">重新上传</span>
              </div>
              <input
                type="file"
                ref="file"
                style="display: none"
                @change="fileChange"
              />
            </div>
          </div>

          <div class="update-choose">
            <p @click="imgAutomatic">
              <span v-if="chooseAutomatic"></span
              ><img v-else src="@/assets/img/personal/icon-gou.png" alt="" />
              自动生成
            </p>

            <p @click="imgManual">
              <span v-if="chooseManual"></span
              ><img v-else src="@/assets/img/personal/icon-gou.png" alt="" />
              手动上传
            </p>
          </div>
          <div
            class="choose-automatic"
            v-if="!chooseAutomatic"
            @click="drawLogo(name), (chooseAdd = false)"
          >
            生成黑白图样
          </div>
        </div>
        <div class="pattern-manual" v-if="!chooseManual">
          <p>上传黑白图样，注册后可以更换商标颜色使用；</p>
          <p>上传彩色图样，注册后只能按照该彩色图样使用，并在委托书商标名称</p>
          <p>后方填写“（指定颜色）”四字即可，无需指明具体颜色；</p>
        </div>
      </div>
      <h3>
        <span>商标分类</span>
      </h3>
      <div class="registerb-classification">
        <div class="classification-mode">
          <strong>选择类别方式</strong>
          <span @click="modeNum = 1" :class="{ on: modeNum == 1 }"
            >智能推荐</span
          >
          <span @click="modeNum = 2" :class="{ on: modeNum == 2 }"
            >自助选择</span
          >
          <span @click="modeNum = 3" :class="{ on: modeNum == 3 }"
            >全类保护</span
          >
        </div>
        <div class="classification-industry" v-show="modeNum == 1">
          <strong>选择您的行业</strong>
          <div class="industry-text">
            <p class="text-msg" @click="selectText = !selectText">
              {{ textMsg }}
              <img src="@/assets/img/personal/icon-xia.png" alt="" />
            </p>

            <ul v-show="selectText">
              <li
                v-for="(item, index) in handlingList"
                :key="index"
                @click="selectNum = index"
                :class="{ on: selectNum == index }"
                @mousemove="selectMove(index)"
              >
                <p>{{ item.title }}</p>
                <img
                  v-if="selectNum == index"
                  src="@/assets/img/personal/icon-right-on.png"
                  alt=""
                />
                <img v-else src="@/assets/img/personal/icon-right.png" alt="" />
              </li>
            </ul>
            <div class="industry-level" v-show="selectText">
              <p
                v-for="(item, index5) in handlingList1"
                :key="index5"
                @click="
                  (levelNum = index5),
                    (textMsg = item.title),
                    (selectText = false)
                "
                :class="{ on: levelNum == index5 }"
              >
                {{ item.title }}
              </p>
            </div>
          </div>
        </div>
        <div class="classification-recommend" v-show="modeNum == 1">
          <strong>选择推荐规则</strong>
          <div class="">
            <p
              @click="
                (industryRecommend = !industryRecommend),
                  (commodityRecommend = false)
              "
            >
              <span v-if="industryRecommend"></span>
              <img v-else src="@/assets/img/personal/icon-gou.png" alt="" />
              <font>行业精准推荐</font>
            </p>
            <p
              @click="
                (commodityRecommend = !commodityRecommend),
                  (industryRecommend = true)
              "
            >
              <span v-if="!commodityRecommend"></span>
              <img v-else src="@/assets/img/personal/icon-gou.png" alt="" />
              <font>商品全面覆盖</font>
            </p>
          </div>
        </div>
      </div>
      <div class="registerb-category" v-show="modeNum == 1">
        <h3>
          <span>类别推荐</span>
        </h3>
        <div class="category-list">
          <div class="listt">
            <strong>我们为您推荐以下商品/服务项目</strong>
            <span
              >因商标注册存在盲期，以下群组的通过率检索结果可能存在偏差，仅供参考！</span
            >
          </div>
          <div class="listb clearfix">
            <div class="listb-l fl">
              <div class="listb-lt">
                <p v-for="(item, index) in handlingList" :key="index">
                  {{ item.title }}
                </p>
              </div>
              <div class="listb-lb">
                <div class="listb-lb-cont" :class="{ on: !listbBtnAdd }">
                  <div class="" v-if="!listbBtnAdd">
                    <p
                      v-for="(item, index) in handlingList"
                      :key="index"
                      @click="contNum = index"
                      :class="{ on: contNum == index }"
                    >
                      {{ item.title }}
                    </p>
                  </div>
                  <span class="listb-l-btn" @click="listbBtnAdd = !listbBtnAdd"
                    >+ 添加类别</span
                  >
                </div>
              </div>
            </div>
            <div class="listb-r fl">
              <div
                class="listb-r-list"
                v-for="(item, index) in handlingList"
                :key="index"
              >
                <h4 class="listb-r-title">
                  {{ item.title }}
                  <p>
                    <font>￥300</font
                    ><span v-if="item.show" @click="item.show = !item.show"
                      >展开</span
                    ><span v-else @click="item.show = !item.show">收起</span>
                  </p>
                </h4>
                <div class="" v-show="!item.show">
                  <ul class="listb-r-result">
                    <li v-for="(item1, index1) in item.list2" :key="index1">
                      <h4>
                        {{ item1.title }}
                        <img
                          src="@/assets/img/personal/icon-detail.png"
                          alt=""
                          @click="resultNum(item1)"
                        />
                      </h4>
                      <span
                        v-for="(item2, index2) in item1.list3"
                        :key="index2"
                        >{{ item2.title }}</span
                      >
                    </li>
                  </ul>
                  <div class="listb-r-btn">
                    <div class="btn-btn">
                      <span
                        v-if="item.addShow"
                        @click="item.addShow = !item.addShow"
                        >+添加</span
                      >
                      <span v-else @click="item.addShow = !item.addShow"
                        ><img
                          src="@/assets/img/personal/icon-listb-r-btn-on.png"
                          alt=""
                        />关闭</span
                      >
                    </div>
                    <div class="btn-list clearfix" v-show="!item.addShow">
                      <div class="btnl fl">
                        <p
                          v-for="(item3, index3) in item.list2"
                          :key="index3"
                          @click="btnlClk(item3), (btnlNum = index3)"
                          :class="{ on: btnlNum == index3 }"
                        >
                          {{ item3.title }}
                        </p>
                      </div>
                      <div class="btnr fl">
                        <p
                          v-for="(item7, index7) in handlingList2"
                          :key="index7"
                          @click="item7.btnrNum = true"
                        >
                          <img
                            src="@/assets/img/personal/icon-btnr-gou.png"
                            alt=""
                            :class="{ on: item7.btnrNum }"
                          />
                          <font>{{ item7.title }}</font>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="Independent clearfix" v-show="modeNum == 2">
        <div class="clearfix">
          <div class="Independentl fl">
            <div
              class="Independentl-first"
              v-for="(item, index) in handlingList"
              :key="index"
            >
              <p
                @click="(item.hide = !item.hide), (item.imgOn = !item.imgOn)"
                :class="{ on: item.isOn }"
              >
                {{ item.title }}
                <span v-if="item.isOn">
                  <img
                    v-if="item.imgOn"
                    class="up-on"
                    src="@/assets/img/personal/icon-listb-r-btn-on.png"
                    alt=""
                  />
                  <img
                    v-else
                    class="down-on"
                    src="@/assets/img/personal/icon-listb-r-btn-on.png"
                    alt=""
                  />
                </span>
                <span v-else>
                  <img
                    v-if="!item.hide"
                    src="@/assets/img/personal/icon-left.png"
                    alt=""
                  />
                  <img
                    v-else
                    class="up"
                    src="@/assets/img/personal/icon-left.png"
                    alt=""
                  />
                </span>
              </p>
              <div
                class="Independentl-second"
                v-for="(item1, index1) in item.list2"
                :key="index1"
                v-show="item.hide"
              >
                <p
                  @click="
                    (item1.hide = !item1.hide), (item1.imgOn = !item1.imgOn)
                  "
                  :class="{ on: item1.isOn }"
                >
                  {{ item1.title }}
                  <span v-if="item1.isOn">
                    <img
                      v-if="item1.imgOn"
                      class="up-on"
                      src="@/assets/img/personal/icon-listb-r-btn-on.png"
                      alt=""
                    />
                    <img
                      v-else
                      class="down-on"
                      src="@/assets/img/personal/icon-listb-r-btn-on.png"
                      alt=""
                    />
                  </span>
                  <span v-else>
                    <img
                      v-if="!item1.hide"
                      src="@/assets/img/personal/icon-left.png"
                      alt=""
                    />
                    <img
                      v-else
                      class="up"
                      src="@/assets/img/personal/icon-left.png"
                      alt=""
                    />
                  </span>
                </p>
                <div
                  class="Independentl-third"
                  v-for="(item2, index2) in item1.list3"
                  :key="index2"
                  v-show="item1.hide"
                >
                  <p
                    @click="thirdClk(index2, index1, index)"
                    :class="{ on: item2.isOn, on1: item2.isOn1 }"
                  >
                    {{ item2.title }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="Independentr fl">
            <h5>选择商品/服务 <span>清空选项</span></h5>
            <div class="Independentr-empty" v-if="thirdChose">
              <img src="@/assets/img/personal/Independentr-empty.png" alt="" />
              <h4>请点击左侧选择商标类别</h4>
            </div>
            <div class="Independentr-list" v-else>
              <div class="list-chose">
                <h4>
                  第01类 化学原料
                  <span>共<font>15</font> 项（10项以上每项加收30元）</span>
                  <p>
                    <font>￥420</font>
                    <img
                      src="@/assets/img/personal/icon-chose-delete.png"
                      alt=""
                    />
                  </p>
                </h4>
                <div class="choset-list">
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                </div>
              </div>
              <div class="list-chose">
                <h4>
                  第01类 化学原料
                  <span>共<font>15</font> 项（10项以上每项加收30元）</span>
                  <p>
                    <font>￥420</font>
                    <img
                      src="@/assets/img/personal/icon-chose-delete.png"
                      alt=""
                    />
                  </p>
                </h4>
                <div class="choset-list">
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                </div>
              </div>
              <div class="list-chose">
                <h4>
                  第01类 化学原料
                  <span>共<font>15</font> 项（10项以上每项加收30元）</span>
                  <p>
                    <font>￥420</font>
                    <img
                      src="@/assets/img/personal/icon-chose-delete.png"
                      alt=""
                    />
                  </p>
                </h4>
                <div class="choset-list">
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                </div>
              </div>
              <div class="list-chose">
                <h4>
                  第01类 化学原料
                  <span>共<font>15</font> 项（10项以上每项加收30元）</span>
                  <p>
                    <font>￥420</font>
                    <img
                      src="@/assets/img/personal/icon-chose-delete.png"
                      alt=""
                    />
                  </p>
                </h4>
                <div class="choset-list">
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                </div>
              </div>
              <div class="list-chose">
                <h4>
                  第01类 化学原料
                  <span>共<font>15</font> 项（10项以上每项加收30元）</span>
                  <p>
                    <font>￥420</font>
                    <img
                      src="@/assets/img/personal/icon-chose-delete.png"
                      alt=""
                    />
                  </p>
                </h4>
                <div class="choset-list">
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                </div>
              </div>
              <div class="list-chose">
                <h4>
                  第01类 化学原料
                  <span>共<font>15</font> 项（10项以上每项加收30元）</span>
                  <p>
                    <font>￥420</font>
                    <img
                      src="@/assets/img/personal/icon-chose-delete.png"
                      alt=""
                    />
                  </p>
                </h4>
                <div class="choset-list">
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                </div>
              </div>
              <div class="list-chose">
                <h4>
                  第01类 化学原料
                  <span>共<font>15</font> 项（10项以上每项加收30元）</span>
                  <p>
                    <font>￥420</font>
                    <img
                      src="@/assets/img/personal/icon-chose-delete.png"
                      alt=""
                    />
                  </p>
                </h4>
                <div class="choset-list">
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                  <span>010305 工业用石墨</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="whole-class clearfix" v-show="modeNum == 3">
        <div class="clearfix">
          <div class="classl fl">
            <p
              v-for="(item, index) in handlingList"
              :key="index"
              @click="classlNum = index"
              :class="{ on: classlNum == index }"
            >
              {{ item.title }}
            </p>
          </div>
          <div class="classr fl">
            <div
              class="classr-list"
              v-for="(item, index) in handlingList"
              :key="index"
            >
              <h4>
                {{ item.title }} <span>（共选<font>9</font>项）</span>
                <p>
                  <font>￥300</font
                  ><span v-if="item.show" @click="item.show = !item.show"
                    >展开</span
                  >
                  <span v-else @click="item.show = !item.show">收起</span>
                </p>
              </h4>
              <div class="" v-show="!item.show">
                <div
                  class="list-choose"
                  v-for="(item1, index1) in item.list2"
                  :key="index1"
                >
                  <h5>
                    {{ item1.title
                    }}<img
                      src="@/assets/img/personal/icon-detail.png"
                      @click="chooseNum(item1)"
                      alt=""
                    />
                  </h5>
                  <div class="choose-list">
                    <span v-for="(item2, index2) in item1.list3" :key="index2">
                      {{ item2.title }}</span
                    >
                  </div>
                </div>

                <div class="list-btn">
                  <div class="btn-btn">
                    <span
                      v-if="item.addShow"
                      @click="item.addShow = !item.addShow"
                      >+添加
                    </span>
                    <span v-else @click="item.addShow = !item.addShow"
                      ><img
                        src="@/assets/img/personal/icon-listb-r-btn-on.png"
                        alt=""
                      />关闭</span
                    >
                  </div>
                  <div class="btn-list clearfix" v-show="!item.addShow">
                    <div class="listl fl">
                      <p
                        v-for="(item1, index1) in item.list2"
                        :key="index1"
                        @click="listlBtn(item1), (listlNum = index1)"
                        :class="{ on: listlNum == index1 }"
                      >
                        {{ item1.title }}
                      </p>
                    </div>
                    <div class="listr fl">
                      <p
                        v-for="(item9, index9) in handlingList3"
                        :key="index9"
                        @click="item9.btnrNum = true"
                      >
                        <img
                          src="@/assets/img/personal/icon-btnr-gou.png"
                          alt=""
                          :class="{ on: item9.btnrNum }"
                        />
                        <font>{{ item9.title }}</font>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="registerb-statistics fr"
        v-show="modeNum == 1 || modeNum == 2 || modeNum == 3"
      >
        <p>发票税费：<font>￥945.00</font></p>
        <p>应付总额：<font>￥14445.00</font></p>
        <span @click="next">下一步</span>
      </div>
    </div>

    <div class="register-promise" v-show="agree">
      <div class="register-promise-cont">
        <h2>商标注册承诺书</h2>
        <div class="content">
          <p>我本人已阅读、并完全理解商标法如下条款：</p>
          <p>
            第四条
            自然人、法人或者其他组织在生产经营活动中，对其商品或者服务需要取得商标专用权的，应当向商标局申请商标注册。不以使用为目的的恶意商标注册申请，
            应当予以驳回。本法有关商品商标的规定， 适用于服务商标。
          </p>
          <p>第十条 下列标志不得作为商标使用：</p>
          <p>
            (一)同中华人民共和国的国家名称、国旗、国徽、国歌、军旗、军徽、军歌、勋章等相同或者近似的，以及同中央国家机关的名称、标志、所在地特定地点的名称或者标志性建筑物的名称、图形相同的;
          </p>
          <p>
            (二)同外国的国家名称、国旗、国徽、军旗等相同或者近似的，但经该国政府同意的除外;
          </p>
          <p>
            (三)同政府间国际组织的名称、旗帜、徽记等相同或者近似的，但经该组织同意或者不易误导公众的除外;
          </p>
          <p>
            (四)与表明实施控制、予以保证的官方标志、检验印记相同或者近似的，但经授权的除外;
          </p>
          <p>(五)同“红十字”、“红新月”的名称、标志相同或者近似的;</p>
          <p>(六)带有民族歧视性的;</p>
          <p>(七)带有欺骗性，容易使公众对商品的质量等特点或者产地产生误认的;</p>
          <p>(八)有害于社会主义道德风尚或者有其他不良影响的。</p>
          <p>
            县级以上行政区划的地名或者公众知晓的外国地名，不得作为商标。但是，地名具有其他含义或者作为集体商标、证明商标组成部分的除外;已经注册的使用地名的商标继续有效。
          </p>
          <p>第十一条 下列标志不得作为商标注册：</p>
          <p>（一）仅有本商品的通用名称、图形、型号的；</p>
          <p>
            （二）仅直接表示商品的质量、主要原料、功能、用途、重量、数量及其他特点的；
          </p>
          <p>
            （三）其他缺乏显著特征的。
            前款所列标志经过使用取得显著特征，并便于识别的，可以作为商标注册。
          </p>
          <p>
            第十二条
            以三维标志申请注册商标的，仅由商品自身的性质产生的形状、为获得技术效果而需有的商品形状或者使商品具有实质性价值的形状，不得注册。
          </p>
          <p>
            第十三条
            为相关公众所熟知的商标，持有人认为其权利受到侵害时，可以依照本法规定请求驰名商标保护。
            就相同或者类似商品申请注册的商标是复制、摹仿或者翻译他人未在中国注册的驰名商标，容易导致混淆的，不予注册并禁止使用。
            就不相同或者不相类似商品申请注册的商标是复制、摹仿或者翻译他人已经在中国注册的驰名商标，误导公众，致使该驰名商标注册人的利益可能受到损害的，不予注册并禁止使用。
          </p>
          <p>
            第十五条
            未经授权，代理人或者代表人以自己的名义将被代理人或者被代表人的商标进行注册，被代理人或者被代表人提出异议的，不予注册并禁止使用。
            就同一种商品或者类似商品申请注册的商标与他人在先使用的未注册商标相同或者近似，申请人与该他人具有前款规定以外的合同、业务往来关系或者其他关系而明知该他人商标存在，该他人提出异议的，不予注册。
          </p>
          <p>
            第三十二条
            申请商标注册不得损害他人现有的在先权利，也不得以不正当手段抢先注册他人已经使用并有一定影响的商标。
          </p>
          <p>我知晓并认同：</p>
          <p>一、 坚持依法申请商标注册，自觉遵守相关法律法规的要求。</p>
          <p>
            二、
            申请商标注册，应当以商业使用为目的，遵守诚实信用原则，并不得申请不以使用为目的的恶意商标注册。
          </p>
          <p>
            三、
            申请商标注册，应当有显著特征，便于识别，并不得与他人在先取得的合法权利相冲突。
          </p>
          <p>四、 申请商标注册，不得有害于社会主义道德风尚或者有不良影响。</p>
          <p>五、 申请商标注册，不得复制、摹仿或者翻译他人的驰名商标。</p>
          <p>
            六、p
            代理人或者代表人未经授权，不得以自己的名义将被代理人或者被代表人的商标进行注册。
          </p>
          <p>
            七、
            申请人与他人具有合同、业务往来关系或者其他关系而明知该他人商标存在，不得就同一种商品或者类似商品申请注册的商标与该他人在先使用的未注册商标相同或者近似。
          </p>
          <p>
            八、申请商标注册，不得损害他人现有的在先权利，也不得以不正当手段抢先注册他人已经使用并有一定影响的商标。
          </p>
          <p>
            九、对恶意申请商标注册的，根据情节给予警告、罚款等行政处罚；对恶意提起商标诉讼的，由人民法院依法给予处罚。
          </p>
          <p>
            十、伪造或者变造《商标注册证》或者其他商标证明文件的，依照刑法关于伪造、变造国家机关证件罪或者其他罪的规定，依法追究刑事责任。
          </p>
          <p>
            十一、将他人注册商标、未注册的驰名商标作为企业名称中的字号使用，误导公众，构成不正当竞争行为的，应当依法承担民事责任。权利人因被侵权所受到的实际损失、侵权人因侵权所获得的利益难以确定的，由人民法院根据侵权行为的情节判决给予权利人五百万元以下的赔偿。
          </p>
          <p>
            十二、未经商标注册人许可，在同一种商品或服务上使用与其注册商标相同的商标，构成犯罪的，除赔偿被侵权人的损失外，依法追究刑事责任。
          </p>
          <p>
            十三、伪造、擅自制造他人注册商标标识或者销售伪造、擅自制造的注册商标标识，构成犯罪的，除赔偿被侵权人的损失外，依法追究刑事责任。
          </p>
          <p>
            十四、销售明知是假冒注册商标的商品，构成犯罪的，除赔偿被侵权人的损失外，依法追究刑事责任。
          </p>
          <p>
            我承诺本次商标注册申请行为未违反商标法第四条、第十五条和第三十二条的规定。
          </p>
          <p>
            若我隐瞒事实，违反了相关法律法规的规定，我同意授权权大师使用本人权大师账号内的或以往提供给权大师的申请人身份证明以及委托书文件，代为向商标局递交撤回申请，以消除影响。
          </p>
          <p>
            如若我的申请行为对公众以及权大师造成了影响和损失，我承诺承担该直接损失以及因消除影响带来的损失。
          </p>
          <p>
            特别提示：请注意申请商标递交后至授权前均需保证资格证明文件为有效状态，请注意务必不要在审查最终结果之前对执照或其他证明文件进行注销，这将会影响审查导致不予受理。
          </p>
        </div>
        <span @click="agree = false">我已阅读同意</span>
      </div>
    </div>
  </div>
</template>

<script>
import storage from "@/utils/storage.js";
export default {
  data() {
    return {
      handlingList: [], //所有数据的数组（包括一二三类）
      handlingList1: [], //选择您的行业的第二大类数组
      handlingList2: [], //选中列表里（类名：listb-r）添加里的第三类数组
      handlingList3: [], //选中列表里（类名：listr p）添加里的第三类数组
      btnlNum: -1, //控制选中列表里（类名：listb-r）添加里的左边列表选中高亮
      name: "", ////要存到storage里的数据字段信息，商标名称
      agree: false, //控制商标注册承诺书的显示与隐藏
      modeNum: 0, //控制选择类别方式的tab切换
      selectText: false, //控制选择您的行业的显示与隐藏
      selectNum: 0, //控制选择您的行业（类名：industry-text li）的左边列表选中高亮
      levelNum: 0, //控制选择您的行业（类名：industry-level p）的右边列表选中高亮
      textMsg: "请选择", //选择您的行业下拉选择的初始默认文本
      thirdChose: true, //控制对应自助选择，下的右侧列表（类名： Independentr）没有选择时的显示与隐藏，默认为显示
      classlNum: -1, //控制全类保护,下的左侧侧列表（类名： classl）选中高亮
      chooseAutomatic: true, //控制生成黑白图样,按钮的显示与隐藏
      chooseManual: true, //控制手动上传的提示文本的的显示与隐藏
      manualImg: "", //生成手动上传图片的地址
      manualImgSee: false, //手动上传图片成功后，查看大图，显示与隐藏
      updateImgSH: false, //控制手动上传的图片的的显示与隐藏
      automaticImgSee: false, //自动上传图片成功后，查看大图，显示与隐藏
      textAutomatic: true, //控制自动上传的提示文本的的显示与隐藏
      automaticSrc: "", //控制自动上传的图片的地址
      chooseAdd: true, //控制图样上传的初始文本（类名：update-jia）的显示与隐藏
      contNum: -1, //控制智能推荐,下的左侧列表点击添加（类名： listb-lb-cont p）选中高亮
      listbBtnAdd: true, //控制智能推荐,下的左侧列表点击添加的（类名： listb-lb-cont ）显示与隐藏
      industryRecommend: false, //控制选择推荐规则，的行业精准推荐的选中与未选中
      commodityRecommend: false, //控制选择推荐规则，的商品全面覆盖的选中与未选中
      listlNum: -1,
      listrNum: -1,
    };
  },
  created() {
    this.name = storage.getItem("name");
    this.handlingLists(); //获取数据
  },
  methods: {
    //获取数据
    async handlingLists() {
      const res = await this.$http.post("/api/handling").then((res) => {
        this.handlingList = res.data.list1;
      });
    },
    //选择您的行业下拉事件
    selectMove(index) {
      this.handlingList1 = [];
      this.handlingList.forEach((item) => {
        this.handlingList1.push(item.list2);
      });
      this.handlingList1 = this.handlingList1[index];
    },
    //为列表里（类名：listb-r）添加里的第三类数组提供数据
    btnlClk(item3) {
      this.handlingList2 = item3.list3;
    },
    //控制智能推荐,下的右侧列表点击取消事件
    resultNum(item1) {
      item1.list3.forEach((item) => {
        item.btnrNum = false;
      });
    },
    //自动生成选中事件
    imgAutomatic() {
      this.chooseAutomatic = !this.chooseAutomatic;
      this.chooseManual = true;
      if (!this.chooseAutomatic) {
        this.chooseAdd = false;
      } else {
        this.chooseAdd = true;
      }
    },
    //手动上传选中事件
    imgManual() {
      this.chooseManual = !this.chooseManual;
      this.chooseAutomatic = true;
      if (!this.chooseManual) {
        this.chooseAdd = false;
      } else {
        this.chooseAdd = true;
      }
    },
    //手动上传，上传图片
    updateImg() {
      this.$refs.file.click();
    },
    //手动上传，上传图片之后
    fileChange(e) {
      // 通过DOM取文件数据
      const file = this.$refs.file.files[0];
      // 计算文件大小 KB
      const size = Math.floor(file.size / 1024);
      if (size > 200) {
        alert("文件过大");
      } else {
        // 显示本地图片
        this.manualImg = window.URL.createObjectURL(file);

        // 使用 FormData 构建表单数据
        const formData = new FormData();
        formData.append("file", file);
        this.updateImgSH = true;
      }
    },
    //自动生成图片事件
    drawLogo(text) {
      this.textAutomatic = false;
      // 创建画布
      let canvas = document.createElement("canvas");
      // 绘制文字环境
      let context = canvas.getContext("2d");
      // 获取字体宽度
      let width = Math.ceil(context.measureText(text).width) || 1;

      // 如果宽度不够 240

      // 画布宽度
      canvas.width = 190;
      // 画布高度
      canvas.height = 138;
      // 填充白色
      context.fillStyle = "#ffffff";
      // 绘制文字之前填充白色
      context.fillRect(0, 0, canvas.width, canvas.height);
      // 设置水平对齐方式
      context.textAlign = "center";
      // 设置垂直对齐方式
      context.textBaseline = "middle";
      if (width < 24) {
        context.font = "80px 微软雅黑";
      } else if (width <= 30) {
        context.font = "60px 微软雅黑";
        console.log(context.font);
      } else if (width <= 42) {
        context.font = "40px 微软雅黑";
      } else if (width <= 54) {
        context.font = "20px 微软雅黑";
      } else {
        context.font = "10px 微软雅黑";
      }
      // 设置字体颜色
      context.fillStyle = "#000";

      // 绘制文字（参数：要写的字，x坐标，y坐标）
      context.fillText(text, canvas.width / 2, canvas.height / 2);
      // 生成图片信息
      let dataUrl = canvas.toDataURL("image/jpg");
      this.automaticSrc = dataUrl;
      return dataUrl;
    },
    //自助选择，下的对应左侧列表选中事件
    thirdClk(index2, index1, index) {
      this.thirdChose = false;

      this.handlingList.forEach((list, i) => {
        //选中的第3类对应的第1类高亮
        if (i == index) {
          list.isOn = true;
        }
        list.list2.forEach((list1, k) => {
          //选中的第3类对应的第二类高亮
          if (i == index && k == index1) {
            list1.isOn = true;
          }
          list1.list3.forEach((list2, j) => {
            //第3类选中高亮
            if (i == index && k == index1 && j == index2) {
              list2.isOn = true;
              list2.isOn1 = true;
            } else {
              list2.isOn = false;
            }
          });
        });
      });
    },
    //全类保护下的右侧列表里添加第三类的左侧列表点击事件
    listlBtn(item1) {
      this.handlingList3 = item1.list3;
    },
    chooseNum(item1) {
      item1.list3.forEach((item) => {
        item.btnrNum = false;
      });
    },
    //下一步，并将所填，所选的数据存入storage中
    next() {
      storage.setItem("name", this.name);
      this.$router.push("/personal/handling/fllApplicant");
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../style/register.less";
</style>