<template>
<div class="incls p-b40 ba-col-fff">
  <h2>
    商品分类表
  </h2>
  <div class="incls_inp ">
    <el-input
        v-model="keyword"
        size="small"
        placeholder="请输入主营业务或者行业关键词进行查询">
      <i
          class="el-icon-search el-input__icon"
          slot="prefix">
      </i>
      <el-button size="small" slot="suffix" type="primary" @click="groupSearch">搜索</el-button>
    </el-input>
  </div>
  <div >
    <el-skeleton :loading="loading" animated class="incls_list">
      <template slot="template">
        <div class="inp_cont grid grid-row-gap20">
        <div class="" v-for="(item,index) in loadList">
          <p class="font-16 font-wbold col-blue"><el-skeleton-item variant="text" /></p>
          <p  class="ptb10 font-16 col-303 flex flex-cen">
            <el-skeleton-item variant="text" />
          </p>
          <div class="p-10-20 col-606 ba-col-F5F grid grid-row-gap8" >
            <p v-for="item1 in loadList"><el-skeleton-item variant="text" /></p>
          </div>
        </div>
        </div>
      </template>
      <div class="inp_cont grid grid-row-gap20">

        <div class="" v-for="(item,index) in goodCont">
          <p class="font-16 font-wbold col-blue">{{item.text}}</p>
          <p @click="inclsItem.indexOf(index) == -1 ? inclsItem.push(index) : inclsItem.splice(inclsItem.indexOf(index),1) " class="ptb10 font-16 col-303 flex flex-cen">
            {{item.group }}
            <i :class="{'el-icon-arrow-up':inclsItem.indexOf(index) == -1,'el-icon-arrow-down':inclsItem.indexOf(index) != -1}"></i>
          </p>
          <div class="p-10-20 col-606 ba-col-F5F grid grid-row-gap8" :class="{on:inclsItem.indexOf(index) != -1}">
            <p v-for="item1 in item.goods">{{item1.group}} {{ item1.name }}</p>
          </div>
        </div>
      </div>
    </el-skeleton>


  </div>
</div>
</template>

<script>
import storage from '@/utils/storage'
import {tmSearchGoods} from '@/api/personal.js'
export default {
  name: "goods",
  data(){
    return{
      loadList: storage.getList(),//骨架屏的起始数组（10条数据）
      loading:false,
      goodCont:[],
      inclsItem:[],
      keyword:'',//商品分类表的关键字
    }
  },
  created() {
    this.keyword = this.$route.params.keyword
    this.groupSearch()
  },
  methods:{
    //商品分类表关键字搜索
    async groupSearch(){
      this.loading = true
      try {
        const res = await tmSearchGoods({keyword:this.keyword})
        if(res && res.err_code === 0){
          this.goodCont = res.data
          this.loading = false
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      }finally {

      }
    },
  }

}
</script>


<style scoped lang="less">
.incls{
  height: calc(100vh - 80px);
  ::v-deep .incls_inp{
    width: 500px;
    margin: 20px auto;
    .el-input__inner{
      border-radius: 16px;
      background: #F4F4F5;
      border: none;
    }
    .el-input__suffix{
      right: 0;
      .el-button{
        border-radius: 16px;
      }
    }
  }

  ::v-deep .incls_list{

    overflow-y: auto;
    height: calc(100vh - 235px);
    .inp_cont{

      width: 58%;
      margin: 20px auto;
      .ba-col-F5F{
        transition: all .5s;
        height: calc(100% - 62px);
      }
      .ba-col-F5F.on{
        overflow: hidden;
        padding: 0;
        height: 0;
      }
    }

  }
}
.incls_list::-webkit-scrollbar{
  width : 0px;
  height: 10px;

}
.incls_list::-webkit-scrollbar-thumb{
  box-shadow   : inset 0px 0px 0px rgb(0, 0, 0,.1);
  background: rgba(0, 0, 0,0.1);
  border-radius: 5px;
}
.incls_list::-webkit-scrollbar-track {
  background: #F4F4F4;
  //box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
}
</style>