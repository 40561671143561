<template>
  <div class="">
    <h2>批量上传商标官文</h2>
    <div class="mainrb">
      <div class="mainrB-t">
        <el-button
            :disabled="btn || tableData.length == 0"
            icon="el-icon-upload2"
            size="small"
            type="primary"
            @click="chooseUp"
        >
          开始上传
        </el-button>
        <el-button :disabled="disabled" icon="el-icon-delete" size="small" type="danger" @click="remove(deleteArr)">
          删除已选
        </el-button>
      </div>

      <el-table
          ref="multipleTable"
          v-loading="loadingTable"
          :data="tableData"
          element-loading-text="文件识别中"
          height="100%"
          style="width: 100%"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          :row-class-name='getRow'
      >
        <template slot="empty">
          <p>1、选择文件：点击“选择文件”按钮选择需要上传的官文文件；</p>
          <p>2、完善信息：完善“官文类型”、“商标号”、“商标类别”等信息；</p>
          <p>3、上传文件：点击“开始上传”，待上传完成后可在商标官文中查看。</p>
          <p><i class="el-icon-info"></i> 文件名称格式为“官文类型_注册号_收件日期”的官文，系统将自动识别，如：商标注册证_47711548_20211213.pdf。</p>
          <button class="el-button el-button--primary el-button--small" data-v-5f0a7fba="" type="button"
                  @click="upload">
            <input ref="clearFile" accept="*" class="add-file-right-input" multiple="multiplt"
                   style="margin-left:70px;" type="file" @change="getFile($event)">
            <i class="el-icon-folder-opened"></i>
            <span>选择文件</span>
          </button>
        </template>
        <el-table-column
            :selectable="selectable"
            type="selection"
           >
        </el-table-column>
        <el-table-column
            label="序号"
            type="index"
            >
        </el-table-column>
        <el-table-column
            label="官文类型" width="200">
          <template slot-scope="scope">
            <el-select
                class="borderWarn"
                :ref="`matterRef${scope.row.index}`"
                :class="{on:!scope.row.matter && (input == scope.row.index) }"
                v-model="scope.row.matter"
                :disabled="scope.row.created_at != null"
                clearable
                filterable
                placeholder="请选择官文类型"
                size="small"
            >
              <el-option
                  v-for="item in typeFromSelect"
                  :label="item.text"
                  :value="item.text"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
            label="文件名称">
          <template slot-scope="scope">
            <span v-if="!scope.row.url">{{ scope.row.name }}</span>
            <a v-else target="_blank"
               :href="`${scope.row.url}&preview=true`" >
              {{scope.row.name}}
            </a>
          </template>
        </el-table-column>
        <el-table-column
            label="商标号" width="150">
          <template slot-scope="scope">
            <el-select
                :ref="`tmcodeRef${scope.row.index}`"
                :class="{on:!scope.row.tmcode && (input == scope.row.index)}"
                v-model="scope.row.tmcode"
                :disabled="scope.row.created_at != null"
                :loading="loading"
                :remote-method="remoteMethod"
                class="tmcode borderWarn"
                clearable
                collapse-tags
                filterable
                placeholder="请输入商标号"
                remote
                reserve-keyword
                size="small"
                @change="change(scope.row)"
                @focus="focus">
              <el-option
                  v-for="item in options"
                  :key="item.text"
                  :label="item.text"
                  :value="item.text">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
            label="商标类别" width="200">
          <template slot-scope="scope">
            <el-select
                :ref="`intclsRef${scope.row.index}`"
                class="borderWarn"
                :class="{on:!scope.row.intcls && (input == scope.row.index)}"
                v-model="scope.row.intcls"
                :disabled="scope.row.created_at != null"
                clearable
                filterable
                placeholder="请选择商标类别"
                size="small"
            >
              <el-option
                  v-for="(item,index) in scope.row.clsopt "
                  :key="index + 100"
                  :label="item.text"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
            label="申请人" prop="appname">
        </el-table-column>
        <el-table-column
            label="收文日期" width="200">
          <template slot-scope="scope">
            <el-date-picker
                :ref="`made_atRef${scope.row.index}`"
                class="borderWarn"
                :class="{on:!scope.row.made_at && (input == scope.row.index)}"
                v-model="scope.row.made_at"
                :readonly="scope.row.created_at != null"
                placeholder="请选择文件日期"
                size="small"
                type="date"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column
            label="上传日期" >
          <template slot-scope="scope">
            <span
                v-if="scope.row.created_at != ''">{{
                scope.row.created_at == null ? '待上传' : scope.row.created_at
              }}</span>
            <i v-else class="el-icon-loading"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        title="提示"
        width="30%">
      <span class="body"><i class="el-icon-success"></i> 商标官文文件已上传成功！</span>
      <span slot="footer" class="dialog-footer">
        <el-button icon="el-icon-folder-opened" size="small" type="primary" @click="uploaded">继续上传</el-button>
        <router-link to="/personal/trademarkAdmin/trademarkArchives">
          <el-button icon="el-icon-s-data" plain size="small" @click="uploaded" type="primary">查看官文</el-button>
        </router-link>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import {managementMatch, managementTmcodes, managementTMUpload, managementTMUploaded} from '@/api/personal.js'

export default {
  data() {
    return {
      dialogVisible: false,//上传成功后弹框
      loadingTable: false,//控制加载状态
      tableData: [],//列表数据
      options: [],//商标号下拉
      loading: false,//商标号查询时的加载
      files: [],//记录选择文件的file
      names: [],//记录选择文件的name
      deleteArr: [],//记录选中行id,
      disabled: true,//控制输入框的只读和禁用
      disupload: true,
      typeFromSelect: [],//官文类型下拉
      btn: true,
      input:-1,
    }
  },
  created() {
    this.select()
  },
  methods: {
    //下拉
    async select() {
      let s = await storage.getSelects('tm_doc_types')
      this.typeFromSelect = storage.getTrademarkTypes().concat(storage.getMaintainTypes())
    },
    //记录选中行
    handleSelectionChange(val) {
      this.deleteArr = []
      val.forEach((item) => {
        this.deleteArr.push(item)
      })
      if (val.length > 0) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    // 删除已选
    remove(val) {
      val.forEach((item, i) => {
        if (this.tableData.indexOf(item) != -1) {
          this.tableData.splice(this.tableData.indexOf(item), 1)
        }
        for (let k in this.files) {
          if (this.files[k].name == item.name) {
            this.files.splice(k, 1)
          }
        }

        if (this.names.indexOf(item.name) != -1) {
          this.names.splice(this.names.indexOf(item.name), 1)
        }
      })
    },
    //控制列表中不能被选
    selectable(row, index) {
      if (row.created_at == null) {
        return true
      } else {
        return false
      }
    },
    upload() {
      this.$refs.clearFile.click();
    },
    //选择文件
    async getFile(event) {
      let file = event.target.files;
      for (let k of file) {
        this.names.push(k.name)
        this.files.push(k)
      }
      try {
        this.loadingTable = true
        const res = await managementMatch({names: this.names})
        if (res && res.err_code === 0) {
          res.data.forEach((item) => {
            this.tableData.push(item)
            this.disupload = false
            this.loadingTable = false
            this.btn = false
          })
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {
        this.disupload = false
        this.loadingTable = false
      }
    },
    //商标号查询
    async remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        try {
          const res = await managementTmcodes({value: query})
          if (res && res.err_code === 0) {
            this.loading = false;
            this.options = res.data
          }else{
            this.$message({
              message: res.err_msg,
              type: 'warning'
            });
          }
        } finally {
          this.loading = false;
        }
      } else {
        this.options = [];
      }
    },
    //商标号对应的商标类别下拉
    change(val) {
      val.clsopt = []
      this.options.forEach((item) => {
        if (item.id == val.tmcode) {
          val.clsopt = item.opt
          val.appname = item.appname
          if (val.clsopt.length <= 1) {
            val.intcls = val.clsopt[0].id
          } else {
            val.intcls = null
          }
        }
      })
    },

    focus(event) {
      this.options = []
    },
    //上传文件

    getRow(row,rowIndex){
      this.$set(row.row, 'index', row.rowIndex);
    },
    // 开始上传
    async chooseUp() {
      let cont = 0
      this.btn = true
      let int;
      let k;
      let formData;
      for (k in this.tableData) {
        if (this.tableData[k].intcls && this.tableData[k].matter && this.tableData[k].tmcode && this.tableData[k].made_at) {
          int = true
        } else {
          if(!this.tableData[k].matter){
            this.$refs[`matterRef${k}`].focus()
          } else if(!this.tableData[k].tmcode){
            this.$refs[`tmcodeRef${k}`].focus()
          } else if(!this.tableData[k].intcls){
            this.$refs[`intclsRef${k}`].focus()
          }  else{
            this.$refs[`made_atRef${k}`].focus()
          }
          this.input = k
          this.btn = false
          int = false
          break;
        }
      }
      if (int) {
        for (let i in this.tableData) {
          if (this.tableData[i].created_at == null) {
            formData = new FormData()
            formData.append("doc", JSON.stringify(this.tableData[i]));
            formData.append("file", this.files[i]);
            this.tableData[i].created_at = ''
            try {
              const res = await managementTMUpload(formData)
              if (res && res.err_code === 0) {
                this.$set(this.tableData, i, res.data)
              }else{
                this.btn =true
              }
            } finally {
            }
          }
          if (this.tableData[i].created_at != null && this.tableData[i].created_at != '') {
            cont++
          }
        }
        if (cont == this.tableData.length) {
          this.dialogVisible = true

        }
      }
    },
    // 继续上传
    async uploaded() {
      try {
        const res = await managementTMUploaded(this.tableData)
        if (res && res.err_code === 0) {
          this.dialogVisible = false
          this.tableData = []
          this.files = []
          this.names = []
          this.input = -1
        }
      } finally {

      }


    }
  }
}
</script>

<style lang="less" scoped>
.mainrb {
  .mainrB-t {
    .el-button {
      margin: 10px 0 10px 10px;
    }
  }

  .el-table {
    ::v-deep .table__body-wrapper {
      height: calc(100vh - 305px) !important;

    }

    ::v-deep .el-date-editor {
      height: unset;
      .el-input__inner {
        padding: 0 30px !important;
      }
    }
    .borderWarn.on{
     ::v-deep input{
        border-color: red;
      }
    }
    .tmcode {
      ::v-deep .el-input {
        width: 100% !important;
      }

      ::v-deep .el-select__tags {
        flex-wrap: unset;
        left: 5px;
      }
    }
    ::v-deep .el-table__empty-block{
  .el-table__empty-text{
    width: 55%;
  }
}
  }

  .add-file-right-input {
    display: none;
  }
}

::v-deep .el-dialog {
margin-top: 35vh !important;
  padding-bottom: 10px;
  .el-dialog__header {
    padding: 15px 15px 10px;
  }
  .el-dialog__body{
    padding: 10px 15px !important;

  }
  .body{
    display: flex;
    align-items: center;
    i{
      color: #67c23a;
      font-size: 24px;
      margin-right: 10px;
    }
  }
  .el-dialog__footer{
    padding: 5px 15px 0;
  }
  .dialog-footer {
    a {
      margin-left: 10px;
    }
  }
}

::v-deep .el-table__body-wrapper {
  height: calc(~"100vh - 262px") !important;
}
</style>
