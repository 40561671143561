<template>
  <div class="newly-build">
    <h2>申请人信息</h2>
    <el-skeleton  :loading="loading" animated >
      <template slot="template">
        <el-form ref="details" :model="details" :rules="rules" class="demo-ruleForm" label-width="100px">
          <div class="applicant-information">
            <el-row :gutter="20">
              <el-col :span="details.client_type == 2 ? 8 : 12" >
                <el-form-item label="申请人类型:" >
                  <el-skeleton-item variant="p" />
                </el-form-item>
              </el-col>
              <el-col :span="details.client_type == 2 ? 8 : 12" >
                <el-form-item
                    :label="details.client_type == 2 ?  '身份证号:'  : '信用代码:' "
                >
                  <el-skeleton-item variant="p" />
                </el-form-item>
              </el-col>
              <el-col :span="details.client_type == 2 ? 8 : 0 " v-if="details.client_type == 2">
                <el-form-item label="证件类型:">
                  <el-skeleton-item variant="p" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="details.client_type == 2 ? 8 : 12 ">
                <el-form-item label="申请人名称:">
                  <el-skeleton-item variant="p" />
                </el-form-item>
              </el-col>
              <el-col :span="details.client_type == 2 ? 8 : 12 ">
                <el-form-item label="国家/地区:">
                  <el-skeleton-item variant="p" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="24" :xl="16" :xs="24">
                <el-form-item
                    label="申请人名称(英文)："
                >
                  <el-skeleton-item variant="p" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="16" :xs="16">
                <el-form-item
                    label="申请人地址:" prop="license_address">
                  <el-skeleton-item variant="p" />
                </el-form-item>
              </el-col>
              <el-col :sm="8" :xs="8">
                <el-form-item label="邮政编码:">
                  <el-skeleton-item variant="p" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="16" :xs="16">
                <el-form-item
                    label="申请人联系地址:"
                    prop="contact_address">
                  <el-skeleton-item variant="p" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :sm="16" :xs="16">
                <el-form-item
                    label="申请人地址(英文):">
                  <el-skeleton-item variant="p" />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="contact-information">
            <h2>联系人信息</h2>
            <el-row :gutter="20" class="contacts">
              <el-col :sm="8">
                <el-form-item label="联系人姓名:">
                  <el-skeleton-item variant="p" />
                </el-form-item>
              </el-col>
              <el-col :sm="8" class="">
                <el-form-item label="联系人电话:">
                  <el-skeleton-item variant="p" />
                </el-form-item>
              </el-col>
              <el-col :sm="8">
                <el-form-item label="电子邮箱:">
                  <el-skeleton-item variant="p" />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="license">
            <h2>营业执照</h2>
            <el-row  class="material-information">
              <el-col>
                <div class="">
                  <el-skeleton-item variant="text" style="width: 100px"/>
                  <el-skeleton-item variant="text" style="width: 280px"/>
                  <div class="" >
                    <el-skeleton-item variant="h3" style="display: block;margin: 10px 0; width: 500px;height: 20px;"/>
                    <el-skeleton-item variant="h3" style="width: 500px;height: 20px;"/>
                  </div>
                </div>
              </el-col>

            </el-row>
          </div>
          <el-row class="btn">
            <el-skeleton-item variant="text" style="width: 100px"/>
            <el-skeleton-item variant="text" style="width: 100px"/>
          </el-row>
        </el-form>
      </template>
    <el-form ref="details" :model="details" :rules="rules" class="demo-ruleForm" label-width="100px">
      <div class="applicant-information">
        <el-row :gutter="20">
          <el-col :span="details.client_type == 2 ? 8 : 12" >
            <el-form-item label="申请人类型:" >
              {{details.client_type == 1 ? '企业单位' : '个体经营'}}
            </el-form-item>
          </el-col>
          <el-col :span="details.client_type == 2 ? 8 : 12" >
            <el-form-item
                :label="details.client_type == 2 ?  '身份证号:'  : '信用代码:' "
            >
              {{details.client_type == 2 ? details.id_number : details.credit_code}}
            </el-form-item>
          </el-col>
          <el-col :span="details.client_type == 2 ? 8 : 0 " v-if="details.client_type == 2">
            <el-form-item label="证件类型:">
              {{details.license_type == 1 ? '身份证' : (details.license_type == 2 ? '护照' : '营业执照')}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="details.client_type == 2 ? 8 : 12 ">
            <el-form-item label="申请人名称:">
              {{details.name}}
            </el-form-item>
          </el-col>
          <el-col :span="details.client_type == 2 ? 8 : 12 ">
            <el-form-item label="国家/地区:">
              {{details.country}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :sm="24" :xl="16" :xs="24">
            <el-form-item
                label="申请人名称(英文)："
            >
              <el-input v-model="details.name_en" clearable placeholder="请输入申请人名称(英文)" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :sm="16" :xs="16">
            <el-form-item
                label="申请人地址:" prop="license_address">
              <el-input v-model="details.license_address" clearable placeholder="请输入申请人地址" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="8" :xs="8">
            <el-form-item label="邮政编码:">
              <el-input clearable v-model="details.zip_code" placeholder="请输入邮政编码" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :sm="16" :xs="16">
            <el-form-item
                label="申请人联系地址:"
                prop="contact_address">
              <el-input v-model="details.contact_address" clearable placeholder="请输入申请人联系地址" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :sm="16" :xs="16">
            <el-form-item
                label="申请人地址(英文):">
              <el-input v-model="details.license_address_en" clearable placeholder="请输入申请人地址(英文)" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="contact-information">
        <h2>联系人信息</h2>
        <el-row :gutter="20" class="contacts">
          <el-col :sm="8">
            <el-form-item label="联系人姓名:">
              {{details.contact_name}}
            </el-form-item>
          </el-col>
          <el-col :sm="8" class="">
            <el-form-item label="联系人电话:">
              {{details.contact_cellphone}}
            </el-form-item>
          </el-col>
          <el-col :sm="8">
            <el-form-item label="电子邮箱:">
              {{details.contact_email}}
            </el-form-item>
          </el-col>
        </el-row>
      </div>
<div class="license">
  <h2>营业执照</h2>
  <el-row  class="material-information">
    <el-col>
      <el-upload

          ref="sampupload"
          :file-list="fileList"
          action=""
          :http-request="uploadImg"
          :before-upload="beforeAvatar"
          class="upload-demo"
          list-type="picture">
        <el-button icon="el-icon-upload2" size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">
          <el-alert
              title="请上传PDF的文件，大小不超过2M"
              type="info">
          </el-alert>
        </div>
        <div slot="file" slot-scope="{file}">
          <a :href="`${file.url}&preview=true`"
             target="_blank">
            <img src="@/assets/img/personal/icon-pdf.jpeg" alt="">
          </a>
          <div class="name">
            {{ file.filename || file.name }}
            <i class="el-icon-download" @click="sampleDownload(file)"></i>
          </div>
        </div>
      </el-upload>
    </el-col>

  </el-row>
</div>

      <div class="license" v-if="details.country != '中国'">
        <h2>营业执照翻译件</h2>
        <el-row  class="material-information">
          <el-col>
            <el-upload
                ref="translateupload"
                :file-list="translateList"
                action=""
                :http-request="uploadTranslate"
                :before-upload="beforeTranslater"
                class="upload-demo"
                list-type="picture">
              <el-button icon="el-icon-upload2" size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                <el-alert
                    title="请上传PDF的文件，大小不超过2M"
                    type="info">
                </el-alert>
              </div>
              <div slot="file" slot-scope="{file}">
                <a :href="`${file.url}&preview=true`"
                   target="_blank">
                  <img src="@/assets/img/personal/icon-pdf.jpeg" alt="">
                </a>
                <div class="name">
                  {{ file.filename || file.name }}
                  <i class="el-icon-download" @click="sampleDownload(file)"></i>
                </div>
              </div>
            </el-upload>
          </el-col>

        </el-row>
      </div>
      <el-row class="btn">
        <el-button icon="el-icon-close" size="small" @click="exit">取消</el-button>
        <el-button :disabled="loadSave" :icon="loadSave ? 'el-icon-loading' : 'el-icon-folder-checked'" size="small" @click="submitForm('details')" type="primary">保存</el-button>
      </el-row>
    </el-form>
    </el-skeleton>
  </div>
</template>

<script>
import {applicantsDetail, managementUpload,applicantsSave} from "@/api/personal.js"

export default {
  data() {
    return {
      loadSave:false,
      loading:true,
      countryRadio: [],
      //新建申请人表单字段
      details: {},
      id:'',
      fileList: [],
      translateList:[],
      business_license:{
        filename:'',
        filepath:'',
        url:'',
      },
      rules: {
        license_address: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
        contact_address: [
          {required: true, message: '请输入', trigger: 'blur'}
        ],
      },
      documentTypeSelect: [
        {id: "01", value: "身份证"},
        {id: "02", value: "护照"},
        {id: "03", value: "营业执照"},
      ],
    };
  },
  created() {
    this.detail()
  },
  methods: {
    async detail() {
      this.loading = true
      this.id = this.$route.params.id
      try {
        const res = await applicantsDetail({id:this.id})
        if (res && res.err_code === 0) {
          this.loading = false
          this.details = res.data
          if(this.details.business_license.url){
            this.fileList = [this.details.business_license]
          }else{
            this.fileList = []
          }
          if(!this.details.translate.url){
            this.translateList = []
          }else{
            this.translateList = [this.details.translate]
          }
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {
        this.loading = false
      }

    },
    submitForm(details) {
      this.$refs[details].validate(async (valid) => {
        if (valid) {
          try {
            this.loadSave = true
            const res = await applicantsSave(this.details)
            if(res && res.err_code === 0){
              this.$message.success('保存成功');
              this.loadSave = false
              this.detail()
            }
          }catch (e) {
            this.loadSave = false
          }
        } else {
          return false;
        }
      });
    },
    beforeAvatar(file) {
      const isPDF = file.type === 'application/pdf';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if ( !isPDF ) {
        this.$message.error('上传文件只能是 PDF 格式!');
      }else{
        if (!isLt2M) {
          this.$message.error('上传文件大小不能超过 2MB!');
        }else{
          this.fileList = []
        }
      }
      return isPDF && isLt2M;
    },
    beforeTranslater(file){
      const isPDF = file.type === 'application/pdf';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if ( !isPDF ) {
        this.$message.error('上传文件只能是 PDF 格式!');
      }else{
        if (!isLt2M) {
          this.$message.error('上传文件大小不能超过 2MB!');
        }else{
          this.translateList = []
        }
      }
      return isPDF && isLt2M;
    },
    //文件上传后事件(自动上传)
    async uploadImg(item) {
      let formData = new FormData();
      formData.append("file", item.file);
      try {
        const res = await managementUpload(formData)
        if (res && res.err_code === 0) {
          this.fileList.push(res.data)
          this.fileList.forEach((item) =>{
            if(item.filename){
              this.details.business_license.filename = item.filename
              this.details.business_license.filepath = item.filepath

            }else{
              this.details.business_license.filename = item.name
              this.details.business_license.filepath = item.path
            }
            this.details.business_license.url = item.url
          })
        } else {
          this.$message({
            type: "warning",
            message: res.err_msg,
          })
        }
      } finally {
        this.uploadBtn = true;
      }
    },
    async uploadTranslate(item){
      let formData = new FormData();
      formData.append("file", item.file);
      try {
        const res = await managementUpload(formData)
        if(res && res.err_code === 0){
          this.translateList.push(res.data)
          this.translateList.forEach((item) =>{
            if(item.filename){
              this.details.translate.filename = item.filename
              this.details.translate.filepath = item.filepath

            }else{
              this.details.translate.filename = item.name
              this.details.translate.filepath = item.path
            }
            this.details.translate.url = item.url
          })

        }
      }finally {

      }
    },
    sampleDownload(file) {
      if(file.link){
        location.href = file.link
      }else{
        location.href = file.url
      }
    },
    exit(){
      window.close()
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../style/newlyBuild.less";
</style>
