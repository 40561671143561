<template>
<div class="">
  <div  class="incls p-b40 ba-col-fff">
  <h2>
    商品分类表
  </h2>
  <div class="incls_inp ">
    <el-input
        v-model="keyword"
        size="small"
        placeholder="请输入主营业务或者行业关键词进行查询">
      <i
          class="el-icon-search el-input__icon"
          slot="prefix">
      </i>
      <el-button size="small" slot="suffix" type="primary" @click="groupSearch">搜索</el-button>
    </el-input>
  </div>
  <div  class="incls_text flex flex-warp flex-cen text-cen">
    <div class="text_item  m-b15 "  v-for="(item,index) in inclsList">
      <div class="grid grid-row-gap4 m-auto pointer" @click="searchClass(index + 1)">
        <img :src="item.icon" alt="" class="m-auto">
        <p>{{item.name}}</p>
      </div>

    </div>
  </div>

</div>
</div>
</template>

<script>
import storage from '@/utils/storage'
import icon1 from '@/assets/img/incls/cls1.png'
import icon2 from '@/assets/img/incls/cls2.png'
import icon3 from '@/assets/img/incls/cls3.png'
import icon4 from '@/assets/img/incls/cls4.png'
import icon5 from '@/assets/img/incls/cls5.png'
import icon6 from '@/assets/img/incls/cls6.png'
import icon7 from '@/assets/img/incls/cls7.png'
import icon8 from '@/assets/img/incls/cls8.png'
import icon9 from '@/assets/img/incls/cls9.png'
import icon10 from '@/assets/img/incls/cls10.png'
import icon11 from '@/assets/img/incls/cls11.png'
import icon12 from '@/assets/img/incls/cls12.png'
import icon13 from '@/assets/img/incls/cls13.png'
import icon14 from '@/assets/img/incls/cls14.png'
import icon15 from '@/assets/img/incls/cls15.png'
import icon16 from '@/assets/img/incls/cls16.png'
import icon17 from '@/assets/img/incls/cls17.png'
import icon18 from '@/assets/img/incls/cls18.png'
import icon19 from '@/assets/img/incls/cls19.png'
import icon20 from '@/assets/img/incls/cls20.png'
import icon21 from '@/assets/img/incls/cls21.png'
import icon22 from '@/assets/img/incls/cls22.png'
import icon23 from '@/assets/img/incls/cls23.png'
import icon24 from '@/assets/img/incls/cls24.png'
import icon25 from '@/assets/img/incls/cls25.png'
import icon26 from '@/assets/img/incls/cls26.png'
import icon27 from '@/assets/img/incls/cls27.png'
import icon28 from '@/assets/img/incls/cls28.png'
import icon29 from '@/assets/img/incls/cls29.png'
import icon30 from '@/assets/img/incls/cls30.png'
import icon31 from '@/assets/img/incls/cls31.png'
import icon32 from '@/assets/img/incls/cls32.png'
import icon33 from '@/assets/img/incls/cls33.png'
import icon34 from '@/assets/img/incls/cls34.png'
import icon35 from '@/assets/img/incls/cls35.png'
import icon36 from '@/assets/img/incls/cls36.png'
import icon37 from '@/assets/img/incls/cls37.png'
import icon38 from '@/assets/img/incls/cls38.png'
import icon39 from '@/assets/img/incls/cls39.png'
import icon40 from '@/assets/img/incls/cls40.png'
import icon41 from '@/assets/img/incls/cls41.png'
import icon42 from '@/assets/img/incls/cls42.png'
import icon43 from '@/assets/img/incls/cls43.png'
import icon44 from '@/assets/img/incls/cls44.png'
import icon45 from '@/assets/img/incls/cls45.png'
export default {
  name: "intcls",
  data(){
    return{

      inclsList: [
        {
          icon: icon1,
          name: '01化工原料'
        },
        {
          icon: icon2,
          name: '02油漆涂料'
        },
        {
          icon: icon3,
          name: '03化妆清洁'
        },
        {
          icon: icon4,
          name: '04工业油脂'
        },
        {
          icon: icon5,
          name: '05药品制剂'
        },
        {
          icon: icon6,
          name: '06五金器具'
        },
        {
          icon: icon7,
          name: '07机械机器'
        },
        {
          icon: icon8,
          name: '08手工用具'
        },
        {
          icon: icon9,
          name: '09电子电器'
        },
        {
          icon: icon10,
          name: '10医疗器械'
        },
        {
          icon: icon11,
          name: '11电器装置'
        },
        {
          icon: icon12,
          name: '12运输工具'
        },
        {
          icon: icon13,
          name: '13军火烟花'
        },
        {
          icon: icon14,
          name: '14珠宝钟表'
        },
        {
          icon:icon15,
          name: '15各种乐器'
        },
        {
          icon: icon16,
          name: '16办公用品'
        },
        {
          icon: icon17,
          name: '17橡胶制品'
        },
        {
          icon: icon18,
          name: '18皮革皮具'
        },
        {
          icon: icon19,
          name: '19建筑材料'
        },
        {
          icon: icon20,
          name: '20家具装饰'
        },
        {
          icon:icon21,
          name: '21家用器具'
        },
        {
          icon: icon22,
          name: '22绳网袋篷'
        },
        {
          icon: icon23,
          name: '23纺织纱线'
        },
        {
          icon: icon24,
          name: '24布料床品'
        },
        {
          icon: icon25,
          name: '25服装鞋帽'
        },
        {
          icon: icon26,
          name: '26服装辅料'
        },
        {
          icon: icon27,
          name: '27地毯席垫'
        },
        {
          icon:icon28,
          name: '28娱乐器具'
        },
        {
          icon: icon29,
          name: '29鱼肉蛋奶'
        },
        {
          icon: icon30,
          name: '30方便食品'
        },
        {
          icon: icon31,
          name: '31植物种籽'
        },
        {
          icon: icon32,
          name: '32啤酒饮料'
        },
        {
          icon: icon33,
          name: '33含酒精饮料'
        },
        {
          icon: icon34,
          name: '34烟草烟具'
        },
        {
          icon: icon35,
          name: '35广告销售'
        },
        {
          icon: icon36,
          name: '36金融不动产'
        },
        {
          icon: icon37,
          name: '37安装维修'
        },
        {
          icon: icon38,
          name: '38通讯服务'
        },
        {
          icon: icon39,
          name: '39运输储藏'
        },
        {
          icon: icon40,
          name: '40材料加工'
        },
        {
          icon: icon41,
          name: '41教育娱乐'
        },
        {
          icon: icon42,
          name: '42技术服务'
        },
        {
          icon: icon43,
          name: '43餐饮住宿'
        },
        {
          icon: icon44,
          name: '44医疗园艺'
        },
        {
          icon:icon45,
          name: '45社会服务'
        },
      ],

      keyword:'',//商品分类表的关键字
    }

  },
  created() {
  },
  methods:{
//商品分类表关键字搜索
     groupSearch(){
      const {href} = this.$router.resolve({
        name: 'queryGoods',
        params: {keyword:this.keyword},
      })
       window.open(href, '_blank');
    },

    //类别搜索
     searchClass(index){
      const {href} = this.$router.resolve({
        name: 'queryClass',
        params: {intcls:(index < 10 ) ? ('0' + index) : index},
      })
      window.open(href, '_blank');

    },
  }
}
</script>

<style scoped lang="less">
.incls{
::v-deep .incls_inp{
  width: 500px;
  margin: 20px auto;
.el-input__inner{
  border-radius: 16px;
  background: #F4F4F5;
  border: none;
}
.el-input__suffix{
  right: 0;
.el-button{
  border-radius: 16px;
}
}
}
.incls_text{
  max-width: 1000px;
  margin: auto;
.text_item{
  width: 20%;
  div{
    width: 95px;
    padding: 5px 0;
  }
  div:hover{
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
  }
img{
  height: 40px;
}
}
}
.text_cont{
.cont{
  width: 58%;
  margin: auto;
.m-b15{
  width: 20%;
}
.cont_t{
  margin-bottom: 15px;
}
.cont_b{
  max-height: 620px;
.cont_b_l,.cont_b_r{
  overflow-y: auto;
  max-height: 100%;
  flex: 1;
}
.cont_b_r{
  border-left: 1px solid #E4E7ED;
  overflow-y: auto;

}
.cont_b_l::-webkit-scrollbar,.cont_b_r::-webkit-scrollbar {
  width : 5px;
  height: 10px;

}
.cont_b_l::-webkit-scrollbar-thumb,.cont_b_r::-webkit-scrollbar-thumb{
  box-shadow   : inset 0px 0px 0px rgb(0, 0, 0,.1);
  background: rgba(0, 0, 0,0.1);
  border-radius: 5px;
}
.cont_b_l::-webkit-scrollbar-track,.cont_b_r::-webkit-scrollbar-track {
  background: #F4F4F4;
//box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
}
}
}
}
}
</style>