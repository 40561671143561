<template>
<div class="content w100 ba-col-fff m-auto col-303">
<div class="m-b40">
  <img class="w100" src="@/assets/img/personal/contact_top.png" alt="">
</div>
  <div class="cont m-auto">
    <h1 class="font-24 font-wbold text-cen cont_top ">南京知者云科技有限公司</h1>
    <div class="cont_cen flex ">
      <div class="cen_l flex flex-direction  line-h28">
          <p><i class="icon-location-fill iconfont"></i><span>江苏省南京市中山南路8号苏豪大厦15楼</span></p>
        <p><i class="icon-email-fill iconfont"></i><span>210000</span></p>
        <p><i class="icon-email-fill iconfont"></i><span>yy@zzyip.cn</span></p>
        <p><i class="icon-phone-fill iconfont"></i><span>025-83314108</span></p>
      </div>
      <div class="cen_R flex flex-gap10">
        <i class="icon-wechat-fill iconfont"></i>
        <img src="@/assets/img/personal/weix.png" alt="">
      </div>
    </div>

    <div class="cont_b">
      <img class="w100" src="@/assets/img/personal/map.png" alt="">
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "contact"
}
</script>

<style scoped lang="less">
.content{
    max-width: 1000px;
    height: calc(100vh - 100px);
    overflow-y: auto;

    .cont{

      .cont_cen{

        i{
          font-size: 20px;
          color: #409EFF;
        }
        .cen_l{
          p{
            display: flex;
            gap: 10px;
            align-items: center;
          }

        }
        .cen_r{
          img{
            width: 180px;
          }
        }
      }
      .cont_b{
        max-width: 600px;
      }
    }
  }
.content::-webkit-scrollbar {
  width : 0px;
  height: 15px;

}
@media screen and (max-width: 768px){

 .cont{
   width: 80%;
   h1{
     margin-bottom: 30px;
   }
   .cont_cen{
     flex-direction:column;
     gap: 20px;
     .cen_l{
        gap: 15px;
     }
   }
   .cont_b{
     width: 100%;
     padding: 30px 0;
   }
 }
}

@media screen and (min-width:768px){
  .content{
    padding-bottom: 80px;
    .cont{
      max-width: 600px;
      width: 60%;
      .cont_cen{
        padding: 30px 45px;
        justify-content: space-between;
        .cen_l{
          gap: 10px;
        }

      }
      //.cont_b{
      //  width: 60%;
      //}
    }
  }



}
</style>