<template>
    <div class="">
        <div class="helpCenter-ban">
            <div class="container">帮助中心</div>
        </div>
        <div class="helpCenter-cont">
          <div class="container">
            <h2>常见问题</h2>
            <div class="cont">
              <div class="cont-l">
                <p class="on">常见商标问题</p>
                <p>常见版权问题</p>
                <p>常见专利问题</p>
              </div>
              <div class="cont-r">
                <h4>常见商标问题</h4>
                <div class="r-list">
                  <ul>
                    <li>
                      <span>1、</span>
                      <div class="">
                        <p>问：哪些情形可能会导致不予受理哪些情形可能会导致不予受理哪些情形可能会导致不予受理哪些情形可能会导致不予受理哪些情形可能会导致不予受理哪些情形可能会导致不予受理哪些情形可能会导致不予受理哪些情形可能会导致不予受理哪些情形可能会导致不予受理？</p>
                        <p>答：比如章戳不清、申请人不具备申请资格或者提供的主体资格证明无效等等这些情况都会导致不予受理，所以申报之前我们
                          会仔细检查报送文件，确保商标局受理，以免耽误申请。哪些情形可能会导致不予受理哪些情形可能会导致不予受理哪些情形可能会导致不予受理哪些情形可能会导致不予受理哪些情形可能会导致不予受理哪些情形可能会导致不予受理哪些情形可能会导致不予受理哪些情形可能会导致不予受理哪些情形可能会导致不予受理哪些情形可能会导致不予受理</p>
                      </div>
                    </li>
                    <li>
                      <span>1、</span>
                      <div class="">
                        <p>问：哪些情形可能会导致不予受理？</p>
                        <p>答：比如章戳不清、申请人不具备申请资格或者提供的主体资格证明无效等等这些情况都会导致不予受理，所以申报之前我们
                          会仔细检查报送文件，确保商标局受理，以免耽误申请。</p>
                      </div>
                    </li>
                  </ul>

                </div>
              </div>
            </div>

          </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="less" scoped>
@import "../../style/helpCenter.less";
</style>