<template>
  <div class="">
    <div class="copyServices-ban">
      <div class="container">
        <img alt="" class="copyServices-logo" src="@/assets/img/copyServices/copyServices-ban.png">
        <h1>申请版权登记<br>捍卫作品价值</h1>
        <form action="">
          <input placeholder="  请输入您的电话号码" type="text"/>
          <button>
            立即咨询
            <img alt="" src="@/assets/img/copyServices/icon-button.png"/>
          </button>
        </form>
      </div>
    </div>

    <div class="copyServices-advantage container">
      <div class="copyServices-title">登记著作版权的五大优势</div>
      <ul>
        <li>
          <img alt="" src="@/assets/img/copyServices/advantage1.png"/>
          <h3>避免纠纷</h3>
          <p>在发生著作权纠纷时， 是主张权利的有力武器</p>
        </li>
        <li>
          <img alt="" src="@/assets/img/copyServices/advantage2.png"/>
          <h3>转让继承</h3>
          <p>在进行版权转让交易活动 时，可提高交易的安全性</p>
        </li>
        <li>
          <img alt="" src="@/assets/img/copyServices/advantage3.png"/>
          <h3>无形资产</h3>
          <p>在著作权质押融资时，是 一个重要的材料和证明文件</p>
        </li>
        <li>
          <img alt="" src="@/assets/img/copyServices/advantage4.png"/>
          <h3>保护权益</h3>
          <p>作为权利证明，通常是著作权 人启动反盗版维权行动的前提</p>
        </li>
        <li>
          <img alt="" src="@/assets/img/copyServices/advantage5.png"/>
          <h3>政府福利</h3>
          <p>获得政策优惠或补贴</p>
        </li>
      </ul>
    </div>

    <div class="copyServices-technological">
      <div class="container">
        <div class="copyServices-title">著作权办理流程</div>
        <ul>
          <li>
            <h4>
              <img alt="" src="@/assets/img/copyServices/technological1.png"/>
            </h4>
            <p>确定类别并准备资料</p>
          </li>
          <li>
            <img alt="" src="@/assets/img/patentServices/icon-process2.png"/>
            <img alt="" src="@/assets/img/patentServices/icon-process3.png"/>
          </li>
          <li>
            <h4>
              <img alt="" src="@/assets/img/copyServices/technological2.png"/>
            </h4>
            <p>递交申请资料</p>
          </li>
          <li>
            <img alt="" src="@/assets/img/patentServices/icon-process2.png"/>
            <img alt="" src="@/assets/img/patentServices/icon-process3.png"/>
          </li>
          <li>
            <h4>
              <img alt="" src="@/assets/img/copyServices/technological3.png"/>
            </h4>
            <p>作品审查</p>
          </li>
          <li>
            <img alt="" src="@/assets/img/patentServices/icon-process2.png"/>
            <img alt="" src="@/assets/img/patentServices/icon-process3.png"/>
          </li>
          <li>
            <h4>
              <img alt="" src="@/assets/img/copyServices/technological4.png"/>
            </h4>
            <p>核准登记</p>
          </li>
          <li>
            <img alt="" src="@/assets/img/patentServices/icon-process2.png"/>
            <img alt="" src="@/assets/img/patentServices/icon-process3.png"/>
          </li>
          <li>
            <h4>
              <img alt="" src="@/assets/img/copyServices/technological5.png"/>
            </h4>
            <p>收到证书</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="copyServices-data">
      <div class="container">
        <div class="copyServices-title">办理登记所需资料</div>
        <div class="data-cont">
          <div class="data-contL">
            <img alt="" src="@/assets/img/copyServices/data1.png"/>
            <h4>作品著作权</h4>
            <p>作品著作权申请表</p>
            <p>作品说明书</p>
            <p>权属证明</p>
            <p>作品样本</p>
            <p>身份证明文件</p>
            <p>代理委托书</p>
          </div>
          <div class="data-contR">
            <img alt="" src="@/assets/img/copyServices/data2.png"/>
            <h4>软件著作权</h4>
            <p>软件著作权申请表</p>
            <p>操作手册</p>
            <p>源程序代码</p>
            <p>权属证明</p>
            <p>身份证明（法人为<br> 营业执照、个人为身份证）</p>
            <p>代理委托书</p>
          </div>
        </div>
      </div>
    </div>

    <div class="copyServices-service container">
      <div class="copyServices-title">服务优势</div>
      <ul>
        <li>
          <strong>
            <img alt="" src="@/assets/img/copyServices/service1.png"/>
          </strong>
          <p>专业代理人均从业2年以上，熟练 掌握商标注册流程，代理经验丰富</p>
        </li>
        <li>
          <strong>
            <img alt="" src="@/assets/img/copyServices/service2.png"/>
          </strong>
          <p>
            商标申请材料备齐，30分钟极速递交 商标局（工作日下午3点前确认完毕）
          </p>
        </li>
        <li>
          <strong>
            <img alt="" src="@/assets/img/copyServices/service3.png"/>
          </strong>
          <p>
            便捷商标注册通道，三步搞定！有更高的
            商标注册成功率和精准的商标注册结果， 商标服务质量有保证
          </p>
        </li>
      </ul>
    </div>


    <div class="copyServices-group">
      <div class="container">
        <img alt="" class="" src="@/assets/img/trademark/groupPhoto.png"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {};

</script>

<style lang="less" scoped>
@import "../../style/copyServices.less";
</style>