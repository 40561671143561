
<template>
  <div class="">
    <h2>
      商标官文<span v-if="managementList.length != 0">{{ pageSize }} </span>
    </h2>
    <!-- 表单 -->
    <form  class="acvhives" ref="filters" v-on:submit.prevent>
      <el-row class="formInput" :class="{on:formOpen}" >
        <el-col :xs="10" :sm="10" :md="7" :lg="7" :xl="7">
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="6" :sm="6" :md="7" :lg="6" :xl="4"><strong>商标名称</strong></el-col>
            <el-col :xs="18" :sm="18" :md="17" :lg="18" :xl="20">
              <el-input

                  clearable
                  size="small"
                  v-model="filters.tmname"
                  placeholder="请输入商标名称"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="10" :sm="10" :md="7" :lg="7" :xl="7">
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="6" :sm="6" :md="7" :lg="6" :xl="4"><strong>商标号</strong></el-col>
            <el-col :xs="18" :sm="18" :md="17" :lg="18" :xl="20">
              <el-input

                  clearable
                  size="small"
                  v-model="filters.tmcode"
                  placeholder="请输入商标号"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="10" :sm="10" :md="7" :lg="7" :xl="7">
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="6" :sm="6" :md="7" :lg="6" :xl="4"><strong>官文类型</strong></el-col>
            <el-col :xs="18" :sm="18" :md="17" :lg="18" :xl="20">
              <el-select
                  clearable
                  size="small"

                  v-model="filters.matter"
                  filterable
                  placeholder="请选择官文类型"
              >
                <el-option
                    v-for="item in typeFromSelect"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="10" :sm="10" :md="7" :lg="7" :xl="7">
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="6" :sm="6" :md="7" :lg="6" :xl="4"><strong>商标类别</strong></el-col>
            <el-col :xs="18" :sm="18" :md="17" :lg="18" :xl="20">
              <el-select
                  clearable
                  multiple
                  collapse-tags
                  size="small"
                  v-model="filters.intcls"
                  filterable
                  placeholder="请选择商标类别"
              >
                <el-option
                    v-for="(item,index) in categorySelect"
                    :key="index"
                    :label="item.text"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="10" :sm="10" :md="7" :lg="7" :xl="7">
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="6" :sm="6" :md="7" :lg="6" :xl="4"><strong>申请人</strong></el-col>
            <el-col :xs="18" :sm="18" :md="17" :lg="18" :xl="20">
              <el-select
                  clearable
                  size="small"
                  v-model="filters.appname"
                  filterable
                  placeholder="请选择申请人"
              >
                <el-option
                    v-for="item in applicantSelect"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>

      </el-row>
      <el-row class="formOpen">
        <el-col  :span="24" :class="{on:formOpen}" >
          <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
        </el-col>
      </el-row>
      <el-button
          type="primary"
          size="small"
          class="search"
          @click="managementLists(filters.page = 1)"
          icon="el-icon-search"
      >查询</el-button
      >
      <el-button type="reset" :class="{on:formOpen}" size="small" class="reset" @click="reset" icon="el-icon-refresh-left"
      >重置</el-button
      >
    </form>

    <div class="mainrb">
      <div class="managementBt clearfix">
        <router-link to="/personal/trademarkAdmin/upload">
          <el-button
              type="primary"
              size="small"
              class="search"
              icon="el-icon-upload2"
          >
            批量上传
          </el-button>
        </router-link>

        <span v-if="managementList.length != 0">
          <el-button   size="small" @click="download" v-if="down" icon="el-icon-download" :disabled="managementList.length == 0 || loading" >批量下载</el-button>
          <el-button  v-else size="small" :loading="true">下载中</el-button>
        </span>
        <div class="page-up">
          <el-pagination
              background
              size="small"
              layout="prev, next"
              @current-change="managementLists"
              :current-page.sync="filters.page"
              :page-sizes="[30, 50, 100]"
              :page-size="filters.per_page"
              :total="pageSize">
          </el-pagination>
        </div>
      </div>
      <!-- 表格 -->
      <el-skeleton  :loading="loading" animated :count="1">
        <template slot="template">
          <el-table
              :class="{on:!formOpen,ios:$store.state.heights}"
              :data="managementList"
              @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column  width="50" label="序号">
              <template>
                <el-skeleton-item variant="p" class="w80"/>
              </template>
            </el-table-column>
            <el-table-column label="官文类型" width="">
              <template>
                <el-skeleton-item variant="p" class="w80"/>
              </template>
            </el-table-column>
            <el-table-column label="申请人" width="">
              <template>
                <el-skeleton-item variant="p" class="w80"/>
              </template>
            </el-table-column>
            <el-table-column label="商标类别" width="">
              <template>
                <el-skeleton-item variant="p" class="w80"/>
              </template>
            </el-table-column>
            <el-table-column label="商标名称">
              <template>
                <el-skeleton-item variant="p" class="w80"/>
              </template>
            </el-table-column>
            <el-table-column label="商标号">
              <template>
                <el-skeleton-item variant="p" class="w80"/>
              </template>
            </el-table-column>
            <el-table-column label="收文日期">
              <template>
                <el-skeleton-item variant="p" class="w80"/>
              </template>
            </el-table-column>
            <el-table-column label="上传日期">
              <template>
                <el-skeleton-item variant="p" class="w80"/>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template>
                <el-skeleton-item variant="p" class="w80"/>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-table
            :class="{on:!formOpen,ios:$store.state.heights}"
            :data="managementList"
            @selection-change="handleSelectionChange"
            height="100%"
        >
          <template slot="empty">
            <el-empty :image-size="80"></el-empty>
          </template>
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column type="index" width="50" label="序号">
          </el-table-column>
          <el-table-column label="官文类型" :show-overflow-tooltip="true" width="">
            <template slot-scope="scope">
              <a target="_blank"
                 :href="`${scope.row.url}&preview=true`" >
                {{scope.row.matter}}
              </a>
            </template>
          </el-table-column>
          <el-table-column label="商标名称" width="" :show-overflow-tooltip="true">
            <template slot-scope="scope">


              <span >{{ scope.row.tmname || '图形'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="申请人" width="" :show-overflow-tooltip="true">
            <template slot-scope="scope">

              <span :class="{'col-blue':scope.row.appname}">
                {{scope.row.appname || '-'}}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="商标类别" :show-overflow-tooltip="true" prop="clstext"> </el-table-column>
          <el-table-column label="商标号" width="100">
            <template slot-scope="scope">
              <router-link
                  target="_blank"
                  :to="{
                name: 'tradDetails',
                params: { tmcode: scope.row.tmcode, intcls: scope.row.intcls,alias:0 },
              }">
                {{scope.row.tmcode}}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="收文日期" width="110" prop="made_at"></el-table-column>
          <el-table-column label="上传日期" width="110" prop="created_at"></el-table-column>
          <el-table-column label="操作" width="170" fixed="right" >
            <template slot-scope="scope" >
              <div class="operation">
                <el-button type="text" size="small" @click="openUrl(scope.row)" icon="el-icon-download">下载</el-button>
                <el-button v-if="scope.row.is_custom == 1" @click="deleteFile(scope.row.id)" type="text" class="delete" size="small" icon="el-icon-delete">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-skeleton>

      <!-- 分页 -->
      <el-pagination
          @size-change="changeRowNum"
          @current-change="managementLists(backTop)"
          :current-page.sync="filters.page"
          :page-sizes="[30, 50, 100]"
          :page-size="filters.per_page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageSize"
      >
      </el-pagination>
    </div>
  </div>
</template>


<script>
import storage from '@/utils/storage'
import { management,managementDown,managementDelete, } from '@/api/personal.js'
export default {
  name: 'Archives',
  data() {
    return {
      formOpen:false,//控制搜索的展开与收起
      down:true,//控制导出中的显示与隐藏
      loading: true,//控制列表的骨架屏
      managementList: [],//列表数据
      array: {
        ids:[],
      }, //批量下载的数组
      deleteId:{
        id:'',
      },
      //官文搜索表单字段
      filters: {
        matter: "", //官文名称
        tmname: "", //商标名称
        intcls: "", //商标类别
        tmcode: "", //商标号
        appname: "", //申请人
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
      },
      categorySelect:storage.getIntcls(),//商标类别下拉
      typeFromSelect:[],//官文类型下拉
      typeSelect: [
          {id: '01', text: '商标注册证'},
        {id: '02', text: '商标注册申请受理通知书'},
        {id: '03', text: '商标驳回通知书'},
        {id: '04', text: '商标部分驳回通知书'},
        {id: '05', text: '初步审定公告通知书'},
      ],//官文类型的下拉数据
      applicantSelect: [],//申请人的下拉数据
      backTop:true,
      pageSize: 0,//总条数
    };
  },
  created() {
    this.managementLists();
    this.select()
  },
  methods: {
    //获取下拉数据事件
    async select () {
      let s = await storage.getSelects( 'tm_doc_apps','tm_doc_types')
      this.applicantSelect = s.tm_doc_apps
      this.typeFromSelect = s.tm_doc_types
    },
    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.managementLists(this.backTop);
    },
    //渲染列表事件
    async managementLists(backTop) {
      this.loading = true;
      this.managementList = storage.getList();
      try{
        const res = await management(this.filters)
        if(res && res.err_code === 0){
          if(backTop === true){
            storage.backTop()
          }
          this.loading = false;
          this.managementList = res.data.list;
          this.pageSize = res.data.total;
        }else {
          this.managementList = []
        }
      }finally{
        this.loading = false;
      }
    },
    //重置事件
    reset() {
      this.filters = this.$options.data().filters; //清空表单
      this.managementLists();
    },
    openUrl(item) {
      let name = item.tmcode + '-' + item.intcls + '-' + (item.tmname ? item.tmname : '图形') + '-' + item.matter +  '.' + item.name.split('.')[1]
      location.href = item.url.replace(item.name,name)
    },
    //批量下载点击事件
    async download() {
      if(this.array.ids.length == 0){
        this.$confirm("请选中要下载的官文序号", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
      }else{
        this.down = false
        try{
          const res = await managementDown(this.array)
          if(res && res.err_code === 0){
            window.location.href = res.data.url
            this.down = true
          }

        }finally{
          this.down = true
        }
      }

    },
    //记录选择要下载的id事件
    handleSelectionChange(val) {
      this.array.ids = [];
      val.forEach((item) => {
        this.array.ids.push(item.id);
      });
    },
    //官文删除事件
    deleteFile(item){
      this.deleteId.id = item
      try{

        this.$confirm('确定要删除该官文吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          await managementDelete(this.deleteId).then(res => {
            if (res && res.err_code === 0) {
              this.$message({
                type: "success",
                message: "官文已删除！",
              });
              this.managementLists(this.filters.page = 1)
            } else {
              this.$message({
                type: "error",
                message: res.err_msg,
              });
            }
          })
        })

      } finally{

      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/management.less";

</style>
