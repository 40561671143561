<template>
  <div class="ba-col-fff">
    <h2>审批人设置</h2>
    <div class="mainrb">
      <div class="listT p10">
        <el-button icon="el-icon-circle-plus-outline" size="small" type="primary" @click="edit()">
          创建审批人
        </el-button>

      </div>
      <el-skeleton :count="1" :loading="loading" animated>
        <template slot="template">
          <el-table :class="{ ios: $store.state.heights }" :data="approvalList" style="width: 100%">

            <el-table-column label="序号">
              <template>
                <el-skeleton-item variant="p" />
              </template>
            </el-table-column>
            <el-table-column label="姓名">
              <template>
                <el-skeleton-item variant="p" class="w50" />
              </template>
            </el-table-column>
            <el-table-column label="手机号" width="180">
              <template>
                <el-skeleton-item variant="p" class="w50" />
              </template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template>
                <el-skeleton-item variant="p" class="w50" />
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <template>
                  <el-skeleton-item variant="p" class="w50" />
                </template>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-table :class="{ ios: $store.state.heights }" :data="approvalList" height="100%" style="width: 100%">
          <template slot="empty">
            <el-empty :image-size="80"></el-empty>
          </template>

          <el-table-column label="序号" type="index">
          </el-table-column>
          <el-table-column label="姓名" min-width="120" prop="name" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="手机号" min-width="180" prop="phone" :show-overflow-tooltip="true">

          </el-table-column>

          <el-table-column label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.created_at ? scope.row.created_at.slice(0, 10) : '' }}
            </template>
          </el-table-column>

          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button icon="el-icon-edit-outline" size="small" type="text" @click="edit(scope.row)">编辑</el-button>
              
              <el-button v-if="approvers.filter(item => item.id == scope.row.id).length == 0" @click="deleteRow(scope.row)" icon="el-icon-delete" class="delete" size="small"
                type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-skeleton>
    </div>
    <el-dialog :closeOnClickModal="false" :modal-append-to-body="false" :visible.sync="approvalDia" class="approvalDia"
      title="设置审批人" width="390px" @close="openDia('ruleForm')">
      <el-form label-width="70px" :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" clearable placeholder="请输入姓名" size="small"></el-input>
        </el-form-item>
        <el-form-item label="手机号" maxlength="11" prop="phone">
          <el-input v-model="form.phone" clearable placeholder="请输入手机号" size="small"></el-input>
        </el-form-item>
        <el-form-item class="tr m0">
          <el-button icon="el-icon-close" size="small" @click="approvalDia = false">取 消</el-button>
          <el-button icon="el-icon-folder-checked" class="confirm" size="small" type="primary" :loading="saveLoad"
            @click="save('ruleForm')">确
            定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { flowsApproversSave, flowsApproversDelete, flowsApprovers,flowsApplyForm } from '@/api/personal.js'
import storage from '@/utils/storage'
export default {
  props: ['user'],
  data() {
    var phone = (rule, value, callback) => {
      if (value === '' || !/^1[2-9]\d{9}$/.test(value)) {
        callback(new Error(' '));
      } else {
        callback();
      }
    };
    return {
      saveLoad: false,
      approvers:[],
      approver_id: '',
      form: {
        name: '',
        phone: '',
        created_at: '',
        id: '',

      },
      rules: {
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        phone: [
          { validator: phone, trigger: 'blur' }
        ],
      },
      approvalDia: false,
      approvalList: [],
      loading: false,

    }
  },
  created() {
    this.list()
    this.detail()
  },
  methods: {
    // 表单
    detail() {
      flowsApplyForm().then(res => {
        if (res.err_code === 0) {
          if (res.data.approvers.length != 0) {
            this.approvers = res.data.approvers
          }

        }
      })
    },
    //导出事件
    openDia(formName) {
      this.$refs[formName].resetFields();
      this.form = this.$options.data().form;
    },
    // 保存设置
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoad = true
          try {

            flowsApproversSave(this.form).then(res => {
              if (res.err_code === 0) {
                this.$message({
                  message: '保存成功',
                  type: 'success'
                });
                this.list()
                this.approvalDia = false
                this.saveLoad = false
              }
            })
          } catch {
            this.saveLoad = false
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    deleteRow(row) {
      this.$confirm('确定要删除此审批人吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        flowsApproversDelete({ id: row.id }).then(res => {
          if (res.err_code === 0) {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.list()
          }
        })
      }).catch(() => {

      });

    },
    // 编辑
    edit(row) {
      if (row && row.id) {
        this.approver_id = row.id
        this.form = { ...this.form, ...row }
      } else {
        this.approver_id = ''
        this.form.created_at = storage.convertTime(new Date())
      }
      this.approvalDia = true
    },
    list() {
      this.loading = true
      try {
        flowsApprovers().then(res => {
          if (res.err_code === 0) {
            this.approvalList = res.data
            this.loading = false
          } else {
            this.loading = false
          }
        })

      } catch {
        this.loading = false
      }
    },
    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.list();
    },

  }
}
</script>
<style lang="less" scoped>
.mainrb{
    height: calc(100vh - 170px);
}
.main .mainr form .el-button {
  position: unset;
}

.delete {
  color: #f56c6c;
}
</style>

