
<template>
  <div class="">
    <div class="trademark-ban">
      <div class="container">
        <h1 :class="{ on: transition }">专业服务 极速办理</h1>
        <h3 :class="{ on: transition }">
          智能申请注册满足不同用户需求<br />
          更专业、更快速、更智能
        </h3>
        <router-link to="/" :class="{ on: transition }">立即注册</router-link>
      </div> 
    </div>
    <div class="trademark-benefit container">
      <h2 class="trademark-title">注册商标的好处</h2>
      <ul>
        <li>
            <h3>抢占市场</h3>
            <p>扩大产品影响力，提升市场占有率， 便于消费者认准产品品牌</p>

        </li>
        <li>
          <h3>品牌效应</h3>
          <p>提高品牌市场估值，企业 重要无形资产</p>
        </li>
        <li>
          <h3>市场通行证</h3>
          <p>入驻天猫、京东等正规电商平台 运营的必要条件</p>
        </li>
        <li>
          <h3>阻止侵权</h3>
          <p>
            明确产权，防止品牌盗用， 保护自己的权利不受侵犯， 防止市场投入打水漂
          </p>
        </li>
      </ul>
    </div>

    <div class="trademark-process">
      <div class="container">
        <h2 class="trademark-title">注册商标的好处</h2>
        <div class="process-cont clearfix">
          <div class="process-contL fl">
            <div class="process-contLt">
              <ul>
                <li>自助申请注册</li>
                <li>
                  <img
                    src="@/assets/img/trademark/icon-process1.png"
                    alt=""
                    class="process1"
                  />
                </li>
                <li>商标查询</li>
                <li>
                  <img
                    src="@/assets/img/trademark/icon-process2.png"
                    alt=""
                    class="process2"
                  />
                </li>
                <li>材料审核</li>
              </ul>
            </div>

            <div class="process-contLb">
              <ul>
                <li>
                  <h4>
                    <img
                      src="@/assets/img/trademark/icon-process3.png"
                      alt=""
                    />
                  </h4>
                  <p>专属顾问</p>
                </li>
                <li>
                  <img
                    src="@/assets/img/trademark/icon-process1.png"
                    alt=""
                    class="process1"
                  />
                </li>
                <li>
                  <h4>
                    <img
                      src="@/assets/img/trademark/icon-process4.png"
                      alt=""
                    />
                  </h4>
                  <p>材料准备</p>
                </li>
                <li>
                  <img
                    src="@/assets/img/trademark/icon-process2.png"
                    alt=""
                    class="process2"
                  />
                </li>
                <li>
                  <h4>
                    <img
                      src="@/assets/img/trademark/icon-process5.png"
                      alt=""
                    />
                  </h4>
                  <p>提交需求</p>
                </li>
              </ul>
            </div>

            <div class="process-line"></div>
          </div>

          <div class="process-contR fl">
            <ul>
              <li>
                <img
                  src="@/assets/img/trademark/icon-process2.png"
                  alt=""
                  class="process2"
                />
              </li>
              <li>
                <h4>
                  <img src="@/assets/img/trademark/icon-process3.png" alt="" />
                </h4>
                <p>专属顾问</p>
              </li>
              <li>
                <img
                  src="@/assets/img/trademark/icon-process2.png"
                  alt=""
                  class="process2"
                />
              </li>
              <li>形式审查</li>
              <li>
                <img
                  src="@/assets/img/trademark/icon-process2.png"
                  alt=""
                  class="process2"
                />
              </li>
              <li>实质审查</li>
              <li>
                <img
                  src="@/assets/img/trademark/icon-process2.png"
                  alt=""
                  class="process2"
                />
              </li>
              <li>初审公告</li>
              <li>
                <img
                  src="@/assets/img/trademark/icon-process2.png"
                  alt=""
                  class="process2"
                />
              </li>
              <li>注册成功<br />领取证书</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="trademark-advantage container">
      <h2 class="trademark-title">服务优势</h2>
      <ul>
        <li>
          <h4>
            <img src="@/assets/img/trademark/icon-advantage1.png" alt="" />
          </h4>
          <p>专业代理人均从业2年以上，熟练 掌握商标注册流程，代理经验丰富</p>
        </li>

        <li>
          <h4>
            <img src="@/assets/img/trademark/icon-advantage2.png" alt="" />
          </h4>
          <p>
            商标申请材料备齐，30分钟极速递交 商标局（工作日下午3点前确认完毕）
          </p>
        </li>

        <li>
          <h4>
            <img src="@/assets/img/trademark/icon-advantage3.png" alt="" />
          </h4>
          <p>
            便捷商标注册通道，三步搞定！有更高的
            商标注册成功率和精准的商标注册结果， 商标服务质量有保证
          </p>
        </li>
      </ul>
    </div>

    <div class="trademark-group">
      <div class="container">
        <img src="@/assets/img/trademark/groupPhoto.png" alt="" class="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      transition: false,
      total: 1,
    };
  },
  created() {
    let clock = window.setInterval(() => {
      this.total--;
      this.transition = true;
      if ((this.total = 0)) {
        window.clearInterval(clock);
      }
    }, 50);
  },
};
</script>

<style lang="less" scoped>
@import "../../style/trademark.less";
</style>