<template>
  <div class="">
    <h2>商标注册申请</h2>
    <div class="mainrb">
      <h3>
        商标信息
      </h3>
      <el-form ref="ruleForm" :model="filters" :rules="rules" class="demo-ruleForm" label-width="100px">
        <el-row>
          <el-col :sm="10">
            <el-form-item label="商标号">
              <el-row>
                <el-col :sm="18">
                  <el-input clearable disabled placeholder="请输入商标号" size="small"></el-input>
                </el-col>
              </el-row>

            </el-form-item>
          </el-col>
          <el-col :sm="10">
            <el-form-item label="申请人名称">
              <el-row align="middle" justify="space-between" type="flex">
                <el-col :sm="18">
                  <el-select
                      v-model="filters"
                      clearable
                      filterable
                      placeholder="请选择申请人名称"
                      size="small"
                  >
                    <el-option
                        v-for="item in intclsSelect"
                        :key="item.id"
                        :label="item.text"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :sm="3">
                  <el-button size="small" type="primary">新建</el-button>
                </el-col>
              </el-row>

            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :sm="10">
            <el-form-item label="商标名称">
              <el-row>
                <el-col :sm="18">
                  <el-input clearable size="small"></el-input>
                </el-col>
              </el-row>

            </el-form-item>
          </el-col>
          <el-col :sm="10">
            <el-form-item label="申请人地址">
              <el-row>
                <el-col :sm="18">
                  <el-input clearable size="small"></el-input>
                </el-col>
              </el-row>

            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :sm="10">
            <el-form-item label="商标类别">
              <el-row>
                <el-col :sm="18">
                  <el-input clearable disabled size="small"></el-input>
                </el-col>
              </el-row>

            </el-form-item>
          </el-col>
          <el-col :sm="10">
            <el-form-item label="联系地址">
              <el-row>
                <el-col :sm="18">
                  <el-input clearable size="small"></el-input>
                </el-col>
              </el-row>

            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="19">
            <el-form-item label="商标说明">
              <el-input :rows="5" clearable placeholder="请输入" size="small" type="textarea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="19">
            <el-form-item label="商标图样">
             <el-row>
               <el-col :span="5">
                 <el-upload
                     class="avatar-uploader"
                     action="https://jsonplaceholder.typicode.com/posts/"
                     :show-file-list="false"
                     :on-success="handleAvatarSuccess">
                   <img v-if="imageUrl" :src="imageUrl" class="avatar" :style="{width:(170 + 170*(value/100)) + 'px',height:(170 + 170*(value/100)) + 'px'}">
                   <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                 </el-upload>
               </el-col>
               <el-col :span="3">
                 <el-slider
                     v-model="value"
                     vertical
                     height="200px">
                 </el-slider>
               </el-col>
             </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :sm="19">
            <el-form-item label="商品/服务项目">
              <el-input v-model="filters.shop" :rows="5" clearable disabled placeholder="请输入" size="small"
                        type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="3">
            <el-button size="small" type="primary" @click="chose = !chose">{{ chose ? '关闭' : '编辑' }}</el-button>
          </el-col>
        </el-row>

        <el-row v-if="chose" :gutter="20" class="choose">
          <el-col :span="12">
            <div class="search">
              <el-row :gutter="15">
                <el-col :span="24">
                  <el-input v-model="filters.shop" clearable
                            placeholder="请输入 " rows="4"
                            size="small"
                            type="textarea"></el-input>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col :span="20">
                  <el-row>
                    <el-col :span="5"><strong>国际分类</strong></el-col>
                    <el-col :span="19">
                      <el-input placeholder="请输入" size="small"></el-input>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5"><strong>商品编码</strong></el-col>
                    <el-col :span="19">
                      <el-input placeholder="请输入" size="small"></el-input>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5"><strong>类似群</strong></el-col>
                    <el-col :span="19">
                      <el-input placeholder="请输入" size="small"></el-input>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="4">
                  <el-button size="small" type="primary">查询</el-button>
                  <el-button size="small">重置</el-button>
                </el-col>
              </el-row>
            </div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                @selection-change="handleSelectionChange"
            >
              <el-table-column
                  type="selection"
                  width="30">
              </el-table-column>
              <el-table-column
                  label="序号"
                  type="index"
                  width="50">
              </el-table-column>
              <el-table-column
                  label="日期" width="100">
                <template slot-scope="scope">{{ scope.row.date }}</template>
              </el-table-column>
              <el-table-column
                  label="国际分类" prop="name" width="80">
              </el-table-column>
              <el-table-column
                  label="类似群" prop="name" width="80">
              </el-table-column>
              <el-table-column
                  label="商品编码" prop="name" width="80">
              </el-table-column>
              <el-table-column
                  label="商品/服务项目" prop="name">
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                :current-page.sync="filters.page.page"
                :page-size="filters.page.per_page"
                :page-sizes="[30, 50, 100]"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="changeRowNum"
                @current-change="list()"
            >
            </el-pagination>
          </el-col>
          <el-col :span="12">
            <div class="search">
              <el-row>
                <el-col><strong>国际分类</strong></el-col>
                <el-col>
                  <el-input clearable placeholder="请输入" size="small"></el-input>
                </el-col>
                <el-col>
                  <el-button size="small" type="primary">添加</el-button>
                </el-col>
              </el-row>
              <el-row>
                <el-col><strong>商品/服务项目</strong></el-col>
                <el-col>
                  <el-input clearable placeholder="请输入" size="small"></el-input>
                </el-col>
                <el-col>
                  <el-button size="small">取消</el-button>
                </el-col>
              </el-row>
              <el-button size="small" type="danger" @click="deleteChoose">删除选中项</el-button>
            </div>
            <el-table
                :data="selectedList"
                tooltip-effect="dark"
                @selection-change="selecteChange">
              <el-table-column
                  type="selection"
                  width="30">
              </el-table-column>
              <el-table-column
                  label="序号"
                  type="index"
                  width="50">
              </el-table-column>
              <el-table-column
                  label="日期" width="100">
                <template slot-scope="scope">{{ scope.row.date }}</template>
              </el-table-column>
              <el-table-column
                  label="国际分类" prop="name" width="80">
              </el-table-column>
              <el-table-column
                  label="类似群" prop="name" width="80">
              </el-table-column>
              <el-table-column
                  label="商品编码" prop="name" width="80">
              </el-table-column>
              <el-table-column
                  label="商品/服务项目" prop="name">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import storage from '@/utils/storage'

export default {
  data() {
    return {
      imageUrl: '',
      value:0,
      chose: false,
      filters: {
        shop: '咖啡；茶；冰茶；茶饮料；用作茶叶代用品的花或叶；甘菊茶饮料；糖；甜食；蜂蜜；谷类制品；食用冰；鲜酵母；食盐；芥末；醋；辛香料；冰',
        page: {
          page: 1, //默认显示第一页
          per_page: 30, //显示数量
        }
      },
      rules: {},
      tableData: [
          {
        date: '2016-05-03',
        name: '王虎',
        address: '上海市普陀区金沙江路 1518 1'
      },
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-01',
          name: '小虎',
          address: '上海市普陀区金沙江路 1511'
        },
        {
          date: '2016-05-08',
          name: '王小',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-0',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }],
      selectedList: [],
      intclsSelect: [],
      multipleSelection: [],
      deletes: [],
      ids:[],
    }
  },
  created() {
    this.select();
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },

    async select() {
      let s = await storage.getSelects('app_names')
      this.intclsSelect = s.app_names
    },
    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.page.per_page = val;
      this.list()
    },
    list() {},

    handleSelectionChange(val) {
      this.selectedList = val
      this.ids = []
      this.selectedList.forEach((item) =>{
        this.tableData.forEach((item1,i) =>{
          if(item1 == item){
            this.ids.push(i)
          }
        })
      })
    },
    selecteChange(val) {
      this.deletes = val
    },
    deleteChoose() {
      this.deletes.forEach((item) => {
        this.selectedList.forEach((item1, i) => {
          if (item1 == item) {
            this.selectedList.splice(i, 1);
            this.$refs.multipleTable.toggleRowSelection(this.tableData[this.ids[i]]);
          }
        })
      })

    },
  },
}
</script>

<style lang="less" scoped>
@import "../../style/handle.less";
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>