<template>
  
  <el-dialog :closeOnClickModal="false" :modal-append-to-body="false" :visible.sync="approvalDia" title="审批申请"
    width="500px" @close="closeDia('ruleForm')">
    <el-form label-width="80px" :model="filters" :rules="rules" ref="ruleForm">
      <el-form-item label="表单名称" prop="name">
        <el-select v-if="detailId == 'add' || (filters.flows.filter(i => i.result == 1 || i.result === 0).length == 0 && filters.state === 0) " 
          v-model="filters.form_id" clearable filterable placeholder="请选择表单名称" size="small" @change="nameChange">
          <el-option v-for="item in apply_forms" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-input v-else v-model="filters.name" type="text" readonly placeholder="请输入表单名称"> </el-input>
        
      </el-form-item>
      <el-form-item label="审批内容" prop="content">
        <el-input v-model="filters.content" type="textarea" :rows="2" placeholder="请输入审批内容"> </el-input>
      </el-form-item>
      <el-form-item label="附件">

        <el-upload v-if="uploadSH()" ref="upload" :file-list="filters.attaches" :before-upload="beforeUpload" size="small"
          class="upload-attach" :http-request="uploadImg" action="">

          <el-button  size="small" plain icon="el-icon-upload2" type="primary">上传文件</el-button>
          <span  slot="tip" class="el-upload__tip col-red">
            文件大小不超过10M
          </span>
          <div class="upload-file" slot="file" slot-scope="{file}" v-if="filters.attaches.length != 0">
            <div class="name">
              <a  :href="file.link ? `${file.link}&preview=true` : `${file.url}&preview=true`" target="_blank" class="word-break col-blue">{{ file.name }}</a>
              <i class="el-icon-download font-20" @click="registerDownload(file)"></i>
            </div>
          </div>
        </el-upload>
        <div class="upload-sh" v-if="!uploadSH()" >
          <div class="upload-file"  v-if="filters.attaches.length != 0">
            <div class="name" v-for="file in filters.attaches">
              <a :href="file.link ? `${file.link}&preview=true` : `${file.url}&preview=true`" target="_blank" class="word-break col-blue">{{ file.name }}</a>
              <i class="el-icon-download font-20" @click="registerDownload(file)"></i>
            </div>
        </div>
        <div v-if="filters.attaches.length == 0">-</div>
      </div>
      </el-form-item>
      <el-form-item label="流程">
        <p class="flex flex-cen" v-if="filters.creator">
          {{ filters.creator.nickname }}（发起）
          <span>{{ filters.created_at ? filters.created_at.slice(0,10) : '' }}</span>
        </p>
        <p v-for="(item,i) in filters.flows" :key="i" class="flex flex-cen" >
          <span>{{ item.name }}
          <span v-if="(num >= i || num === '') && filters.state != -1">
            {{
            (detailId == 'add' || replace.id != filters.form_id) ? '' :
            (!item.approved_at ? (user.mobile == item.phone ? '（审批中）' : (pend_num.length != 0 && pend_num[0]  >= i ? '（待审批）' : '')) : (item.result === 1 ? '（已同意）' : '（已拒绝）'))
            }}
          </span>
            
          </span>
          <span>{{ item.approved_at ? item.approved_at.slice(0,10) : '' }}</span>
        </p>
      </el-form-item>
      <el-form-item>
        <el-button size="small"
          v-if="!filters.state && filters.flows.filter(item => item.result || item.result === 0).length == 0"
          icon="el-icon-s-promotion" type="primary" :loading="saveLoad" @click="save('ruleForm')">提交申请</el-button>
        <el-button size="small"
          v-if="filters.id && ( filters.state === 0) "
          icon="el-icon-refresh-right" type="info" :loading="saveLoad" @click="revoke()">撤销申请</el-button>

      </el-form-item>
    </el-form>

  </el-dialog>
</template>

<script>
import { fileUpload, flowsSave, flowsForm, flowsApplyForms, flowsDelete } from '@/api/personal.js'
import storage from '@/utils/storage'
export default {
  props: ['user'],
  data() {
    return {
      num:'',
      pend_num:[],
      detailId: '',
      approvalDia: false,
      saveLoad: false,
      fileList: [],
      filters: {
        name: '',
        content: '',
        attaches: [],
        flows: [],
        form_id:'',
      },
      replace: {
        state: '',
        id:'',
      },
      rules: {
        name: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        content: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
      },
      apply_forms:[],
    }
  },
  created() {
  },
  methods: {
    // 表单名称修改
    nameChange(){
      this.apply_forms.forEach(item =>{
        if(item.id == this.filters.form_id ){
          this.filters.name =  this.user.nickname + '提交的' + item.name + '审批'
          this.filters.flows = item.approvers
        }
      })
      
    },
    uploadSH(){
      if(this.filters.flows.filter(item => item.result || item.result === 0).length != 0 || this.filters.state == -1){
        return false
      }
      // if(this.filters.flows.filter(item => item.result === 0).length != 0){
      //   return false
      // }

      return true
    },
    closeDia(formName) {
      this.$refs[formName].resetFields();
      this.filters = this.$options.data().filters;
      this.replace = this.$options.data().replace;
    },
    getFlows(){
      flowsApplyForms().then(res => {
          if (res.err_code === 0) {
            this.apply_forms = res.data
            this.approvalDia = true
          }
        })
    },
    detail(id) {
      this.detailId = id
      this.num = ''
      this.pend_num = []
      if (this.detailId == 'add') {
        this.getFlows()
      } else {
        flowsForm({ id: this.detailId }).then(res => {
          if (res.err_code === 0) {
            this.filters = { ...this.filters, ...res.data }
            this.filters.attaches = (res.data.attaches || [])
            this.filters.flows.forEach((item,i) =>{
              if(item.result === 0){
                this.num = i
              }
              if(!item.approved_at){
                this.pend_num.push(i)
              }

            })
            if(this.filters.flows.filter(i => i.result == 1 || i.result === 0).length == 0 && this.filters.state === 0){
              this.replace.id = this.filters.form_id
              this.getFlows()
            }
            this.approvalDia = true
          }
        })
      }

    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 <= 10;
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过10MB!');
      } else {
        this.$message.success('上传成功');
        this.filters.attaches = []
      }

      return isLt10M;
    },
    registerDownload(file) {
      if (file.link) {
        location.href = file.link + '&preview=true'
      } else {
        location.href = file.url + '&preview=true'
      }

    },
    //文件上传后事件(自动上传)
    async uploadImg(item) {
      let formData = new FormData();
      formData.append("file", item.file);
      formData.append("is_base64", 0);
      try {
        const res = await fileUpload(formData)
        if (res && res.err_code === 0) {
          this.filters.attaches = [{ name: res.data.name, path: res.data.path, url: res.data.url }]
          // this.$refs.upload.clearFiles()
          console.log(res.data)
        } else {
          this.$message({
            type: "warning",
            message: res.err_msg,
          })
        }
      } finally {
        this.uploadBtn = true;
      }
    },
    save(formName) {
      console.log(this.filters)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          try {
            this.saveLoad = true
            flowsSave(this.filters).then(res => {
              this.$message({
                message: '保存成功',
                type: 'success'
              });

              this.saveLoad = false
              this.approvalDia = false
              this.$emit('list')
            })
          } catch {
            this.saveLoad = false
          }
        } else {
          return false
        }
      })


    },
    // 撤销
    revoke() {
      this.$confirm('确定要撤销此审批?', '撤销申请', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        try {
          this.saveLoad = true
          flowsDelete({ id: this.filters.id, action: 1 }).then(res => {
            this.$message({
              message: '撤销成功',
              type: 'success'
            });
            this.saveLoad = false
            this.approvalDia = false
            this.$emit('list')
          })
        } catch {
          this.saveLoad = false
        }
      }).catch(() => {

      });

    },
  }
}
</script>
<style lang="less" scoped>
.main .mainr form .el-button {
  position: unset;
}
 .upload-sh{
  .upload-file{
    border: 1px solid #c0ccda;
      border-radius: 6px;
      padding: 10px;
      ::v-deep .name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap:5px;
          a{
            line-height: 1.8;
          }
        }

        .el-icon-download {
          cursor: pointer !important;
          color: #CECECE !important;
          font-size: 20px !important;
        }
  }
 }
::v-deep .upload-attach {
  .el-upload-list {
    height: unset;
    margin-top: 10px;

    li {
      border: 1px solid #c0ccda;
      border-radius: 6px;
      padding: 10px;
      .upload-file {
        img {
          max-width: 60px;
          max-height: 60px
        }

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap:5px;

        }

        .el-icon-download {
          cursor: pointer !important;
          color: #CECECE !important;
          font-size: 20px !important;
        }
      }
    }

    li:hover {
      background-color: #fff;
    }



  }
}
</style>