import { render, staticRenderFns } from "./worksArchives.vue?vue&type=template&id=b249a09c&scoped=true&"
import script from "./worksArchives.vue?vue&type=script&lang=js&"
export * from "./worksArchives.vue?vue&type=script&lang=js&"
import style0 from "./worksArchives.vue?vue&type=style&index=0&id=b249a09c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b249a09c",
  null
  
)

export default component.exports