<template>
  <div class="container">
    <div class="searchDetailsT clearfix">
      <img
        src="@/assets/img/search/details.png"
        alt=""
        class="searchDetailsT1 fl"
      />
      <div class="searchDetailsT2 fl">
        <h2>学校体育</h2>
        <ul>
          <li>当前状态： 已无效</li>
          <li>商品类别：16类 办公用品</li>
          <li>商标号：31822502</li>
        </ul>
      </div>
      <div class="searchDetailsT3 fr">
        <img src="@/assets/img/search/icon-searchDetails1.png" alt="" />
        <img src="@/assets/img/search/icon-searchDetails2.png" alt="" />
      </div>
    </div>

    <table border="1" width="100%">
      <tr>
        <td>商标名称</td>
        <td colspan="3">学校体育</td>
      </tr>
      <tr>
        <td>法律状态</td>
        <td><font>已无效</font></td>
        <td>商标号</td>
        <td>31822502</td>
      </tr>
      <tr>
        <td>商品类别</td>
        <td>16类 办公用品</td>
        <td>申请日期</td>
        <td>2018-06-26</td>
      </tr>
      <tr>
        <td>申请人名称</td>
        <td colspan="3">北京天地易通教育科技有限公司</td>
      </tr>
      <tr>
        <td>申请人地址</td>
        <td colspan="3">北京市西城区黄寺大街23号2号楼8-2002室</td>
      </tr>
      <tr>
        <td>申请人名称（英文）</td>
        <td colspan="3">-2002室</td>
      </tr>
      <tr>
        <td>申请人地址（英文）</td>
        <td colspan="3">-2002室</td>
      </tr>
      <tr>
        <td>商标图片</td>
        <td>
          <img
            src="@/assets/img/search/details.png"
            alt=""
            @click="imgOn($event)"
          />
          <strong>点击查看大图</strong>
        </td>
        <td>图片要素</td>
        <td>无</td>
      </tr>
      <tr>
        <td>商品/服务项目</td>
        <td colspan="3">
          1606 印刷出版物 1606 报纸 1606 新闻刊物 1606 期刊 1606 杂志(期刊) 1606
          海报 1607 宣传画 1607 照片(印制品) 1607 照片(印制的)
        </td>
      </tr>
      <tr>
        <td>初审公告期号</td>
        <td></td>
        <td>初审公告日期</td>
        <td></td>
      </tr>
      <tr>
        <td>注册公告期号</td>
        <td></td>
        <td>注册公告日期</td>
        <td></td>
      </tr>

      <tr>
        <td>专用权期限</td>
        <td></td>
        <td>是否共有商标</td>
        <td>否</td>
      </tr>

      <tr>
        <td>后期指定日期</td>
        <td></td>
        <td>国际注册日期</td>
        <td></td>
      </tr>
      <tr>
        <td>优先权日期</td>
        <td></td>
        <td>代理人名称</td>
        <td>柜台办理</td>
      </tr>

      <tr>
        <td>商标状态</td>
        <td colspan="3">
          <p>
            <span>2019-03-03 商标注册申请 </span> |<span> 等待驳回复审</span>
          </p>
          <p>
            <span>2019-01-03 商标注册申请 </span> |<span> 驳回通知发文</span>
          </p>
          <p>
            <span>2018-08-02 商标注册申请 </span> |<span> 受理通知书发文</span>
          </p>
          <p><span>2018-06-26 商标注册申请</span> |<span> 申请收文</span></p>
        </td>
      </tr>
      <tr>
        <td>商标公告</td>
        <td colspan="3"></td>
      </tr>

      <tr>
        <td>评审文书</td> 
        <td colspan="3"></td>
      </tr>
    </table>
    <div class="annotation">*商标查询信息仅供参考，实际以国家商标局为准。</div>

    <div class="big-img" @click="imgOff" :class="{ disnone: bigImg }">
      <img src="@/assets/img/search/details.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id:this.$route.query.id,
      imgsrc: "",
      bigImg: true,
    };
  },
created() {
    
    console.log(this.id);
    
},
  methods: {
    imgOn(e) {
      this.bigImg = false;
      this.imgsrc = e.currentTarget.src;
    },
    imgOff() {
      this.bigImg = true;
    },
  },
};
</script>


<style lang="less" scoped>
@import "../../style/searchDetails.less";
</style>