<template>
  <div class="">
    <h2>账号设置</h2>
    <div class="mainrb">
      <el-tabs v-model="activeName">
        <el-tab-pane class="first" label="账户信息" name="first">
          <el-skeleton :count="1" :loading="loading" animated>
            <template slot="template">
              <el-row align="middle" type="flex">
                <el-col :lg="5" :md="5" :sm="7" :xl="4" :xs="8">
                  <div :class="{on:imageUrlSH}" class="mainb-l">
                    <el-upload
                        :http-request="uploadImg"
                        :show-file-list="false"
                        action=""
                        class="avatar-uploader">
                      <el-skeleton-item
                          style="width: 70px; height:70px;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                          variant="image"
                      />
                      <span v-if="!modify"><i class="el-icon-edit avatar-uploader-icon"></i></span>
                    </el-upload>
                  </div>
                </el-col>
                <el-col :lg="19" :md="19" :sm="17" :xl="20" :xs="16">
                  <el-form ref="filters" :model="filters" :rules="rules">
                    <el-form-item label="企业：" prop="company" v-if="user.type !== 0">
                      <el-skeleton-item style="width: 50%;" variant="h3"/>

                    </el-form-item>
                    <el-form-item label="账户类型：">
                      <el-skeleton-item style="width: 50%;" variant="h3"/>
                    </el-form-item>
                    <el-form-item v-if="modify" label="到期时间：">
                      <el-skeleton-item style="width: 50%;" variant="h3"/>
                    </el-form-item>
                    <el-form-item class="mobile" label="账号：">
                      <el-skeleton-item style="width: 50%;" variant="h3"/>
                    </el-form-item>
                    <el-form-item class="user" label="姓名：" prop="nickname">
                      <el-skeleton-item style="width: 50%;" variant="h3"/>
                    </el-form-item>
                    <el-form-item class="user" label="邮箱：" prop="email">
                      <el-skeleton-item style="width: 50%;" variant="h3"/>
                    </el-form-item>
                    <el-form-item class="button">
                      <el-skeleton-item style="width: 50px;" variant="h3"/>
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
            </template>
            <el-row align="middle" type="flex">
              <el-col :lg="5" :md="5" :sm="8" :xl="4" :xs="8">

                <div :class="{on:imageUrlSH}" class="mainb-l">
                  <div class="l-con">
                    <img v-if="!details.avatar" alt="" src="@/assets/img/personal/portrait.png">
                    <el-image
                        v-else
                        :src="details.avatar"
                        :preview-src-list="[details.avatar]">
                    </el-image>
                  </div>
                  <el-upload
                      :http-request="uploadImg"
                      :show-file-list="false"
                      action=""
                      class="avatar-uploader">
                    <span v-if="!modify"><i class="el-icon-edit avatar-uploader-icon"></i></span>
                  </el-upload>
                </div>
              </el-col>
              <el-col :lg="19" :md="19" :sm="16" :xl="20" :xs="16">
                <el-form ref="filters" :model="filters" :rules="rules">
                  <el-form-item label="企业：" prop="company" >
                    <p v-if="modify">{{ details.company }}</p>
                    <el-select
                        v-else
                        v-model="filters.company"
                        clearable
                        filterable
                        placeholder="请选择企业"
                        size="small"
                    >
                      <el-option
                          v-for="item in companySelect"
                          :key="item.text"
                          :label="item.text"
                          :value="item.text"
                      >
                      </el-option>
                    </el-select>

                  </el-form-item>
                  <el-form-item label="账户类型：">

                    <font
                        :class="{'col-000':details.type == 0,blue:details.type == 1,golden:details.type == 2,purple:details.type == 3}">{{
                        details.type == 0 ? '免费版' : (details.type == 1 ? '基础版' : (details.type == 2 ? '专业版' : '国际版'))
                      }}</font>
                  </el-form-item>
                  <el-form-item v-if="modify " label="到期时间：">
                    <span :class="{grey:user.type === 0}">{{ details.expired_at || '-' }}</span>
                  </el-form-item>
                  <el-form-item class="mobile" label="账号：">
                    <font>{{ details.mobile || '-'}}</font>
                  </el-form-item>
                  <el-form-item class="user" label="姓名：" prop="nickname">
                    <p v-if="modify">{{ details.nickname || '-'}}</p>
                    <el-input v-else v-model="filters.nickname" clearable placeholder="请输入" size="small"></el-input>
                  </el-form-item>
                  <el-form-item class="user" label="邮箱：" prop="email">
                    <p v-if="modify">{{ details.email }}</p>
                    <el-input v-else v-model="filters.email" clearable placeholder="请输入" size="small"></el-input>
                  </el-form-item>
                  <el-form-item v-if="modify" class="button">
                    <el-button icon="el-icon-edit" size="small" type="primary" @click="modify = false">修改
                    </el-button>
                  </el-form-item>
                  <el-form-item v-else class="button">
                    <el-button icon="el-icon-close" size="small" @click="modify = true">取消
                    </el-button>
                    <el-button :disabled="submitDis" icon="el-icon-folder-checked" size="small" type="primary"
                               @click=" detailSave('filters')">保存
                    </el-button>

                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
          </el-skeleton>
        </el-tab-pane>
        <el-tab-pane v-if="subSH" class="second" label="子账户管理" name="second">
          <el-button icon="el-icon-circle-plus-outline" size="small" type="primary" @click="establish">创建账号</el-button>
          <el-table :data="tableData" height="100%"  :class="{ios:$store.state.heights}" >
            <template slot="empty">
              <el-empty :image-size="80"></el-empty>
            </template>
            <el-table-column label="序号" type="index">
            </el-table-column>
            <el-table-column label="账号" prop="mobile"></el-table-column>
            <el-table-column label="姓名" prop="nickname"></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <el-switch v-model="scope.row.status" @change="switchChange(scope.row)"></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="邮箱">
              <template slot-scope="scope">
                {{ scope.row.email || '-' }}
              </template>

            </el-table-column>
            <el-table-column label="管理权限" width="500" >
              <template slot-scope="scope" class="auth_list">
                <span class="" v-if="scope.row.auth.indexOf(item.id) != -1" v-for="(item,index) in companySelect">{{item.text}}<font >、</font></span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" >
              <template slot-scope="scope">
                <el-button icon="el-icon-edit" size="small" type="text" @click="edit(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
        :closeOnClickModal="false"
        :title="subTitle"
        :visible.sync="dialogVisible"
        width="474px"
        @closed="empty">
      <el-form ref="subFilters" :model="subFilters" :rules="subFiltersrules">
        <el-form-item class="mobile" label="账号：" prop="mobile">
          <el-row>
            <el-col :span="20">
              <el-input v-model="subFilters.mobile" clearable placeholder="请填写手机号" size="small"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="mobile" label="姓名：" prop="nickname">
          <el-row>
            <el-col :span="20">
              <el-input v-model="subFilters.nickname" clearable placeholder="请输入" size="small"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="mobile" label="邮箱：" prop="email">
          <el-row>
            <el-col :span="20">
              <el-input v-model="subFilters.email" clearable placeholder="请填写邮箱" size="small"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="mobile" label="状态：" prop="status">
          <el-row>
            <el-col :span="20">
              <el-radio-group v-model="subFilters.status">
                <el-radio :label="0">停用</el-radio>
                <el-radio :label="1">启用</el-radio>
              </el-radio-group>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="auth" label="权限：" prop="auth" >
          <el-checkbox-group v-model="subFilters.auth">
            <el-checkbox v-for="item in companySelect" :label="item.id" name="type">{{item.text}}</el-checkbox>
          </el-checkbox-group>

        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-close" size="small" @click="subReset('subFilters')">取消</el-button>
          <el-button icon="el-icon-folder-checked" size="small" type="primary" @click="subSave('subFilters')">保存
          </el-button>
        </el-form-item>
      </el-form>

    </el-dialog>
  </div>
</template>
<script>
import storage from '@/utils/storage'
import {fileUpload, myMessage, myMessageSave, myMessageSub, myMessageSubSave} from '@/api/personal.js'

export default {
  name: 'MyMessage',
  data() {
    return {
      subSH:true,
      loading: true,//骨架屏
      activeName: 'first',//tab的起始点
      modify: true,//控制账户设置是否在修改状态
      dialogVisible: false,//子账户弹框的显示与隐藏
      imageUrlSH: false,
      companySelect: [],//企业下拉
      details: [],//账户设置数据
      portrait: false,
      submitDis: false,
      filters: {//账户设置字段
        avatar: '',
        company: '',//企业名称
        nickname: '',//姓名
        email: '',//邮箱
      },
      rules: {//账户设置验证
        company: [
          {required: true, message: '请输入企业名', trigger: 'blur'},
        ],
        nickname: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        email: [
          {required: true, message: '请输入邮箱地址', trigger: 'blur'},
          {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
        ],
      },
      tableData: [],
      subFilters: {//子账户字段
        id: 0,//id
        mobile: '',//账号
        nickname: '',//姓名
        email: '',//邮箱
        status: 1,//状态
        auth:[]
      },
      replace:{
        auth:[]
      },
      subFiltersrules: {//子账户验证
        mobile: [
          {required: true, message: "请填写手机号", trigger: "blur"},
          {pattern: /^((0\d{2,3}-\d{7,8})|(1[23584]\d{9}))$/, message: "请填写手机号", trigger: "blur"}
        ],
        nickname: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        email: [
          {required: true, message: '请输入邮箱地址', trigger: 'blur'},
          {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
        ],
        status: [
          {required: true, message: '请选择状态', trigger: 'change'}
        ],
        auth: [
          { type: 'array', required: true, message: '', trigger: 'change' }
        ],
      },
      subTitle: '设置子账户',
      user: storage.getUser(),
    };
  },
  created() {
    this.detail()
      this.select()

    if(this.user.parent_id === 0 && this.user.type !== 0){
      this.subList()
    }else{
      this.subSH = false
    }
    if (!storage.getUser().nickname || !storage.getUser().company || !storage.getUser().email) {
      this.modify = false
    }
  },
  methods: {
    //下拉
    async select() {
      let s = await storage.getSelects('app_names')
      this.companySelect = s.app_names
    },
    // 获取账户信息事件
    async detail() {
      this.loading = true
      try {
        const res = await myMessage()
        if (res && res.err_code === 0) {
          this.user = storage.getUser()
          this.portrait = false
          this.loading = false
          this.details = res.data
          // this.filters = res.data
          this.details.avatar = this.user.avatar
          this.filters.avatar = res.data.avatar
          this.filters.company = res.data.company
          this.filters.nickname = res.data.nickname
          this.filters.email = res.data.email

        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {
        this.loading = false
      }
    },
    // 修改账户信息事件
    detailSave(formName) {
      if (!this.portrait) {
        this.$delete(this.filters, 'avatar')
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitDis = true
            const res = await myMessageSave(this.filters)
            if (res && res.err_code === 0) {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.submitDis = false
              this.modify = true
              this.filters.avatar = this.details.avatar
              this.user.company = this.filters.company
              this.user.nickname = this.filters.nickname
              this.user.email = this.filters.email
              this.user.avatar = this.details.avatar
              storage.setUser(this.user)
              this.detail()
              this.$store.commit('setuser',{
                user:storage.getUser(),
              });
              // this.$emit('getMessage', storage.getUser());
            }else{
              this.$message({
                message: res.err_msg,
                type: 'warning'
              });
            }
          } catch (e) {
            this.submitDis = false
          }
        } else {
          return false
        }
      })
    },
    //上传头像
    async uploadImg(item) {
      let formData = new FormData();
      formData.append("file", item.file);
      formData.append("is_base64", 0);
      if(item.file.size /1024/1024 <=2){
        try {
          const res = await fileUpload(formData)
          if (res && res.err_code === 0) {
            this.portrait = true
            this.details.avatar = res.data.url;
            this.filters.avatar = res.data.path
          } else {
            this.$message({
              type: "warning",
              message: res.err_msg,
            })
          }
        } finally {
          this.uploadBtn = true;
        }
      }else{
        this.$message({
          type: "warning",
          message: '请上传2M以内的图片!',
        })
      }

    },
    // 子账户列表
    async subList() {
      try {
        const res = await myMessageSub()
        if (res && res.err_code === 0) {
          this.tableData = res.data
          this.tableData.forEach((item) => {
            if (item.status == 1) {
              item.status = true
            } else {
              item.status = false
            }
          })
          // storage.setItem('users',this.tableData)
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {
      }
    },
    //创建账号
    establish() {
      this.dialogVisible = true
      this.$store.commit('setscroll',{
        scroll:this.dialogVisible,
      });
      this.companySelect.forEach((item) =>{
        this.subFilters.auth.push(item.id)
      })
    },
    //修改子账户的状态
    async switchChange(value) {
      this.subFilters.id = value.id
      this.subFilters.mobile = value.mobile
      this.subFilters.nickname = value.nickname
      this.subFilters.email = value.email
      this.subFilters.status = value.status ? 1 : 0

      try {
        const res = await myMessageSubSave(this.subFilters)
        if (res && res.err_code === 0) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          storage.setItem('users', this.tableData)
          this.subFilters = this.$options.data().subFilters;
          this.$refs.subFilters.resetFields();
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } catch (e) {

      }
    },
    // 新建子账户信息事件
    subSave(subFilters) {
      this.$refs[subFilters].validate(async (valid) => {
        if (valid) {
          try {
            const res = await myMessageSubSave(this.subFilters)
            if (res && res.err_code === 0) {
              this.subList()
              this.$message({
                message: '保存成功',
                type: 'success'
              })
              this.dialogVisible = false
              this.$store.commit('setscroll',{
                scroll:this.dialogVisible,
              });
              this.subFilters = this.$options.data().subFilters;
              this.$refs[subFilters].resetFields();
              this.subTitle = '设置子账户'
            } else {
              this.subTitle = res.err_msg
            }
          } catch (e) {

          }
        } else {
          return false
        }
      })
    },
    // 取消修改子账户信息事件
    subReset(subFilters) {
      this.dialogVisible = false
      this.subFilters = this.$options.data().subFilters;
      this.$refs[subFilters].resetFields();
      this.subTitle = '设置子账户'
    },

    //关闭弹窗，清空选项
    empty() {
      this.dialogVisible = false
      this.$store.commit('setscroll',{
        scroll:this.dialogVisible,
      });
      this.subFilters = this.$options.data().subFilters;
      this.$refs.subFilters.resetFields();
      this.subTitle = '设置子账户'
    },

//编辑表格数据
    edit(value) {
      this.dialogVisible = true
      this.$store.commit('setscroll',{
        scroll:this.dialogVisible,
      });
      this.subFilters.id = value.id
      this.subFilters.mobile = value.mobile
      this.subFilters.nickname = value.nickname
      this.subFilters.email = value.email
      this.subFilters.status = value.status
      if (this.subFilters.status) {
        this.subFilters.status = 1
      } else {
        this.subFilters.status = 0
      }
      this.subFilters.auth = value.auth
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../style/essential.less";
::v-deep .el-table__cell{
  .cell span:nth-last-of-type(1){
    font{
      display: none;
    }
  }
}
::v-deep .auth{
  display: flex;
  align-items: center;
  .el-form-item__label{
    float: unset;
  }
  .el-form-item__content{
    width: calc(100% - 65px);
    label{
      margin: 0;
      display: block;
      .el-checkbox__label{
        white-space: nowrap;
        overflow: hidden;
        text-overflow:ellipsis
      }
    }
  }
}
</style>
