<template>
  <div class="applyDetail detail ba-col-fff">
    <h2>国内商标申请详情</h2>
    <el-skeleton :loading="loading" animated>
      <template slot="template">
        <div class="plf20 col-606">
          <div class=" bor">
            <div  class="detail_l ">
              <div class="img w100 flex align-cen ju-con-cen pos-rel">
                <el-skeleton-item
                    class="img-logo"
                    style="width: 190px;height: 190px"
                    variant="image"
                />
              </div>
              <div class="flows p20">
                <h3 class="col-303 font-wbold m-b10">商品/服务项目</h3>
                <p class="line-h30 ellipsis">
                  <el-skeleton-item  variant="p"/>
                </p>
                <p class="line-h30 ellipsis">
                  <el-skeleton-item  variant="p"/>
                </p>
                <p class="line-h30 ellipsis">
                  <el-skeleton-item  variant="p"/>
                </p>
              </div>
            </div>
            <div   class="detail_r">
              <div class="r-cont">
                <h3 class="p10 line-h30 col-303 font-wbold">
                  <el-skeleton-item style="width: 50%" variant="p"/>

                  <div  class="disinblk">
                    <el-skeleton-item style="width: 50%" variant="p"/>
                  </div>

                </h3>
                <div class="flex flex-cen p20">
                  <el-skeleton-item variant="p" style="width: 50%"/>
                  <el-skeleton-item style="margin: 0 20px;"  variant="p"/>
                  <el-skeleton-item variant="p" style="width: 50%"/>
                </div>
                <div class="cont-text p20 line-h30">

                  <div class="flex flex-cen flex-warp cont">
                    <p><strong>申请类型：</strong><el-skeleton-item style="width: 50%" variant="p"/></p>
                    <p v-if="user.type != 0"><strong>金额：</strong><el-skeleton-item style="width: 50%" variant="p"/></p>

                    <p>
                      <strong>申请日期：</strong><el-skeleton-item style="width: 50%" variant="p"/>
                    </p>
                    <p>
                      <strong>商标号：</strong><el-skeleton-item style="width: 50%" variant="p"/>
                    </p>

                    <p>
                      <strong>收文方式：</strong><el-skeleton-item style="width: 50%" variant="p"/>
                    </p>
                    <p>
                      <strong>商标类型：</strong><el-skeleton-item style="width: 50%" variant="p"/>
                    </p>

                    <p>
                      <strong>申请人名称（中）：</strong><el-skeleton-item style="width: 50%" variant="p"/>
                    </p>
                    <p>
                      <strong>申请人名称（英）：</strong><el-skeleton-item style="width: 50%" variant="p"/>
                    </p>

                    <p>
                      <strong>申请人地址（中）：</strong><el-skeleton-item style="width: 50%" variant="p"/>
                    </p>
                    <p>
                      <strong>申请人地址（英）：</strong><el-skeleton-item style="width: 50%" variant="p"/>
                    </p>

                    <div class="w100">
                      <strong>代理机构：</strong> <el-skeleton-item style="width: 50%" variant="p"/>
                    </div>
                  </div>



                </div>
              </div>
              <div class="r-list">
                <div class="list_t p10 ">
                  <h3 class="col-303 font-wbold flex flex-cen">
                    <span>商标官文</span>
                    <div class="fr">

                      <el-skeleton-item style="width: 100px" variant="p"/>
                    </div>
                  </h3>
                </div>
                <el-table
                    :data="skeletonList"
                    style="width: 100%"
                    tooltip-effect="dark"
                    @selection-change="handleSelectionChange"
                >
                  <template slot="empty">
                    <el-empty></el-empty>
                  </template>
                  <el-table-column type="selection" width="55"></el-table-column>
                  <el-table-column label="序号" width="50">
                    <template>
                      <el-skeleton-item style="width: 100%" variant="p"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="官文名称">
                    <template>
                      <el-skeleton-item style="width: 50%" variant="p"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="收文日期">
                    <template>
                      <el-skeleton-item style="width: 50%" variant="p"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="上传日期">
                    <template>
                      <el-skeleton-item style="width: 50%" variant="p"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="100">
                    <template>
                      <el-skeleton-item style="width: 50%" variant="p"/>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>

        </div>

      </template>
      <template>
        <div class="plf20 m-b40 col-606">
          <div class=" bor">
            <div class="detail_l ">
              <div class="img w100 flex align-cen ju-con-cen pos-rel">
                <el-image
                    :src="detail.tmimage"
                    :preview-src-list="[detail.tmimage]">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <img
                    alt=""
                    class="img-flag pos-abs pos-l0 pos-t0"
                    :src="statusSrc">
              </div>
              <div class="flows p20">
                <h3 class="col-303 font-wbold m-b10">商品/服务项目</h3>
                <div class="line-h30" v-html="detail.tmgoods" >
                </div>
              </div>
<!--              <p v-if="replace.tmgoods.length > 10 " class="text-cen pointer" @click="tmgoodsUp = !tmgoodsUp">{{tmgoodsUp ? '收起' : '查看全部'}}<i class=" m-l5" :class="{'el-icon-arrow-down':tmgoodsUp == false,'el-icon-arrow-up':tmgoodsUp == true}"></i></p>-->

            </div>
            <div class="detail_r">
              <div class="r-cont">
                <h3 class="p10 line-h30 col-303 font-16 font-wbold">
                  {{ detail.clstext }}：
                  <div class="disinblk col-blue">
                    {{
                      (!detail.tmname || detail.tmname == '图形') ? '图形' : detail.tmname
                    }}
                  </div>

                </h3>
                <div class="cont-text p20 ">
                  <div class="">
                    <el-steps :active="step" align-center>
                      <el-step v-for="(item,index) in steps" :title="item.title" :description="item.date" :icon="item.icon">
                      </el-step>
                    </el-steps>
                  </div>

                  <div class="flex flex flex-warp cont">
                    <p><strong>申请类型：</strong>{{ detail.typetext || '-' }}</p>
                    <p v-if="user.type != 0"><strong>金额：</strong>￥{{ detail.subtotal || '-' }}</p>

                    <p>
                      <strong>申请日期：</strong>{{ detail.deliver_at || '-' }}
                    </p>
                    <p>
                      <strong>商标号：</strong>{{ detail.tmcode || '-' }}
                      <i class="el-icon-copy-document col-blue pointer m-l5" v-copy="detail.tmcode"></i>
                    </p>

                    <p>
                      <strong>收文方式：</strong>{{ detail.way || '-' }}
                    </p>
                    <p>
                      <strong>商标类型：</strong>{{ detail.tmsblx || '-' }}
                    </p>
                    <p>
                      <strong>申请人名称（中）：</strong>
                      <router-link v-if="detail.client_id != 0" class="disinblk" :class="{'col-blue':detail.appname}" :to="{name: 'newlyBuild',params: { id: detail.client_id  },}"  target="_blank">
                        {{ detail.appname || '-'}}
                      </router-link>
                      <template v-else>
                        {{ detail.appname || '-' }}
                      </template>
                    </p>
                    <p>
                      <strong>申请人名称（英）：</strong>
                      <router-link v-if="detail.client_id != 0"  :class="{'col-blue':detail.appnameen}" :to="{name: 'newlyBuild',params: { id: detail.client_id  },}"  target="_blank">
                        {{ detail.appnameen || '-'}}
                      </router-link>
                      <template v-else>
                        {{ detail.appnameen || '-' }}
                      </template>
                    </p>

                    <p>
                      <strong>申请人地址（中）：</strong>{{ detail.appaddr || '-' }}
                    </p>
                    <p>
                      <strong>申请人地址（英）：</strong>{{ detail.appaddren || '-' }}
                    </p>

                    <div class="w100">
                      <strong>代理机构：</strong> <span :class="{'col-blue':detail.agentname}">{{ detail.agentname || '-'}}</span>
                    </div>
                  </div>


                </div>
              </div>
              <div class="r-list">
                <div class="list_t p10 ">
                  <h3 class="col-303 font-wbold flex flex-cen ">
                    <span>商标官文</span>
                    <div class="">
                      <span class="m-l10">
                        <el-button  v-if="user.type == 0" size="small" @click="over" icon="el-icon-download" > 批量下载</el-button>
                        <el-button v-else :loading="!down" size="small" @click="batchDownload" icon="el-icon-download" :disabled="ids.length == 0"> {{down ? '批量下载' : '下载中' }}</el-button>

                      </span>
                    </div>
                  </h3>
                </div>
                <el-table
                    :data="detail.documents"
                    height="100%"
                    @selection-change="handleSelectionChange"
                >
                  <template slot="empty">
                    <el-empty :image-size="80"></el-empty>
                  </template>
                  <el-table-column type="selection" :selectable="selectable" width="55"></el-table-column>
                  <el-table-column label="序号" type="index" width="50"></el-table-column>
                  <el-table-column label="官文类型" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                      <span v-if="user.type == 0 && Math.floor((new Date().getTime() - new Date(scope.row.created_at).getTime())/(24 * 60 * 60 * 1000)) > 30" class="col-blue" @click="over">{{ scope.row.matter }}</span>
                      <a :href="`${scope.row.url}&preview=true`" class="ellipsis"
                         v-else
                         target="_blank">
                        {{ scope.row.matter }}
                      </a>

                    </template>
                  </el-table-column>
                  <el-table-column label="收文日期" prop="made_at"></el-table-column>
                  <el-table-column label="上传日期" prop="created_at"></el-table-column>
                  <el-table-column fixed="right" label="操作" width="200">
                    <template slot-scope="scope">
                      <el-button v-if="user.type == 0 && Math.floor((new Date().getTime() - new Date(scope.row.created_at).getTime())/(24 * 60 * 60 * 1000)) > 30"
                                 icon="el-icon-download" size="small" type="text" @click="over">下载
                      </el-button>
                      <el-button v-else icon="el-icon-download" size="small" type="text" @click="open(scope.row)">下载
                      </el-button>

                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>

        </div>

      </template>

    </el-skeleton>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import {applyDetali,applyDownload} from '@/api/personal.js'
import status1 from '@/assets/img/personal/icon-status1.png'
import status2 from '@/assets/img/personal/icon-status2.png'
import status3 from '@/assets/img/personal/icon-status3.png'
import status4 from '@/assets/img/personal/icon-status4.png'
import status5 from '@/assets/img/personal/icon-status5.png'
import status6 from '@/assets/img/personal/icon-status6.png'
import status7 from '@/assets/img/personal/icon-status7.png'
import status8 from '@/assets/img/personal/icon-status8.png'
export default {
  name: "ApplyDetail",
  data() {
    return {
      user:storage.getUser(),
      statusImgSelect:[status1,status2,status3,status4,status5,status6,status7,status8],
      statusSelect:[ "已报送","已受理","已驳回","部分驳回","初审公告","已发证","已决定","已裁定",  ],
      statusSrc:'',//状态图片
      tmgoodsUp:false,
      replace:{
        documents:[],
        tmgoods:[]
      },
      skeletonList:storage.getList(),//骨架屏数据
      down: true,
      loading: true,//控制整个页面的骨架屏
      filters: {
        id: '',
      },
      detail: [],
      ids: [],
      step:0,
      steps:[],
    }

  },
  created() {
    this.domesticDetails()
  },
  methods: {
    //详情数据
    async domesticDetails() {
      this.filters.id = this.$route.params.id
      try {
        const res = await applyDetali(this.filters)
        if (res && res.err_code === 0) {
          this.loading = false
          this.detail = res.data;
          this.replace.tmgoods = res.data.tmgoods.split(",")
          let pages = new RegExp(",","g");
          this.detail.tmgoods = res.data.tmgoods.replace(pages,",</br>")
          this.replace.documents = this.detail.documents
          this.step =res.data.steps.active
          this.statusSrc = this.statusImgSelect[this.statusSelect.indexOf(res.data.status)]
          this.steps = res.data.steps.steps

        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {
        this.loading = false
      }
    },

    async batchDownload() {
      if (this.ids.length != 0) {
        this.down = false
        try {
          const res = await applyDownload({ids:this.ids})
          if (res && res.err_code === 0) {
            window.open(res.data.url)
            this.down = true
          }else{
            this.$message({
              message: res.err_msg,
              type: 'warning'
            });
          }
        }finally {
          this.down = true
        }
      } else {
        this.$message({
          type: "warning",
          message: "请选中要下载的官文序号!",
        });
      }
    },

    handleSelectionChange(val) {
      this.ids = [];
      val.forEach((item) => {
        this.ids.push(item.id);
      });
    },
    open(url) {
      let name = this.detail.tmcode + '-' + this.detail.intcls + '-' + (this.detail.tmname ? this.detail.tmname : '图形') + '-' + url.matter + '.' + url.name.split('.')[1]
      location.href = url.url.replace(url.name,name)
    },
    selectable(row, index){

      if(this.user.type == 0 &&  Math.floor((new Date().getTime() - new Date(row.created_at).getTime())/(24 * 60 * 60 * 1000)) > 30){
        return false
      }else{
        return  true
      }
    },
    over(){
      this.$confirm('已超出30天免费下载期，开通企业版可享无限期免费下载，详情请联系知产顾问！', '提示', {
        confirmButtonText: '确定',
        showCancelButton:false,
        type: 'warning'
      })
    },
  },


}
</script>

<style lang="less" scoped>
@import "../../style/applyDetail.less";
</style>
