<template>
  <div class="">
    <h2>
      著作权官文<span v-if="managementList.length != 0">{{ pageSize }} </span>
    </h2>
    <form action="" ref="filters" class="work-form" v-on:submit.prevent>
      <el-row :gutter="10" class="formInput" :class="{on:formOpen}">
        <el-col :xs="20" :sm="10" :lg="7" >
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="5" :sm="13" :md="8" :lg="8" :xl="6">
              <strong>作品/软件名称</strong></el-col>
            <el-col :xs="19" :sm="11" :md="16" :lg="16" :xl="18">
              <el-input
                  clearable

                  size="small"
                  v-model="filters.fullname"
                  placeholder="请输入作品/软件名称"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="20" :sm="10" :lg="7" >
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="5" :sm="13" :md="8" :lg="8" :xl="6">
              <strong>登记号</strong></el-col>
            <el-col :xs="19" :sm="11" :md="16" :lg="16" :xl="18">
              <el-input

                  clearable
                  size="small"
                  v-model="filters.regnum"
                  placeholder="请输入登记号"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="20" :sm="10" :lg="7" >
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="5" :sm="13" :md="8" :lg="8" :xl="6"><strong>官文类型</strong></el-col>
            <el-col :xs="19" :sm="11" :md="16" :lg="16" :xl="18">
              <el-select
                  clearable
                  size="small"
                  v-model="filters.matter"
                  filterable
                  placeholder="请选择官文类型"
              >
                <el-option
                    v-for="item in typeSelect"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>

        </el-col>
        <el-col :xs="20" :sm="10" :lg="7" >
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="5" :sm="13" :md="8" :lg="8" :xl="6" ><strong>上传日期</strong></el-col>
            <el-col :xs="19" :sm="11" :md="16" :lg="16" :xl="18">
              <el-date-picker

                  size="small"
                  v-model="filters.uploaddate"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-col>
          </el-row>

        </el-col>
      </el-row >
      <el-row class="formOpen">
        <el-col  :span="24"  :class="{on:formOpen}" >
          <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
        </el-col>
      </el-row>
      <el-button
          type="primary"
          class="search"
          size="small"
          @click="managementLists(filters.page = 1)"
          icon="el-icon-search"
      >查询</el-button
      >

      <el-button type="reset" :class="{on:formOpen}" class="reset" size="small" @click="reset" icon="el-icon-refresh-left"
      >重置</el-button
      >
    </form>
    <div class="mainrb">
      <div class="managementBt">
        <el-button
            type="primary"
            size="small"
            class="search"
            @click="fileSH = true"
            icon="el-icon-upload2"
        >上传官文</el-button
        >

        <span v-if="managementList.length != 0">
          <el-button   size="small" @click="download" v-if="down" icon="el-icon-download" :disabled="managementList.length == 0 || loading">批量下载</el-button>
          <el-button  v-else size="small" :loading="true">下载中</el-button>
        </span>
        <div class="page-up">
          <el-pagination
              background
              size="small"
              layout="prev, next"
              @current-change="managementLists"
              :current-page.sync="filters.page"
              :page-sizes="[30, 50, 100]"
              :page-size="filters.per_page"
              :total="pageSize">
          </el-pagination>
        </div>

      </div>
      <el-skeleton  :loading="loading" animated :count="1">
        <template slot="template">
          <el-table
              :class="{on:!formOpen}"
              :data="lists"
              @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column  width="50" label="序号">
              <template>
                <el-skeleton-item variant="p" style="width: 100%"/>
              </template>
            </el-table-column>
            <el-table-column label="官文类型">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="作品名称/软件名称" width="180">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="著作权人">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="登记号">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="上传日期">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template>
                <el-skeleton-item variant="p" class="w50"/>

              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-table
            :class="{on:!formOpen,ios:$store.state.heights}"
            :data="managementList"
            height="100%"
            @selection-change="handleSelectionChange"
        >
          <template slot="empty">
            <el-empty :image-size="80"></el-empty>
          </template>
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column type="index" width="50" label="序号"></el-table-column>
          <el-table-column label="官文类型" width="">
            <template slot-scope="scope">
              <a v-if="scope.row.url" target="_blank"
                 :href="`${scope.row.url}&preview=true`" >
                {{scope.row.matter}}
              </a>
              <template v-else>
                {{scope.row.matter || '-'}}
              </template>
            </template>
          </el-table-column>
          <el-table-column label="作品/软件名称" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              {{scope.row.fullname || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="著作权人" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <router-link
                  v-if="scope.row.client_id != 0 && scope.row.client_id != null "
                  :to="{
                  name: 'newlyBuild',
                    params: { id: scope.row.client_id  },
                }" target="_blank"
              >
                {{scope.row.author_nationality || '-'}}
              </router-link>
              <template v-else>
                {{scope.row.author_nationality || '-'}}
              </template>
            </template>
          </el-table-column>
          <el-table-column label="登记号" width="" :show-overflow-tooltip="true">
            <template slot-scope="scope" >
              <router-link v-if=" scope.row.matter != '软件登记证书'" :to="{name: 'worksDetails',
                params: { id: scope.row.copyright_id }}"  target="_blank">
                {{scope.row.regnum}}
              </router-link>
            <span v-else>
              {{scope.row.regnum || '-'}}
            </span>
            </template>
          </el-table-column>
          <el-table-column label="上传日期" prop="uploaddate"> </el-table-column>
          <el-table-column label="操作"  fixed="right" >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="open(scope.row)" icon="el-icon-download">下载</el-button>
              <el-button v-if="scope.row.is_custom == 1" @click="deleteFile(scope.row.id)" class="delete col-red" type="text" size="small" icon="el-icon-delete">删除</el-button>

            </template>
          </el-table-column>
        </el-table>
      </el-skeleton>

      <el-pagination
          @size-change="changeRowNum"
          @current-change="managementLists(backTop)"
          :current-page.sync="filters.page"
          :page-sizes="[30, 50, 100]"
          :page-size="filters.per_page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageSize"
      >
      </el-pagination>
    </div>

    <el-dialog
        title="上传文件"
        :visible.sync="fileSH"
        width="335px"
        class="file"
        :closeOnClickModal="false"
        @open="openDia"
        @close="empty"
        @closed="empty"
    >
      <div class="cont-tabc" >
        <el-form :model="manual" :rules="manualRules" label-position="right" label-width="80px" ref="ruleForm" size="mini">
          <el-form-item label="官文类型" prop="matter">
            <el-select
                clearable
                size="small"
                v-model="manual.matter"
                filterable
                placeholder="请选择官文类型"
            >
              <el-option
                  v-for="item in typeSelect"
                  :key="item"
                  :label="item"
                  :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="登记号" prop="regnum">
            <el-input
                clearable
                size="small"
                v-model="manual.regnum"
                placeholder="请输入登记号"
            ></el-input>
          </el-form-item>
          <div class="tabc-update">
            <el-upload
                class="upload-demo"
                ref="upload"
                action="/api/upload"
                :file-list="fileList"
                :limit="1"
                :http-request="uploadImg"
                :before-upload="beforeUpload"
            >
              <el-form-item prop="name">
                <el-input readonly size="small" v-model="manual.name"  placeholder="请点击按钮上传文件"></el-input>
              </el-form-item>
              <el-button
                  :disabled="distinguishDis"
                  slot="trigger"
                  size="mini"
                  type="primary"
                  circle
                  icon="el-icon-plus"
                  v-if="uploadBtn"
              ></el-button>
              <el-button v-else type="primary" circle size="mini" slot="trigger" :loading="true"></el-button>
            </el-upload>

          </div>
<!--          <div class="batch">-->
<!--            官文太多？马上 <router-link to="/personal/trademarkAdmin/upload">批量上传</router-link>-->
<!--          </div>-->
          <div class="submitBtn">
            <el-button @click="fileSH = false" class="cancel" size="small" icon="el-icon-close">取 消</el-button>
            <el-button  type="primary" size="small" class="confirm"  @click="submit('ruleForm')" :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' :  'el-icon-folder-checked'">确 定</el-button>
          </div>

        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import storage from '@/utils/storage'
import { managementWork,manageWorkDown,fileUpload,manageWorkDistinguish,manageWorkSave,manageWorkDelete,copyrightDocsSave } from '@/api/personal.js'
export default {
  data() {
    return {
      lists:storage.getList(),
      formOpen:false,
      down:true,//控制导出中的显示与隐藏
      loading: true,//控制列表的骨架屏
      loadingUp:true,//控制识别文件的骨架屏
      uploadBtn:true,//控制上传文件按钮加载中的显示与隐藏
      fileList: [],//记录上传文件成功的数组
      pickerOptions: {//日期选择器的便利选择对象
        shortcuts:storage.getShortcuts(),
      },
      fileSH: false, //控制文件上传对话框显示与隐藏
      managementList: [],//列表数据
      array:{
        ids: []
      },//批量下载的数组
      deleteId:{
        id:''
      },
      tabtNum: 1, //文件上传对话框里控制手动上传还是智能识别
      file:{
        name: "", //上传的文件名
        path:'',
      },
      submitDis:false,
      distinguishDis:false,
      submitBtn:true,
      distinguishSH: true, //控制识别按钮的显示与隐藏
      contSH: false,//控制识别中的骨架屏
      isOn: false, //控制补充信息的显示与隐藏
      isAct: false, //控制补充信息（智能识别）的显示与隐藏
      automatic: {//自动上传的字段
        matter: "",
        regnum:'',
        name: "",//上传官文的名称
      },
      automaticRules:{
        matter:[
          { required: true, message: '请选择官文类型', trigger: 'change' },
        ],
        regnum:[
          { required: true, message: '请输入商标号', trigger: 'change' },
        ],
        name:[
          {  required: true, message: '请点击按钮上传文件', trigger: 'change' },
        ],
      },
      manual: {//手动上传的字段
        matter: "",
        regnum:'',
        name:''
      },
      manualRules:{
        matter:[
          { required: true, message: '请选择官文类型', trigger: 'change' },
        ],
        regnum:[
          { required: true, message: '请输入商标号', trigger: 'change' },
        ],
        name:[
          {  required: true, message: '请点击按钮上传文件', trigger: 'change' },
        ],
      },
      archives: {
        matter: "", //官文名称
        fullname: "", //商标名称
        regnum: "", //登记号
        uploaddate: "", //上传日期
      },
      namesSelect:[],//官文类型下拉数据
      filters: {//官文搜索表单字段
        fullname: "", //软件简称
        matter:'',
        regnum:'',
        uploaddate:'',
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
      },
      typeSelect: ['作品样品','作品登记证书','软件登记证书'],//手动上传官文类型下拉
      backTop:true,
      pageSize: 0,//总条数

    };
  },
  created() {
    this.managementLists();
    this.select()
  },
  methods: {
    //下拉
    async select(){
      let s = await storage.getSelects('copyright_doc_types')
      this.namesSelect = s.copyright_doc_types
    },

    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.managementLists(this.backTop);
    },
    //渲染列表事件
    async managementLists(backTop) {
      this.loading = true;
      this.managementList = storage.getList();
      try{
        const res = await managementWork(this.filters)
        if(res && res.err_code === 0){
          if(backTop === true){
            storage.backTop()
            this.loading = false;
            this.pageSize = res.data.total;
            this.managementList = res.data.list;
          }else{
            this.loading = false;
            this.pageSize = res.data.total;
            this.managementList = res.data.list;
          }

        }else {
          this.managementList = []
        }
      }finally{
        this.loading = false;
      }
    },
    //重置事件
    reset() {
      this.filters = this.$options.data().filters;
      this.managementLists()
    },
    //记录选择要下载的id事件
    handleSelectionChange(val) {
      this.array.ids = [];
      val.forEach((item) => {
        this.array.ids.push(item.id);
      });
    },
    //批量下载点击事件
    async download() {
      if(this.array.ids.length == 0){
        this.$confirm("请选中要下载的官文序号", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
      }else{
        this.down = false
        try{
          const res = await manageWorkDown(this.array)
          if(res && res.err_code === 0){
            window.open(res.data.url)
            this.down = true
          }else{
            this.$message({
              message: res.err_msg,
              type: 'warning'
            });
          }

        }finally{
          this.down = true;
        }
      }

    },
    open(item) {
      let name = (item.fullname ? item.fullname : '图形') + '-' + item.matter +  '.' + item.name.split('.')[1]
      location.href = item.url.replace(item.name,name)
      // location.href = url
    },
//官文删除事件
    deleteFile(item){
      this.deleteId.id = item
      try{
        this.$confirm('确定要删除该官文吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          await manageWorkDelete(this.deleteId).then(res => {
            if (res && res.err_code === 0) {
              this.$message({
                type: "success",
                message: "官文已删除！",
              });
              this.managementLists(this.filters.page = 1)
            } else {
              this.$message({
                type: "error",
                message: res.err_msg,
              });
            }
          })
        })

      } finally{

      }
    },

    //文件上传时事件
    beforeUpload(){
      this.uploadBtn = false
    },

//文件上传后事件(自动上传)
    async uploadImg(item) {
      let formData = new FormData();
      formData.append("file", item.file);
      formData.append("is_base64", 0);
      try{
        const res = await fileUpload(formData)
        if(res && res.err_code === 0){
          this.uploadBtn = true
          this.file.name =this.automatic.name = this.manual.name = res.data.name;
          this.file.path = res.data.path;
          this.$refs.upload.clearFiles()
          this.fileList = []
        }else{
          this.$message({
            type: "warning",
            message: res.err_msg,
          })
        }
      }finally{
        this.uploadBtn = true;
      }
    },
    //识别按钮点击事件
    distinguishBtn(automatic) {

      this.$refs[automatic].validate(async (valid) =>{
        try{
          if(this.automatic.name != ''){
            this.distinguishDis = true
            this.contSH = true;
            this.loadingUp = true
            let res = await manageWorkDistinguish(this.file)
            if(res && res.err_code === 0){
              this.loadingUp = false
              this.automatic.regnum = res.data.regnum;
              this.automatic.matter = res.data.matter;
              this.distinguishSH = false;
              this.distinguishDis = false
            }else{
              this.$message({
                message: res.err_msg,
                type: 'warning'
              })
              this.distinguishSH = true;
              this.contSH =false
              this.fileList = []
              this.distinguishDis = false
            }
          }
        }catch (e) {
          this.distinguishSH = true;
          this.contSH =false
          this.fileList = []
        }
      })
    },
    openDia(){
      this.$emit('dialog',this.fileSH)
    },
    //上传官文提交事件
    submit(formName) {
      this.$refs[formName].validate(async (valid) =>{
        if(valid){
          try{
            this.distinguishDis = this.submitDis = true
            const res = await copyrightDocsSave({ ...this.file, ...this.manual})
            if(res && res.err_code === 0){
              this.success()
            }else{
              this.$message({
                type: "warning",
                message: res.err_msg,
              })
              this.fileList = []
              this.distinguishDis = this.submitDis = false
            }
          }catch (e) {
            this.fileList = []
            this.distinguishDis = this.submitDis = false
          }
        }else{
          return false;
        }
      })


    },
    submitAutomatic(automatic){
      this.$refs[automatic].validate(async (valid) =>{
        if(valid){
          try{
            this.distinguishDis = this.submitDis = true
            const res =await  manageWorkSave({ ...this.file, ...this.automatic})
            if(res && res.err_code === 0){
              this.success()
            }else{
              this.$message({
                type: "warning",
                message: res.err_msg,
              })
              this.distinguishDis = this.submitDis = false
            }
            // this.$refs[automatic].resetFields();
          }catch (e) {
            this.fileList = []
            this.distinguishDis = this.submitDis = false
          }
        }else{
          return false
        }

      })

    },
    //上传官文提交成功事件
    success(){
      this.$message({
        type: "success",
        message: "提交成功!",
      });
      this.managementLists(this.filters.page = 1)
      this.fileSH = false;
      this.$store.commit('setscroll',{
        scroll:this.downloadDia,
      });
      this.tabtNum =1
      this.distinguishSH = true;
      this.contSH = false;
      this.fileList = []
      this.distinguishDis = this.submitDis = false
    },
    empty(){
      this.$refs.ruleForm.resetFields()
      this.fileSH = false;
      this.$store.commit('setscroll',{
        scroll:this.fileSH,
      });
      this.tabtNum =1
      this.distinguishSH = true;
      this.contSH = false;
      this.$refs.upload.clearFiles()
      this.fileList = []
      this.file = this.$options.data().file; //清空表单
      this.submitDis = false
    },

  },
};
</script>

<style lang="less" scoped>
@import "../../style/management.less";

.el-col-md-7{
  margin-right:0;
}
@media screen and (max-width: 1320px){

  ::v-deep .el-table{
    .delete {
     margin: 10px 0 0 0;
    }
  }

}

@media screen and (max-width: 768px){
  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 518px") !important;
  }
  .el-table.on{
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 392px") !important;
    }
  }
  .formInput.on{
    height: 168px;
  }
}

@media screen and (min-width: 768px){
  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 424px") !important;
  }
  .ios{
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 464px") !important;
    }
  }
  .formInput.on{
    height: 74px;
  }
}

</style>
