<template>
    <div class="">
        <h2>表单设置</h2>
        <form v-on:submit.prevent>
            <div class="flex flex-cen search p-b10">
                <div class="flex flex-cen flex-gap10">
                    <strong>表单名称</strong>
                    <el-input v-model="name" clearable placeholder="请输入表单名称" size="small"></el-input>
                </div>
                <el-button class="search" icon="el-icon-search" size="small" type="primary" @click="applyForms">查询
                </el-button>
            </div>
        </form>
        <div class="mainrb">
            <div class="listT p10">
                <el-button icon="el-icon-circle-plus-outline" size="small" type="primary" @click="formAdd">
                    创建表单
                </el-button>

            </div>
            <el-skeleton :count="1" :loading="loading" animated>
                <template slot="template">
                    <el-table :class="{ ios: $store.state.heights }" :data="form_list" style="width: 100%">

                        <el-table-column label="序号">
                            <template>
                                <el-skeleton-item variant="p" />
                            </template>
                        </el-table-column>
                        <el-table-column label="表单名称">
                            <template>
                                <el-skeleton-item variant="p" class="w50" />
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" width="180">
                            <template>
                                <el-skeleton-item variant="p" class="w50" />
                            </template>
                        </el-table-column>
                        <el-table-column label="审批人">
                            <template>
                                <el-skeleton-item variant="p" class="w50" />
                            </template>
                        </el-table-column>
                        <el-table-column label="创建人">
                            <template>
                                <el-skeleton-item variant="p" class="w50" />
                            </template>
                        </el-table-column>
                        <el-table-column label="创建时间">
                            <template>
                                <el-skeleton-item variant="p" class="w50" />
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <template>
                                    <el-skeleton-item variant="p" class="w50" />
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <el-table :class="{ ios: $store.state.heights }" :data="form_list" height="100%" style="width: 100%">
                    <template slot="empty">
                        <el-empty :image-size="80"></el-empty>
                    </template>

                    <el-table-column label="序号" type="index">
                    </el-table-column>
                    <el-table-column label="表单名称" min-width="120" prop="name" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column label="状态" min-width="180">
                        <span slot-scope="scope"
                            :class="{ 'col-green': scope.row.state == 1, 'col-909': scope.row.state == 0 }">
                            {{ scope.row.state == 1 ? '启用' : '停用' }}
                        </span>
                    </el-table-column>

                    <el-table-column label="审批人">
                        <template slot-scope="scope">
                            <span v-for="(item, i) in scope.row.approvers">
                                <span>{{ item.name }}</span>
                                <span class="font-12" v-if="i != scope.row.approvers.length - 1">-></span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建人">
                        <template slot-scope="scope">
                            {{ scope.row.creator.nickname || '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间">
                        <template slot-scope="scope">
                            {{ scope.row.created_at ? scope.row.created_at.slice(0, 10) : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit-outline" size="small" type="text"
                                @click="detail({ id: scope.row.id })">编辑</el-button>

                            <el-button v-if="scope.row.state != 1" icon="el-icon-delete" class="col-red" size="small"
                                @click="deleteForm({ id: scope.row.id })" type="text">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-skeleton>
        </div>
        <el-dialog :closeOnClickModal="false" :modal-append-to-body="false" :visible.sync="approvalDia" class="form-dia"
            title="表单设置" width="390px" @close="openDia('ruleForm')" @open="openDia('ruleForm')">
            <el-form label-width="100px" :model="form" :rules="rules" ref="ruleForm">
                <el-form-item label="表单名称" prop="name">
                    <el-input v-model="form.name" clearable placeholder="请输入表单名称" size="small"></el-input>
                </el-form-item>
                <el-form-item label="审批人" prop="approvers">
                    <div class="flex flex-dir-col">
                        <el-select v-model="replace.first" clearable placeholder="请选择" @change="approvalChnage(1)"
                            size="small">

                            <el-option :disabled="form.approvers.filter(i => i.id == item.id).length != 0 && item.id != replace.first"
                                v-for="item in approvalList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                        <div class="tc">
                            <i class="el-icon-d-arrow-right font-16"></i>
                        </div>

                        <el-select v-model="replace.secound" clearable placeholder="请选择" @change="approvalChnage(2)"
                            size="small">
                            <el-option :disabled="form.approvers.filter(i => i.id == item.id).length != 0 && item.id != replace.secound"
                                v-for="item in approvalList" :key="item.name" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                        <div class="tc">
                            <i class="el-icon-d-arrow-right font-16"></i>
                        </div>
                        <el-select v-model="replace.thrid" clearable placeholder="请选择" @change="approvalChnage(3)"
                            size="small">
                            <el-option :disabled="form.approvers.filter(i => i.id == item.id).length != 0 && item.id != replace.thrid"
                                v-for="item in approvalList" :key="item.phone" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                        <div class="tc">
                            <i class="el-icon-d-arrow-right font-16"></i>
                        </div>
                        <el-select v-model="replace.fourth" clearable placeholder="请选择" @change="approvalChnage(3)"
                            size="small">
                            <el-option :disabled="form.approvers.filter(i => i.id == item.id).length != 0 && item.id != replace.fourth"
                                v-for="item in approvalList" :key="item.phone" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                        <div class="tc">
                            <i class="el-icon-d-arrow-right font-16"></i>
                        </div>
                        <el-select v-model="replace.fifth" clearable placeholder="请选择" @change="approvalChnage(3)"
                            size="small">
                            <el-option :disabled="form.approvers.filter(i => i.id == item.id).length != 0 && item.id != replace.fifth"
                                v-for="item in approvalList" :key="item.phone" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>

                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="0">停用</el-radio>
                        <el-radio :label="1">启用</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item class="tr">
                    <el-button size="small" icon="el-icon-s-promotion" type="primary" :loading="saveLoad"
                        @click="save('ruleForm')">
                        保存设置
                    </el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { flowsApplyForm, flowsApplyFormSave, flowsApprovers, flowsApplyForms, applyFormDelete } from '@/api/personal.js'
import storage from '@/utils/storage'
export default {
    props: ['user'],
    data() {
        var approvers = (rule, value, callback) => {
            if (value.length == 0) {
                callback(new Error(' '));
            } else {
                callback();
            }
        };
        return {
            loading: false,
            approvalDia: false,
            name: '',
            form_list: [],
            saveLoad: false,
            form: {
                name: '',
                state: 1,
                approvers: [],
            },
            replace: {
                first: '',
                secound: '',
                thrid: '',
                fourth:'',
                fifth:'',
            },
            approvalList: [],
            rules: {
                name: [
                    { required: true, message: ' ', trigger: 'blur' }
                ],
                approvers: [
                    { validator: approvers, trigger: 'change' }
                ],
                state: [
                    { required: true, message: ' ', trigger: 'change' }
                ],
            }
        }
    },
    created() {
        this.applyForms()
    },
    methods: {
        // 获取表单设置列表
        applyForms() {
            this.loading = true
            flowsApplyForms({ name: this.name }).then(res => {
                if (res.err_code === 0) {
                    this.form_list = res.data
                    this.loading = false
                }
            }).catch(() => {
                this.loading = false
            })
        },
        formAdd() {
            this.list()
        },
        // 获取审批人
        list() {
            this.approvalList = []
            flowsApprovers().then(res => {
                if (res.err_code === 0) {
                    if (res.data.length != 0) {
                        res.data.forEach(item => {
                            this.approvalList.push({ id: item.id, name: item.name, phone: item.phone })
                        })
                        this.approvalDia = true
                    } else {
                        this.$message({
                            message: '请先创建至少一位审批人！',
                            type: 'warning'
                        });
                    }

                }
            })
        },
        // 详情
        detail(obj) {
            this.list()
            flowsApplyForm(obj).then(res => {
                if (res.err_code === 0) {
                    this.form = { ...this.form, ...res.data }
                    if (this.form.approvers.length != 0) {
                        this.replace.first = this.form.approvers[0].id
                        if (this.form.approvers.length > 1) {
                            this.replace.secound = this.form.approvers[1].id
                        }
                        if (this.form.approvers.length > 2) {
                            this.replace.thrid = this.form.approvers[2].id
                        }
                        if (this.form.approvers.length > 3) {
                            this.replace.fourth = this.form.approvers[3].id
                        }
                        if (this.form.approvers.length > 4) {
                            this.replace.fifth = this.form.approvers[4].id
                        }
                    }

                }
            })
        },
        // 删除表单
        deleteForm(obj) {
            this.$confirm('确定要删除此审批表单吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                applyFormDelete(obj).then(res => {
                    if (res.err_code === 0) {
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                        this.applyForms()
                    }
                }).catch(() => {

                })
            }).catch(() => {

            });

        },
        // 审批人修改
        approvalChnage(num) {
            let first = this.approvalList.filter(item => item.id == this.replace.first)
            let secound = this.approvalList.filter(item => item.id == this.replace.secound)
            let thrid = this.approvalList.filter(item => item.id == this.replace.thrid)
            let fourth = this.approvalList.filter(item => item.id == this.replace.fourth)
            let fifth = this.approvalList.filter(item => item.id == this.replace.fifth)
            this.form.approvers = [...first, ...secound, ...thrid,...fourth,...fifth]
            console.log(this.form.approvers)
        },
        save(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.saveLoad = true
                    try {
                        flowsApplyFormSave(this.form).then(res => {
                            if (res.err_code === 0) {
                                this.$message({
                                    message: '保存成功',
                                    type: 'success'
                                });
                                this.approvalDia = false
                                this.saveLoad = false
                                this.applyForms()
                            } else {
                                this.saveLoad = false
                            }
                        })
                    } catch {
                        this.saveLoad = false
                    }

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        openDia(ruleForm) {
            this.form = this.$options.data().form;
            this.$refs[ruleForm].resetFields();
            
            this.replace = this.$options.data().replace;
        },
    }
}
</script>
<style lang="less" scoped>
.mainrb{
    height: calc(100vh - 200px);
}
.form-dia {
    ::v-deep .el-form-item__content {
        line-height: 32px;
    }

    .el-icon-d-arrow-right {
        transform: rotate(90deg);
    }
}

.search {
    strong {
        width: 75px;
    }
}

.main .mainr form .el-button {
    position: unset;
}
</style>