<template>
  <div>
    <h2>流程审批</h2>
    <form ref="filters" v-on:submit.prevent>
      <el-row :class="{ on: formOpen }" :gutter="10" class="formInput">
        <el-col :md="7" :sm="10" :xs="20">
          <el-row :gutter="10" justify="space-between" align="middle" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>表单名称</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-input v-model="filters.name" clearable placeholder="请输入表单名称" size="small"></el-input>
            </el-col>
          </el-row>
        </el-col>

        <el-col :md="7" :sm="10" :xs="20">
          <el-row :gutter="10" justify="space-between" align="middle" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>审批状态</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-select v-model="filters.state" clearable filterable placeholder="请选择审批状态" size="small">
                <el-option v-for="item in state_sel" :key="item.id" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>

        <el-col :md="7" :sm="10" :xs="20">
          <el-row :gutter="10" justify="space-between" align="middle" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>发起时间</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-date-picker v-model="filters.created_at" :picker-options="pickerOptions" end-placeholder="结束日期"
                range-separator="至" size="small" start-placeholder="开始日期" type="daterange" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>
        <el-col :md="7" :sm="10" :xs="20" v-if="user.parent_id === 0 && user.type !== 0">
          <el-row :gutter="10" justify="space-between" align="middle" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>发起人</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-select v-model="filters.creator_id" clearable filterable placeholder="请选择发起人" size="small">
                <el-option v-for="item in creator_sel" :key="item.id" :label="item.nickname" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :md="7" :sm="10" :xs="20">
          <el-row :gutter="10" justify="space-between" align="middle" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>审批编号</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-input v-model="filters.number" clearable placeholder="请输入审批编号" size="small"></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :md="7" :sm="10" :xs="20">
          <el-row :gutter="10" justify="space-between" align="middle" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>完成时间</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-date-picker v-model="filters.finished_at" :picker-options="pickerOptions" end-placeholder="结束日期"
                range-separator="至" size="small" start-placeholder="开始日期" type="daterange" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="formOpen">
        <el-col :class="{ on: formOpen }" :span="24">
          <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
        </el-col>
      </el-row>
      <el-button class="search" icon="el-icon-search" size="small" type="primary" @click="search">查询
      </el-button>
      <el-button :class="{ on: formOpen }" class="reset" icon="el-icon-refresh-left" size="small" type="reset"
        @click="reset">重置
      </el-button>
    </form>

    <div class="mainrb">
      <div class="listT p10">
        <el-button icon="el-icon-circle-plus-outline" size="small" type="primary" @click="detail('add')">
          创建审批
        </el-button>

      </div>
      <el-skeleton :count="1" :loading="loading" animated>
        <template slot="template">
          <el-table :class="{ on: !formOpen, ios: $store.state.heights }" :data="approvalList" style="width: 100%">

            <el-table-column label="序号">
              <template>
                <el-skeleton-item variant="p" />
              </template>
            </el-table-column>
            <el-table-column label="审批单">
              <template>
                <el-skeleton-item variant="p" class="w50" />
              </template>
            </el-table-column>
            <el-table-column label="编号" width="180">
              <template>
                <el-skeleton-item variant="p" class="w50" />
              </template>
            </el-table-column>
            <el-table-column label="审批人">
              <template>
                <el-skeleton-item variant="p" class="w50" />
              </template>
            </el-table-column>
            <el-table-column label="状态" width="180">
              <template>
                <el-skeleton-item variant="p" class="w50" />
              </template>
            </el-table-column>
            <el-table-column label="发起时间">
              <template>
                <el-skeleton-item variant="p" class="w50" />
              </template>
            </el-table-column>
            <el-table-column label="完成时间">
              <template>
                <el-skeleton-item variant="p" class="w50" />
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <template>
                  <el-skeleton-item variant="p" class="w50" />
                </template>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-table :class="{ on: !formOpen, ios: $store.state.heights }" :data="approvalList" height="100%"
          style="width: 100%">
          <template slot="empty">
            <el-empty :image-size="80"></el-empty>
          </template>

          <el-table-column label="序号" type="index">
          </el-table-column>
          <el-table-column label="审批单" prop="name" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="编号" prop="number" width="180" :show-overflow-tooltip="true">

          </el-table-column>

          <el-table-column label="审批人">
            <template slot-scope="scope">
              <span v-for="(item, i) in scope.row.flows">
                <span
                  :class="{ 'col-909': !item.approved_at, 'col-red': item.result === 0, 'col-green': item.result === 1 }"
                  class="font-12">{{ item.name }}</span>
                <span class="font-12" v-if="i != scope.row.flows.length - 1">-></span>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span
                :class="{ 'col-909': scope.row.state == -1, 'col-E6A': scope.row.state === 0, 'col-green': scope.row.state === 1 }">
                {{ scope.row.state == -1 ? '已撤销' : (scope.row.state === 0 ? '审批中' : scope.row.state === 1 ? '已完成' : '-')
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="发起时间">
            <template slot-scope="scope">
              {{ scope.row.created_at ? scope.row.created_at.slice(0, 10) : '-' }}
            </template>
          </el-table-column>

          <el-table-column label="完成时间">
            <template slot-scope="scope">
              {{ scope.row.finished_at ? scope.row.finished_at.slice(0, 10) : '-' }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <div slot-scope="scope" class="flex flex-gap5">
              
              <el-button icon="el-icon-edit-outline" size="small" type="text"
                @click="detail(scope.row.id)">详情</el-button>
              <el-button v-if="scope.row.state != -1" icon="el-icon-copy-document" size="small" type="text" v-copy="copy(scope.row)">复制链接</el-button>
              <el-popconfirm title="确定要删除此审批？" @confirm="confirm(scope.row.id, 2)">
                <el-button slot="reference" v-if="scope.row.state == -1" icon="el-icon-delete" class="col-red"
                  size="small" type="text">删除</el-button>
              </el-popconfirm>
              <el-popconfirm title="确定要撤销此审批？" @confirm="confirm(scope.row.id, 1)">
                <el-button slot="reference" v-if="scope.row.state === 0" icon="el-icon-refresh-right" class="col-606"
                  size="small" type="text">撤销</el-button>
              </el-popconfirm>

            </div>
          </el-table-column>
        </el-table>
      </el-skeleton>
      <!-- 分页 -->
      <el-pagination :current-page.sync="filters.page" :page-size="filters.per_page" :page-sizes="[30, 50, 100]"
        :total="pageSize" layout="total, sizes, prev, pager, next, jumper,slot" @size-change="changeRowNum"
        @current-change="list()">
      </el-pagination>
    </div>
    <detail :user="user" @list="list" ref="details" ></detail>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import { flows, flowsDelete, flowsApplyForms, myMessageSub } from '@/api/personal.js'
import detail from './detail'
export default {
  components: {
    detail,
  },
  data() {
    return {
      user: storage.getUser(),
      state_sel: [
        { id: -1, text: '已撤销' },
        { id: 0, text: '审批中' },
        { id: 1, text: '已完成' },
      ],
      creator_sel: [],
      approvalList: [],
      loading: false,
      pickerOptions: {//日期选择器的便利选择对象
        shortcuts: storage.getShortcuts()
      },
      formOpen: false,
      filters: {
        name: '',
        state: '',
        created_at: '',
        creator_id: '',
        number: '',
        finished_at: '',
        page: 1, //默认显示第一页
        per_page: 30, //显示数量

      },
      last_page: 1,
      pageSize: 0, //总条数
      flows_apply:[],
    }
  },
  created() {
    this.list()
    this.flowsApply()
    if (this.user.parent_id === 0 && this.user.type !== 0) {
      this.getCreator()
    }
  },
  methods: {
    getCreator() {
      myMessageSub().then(res => {
        if (res.err_code === 0) {
          this.creator_sel = [...[{ id: this.user.id, nickname: this.user.nickname }, ...res.data]]
        }
      })
    },
    copy(row) {
      return location.origin + '/pages/approval/index?id=' + row.id 
    },
    // 详情
    flowsApply() {
      flowsApplyForms().then(res => {
        if (res.err_code === 0) {
          this.flows_apply = res.data
        }
      })
    },
    search() {
      this.filters.page = 1
      this.list()
    },
    reset() {
      this.filters = this.$options.data().filters
      this.list()
    },
    list() {
      this.loading = true
      try {
        flows(this.filters).then(res => {
          if (res.err_code === 0) {
            this.approvalList = res.data.list
            this.pageSize = res.data.total
            this.loading = false
          }
        })

      } catch {
        this.loading = false
      }


    },
    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.list();
    },
    // 审批申请
    detail(row) {
      if (row == 'add' && this.flows_apply.length == 0) {
        let message = ''
        if (this.user.parent_id != 0 || this.user.type === 0) {
          message = '请先在主账户完善保单设置！'
        } else {
          message = '请先完善保单设置！'
        }
        this.$message({
          message: message,
          type: 'warning'
        });
      } else {
        this.$refs.details.detail(row)
      }

    },
    confirm(id, action) {
      flowsDelete({ id: id, action, action }).then(res => {
        if (action == 1) {
          this.$message({
            message: '撤销成功',
            type: 'success'
          });
        } else {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
        }
        this.list()
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-table__body-wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: all 1s;
  -webkit-transition: all 1s;
}

form {
  .el-col-lg-7 {
    text-align: right;
  }

  .el-col-xl-7 {
    margin: 0 1% 0 0;
  }

  input {
    border: 1px solid rgba(234, 234, 234, 100);
  }

  .search {
    top: 10px;
  }

  .reset {
    top: 52px;
    display: none;
  }

  .reset.on {
    display: block;
  }

  .formInput {
    height: 42px;
  }
}

@media screen and (max-width: 1900px) {
    ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 612px") !important;
  }
  .el-table.on {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 393px") !important;
    }
  }

  .el-table.on.ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 433px") !important;
    }
  }
}



@media screen and (max-width: 768px) {
  ::v-deep .el-table__body-wrapper {
  height: calc(~"100vh - 603px") !important;
}
.ios {
  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 643px") !important;
  }
}
  .el-table.on {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 393px") !important;
    }
  }

  .el-table.on.ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 433px") !important;
    }
  }
  .contentC {
    width: 64%;
  }

  .formInput.on {
    height: 252px;
  }



}
@media screen and (min-width: 768px) {



::v-deep .el-table__body-wrapper {
  height: calc(~"100vh - 466px") !important;
}

.el-table.on {
  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 392px") !important;
  }
}
.ios {
  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 506x") !important;
  }
}
.el-table.on.ios {
  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 432px") !important;
  }
}
.formInput.on {
    height: 116px;
  }
}




@media screen and (min-width: 992px) {
  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 424px") !important;
  }

  .ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 464px") !important;
    }
  }

  .el-col-md-3 {
    width: 12.914%;
  }

  .el-col-md-21 {
    width: 87.086%;
  }

  .formInput.on {
    height: 74px;
  }

}

@media screen and (min-width: 1200px) {

  .el-col-lg-3 {
    width: 9.476%;
  }

  .el-col-lg-21 {
    width: 90.524%;
  }
}

@media screen and (min-width: 1920px) {
  .el-col-xl-2 {
    width: 5.376%;
  }

  .el-col-xl-22 {
    width: 94.624%;
  }
}
</style>