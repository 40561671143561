<template>
    <div class="">
        发票信息
    </div>
</template>
<script>
export default {
    
}
</script>

<style lang="less" scoped>
@import "../../style/InvoiceInfor.less";
</style>