

<template>
  <div class="">
    <div class="mainrT clearfix">
      <h2>
        专利<span v-if="patentList.length != 0">{{ pageSize }} </span>
      </h2>
      <!-- 表单 -->
      <form action="" ref="filters" v-on:submit.prevent>
        <el-row :gutter="10" class="formInput" :class="{on:formOpen}">
          <el-col :xs="23" :sm="10" :md="7" :lg="7" :xl="7">
            <el-row type="flex" align="middle" justify="space-between" :gutter="10">
              <el-col :xs="5" :sm="7" :md="7" :lg="6" :xl="4">
                <strong>发明名称</strong>
              </el-col>
              <el-col :xs="14" :sm="17" :md="17" :lg="18" :xl="20">
                <el-input size="small" clearable v-model="filters.title" placeholder="请输入发明名称"></el-input>
              </el-col>
            </el-row>
          </el-col>

          <el-col :xs="23" :sm="10" :md="7" :lg="7" :xl="7">
            <el-row type="flex" align="middle" justify="space-between" :gutter="10">
              <el-col :xs="5" :sm="7" :md="7" :lg="6" :xl="4">
                <strong>申请号</strong>
              </el-col>
              <el-col :xs="14" :sm="17" :md="17" :lg="18" :xl="20">
                <el-input size="small" clearable v-model="filters.appnumber" placeholder="请输入申请号"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="23" :sm="10" :md="7" :lg="7" :xl="7">
            <el-row type="flex" align="middle" justify="space-between" :gutter="10">
              <el-col :xs="5" :sm="7" :md="7" :lg="6" :xl="4">
                <strong>公开号</strong>
              </el-col>
              <el-col :xs="14" :sm="17" :md="17" :lg="18" :xl="20">
                <el-input size="small" clearable v-model="filters.pubnumber" placeholder="请输入公开号"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="23" :sm="10" :md="7" :lg="7" :xl="7">
            <el-row type="flex" align="middle" justify="space-between" :gutter="10">
              <el-col :xs="5" :sm="7" :md="7" :lg="6" :xl="4">
                <strong>发明人</strong>
              </el-col>
              <el-col :xs="14" :sm="17" :md="17" :lg="18" :xl="20">
                <el-input size="small" clearable v-model="filters.inventor" placeholder="请输入发明人"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="23" :sm="10" :md="7" :lg="7" :xl="7">
            <el-row type="flex" align="middle" justify="space-between" :gutter="10">
              <el-col :xs="5" :sm="7" :md="7" :lg="6" :xl="4"><strong>申请人</strong></el-col>
              <el-col :xs="14" :sm="17" :md="17" :lg="18" :xl="20">
                <el-select
                    clearable
                    size="small"
                    v-model="filters.applicantname"
                    filterable
                    placeholder="请选择申请人"
                >
                  <el-option
                      v-for="item in applicantnameSelect"
                      :key="item.text"
                      :label="item.text"
                      :value="item.text"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>

          </el-col>
          <el-col :xs="23" :sm="10" :md="7" :lg="7" :xl="7">
            <el-row type="flex" align="middle" justify="space-between" :gutter="10">
              <el-col :xs="5" :sm="7" :md="7" :lg="6" :xl="4"><strong>专利类型</strong></el-col>
              <el-col :xs="14" :sm="17" :md="17" :lg="18" :xl="20">
                <el-select
                    clearable
                    size="small"
                    v-model="filters.patent_type"
                    filterable
                    placeholder="请选择专利类型"
                >
                  <el-option
                        v-for="item in patentSelect.select"
                      :key="item.value"
                      :label="item.value"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>

          </el-col>
          <el-col :xs="23" :sm="10" :md="7" :lg="7" :xl="7">
            <el-row type="flex" align="middle" justify="space-between" :gutter="10">
              <el-col :xs="5" :sm="7" :md="7" :lg="6" :xl="4" ><strong>法律状态</strong></el-col>
              <el-col :xs="14" :sm="17" :md="17" :lg="18" :xl="20">
                <el-select
                    clearable
                    size="small"
                    v-model="filters.law_status"
                    filterable
                    placeholder="请选择法律状态"
                >
                  <el-option
                      v-for="item in statusSelect.select"
                      :key="item.value"
                      :label="item.value"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>

          </el-col>
          <el-col :xs="23" :sm="10" :md="7" :lg="7" :xl="7">
            <el-row type="flex" align="middle" justify="space-between" :gutter="10">
              <el-col :xs="5" :sm="7" :md="7" :lg="6" :xl="4">
                <strong>申请日期 </strong>
              </el-col>
              <el-col :xs="14" :sm="17" :md="17" :lg="18" :xl="20">
                <el-date-picker
                    size="small"
                    v-model="filters.application_time"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="23" :sm="10" :md="7" :lg="7" :xl="7">
            <el-row type="flex" align="middle" justify="space-between" :gutter="10">
              <el-col :xs="5" :sm="7" :md="7" :lg="6" :xl="4">
                <strong>公开日期</strong>
              </el-col>
              <el-col :xs="14" :sm="17" :md="17" :lg="18" :xl="20">
                <el-date-picker
                    size="small"
                    v-model="filters.pub_date"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-col>
            </el-row>
          </el-col>

        </el-row >
        <el-row class="formOpen">
          <el-col  :xs="24"  :class="{on:formOpen}" >
            <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
          </el-col>
        </el-row>
        <el-button
          type="primary"
          @click="perindexList(filters.page = 1)"
          class="search"
          size="small"
          icon="el-icon-search"
          >查询</el-button
        >
        <el-button type="reset" :class="{on:formOpen}" @click="reset" size="small" class="reset" icon="el-icon-refresh-left"
          >重置</el-button
        >
      </form>
    </div>

    <div class="mainrb">
      <div class="mainrB-t">
        <div class="page-up">
          <el-pagination
              background
              size="small"
              layout="prev, next"
              @current-change="perindexList"
              :current-page.sync="filters.page"
              :page-sizes="[30, 50, 100]"
              :page-size="filters.per_page"
              :total="pageSize">
          </el-pagination>
        </div>
      </div>
      <!-- 表格 -->
      <el-skeleton  :loading="loading" animated :count="1">
        <template slot="template">
          <el-table
              :class="{on:!formOpen,ios:$store.state.heights}"
              :data="patentList"
              :default-sort="{ prop: 'appdate', order: 'ascending ' }"
          >
            <el-table-column  width="50" label="序号">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>

            <el-table-column label="发明名称">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="专利类型">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="法律状态">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="申请号">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="申请日">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="公开(公告)号" width="120" >
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="公开(公告)日期" width="120">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="发明人">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-table
            :class="{on:!formOpen,ios:$store.state.heights}"
            :data="patentList"
            height="100%"
            :default-sort="{ prop: 'appdate', order: 'ascending ' }"
        >
          <template slot="empty">
            <el-empty :image-size="80"></el-empty>
          </template>
          <el-table-column type="index" width="50" label="序号">
          </el-table-column>

          <el-table-column label="发明名称" :show-overflow-tooltip="true" prop="title">
          </el-table-column>
          <el-table-column label="申请人" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <router-link v-if="scope.row.client_id != 0" :to="{
                  name: 'newlyBuild',
                    params: { id:  scope.row.client_id  },
                }" target="_blank">
                {{scope.row.applicantname}}
              </router-link>
              <template v-else>
                {{scope.row.applicantname}}
              </template>
            </template>
          </el-table-column>
          <el-table-column label="专利类型" prop="patent_type"></el-table-column>
          <el-table-column label="法律状态" >
            <template slot-scope="scope">
              <el-tag size="mini" :type="scope.row.law_status == '公布' ? 'success' :'' ">{{scope.row.law_status}} </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="申请号" prop="appnumber" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column label="申请日" width="100" prop="application_time"> </el-table-column>
          <el-table-column label="公开(公告)号" width="120" >
            <template slot-scope="scope">
              {{scope.row.pubnumber || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="公开(公告)日期" width="120"  >
            <template slot-scope="scope">
              {{scope.row.pub_date || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="发明人" prop="inventor" :show-overflow-tooltip="true"> </el-table-column>
          <el-table-column label="操作" width="100" fixed="right">
            <template slot-scope="scope">
              <router-link
                  :to="{
                name: 'patentDetails',
                params: { id: scope.row.id },
              }"
                  target="_blank"
              >
                <el-button size="small" type="text" icon="el-icon-edit-outline">详情</el-button>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </el-skeleton>
      <!-- 分页 -->
      <el-pagination
        @size-change="changeRowNum"
        @current-change="perindexList(backTop)"
        :current-page.sync="filters.page"
        :page-sizes="[30, 50, 100]"
        :page-size="filters.per_page"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageSize"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import { patent } from '@/api/personal.js'
export default {
  data() {
    return {
      formOpen:false,
      backTop:true,
      loading: true,//控制列表的骨架屏
      pickerOptions: {//时间选择器的便利选择对象
        shortcuts:storage.getShortcuts(),
      },
      patentList: [],//专利信息数组
      //专利信息搜索表单的字段
      filters: {
        title:'',
        appnumber:'',
        applicantname:'',
        pubnumber:'',
        inventor:'',
        application_time: "", //申请日期
        pub_date: "", //公开(公告)日期
        patent_type: "", //专利类型
        law_status: "", //法律状态
        page: 0, //默认显示第一页
        per_page: 30, //显示数量
      },
      patentSelect: {// 专利类型,下拉所需的内容
        select: [{ id: "01", value: "发明专利" },{ id: "02", value: "外观专利" },{ id: "03", value: "实用新型" }],
      },
      statusSelect: {// 法律状态,下拉所需的内容
        select: [{ id: "01", value: "公布" }, {id: "02", value: "授权" }],
      },
      applicantnameSelect:[],
      pageSize: 0, //总条数

    };
  },
  created() {
    this.perindexList();
    this.select()
  },

  methods: {
    // 下拉
    async select(){
      let s = await storage.getSelects('app_names')
      this.applicantnameSelect = s.app_names
    },
    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.perindexList(this.backTop);
    },
    //渲染列表事件
    async perindexList(backTop) {
      this.loading = true;
      this.patentList = storage.getList()
      try{
        const res = await patent(this.filters)
        if(res && res.err_code === 0){
          if(backTop === true){
            storage.backTop()
          }
          this.loading = false;
          this.patentList = res.data.list;
          this.pageSize = res.data.total;

        }else {
          this.patentList = []
        }
      }finally{
        this.loading = false;

      }
    },

    //重置事件
    reset() {
      this.filters = this.$options.data().filters;
      this.perindexList()
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/patent.less";
</style>
