<template>
  <div class="">
    <h2>
      使用证据<span v-if="list.length != 0">{{ pageSize }} </span>
    </h2>
    <form ref="acvhives" action="" v-on:submit.prevent>
      <el-row :class="{on:formOpen}" :gutter="10" class="formInput">
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="10">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="6" :md="7" :sm="8" :xl="4" :xs="6"><strong>商标品牌</strong></el-col>
            <el-col :lg="18" :md="17" :sm="15" :xl="20" :xs="18">
              <el-input
                  v-model="filters.tmname"
                  clearable
                  placeholder="请输入商标名称"
                  size="small"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="10">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="6" :md="7" :sm="8" :xl="4" :xs="6"><strong>证据类型</strong></el-col>
            <el-col :lg="18" :md="17" :sm="15" :xl="20" :xs="18">
              <el-select
                  v-model="filters.types"
                  clearable
                  filterable
                  placeholder="请选择官文类型"
                  size="small"
              >
                <el-option
                    v-for="item in typeSelect"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="10">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="6" :md="7" :sm="8" :xl="4" :xs="6"><strong>使用时间</strong></el-col>
            <el-col :lg="18" :md="17" :sm="15" :xl="20" :xs="18">
              <el-date-picker
                  v-model="filters.usedate"
                  :picker-options="getMouch"
                  align="right"
                  end-placeholder="结束月份"
                  range-separator="至"
                  start-placeholder="开始月份"
                  type="monthrange"
                  unlink-panels>
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="10">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="6" :md="7" :sm="8" :xl="4" :xs="6"><strong>标签</strong></el-col>
            <el-col :lg="18" :md="17" :sm="15" :xl="20" :xs="18">
              <el-select
                  v-model="filters.tags"
                  clearable
                  filterable
                  placeholder="请选择标签"
                  size="small"
              >
                <el-option
                    v-for="item in tipSelect"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>

        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="10">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="6" :md="7" :sm="8" :xl="4" :xs="6"><strong>上传日期</strong></el-col>
            <el-col :lg="18" :md="17" :sm="15" :xl="20" :xs="18">
              <el-date-picker
                  v-model="filters.created_at"
                  :picker-options="pickerOptions"
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="formOpen">
        <el-col :class="{on:formOpen}" :span="24">
          <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
        </el-col>
      </el-row>
      <el-button class="search" icon="el-icon-search" size="small" type="primary" @click="lists(filters.page = 1)">
        查询
      </el-button>
      <el-button :class="{on:formOpen}" class="reset" icon="el-icon-refresh-left" size="small" type="reset"
                 @click="reset">
        重置
      </el-button>
    </form>

    <div class="mainrb">
      <div class="managementBt clearfix">
        <router-link to="/personal/evidUpload">
          <el-button
              class="search"
              icon="el-icon-upload2"
              size="small"
              type="primary"
          >
            批量上传
          </el-button>
        </router-link>

        <span>
          <el-button v-if="down" :disabled="array.ids.length == 0" icon="el-icon-download" size="small"
                     @click="download">批量下载</el-button>
          <el-button v-else :loading="true" size="small">下载中</el-button>
        </span>
        <el-button type="primary" icon="el-icon-document" size="small" plain>合成PDF</el-button>
        <div class="page-up m-r10">
          <el-button icon="el-icon-setting" @click="darg" size="small">水印设置</el-button>
        </div>
      </div>
      <!-- 表格 -->
      <el-skeleton :count="1" :loading="loading" animated>
        <template slot="template">
          <el-table
              :class="{on:!formOpen,ios:$store.state.heights}"
              :data="loadList"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="序号" width="50">
              <template>
                <el-skeleton-item class="w80" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="文件名称" width="">
              <template>
                <el-skeleton-item class="w80" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="商标品牌" width="">
              <template>
                <el-skeleton-item class="w80" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="证据类型" width="">
              <template>
                <el-skeleton-item class="w80" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="标签">
              <template>
                <el-skeleton-item class="w80" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="大小">
              <template>
                <el-skeleton-item class="w80" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="使用时间">
              <template>
                <el-skeleton-item class="w80" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="上传日期">
              <template>
                <el-skeleton-item class="w80" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template>
                <el-skeleton-item class="w80" variant="p"/>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-table
            :class="{on:!formOpen,ios:$store.state.heights}"
            :data="list"
            height="100%"
            ref="tableRef"
            @selection-change="handleSelectionChange"
            @sort-change="sort_change"
        >
          <template slot="empty">
            <el-empty :image-size="80"></el-empty>
          </template>
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column label="文件名称">
            <template slot-scope="scope">
              <p class="col-blue pointer">{{ scope.row.name }}</p>
            </template>
          </el-table-column>
          <el-table-column label="商标品牌" prop="brand" width=""></el-table-column>
          <el-table-column label="证据类型" prop="type" width=""></el-table-column>
          <el-table-column label="标签" prop="label"></el-table-column>
          <el-table-column label="大小" prop="size"></el-table-column>
          <el-table-column label="使用时间" sortable="custom" prop="created_at" width="110"></el-table-column>
          <el-table-column label="上传日期" sortable="custom" prop="created_at" width="110"></el-table-column>
          <el-table-column fixed="right" label="操作" width="170">
            <template slot-scope="scope">
              <div class="operation">
                <el-button icon="el-icon-download" size="small" type="text" @click="openUrl(scope.row.url)">下载
                </el-button>
                <!--              v-if="scope.row.is_custom == 1"-->
                <el-button class="delete" icon="el-icon-delete" size="small" type="text"
                           @click="deleteFile(scope.row.id)">删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-skeleton>

      <!-- 分页 -->
      <el-pagination
          :current-page.sync="filters.page"
          :page-size="filters.per_page"
          :page-sizes="[30, 50, 100]"
          :total="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="changeRowNum"
          @current-change="lists(backTop)"
      >
      </el-pagination>
    </div>

<!--    水印设置-->
    <el-dialog
        :closeOnClickModal="false"
        title="水印设置"
        :visible.sync="watermarkVisible"
        class="water_dia"
        @open="openDia"
    >
      <el-form :model="waterForm" :rules="waterRules" label-width="100px" ref="waterForm">
        <el-form-item label="开启水印" prop="delivery">
          <el-switch v-model="waterForm.delivery"></el-switch>
        </el-form-item>
        <el-form-item label="水印内容" prop="name">
          <el-input   placeholder="请输入水印内容" size="small" v-model="waterForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small"  @click="watermarkVisible = false">关 闭</el-button>
        <el-button :loading="waterLoad" size="small" type="primary" @click="submit('waterForm')">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import {evidences,getWatermarkSetting,saveWatermarkSetting,} from '@/api/personal.js'
export default {
  name: "evidence",
  data() {
    return {
      waterLoad:false,//控制水印设置保存按钮加载状态
      waterForm:{
        delivery:true,
        name:'',
      },//水印设置字段
      waterRules:{
        name: [
          { required: true, message: ' ', trigger: 'blur' },
        ],
      },//水印设置表单验证规则
      watermarkVisible:false,
      pickerOptions: {//时间选择器的便利选择对象
        shortcuts: storage.getShortcuts()
      },
      getMouch: {
        shortcuts: storage.getMouch()
      },
      loadList: storage.getList(),//骨架屏的起始数组（10条数据）
      typeSelect: ['销售凭证', '广告宣传', '销售数据', '媒体报道', '榜单排行', '荣誉奖项', '裁判文书'],
      tipSelect: ['合同', '发票', '海报', '报告', '奖牌', '证书', '照片', '截图', '文档', '其他'],
      authorSelect: [],
      list: [
        {
          name: '梦之蓝广告发票.jpg',
          brand: '海之蓝',
          type: '广告宣传',
          label: '合同、发票',
          size: '873k',
          created_at: '2022-02-03',
        }
      ],
      backTop: true,
      last_page: 1,
      pageSize: 0, //总条数
      formOpen: false,//控制搜索栏展开或收起
      filters: {
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
        tmname:'',//商标品牌
        types:[],//证据类型
        usedate:[],//使用时间
        tags:[],//标签
        created_at:[],//上传时间

        srot:'',

      },//搜索关键字

      down: true,//控制导出中的显示与隐藏
      loading: true,//控制列表的骨架屏
      array: {
        ids: [],
      }, //批量下载的数组
      deleteId: {
        id: '',
      },//删除的id
    }
  },
  created() {
    this.lists()
  },
  methods: {
    // 水印
    darg(){
      this.watermarkVisible = true
      getWatermarkSetting().then(res =>{
        console.log(res)
      })
    },
//排序事件
    sort_change(column) {
      if (column.column.label == '使用时间') {
        if (column.order === "descending") {
          this.filters.sort = "usedate|desc";
        } else {
          this.filters.sort = "usedate|asc";
        }
      } else {
        if (column.order === "descending") {
          this.filters.sort = "regdate|desc";
        } else {
          this.filters.sort = "regdate|asc";
        }
      }

      this.lists();
    },
    openDia() {
      this.waterForm = this.$options.data().waterForm;
      this.$refs.waterForm.resetFields();
      this.waterLoad = false
    },
    submit(formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          saveWatermarkSetting({enable:1,content:'123'}).then(res =>{
            this.waterLoad = true
            console.log(res)
          })
          this.watermarkVisible = false
        } else {
          return false;
        }
      });
    },

    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.lists(this.backTop);
    },
    //列表
    lists() {
      this.loading = true
      evidences(this.filters).then(res =>{
        this.loading = false
        console.log(res)
      })
    },
    //重置
    reset() {
      this.filters = this.$options.data().filters;
    },

    //下载
    download() {
      this.down = false
      setTimeout(() => (
          this.$message({
            message:'下载成功',
            type: 'success'
          }),
          this.down = true,
      this.$refs.tableRef.clearSelection()
      ), 2000)
    },
    //记录选择要下载的id事件
    handleSelectionChange(val) {
      this.array.ids = [];
      val.forEach((item) => {
        this.array.ids.push(item.id);
      });
    },

    openUrl(url) {
      // location.href = url
    },
    //官文删除事件
    deleteFile(item) {
      this.deleteId.id = item
      try {
        this.$confirm('确定要删除该官文吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          this.list.splice(this.list.indexOf(item), 1)
          // await managementDelete(this.deleteId).then(res => {
          //   if (res && res.err_code === 0) {
          //     this.$message({
          //       type: "success",
          //       message: "官文已删除！",
          //     });
          //     this.lists(this.filters.page = 1)
          //   } else {
          //     this.$message({
          //       type: "error",
          //       message: res.err_msg,
          //     });
          //   }
          // })
        })

      } finally {

      }
    },
  }
}
</script>

<style lang="less" scoped>
@import "../../style/management.less";
@media screen and (max-width: 992px){
  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 487px") !important;
  }

  .ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 527px") !important;
    }
  }

  .el-table.on {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 403px") !important;
    }
  }

  .el-table.on.ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 443px") !important;
    }
  }
}
@media screen and (min-width: 992px) {
  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 435px") !important;
  }

  .ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 475px") !important;
    }
  }

  .el-table.on {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 403px") !important;
    }
  }

  .el-table.on.ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 443px") !important;
    }
  }

}
::v-deep .water_dia{
  .el-dialog{
    width: 475px;
    .el-dialog__body{
      padding: 20px;
      .el-form{
        margin: 0;
        padding: 0;
        display: grid;
        grid-row-gap: 30px;
        .el-form-item {
          margin: 0;
        }
      }
    }
  }


}
</style>