<template>
  <div class="">
    <el-skeleton  :loading="loading" animated :count="1">
      <template slot="template">
        <el-skeleton-item variant="h3" style="width:200px" />
        <div class="el-steps">
          <el-skeleton-item variant="h3"/>
          <el-skeleton-item variant="h3" style="margin: 0 20px"/>
          <el-skeleton-item variant="h3"/>
        </div>

        <table width="100%" class="domesticDetailst-cont">
        <tr>
          <td>商标名称</td>
          <td ><el-skeleton-item variant="p" /> </td>
          <td >商标类别：</td>
          <td><el-skeleton-item variant="p" /></td>
        </tr>
        <tr>
          <td>申请/注册号：</td>
          <td>
            <font><el-skeleton-item variant="p" /></font>
          </td>
          <td>商标类型：</td>
          <td><el-skeleton-item variant="p" /></td>
        </tr>
        <tr>
          <td>商标状态：</td>
          <td><el-skeleton-item variant="p" /></td>
          <td>申请人：</td>
          <td><el-skeleton-item variant="p" /> </td>
        </tr>
        <tr>
          <td rowspan="2" >商标图片</td>
          <td rowspan="2" class="img">
            <el-skeleton-item variant="image" style="width: 120px;height: 120px;margin: auto" />
            <p>点击查看大图</p>
          </td>
          <td rowspan="1">申请日期：</td>
          <td rowspan="1">
            <el-skeleton-item variant="p" />
          </td>

        </tr>

        <tr>
          <td>商标说明：</td>
          <td><el-skeleton-item variant="p" /></td>
        </tr>


        <tr>
          <td>商品服务：</td>
          <td colspan="3">
            <el-skeleton-item variant="p" />
          </td>
        </tr>
        </table>
        <div class="applicationDetails-list" >
          <h3>
            官文信息
            <div class="">
              <el-button size="small" @click="batchDownload"> 批量下载 </el-button>
            </div>
          </h3>
          <el-table
              ref="multipleTable"
              :data="myApplicationList"
              tooltip-effect="dark"
              style="width: 100%"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column type="index" label="序号" width="50">
              <template>
                <el-skeleton-item variant="p" style="width: 50px"/>
              </template>
            </el-table-column>
            <el-table-column prop="tradeName" label="官文名称">
              <template>
                <el-skeleton-item variant="p" />
              </template>
            </el-table-column>
            <el-table-column prop="time" label="上传时间">
              <template>
                <el-skeleton-item variant="p" />
              </template>
            </el-table-column>
            <el-table-column label="下载" width="50">
              <template>
                <img
                    src="@/assets/img/personal/icon-domesticDetailst-down.png"
                    alt=""
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    <h2>申请详情</h2>
      <el-steps :active="active" align-center>
        <el-step v-for="item in timeArr"  :title="item.name" :icon="item.icon" :description="item.time"></el-step>
      </el-steps>

      <table width="100%" class="domesticDetailst-cont">
        <tr>
          <td>商标名称</td>
          <td >AAAAAAAAAAAAAAAAAA</td>
          <td >商标类别：</td>
          <td>25类 服装鞋帽</td>
        </tr>
        <tr>
          <td>申请/注册号：</td>
          <td>
            <font>57804032</font>
          </td>
          <td>商标类型：</td>
          <td>文字商标</td>
        </tr>
        <tr>
          <td>商标状态：</td>
          <td>申请中</td>
          <td>申请人：</td>
          <td>温州艾芮卡电子商务有限公司 </td>
        </tr>
        <tr>
          <td rowspan="2" >商标图片</td>
          <td rowspan="2" class="img">
            <el-image :src="tmimage" :preview-src-list="[tmimage]">
            </el-image>
            <p>点击查看大图</p>
          </td>
          <td rowspan="1">申请日期：</td>
          <td rowspan="1">
            <font>2021-07-19</font>
          </td>
        </tr>
        <tr>
          <td>商标说明：</td>
          <td>无</td>
        </tr>
        <tr>
          <td>商品服务：</td>
          <td colspan="3">
            2501 成品衣  2501 服装  2502 婴儿全套衣  2502 服装
            2503 游泳衣  2504 服装  2505 服装
            2507 鞋(脚上的穿着物)  2508 帽  2509 袜
            2510 手套(服装)  2511 围巾  2512 皮带(服饰用)
          </td>
        </tr>
      </table>

    <div class="applicationDetails-list" style="margin: 20px 0;
    box-shadow: 0px 0px 4px 1px rgba(199, 219, 247, 40);
    border-radius: 5px;
    background: #fff;
    width: 100%;
    padding-bottom: 5px;">
      <h3>
        官文信息
        <div class="">
          <el-button v-if="down" size="small" @click="batchDownload"> 批量下载 </el-button>
          <el-button  v-else size="small" :loading="true">下载中</el-button>
        </div>
      </h3>
      <el-table
        ref="multipleTable"
        :data="myApplicationList"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="tradeName" label="官文名称"> </el-table-column>
        <el-table-column prop="time" label="上传时间"> </el-table-column>
        <el-table-column label="下载" width="50">
          <template>
            <img
              src="@/assets/img/personal/icon-domesticDetailst-down.png"
              alt=""
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    </el-skeleton>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import tmimage from '@/assets/img/download.png'
export default {
  data() {
    return {
      down:true,
      tmimage:tmimage,
      timeArr:[
        {name:'提交',time:'2021年09月22日',icon:'el-icon-upload'},
        {name:'付款',time:'2021年10月22日',icon:'el-icon-money'},
        {name:'提交',time:'2021年11月22日',icon:'el-icon-s-promotion\n'},

      ],

      textarea:'',
      active:2,
      loading:true,
      id: this.$route.query.id,
      ids: [],
      myApplicationList: [],
    };
  },
  created() {
    this.myApplicationLists();
  },
  methods: {
    async myApplicationLists() {
      this.loading = true
      this.myApplicationList = storage.getList()
      await this.$http.post("/api/myApplication").then((res) => {
        setTimeout(() => (this.loading = false), 1000)
        this.myApplicationList = res.data.list;
      }).finally(() => {
        setTimeout(() => (this.loading = false), 1000)
      });
    },
    batchDownload() {
      if (this.ids.length != 0) {
        this.down = false
      } else {
        this.$message({
          type: "warning",
          message: "请选中要下载的官文序号!",
        });
      }
    },
    handleSelectionChange(val) {
      this.ids = [];
      val.forEach((item) => {
        this.ids.push(item.id);
      });
    },
  },
};
</script>


<style lang="less" scoped>
@import "../../style/applicationDetails.less";

</style>
