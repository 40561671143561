<template>
    <div class="">
        <div class="trademarkClass-ban">
            <div class="container">商标分类</div>
        </div>
        
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="less" scoped>
@import "../../style/trademarkClass.less";
</style>