<template>
  <div class="">
    <h2>商标注册</h2>
    <div class="registert">
      <ul>
        <li>填写商标信息</li>
        <li>填写申请人信息</li>
        <li>支付订单费用</li>
      </ul>
    </div>
    <div class="payOrder">
      <div class="payOrder-information">
        <h3>商标信息</h3>
        <div class="">
          <img src="@/assets/img/personal/payOrder-logo.png" alt="" />
          <p>商标图样</p>
        </div>
        <ul>
          <li><strong>商标名称：</strong><span>偶你咋</span></li>
          <li><strong>商标类型：</strong><span>图片</span></li>
          <li><strong>商标说明：</strong><span>该商标文字为：偶你咋</span></li>
        </ul>
      </div>
      <div class="payOrder-category">
        <h3>商标类别</h3>
        <ul>
          <li>
            <h4>09 科学仪器</h4>
            <div class="category-project">
              <p>商品服务项目:</p>
              <div class="">
                <span>C090145 人脸识别设备</span>
              </div>
            </div>
            <div class="category-file">
              <p>官方回执文件:</p>
              <span>暂无</span>
            </div>
          </li>
          <li>
            <h4>09 科学仪器</h4>
            <div class="category-project">
              <p>商品服务项目:</p>
              <div class="">
                <span>C090145 人脸识别设备</span>
                <span>C090145 人脸识别设备</span>
                <span>C090145 人脸识别设备</span>

                <span>C090145 人脸识别设备</span>
                <span>C090145 人脸识别设备</span>
                <span>C090145 人脸识别设备</span>
                <span>C090145 人脸识别设备</span>
                <span>C090145 人脸识别设备</span>
              </div>
            </div>
            <div class="category-file">
              <p>官方回执文件:</p>
              <span>暂无</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="payOrder-documents">
        <h3>商标信息<span>上传材料注意事项</span></h3>
        <ul>
          <li>
            <img src="@/assets/img/personal/upload1.png" alt="" />
            <h4>营业执照</h4>
            <p>（需盖公章）</p>
          </li>
          <li>
            <img src="@/assets/img/personal/upload2.png" alt="" />
            <h4>委托书</h4>
            <span>生成委托书</span>
          </li>
          <li>
            <img src="@/assets/img/personal/upload3.png" alt="" />
            <h4>优先权证明(非必传)</h4>
          </li>
        </ul>
      </div>
      <div class="payOrder-applicant">
        <h3>申请人信息-(中国大陆-企业)</h3>
        <ul>
          <li>
            <strong>公司名称： </strong
            ><span>{{applicantName}}</span>
          </li>
          <li>
            <strong>统一社会信用代码： </strong><span>{{applicantRegion}}</span>
          </li>
          <li>
            <strong>公司地址： </strong><span>中山南路8号苏豪大厦15楼</span>
          </li>
          <li><strong>邮政编码： </strong><span>210000</span></li>
        </ul>
      </div>
      <div class="payOrder-contact">
        <h3>联系人信息</h3>
        <ul>
          <li><strong>联系人：</strong><span>犹㼆</span></li>
          <li><strong>手机：</strong><span>13331451976</span></li>
          <li><strong>邮箱：</strong><span>2352997062@qq.com</span></li>
          <li><strong>座机：</strong><span>2352997062@qq.com</span></li>
        </ul>
      </div>
      <div class="payOrder-payment">
        <h3>付款信息</h3>
        <ul>
          <li><strong>实付金额：</strong><span>￥2247.00 </span></li>
          <li><strong>支付时间：</strong><span>--</span></li>
          <li><strong>支付方式： </strong><span>未付款</span></li>
        </ul>
      </div>
      <div class="payOrder-remarks">
        <h3>订单备注</h3>
        <div class="">
          <textarea name="" id="" cols="30" rows="10"></textarea>
        </div>
      </div>
    </div>

    <div class="statistics clearfix">
      <div class="statisticsl fl">
        <p>
          应付金额：<font>￥ 2247.00 </font>
          <span
            >（ 业务总价: <font>￥ 2247.00</font> 增值服务:
            <font>￥ 0.00</font> ）</span
          >
        </p>
        <p>已选择<font>7</font>大类，共<font>65</font> 个小项</p>
      </div>
      <div class="statisticsr fr">
        <span @click="preservation">保存订单</span>
        <span class="on" @click="next">立即付款</span>
      </div>
    </div>

    <div class="preservation" v-show="preservationSH">
      <p>
        <img
          src="@/assets/img/personal/icon-preservation-gou.png"
          alt=""
        />保存成功，可进入个人中心-订单管理查找订单
      </p>
    </div>
  </div>
</template>


<script>
import storage from "@/utils/storage.js";
export default {
  data() {
    return {
      preservationSH: false,
      preservationSHTime: 3,
      applicantName:'',
      applicantRegion:'',
    };
  },
  created() {
    this.applicantName = JSON.parse(storage.getItem("information")).code
    this.applicantRegion = JSON.parse(storage.getItem("information")).region
  },
  methods: {
    preservation() {
      let clock = window.setInterval(() => {
        this.preservationSHTime--;
        this.preservationSH = true;
        
        if (this.preservationSHTime == 0) {
            console.log(this.preservationSHTime)
            this.preservationSHTime = 3
            
          this.preservationSH = false;
          window.clearInterval(clock);
        }
      }, 1000);
    },
    next(){
      this.$router.push('/personal/handling/cashier')
    }
  },
};
</script>


<style lang="less" scoped>
@import "../../style/payOrder.less";
</style>