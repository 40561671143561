<template>
  <div class="journal">
    <div class="journal-txt">
      <h1>更新日志</h1>
    </div>
    <el-skeleton :loading="loading" animated :count="6" >
      <template slot="template" >

        <div class="body-item">
          <el-skeleton-item variant="image" style="width: 100%; height: 190px;" />
          <h4>
            <span><el-skeleton-item variant="p" style="width: 20%" /></span>
            <span><el-skeleton-item variant="p" style="width: 40%" /></span>
          </h4>
          <p><el-skeleton-item variant="p" style="width: 50%" /></p>
          <h5> <el-skeleton-item variant="p" style="width: 30%" /></h5>

        </div>
      </template>


    </el-skeleton>
    <div v-if="!loading" class="journal-body">

      <div class="body-item" v-for="item in list" :key="item.id">
        <router-link :to="{name: 'journalDetail',params: { id:item.id}}" target="_blank">
          <img alt="" src="@/assets/img/personal/journal1.png">
          <h4>
            <span>{{ item.version || '-'}}</span>
            <span>{{ item.title || '-'}}</span>
          </h4>
          <p v-html="item.summary"></p>
          <h5>{{ item.created_at || '-'}}</h5>
        </router-link>

      </div>

    </div>
    <div class="journal-page">
      <span :class="{on:page == 1}" @click="pages('per')">上一页</span>
      <span><font>{{ page }}</font>/{{ total }}</span>
      <span :class="{on:page == total}" @click="pages('next') && page != total">下一页</span>
    </div>
  </div>
</template>
<script>
import storage from '@/utils/storage'
import {updateLogs} from '@/api/personal.js'
export default {
  data(){
    return{
      total:1,
      page:1,
      list:[],
      loading:true,
    }

  },
  created() {
    this.lists()
  },
  methods:{
    pages(item){
      if(item == 'per'){
        if(this.page == 1){
          this.page = 1
        }else{
          this.page--
          this.lists()
        }
      }else{
        if(this.page == this.total){
          this.page = this.total
        }else{
          this.page++
          this.lists()
        }
      }
    },
   async lists(){
     this.loading = true
      try {
        const res = await updateLogs({page:this.page})
        if(res && res.err_code == 0){
          this.loading = false
          this.list = res.data.list
          this.total = res.data.last_page
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      }finally {
        this.loading = false
      }

    },
  }

}
</script>
<style lang="less" scoped>
.journal {
  min-height:  calc(100vh - 100px);
  margin-top: 10px;
  background: url("../../assets/img/personal/ba-client.png");
  background-size: 100% 100%;
  position: relative;
  .journal-txt {
    margin-bottom: 40px;
    text-align: center;
    padding-top: 40px;

    h1 {
      font-size: 48px;
      font-weight: bold;
    }
  }
  .journal-body,::v-deep .el-skeleton {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    .body-item {
      margin-bottom: 20px;
      padding: 20px 15px 40px;
      background: #fff;
      box-shadow: 0px 0px 7px 1px rgba(211, 230, 249, 100);
      border-radius: 5px;
      position: relative;
      img{
        width: 100%;
      }
      h4{
        color: #3894FF;
        margin: 10px 0;
        font-size: 16px;
        span{
          margin-right: 20px;
        }
      }
      p{
        display: -webkit-box;//谷歌
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp:3;
      }
      h5{
        position: absolute;
        bottom: 20px;
        right: 30px;
        color: #999999;
        font-size: 12px;
      }
    }

  }
  .journal-page{
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
      cursor: pointer;
      font{
        color: #3894FF;
      }
    }
    span.on{
      cursor:not-allowed;
      color: #c0c4cc;
    }
  }
}
@media screen and (max-width: 768px){
  .journal {
    padding-bottom: 50px;
    .journal-body,::v-deep .el-skeleton {
      justify-content: space-between;
      width: 80%;
      .body-item{

        width: 49%;
      }

    }
  }
}
@media screen and (min-width: 768px) {
  .journal {
    padding-bottom: 50px;
    .journal-body,::v-deep .el-skeleton {
      justify-content: space-between;
      width: 80%;
      .body-item{
        width: 49%;
      }

    }
  }
}
@media screen and (min-width: 1200px) {
  .journal {
    .journal-body,::v-deep .el-skeleton {
      justify-content: unset;
      width: 90%;
      .body-item{
        margin-right: 20px;
        width: 32%;
      }
      .body-item:nth-of-type(3n){
        margin-right: 0;
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .journal {
    .journal-body,::v-deep .el-skeleton {
      width: 70%;
      .body-item{
        width: 380px;
      }
    }
  }
}
</style>