<template>
  <div class="">
    <div class="mainrT">
      <h2>
        作品著作权<span v-if="copyrightList.length != 0">{{ pageSize }} </span>
      </h2>
    </div>

    <form ref="filters clearfix" action="" v-on:submit.prevent>
      <el-row :gutter="10" class="formInput" :class="{on:formOpen}">
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="10">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="6" :md="7" :sm="8" :xl="4" :xs="6"><strong>作品名称</strong></el-col>
            <el-col :lg="18" :md="17" :sm="15" :xl="20" :xs="18">
              <el-input
                  v-model="filters.fullname"

                  clearable
                  placeholder="请输入作品名称"
                  size="small"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="10">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="6" :md="7" :sm="8" :xl="4" :xs="6"><strong>登记号</strong></el-col>
            <el-col :lg="18" :md="17" :sm="15" :xl="20" :xs="18">
              <el-input
                  v-model="filters.regnum"

                  clearable
                  placeholder="请输入登记号"
                  size="small"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="10">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="6" :md="7" :sm="8" :xl="4" :xs="6"><strong>著作权人</strong></el-col>
            <el-col :lg="18" :md="17" :sm="15" :xl="20" :xs="18">
              <el-select
                  v-model="filters.author_nationality"

                  clearable
                  filterable
                  placeholder="请选择著作权人"
                  size="small"
              >
                <el-option
                    v-for="item in authorSelect"
                    :key="item.text"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="10">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="6" :md="7" :sm="8" :xl="4" :xs="6"><strong>作品类别</strong></el-col>
            <el-col :lg="18" :md="17" :sm="15" :xl="20" :xs="18">
              <el-select
                  v-model="filters.type"

                  clearable
                  filterable
                  placeholder="请选择作品类别"
                  size="small"
              >
                <el-option
                    v-for="item in typeSelect"
                    :key="item.text"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="10">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="6" :md="7" :sm="8" :xl="4" :xs="6"><strong>登记日期</strong></el-col>
            <el-col :lg="18" :md="17" :sm="15" :xl="20" :xs="18">
              <el-date-picker
                  v-model="filters.regtime"

                  :picker-options="pickerOptions"
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>

      </el-row>
      <el-row class="formOpen">
        <el-col  :span="24" :class="{on:formOpen}" >
          <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
        </el-col>
      </el-row>
      <el-button class="search" icon="el-icon-search" size="small" type="primary" @click="copyrights(filters.page = 1)">
        查询
      </el-button>
      <el-button class="reset" :class="{on:formOpen}" icon="el-icon-refresh-left" size="small" type="reset" @click="reset">
        重置
      </el-button>
    </form>
    <div class="mainrb">

      <el-button  :loading="down" class="excel" :disabled="loading || copyrightList.length == 0" icon="el-icon-document" size="small" type="primary" @click="download">
        {{down ? '导出中' : '导出数据'}}
      </el-button>

      <div class="page-up">
        <el-pagination
            :current-page.sync="filters.page"
            :page-size="filters.per_page"
            :page-sizes="[30, 50, 100]"
            :total="pageSize"
            background
            layout="prev, next"
            size="small"
            @current-change="copyrights">
        </el-pagination>
      </div>
      <el-skeleton :count="1" :loading="loading" animated>
        <template slot="template">
          <el-table :data="copyrightList" :class="{on:!formOpen,ios:$store.state.heights}">
            <template slot="empty">
              <el-empty></el-empty>
            </template>
            <el-table-column label="序号" width="50">
              <template>
                <el-skeleton-item class="w80" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="作品名称">
              <template>
                <div class="tmimage">
                  <div class="img">
                    <el-skeleton-item
                        style="width: 70px; height: 70px;margin: auto"
                        variant="image"
                    />
                  </div>

                </div>


              </template>
            </el-table-column>
            <el-table-column label="著作权人">
              <template>
                <el-skeleton-item variant="p" class="w80"/>
              </template>
            </el-table-column>
            <el-table-column label="创作完成日期" width="120">
              <template>
                <el-skeleton-item variant="p" class="w80"/>
              </template>
            </el-table-column>
            <el-table-column label="登记号">
              <template>
                <el-skeleton-item variant="p" class="w80"/>
              </template>
            </el-table-column>
            <el-table-column label="登记日期">
              <template>
                <el-skeleton-item variant="p" class="w80"/>
              </template>
            </el-table-column>
            <el-table-column label="类别">
              <template>
                <el-skeleton-item variant="p" class="w80"/>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template>
                <el-skeleton-item variant="p" class="w80"/>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-table :data="copyrightList" height="100%" :class="{on:!formOpen,ios:$store.state.heights}">
          <template slot="empty">
            <el-empty :image-size="80"></el-empty>
          </template>
          <el-table-column label="序号" type="index" width="50">
          </el-table-column>
          <el-table-column label="作品名称" width="280">
            <template slot-scope="scope">
              <div class="tmimage">
                <div v-if="scope.row.type == '美术作品' || scope.row.type == '摄影作品'" class="img">
                  <el-image
                      :preview-src-list="  scope.row.pic "
                      :src="scope.row.pic[0]"
                  >
                    <div slot="error" class="image-slot">
                      <img  src="@/assets/img/personal/default1.png" alt="">
                    </div>
                  </el-image>
                </div>
                <p class="vertical ver-line3">
                  {{ scope.row.fullname }}
                </p>
              </div>

            </template>
          </el-table-column>
          <el-table-column label="著作权人" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <router-link
                  v-if="scope.row.client_id != 0"
                  :to="{
                  name: 'newlyBuild',
                    params: { id: scope.row.client_id  },
                }" target="_blank"
              >
                {{ scope.row.author_nationality }}
              </router-link>
              <template v-else>
                {{ scope.row.author_nationality }}
              </template>
            </template>
          </el-table-column>
          <el-table-column label="创作完成日期" width="120">
            <template slot-scope="scope">
              {{scope.row.finish_time || '-'}}
            </template>

          </el-table-column>
          <el-table-column label="登记号" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <a
                  v-if="scope.row.cert"
                  :href="`${scope.row.cert}&preview=true`"
                  rel="noopener noreferrer"
                  target="_blank"
              >{{ scope.row.regnum }}
              </a>
              <template v-else>
                {{ scope.row.regnum || '-'}}
              </template>
            </template>
          </el-table-column>
          <el-table-column label="登记日期" width="100" prop="regtime"></el-table-column>
          <el-table-column label="类别" prop="type"></el-table-column>
          <el-table-column label="操作" width="100" fixed="right">
            <template slot-scope="scope">
              <router-link :to="{name: 'worksDetails',
                params: { id: scope.row.id}}" target="_blank">
                <el-button icon="el-icon-edit-outline" size="small" type="primary">详情</el-button>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </el-skeleton>


      <el-pagination
          :current-page.sync="filters.page"
          :page-size="filters.per_page"
          :page-sizes="[30, 50, 100]"
          :total="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="copyrights()"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import {work, workExport} from '@/api/personal.js'
export default {
  // props:['tms'],
  data() {
    return {
      tms:'',
      formOpen:false,
      down: false,//控制导出中的显示与隐藏
      loading: true,//控制列表的骨架屏
      pickerOptions: {//时间选择器的便利选择对象
        shortcuts: storage.getShortcuts(),
      },
      copyrightList: [],// 作品著作权数组
      typeSelect: [],// 作品类型,下拉所需的内容
      authorSelect: [],// 著作权人,下拉所需的内容
      //信息搜索表单的字段
      filters: {
        fullname: "",
        regdate: "", //作品著作权，登记日期，的文本
        type: "", //作品著作权，作品类型的选中文本
        author_nationality: "",
        regnum: "",
        regtime: "",
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
      },
      pageSize: 0, //总条数

    };
  },
  created() {

    this.tms = this.$store.state.tms

    if(this.tms){
      if (this.tms.indexOf('国作登字') != -1) {
        this.filters.regnum = this.tms
      } else {
        this.filters.fullname = this.tms
      }
    }
    this.copyrights();
    this.select()
  },
  computed: {

    gettms:function (){
      return this.$store.state.tms;
    }
  },
  watch: {
    'gettms': function (newVal, oldVal) {
      if (newVal.indexOf('国作登字') != -1) {
        this.filters.regnum = newVal
      } else {
        this.filters.fullname = newVal
      }
      this.copyrights();
    }
  },
  methods: {
    //分页的每页显示多少条的事件
    handleSizeChange(val) {
      this.filters.per_page = val;
      this.copyrights();
    },
    //渲染列表事件
    async copyrights() {
      this.loading = true;
      this.copyrightList = storage.getList();
      try {
        const res = await work(this.filters)
        if (res && res.err_code === 0) {
          this.loading = false;
          this.pageSize = res.data.total;
          this.copyrightList = res.data.list;
        } else {
          this.copyrightList = []
        }
      } finally {
        this.loading = false;
      }

    },
    //重置事件
    reset() {
      this.filters = this.$options.data().filters; //清空表单
      this.copyrights();
    },
    //下拉框数据
    async select() {
      let s = await storage.getSelects('app_names', 'copyright_types')
      this.authorSelect = s.app_names
      this.typeSelect = s.copyright_types

    },
    //导出数据事件
    async download() {
      this.down = true
      try {
        const res = await workExport(this.filters)
        if(res && res.err_code === 0){
          location.href = res.data.url
          // window.open(res.data.url)
          this.down = false
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }

      } finally {
        this.down = false
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/copyright.less";



</style>
