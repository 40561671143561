<template>
  <div class="detail ba-col-fff">
    <h2>评审商标详情</h2>
    <el-skeleton :loading="loading" animated>
      <template slot="template">
        <div class="plf20 col-606">
          <div class=" bor">
            <div  class="detail_l ">
              <div class="img w100 flex align-cen ju-con-cen pos-rel">
                <el-skeleton-item
                    class="img-logo"
                    style="width: 190px;height: 190px"
                    variant="image"
                />
              </div>
              <div class="flows p20">
                <h3 class="col-303 font-wbold m-b10">商标流程</h3>
                <p class="line-h30 ellipsis">
                  <el-skeleton-item variant="p" class="w50"/>
                </p>
                <p class="line-h30 ellipsis">
                  <el-skeleton-item variant="p" class="w50"/>
                </p>
                <p class="line-h30 ellipsis">
                  <el-skeleton-item variant="p" class="w50"/>
                </p>
              </div>
            </div>
            <div   class="detail_r">
              <div class="r-cont">
                <h3 class="p10 col-303 font-wbold">
                  <el-skeleton-item variant="p" class="w50"/>

                  <div  class="disinblk">
                    <el-skeleton-item variant="p" class="w50"/>
                  </div>

                </h3>
                <div class="cont-text p20 line-h30">
                  <div class="flex flex-cen">
                    <p class="w30"><strong>争议商标号：</strong><el-skeleton-item variant="p" class="w50"/></p>
                    <p class="w30"><strong>争议类别：</strong><el-skeleton-item variant="p" class="w50"/></p>
                    <p class="w30">
                      <strong>商标类型：</strong><el-skeleton-item variant="p" class="w50"/>
                    </p>
                  </div>
                  <div class="flex flex-cen">
                    <p class="w30"><strong>商标申请日期：</strong><el-skeleton-item variant="p" class="w50"/></p>
                    <p class="w30"><strong>商标注册日期：</strong><el-skeleton-item variant="p" class="w50"/></p>
                    <p class="w30">
                      <strong>商标专用期限：</strong><el-skeleton-item variant="p" class="w50"/>
                    </p>
                  </div>
                  <div class="">
                    <strong>类似群组：</strong>
                    <el-skeleton-item variant="p" class="w50"/>
                  </div>
                  <div class="">
                    <strong>商品/服务项目：</strong>
                    <el-skeleton-item variant="p" class="w50"/>
                  </div>

                  <div class="flex flex-cen">
                    <p class="w30">
                      <strong>案件号：</strong><el-skeleton-item variant="p" class="w50"/>
                    </p>
                    <p class="w30">
                      <strong>申请日期：</strong><el-skeleton-item variant="p" class="w50"/>
                    </p>
                    <p class="w30">
                      <strong>裁定日期：</strong><el-skeleton-item variant="p" class="w50"/>
                    </p>
                  </div>
                  <div class="flex flex-cen">
                    <p>
                      <strong>申请人：</strong><el-skeleton-item variant="p" class="w50"/>
                    </p>
                    <p>
                      <strong>被申请人：</strong><el-skeleton-item variant="p" class="w50"/>
                    </p>
                  </div>
                  <div class="flex flex-cen">
                    <p>
                      <strong>申请人代理机构：</strong><el-skeleton-item variant="p" class="w50"/>
                    </p>
                    <p>
                      <strong>被申请人代理机构：</strong><el-skeleton-item variant="p" class="w50"/>
                    </p>
                  </div>
                  <div class="">
                    <strong>引用商标法：</strong>
                    <el-skeleton-item variant="p" class="w50"/>
                  </div>
                  <div class="">
                    <strong>引证商标：</strong>
                    <el-skeleton-item
                        class="img-logo"
                        style="width: 90px;height: 90px"
                        variant="image"
                    />
                  </div>
                </div>
              </div>
              <div class="r-list">
                <div class="list_t p10">
                  <h3 class="col-303 font-wbold flex flex-cen">商标官文
                    <div class="">
                      <el-skeleton-item style="width: 100px" variant="p"/>

                      <el-skeleton-item style="width: 100px" variant="p"/>
                    </div>
                  </h3>
                </div>
                <el-table
                    :data="docs"
                    style="width: 100%"
                    tooltip-effect="dark"
                    @selection-change="handleSelectionChange"
                >
                  <template slot="empty">
                    <el-empty></el-empty>
                  </template>
                  <el-table-column type="selection" width="55"></el-table-column>
                  <el-table-column label="序号" width="50">
                    <template>
                      <el-skeleton-item style="width: 100%" variant="p"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="官文名称">
                    <template>
                      <el-skeleton-item style="width: 50%" variant="p"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="收文日期">
                    <template>
                      <el-skeleton-item style="width: 50%" variant="p"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="上传日期">
                    <template>
                      <el-skeleton-item style="width: 50%" variant="p"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="100">
                    <template>
                      <el-skeleton-item style="width: 50%" variant="p"/>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>

        </div>

      </template>
      <template>
        <div class="plf20 m-b40 col-606" ref="bor">
          <div class=" bor" >
            <div class="detail_l ">
              <div class="img w100 flex align-cen ju-con-cen pos-rel">
                <el-image
                    :src="detail.tmimage"
                    :preview-src-list="[detail.tmimage]">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline "></i>
                  </div>
                </el-image>
                <div class="flag pos-abs pos-l0 pos-t0"
                     :class="{flag7:detail.result == '驳回' || detail.result == '不予注册',flag9:detail.result == '部分驳回' || detail.result == '部分不予注册',flag5:detail.result == '准予注册' || detail.result == '维持注册',flag4:detail.result == '初步审定',flag3:detail.result == '撤销',flag2:detail.result == '部分撤销' || detail.result == '部分无效',flag6:detail.result == '无效宣告'}"
                >
                  <span>
                    {{detail.result}}
                  </span>
                </div>

              </div>
              <div class="flows p20">
                <h3 class="col-303 font-wbold m-b10">商标流程</h3>
                <div  class="">
                  <p v-for="item in (flowsUp ? detail.flows : replace.flows)" class="line-h20">
                    {{ item.flowdate }} &nbsp;&nbsp;
                    <span v-html="item.flowname"></span>
                  </p>
                </div>
              </div>
              <p v-if="replace.flowsInit.length > 5 " class="text-cen pointer p-b10" @click="flowsUp = !flowsUp">{{flowsUp ? '收起' : '查看全部'}}<i class=" m-l5" :class="{'el-icon-arrow-down':flowsUp == false,'el-icon-arrow-up':flowsUp == true}"></i></p>

            </div>
            <div class="detail_r">
              <div class="r-cont">
                <h3 class="p10 col-303 font-wbold flex flex-cen ">
                  <p>
                    {{ detail.type || '-'}}：

                    <span class="disinblk col-blue">
                    {{detail.tmname || '图形' }}
                  </span>
                  </p>

                  <el-button v-if="detail.review"  class="fr" size="mini" type="text" icon="el-icon-document" @click="insDel() ">
                    {{ detail.review }}
                  </el-button>
                </h3>
                <div class="cont-text p20 line-h30">
                  <div class="flex ju-con-bet">
                    <p><strong>争议商标号：</strong>{{ detail.tmcode || '-' }}<i class="el-icon-copy-document col-blue pointer m-l5" v-copy="detail.tmcode"></i> </p>
                    <p><strong>争议类别：</strong>{{ detail.clstext || '-' }}</p>
                    <p>
                      <strong>商标类型：</strong>{{ detail.tmsblx || '-' }}
                    </p>
                  </div>
                  <div class="flex ju-con-bet">
                    <p><strong>商标申请日期：</strong>{{ detail.appdate || '-' }}</p>
                    <p><strong>商标注册日期：</strong>{{ detail.regdate || '-' }}</p>
                    <p class="word-break">
                      <strong>商标专用期限：</strong>{{ detail.enddate || '-' }}
                    </p>
                  </div>
                  <div class="">
                    <strong>类似群组：</strong>
                    {{ detail.tmgroup || '-' }}
                  </div>

                  <div class="project">
                    <strong>商品/服务项目：</strong>
                    <span v-for="(item,index) in replace.tmgoodsdetail">
                       <s v-if="item.status != 0" class="grey">{{item.name}}</s>
                            <font v-else>{{item.name}}</font>
                      <font v-if="index != replace.tmgoodsdetail.length - 1">、</font>
                    </span>
                    <span v-if="replace.tmgoodsdetail.length == 0">-</span>
                  </div>

                  <div class="flex ju-con-bet">
                    <p class="w30">
                      <strong>案件号：</strong>{{ detail.caseno || '-' }}
                    </p>
                    <p class="w30">
                      <strong>申请日期：</strong>{{ detail.deliverdate || '-' }}
                    </p>
                    <p class="w30">
                      <strong>裁定日期：</strong>{{ detail.judgedate || '-' }}
                    </p>
                  </div>
                  <div class="flex ju-con-bet">
                    <p>
                      <strong>申请人：</strong>
                      <span :class="{'col-blue':detail.appname }"> {{ detail.appname || '-' }}</span>
                    </p>
                    <p>
                      <strong>被申请人：</strong>
                      <span :class="{'col-blue':detail.resname }"> {{ detail.resname || '-' }}</span>

                    </p>
                  </div>
                  <div class="flex ju-con-bet">
                    <p>
                      <strong>申请人代理机构：</strong><span :class="{'col-blue':detail.agentname }"> {{ detail.agentname || '-' }}</span>
                    </p>
                    <p>
                      <strong>被申请人代理机构：</strong><span :class="{'col-blue':detail.resagentname }"> {{ detail.resagentname || '-' }}</span>
                    </p>
                  </div>
                  <div class="">
                    <strong>引用商标法：</strong>
                    <el-tooltip class="item" v-for="(item,index) in replace.law" popper-class="max-wTip" effect="dark"  placement="bottom-start">
                      <div slot="content" v-html="item.content">
                      </div>
                      <span class="col-blue pointer">{{ item.clause }} <font v-if="index != replace.law.length - 1" class="col-606">、</font></span>
                    </el-tooltip>
                    <span v-if="replace.law.length == 0">-</span>
                  </div>
                  <div class="citation">
                    <strong>引证商标：</strong>
                    <span v-if="replace.tmcited.length == 0">-</span>
                    <div  class="citation-list flex flex-warp flex-gap10">
                      <div class="  list-item" v-for="item in replace.tmcited">
                        <div class="item-t flex align-cen ju-con-cen">
                          <el-image
                              :src="item.tmimage"
                              :preview-src-list="srcList">
                          </el-image>
                        </div>
                        <div class="item-b line-h32 text-cen">
                          <span class="w25 disinblk">{{item.intcls || '-'}}</span>
                          <router-link
                              class="col-blue disinblk w75"
                              :to="{ name: 'tradDetails', params: { tmcode: item.tmcode,intcls:item.intcls,alias:0 } }"
                              target="_blank">
                            {{ item.tmcode || '-' }}
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
              <div class="r-list">
                <div class="list_t p10">
                  <h3 class="col-303 font-wbold flex flex-cen">商标官文
                    <div class="">
                      <el-button
                          icon="el-icon-upload2"
                          size="small" type="primary"
                          @click="upload"
                      >
                        上传官文
                      </el-button>
                      <span v-if="downSH" class="m-l10">
          <el-button
              v-if="down"

              icon="el-icon-download"
              size="small"
              @click="download"
          >
            批量下载
          </el-button>
          <el-button
              v-else
              :loading="true"
              size="small">
            下载中
          </el-button>
        </span>

                    </div>
                  </h3>
                </div>
                <el-table
                    :data="detail.docs"
                    @selection-change="handleSelectionChange"
                >
                  <template slot="empty">
                    <el-empty :image-size="80"></el-empty>
                  </template>
                  <el-table-column type="selection" width="50"></el-table-column>
                  <el-table-column label="序号" type="index" width="50"></el-table-column>
                  <el-table-column label="官文类型" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                      <a :href="`${scope.row.url}&preview=true`"
                         target="_blank">
                        {{ scope.row.matter }}
                      </a>
                    </template>
                  </el-table-column>
                  <el-table-column label="收文日期" width="110" prop="made_at"></el-table-column>
                  <el-table-column label="上传日期" width="110" prop="created_at"></el-table-column>
                  <el-table-column fixed="right" label="操作" width="140">
                    <template slot-scope="scope">
                      <div class="operation">
                        <el-button icon="el-icon-download" size="small" type="text" @click="open(scope.row)">下载
                        </el-button>
                        <el-button v-if="scope.row.is_custom == 1" class="del" icon="el-icon-delete" size="small"
                                   type="text" @click="deleteFile(scope.row.id)">删除
                        </el-button>

                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>

        </div>

      </template>
    </el-skeleton>


    <!-- 上传 -->
    <el-dialog
        :closeOnClickModal="false"
        :visible.sync="fileSH"
        class="file"
        title="上传文件"
        width="335px"
        @close="empty"
        @closed="empty"
    >
      <!--      <div v-if="identification" class="cont-tab">-->
      <!--        <p @click="tabtNum = 1">-->
      <!--          <span :class="{ checked: tabtNum == 1 }" class="el-checkbox__input">-->
      <!--            <span class="el-checkbox__inner"> </span>-->
      <!--            <input-->
      <!--                aria-hidden="false"-->
      <!--                class="el-checkbox__original"-->
      <!--                type="checkbox"-->
      <!--                value="手动添加"-->
      <!--            />-->
      <!--          </span>-->

      <!--          <font>手动添加</font>-->
      <!--        </p>-->
      <!--        <p @click="tabtNum = 2">-->
      <!--          <span :class="{ checked: tabtNum == 2 }" class="el-checkbox__input">-->
      <!--            <span class="el-checkbox__inner"> </span>-->
      <!--            <input-->
      <!--                aria-hidden="false"-->
      <!--                class="el-checkbox__original"-->
      <!--                type="checkbox"-->
      <!--                value="手动添加"-->
      <!--            />-->
      <!--          </span>-->
      <!--          <font>智能识别</font>-->
      <!--        </p>-->
      <!--      </div>-->

      <div class="cont-tabc">
        <el-form ref="ruleForm" :model="manual" :rules="manualRules" label-position="right" label-width="80px"
                 size="mini">
          <el-form-item label="官文类型" prop="matter">
            <el-select
                v-model="manual.matter"
                clearable
                filterable
                placeholder="请选择官文类型"
                size="small"
            >
              <el-option
                  v-for="item in typeSelect"
                  :key="item.text"
                  :label="item.text"
                  :value="item.text"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商标号">
            <el-input
                v-model="filters.tmcode"
                clearable
                placeholder="请输入商标号"
                readonly
                size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="收文日期" prop="made_at">
            <el-date-picker
                v-model="manual.made_at"
                placeholder="请选择收文日期"
                size="small"
                type="date"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <div class="tabc-update">

            <el-upload
                ref="upload"
                :before-upload="beforeUpload"
                :file-list="fileList"
                :http-request="uploadImg"
                :limit="1"
                :on-exceed="handleExceed"
                action="/api/upload"
                class="upload-demo"
            >
              <el-form-item prop="name">
                <el-input v-model="manual.name" placeholder="请点击按钮上传文件" readonly size="small"></el-input>
              </el-form-item>
              <el-button
                  v-if="uploadBtn"
                  slot="trigger"
                  :disabled="distinguishDis"
                  circle
                  icon="el-icon-plus"
                  size="mini"
                  type="primary"
              ></el-button>
              <el-button v-else slot="trigger" :loading="true" circle size="mini" type="primary"></el-button>
            </el-upload>

          </div>
          <div class="batch">
            官文太多？马上
            <router-link to="/personal/trademarkAdmin/upload">批量上传</router-link>
          </div>
          <div class="submitBtn">
            <el-button class="cancel" icon="el-icon-close" size="small" @click="fileSH = false">取 消</el-button>
            <el-button :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' :  'el-icon-folder-checked'"
                       class="confirm" size="small"
                       type="primary" @click="submit('ruleForm')">确
              定
            </el-button>
          </div>

        </el-form>
      </div>
      <!--      <div v-show="tabtNum == 2" class="cont-tabc">-->
      <!--        <el-form ref="automatic" :model="automatic" :rules="automaticRules" label-position="right" label-width="80px"-->
      <!--                 size="mini">-->
      <!--          <div v-show="contSH" class="tabc-cont">-->
      <!--            <el-skeleton :count="1" :loading="loadingUp" animated>-->
      <!--              <template slot="template">-->
      <!--                <div class="el-form-item is-required el-form-item&#45;&#45;mini">-->
      <!--                  <label class="el-form-item__label">官文类型</label>-->
      <!--                  <div class="el-form-item__content" style="height: 32px; line-height: 32px">-->
      <!--                    <el-skeleton-item variant="p"/>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--                <div class="el-form-item is-required el-form-item&#45;&#45;mini">-->
      <!--                  <label class="el-form-item__label">商标号</label>-->
      <!--                  <div class="el-form-item__content" style="height: 32px; line-height: 32px">-->
      <!--                    <el-skeleton-item variant="p"/>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--                <div class="el-form-item is-required el-form-item&#45;&#45;mini">-->
      <!--                  <label class="el-form-item__label">收文日期</label>-->
      <!--                  <div class="el-form-item__content" style="height: 32px; line-height: 32px">-->
      <!--                    <el-skeleton-item variant="p"/>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </template>-->
      <!--              <template>-->

      <!--                <el-form-item label="官文类型" prop="matter">-->
      <!--                  <el-select-->
      <!--                      v-model="automatic.matter"-->
      <!--                      clearable-->
      <!--                      filterable-->
      <!--                      placeholder="请选择官文类型"-->
      <!--                      size="small"-->
      <!--                  >-->
      <!--                    <el-option-->
      <!--                        v-for="item in typeSelect"-->
      <!--                        :key="item.text"-->
      <!--                        :label="item.text"-->
      <!--                        :value="item.text"-->
      <!--                    >-->
      <!--                    </el-option>-->
      <!--                  </el-select>-->
      <!--                </el-form-item>-->
      <!--                <el-form-item label="商标号">-->
      <!--                  <el-input-->
      <!--                      v-model="filters.tmcode"-->
      <!--                      clearable-->
      <!--                      readonly-->
      <!--                      placeholder="请输入商标号"-->
      <!--                      size="small"-->
      <!--                  ></el-input>-->
      <!--                </el-form-item>-->
      <!--                <el-form-item label="收文日期" prop="made_at">-->
      <!--                  <el-date-picker-->
      <!--                      v-model="automatic.made_at"-->
      <!--                      placeholder="请选择收文日期"-->
      <!--                      size="small"-->
      <!--                      type="date"-->
      <!--                      value-format="yyyy-MM-dd"-->
      <!--                  >-->
      <!--                  </el-date-picker>-->
      <!--                </el-form-item>-->
      <!--              </template>-->
      <!--            </el-skeleton>-->
      <!--          </div>-->
      <!--          <div class="tabc-update">-->
      <!--            <el-upload-->
      <!--                ref="upload"-->
      <!--                :before-upload="beforeUpload"-->
      <!--                :file-list="fileList"-->
      <!--                :http-request="uploadImg"-->
      <!--                :limit="1"-->
      <!--                :on-exceed="handleExceed"-->
      <!--                action="/api/upload"-->
      <!--                class="upload-demo"-->
      <!--            >-->

      <!--              <el-form-item prop="name">-->
      <!--                <el-input v-model="automatic.name" readonly placeholder="请点击按钮上传文件" size="small"></el-input>-->
      <!--              </el-form-item>-->

      <!--              <el-button-->
      <!--                  v-if="uploadBtn"-->
      <!--                  slot="trigger"-->
      <!--                  :disabled="distinguishDis"-->
      <!--                  circle-->
      <!--                  icon="el-icon-plus"-->
      <!--                  size="mini"-->
      <!--                  type="primary"-->
      <!--              ></el-button>-->
      <!--              <el-button v-else slot="trigger" :loading="true" circle size="mini" type="primary"></el-button>-->
      <!--            </el-upload>-->
      <!--          </div>-->
      <!--          <div v-if="distinguishSh" class="tabc-distinguish">-->
      <!--            <el-button :disabled="distinguishDis" size="small" icon="el-icon-view" type="primary" @click="distinguishBtn('automatic')">识 别-->
      <!--            </el-button>-->
      <!--          </div>-->
      <!--          <el-form-item v-else class="submitBtn">-->
      <!--            <el-button class="cancel" size="small" @click="fileSH = false" icon="el-icon-close">取 消</el-button>-->
      <!--            <el-button :disabled="submitDis" class="confirm" size="small" type="primary"-->
      <!--                       @click="submitAutomatic('automatic')" icon="el-icon-folder-checked">确 定-->
      <!--            </el-button>-->
      <!--          </el-form-item>-->
      <!--        </el-form>-->
      <!--      </div>-->
    </el-dialog>

    <el-dialog

        :closeOnClickModal="false"
        title="文书详情"
        :visible.sync="insDelVisible"
        class="ins_del_dia"
    >
      <div class="dia_body line-h22">
        <div class="body_title">
          <h3 class="font-16 font-wbold col-303 text-cen">{{ insDetail.title || '-' }}</h3>
          <p class="font-12 col-909 text-cen">{{insDetail.caseno || '-'}}</p>
        </div>
        <div class="body_flows ba-col-EFE p10 col-red m-tb10">
          <p>{{ insDetail.judgedate || '-' }}<span class="m-l5">裁定结果：</span></p>
          <p>{{ replace.judgement.result || '-' }}</p>
        </div>
        <div class="body_cont">
          <h4 class="col-303 font-wbold"><font></font> 主体信息</h4>
          <p v-html="(insDetail.litigant || '-')"></p>
          <h4 class="col-303 font-wbold"><font></font>审理过程</h4>
          <p v-html="(insDetail.process || '-')"></p>
          <h4 class="col-303 font-wbold"><font></font>裁判理由</h4>
          <p v-html="(insDetail.cause || '-')"></p>
          <h4 class="col-303 font-wbold"><font></font>裁定结果</h4>
          <p v-html="(replace.judgement.law || '-')"></p>
          <p v-html="(replace.judgement.result || '-')"></p>
          <h4 class="col-303 font-wbold"><font></font>合议组员</h4>
          <p v-html="(insDetail.officer || '-')"></p>
          <h4 class="col-303 font-wbold"><font></font>{{replace.review.indexOf('裁定') == -1 ? '决定' : '裁定'}}日期</h4>
          <p v-html="(insDetail.judgedate || '-')"></p>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button icon="el-icon-close" size="small"  @click="insDelVisible = false">关 闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import storage from '@/utils/storage'
import {
  tmReviewDetail,
  batchDownload,
  fileDistinguish,
  fileSubmit,
  fileUpload,
  managementDelete,
  judgeInfo
} from '@/api/personal.js'
export default {
  // 使用directives注册v-focus指令

  data() {
    return {
      insDelVisible:false,//控制文书详情对话框显示与隐藏
      flowsUp:false,
      replace:{//替代字段集合
        law:[],
        tmcited:[],
        flows:[],
      flowsInit:[],
        tmgoodsdetail:[],
        judgement:{},
        review:'',
      },
      bodyHeight: '',
      loading: true,//控制整个页面的骨架屏
      filters: {//接收列表页传的参数来渲染页面数据
        tmcode: '',//注册商标
         intcls: '',//国际分类
      },
      docs: storage.getList(),//骨架屏的初始数据
      detail: [],//整个详情的数据
      insDetail:[],//文书详情的数据
      srcList: [],//存储查看大图的数据
      id: {ids: []},//记录批量下载的数据},
      deleteId: {
        id: '',
      },
      downSH: true,//控制批量下载按钮的显示与隐藏
      down: true,//控制批量下载加载中按钮的显示与隐藏

      uploadBtn: true,//控制上传文件加载中按钮的显示与隐藏
      fileList: [],//记录上传文件成功的数组
      loadingUp: true,//控制上传文件中识别文件的骨架屏
      fileSH: false,//控制上传官文弹窗的显示与隐藏
      tabtNum: 1,//控制手动和自动上传的tab切换
      file: {
        name: "",//上传官文的名称
        path: '',//上传官文的路径
      },
      distinguishSh: true,//控制识别按钮显示与隐藏
      distinguishDis: false,
      submitDis: false,
      contSH: false,//控制识别中的骨架屏
      typeSelect: [],//官文类型的下拉数据
      automatic: {//自动上传的字段
        matter: "",
        made_at: "",
        name: "",//上传官文的名称
      },
      automaticRules: {
        matter: [
          {required: true, message: '请选择官文类型', trigger: 'change'},
        ],
        made_at: [
          {required: true, message: '请选择收文日期', trigger: 'change'},
        ],
        name: [
          {required: true, message: '请点击按钮上传文件', trigger: 'change'},
        ],
      },
      manual: {//手动上传的字段
        matter: "", //官文名称
        made_at: "",
        name: ''
      },
      manualRules: {
        matter: [
          {required: true, message: '请选择官文类型', trigger: 'change'},
        ],
        made_at: [
          {required: true, message: '请选择收文日期', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请点击按钮上传文件', trigger: 'change'},
        ],
      },
    };
  },

  created() {

    this.domesticDetails();
    this.bodyHeight = document.body.offsetHeight

  },
  mounted() {
    window.onresize = () => {
      this.bodyHeight = document.body.offsetHeight

    };
  },
  computed: {
    mainrHeight: function () {
      return (this.bodyHeight - 95) + 'px'
    },
  },
  methods: {

    //文书详情
    async insDel(){

      try {
        const res = await judgeInfo({intcls:this.detail.intcls,tmcode:this.detail.tmcode,type:this.detail.type})
            if(res && res.err_code === 0){
              this.insDetail = res.data
              this.replace.judgement = res.data.judgement
              this.insDelVisible = true
            }
      }finally {

      }
    },
    // 下拉
    async select() {
      let s = await storage.getSelects('tm_doc_types', 'app_names')
      this.typeSelect = storage.getReviewTypes()
      // for (let i in s.app_names) {
      //   if (s.app_names[i].text == this.detail.appname) {
      //     this.typeSelect = storage.getTrademarkTypes()
      //     break
      //   } else {
      //     this.typeSelect = storage.getMaintainTypes()
      //   }
      // }
    },
    //返回顶部事件
    backTop() {
      const that = this;
      let ispeed = Math.floor(-that.scrollTop / 5);
      document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
    },
    //渲染详情事件
    async domesticDetails() {
      this.filters.tmcode = this.$route.params.tmcode
      this.filters.intcls = this.$route.params.intcls
      try {
        const res = await tmReviewDetail(this.filters)
        if (res && res.err_code === 0) {
          this.loading = false

          this.detail = res.data;
          if(res.data.review){
            this.replace.review = res.data.review
          }
          if(res.data.tmcited){
            this.replace.tmcited = this.detail.tmcited
            res.data.tmcited.forEach(item =>{
              this.srcList.push(item.tmimage);
            })
          }
          if(this.detail.law){
            this.replace.law = this.detail.law
            this.replace.law.forEach(item =>{
              let pages = new RegExp("\n\n","g");
              item.content = item.content.replace(pages,"</br>")
            })

          }
          this.replace.tmgoodsdetail = this.detail.tmgoods
          this.replace.flowsInit = this.detail.flows
          if(this.detail.flows.length > 5){
            this.replace.flows = this.detail.flows.slice(0,5)
          }else{
            this.replace.flows = this.detail.flows
          }
          this.downSH = this.detail.docs.length != 0;
          await this.select()
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {
        this.loading = false
      }
    },

    //记录选择要下载的id事件
    handleSelectionChange(val) {
      this.id.ids = [];
      val.forEach((item) => {
        this.id.ids.push(item.id);
      });
    },
    //批量下载点击事件
    async download() {
      if (this.id.ids.length == 0) {
        this.$confirm("请选中要下载的官文序号", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      } else {

        this.down = false
        try {
          const res = await batchDownload(this.id)
          if (res && res.err_code === 0) {
            window.open(res.data.url)
            this.down = true
          }else{
            this.$message({
              message: res.err_msg,
              type: 'warning'
            });
          }
        } catch (error) {
          console.log("错误", error)
        } finally {
          this.down = true
        }

      }

    },
    open(url) {
      let name = this.detail.tmcode + '-' + this.detail.intcls + '-' + (this.detail.tmname ? this.detail.tmname : '图形')  + '-' + url.matter + '.' + url.name.split('.')[1]
      location.href = url.url.replace(url.name,name)
    },


    //官文删除事件
    deleteFile(item) {
      this.deleteId.id = item
      try {

        this.$confirm('确定要删除该官文吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          await managementDelete(this.deleteId).then(res => {
            if (res && res.err_code === 0) {
              this.$message({
                type: 'success',
                message: "官文已删除！",
              })
              this.domesticDetails()
            } else {
              this.$message({
                type: 'error',
                message: res.err_msg,
              });
              this.domesticDetails()
            }
          })
        })
      } finally {

      }
    },

    upload() {
      this.fileSH = true
      this.$store.commit('setscroll', {
        scroll: this.fileSH,
      });
    },
    //文件上传时事件
    beforeUpload() {
      this.uploadBtn = false
    },
    //文件上传数量超过1个提示事件
    handleExceed(files, fileList) {
      this.$message.warning(
          `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`
      );
    },

    //文件上传后事件(自动上传)
    async uploadImg(item) {
      let formData = new FormData();
      formData.append("file", item.file);
      formData.append("is_base64", 0);
      try {
        const res = await fileUpload(formData)
        if (res && res.err_code === 0) {
          this.uploadBtn = true
          this.file.name = this.automatic.name = this.manual.name = res.data.name;
          this.file.path = res.data.path;
          this.$refs.upload.clearFiles()
        } else {
          this.$message({
            type: "warning",
            message: res.err_msg,
          })
        }
      } finally {
        this.uploadBtn = true;
      }
    },

    //识别按钮点击事件
    distinguishBtn(automatic) {
      this.$refs[automatic].validate(async (valid) => {
        try {
          if (this.automatic.name != '') {
            this.distinguishDis = true
            this.contSH = true;
            this.loadingUp = true
            const res = await fileDistinguish({...this.filters, ...this.file})
            if (res && res.err_code === 0) {
              this.distinguishDis = false
              this.loadingUp = false
              this.automatic.made_at = res.data.made_at;
              this.distinguishSh = false;
              if (this.typeSelect.indexOf(res.data.matter) == -1) {
                this.automatic.matter = res.data.matter;
              } else {
                this.$message({
                  type: "warning",
                  message: '上传文件的官文类型不存在，请选择成已有的官文类型',
                })
                this.automatic.matter = ''
              }
            } else {
              this.$message({
                message: res.err_msg,
                type: 'warning'
              })
              this.contSH = false
              this.fileList = []
              this.distinguishDis = false
              this.distinguishSh = true
            }
          }
        } catch (e) {
          this.contSH = false
          this.fileList = []
          this.distinguishDis = false
          this.distinguishSh = true
        }

      })
    },
    //上传官文提交事件
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.distinguishDis = this.submitDis = true
            const res = await fileSubmit({...this.file, ...this.manual, ...this.filters})
            if (res && res.err_code === 0) {
              this.$refs[formName].resetFields();
              this.success()
            } else {
              this.$message({
                type: "warning",
                message: res.err_msg,
              })
              this.fileList = []
              this.distinguishDis = this.submitDis = false
            }
          } catch (e) {
            this.fileList = []
            this.distinguishDis = this.submitDis = false
          }
        } else {
          return false;
        }
      })


    },
    // 识别提交
    submitAutomatic(automatic) {
      this.$refs[automatic].validate(async (valid) => {
        if (valid) {
          try {
            this.distinguishDis = this.submitDis = true
            const res = await fileSubmit({...this.file, ...this.automatic, ...this.filters})
            if (res && res.err_code === 0) {
              this.success()
            } else {
              this.$message({
                type: "warning",
                message: res.err_msg,
              })
              this.distinguishDis = this.submitDis = false
            }
          } catch (e) {
            this.fileList = []
            this.distinguishDis = this.submitDis = false
          }
        } else {
          return false
        }
      })


    },
    //上传官文提交成功事件
    success() {
      this.$message({
        type: "success",
        message: "提交成功!",
      });
      this.fileSH = false;
      this.$store.commit('setscroll', {
        scroll: this.fileSH,
      });
      this.manual = this.$options.data().manual; //清空表单
      this.automatic = this.$options.data().automatic; //清空表单
      this.distinguishSh = true;
      this.contSH = false;
      this.tabtNum = 1
      this.fileList = []
      this.file.name = ''
      this.distinguishDis = this.submitDis = false
      this.domesticDetails()
    },
    //上传官文取消事件
    empty() {
      this.fileSH = false;
      this.$store.commit('setscroll', {
        scroll: this.fileSH,
      });
      this.$refs.ruleForm.resetFields()
      // this.$refs.automatic.resetFields()
      this.tabtNum = 1
      this.distinguishSh = true;
      this.contSH = false;
      this.$refs.upload.clearFiles()
      this.file = this.$options.data().file; //清空表单
    },


  },
};
</script>

<style lang="less" scoped>
.project{
  border-bottom: 1px solid #DFDFDF;
}
.citation{
  .list-item{
    width: 100px;
    border: 1px solid #DFDFDF;

    .item-t{
      width: 100%;
      height: 100px;
      border-bottom: 1px solid #DFDFDF;
      ::v-deep img{
        max-width: 100%;
        max-height: 98px;
      }
    }
    .item-b{
      span:nth-of-type(1){
        border-right: 1px solid #DFDFDF;
      }
    }

  }
}
</style>
