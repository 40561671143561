<template>
  <div class="">
    <h2>
      自有商标<span v-if="domesticList.length != 0">{{ pageSize }} </span>
    </h2>
    <!-- 表单 -->
    <form ref="filters" action="" v-on:submit.prevent>
      <el-row :class="{on:formOpen}" class="input formInput">
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>注册号</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-input

                  v-model="filters.regno"
                  clearable
                  placeholder="请输入注册号"
                  size="small"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>商标名称</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-input
                  v-model="filters.tmname"
                  clearable
                  placeholder="请输入商标名称"
                  size="small"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>

        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>商标类别</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-select
                  v-model="filters.intcls"
                  clearable
                  collapse-tags
                  filterable
                  multiple
                  placeholder="请选择商标类别"
                  size="small"
              >
                <el-option
                    v-for="item in intclsSelect"
                    :key="item.id"
                    :label="item.text"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>申请号</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
             
              <el-input
                  v-model="filters.appno"
                  clearable
                  placeholder="请输入申请号"
                  size="small"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>申请人</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-select
                  v-model="filters.appname"
                  clearable
                  filterable
                  placeholder="请选择申请人"
                  size="small"
              >
                <el-option
                    v-for="item in appnameSelect"
                    :key="item.text"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
              <!-- <el-input
                  v-model="filters.appno"
                  clearable
                  placeholder="请输入申请号"
                  size="small"
              ></el-input> -->
            </el-col>
          </el-row>
        </el-col>

        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>商标状态</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-select
                  v-model="filters.tmstatus"
                  clearable
                  filterable
                  placeholder="请选择商标状态"
                  size="small"
              >
                <el-option
                    v-for="item in tmstatusSelect"
                    :key="item.id"
                    :label="item.text"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>申请途径</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-select
                  v-model="filters.apply"
                  clearable
                  filterable
                  placeholder="请选择申请途径"
                  size="small"
              >
                <el-option
                    v-for="item in applySelect"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>

        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>国家地区</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-select
                  v-model="filters.country"
                  clearable
                  filterable
                  placeholder="请选择国家地区"
                  size="small"
              >
                <el-option
                    v-for="(item,index) in countrySelect"
                    :key="index"
                    :label="item.value"
                    :value="item.key"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>

        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>申请日期</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-date-picker
                  v-model="filters.appdate"

                  :picker-options="pickerOptions"
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="19">
          <el-row align="middle" type="flex" justify="space-between" :gutter="10">
            <el-col :lg="7" :md="9" :sm="9" :xl="4" :xs="6"><strong>注册日期</strong></el-col>
            <el-col :lg="17" :md="15" :sm="15" :xl="20" :xs="18">
              <el-date-picker

                  v-model="filters.regdate"
                  :picker-options="pickerOptions"
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>

      </el-row>
      <el-row class="formOpen">
        <el-col :class="{on:formOpen}" :xs="24">
          <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
        </el-col>
      </el-row>
      <el-button class="search" icon="el-icon-search" size="small" type="primary"
                 @click="perindexList(filters.page = 1)"
      >查询
      </el-button>
      <el-button :class="{on:formOpen}" class="reset" icon="el-icon-refresh-left" size="small" type="reset"
                 @click="reset"
      >重置
      </el-button
      >
    </form>
    <!-- 列表 -->
    <div class="mainrb">
      <div class="listT clearfix">
        <!--        <el-button  :loading="!down" icon="el-icon-document" :disabled="domesticList.length == 0 || loading" size="small" type="primary" @click="downloadDia = true">-->
        <!--          {{down ? '导出数据' : '导出中' }}-->
        <!--        </el-button>-->

        <div class="listTr fr">
          <strong :class="{ on: liston == 0 }" @click="liston = 0"
          ><img
              v-if="liston == 0"
              alt=""
              src="@/assets/img/search/icon-list2-1.png"/>
            <img v-else alt="" src="@/assets/img/search/icon-list2.png"
            /></strong>
          <strong :class="{ on: liston == 1 }" @click="liston = 1">
            <img
                v-if="liston == 1"
                alt=""
                src="@/assets/img/search/icon-list1.png"
            />
            <img v-else alt="" src="@/assets/img/search/icon-list1-1.png"/>
          </strong>


        </div>
      </div>
      <!--       表格列表-->
      <el-table
          v-show="liston == 1"
          :class="{on:!formOpen,ios:$store.state.heights}"
          :data="domesticList"
          height="90%"
          @sort-change="sort_change"

      >
        <template slot="empty">
          <el-empty :image-size="80"></el-empty>
        </template>
        <el-table-column label="序号" width="50">
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item class="w50" variant="p"/>
              </template>
              <span>{{ scope.$index + 1 }} </span>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column label="商标名称" min-width="120" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>

              <template >
                <div class="ellipsis">
                  {{ scope.row.tm_name || '图形'}}
                </div>
              </template>
            </el-skeleton>
          </template>

        </el-table-column>
        <el-table-column label="注册号" width="100">
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>
              <template>
                {{ scope.row.reg_no || '-'}}
              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column label="类别" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>
              <template>
                <div class="ellipsis">
                  <span v-for="(int,intInx) in scope.row.int_cls">{{int}} <font v-if="intInx != scope.row.int_cls.length - 1">,</font> </span>
                </div>

              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column
            label="申请号" 
        >
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated >
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>
              <template>
                 <span class="">
                  {{ scope.row.reg_no || '-' }}
                </span>
              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column
            label="申请人" min-width="120"
            :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated class="ellipsis">
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>
              <template>
                 <span class="col-blue">
                  {{ scope.row.applicant_cn || '-' }}
                </span>
              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column
            label="申请途径" min-width="120"
            :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated class="ellipsis">
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>
              <template>
                 <span >
                  {{ scope.row.apply || '-' }}
                </span>
              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column
            label="国家地区" min-width="120"
            :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated class="ellipsis">
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>
              <template>
                {{ scope.row.country || '-' }}
              </template>
            </el-skeleton>
          </template>
        </el-table-column>

        <el-table-column label="状态" min-width="100"  :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>
              <template>
                <el-tag
                    class="ellipsis max-w80"
                    :class="{green: scope.row.tm_status == '有效' ,tag4: scope.row.tm_status == '失效'}"
                    type=""
                    size="mini"
                >
                  {{ scope.row.tm_status }}
                </el-tag>
              </template>
            </el-skeleton>
          </template>
        </el-table-column>

        <el-table-column
            label="申请日期"
            width="120"
            sortable="custom"
        >
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>
              <template>
                {{ scope.row.app_date || '-' }}
              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column
            label="注册日期"
            width="120"
            sortable="custom"
        >
          <template slot-scope="scope" class="sort-jub">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item variant="p" class="w50"/>
              </template>
              <template >
                {{ scope.row.reg_date || '-' }}
              </template>
            </el-skeleton>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" >
          <template slot-scope="scope">
            <el-skeleton :count="1" :loading="loading" animated>
              <template slot="template">
                <el-skeleton-item class="w50" variant="p"/>
              </template>
              <template>
                <router-link
                    :to="{ name: 'interMarkDetali', params: { id: scope.row.id } }"
                    target="_blank">
                  <el-button icon="el-icon-edit-outline" size="small" type="text">详情</el-button>
                </router-link>
                <!--                <el-button icon="el-icon-upload2" class="upload2" size="small" type="text" @click="upload(scope.row.tmcode)">-->
                <!--                  上传-->
                <!--                </el-button>-->
              </template>
            </el-skeleton>
          </template>
        </el-table-column>
      </el-table>
      <!-- 块状列表 -->
      <div v-show="liston == 0" :class="{empty:domesticList.length == 0,on:!formOpen,ios:$store.state.heights}" class="list-list">
        <ul class="list_t flex flex-cen">

          <li>
            <span class="pointer" @click="sort == '' ? (filters.sort = 'app_date|asc',sort = 'appdateAsc') : (sort == 'appdateAsc' ? (filters.sort = 'app_date|desc',sort = 'appdateDes') : ((sort.indexOf('appdate') == -1) ?  (filters.sort = 'app_date|asc',sort = 'appdateAsc') : (filters.sort = '',sort = '')) ),perindexList() ">申请日期</span>
            <span class="caret-wrapper">
              <i class="sort-caret ascending" @click.stop="sort == 'appdateAsc' ? (sort = '',filters.sort = '') : (sort = 'appdateAsc',filters.sort = 'app_date|asc'),perindexList() " :class="{on:sort == 'appdateAsc'}"></i>
              <i class="sort-caret descending" @click.stop="sort == 'appdateDes' ? (sort = '',filters.sort = '') : (sort = 'appdateDes',filters.sort = 'app_date|desc'),perindexList() " :class="{on:sort == 'appdateDes'}"></i>
            </span>
          </li>
          <li>
            <span class="pointer" @click="sort == '' ? (filters.sort = 'reg_date|asc',sort = 'regdateAsc') : (sort == 'regdateAsc' ? (filters.sort = 'reg_date|desc',sort = 'regdateDes') : ((sort.indexOf('regdate') == -1) ?  (filters.sort = 'reg_date|asc',sort = 'regdateAsc') : (filters.sort = '',sort = ''))),perindexList() ">注册日期</span>
            <span class="caret-wrapper">
              <i class="sort-caret ascending" @click.stop="sort == 'regdateAsc' ? (sort = '',filters.sort = '') : (sort = 'regdateAsc',filters.sort = 'reg_date|asc'),perindexList()  " :class="{on:sort == 'regdateAsc'}"></i>
              <i class="sort-caret descending" @click.stop="sort == 'regdateDes' ? (sort = '',filters.sort = '') : (sort = 'regdateDes',filters.sort = 'reg_date|desc') ,perindexList() " :class="{on:sort == 'regdateDes'}"></i>
            </span>
          </li>
          <li>
            <span class="pointer" @click="sort == '' ? (filters.sort = 'end_date|asc',sort = 'enddateAsc') : (sort == 'enddateAsc' ? (filters.sort = 'end_date|desc',sort = 'enddateDes') : ((sort.indexOf('enddate') == -1) ?  (filters.sort = 'end_date|asc',sort = 'enddateAsc') : (filters.sort = '',sort = ''))),perindexList() ">专用期限至 </span>
            <span class="caret-wrapper">
              <i class="sort-caret ascending" @click.stop="sort == 'enddateAsc' ? (sort = '',filters.sort = '') : (sort = 'enddateAsc',filters.sort = 'end_date|asc'),perindexList()  " :class="{on:sort == 'enddateAsc'}"></i>
              <i class="sort-caret descending" @click.stop="sort == 'enddateDes' ? (sort = '',filters.sort = '') : (sort = 'enddateDes',filters.sort = 'end_date|desc') ,perindexList() " :class="{on:sort == 'enddateDes'}"></i>
            </span>
          </li>
        </ul>
        <el-empty v-if="domesticList.length == 0" :image-size="80"></el-empty>
        <div v-else class="list-cont">
          <el-skeleton :class="{on:!formOpen,ios:$store.state.heights}" :cont="1" :loading="loading" animated>
            <template slot="template">
              <div v-for="(item, index) in loadList" :key="index" class="listb">
                <div class="content">
                  <div class="">
                    <el-skeleton-item
                        style="width: 110px;height:110px"
                        variant="image"
                    />
                  </div>
                  <div class="contentC">
                    <p class="m-b20">
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                    </p>
                    <p class="m-b20">
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>

                    </p>
                    <p class="m-b20">
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                    </p>
                    <p>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                    </p>
                  </div>
                  <div class="details">
                    <el-skeleton-item
                        style="width: 100px"
                        variant="p"
                    />
                  </div>
                </div>
              </div>
            </template>
            <div v-for="(item, index) in domesticList" :key="index" class="listb">
              <div class="content">
                <div class="content-index">
                  {{index + 1}}
                </div>
                <div class="content-img ">
                  <div class="img-img  flex align-cen ju-con-cen">
                    <el-image :src="item.tm_image"
                              :preview-src-list="[item.tm_image]">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                  <div class="img-tmlaw ellipsis"
                       :class="{green: item.tm_status == '有效' ,tag4: item.tm_status == '失效','tag-primary':item.tm_status != '有效' || item.tm_status != '失效'}"
                  >
                    {{item.tm_status}}
                  </div>
                </div>
                <div class="contentC">
                  <p>

                    <span class="disinblk col-blue ellipsis font-16" >{{ item.tm_name || '图形'}}</span>
                    <span class="flex align-cen">国家地区：
                       <el-tooltip class="item" effect="dark" v-showtip :content="item.country" placement="top-start">
                        <font class=" ellipsis w80">
                          <font class="ellipse-text " >
                            {{ item.country || '-' }}
                          </font>
                        </font>
                      </el-tooltip>
                    </span>
                    <span>申请途径：{{ item.apply || '-'}} </span>

                  </p>
                  <p>
                    <span>申请号：{{ item.app_no || '-' }}</span>
                    <span>注册号：{{ item.reg_no || '-' }}</span>
                    <span>类别：<font v-for="(itemInt,intInx) in item.int_cls">{{ itemInt }} <font v-if="intInx != item.int_cls.length - 1">,</font> </font></span>


                  </p>
                  <p>
                    <span>申请日期：{{ item.app_date || '-' }}</span>
                    <span>注册日期：{{ item.reg_date || '-' }}</span>
                    <span>专用期限至：{{ item.end_date || '-' }}</span>
                  </p>
                  <p>

                    <span class=" flex align-cen" >
                      申请人：
                       <el-tooltip  class="item" effect="dark" v-showtip :content="item.applicant_cn" placement="top-start">
                         <span  class="w80 flex align-cen">
                           <font :class="{'col-blue':item.applicant_cn }" class="ellipse-text"> {{ item.applicant_cn || '-'}} </font>
                         </span>
                       </el-tooltip>

                    </span>

                    <span class="flex align-cen">代理机构：
                      <el-tooltip class="item" effect="dark" v-showtip :content="item.agent" placement="top-start">
                        <font class=" ellipsis w80">
                          <font class="ellipse-text " :class="{'col-blue':item.agent}">
                            {{ item.agent || '-' }}
                          </font>
                        </font>
                      </el-tooltip>

                    </span>

                  </p>

                  <p class=" flex align-cen">
                    商品项目：


                    <el-tooltip class="item"  popper-class="max-wTip" effect="dark" v-showtip placement="bottom-start">
                      <span slot="content" >
                        <font v-for="goods in item.tm_goods">
                          {{goods.code + '：' + goods.name}}
                        </font>
                      </span>
                      <span class="disinblk w80 ">
                         <span class="ellipse-text " >
                            <font v-for="goods in item.tm_goods">
                          {{goods.name}}
                        </font>
                         </span>
                      </span>
                    </el-tooltip>

                  </p>
                </div>
                <div class="details">
                  <router-link :to="{name: 'interMarkDetali',params: { id: item.id },}"
                               target="_blank">
                    <el-button icon="el-icon-edit-outline" size="small" type="primary">详情</el-button>
                  </router-link>
                  <!--                  <el-button icon="el-icon-upload2" plain size="small" type="primary" @click="upload(item.tmcode)"-->
                  <!--                  >上传-->
                  <!--                  </el-button>-->

                </div>
              </div>
            </div>
          </el-skeleton>
        </div>
      </div>
      <!-- 分页 -->
      <!-- 分页 -->
      <el-pagination
          :current-page.sync="filters.page"
          :page-size="filters.per_page"
          :page-sizes="[30, 50, 100]"
          :total="pageSize"
          layout="total, sizes, prev, pager, next, jumper,slot"
          @size-change="changeRowNum"
          @current-change="perindexList(backTop)"
      >
      </el-pagination>
    </div>

    <!-- 上传 -->
    <el-dialog
        :closeOnClickModal="false"
        :visible.sync="fileSH"
        class="file"
        title="上传文件"
        width="335px"
        @close="empty"
        @closed="empty"
    >


      <div class="cont-tabc">
        <el-form ref="ruleForm" :model="manual" :rules="manualRules" label-position="right" label-width="80px"
                 size="mini">
          <el-form-item label="官文类型" prop="matter">
            <el-select
                v-model="manual.matter"
                clearable
                filterable
                placeholder="请选择官文类型"
                size="small"
            >
              <el-option
                  v-for="item in typeSelect"
                  :key="item.text"
                  :label="item.text"
                  :value="item.text"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商标号">
            <el-input
                v-model="fileTmcode.tmcode"
                clearable
                placeholder="请输入商标号"
                readonly
                size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="收文日期" prop="made_at">
            <el-date-picker
                v-model="manual.made_at"
                placeholder="请选择收文日期"
                size="small"
                type="date"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <div class="tabc-update">

            <el-upload
                ref="upload"
                :before-upload="beforeUpload"
                :file-list="fileList"
                :http-request="uploadImg"
                :limit="1"
                :on-exceed="handleExceed"
                action="/api/upload"
                class="upload-demo"
            >
              <el-form-item prop="name">
                <el-input v-model="manual.name" placeholder="请点击按钮上传文件" readonly size="small"></el-input>
              </el-form-item>
              <el-button
                  v-if="uploadBtn"
                  slot="trigger"
                  :disabled="distinguishDis"
                  circle
                  icon="el-icon-plus"
                  size="mini"
                  type="primary"
              ></el-button>
              <el-button v-else slot="trigger" :loading="true" circle size="mini" type="primary"></el-button>
            </el-upload>

          </div>
          <div class="batch">
            官文太多？马上
            <router-link to="/personal/trademarkAdmin/upload">批量上传</router-link>
          </div>
          <div class="submitBtn">
            <el-button class="cancel" icon="el-icon-close" size="small" @click="fileSH = false">取 消</el-button>
            <el-button :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' : 'el-icon-folder-checked'"
                       class="confirm" size="small" type="primary"
                       @click="submit('ruleForm')">确 定
            </el-button>
          </div>

        </el-form>
      </div>

    </el-dialog>
    <!--    导出-->
    <el-dialog
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        :visible.sync="downloadDia"
        title="导出信息"
        class="openDia"
        width="576px"
        @open="openDia">
      <template>
        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="filters.fields" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="city in cities" :key="city" :label="city">{{ city }}</el-checkbox>
        </el-checkbox-group>
      </template>
      <span slot="footer" class="dialog-footer">
    <el-button icon="el-icon-close" size="small" @click="downloadDia = false">取 消</el-button>
    <el-button :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' : 'el-icon-folder-checked'" class="confirm"
               size="small" type="primary" @click="download">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import { globalTms, fileSubmit, fileUpload,tmReviewExport,globalTmsExport} from '@/api/personal.js'
// 导出全部字段集合
const cityOptions = ['商标图样', '商标名称','商标状态', '国家地区', '申请途径', '申请号', '注册号', '商标类别', '申请日期', '申请人', '注册日期', '专用权期限', '商品项目', '代理机构','最后流程'];
export default {
  name: "Objection",
  data() {
    return {
      replace:{//替换字段
        tm_goods:[],
        country:[],
        lawCont:[],
        judgement:{},
        review:'',
      },

      sort:'',//记录排序
      formOpen: false,//控制搜索栏展开或收起

      downloadDia: false,//控制导出对话框的显示与隐藏
      checkAll: false,//全选
      cities: cityOptions,//导出的全部选项
      isIndeterminate: true,//控制全选框状态

      backTop: true,
      down: true,//控制导出加载的显示与隐藏
      loading: true,//控制骨架屏的显示与隐藏
      pickerOptions: {//时间选择器的便利选择对象
        shortcuts: storage.getShortcuts()
      },
      domesticList: [],//官文文件数组
      appnameSelect:[],
      loadList: storage.getList(),//骨架屏的起始数组（10条数据）
      //官文文件查询表单字段
      filters: {
        regno: "", //注册号
        appno:'',//申请号
        country:"",//国家地区
        tmname: "", //商标名称
        intcls: "", //商标类别
        tmstatus:'',//商标状态
        apply:'',//申请途径
        appdate:'',//申请日期
        regdate:"",//注册日期
        result:"",//决定结果
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
        sort: "",//排序
        appname:'',
        fields: ['商标名称','商标状态','国家地区','申请途径','申请号','注册号','商标类别','申请日期','申请人','注册日期','专用权期限','商品项目','代理机构'],
      },

      tmstatusSelect:[],//商标状态，下拉框的所需内容
      applySelect:[],//申请途径下拉
      countrySelect:[],//国家地区下拉
      intclsSelect: storage.getIntcls(),
      last_page: 1,
      pageSize: 0, //总条数
      liston: 0,//控制是那种列表的tab切换(默认是table列表)

      fileTmcode: {
        tmcode: ''
      },
      uploadBtn: true,//控制上传文件加载中按钮的显示与隐藏
      fileList: [],//记录上传文件成功的数组
      loadingUp: true,//控制上传文件中识别文件的骨架屏
      fileSH: false,//控制上传官文弹窗的显示与隐藏
      tabtNum: 1,//控制手动和自动上传的tab切换
      file: {
        name: "",//上传官文的名称
        path: '',//上传官文的路径
      },
      distinguishSh: true,//控制识别按钮显示与隐藏
      distinguishDis: false,
      submitDis: false,
      contSH: false,//控制识别中的骨架屏
      typeSelect: [],//官文类型的下拉数据
      automatic: {//自动上传的字段
        matter: "",
        made_at: "",
        name: "",//上传官文的名称
      },

      manual: {//手动上传的字段
        matter: "", //官文名称
        made_at: "",
        name: ''
      },
      manualRules: {
        matter: [
          {required: true, message: '请选择官文类型', trigger: 'change'},
        ],
        made_at: [
          {required: true, message: '请选择收文日期', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请点击按钮上传文件', trigger: 'change'},
        ],
      },
    };
  },

  created() {
    this.select()
    this.perindexList();
  },

  methods: {
    // 下拉
    async select(){
      let s = await storage.getSelects('app_names')
      this.appnameSelect = s.app_names
      this.typeSelect = storage.getMaintainTypes()

    },
    //全选改变时
    handleCheckAllChange(val) {
      this.filters.fields = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    //单选改变时
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },

    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.perindexList(this.backTop);
    },
    //渲染列表事件
    async perindexList(backTop) {
      this.loading = true;
      this.domesticList = storage.getList()
      try {
        const res = await globalTms(this.filters)
        if (res && res.err_code === 0) {
          if (backTop === true) {
            storage.backTop()
          }
          this.loading = false
          this.pageSize = res.data.total
          this.domesticList = res.data.list
          this.domesticList.forEach((item) =>{
            let str = new RegExp(';', 'g')
            item.country = item.country.replace(str,'、').slice(0,item.country.length)
            let obj = {
              html:'',
              cont:''
            }
            item.tm_goods.forEach((goods) =>{
              obj.html += ( goods.code + '：' + goods.name)
              obj.cont += ( goods.name)
            })
            this.replace.tm_goods.push(obj)
          })

          
          this.tmstatusSelect = res.data.filter.tmstatus
          this.applySelect = res.data.filter.apply
          this.countrySelect = []
          for (let i in res.data.filter.countries){
            if(this.countrySelect.indexOf(res.data.filter.countries[i]) == -1){
              let obj = {}
              obj.key = i
              obj.value = res.data.filter.countries[i]
              this.countrySelect.push(obj)
            }
          }
        } else {
          this.domesticList = []
        }
      } finally {
        this.loading = false
      }
    },

    //导出事件
    openDia() {
      this.filters.fields = this.$options.data().filters.fields;
      this.checkAll = false
      this.isIndeterminate = true
    },
    //确定导出
    async download() {
      this.down = false
      this.downloadDia = false
      if (this.filters.fields.length != 0) {
        try {
          const res = await globalTmsExport(this.filters)
          location.href = res.data.url
          this.down = true
        } finally {
          this.down = true
        }
      } else {
        this.$message({
          message: '至少选择一个',
          type: 'warning'
        });
      }

    },

    //重置事件
    reset() {
      this.filters = this.$options.data().filters;
      this.sort = ''
      this.perindexList()
    },
    //排序事件
    sort_change(column) {
      if (column.column.label == '申请日期') {
        if (column.order === "descending") {
          this.filters.sort = "app_date|desc";
        } else {
          this.filters.sort = "app_date|asc";
        }
      } else {
        if (column.order === "descending") {
          this.filters.sort = "reg_date|desc";
        } else {
          this.filters.sort = "reg_date|asc";
        }
      }

      this.perindexList();
    },

    upload(val) {
      this.submitDis = false
      this.fileSH = true
      this.fileTmcode.tmcode = val
      this.$store.commit('setscroll',{
        scroll:this.fileSH,
      });
    },
    //文件上传时事件
    beforeUpload() {
      this.uploadBtn = false
    },
    //文件上传数量超过1个提示事件
    handleExceed(files, fileList) {
      this.$message.warning(
          `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`
      );
    },

    //文件上传后事件(自动上传)
    async uploadImg(item) {
      let formData = new FormData();
      formData.append("file", item.file);
      formData.append("is_base64", 0);
      try {
        const res = await fileUpload(formData)
        if (res && res.err_code === 0) {
          this.uploadBtn = true
          this.file.name = this.automatic.name = this.manual.name = res.data.name;
          this.file.path = res.data.path;
          this.$refs.upload.clearFiles()
        } else {
          this.$message({
            type: "warning",
            message: res.err_msg,
          })
        }
      } finally {
        this.uploadBtn = true;
      }
    },

    //上传官文提交事件
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.distinguishDis = this.submitDis = true
            const res = await fileSubmit({...this.file, ...this.manual, ...this.fileTmcode})
            if (res && res.err_code === 0) {
              this.$refs[formName].resetFields();
              this.success()
            } else {
              this.$message({
                type: "warning",
                message: res.err_msg,
              })
              this.fileList = []
              this.distinguishDis = this.submitDis = false
            }
          } catch (e) {
            this.fileList = []
            this.distinguishDis = this.submitDis = false
          }
        } else {
          return false;
        }
      })


    },
    //上传官文提交成功事件
    success() {
      this.$message({
        type: "success",
        message: "提交成功!",
      });
      this.fileSH = false;
      this.$store.commit('setscroll',{
        scroll:this.fileSH,
      });
      this.manual = this.$options.data().manual; //清空表单
      this.automatic = this.$options.data().automatic; //清空表单
      this.distinguishSh = true;
      this.contSH = false;
      this.tabtNum = 1
      this.fileList = []
      this.file.name = ''
      this.distinguishDis = this.submitDis = false
      this.domesticDetails()
    },
    //上传官文取消事件
    empty() {
      this.fileSH = false;
      this.$store.commit('setscroll',{
        scroll:this.fileSH,
      });
      this.$refs.ruleForm.resetFields()
      // this.$refs.automatic.resetFields()

      this.tabtNum = 1
      this.distinguishSh = true;
      this.contSH = false;
      this.$refs.upload.clearFiles()
      this.file = this.$options.data().file; //清空表单
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/domestic.less";

div{
  .mainrb{
    .list-list {
      .listb {
        .content{
          .contentC {

            p:nth-last-of-type(1),p:nth-last-of-type(2) {
              margin-bottom: 0;

            }
            p:nth-last-of-type(1){
              justify-content: unset;
              span{
                width: 85%;
              }
            }
            p:nth-last-of-type(2){

              span{
                width: 49%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .el-dropdown-menu {
    right: 10px;
  }

  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 760px") !important;
  }

  .ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 800px") !important;
    }
  }
  .list-list>div>div{
    height: calc(~"100vh - 760px") ;
  }
  .list-list>div>div.ios{
    height: calc(~"100vh - 800px") ;
  }

  .list-list.empty{
    height: calc(~"100vh - 728px");
  }
  .list-list.empty.ios{
    height: calc(~"100vh - 768px");
  }
  .formInput.on {
    height: 410px;
  }
}

@media screen and (min-width: 768px) {
  .el-dropdown-menu {
    right: 10px;
  }

  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 550px") !important;
  }

  .ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 59px") !important;
    }
  }
  .list-list>div>div{
    height: calc(~"100vh - 550px") ;
  }
  .list-list>div>div.ios{
    height: calc(~"100vh - 590px") ;
  }

  .list-list.empty{
    height: calc(~"100vh - 508px");
  }
  .list-list.empty.ios{
    height: calc(~"100vh - 548px");
  }
  .formInput.on {
    height: 200px;
  }
}

@media screen and (min-width: 992px) {
  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 510px") !important;
  }
  .el-table.ios{
    ::v-deep .el-table__body-wrapper {
      height: calc(~"var(--vh,1vh) * 100 - 550px") !important;
    }
  }
  .list-list>div>div{
    height: calc(~"100vh - 510px") ;
  }
  .list-list>div>div.ios{
    height: calc(~"100vh - 550px") ;
  }

  .list-list.empty{
    height: calc(~"100vh - 468px");
  }
  .list-list.empty.ios{
    height: calc(~"100vh - 528px");
  }

  .formInput.on {
    height: 160px;
  }
}

@media screen and (min-width: 1200px) {
  .el-dropdown-menu {
    right: 30px;
  }
}

@media screen and (min-width: 1920px) {
  .el-dropdown-menu {
    right: 3%;
  }


  form {

    .el-col-lg-7 {
      width: 29.16667%;
    }

  }
}

</style>
