<template>
  <div class="">
    <h2>行政诉讼申请<span v-if="applyList.length != 0">{{ pageSize }} </span></h2>
    <form ref="filters" v-on:submit.prevent>
      <el-row :class="{on:formOpen}" :gutter="10" class="formInput">
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row align="middle" type="flex" :gutter="10" justify="space-between">
            <el-col :lg="8" :md="8" :sm="9" :xl="5" :xs="5">
              <strong>商标名称</strong>
            </el-col>
            <el-col :lg="16" :md="16" :sm="15" :xl="19" :xs="19">
              <el-input v-model="filters.tmname" clearable placeholder="请输入商标名称" size="small"></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row align="middle" type="flex" :gutter="10" justify="space-between">
            <el-col :lg="8" :md="8" :sm="9" :xl="5" :xs="5">
              <strong>商标号</strong>
            </el-col>
            <el-col :lg="16" :md="16" :sm="15" :xl="19" :xs="19">
              <el-input v-model="filters.tmcode" clearable placeholder="请输入商标号" size="small"></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row align="middle" type="flex" :gutter="10" justify="space-between">
            <el-col :lg="8" :md="8" :sm="9" :xl="5" :xs="5">
              <strong>商标类别</strong>
            </el-col>
            <el-col :lg="16" :md="16" :sm="15" :xl="19" :xs="19">
              <el-select
                  v-model="filters.intcls"
                  clearable
                  collapse-tags
                  filterable
                  multiple
                  placeholder="请选择商标类别"
                  size="small"
              >
                <el-option
                    v-for="(item,index) in intclsSelect"
                    :key="index"
                    :label="item.text"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row align="middle" type="flex" :gutter="10" justify="space-between">
            <el-col :lg="8" :md="8" :sm="9" :xl="5" :xs="5">
              <strong>诉讼类型</strong>
            </el-col>
            <el-col :lg="16" :md="16" :sm="15" :xl="19" :xs="19">
              <el-select
                  v-model="filters.type"
                  clearable
                  filterable
                  placeholder="请选择诉讼类型"
                  size="small"
              >
                <el-option
                    v-for="item in typeSelect"
                    :key="item.id + 30"
                    :label="item.text"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row align="middle" type="flex" :gutter="10" justify="space-between">
            <el-col :lg="8" :md="8" :sm="9" :xl="5" :xs="5">
              <strong>原告/上诉人</strong>
            </el-col>
            <el-col :lg="16" :md="16" :sm="15" :xl="19" :xs="19">
              <el-select
                  v-model="filters.appname"
                  clearable
                  filterable
                  placeholder="请选择原告/上诉人"
                  size="small"
              >
                <el-option
                    v-for="item in appSelect"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row align="middle" type="flex" :gutter="10" justify="space-between">
            <el-col :lg="8" :md="8" :sm="9" :xl="5" :xs="5">
              <strong>第三人</strong>
            </el-col>
            <el-col :lg="16" :md="16" :sm="15" :xl="19" :xs="19">
              <el-select
                  v-model="filters.third"
                  clearable
                  filterable
                  placeholder="请选择第三人"
                  size="small"
              >
                <el-option
                    v-for="item in thirdSelect"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row align="middle" type="flex" :gutter="10" justify="space-between">
            <el-col :lg="8" :md="8" :sm="9" :xl="5" :xs="5">
              <strong>当前进度</strong>
            </el-col>
            <el-col :lg="16" :md="16" :sm="15" :xl="19" :xs="19">
              <el-select
                  v-model="filters.status"
                  clearable
                  filterable
                  placeholder="请选择当前进度"
                  size="small"
              >
                <el-option
                    v-for="(item,index) in statusSelect"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row align="middle" type="flex" :gutter="10" justify="space-between">
            <el-col :lg="8" :md="8" :sm="9" :xl="5" :xs="5">
              <strong>判决结果</strong>
            </el-col>
            <el-col :lg="16" :md="16" :sm="15" :xl="19" :xs="19">
              <el-select
                  v-model="filters.result"
                  clearable
                  filterable
                  placeholder="请选择判决结果"
                  size="small"
              >
                <el-option
                    v-for="(item,index) in resultSelect"
                    :key="index+ 120"
                    :label="item.text"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>

      </el-row>
      <el-row class="formOpen">
        <el-col :class="{on:formOpen}" :span="24">
          <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
        </el-col>
      </el-row>
      <el-button class="search" icon="el-icon-search" size="small" type="primary"
                 @click="perindexList(filters.page = 1)"
      >查询
      </el-button>
      <el-button :class="{on:formOpen}" class="reset" icon="el-icon-refresh-left" size="small" type="reset"
                 @click="reset"
      >重置
      </el-button
      >
    </form>
    <div class="mainrb">
      <div class="listT p10" >
        <el-button :loading="down" icon="el-icon-document" :disabled="applyList.length == 0 || loading" size="small" type="primary" @click="diaClick">
          {{down ? '导出中' :  '导出数据' }}
        </el-button>

      </div>
      <el-skeleton :count="1" :loading="loading" animated>
        <template slot="template">
          <el-table
              :class="{on:!formOpen,ios:$store.state.heights}"
              :data="applyList"
              height="100%"
              style="width: 100%">
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table :data="props.row.documents" style="width: 80%">
                  <el-empty description="描述文字"></el-empty>
                  <el-table-column
                      label="序号"
                      type="index"
                      width="50">
                  </el-table-column>
                  <el-table-column
                      label="文件名称"
                      prop="name">
                  </el-table-column>
                  <el-table-column
                      label="上传日期"
                      prop="created_at">
                  </el-table-column>
                  <el-table-column
                      label="操作">
                    <template slot-scope="scope">
                      <router-link :to="scope.row.url">
                        <el-skeleton-item variant="p"/>
                      </router-link>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column
                label="序号">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="商标号">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="商标名称">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="商标类别">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>

            <el-table-column
                label="原告/上诉人"  width="160">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="诉讼类型">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column  label="金额" v-if="user.type != 0">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="第三人">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="当前进度">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="判决结果">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="创建日期">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="操作">
              <template slot-scope="scope">
                <template>
                  <el-skeleton-item variant="p" class="w50"/>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-table
            :class="{on:!formOpen,ios:$store.state.heights}"
            :data="applyList"
            height="100%"
            style="width: 100%">
          <template slot="empty">
            <el-empty :image-size="80"></el-empty>
          </template>
          <el-table-column class="cont" type="expand">
            <template slot-scope="props" class="cont">
              <el-table :data="props.row.documents" style="width: 94%">
                <el-table-column
                    label="序号"
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column
                    label="官文类型" :show-overflow-tooltip="true">
                  <template slot-scope="scope">
                    <span v-if="user.type == 0 && Math.floor((new Date().getTime() - new Date(scope.row.created_at).getTime())/(24 * 60 * 60 * 1000)) > 30" class="col-blue" @click="over">{{ scope.row.matter }}</span>

                    <a
                        v-else
                        class="ellipsis"
                        :href="`${scope.row.url}&preview=true`"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                      {{ scope.row.matter }}
                    </a>
                  </template>
                </el-table-column>


                <el-table-column
                    label="上传日期"
                    prop="created_at">
                  <template slot-scope="scope">
                    {{ scope.row.created_at || '-' }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="操作">
                  <template slot-scope="scope">
                    <el-button v-if="user.type == 0 && Math.floor((new Date().getTime() - new Date(scope.row.created_at).getTime())/(24 * 60 * 60 * 1000)) > 30" icon="el-icon-download" size="small" type="text" @click="over">下载
                    </el-button>
                    <el-button v-else icon="el-icon-download" size="small" type="text" @click="open(props.row,scope.row)">下载
                    </el-button>

                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>

          <el-table-column
              label="序号" type="index">
          </el-table-column>
          <el-table-column
              label="商标号"
              prop="tmcode"
          width="90">
            <template slot-scope="scope">
              <router-link :to="{
                name: 'tradDetails',
                params: { tmcode: scope.row.tmcode, intcls: scope.row.intcls,alias:0 },
              }" target="_blank">
                {{ scope.row.tmcode }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column
              label="商标名称" width="160">
            <template slot-scope="scope">
              <div class="tmimage">
                <div class="">
                  <el-image
                      :preview-src-list="[scope.row.tmimage]"
                      :src="scope.row.tmimage"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
                <p class="ver-line3 vertical" >{{scope.row.tmname || '图形'}}  </p>
              </div>

            </template>
          </el-table-column>
          <el-table-column
              label="商标类别"
              prop="clstext"
              >
          </el-table-column>

          <el-table-column
              label="原告/上诉人"
              min-width="160"
              :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <router-link v-if="scope.row.applicant_id != 0"
                           class="ellipse-text ellipsis"
                           :to="{
                  name: 'newlyBuild',
                    params: { id:  scope.row.applicant_id  },
                }" target="_blank">
                {{ scope.row.applicant || '-' }}
              </router-link>
              <template v-else>
                <span class="ellipse-text disblk ellipsis">{{scope.row.applicant || '-'}}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column
              label="诉讼类型"
              prop="typetext"
              min-width="160">
            <template slot-scope="scope">
                <el-tooltip class="item" :content="scope.row.typetext" effect="dark" v-showtip placement="top-start">
                  <p class="ellipsis">
                    <span class="ellipse-text ">{{scope.row.typetext}}</span>
                  </p>

                </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
              label="金额"
              v-if="user.type != 0"
          >
            <template slot-scope="scope">
              ￥{{scope.row.subtotal}}
            </template>
          </el-table-column>
          <el-table-column
              label="第三人"
              min-width="160"
              :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ scope.row.third || '-' }}
            </template>
          </el-table-column>
          <el-table-column
              label="当前进度" width="90">
            <template slot-scope="scope">
              <el-tag
                  :type="scope.row.status == '文书准备' ? '' : (scope.row.status == '已开庭' ? 'warning' : 'success') "
                  size="mini"
              >
                {{ scope.row.status }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
              label="判决结果"
              prop="typetext"
              width="90">
            <template slot-scope="scope">
              <el-tag :type="scope.row.result === 0 ? 'danger' : (scope.row.result === 1 ? 'success' : '') "
                      size="mini">
                {{ scope.row.result === 0 ? '输' : (scope.row.result === 1 ? '赢' : '未判决') }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
              label="创建日期"
              prop="deliver_at" width="110" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
              label="操作">
            <template slot-scope="scope">
              <router-link :to="{
                         name: 'litigationDetail',
            params: { id: scope.row.id},
            }" target="_blank">
                <el-button icon="el-icon-edit-outline" size="small" type="primary">详情</el-button>
              </router-link>
              <!--              <el-button size="small" type="primary" icon="el-icon-message" plain>报送</el-button>-->
              <!--              <el-button size="small" icon="el-icon-close" plain>取消</el-button>-->
            </template>
          </el-table-column>
        </el-table>
      </el-skeleton>
      <!-- 分页 -->
      <el-pagination
          :current-page.sync="filters.page"
          :page-size="filters.per_page"
          :page-sizes="[30, 50, 100]"
          :total="pageSize"
          layout="total, sizes, prev, pager, next, jumper,slot"
          @size-change="changeRowNum"
          @current-change="perindexList()"
      >
      </el-pagination>
    </div>

    <el-dialog
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        :visible.sync="downloadDia"
        title="导出信息"
        width="576px"
        class=" openDia"
        @open="openDia">
      <template>
        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="filters.fields" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="city in cities" :key="city" :label="city">{{ city }}</el-checkbox>
        </el-checkbox-group>
      </template>
      <span slot="footer" class="dialog-footer">
    <el-button icon="el-icon-close" size="small" @click="downloadDia = false">取 消</el-button>
    <el-button :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' : 'el-icon-folder-checked'" class="confirm"
               size="small" type="primary" @click="download">确 定</el-button>
  </span>
    </el-dialog>

    <el-dialog
        class="editionDia"
        title="提示"
        :visible.sync="editionDia"
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        width="390px">
      <div class="text-cen flex align-cen"><i class="el-icon-warning m-r10"></i> 当前账号没有此操作权限，请升级至<span
          class="font-wbold col-red ">“企业版”</span>！
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" size="small" @click="editionDia = false">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import {applyLawsuits,exportLawsuits} from '@/api/personal.js'
const cityOptions = ['商标图样', '商标名称',  '商标号', '商品类别',  '原告/上诉人', '诉讼类型', '金额', '第三人','当前进度', '判决结果','创建日期', '商品项目'];
export default {
  name: "Litigation",
  data() {
    return {
      user:storage.getUser(),
      down: false,//控制导出加载的显示与隐藏
      downloadDia: false,//控制导出的对话框
      checkAll: false,
      cities: cityOptions,
      isIndeterminate: true,
      submitDis: false,
      editionDia:false,

      formOpen: false,
      loading: true,//控制列表的骨架屏
      applyList: [],
      pickerOptions: {//日期选择器的便利选择对象
        shortcuts: storage.getShortcuts()
      },
      filters: {
        tmcode: '',//商标号
        tmname: '',//商标名称
        appname: '',//申请人
        deliver_at: '',//报送日期
        type: '',//申请类型
        status: '',//当前进度
        intcls: '',//商标类别
        result: '',
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
        checkList: [],//记录满三年和可续展选中时的数组
        fields: ['商标名称',  '商标号', '商品类别',  '原告/上诉人', '诉讼类型', '金额','第三人', '当前进度', '判决结果','创建日期'],
      },
      //商标类别，下拉框的所需内容
      intclsSelect: storage.getIntcls(),
      typeSelect: [
        {id: '50', text: '对商评委决定不服的行政诉讼一审（原告）'},
        {id: '51', text: '对商评委决定不服的行政诉讼一审（第三人）'},
        {id: '52', text: ' 对商评委决定不服的行政诉讼二审（上诉人）'},
        {id: '53', text: '对商评委决定不服的行政诉讼二审（被上诉人/第三人）'},
        {id: '54', text: ' 对二审法院判决不服的行政诉讼再审（申请人）'},
        {id: '55', text: '对二审法院判决不服的行政诉讼再审（第三人）'},
      ],
      statusSelect: [{id: '1', text: '文书准备'}, {id: '2', text: '已庭审'}, {id: '3', text: '已结案'}],
      resultSelect: [{id: '0', text: '输'}, {id: '1', text: '赢'}, {id: '2', text: '未判决'}],
      appSelect: [],
      thirdSelect: [],
      last_page: 1,
      pageSize: 0, //总条数
    }
  },
  created() {
    this.perindexList()
    this.select()
  },
  methods: {
    handleCheckAllChange(val) {
      this.filters.fields = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    diaClick(){
      if(storage.getUser().type != 0){
        this.downloadDia = true
      }else{
        this.editionDia = true
      }

    },
    //导出事件
    openDia() {
      this.filters.fields = this.$options.data().filters.fields;
      this.checkAll = false
      this.isIndeterminate = true
    },
    async download() {
      if(storage.getUser().type == 0){
        this.downloadDia = false
        this.$message({
          message: '当然账号没有此操作权限，请升级至“企业版”！',
          type: 'warning'
        });
      }else{
        this.down = true
        this.downloadDia = false
        if (this.filters.fields.length != 0) {
          try {
            const res = await exportLawsuits(this.filters)
            if(res && res.err_code === 0){
              location.href = res.data.url
              this.down = false
            }else{
              this.$message({
                message: res.err_msg,
                type: 'warning'
              });
            }

          } finally {
            this.down = false
          }
        } else {
          this.$message({
            message: '至少选择一个',
            type: 'warning'
          });
        }
      }


    },
    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.perindexList();
    },
    // 下拉
    async select() {
      let s = await storage.getSelects('lawsuit_applicant', 'lawsuit_third')
      this.appSelect = s.lawsuit_applicant
      this.thirdSelect = s.lawsuit_third
    },
    //渲染列表事件
    async perindexList() {
      this.loading = true;

      this.applyList = storage.getList()
      try {
        const res = await applyLawsuits(this.filters)
        if (res && res.err_code === 0) {
          this.loading = false
          this.applyList = res.data.list
          this.pageSize = res.data.total
        } else {
          this.applyList = []
        }
      } finally {
        this.loading = false
      }
    },

    reset() {
      this.filters = this.$options.data().filters;
      this.perindexList(this.filters.page = 1)
    },
    // 下载
    open(item,url) {
   let name = item.tmcode + '-' + item.intcls + '-' + (item.tmname ? item.tmname : '图形') + '-' + url.matter +  '.' + url.name.split('.')[1]
      location.href = url.url.replace(url.name,name)
    },
    over(){
      this.$confirm('已超出30天免费下载期，开通企业版可享无限期免费下载，详情请联系知产顾问！', '提示', {
        confirmButtonText: '确定',
        showCancelButton:false,
        type: 'warning'
      })
    },
  }
}
</script>

<style lang="less" scoped>
@import "../../style/apply.less";
@media screen and (max-width: 768px) {


  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 644px") !important;
  }
  .el-table.on {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 392px") !important;
    }
  }

  .ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 684px") !important;
    }
  }
  .el-table.on.ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 432px") !important;
    }
  }

}
</style>





