<template>
  <div class="detail">
    <h2>商标详情</h2>
    <el-skeleton :loading="loading" animated>
      <template slot="template">
        <el-row class="domesticDetail">
          <el-col :sm="3" :md="2">
            <div class="">
              <el-skeleton-item
                  class="domesticDetails-logo"
                  style="width: 60px;height: 60px"
                  variant="image"
              />
            </div>

          </el-col>
          <el-col :sm="19" :md="22">
            <el-row>
              <el-col :span="8">
                <h3 > <el-skeleton-item
                    style="width: 100px;"
                    variant="p"
                /></h3>
                <p>当前状态： <el-skeleton-item style="width: 100px;" variant="p"/></p>
              </el-col>
              <el-col :span="8">
                商品类别：<el-skeleton-item style="width: 100px;" variant="p"/>
              </el-col>
              <el-col :span="8">
                商标号：<el-skeleton-item style="width: 100px;" variant="p"/>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <div class="table-cont">
          <table class="domesticDetailst-cont" width="100%">
            <tr>
              <td>商标名称</td>
              <td>
                <el-skeleton-item variant="p"/>
              </td>
              <td>别名</td>
              <td>
                <el-skeleton-item variant="p"/>
              </td>
            </tr>
            <tr>
              <td>法律状态</td>
              <td>
                <el-skeleton-item variant="p"/>
              </td>
              <td>商标号</td>
              <td>
                <el-skeleton-item variant="p"/>
              </td>
            </tr>
            <tr>
              <td>商品类别</td>
              <td>
                <el-skeleton-item variant="p"/>
              </td>
              <td>申请日期</td>
              <td>
                <el-skeleton-item variant="p"/>
              </td>
            </tr>
            <tr>
              <td rowspan="2">商标图片</td>
              <td class="img" rowspan="2">
                <el-skeleton-item style="width: 130px;height: 130px;margin:0 auto;" variant="image"/>
                <el-skeleton-item variant="p" style="width: 130px;margin:5px auto 0 auto;"/>
              </td>
              <td rowspan="1">申请人名称</td>
              <td rowspan="1">
                <el-skeleton-item variant="p"/>
              </td>
            </tr>

            <tr>
              <td rowspan="1">申请人地址</td>
              <td rowspan="1">
                <el-skeleton-item variant="p"/>
              </td>
            </tr>

            <tr>
              <td>商品/服务项目</td>
              <td class="table-services" colspan="3">
                <span><el-skeleton-item variant="p"/></span>
              </td>
            </tr>
            <tr>
              <td>初审公告期号</td>
              <td>
                <el-skeleton-item variant="p"/>
              </td>
              <td>初审公告日期</td>
              <td>
                <el-skeleton-item variant="p"/>
              </td>
            </tr>
            <tr>
              <td>注册公告期号</td>
              <td>
                <el-skeleton-item variant="p"/>
              </td>
              <td>注册公告日期</td>
              <td>
                <el-skeleton-item variant="p"/>
              </td>
            </tr>

            <tr>
              <td>专用权期限</td>
              <td>
                <el-skeleton-item variant="p"/>
              </td>
              <td>代理人名称</td>
              <td>
                <el-skeleton-item variant="p"/>
              </td>
            </tr>

            <tr>
              <td>商标状态</td>
              <td colspan="3">
                <el-skeleton-item variant="p"/>
              </td>
            </tr>
          </table>
        </div>
        <div class="domesticDetails-list">
          <h2>
            官文信息
            <div class="fr">
              <el-skeleton-item variant="p" style="width: 100px"/>

              <el-skeleton-item variant="p" style="width: 100px"/>
            </div>
          </h2>

          <el-table
              :data="docs"
              style="width: 100%"
              tooltip-effect="dark"
              @selection-change="handleSelectionChange"
          >
            <template slot="empty">
              <el-empty></el-empty>
            </template>
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="序号" width="50">
              <template>
                <el-skeleton-item style="width: 100%" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="官文名称">
              <template>
                <el-skeleton-item style="width: 50%" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="收文日期">
              <template>
                <el-skeleton-item style="width: 50%" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="上传日期">
              <template>
                <el-skeleton-item style="width: 50%" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template>
                <el-skeleton-item style="width: 50%" variant="p"/>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
      <template>
        <el-row class="domesticDetail">
          <el-col :xs="4" :sm="3" :md="2">
            <div class="">
              <img :src="detail.tmimage" alt="" class="domesticDetails-logo"/>
            </div>

          </el-col>
          <el-col :xs="18" :sm="19" :md="22">
                <el-row>
                  <el-col :span="8">
                    <h3 v-if="detail.tmname == ''">图形</h3>
                    <h3 v-else>{{ detail.tmname }}</h3>
                    <p>当前状态： {{ detail.tmlaw }}</p>
                  </el-col>
                  <el-col :span="8">
                    商品类别：{{ detail.clstext }}
                  </el-col>
                  <el-col :span="8">
                    商标号：{{ detail.tmcode }}
                  </el-col>
                </el-row>
          </el-col>
        </el-row>
        <div class="table-cont">
          <table class="domesticDetailst-cont" width="100%">
            <tr>
              <td >商标名称</td>
              <td >{{
                  (!detail.tmname || detail.tmname == '图形') ? '图形' : detail.tmname
                }}
              </td>
              <td :class="{alias:(detail.alias == null )}">{{ (!detail.tmname || detail.tmname == '图形') ? '别名' : '' }} </td>
              <td v-if="!detail.tmname || detail.tmname == '图形'"
                  v-click-outside="handleClose">
                <div v-if="aliaSh" class="">
                  <el-input
                      v-model="filters.alias"
                      v-focus
                      placeholder="请输入别名"
                      size="small"
                      @change="input"
                  ></el-input>
                  <i class="el-icon-check" @click="input"></i>

                </div>
                <div v-else class="">
                  <span @dblclick="aliaSh = true">{{ filters.alias || '-'}}</span>
                  <el-tooltip class="item" content="双击文字编辑别名" effect="dark" placement="right">
                    <i class="el-icon-info"></i>
                  </el-tooltip>

                </div>
              </td>
            </tr>
            <tr>
              <td>法律状态</td>
              <td>
                <el-tag size="mini"
                        :class="{green:detail.tmlaw == '已注册',classA:detail.tmlaw == '申请中'}"
                        :type="detail.tmlaw == '已初审' ? 'success' : (detail.tmlaw == '已销亡' ? 'info' : (detail.tmlaw == '已驳回' ? 'danger' : '')) ">
                  {{ detail.tmlaw || '-'}}
                </el-tag>
              </td>
              <td>商标号</td>
              <td>{{ detail.tmcode || '-'}}</td>
            </tr>
            <tr>
              <td>商品类别</td>
              <td>{{ detail.clstext || '-'}}</td>
              <td>申请日期</td>
              <td>{{ detail.appdate || '-'}}</td>
            </tr>
            <tr>
              <td rowspan="2">商标图片</td>
              <td class="img" rowspan="2">
                <el-image :preview-src-list="srcList" :src="detail.tmimage">
                </el-image>
                <p>点击查看大图</p>
              </td>
              <td rowspan="1">申请人名称</td>
              <td rowspan="1">
                <router-link v-if="detail.client_id != 0" :to="{
                  name: 'newlyBuild',
                    params: { id: detail.client_id  },
                }" target="_blank" style="display: inline-block;color: #3291F8;">
                  {{detail.appname}}
                </router-link>
                <template v-else>
                  {{detail.appname || '-'}}
                </template>
              </td>
            </tr>

            <tr>
              <td rowspan="1">申请人地址</td>
              <td rowspan="1">{{ detail.appaddr || '-' }}</td>
            </tr>

            <tr>
              <td>商品/服务项目</td>
              <td class="table-services" colspan="3">
                <span>{{ detail.tmgoods || '-' }}</span>
              </td>
            </tr>
            <tr>
              <td>初审公告期号</td>
              <td>
                  {{ detail.csggqh || '-'  }}
              </td>
              <td>初审公告日期</td>
              <td>
                {{ detail.csdate || '-'  }}
              </td>
            </tr>
            <tr>
              <td>注册公告期号</td>
              <td>
                {{ detail.zcggqh || '-'  }}
              </td>
              <td>注册公告日期</td>
              <td>
                {{ detail.regdate  || '-'  }}
              </td>
            </tr>

            <tr>
              <td>专用权期限</td>
              <td>
                {{ detail.enddate || '-'  }}
              </td>
              <td>代理人名称</td>
              <td>
                <font>{{ detail.agentname }}</font>
              </td>
            </tr>

            <tr>
              <td>商标状态</td>
              <td colspan="3">
                <p v-for="(item,index) in detail.flows" v-if="flowsSH" :key="index">
                  <span>{{ item.flowdate }} {{ item.flowname }} </span>
                </p>
                <p v-else>-</p>
              </td>
            </tr>
          </table>
        </div>
        <div class="domesticDetails-list">
          <h2>
            官文信息
            <div class="fr">
              <el-button icon="el-icon-upload2" size="small" type="primary" @click="upload"
              >上传官文
              </el-button
              >
              <span v-if="downSH">
          <el-button v-if="down" icon="el-icon-download" size="small" @click="download" :disabled="detail.docs.length == 0">批量下载</el-button>
          <el-button v-else :loading="true" size="small">下载中</el-button>
        </span>

            </div>
          </h2>

          <el-table
              :data="detail.docs"
              style="width: 100%"
              @selection-change="handleSelectionChange"
          >
            <template slot="empty">
              <el-empty :image-size="80"></el-empty>
            </template>
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column label="官文类型">
              <template slot-scope="scope">
                <a :href="`${scope.row.url}&preview=true`"
                   target="_blank">
                  {{ scope.row.matter }}
                </a>
              </template>
            </el-table-column>
            <el-table-column label="收文日期" prop="made_at"></el-table-column>
            <el-table-column label="上传日期" prop="created_at"></el-table-column>
            <el-table-column fixed="right" label="操作" width="200">
              <template slot-scope="scope">
                <div class="operation">
                  <el-button icon="el-icon-download" size="small" type="primary" @click="open(scope.row.url)">下载
                  </el-button>
                  <el-button v-if="scope.row.is_custom == 1" icon="el-icon-delete" size="small"
                             type="danger" @click="deleteFile(scope.row.id)">删除
                  </el-button>

                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </el-skeleton>

    <!-- 上传 -->
    <el-dialog
        :closeOnClickModal="false"
        :visible.sync="fileSH"
        class="file"
        title="上传文件"
        width="335px"
        @close="empty"
        @closed="empty"
    >
<!--      <div v-if="identification" class="cont-tab">-->
<!--        <p @click="tabtNum = 1">-->
<!--          <span :class="{ checked: tabtNum == 1 }" class="el-checkbox__input">-->
<!--            <span class="el-checkbox__inner"> </span>-->
<!--            <input-->
<!--                aria-hidden="false"-->
<!--                class="el-checkbox__original"-->
<!--                type="checkbox"-->
<!--                value="手动添加"-->
<!--            />-->
<!--          </span>-->

<!--          <font>手动添加</font>-->
<!--        </p>-->
<!--        <p @click="tabtNum = 2">-->
<!--          <span :class="{ checked: tabtNum == 2 }" class="el-checkbox__input">-->
<!--            <span class="el-checkbox__inner"> </span>-->
<!--            <input-->
<!--                aria-hidden="false"-->
<!--                class="el-checkbox__original"-->
<!--                type="checkbox"-->
<!--                value="手动添加"-->
<!--            />-->
<!--          </span>-->
<!--          <font>智能识别</font>-->
<!--        </p>-->
<!--      </div>-->

      <div  class="cont-tabc">
        <el-form ref="ruleForm" :model="manual" :rules="manualRules" label-position="right" label-width="80px"
                 size="mini">
          <el-form-item label="官文类型" prop="matter">
            <el-select
                v-model="manual.matter"
                clearable
                filterable
                placeholder="请选择官文类型"
                size="small"
            >
              <el-option
                  v-for="item in typeSelect"
                  :key="item.text"
                  :label="item.text"
                  :value="item.text"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商标号">
            <el-input
                v-model="filters.tmcode"
                clearable
                readonly
                placeholder="请输入商标号"
                size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="收文日期" prop="made_at">
            <el-date-picker
                v-model="manual.made_at"
                placeholder="请选择收文日期"
                size="small"
                type="date"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <div class="tabc-update">

            <el-upload
                ref="upload"
                :before-upload="beforeUpload"
                :file-list="fileList"
                :http-request="uploadImg"
                :limit="1"
                :on-exceed="handleExceed"
                action="/api/upload"
                class="upload-demo"
            >
              <el-form-item prop="name">
                <el-input v-model="manual.name" readonly placeholder="请点击按钮上传文件" size="small"></el-input>
              </el-form-item>
              <el-button
                  v-if="uploadBtn"
                  slot="trigger"
                  :disabled="distinguishDis"
                  circle
                  icon="el-icon-plus"
                  size="mini"
                  type="primary"
              ></el-button>
              <el-button v-else slot="trigger" :loading="true" circle size="mini" type="primary"></el-button>
            </el-upload>

          </div>
          <div class="batch">
            官文太多？马上 <router-link to="/personal/trademarkAdmin/upload">批量上传</router-link>
          </div>
          <div class="submitBtn">
            <el-button class="cancel" size="small" @click="fileSH = false" icon="el-icon-close">取 消</el-button>
            <el-button :disabled="submitDis" class="confirm" size="small" type="primary" :icon="submitDis ? 'el-icon-loading' :  'el-icon-folder-checked'" @click="submit('ruleForm')">确
              定
            </el-button>
          </div>

        </el-form>
      </div>
<!--      <div v-show="tabtNum == 2" class="cont-tabc">-->
<!--        <el-form ref="automatic" :model="automatic" :rules="automaticRules" label-position="right" label-width="80px"-->
<!--                 size="mini">-->
<!--          <div v-show="contSH" class="tabc-cont">-->
<!--            <el-skeleton :count="1" :loading="loadingUp" animated>-->
<!--              <template slot="template">-->
<!--                <div class="el-form-item is-required el-form-item&#45;&#45;mini">-->
<!--                  <label class="el-form-item__label">官文类型</label>-->
<!--                  <div class="el-form-item__content" style="height: 32px; line-height: 32px">-->
<!--                    <el-skeleton-item variant="p"/>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="el-form-item is-required el-form-item&#45;&#45;mini">-->
<!--                  <label class="el-form-item__label">商标号</label>-->
<!--                  <div class="el-form-item__content" style="height: 32px; line-height: 32px">-->
<!--                    <el-skeleton-item variant="p"/>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="el-form-item is-required el-form-item&#45;&#45;mini">-->
<!--                  <label class="el-form-item__label">收文日期</label>-->
<!--                  <div class="el-form-item__content" style="height: 32px; line-height: 32px">-->
<!--                    <el-skeleton-item variant="p"/>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </template>-->
<!--              <template>-->

<!--                <el-form-item label="官文类型" prop="matter">-->
<!--                  <el-select-->
<!--                      v-model="automatic.matter"-->
<!--                      clearable-->
<!--                      filterable-->
<!--                      placeholder="请选择官文类型"-->
<!--                      size="small"-->
<!--                  >-->
<!--                    <el-option-->
<!--                        v-for="item in typeSelect"-->
<!--                        :key="item.text"-->
<!--                        :label="item.text"-->
<!--                        :value="item.text"-->
<!--                    >-->
<!--                    </el-option>-->
<!--                  </el-select>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="商标号">-->
<!--                  <el-input-->
<!--                      v-model="filters.tmcode"-->
<!--                      clearable-->
<!--                      readonly-->
<!--                      placeholder="请输入商标号"-->
<!--                      size="small"-->
<!--                  ></el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="收文日期" prop="made_at">-->
<!--                  <el-date-picker-->
<!--                      v-model="automatic.made_at"-->
<!--                      placeholder="请选择收文日期"-->
<!--                      size="small"-->
<!--                      type="date"-->
<!--                      value-format="yyyy-MM-dd"-->
<!--                  >-->
<!--                  </el-date-picker>-->
<!--                </el-form-item>-->
<!--              </template>-->
<!--            </el-skeleton>-->
<!--          </div>-->
<!--          <div class="tabc-update">-->
<!--            <el-upload-->
<!--                ref="upload"-->
<!--                :before-upload="beforeUpload"-->
<!--                :file-list="fileList"-->
<!--                :http-request="uploadImg"-->
<!--                :limit="1"-->
<!--                :on-exceed="handleExceed"-->
<!--                action="/api/upload"-->
<!--                class="upload-demo"-->
<!--            >-->

<!--              <el-form-item prop="name">-->
<!--                <el-input v-model="automatic.name" readonly placeholder="请点击按钮上传文件" size="small"></el-input>-->
<!--              </el-form-item>-->

<!--              <el-button-->
<!--                  v-if="uploadBtn"-->
<!--                  slot="trigger"-->
<!--                  :disabled="distinguishDis"-->
<!--                  circle-->
<!--                  icon="el-icon-plus"-->
<!--                  size="mini"-->
<!--                  type="primary"-->
<!--              ></el-button>-->
<!--              <el-button v-else slot="trigger" :loading="true" circle size="mini" type="primary"></el-button>-->
<!--            </el-upload>-->
<!--          </div>-->
<!--          <div v-if="distinguishSh" class="tabc-distinguish">-->
<!--            <el-button :disabled="distinguishDis" size="small" icon="el-icon-view" type="primary" @click="distinguishBtn('automatic')">识 别-->
<!--            </el-button>-->
<!--          </div>-->
<!--          <el-form-item v-else class="submitBtn">-->
<!--            <el-button class="cancel" size="small" @click="fileSH = false" icon="el-icon-close">取 消</el-button>-->
<!--            <el-button :disabled="submitDis" class="confirm" size="small" type="primary"-->
<!--                       @click="submitAutomatic('automatic')" icon="el-icon-folder-checked">确 定-->
<!--            </el-button>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
<!--      </div>-->
    </el-dialog>

  </div>
</template>

<script>
import storage from '@/utils/storage'
import {
  alias,
  batchDownload,
  fileDistinguish,
  fileSubmit,
  fileUpload,
  managementDelete,
  trademarkDetail
} from '@/api/personal.js'

export default {
  // 使用directives注册v-focus指令

  data() {
    return {
      bodyHeight: '',
      loading: true,//控制整个页面的骨架屏
      filters: {//接收列表页传的参数来渲染页面数据
        alias: "",//别名
        tmcode: '',//注册商标intcls: '',//国际分类
      },
      docs: storage.getList(),//骨架屏的初始数据
      aliaSh: false,//控制编辑别名的显示与隐藏
      detail: [],//整个详情的数据
      srcList: [],//存储查看大图的数据
      id: {ids: []},//记录批量下载的数据},
      deleteId: {
        id: '',
      },
      downSH: true,//控制批量下载按钮的显示与隐藏
      flowsSH: true,
      down: true,//控制批量下载加载中按钮的显示与隐藏

      uploadBtn: true,//控制上传文件加载中按钮的显示与隐藏
      fileList: [],//记录上传文件成功的数组
      loadingUp: true,//控制上传文件中识别文件的骨架屏
      fileSH: false,//控制上传官文弹窗的显示与隐藏
      tabtNum: 1,//控制手动和自动上传的tab切换
      file: {
        name: "",//上传官文的名称
        path: '',//上传官文的路径
      },
      distinguishSh: true,//控制识别按钮显示与隐藏
      distinguishDis: false,
      submitDis: false,
      contSH: false,//控制识别中的骨架屏
      typeSelect: [],//官文类型的下拉数据
      automatic: {//自动上传的字段
        matter: "",
        made_at: "",
        name: "",//上传官文的名称
      },
      automaticRules: {
        matter: [
          {required: true, message: '请选择官文类型', trigger: 'change'},
        ],
        made_at: [
          {required: true, message: '请选择收文日期', trigger: 'change'},
        ],
        name: [
          {required: true, message: '请点击按钮上传文件', trigger: 'change'},
        ],
      },
      manual: {//手动上传的字段
        matter: "", //官文名称
        made_at: "",
        name: ''
      },
      manualRules: {
        matter: [
          {required: true, message: '请选择官文类型', trigger: 'change'},
        ],
        made_at: [
          {required: true, message: '请选择收文日期', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请点击按钮上传文件', trigger: 'change'},
        ],
      },
    };
  },

  created() {
    this.domesticDetails();
    this.bodyHeight = document.body.offsetHeight

  },
  mounted() {
    window.onresize = () => {
      this.bodyHeight = document.body.offsetHeight

    };
  },
  computed: {
    mainrHeight: function () {
      return (this.bodyHeight - 95) + 'px'
    },
  },
  methods: {
    // 下拉
    async select() {
      let s = await storage.getSelects('tm_doc_types','app_names')
      for(let i in s.app_names){
        if(s.app_names[i].text == this.detail.appname ){
          this.typeSelect = storage.getTrademarkTypes()
          break
        }else{
          this.typeSelect = storage.getMaintainTypes()
        }
      }
      console.log()
    },
    //返回顶部事件
    backTop() {
      const that = this;
      let ispeed = Math.floor(-that.scrollTop / 5);
      document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
    },
    //渲染详情事件
    async domesticDetails() {
      this.filters.tmcode = this.$route.params.tmcode
      this.filters.intcls = this.$route.params.intcls
      try {
        const res = await trademarkDetail(this.filters)
        if (res && res.err_code === 0) {
          this.loading = false
          this.srcList.push(res.data.tmimage);
          this.detail = res.data;
          this.downSH = this.detail.docs.length != 0;
          this.flowsSH = this.detail.flows != null;
          this.filters.alias = res.data.alias
          await this.select()
        }
      } finally {
        this.loading = false
      }
    },
    //添加别名事件
    async input() {
      try {
        const res = await alias(this.filters)
        if (res && res.err_code === 0) {
          this.aliaSh = false

        }
      } finally {
        this.aliaSh = false
      }
    },
    //记录选择要下载的id事件
    handleSelectionChange(val) {
      this.id.ids = [];
      val.forEach((item) => {
        this.id.ids.push(item.id);
      });
    },
    //批量下载点击事件
    async download() {
      if (this.id.ids.length == 0) {
        this.$confirm("请选中要下载的官文序号", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      } else {

        this.down = false
        try {
          const res = await batchDownload(this.id)
          if (res && res.err_code === 0) {
            window.open(res.data.url)
            this.down = true
          }
        } catch (error) {
          console.log("错误", error)
        } finally {
          this.down = true
        }

      }

    },
    open(url) {
      location.href = url
    },


    //官文删除事件
    deleteFile(item) {
      this.deleteId.id = item
      try {

        this.$confirm('确定要删除该官文吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          await managementDelete(this.deleteId).then(res => {
            if (res && res.err_code === 0) {
              this.$message({
                type: 'success',
                message: "官文已删除！",
              })
              this.domesticDetails()
            } else {
              this.$message({
                type: 'error',
                message: res.err_msg,
              });
              this.domesticDetails()
            }
          })
        })
      } finally {

      }
    },

    upload() {
      this.fileSH = true
      this.$store.commit('setscroll',{
        scroll:this.fileSH,
      });
    },
    //文件上传时事件
    beforeUpload() {
      this.uploadBtn = false
    },
    //文件上传数量超过1个提示事件
    handleExceed(files, fileList) {
      this.$message.warning(
          `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`
      );
    },

    //文件上传后事件(自动上传)
    async uploadImg(item) {
      let formData = new FormData();
      formData.append("file", item.file);
      formData.append("is_base64", 0);
      try {
        const res = await fileUpload(formData)
        if (res && res.err_code === 0) {
          this.uploadBtn = true
          this.file.name = this.automatic.name = this.manual.name = res.data.name;
          this.file.path = res.data.path;
          this.$refs.upload.clearFiles()
        } else {
          this.$message({
            type: "warning",
            message: res.err_msg,
          })
        }
      } finally {
        this.uploadBtn = true;
      }
    },

    //识别按钮点击事件
    distinguishBtn(automatic) {
      this.$refs[automatic].validate(async (valid) => {
        try {
          if (this.automatic.name != '') {
            this.distinguishDis = true
            this.contSH = true;
            this.loadingUp = true
            const res = await fileDistinguish({...this.filters, ...this.file})
            if (res && res.err_code === 0) {
              this.distinguishDis = false
              this.loadingUp = false
              this.automatic.made_at = res.data.made_at;
              this.distinguishSh = false;
              if (this.typeSelect.indexOf(res.data.matter) == -1) {
                this.automatic.matter = res.data.matter;
              } else {
                this.$message({
                  type: "warning",
                  message: '上传文件的官文类型不存在，请选择成已有的官文类型',
                })
                this.automatic.matter = ''
              }
            } else {
              this.$message({
                message: res.err_msg,
                type: 'warning'
              })
              this.contSH = false
              this.fileList = []
              this.distinguishDis = false
              this.distinguishSh = true
            }
          }
        } catch (e) {
          this.contSH = false
          this.fileList = []
          this.distinguishDis = false
          this.distinguishSh = true
        }

      })
    },
    //上传官文提交事件
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.distinguishDis = this.submitDis = true
            const res = await fileSubmit({...this.file, ...this.manual, ...this.filters})
            if (res && res.err_code === 0) {
              this.$refs[formName].resetFields();
              this.success()
            } else {
              this.$message({
                type: "warning",
                message: res.err_msg,
              })
              this.fileList = []
              this.distinguishDis = this.submitDis = false
            }
          } catch (e) {
            this.fileList = []
            this.distinguishDis = this.submitDis = false
          }
        } else {
          return false;
        }
      })


    },
    submitAutomatic(automatic) {
      this.$refs[automatic].validate(async (valid) => {
        if (valid) {
          try {
            this.distinguishDis = this.submitDis = true
            const res = await fileSubmit({...this.file, ...this.automatic, ...this.filters})
            if (res && res.err_code === 0) {
              this.success()
            } else {
              this.$message({
                type: "warning",
                message: res.err_msg,
              })
              this.distinguishDis = this.submitDis = false
            }
          } catch (e) {
            this.fileList = []
            this.distinguishDis = this.submitDis = false
          }
        } else {
          return false
        }
      })


    },
    //上传官文提交成功事件
    success() {
      this.$message({
        type: "success",
        message: "提交成功!",
      });
      this.fileSH = false;
      this.$store.commit('setscroll',{
        scroll:this.fileSH,
      });
      this.manual = this.$options.data().manual; //清空表单
      this.automatic = this.$options.data().automatic; //清空表单
      this.distinguishSh = true;
      this.contSH = false;
      this.tabtNum = 1
      this.fileList = []
      this.file.name = ''
      this.distinguishDis = this.submitDis = false
      this.domesticDetails()
    },
    //上传官文取消事件
    empty() {
      this.fileSH = false;
      this.$store.commit('setscroll',{
        scroll:this.fileSH,
      });
      this.$refs.ruleForm.resetFields()
      // this.$refs.automatic.resetFields()
      this.tabtNum = 1
      this.distinguishSh = true;
      this.contSH = false;
      this.$refs.upload.clearFiles()
      this.file = this.$options.data().file; //清空表单
    },

    //编辑别名点击其他地方，取消编辑事件
    handleClose(e) {
      this.aliaSh = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/domesticDetails.less";
</style>
