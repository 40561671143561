<template>
    <!-- class="approval ba-col-fff" -->
    <div >
        <router-view ></router-view>
        <!-- <h2>流程审批</h2>
        <el-card shadow="never">
           
            <el-tabs  v-if="user.parent_id === 0 && user.type !== 0" v-model="activeName" @tab-click="tabClk">
                <el-tab-pane label="审批列表" name="1"></el-tab-pane>
                <el-tab-pane  label="表单设置" name="2"></el-tab-pane>
                <el-tab-pane  label="审批人设置" name="3"></el-tab-pane>
            </el-tabs>
            
            <div v-else class="pos-rel">

                <div class="approval-tab">审批列表</div> 
            </div>
        </el-card>
        <template v-if="activeName == '1' || (user.parent_id != 0 || user.type === 0)">
            <list ref="list"></list>
        </template>
           <set-form v-if="activeName == '2'"></set-form>
           <set-approval v-if="activeName == '3'"></set-approval> -->
        </div>
</template>

<script>
import storage from '@/utils/storage'
import list from './list'
import setForm from './setForm'
import setApproval from './setApproval'
export default{
    components:{
        list,
        setForm,
        setApproval,
    },
    data(){
        return{
            activeName:'',
            detail_id:'',
            user:storage.getUser(),
        }
    },
    created(){
        if(this.user.parent_id === 0 && this.user.type !== 0){
            this.activeName = '1'
        }else{
             this.activeName = ''
        }
    },
    methods:{
        tabClk(){
           
        },
    }
}
</script>
<style lang="less" scoped>
.approval-tab{
    height: 40px;
    line-height: 40px;
    font-weight: 500;
    color: #303133;
}
.approval-tab::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #E4E7ED;
    z-index: 1;
}
.approval{
    height:calc(100vh - 100px);
}
::v-deep .el-card{
    border:unset;
    .el-card__body{
    padding: 5px 20px;
    .el-tabs__header{
        margin:0;
    }
}
}
 
</style>
