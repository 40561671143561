import { render, staticRenderFns } from "./reviewDetails.vue?vue&type=template&id=04e475a2&scoped=true&"
import script from "./reviewDetails.vue?vue&type=script&lang=js&"
export * from "./reviewDetails.vue?vue&type=script&lang=js&"
import style0 from "./reviewDetails.vue?vue&type=style&index=0&id=04e475a2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04e475a2",
  null
  
)

export default component.exports