<template>
  <div class="">
    <div class="top">
      <div class="container clearfix">
        <span class="fl">您好{{ user.nickname || '' }}，欢迎来到知者云！</span>
        <div class="fr">
          <a v-if="user" href="#" @click.prevent="doLogout">退出登录</a>
          <router-link v-else to="/login">注册/登录</router-link>
          <span class="phone"><img alt="" src="../assets/img/index/icon-phone.png"/>400-1010-989</span>
          <span class="collection"><img alt="" src="../assets/img/index/icon-collection.png"/>收藏本站</span>
        </div>
      </div>
    </div>
    <div class="head">
      <div class="container clearfix">
        <img alt="" class="fl" src="@/assets/img/index/logo.png"/>
        <ul class="fr clearfix">
          <li
              v-for="(item, index) in headList"
              :key="index"
              @click="headLink(index,item)"
          >
            <router-link :to="item.path">
              <span :class="{ on: num == index || item.isOn }">{{ item.name }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <router-view></router-view>
    <div class="foot">
      <div class="container">
        <ul>
          <li>
            <h3>
              <img alt="" class="" src="@/assets/img/icon-foot1.png"/>
            </h3>
            <p>
              当天申请当天上报<br/>
              最大化缩短客户申请时间
            </p>
          </li>
          <li>
            <h3>
              <img alt="" class="" src="@/assets/img/icon-foot2.png"/>
            </h3>
            <p>
              注册进度第一时间<br/>
              通过短信或微信方式告知客户
            </p>
          </li>
          <li>
            <h3>
              <img alt="" class="" src="@/assets/img/icon-foot3.png"/>
            </h3>
            <p>
              商标下达<br/>
              第一时间告知客户
            </p>
          </li>
          <li>
            <h3>
              <img alt="" class="" src="@/assets/img/icon-foot4.png"/>
            </h3>
            <p>
              7*24小时全日制服务<br/>
              专业有效的解决您的任何疑问
            </p>
          </li>
        </ul>
        <div class="footB">
          <img
              alt=""
              class="footB-logo"
              src="@/assets/img/icon-foot-logo.png"
          />
          <div class="footB-cont">
            <ul>
              <li>
                <span></span>
                <router-link to="/about">关于我们</router-link>
              </li>
              <li>
                <span></span>
                <router-link to="/helpCenter">帮助中心</router-link>
              </li>
              <li>
                <span></span>
                <router-link to="/">网站地图</router-link>
              </li>
            </ul>
            <p>© {{ year }} 南京知者云科技有限公司 <a href="http://beian.miit.gov.cn/" target="_blank">苏ICP备2021053453号</a></p>
          </div>
          <img
              alt=""
              class="footB-code"
              src="@/assets/img/index/sider-code.png"
          />
        </div>
      </div>

      <!-- <div class="container cen">
        <div class="footL">
          <img src="@/assets/img/icon-foot.png" alt="" class="" />
        </div>

        <ul class="">
          <li><router-link to="/about">关于我们</router-link> |</li>
          <li><router-link to="/">帮助中心</router-link> |</li>
          <li><router-link to="/">联系我们</router-link></li>
        </ul>
        <p>© 江苏省宁海商标事务所有限公司 苏ICP备2021053453号</p>
      </div> -->
    </div>

    <!-- 右侧栏 -->
    <div :class="{ on: visible }" class="sider">
      <ul>
        <!--        <li >-->
        <!--          <img-->
        <!--            src="@/assets/img/index/icon-sider1.png"-->
        <!--            alt=""-->
        <!--            class="siderImg"-->
        <!--            @mouseenter="siderNum = 1"-->
        <!--          />-->
        <!--          <div class="consulting" :class="{ on: siderNum == 1 }" @mouseleave="siderNum = 0">-->
        <!--            <a href="">在线咨询</a>-->
        <!--          </div>-->
        <!--        </li>-->
        <!--        <li >-->
        <!--          <img-->
        <!--            src="@/assets/img/index/icon-sider2.png"-->
        <!--            alt=""-->
        <!--            class="siderImg"-->
        <!--            @mouseenter="siderNum = 2"-->
        <!--          />-->
        <!--          <div class="sider-phone" :class="{ on: siderNum == 2 }" @mouseleave="siderNum = 0">-->
        <!--            <div class="phone-cont">-->
        <!--              <h3>免费咨询</h3>-->
        <!--              <div class="">-->
        <!--                <img src="@/assets/img/index/icon-sider-phone.png" alt="" />-->
        <!--                <input type="text" placeholder="请输入手机号码" />-->
        <!--              </div>-->
        <!--              <span>免费获取服务</span>-->
        <!--            </div>-->

        <!--            <p>热线电话：400-1010-989</p>-->
        <!--          </div>-->
        <!--        </li>-->
        <!--        <li :class="{ on: btnFlag }" >-->
        <!--          <img-->
        <!--            src="@/assets/img/index/icon-sider3.png"-->
        <!--            alt=""-->
        <!--            class="siderImg"-->
        <!--            @mouseover="siderNum = 3"-->
        <!--          />-->
        <!--          <div class="sider-code" :class="{ on: siderNum == 3 }" @mouseleave="siderNum = 0">-->
        <!--            <img src="@/assets/img/index/sider-code.png" alt="" />-->
        <!--            <h3>关注公众号<br />了解更多服务</h3>-->
        <!--          </div>-->
        <!--        </li>-->
        <li v-if="btnFlag">
          <img
              alt=""
              class="siderImg"
              src="@/assets/img/index/icon-sider4.png"
              @click="backTop"
              @mouseover="siderNum = 4"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import storage from '@/utils/storage'

export default {
  data() {
    return {
      year: new Date().getFullYear(),
      user: storage.getUser(),
      headList: [
        {
          path: '#',//"#",
          name: "首页",
          isOn: false,
        },
        {
          path: '#',//"/trademark",
          name: "商标注册",
          isOn: false,
        },
        {
          path: '#',//"/copyServices",
          name: "版权服务",
          isOn: false,
        },
        {
          path: '#',//"/patentServices",
          name: "专利服务",
          isOn: false,
        },
        {
          path: '#',//"/trademarkClass",
          name: "商标分类",
          isOn: false,
        },
        {
          path: "/personal/domestic",
          name: "个人中心",
          isOn: false,
        },
        {
          path: '/helpCenter',//"/helpCenter",
          name: "帮助中心",
          isOn: false,
        },
        {
          path: '#',//"/about",
          name: "关于我们",
          isOn: false,
        },
      ],
      num: -1,
      btnFlag: false,
      visible: false,
      siderNum: 0,
      win: "",
    };
  },
  created() {
    // this.backTop();
    let path = this.$route.path.split("/")[1];
    let path2 = this.$route.path.split("/")[2];
    this.headList.forEach((item, index) => {
      if (item.path.split("/")[1] == path) {
        this.num = index;
      } else if (item.path.split("/")[1] == path2) {
        this.num = index;
      }
    });

    this.visible = document.body.clientWidth <= 1280;
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
    this.win = document.body.clientWidth;
    // window.onresize = () => {
    //   return (() => {
    //     // 重新赋值最新窗口数据
    //     this.win = document.body.clientWidth;
    //     this.visible = this.win <= 1280;
    //   })();
    // }
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },

  methods: {
    headLink(index, item) {
      this.num = index;
    },
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
            that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    scrollToTop() {
      const that = this;
      let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      that.scrollTop = scrollTop;
      that.btnFlag = that.scrollTop > 60;
    },
    doLogout() {
      this.$confirm('确定要退出登录吗？', '提示', {
        type: 'warning'
      }).then(() => {
        this.$http.post('/api/logout').then((res) => {
          if (res && res.err_code === 0) {
            storage.clear()
            this.$router.push('/login')
          }
        })
      })
    }
  },
};
</script>


<style lang="less">
.sider {
  position: fixed;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);

  ul {

    li {
      position: absolute;
      right: 0;
      width: 50px;
      height: 50px;
      background-color: rgba(18, 85, 122, 100);
      border-radius: 5px;

      .siderImg {
        cursor: pointer;
        display: block;
        margin: 0 auto;
        width: 38px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
      }

      .consulting,
      .sider-phone,
      .sider-code {
        position: absolute;
        background-color: rgba(18, 85, 122, 100);
        right: -110px;
        top: 0;
        transition: right 0.5s;
        border-radius: 10px;
      }

      .consulting a {
        display: block;
        width: 100px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
      }

      .consulting.on {
        right: 60px;
      }

      .sider-phone {
        width: 300px;

        right: -310px;

        .phone-cont {
          cursor: pointer;
          padding: 0 20px;

          h3 {
            padding: 10px 0;
            border-bottom: 1px dashed #fff;
            font-size: 18px;
            text-align: center;
            font-weight: 700;
            color: #fff;
          }

          div {
            display: flex;
            align-items: center;
            width: 100%;
            height: 50px;
            background: #c1dceb;
            margin: 20px 0;
            border-radius: 5px;

            img {
              height: 40px;
              margin: 0 10px;
            }

            input {
              display: inline-block;
              width: 200px;
              height: 50px;
              font-size: 16px;
              background: #c1dceb;
              color: #12557a;
              border-left: 1px dashed #12557a;
              padding-left: 10px;
              border-radius: 0 5px 5px 0;
            }
          }

          span {
            display: block;
            height: 50px;
            background: #fff;
            border-radius: 25px;
            text-align: center;
            font-size: 18px;
            color: #12557a;
            font-weight: 700;
            line-height: 50px;
          }
        }

        p {
          background: #1872a4;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #fff;
          font-size: 16px;
          font-weight: 700;
          margin-top: 20px;
        }
      }

      .sider-phone.on {
        right: 60px;
      }

      .sider-code {
        width: 180px;
        height: 230px;
        right: -190px;
        text-align: center;
        padding-top: 20px;

        img {
          width: 140px;
          margin-bottom: 10px;
        }

        h3 {
          font-size: 18px;
          color: #fff;
          font-weight: 700;
        }
      }

      .sider-code.on {
        right: 60px;
      }
    }

    li:nth-of-type(3) {
      margin-bottom: 0;
      top: 120px;
    }

    li.on {
      margin-bottom: 10px;
    }

    li:nth-of-type(2) {
      top: 60px;
    }

    li:nth-of-type(4) {
      top: 180px;
    }

    // li:nth-of-type(1):hover {
    //   background: #256d95;

    //   .consulting {
    //     right: 60px;
    //   }
    // }
    // li:nth-of-type(2):hover {
    //   background: #256d95;

    //   .sider-phone {
    //     right: 60px;
    //   }
    // }
    // li:nth-of-type(3):hover {
    //   background: #256d95;
    //   .sider-code{
    //     right: 60px;
    //   }

    // }
  }
}

.sider.on {
  right: 0;

  li {
    width: 40px;
    height: 40px;

    .siderImg {
      width: 28px;
    }
  }
}

.foot {
  padding-top: 60px;

  a {
    color: #ffffff;
  }

  ul {
    display: flex;
    justify-content: space-between;
    text-align: center;

    li {
      h3 {
        margin: auto;
        width: 120px;
        height: 120px;
        border: 4px solid #fff;
        border-radius: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 50px;
        }
      }

      p {
        margin-top: 15px;
        font-size: 18px;
        color: #fff;
      }
    }
  }

  .footB {
    width: 900px;
    margin: 135px auto 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footB-logo {
      height: 60px;
    }

    .footB-cont {
      width: 360px;
      color: #fff;

      ul {
        width: 340px;
        margin: 0 auto 20px 0;
        font-size: 14px;

        li {
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background: #fff;
            margin-right: 10px;
          }

          a {
            color: #fff;
          }
        }
      }
    }

    .footB-code {
      width: 100px;
    }
  }
}
</style>
