
import request from '@/utils/request.js';

//个人中心-首页-商标、专利、版权及申请数量
export const homeIpcounts = (params) => { return request.post( "/api/home/ipcounts", params) };
//个人中心-首页-商标概况
export const homeTmsummary = (params) => { return request.post( "/api/home/tmsummary", params) };
//个人中心-首页-日历
export const homeCalendar = (params) => { return request.post( "/api/home/calendar", params) };
//个人中心-首页-信息
export const homeMessages = (params) => { return request.post( "/api/home/messages", params) };

//商标查询-类似分类
export const tmSearchClass = (params) => { return request.post( "/api/tmSearch/class", params) };
//商标查询-类似群组
export const tmSearchGroup = (params) => { return request.post( "/api/tmSearch/group", params) };
//商标查询-商品项目搜索
export const tmSearchGoods = (params) => { return request.post( "/api/tmSearch/goods", params) };
//商标查询-关键字搜索
export const tmSearchKeyword = (params) => { return request.post( "/api/tmSearch/keyword", params) };
//商标查询-搜索详情
export const tmSearchDetail = (params) => { return request.post( "/api/tmSearch/detail", params) };
//商标查询-导出
export const tmSearchExport = (params) => { return request.post( "/api/tmSearch/export", params) };

//待办事项-列表
export const schedules = (params) => { return request.post( "/api/schedules", params) };
//待办事项-保存
export const schedulesSave = (params) => { return request.post( "/api/schedules/save", params) };
//待办事项-完成
export const schedulesComplete = (params) => { return request.post( "/api/schedules/complete", params) };
//待办事项-删除
export const schedulesDelete = (params) => { return request.post( "/api/schedules/delete", params) };
//待办事项-查看待办
export const schedulesDetail = (params) => { return request.post( "/api/schedules/detail", params) };
//待办事项- 查询相关商标
export const tmSearch = (params) => { return request.post( "/api/schedules/tmSearch", params) };
//待办事项-  添加处理记录
export const saveProcess = (params) => { return request.post( "/api/schedules/saveProcess", params) };
//待办事项-  删除处理记录
export const deleteProcess = (params) => { return request.post( "/api/schedules/deleteProcess", params) };

//申请管理 - 国内商标 - 列表
export const apply = (params) => { return request.post( "/api/applies/tms", params) };
//申请管理 - 国内商标 - 详情
export const applyDetali = (params) => { return request.post( "/api/applies/tmDetail", params) };
//申请管理 - 国际商标 - 列表
export const applyGlobalTms = (params) => { return request.post( "/api/applies/globalTms", params) };
//申请管理 - 国际商标 - 详情
export const applyGlobalTmDetail = (params) => { return request.post( "/api/applies/globalTmDetail", params) };
//申请管理 - 国际商标 - 导出
export const globalExportTms = (params) => { return request.post( "/api/applies/globalExportTms", params) };
//申请管理 - 作品著作权 - 列表
export const applyWorks = (params) => { return request.post( "/api/applies/copyrights", params) };
//申请管理 - 作品著作权 - 详情
export const applyWorksDetali = (params) => { return request.post( "/api/applies/copyrightDetail", params) };
//申请管理 - 行政诉讼
export const applyLawsuits = (params) => { return request.post( "/api/applies/lawsuits", params) };
//申请管理 - 行政诉讼详情
export const applyLawsuitsDetali = (params) => { return request.post( "/api/applies/lawsuitDetail", params) };
//申请管理 - 批量下载
export const applyDownload = (params) => { return request.post( "/api/applies/batchDownload", params) };
//申请管理 - 账单列表
export const applyBills = (params) => { return request.post( "/api/applies/bills", params) };
//申请管理 - 账单二维码
export const qrCode = (params) => { return request.post( "/api/order/qrCode", params) };
//申请管理 - 账单详情
export const applyBillDetail = (params) => { return request.post( "/api/applies/billDetail", params) };
//申请管理 - 国内商标导出数据
export const exportTms = (params) => { return request.post( "/api/applies/exportTms", params) };
//申请管理 - 作品著作权导出数据
export const exportCopyrights = (params) => { return request.post( "/api/applies/exportCopyrights", params) };
//申请管理 - 诉讼导出数据
export const exportLawsuits = (params) => { return request.post( "/api/applies/exportLawsuits", params) };
//申请管理 - 账单导出数据
export const exportBills = (params) => { return request.post( "/api/applies/exportBills", params) };

//申请人管理-获取常用申请人列表
export const applicants = (params) => { return request.post( "/api/applicants", params) };
//申请人管理-获取新建申请人详情
export const applicantsDetail = (params) => { return request.post( "/api/applicants/detail", params) };
//申请人管理-短信通知
export const applicantsSwitch= (params) => { return request.post( "/api/applicants/switch", params) };
//申请人管理-保存
export const applicantsSave= (params) => { return request.post( "/api/applicants/save", params) };

//自有商标-获取自有商标列表
export const perindex = (params) => { return request.post( "/api/tms", params) };
//自有商标-导出国内商标
export const exportData = (params) => { return request.post("/api/tms/export", params) };
//自有商标-获取评审商标信息列表
export const tmReview = (params) => { return request.post( "/api/tmReview", params) };
//自有商标-获取评审商标信息导出
export const tmReviewExport = (params) => { return request.post( "/api/tmReview/export", params) };
//自有商标-获取评审商标信息详情
export const tmReviewDetail = (params) => { return request.post( "/api/tmReview/detail", params) };
//自有商标-获取评审商标文书详情
export const judgeInfo = (params) => { return request.post( "/api/tmReview/judgeInfo", params) };

//自有商标-获取异议商标信息列表
export const tmOpposition = (params) => { return request.post( "/api/tmOpposition", params) };
//自有商标-获取异议商标信息详情
export const tmOppositionDetail = (params) => { return request.post( "/api/tmOpposition/detail", params) };
//自有商标-获取维确权商标列表
export const maintainindex = (params) => { return request.post( "/api/tmRights", params) };
//自有商标-导出维确权商标列表
export const maintainindexExport = (params) => { return request.post( "/api/tmRights/export", params) };
//自有商标-导入维确权商标列表
export const maintainindexImport = (params) => { return request.post( "/api/tmRights/import", params) };

//获取自有商标详情
export const trademarkDetail = (params) => { return request.post("/api/tms/detail", params) };
//获取（编辑）国内商标详情别名
export const alias = (params) => { return request.post("/api/tms/alias", params) };
//获取自有商标详情属性设置
export const getPropSetting = (params) => { return request.post("/api/tms/getPropSetting", params) };
//获取自有商标详情保存商标属性设置
export const savePropSetting = (params) => { return request.post("/api/tms/savePropSetting", params) };
//获取自有商标详情保存商标属性
export const saveProps = (params) => { return request.post("/api/tms/saveProps", params) };
//自有商标-自有商标批量下载
export const batchDownload = (params) => { return request.post("/api/tmDocs/batchDownload", params) };
//自有商标-自有商标文件上传
export const fileUpload = (params) => { return request.post("/api/upload", params) };
//自有商标-自有商标文件识别
export const fileDistinguish = (params) => { return request.post("/api/tmDocs/ocr", params) };
//自有商标-自有商标上传提交
export const fileSubmit= (params) => { return request.post("/api/tmDocs/save", params) };

//自有商标-商标图谱的商标统计
export const tmChartsSummary= (params) => { return request.post("/api/tmCharts/summary", params) };
//自有商标-商标图谱的商标布局
export const tmChartsTmDist= (params) => { return request.post("/api/tmCharts/tmDist", params) };
//自有商标-商标图谱的商标申请量
export const tmChartsTmApplies= (params) => { return request.post("/api/tmCharts/tmApplies", params) };
//自有商标-商标图谱的类别分布
export const tmChartsIntCls= (params) => { return request.post("/api/tmCharts/intCls", params) };
//自有商标-商标图谱的状态分布
export const tmChartsTmLaw= (params) => { return request.post("/api/tmCharts/tmLaw", params) };
//自有商标-商标图谱的注册满三年
export const tmChartsOverThree= (params) => { return request.post("/api/tmCharts/overThree", params) };
//自有商标-商标图谱的相关商标
export const tmChartsOverThreeTms= (params) => { return request.post("/api/tmCharts/overThreeTms", params) };
//自有商标-商标图谱的申请趋势
export const tmChartsOverAppTrend= (params) => { return request.post("/api/tmCharts/appTrend", params) };

//国内商标-商标监测
export const tmMonitor = (params) => { return request.post("/api/tmMonitor", params) };
//国内商标-商标监测类型统计
export const tmMonitorCount = (params) => { return request.post("/api/tmMonitor/count", params) };
//国内商标-商标监测类型统计
export const tmMonitorExport = (params) => { return request.post("/api/tmMonitor/export", params) };
//国内商标-商标监测类型统计
export const tmMonitorProcess = (params) => { return request.post("/api/tmMonitor/process", params) };

//国内商标-使用证据
export const evidences = (params) => { return request.post("/api/evidences", params) };
//国内商标-获取水印设置
export const getWatermarkSetting = (params) => { return request.post("/api/evidences/getWatermarkSetting", params) };
//国内商标-保存水印设置
export const saveWatermarkSetting = (params) => { return request.post("/api/evidences/saveWatermarkSetting", params) };
//国内商标-上传证据
export const evidencesUpload = (params) => { return request.post("/api/evidences/upload", params) };
//国内商标-上传完成
export const evidencesUploaded = (params) => { return request.post("/api/evidences/uploaded", params) };
//国内商标-批量下载
export const evidencesBatchDownload = (params) => { return request.post("/api/evidences/batchDownload", params) };

//国际商标-获取列表
export const globalTms = (params) => { return request.post("/api/globalTms", params) };
//国际商标-获取详情
export const globalTmsDetail = (params) => { return request.post("/api/globalTms/detail", params) };
//国际商标-导出
export const globalTmsExport = (params) => { return request.post("/api/globalTms/export", params) };

//国际商标-商标图普-商标统计
export const globalTmsSummary  = (params) => { return request.post("/api/globalTms/summary ", params) };
//国际商标-商标图普-国家/地区统计
export const globalTmsCountryList  = (params) => { return request.post("/api/globalTms/countryList", params) };
//国际商标-商标图普-类别分布
export const globalTmsTypeList  = (params) => { return request.post("/api/globalTms/typeList", params) };
//国际商标-商标图普-商标布局
export const globalTmsTmDist  = (params) => { return request.post("/api/globalTms/tmDist", params) };
//国际商标-商标图普-状态统计
export const globalTmCount  = (params) => { return request.post("/api/globalTms/tmCount", params) };
//知产管理-获取专利列表
export const patent = (params) => { return request.post("/api/patents", params) };
//知产管理-获取专利详情
export const patentDetai = (params) => { return request.post("/api/patents/detail", params) };

//知产管理-获取软件著作权列表
export const software = (params) => { return request.post("/api/software", params) };
//知产管理-获取作品著作权列表
export const work = (params) => { return request.post("/api/copyrights", params) };
//知产管理-获取作品著作权导出
export const workExport = (params) => { return request.post("/api/copyrights/export", params) };
//知产管理-获取作品著作权详情
export const workDetail = (params) => { return request.post("/api/copyrights/detail", params) };
//知产管理-获取作品著作权保存
export const workSave = (params) => { return request.post("/api/copyrights/save", params) };

//知产管理-获取网站备案列表
export const websiteFiling = (params) => { return request.post("/api/icps", params) };

//档案管理-获取国内商标列表
export const management = (params) => { return request.post("/api/tmDocs", params) };
//档案管理-国内商标批量下载
export const managementDown = (params) => { return request.post("/api/tmDocs/batchDownload", params) };
//档案管理-国内商标批量下载
export const managementDelete = (params) => { return request.post("/api/tmDocs/delete", params) };
//档案管理-国内商标文件上传
export const managementUpload = (params) => { return request.post("/api/upload", params) };
//档案管理-国内商标文件识别
export const managementDistinguish = (params) => { return request.post("/api/tmDocs/ocr", params) };
//档案管理-国内商标文件提交
export const managementSave = (params) => { return request.post("/api/tmDocs/save", params) };
//档案管理-国内商标选择文件
export const managementMatch = (params) => { return request.post("/api/tmDocs/match", params) };
//档案管理-国内商标上传文件
export const managementTMUpload = (params) => { return request.post("/api/tmDocs/upload", params) };
//档案管理-国内商标上传文件完成
export const managementTMUploaded = (params) => { return request.post("/api/tmDocs/uploaded", params) };

//档案管理-国内商标搜索
export const managementTmcodes = (params) => { return request.post("/api/tmcodes", params) };

//档案管理-获取维确权商标列表
export const maintain = (params) => { return request.post("/api/tmRightDocs", params) };

//档案管理-软件著作权列表
export const managementSofe = (params) => { return request.post("/api/softwareDocs", params) };
//知产管理-获取软件著作权列表
export const copyrightDocsSave = (params) => { return request.post("/api/copyrightDocs/save", params) };
//档案管理-作品著作权列表
export const managementWork = (params) => { return request.post("/api/copyrightDocs", params) };
//档案管理-作品著作权下载
export const manageWorkDown = (params) => { return request.post("/api/copyrightDocs/batchDownload", params) };
//档案管理-作品著作权删除
export const manageWorkDelete = (params) => { return request.post("/api/copyrightDocs/delete", params) };
//档案管理-作品著作权文件识别
export const manageWorkDistinguish = (params) => { return request.post("/api/copyrightDocs/ocr", params) };
//档案管理-作品著作权文件提交
export const manageWorkSave = (params) => { return request.post("/api/copyrightDocs/save", params) };
//档案管理-作品著作权文件提交
export const managementWorkdetail = (params) => { return request.post("/api/copyrightDocs/detail", params) };

//账号设置-账户信息
export const myMessage = (params) => { return request.post("/api/user/detail", params) };
//账号设置-账户信息保存
export const myMessageSave = (params) => { return request.post("/api/user/save", params) };
//账号设置-子账户信息
export const myMessageSub = (params) => { return request.post("/api/user/sub", params) };
//账号设置-子账户信息保存
export const myMessageSubSave = (params) => { return request.post("/api/user/sub/save", params) };

//信息中心列表
export const messages = (params) => { return request.post("/api/messages", params) };
//信息中心-已读
export const messagesRead = (params) => { return request.post("/api/messages/read", params) };
//信息中心-删除
export const messagesDelete = (params) => { return request.post("/api/messages/delete", params) };
//信息中心-详情
export const messagesDetail = (params) => { return request.post("/api/messages/detail", params) };

//帮助中心-更新日志列表
export const updateLogs = (params) => { return request.post("/api/help/updateLogs", params) };
//帮助中心-更新日志详情
export const updateLogDetail = (params) => { return request.post("/api/help/updateLogDetail", params) };

//审批流程-审批列表
export const flows = (params) => { return request.post("/api/flows", params) };
//审批流程-创建审批
export const flowsSave = (params) => { return request.post("/api/flows/save", params) };
//审批流程-查看审批
export const flowsForm = (params) => { return request.post("/api/flows/form", params) };
//审批流程-撤销/删除审批
export const flowsDelete = (params) => { return request.post("/api/flows/delete", params) };
//审批流程-审批人列表
export const flowsApprovers = (params) => { return request.post("/api/flows/approvers", params) };
//审批流程-添加审批人
export const flowsApproversSave = (params) => { return request.post("/api/flows/approvers/save", params) };
//审批流程-删除审批人
export const flowsApproversDelete = (params) => { return request.post("/api/flows/approvers/delete", params) };
//审批流程-表单设置列表
export const flowsApplyForms = (params) => { return request.post("/api/flows/applyForms", params) };
//审批流程-表单设置
export const flowsApplyForm = (params) => { return request.post("/api/flows/applyForm", params) };
//审批流程-保存表单设置
export const flowsApplyFormSave = (params) => { return request.post("/api/flows/applyForm/save", params) };
//审批流程-删除表单设置
export const applyFormDelete = (params) => { return request.post("/api/flows/applyForm/delete", params) };
//审批流程-审批
export const flowsApprove = (params) => { return request.post("/api/flows/approve", params) };