<template>
  <div class="">
    <h2>国际商标申请<span v-if="applyList.length != 0">{{ pageSize }} </span></h2>
    <form ref="filters" v-on:submit.prevent>
      <el-row :class="{on:formOpen}" :gutter="10" class="formInput">
        <el-col :md="7" :sm="10" :xs="20">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>商标名称</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-input v-model="filters.tmname" clearable placeholder="请输入商标名称" size="small"></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :md="7" :sm="10" :xs="20">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>商标号</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-input v-model="filters.tmcode" clearable placeholder="请输入商标号" size="small"></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :md="7" :sm="10" :xs="20">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>商标类别</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-select
                  v-model="filters.intcls"
                  clearable
                  collapse-tags
                  filterable
                  multiple
                  placeholder="请选择商标类别"
                  size="small"
              >
                <el-option
                    v-for="(item,index) in intclsSelect"
                    :key="index"
                    :label="item.text"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :md="7" :sm="10" :xs="20">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>申请类型</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-select
                  v-model="filters.type"
                  clearable
                  filterable
                  placeholder="请选择申请类型"
                  size="small"
              >
                <el-option
                    v-for="item in typeSelect"
                    :key="item.id + 30"
                    :label="item.text"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :md="7" :sm="10" :xs="20">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>申请人</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-select
                  v-model="filters.appname"
                  clearable
                  filterable
                  placeholder="请选择申请人"
                  size="small"
              >
                <el-option
                    v-for="item in appSelect"
                    :key="item.id + 80"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :md="7" :sm="10" :xs="20">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>当前进度</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-select
                  v-model="filters.status"
                  clearable
                  filterable
                  placeholder="请选择当前进度"
                  size="small"
              >
                <el-option
                    v-for="(item,index) in statusSelect"
                    :key="index+ 120"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :md="7" :sm="10" :xs="20">
          <el-row :gutter="10" align="middle" justify="space-between" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>申请日期</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-date-picker
                  v-model="filters.deliver_at"
                  :picker-options="pickerOptions"
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>

      </el-row>
      <el-row class="formOpen">
        <el-col :class="{on:formOpen}" :span="24">
          <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
        </el-col>
      </el-row>
      <el-button class="search" icon="el-icon-search" size="small" type="primary"
                 @click="perindexList(filters.page = 1)"
      >查询
      </el-button>
      <el-button :class="{on:formOpen}" class="reset" icon="el-icon-refresh-left" size="small" type="reset"
                 @click="reset"
      >重置
      </el-button
      >
    </form>
    <div class="mainrb">
      <div class="listT p10">
        <el-button :disabled="applyList.length == 0 || loading" :loading="down" icon="el-icon-document" size="small"
                   type="primary"
                   @click="diaClick">
          {{ down ? '导出中' : '导出数据' }}
        </el-button>

      </div>
      <el-skeleton :count="1" :loading="loading" animated>
        <template slot="template">
          <el-table
              :class="{on:!formOpen,ios:$store.state.heights}"
              :data="lists"
              style="width: 100%">
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table :data="props.row.documents" style="width: 80%">
                  <el-empty description="描述文字"></el-empty>
                  <el-table-column
                      label="序号"
                      type="index"
                      width="50">
                  </el-table-column>
                  <el-table-column
                      label="文件名称"
                      prop="name">
                  </el-table-column>
                  <el-table-column
                      label="创建日期"
                      prop="made_at">
                  </el-table-column>
                  <el-table-column
                      label="操作">
                    <template slot-scope="scope">
                      <router-link :to="scope.row.url">
                        <el-skeleton-item variant="p"/>
                      </router-link>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column
                label="序号" width="50">
              <template>
                <el-skeleton-item class="w50" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column
                label="商标号">
              <template>
                <el-skeleton-item class="w50" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column
                label="商标名称">
              <template>
                <el-skeleton-item class="w50" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column
                label="商标类别">
              <template>
                <el-skeleton-item class="w50" variant="p"/>
              </template>
            </el-table-column>

            <el-table-column
                label="申请类型">
              <template>
                <el-skeleton-item class="w50" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column v-if="user.type != 0" label="金额">
              <template>
                <el-skeleton-item class="w50" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column
                label="当前进度">
              <template>
                <el-skeleton-item class="w50" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column
                label="申请日期">
              <template>
                <el-skeleton-item class="w50" variant="p"/>
              </template>
            </el-table-column>
            <el-table-column
                label="操作">
              <template slot-scope="scope">
                <template>
                  <el-skeleton-item class="w50" variant="p"/>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-table
            :class="{on:!formOpen,ios:$store.state.heights}"
            :data="applyList"
            height="100%"
            style="width: 100%">
          <template slot="empty">
            <el-empty :image-size="80"></el-empty>
          </template>
          <el-table-column class="cont" type="expand">
            <template slot-scope="props" class="cont">
              <el-table :data="props.row.documents"  style="width: 94%" :row-class-name="e =>  tableRowClassName(e,props.row.documents)">
                <el-table-column
                    label="序号"
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column
                    :show-overflow-tooltip="true" label="官文类型">
                  <template slot-scope="scope">
                    <span
                        v-if="user.type == 0 && Math.floor((new Date().getTime() - new Date(scope.row.created_at).getTime())/(24 * 60 * 60 * 1000)) > 30"
                        class="col-blue" @click="over">{{ scope.row.matter }}</span>
                    <a
                        v-else
                        :href="`${scope.row.url}&preview=true`"
                        class="ellipsis"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                      {{ scope.row.matter }}
                    </a>

                  </template>
                </el-table-column>
                <el-table-column
                    label="收文日期">
                  <template slot-scope="scope">
                    {{ scope.row.made_at || '-' }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="上传日期">
                  <template slot-scope="scope">
                    {{ scope.row.created_at || '-' }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="操作">
                  <template slot-scope="scope">
                    <el-button
                        v-if="user.type == 0 && Math.floor((new Date().getTime() - new Date(scope.row.created_at).getTime())/(24 * 60 * 60 * 1000)) > 30"
                        icon="el-icon-download" size="small" type="text" @click="over">下载
                    </el-button>
                    <el-button v-else icon="el-icon-download" size="small" type="text" @click="open(props.row,scope.row)">下载
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>

          <el-table-column
              label="序号" type="index">
          </el-table-column>
          <el-table-column
              label="商标号"
              prop="tmcode" width="100">
            <template slot-scope="scope">
              <el-tooltip v-showtip :content="scope.row.tmcode" class="item" effect="dark" placement="top-start">
                <p class="  ellipsis">
                  <span class="ellipse-text">
                    <router-link :to="{
                 name: 'internationalDetail',
                         params: { id: scope.row.id},
              }" target="_blank">
                {{ scope.row.tmcode || '-' }}
              </router-link>
                  </span>
                </p>
              </el-tooltip>

            </template>
          </el-table-column>
          <el-table-column
              label="商标名称" width="300">
            <template slot-scope="scope">
              <div class="tmimage">
                <div class="">
                  <el-image
                      :preview-src-list="[scope.row.tmimage]"
                      :src="scope.row.tmimage"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
                <!--                <el-tooltip class="item" :content="scope.row.tmname" effect="dark" v-showtip placement="top-start">-->
                <!--                  <p class="  vertical ver-line3">-->
                <!--                    <span class="ellipse-text">{{scope.row.tmname || '图形'}}</span>-->
                <!--                  </p>-->
                <!--                </el-tooltip>-->
                <p class="vertical ver-line3">{{ scope.row.tmname || '图形' }} </p>
              </div>

            </template>
          </el-table-column>
          <el-table-column
              label="类别" min-width="90" prop="intcls">
          </el-table-column>
          <el-table-column
              label="国家" min-width="90">
            <template slot-scope="scope">
              <el-tooltip v-showtip :content="scope.row.country" class="item" effect="dark" placement="top-start">
                <p class="  ellipsis">
                  <span class="ellipse-text">{{ scope.row.country || '-' }}</span>
                </p>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
              label="申请人"
              min-width="120"
          >
            <template slot-scope="scope">
              <el-tooltip v-showtip :content="scope.row.appname" class="item" effect="dark" placement="top-start">

                <router-link v-if="scope.row.client_id != 0"
                             :to="{
                  name: 'newlyBuild',
                    params: { id:  scope.row.client_id  },
                }"
                             class=" ellipsis" target="_blank">
                  <span class="ellipse-text">{{ scope.row.appname || '-' }}</span>
                </router-link>
                <template v-else>
                  <p class="ellipsis">
                    <span class="ellipse-text ">{{ scope.row.appname || '-' }}</span>
                  </p>

                </template>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
              label="申请类型"
              min-width="120">
            <template slot-scope="scope">
              <el-tooltip v-showtip :content="scope.row.typetext" class="item" effect="dark" placement="top-start">
                <p class="  ellipsis">
                  <span class="ellipse-text">{{ scope.row.typetext || '-' }}</span>
                </p>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
              v-if="user.type != 0"
              label="金额"
          >
            <template slot-scope="scope">
              ￥{{ scope.row.subtotal }}
            </template>
          </el-table-column>
          <el-table-column
              label="当前进度" min-width="80">
            <template slot-scope="scope">

              <el-tag
                  :class="{'tag-primary':['已受理','不予受理','需补正'].indexOf(scope.row.status)  != -1 ,tag1: ['已驳回','审查意见'].indexOf(scope.row.status) != -1,tag2:scope.row.status == '初审公告','tag-green':scope.row.status == '已发证',tag5:scope.row.status == '已报送','tag6':['办理中','提供证据','代缴费','异议中'].indexOf(scope.row.status) != -1}"
                  size="mini"
              >
                {{ scope.row.status }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
              label="申请日期"
              min-width="110" prop="deliver_at">
          </el-table-column>
          <el-table-column
              label="操作"
          >
            <template slot-scope="scope">
              <router-link
                  :to="{
                        name: 'internationalDetail',
                         params: { id: scope.row.id},
                      }"
                  class="disblk"
                  target="_blank"
              >
                <el-button icon="el-icon-edit-outline" size="small" type="primary">详情</el-button>
              </router-link>
              <!--              <el-button size="small" type="primary" icon="el-icon-message" plain>报送</el-button>-->
              <!--              <el-button size="small" icon="el-icon-close" plain>取消</el-button>-->
            </template>
          </el-table-column>
        </el-table>
      </el-skeleton>
      <!-- 分页 -->
      <el-pagination
          :current-page.sync="filters.page"
          :page-size="filters.per_page"
          :page-sizes="[30, 50, 100]"
          :total="pageSize"
          layout="total, sizes, prev, pager, next, jumper,slot"
          @size-change="changeRowNum"
          @current-change="perindexList()"
      >
      </el-pagination>
    </div>


    <el-dialog
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        :visible.sync="downloadDia"
        class="openDia"
        title="导出信息"
        width="576px"
        @open="openDia">
      <template>
        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="filters.fields" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="city in cities" :key="city" :label="city">{{ city }}</el-checkbox>
        </el-checkbox-group>
      </template>
      <span slot="footer" class="dialog-footer">
    <el-button icon="el-icon-close" size="small" @click="downloadDia = false">取 消</el-button>
    <el-button :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' : 'el-icon-folder-checked'" class="confirm"
               size="small" type="primary" @click="download">确 定</el-button>
  </span>
    </el-dialog>

    <el-dialog
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        :visible.sync="editionDia"
        class="editionDia"
        title="提示"
        width="390px">
      <div class="text-cen flex align-cen"><i class="el-icon-warning m-r10"></i> 当前账号没有此操作权限，请升级至<span
          class="font-wbold col-red ">“企业版”</span>！
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button size="small" type="primary" @click="editionDia = false">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import {applyGlobalTms, globalExportTms} from '@/api/personal.js'

const cityOptions = ['商标图样', '商标名称', '商标号', '商品类别', '国家','申请人', '申请类型', '金额', '当前进度', '申请日期', '商品项目'];
export default {
  name: "Apply",
  data() {
    return {
      user: storage.getUser(),
      down: false,//控制导出加载的显示与隐藏
      downloadDia: false,//控制导出的对话框
      checkAll: false,//全选
      cities: cityOptions,//导出的全部选项
      isIndeterminate: true,//全选状态
      submitDis: false,//控制导出确认按钮的加载状态
      editionDia: false,//控制版本提示弹框

      formOpen: false,
      loading: true,//控制列表的骨架屏
      applyList: [],
      lists: storage.getList(),
      pickerOptions: {//日期选择器的便利选择对象
        shortcuts: storage.getShortcuts()
      },
      filters: {
        tmcode: '',//商标号
        tmname: '',//商标名称
        appname: '',//申请人
        deliver_at: '',//报送日期
        type: '',//申请类型
        status: '',//当前进度
        intcls: '',//商标类别
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
        checkList: [],//记录满三年和可续展选中时的数组
        fields: ['商标名称', '商标号', '商品类别','国家', '申请人', '申请类型', '金额', '当前进度', '申请日期'],
      },
      //商标类别，下拉框的所需内容
      intclsSelect: storage.getIntcls(),
      typeSelect: [
        {id: '74', text: '马德里商标国际注册申请'},
        {id: '75', text: '马德里商标国际注册其他事宜（变转续）'},
        {id: '76', text: '境外商标注册申请'},
        {id: '77', text: '境外商标变转续申请'},
        {id: '78', text: '境外商标案件'},
        {id: '79', text: '境外商标其他事宜'},
        {id: '80', text: '境外公司登记'},
        {id: '81', text: '境外公司年审'},
        {id: '82', text: '境外公司其他事宜'},
      ],
      statusSelect: ["已受理","已报送","已驳回","初审公告","审查意见","已发证","办理中","提供证据","不予受理","代缴费","需补正","异议中"  ],
      appSelect: [],
      last_page: 1,
      pageSize: 0, //总条数
    }
  },
  created() {
    this.perindexList()
    this.select()
  },
  methods: {
    handleCheckAllChange(val) {
      this.filters.fields = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    diaClick() {
      if (storage.getUser().type != 0) {
        this.downloadDia = true
      } else {
        this.editionDia = true
      }

    },
    //导出事件
    openDia() {
      this.filters.fields = this.$options.data().filters.fields;
      this.checkAll = false
      this.isIndeterminate = true
    },

    async download() {
      if (storage.getUser().type == 0) {
        this.downloadDia = false
        this.$message({
          message: '当然账号没有此操作权限，请升级至“企业版”！',
          type: 'warning'
        });
      } else {
        this.down = true
        this.downloadDia = false
        if (this.filters.fields.length != 0) {
          try {
            const res = await globalExportTms({
              type: this.filters.type == '' ? 0 : this.filters.type,
              tmcode: this.filters.tmcode,
              tmname: this.filters.tmname,
              appname: this.filters.appname,
              deliver_at: this.filters.deliver_at,
              status: this.filters.status,
              intcls: this.filters.intcls,
              page: this.filters.page,
              fields: this.filters.fields,
              per_page: this.filters.per_page
            })
            if (res && res.err_code === 0) {
              location.href = res.data.url
              this.down = false
            } else {
              this.$message({
                message: res.err_msg,
                type: 'warning'
              });
            }

          } finally {
            this.down = false
          }
        } else {
          this.$message({
            message: '至少选择一个',
            type: 'warning'
          });
        }
      }


    },

    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.perindexList();
    },
    // 下拉
    async select() {
      let s = await storage.getSelects('app_names')
      this.appSelect = s.app_names
    },
    //渲染列表事件
    async perindexList() {
      this.loading = true;
      try {
        const res = await applyGlobalTms({
          type: this.filters.type == '' ? 0 : this.filters.type,
          tmcode: this.filters.tmcode,
          tmname: this.filters.tmname,
          appname: this.filters.appname,
          deliver_at: this.filters.deliver_at,
          status: this.filters.status,
          intcls: this.filters.intcls,
          page: this.filters.page,
          per_page: this.filters.per_page
        })
        if (res && res.err_code === 0) {
          this.loading = false
          this.applyList = res.data.list
          this.pageSize = res.data.total
        } else {
          this.applyList = []
        }
      } finally {
        this.loading = false
      }
    },
    // 重置
    reset() {
      this.filters = this.$options.data().filters;
      this.perindexList(this.filters.page = 1)
    },
    // 下载
    open(item,url) {
      let name = item.tmcode + '-' + item.intcls + '-' + (item.tmname ? item.tmname : '图形') + '-' + url.matter +  '.' + url.name.split('.')[1]
      location.href = url.url.replace(url.name,name)
    },
// 下载文件逾期提示
    over() {
      this.$confirm('已超出30天免费下载期，开通企业版可享无限期免费下载，详情请联系知产顾问！', '提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
        type: 'warning'
      })
    },
// 文件删除
    delete(id) {
      this.$confirm('确定要删除吗？删除后将无法恢复！', '提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
        type: 'warning'
      })
    },
    tableRowClassName(e,row) {
      if(!e.row.show){
        row.splice(e.rowIndex,1)
      }
    },
  }
}
</script>

<style lang="less" scoped>
@import "../../style/apply.less";

::v-deep .el-table {
  .col-red {
    color: #f56c6c !important;
  }
}
</style>



