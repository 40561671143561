<template>
  <div class="">
    <h2>
      软件著作权<span v-if="softwareList.length != 0">{{ pageSize }} </span>
    </h2>
    <form action="" ref="filters clearfix" v-on:submit.prevent>
      <el-row :gutter="10" class="formInput" :class="{on:formOpen}">
        <el-col :xs="10" :sm="10" :md="7" :lg="7" :xl="7">
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="6" :sm="7" :md="7" :lg="6" :xl="4"><strong>软件名称</strong></el-col>
            <el-col :xs="18" :sm="17" :md="17" :lg="18" :xl="20">
              <el-input
                  clearable
                  size="small"
                  v-model="filters.fullname"
                  placeholder="请输入软件名称"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="10" :sm="10" :md="7" :lg="7" :xl="7">
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="6" :sm="7" :md="7" :lg="6" :xl="4"><strong>软件简称</strong></el-col>
            <el-col :xs="18" :sm="17" :md="17" :lg="18" :xl="20">
              <el-input
                  clearable
                  size="small"
                  v-model="filters.simplename"
                  placeholder="请输入软件简称"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="10" :sm="10" :md="7" :lg="7" :xl="7">
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="6" :sm="7" :md="7" :lg="6" :xl="4"><strong>登记号</strong></el-col>
            <el-col :xs="18" :sm="17" :md="17" :lg="18" :xl="20">
              <el-input
                  clearable
                  size="small"
                  v-model="filters.regnum"
                  placeholder="请输入登记号"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="10" :sm="10" :md="7" :lg="7" :xl="7">
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="6" :sm="7" :md="7" :lg="6" :xl="4"><strong>著作权人</strong></el-col>
            <el-col :xs="18" :sm="17" :md="17" :lg="18" :xl="20">
              <el-select
                  clearable
                  size="small"
                  v-model="filters.author_nationality"
                  filterable
                  placeholder="请选择著作权人"
              >
                <el-option
                    v-for="item in authorSelect"
                    :key="item.text"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="10" :sm="10" :md="7" :lg="7" :xl="7">
          <el-row type="flex" align="middle" justify="space-between" :gutter="10">
            <el-col :xs="6" :sm="7" :md="7" :lg="6" :xl="4"><strong>登记日期</strong></el-col>
            <el-col :xs="18" :sm="17" :md="17" :lg="18" :xl="20">
              <el-date-picker
                  size="small"
                  v-model="filters.regtime"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="formOpen">
        <el-col  :span="24" :class="{on:formOpen}" >
          <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
        </el-col>
      </el-row>

      <el-button type="primary" size="small" class="search" icon="el-icon-search" @click="perindexList(filters.page = 1)">
        查询
      </el-button>
      <el-button type="reset" :class="{on:formOpen}" size="small" @click="reset" icon="el-icon-refresh-left" class="reset">
        重置
      </el-button>
    </form>
    <div class="mainrb">
      <!-- 表单 -->
      <div class="page-up">
        <el-pagination
            background
            size="small"
            layout="prev, next"
            @current-change="perindexList"
            :current-page.sync="filters.page"
            :page-sizes="[30, 50, 100]"
            :page-size="filters.per_page"
            :total="pageSize">
        </el-pagination>
      </div>
      <!-- 表格 -->
      <el-skeleton  :loading="loading" animated :count="1">
        <template slot="template">
          <el-table :data="softwareList" :class="{on:!formOpen}">
            <el-table-column  width="50" label="序号">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="软件名称">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column  label="著作权人">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column  label="版本号">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column  label="发布日期">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column  label="软件简称">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column  label="登记号">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column  label="登记批准日期" width="110">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-table :data="softwareList" height="100%" :class="{on:!formOpen,ios:$store.state.heights}">
          <template slot="empty">
            <el-empty :image-size="80"></el-empty>
          </template>
          <el-table-column type="index" width="50" label="序号">
          </el-table-column>
          <el-table-column label="软件名称" :show-overflow-tooltip="true" prop="fullname"> </el-table-column>
          <el-table-column  label="著作权人" width="250">
            <template slot-scope="scope">
              <router-link
                  v-if="scope.row.client_id != 0"
                  :to="{
                  name: 'newlyBuild',
                    params: { id: scope.row.client_id  },
                }" target="_blank"
              >
                {{scope.row.author_nationality }}
              </router-link>
              <template v-else>
                {{scope.row.author_nationality || '-'}}
              </template>
            </template>
          </el-table-column>
          <el-table-column  label="版本号">
            <template slot-scope="scope">
              <el-tag size="mini">{{scope.row.version}} </el-tag>
            </template>
          </el-table-column>
          <el-table-column  label="发布日期" prop="publishtime">
            <template slot-scope="scope">
              <span >{{ scope.row.publishtime ||  '-' }}</span>
            </template>
          </el-table-column>
          <el-table-column  label="软件简称" :show-overflow-tooltip="true" >
            <template slot-scope="scope">
              {{scope.row.simplename || '-'}}
            </template>
          </el-table-column>
          <el-table-column  label="登记号" prop="regnum" :show-overflow-tooltip="true">

          </el-table-column>
          <el-table-column  label="登记批准日期"  width="110">
            <template slot-scope="scope">
              {{scope.row.regtime || '-'}}
            </template>
          </el-table-column>
        </el-table>
      </el-skeleton>

      <!-- 分页 -->
      <el-pagination
        @size-change="changeRowNum"
        @current-change="perindexList(backTop)"
        :current-page.sync="filters.page"
        :page-sizes="[30, 50, 100]"
        :page-size="filters.per_page"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageSize"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import { software } from '@/api/personal.js'
export default {
  data() {
    return {
      formOpen:false,
      backTop:true,
      loading: true,//控制列表的骨架屏
      pickerOptions: {//时间选择器的便利选择对象
        shortcuts:storage.getShortcuts(),
      },
      softwareList: [],//列表数组
      filters:{
        fullname:'',
        author_nationality:'',
        simplename:'',
        regnum:'',
        regtime:'',//登记批准日期
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
      },
      authorSelect:[],
      typeSelect:[],
      pageSize: 0, //总条数

    };
  },
  created() {
    this.perindexList();
    this.select()
  },

  methods: {
    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.perindexList(this.backTop);
    },

    //渲染列表事件
    async perindexList(backTop) {
      this.loading = true;
      this.softwareList = storage.getList()
      try{
        const res = await software(this.filters)
        if(res && res.err_code === 0){
          if(backTop === true){
            storage.backTop()
          }
          this.loading = false;
          this.pageSize = res.data.total;
          this.softwareList = res.data.list;
        }else {
          this.softwareList = []
        }
      }finally{
        this.loading = false;
      }

    },
    //下拉框数据
    async select(){
      let s = await storage.getSelects('app_names', 'copyright_types')
      this.authorSelect = s.app_names

    },
    //重置事件
    reset() {
      this.filters = this.$options.data().filters; //清空表单
      this.perindexList()
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/copyright.less";

@media screen and (max-width: 768px) {


}
@media screen and (min-width: 768px) {
  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 472px") !important;
  }
  .ios{
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 512px") !important;
    }
  }

  .el-table.on{
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 388px") !important;
    }
  }
  .el-table.on.ios{
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 428px") !important;
    }
  }

}
@media screen and (min-width: 992px) {
  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 420px") !important;
  }
  .ios{
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 460px") !important;
    }
  }

}
</style>
