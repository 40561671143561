
<template>
  <div class="entrusted">
    <h2>委托办理</h2>
    <div class="entrustedt">
      <el-steps :active="active" align-center>
        <el-step v-for="item in activeList" :title="item.text" :description="item.description"></el-step>
      </el-steps>
    </div>
    <el-form :model="filters" :rules="filtersRules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <div class="entrusted-type">
        <el-form-item label="委托类型" prop="checkList">
          <el-checkbox-group v-model="filters.checkList">
            <el-checkbox label="商标"></el-checkbox>
            <el-checkbox label="专利"></el-checkbox>
            <el-checkbox label="版权"></el-checkbox>
            <el-checkbox label="法律"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
      <div class="entrusted-matters">
        <el-form-item label="委托事宜" prop="entrust">
          <el-input clearable v-model="filters.entrust" size="small" placeholder="如：3件注册+3件续展"></el-input>
        </el-form-item>

      </div>
    <div class="entrusted-applicant">
      <el-form-item label="申请人" prop="applicant">
        <el-select clearable size="small"  v-model="filters.applicant" placeholder="请选择申请人">
          <el-option

              v-for="item in applicantSelect.select"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-button size="small" type="primary"  icon="el-icon-plus">新建</el-button>
      </el-form-item>

    </div>
      <div class="entrusted-contacts">
        <el-form-item label="联系人" prop="contacts">
          <el-input clearable v-model="filters.contacts" size="small" placeholder="老三"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input clearable v-model="filters.phone" size="small" placeholder="12312341234"></el-input>
        </el-form-item>
      </div>
      <div class="entrusted-instructions">
        <el-form-item label="其他说明" prop="explain">
          <el-input type="textarea" v-model="filters.explain"></el-input>
        </el-form-item>
      </div>
      <el-button size="small" type="primary" @click="entrustedSub('ruleForm')">立即提交</el-button>
    </el-form>

    <div class="entrusted2">
      <h3>方案</h3>
      <div class="">
        <strong>简述</strong>
        <el-input type="textarea"></el-input>
      </div>
      <div class="">
        <strong>附件</strong>
        <el-table >

        </el-table>
      </div>
    </div>
    <div class="wait" v-show="waitShow">
      <h3>{{active == 0 ?  '请等待您的顾问为您制定方案' : '请等待您的顾问为您制定订单' }}</h3>
      <div class="wait-line"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active:1,

      activeList:[
          {text:'提交',description:'2021-10-11'},
        {text:'方案',description:'2021-10-14'},
        {text:'订单',description:'2021-10-15'},
        {text:'报送',description:'2021-10-18'},
      ],
      filters: {
        applicant: "",
        entrust:'',
        contacts:'',
        phone:'',
        explain:'',
        checkList:[],

      },
      filtersRules:{
        checkList: [
          { type: 'array', required: true, message: '请至少选择一个委托类型', trigger: 'change' }
        ],
        entrust: [
          { required: true, message: '请输入委托事宜', trigger: 'blur' },
        ],
        applicant: [
          { required: true, message: '请选择申请人', trigger: 'change' }
        ],
        contacts: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        phone: [
          {type: 'number', required: true, message: '请输入电话', trigger: 'change' },
        ],
      },
      waitShow: false,
      applicantSelect: {
        input: "请选择申请人",
        select: [
          {
            name: "张三",
          },
          {
            name: "李四",
          },
          {
            name: "王五",
          },
        ],
      },
    };
  },
  created() {},
  methods: {

    entrustedSub(ruleForm) {

      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.waitShow = true;
          this.$message({
            message: '提交成功，请等待您的顾问为您制定方案…',
            type: 'success'
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};




</script>

<style lang="less" scoped>
@import "../../style/entrusted.less";
</style>