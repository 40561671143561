<template>
<div class="myMessageCenter">
  <h2>消息中心</h2>
  <div class="mainrb">



      <el-tabs v-model="filters.type" @tab-click="handleClick">
        <el-tab-pane label="全部" name="0"></el-tab-pane>
        <el-tab-pane label="服务消息" name="2"></el-tab-pane>
        <el-tab-pane label="系统消息" name="1"></el-tab-pane>
      </el-tabs>

    <el-row>
      <el-col :span="read ? 10 : 24 " :class="{on:read}" class="notice">
        <el-skeleton  :loading="loading" animated >
          <template slot="template">
            <el-table
                ref="multipleTable"
                height="100%"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
            >
              <el-table-column
                  type="selection"
                  width="55">
              </el-table-column>
              <el-table-column >
                <template slot="header" slot-scope="scope">
                  <el-row class="noticeT">
                    <el-skeleton-item variant="text" style="width: 100px;" />
                    <el-skeleton-item variant="text" style="width: 100px;margin: 0 10px" />
                    <el-skeleton-item variant="text" style="width: 100px;" />
                  </el-row>
                </template>
                <template slot-scope="scope" >
                  <div class="list"  >
                    <span> <el-skeleton-item variant="text" style="width: 100px;" /></span>
                    <span> <el-skeleton-item variant="text" style="width: 100px;" /></span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>
          <el-table
              ref="multipleTable"
              :class="{ios:$store.state.heights,on:read,col:!$store.state.isCollapse}"
              height="100%"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange">
            <template slot="empty">
              <el-empty :image-size="80"></el-empty>
            </template>
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column >
              <template slot="header" slot-scope="scope">
                <el-row class="noticeT" :class="{on:read}">

                  <el-button size="small" :disabled="disabled" type="danger" icon="el-icon-delete" @click="deleteChoose(chooseId)">删除
                  </el-button>
                  <el-button size="small" :disabled="disabled" :type="disabled ? 'info' : 'primary'" icon="el-icon-folder-opened" @click="readChoose(chooseId)">标记已读
                  </el-button>
                  <el-button size="small" type="primary" plain icon="el-icon-folder-opened" @click="allRead">全部已读
                  </el-button>

                </el-row>
              </template>
              <template slot-scope="scope" >
                <div class="list" :class="{on: readAll || scope.row.read_at == 1,read:read}"  @click="cellChoose(scope.row)">
                  <span >{{ scope.row.title }}</span>
                  <span >{{ scope.row.created_at.split(' ')[0] }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-skeleton>
      </el-col>
      <el-col :span="read ? 14 : 0 " :class="{on:read}" class="detail">
        <el-skeleton :loading="loadDetail" animated >
          <template slot="template">
            <div class="bor-bottom p-b10 m-b10">
              <el-skeleton-item variant="text"  />
              <el-skeleton-item variant="text" style="width: 60%" />
            </div>
            <table class="w100 text-cen">
              <thead>
              <tr >
                <th >
                  <el-skeleton-item variant="text" style="width: 60%" />
                </th>
                <th >
                  <el-skeleton-item variant="text" style="width: 60%" />
                </th>
                <th >
                  <el-skeleton-item variant="text" style="width: 60%" />
                </th>
                <th >
                  <el-skeleton-item variant="text" style="width: 60%" />
                </th>
              </tr>
              </thead>
              <tbody>
              <tr >
                <td  >
                  <el-skeleton-item variant="text" style="width: 60%" />
                </td>
                <td  >
                  <el-skeleton-item variant="text" style="width: 60%" />
                </td>
                <td  >
                  <el-skeleton-item variant="text" style="width: 60%" />
                </td>
                <td  >
                  <el-skeleton-item variant="text" style="width: 60%" />
                </td>
              </tr>
              <tr >
                <td  >
                  <el-skeleton-item variant="text" style="width: 60%" />
                </td>
                <td  >
                  <el-skeleton-item variant="text" style="width: 60%" />
                </td>
                <td  >
                  <el-skeleton-item variant="text" style="width: 60%" />
                </td>
                <td  >
                  <el-skeleton-item variant="text" style="width: 60%" />
                </td>
              </tr>
              </tbody>

            </table>

          </template>
          <template>
            <i class="el-icon-arrow-left" @click="read = false"></i>
            <h3>{{ detail.title }}</h3>
            <h5>发布于{{ detail.created_at }}</h5>
            <table v-if="detail.content.thead" class="w100 text-cen">
              <thead>
              <tr >
                <th v-for="item in detail.content.thead">{{item}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="tb in detail.content.tbody">
                <td v-for="td in tb" class="col-606">
                  <p v-if="td.toString().indexOf('href')" v-html="td"></p>
                  <p v-else >{{td || '-'}}</p>
                </td>
              </tr>
              </tbody>

            </table>
            <div class="" v-else v-html="detail.content.html"></div>
          </template>
        </el-skeleton>

      </el-col>
    </el-row>

    <el-pagination
        @size-change="changeRowNum"
        @current-change="list"
        :current-page.sync="filters.page"
        :page-sizes="[30, 50, 100]"
        :page-size="filters.per_page"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageSize"
    >
    </el-pagination>
  </div>
</div>
</template>
<script>
import storage from '@/utils/storage'
import { messages,messagesRead,messagesDelete ,messagesDetail} from '@/api/personal.js'
export default  {
  data (){
    return{
      loadDetail:true,
      loading:true,
      filters:{
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
        type: '0',//tab切换的起始点
      },
      type:'1',
      pageSize: 0, //总条数
      read:false,//控制详情的显示与隐藏
      readZero:false,
      readSecond:false,
      disabled:true,//控制删除与已读按钮的可点击
      chooseId:{
        ids:[]
      },//记录选中的id
      chooseSecondId:[],
      tableData: [],//表格数据
      multipleSelection: [],//记录选中的数据
      readAll:false,//控制全部已读
      readZeroAll:false,
      readSecondAll:false,
      detail: {
        content:{
          tbody:[],
          thead:[]
        }
      },//详情数据
    }
  },

  created() {

    this.list()
  },
  methods: {
    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.list();
    },
   async list(){
     this.loading = true
     this.tableData = storage.getList()
      try {
        const res = await messages(this.filters)
        if(res && res.err_code === 0){
          this.tableData = res.data.list
          this.loading = false
          this.filters.per_page = res.data.per_page
          this.pageSize = res.data.total
          this.filters.page = res.data.current_page
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      }finally {
        this.loading = false
      }
    },
    handleClick(tab, event) {
      // this.filters.type = this.type == '1' ? 2 : 1
     this.filters.page = 1
      this.pageSize = 0
      this.read = false
      this.readSecond = false
      this.disabled = true
      this.list()
    },
//控制删除和已读按钮的点击事件
    handleSelectionChange(val) {
      this.chooseId.ids = []
      val.forEach((item) =>{
        this.chooseId.ids.push(item.id)
      })
      if(val.length > 0){
        this.disabled = false
      }else{
        this.disabled = true
      }
    },
    handleSelectionChangeSecond(val){
      this.chooseSecondId = []
      val.forEach((item) =>{
        this.chooseSecondId.push(item.id)
      })
      if(val.length > 0){
        this.disabled = false
      }else{
        this.disabled = true
      }
    },
    //删除选中事件
    deleteChoose(val){
     try {
       this.$confirm('确定要删选中信息吗？', '提示', {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         type: 'warning',
       }).then(async () =>{
         const res = await messagesDelete(val)
         if(res && res.err_code === 0){
           // console.log(res)
         }
       })

     }finally {

     }
    },
    deleteChooseSecond(val){
      try {
        this.$confirm('确定要删选中信息吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () =>{
          const res = await messagesDelete({ids:val})
          if(res && res.err_code === 0){
            // console.log(res)
          }
        })

      }finally {

      }
    },
    //已读选中事件
    async readChoose(val){
      let ids = []
      this.tableData.forEach( (item) =>{
       if(val.ids.indexOf(item.id) != -1){
         if(item.read_at == 0){
           ids.push(item.id)
           item.read_at == 1
         }else{
           this.$refs.multipleTable.clearSelection();
         }
       }
     })
      if(ids.length != 0){
        try {
          const res = await messagesRead({ids:ids})
          if(res && res.err_code === 0){

            this.$refs.multipleTable.clearSelection();
          }
        }finally {

        }
      }

    },
    async  readChooseSecond(val){
      let ids = []
     this.tableData.forEach( (item) =>{
       if(val.indexOf(item.id) != -1){
         if(item.read_at == 0){
          ids.push(item.id)
         }else{
           this.$refs.multipleTableSecond.clearSelection();
         }
       }
     })
      if(ids.length != 0){
        try {
          const res = await messagesRead({ids:ids})
          if(res && res.err_code === 0){
            this.$refs.multipleTableSecond.clearSelection();
          }
        }finally {

        }
      }
    },
    // 控制详情事件
    cellChoose(row){
      this.read = true
      this.loadDetail = true
      messagesDetail({id:row.id}).then(res =>{
        this.detail =  {...res.data}
        this.loadDetail = false
        if( row.read_at == 0){
          this.chooseId.ids.push(row.id)
          row.read_at = 1
          messagesRead({ids:this.chooseId.ids}).then(result =>{

          })
          // this.readChoose(this.chooseId)
          this.detail.read_at = 1
        }
      })


    },
    cellChooseSecond(row, column, cell, event){
      this.readSecond = true
      this.detail = {...row}

      if( row.read_at == 0){
        this.chooseSecondId.push(row.id)
        this.readChooseSecond(this.chooseSecondId)
        this.detail.read_at = 1
      }
    },
    allRead(){
      this.readAll = true
      this.tableData.forEach((item) =>{
        if(item.read_at == 0){
          this.chooseId.ids.push(item.id)
        }
      })
      if(this.chooseId.ids.length != 0){
        this.readChoose(this.chooseId)
        this.$message({
          message: '全部信息已读',
          type: 'success'
        });
      }

    },


    allReadSecond(){
      this.readSecondAll = true
      this.tableData.forEach((item) =>{
        if(item.read_at == 0){
          this.chooseSecondId.push(item.id)
        }
      })
      if(this.chooseSecondId.length != 0){
        this.readChoose(this.chooseSecondId)
        this.$message({
          message: '全部信息已读',
          type: 'success'
        });
      }

    }
  }
}
</script>

<style lang="less" scoped>
@import "../../style/myMessageCenter.less";
.el-collapse{
  padding: 0 10px 0 25px;
  background: #fff;
  .on{
    ::v-deep .el-collapse-item__header{
      color: #BBBBBB;
    }
  }
  a{
    color: #3291F8;
  }
}
</style>