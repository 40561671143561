<template>
  <div class="">
    <h2>订单查询</h2>
    <form ref="filters" action="" v-on:submit.prevent>
      <label class="order-no">
        <strong>订单编号</strong>
        <div class="">
          <el-input
              v-model="filters.orderNo"
              clearable
              placeholder="请输入订单编号"
              size="small"
          ></el-input>
        </div>
      </label>
      <label class="trade-name">
        <strong>商标名称</strong>
        <div class="">
          <el-input
              v-model="filters.tradeName"
              clearable
              placeholder="请输入商标名称"
              size="small"
          ></el-input>
        </div>
      </label>
      <label class="adviser">
        <strong>顾问</strong>
        <div class="">
          <el-input
              v-model="filters.adviser"
              clearable
              placeholder="请输入顾问"
              size="small"
          ></el-input>
        </div>
      </label>
      <div class="order-type">
        <strong>订单类型</strong>
        <div class="">
          <el-select
              v-model="filters.ordertype"
              clearable
              filterable
              placeholder="请选择订单类型"
              size="small"
          >
            <el-option
                v-for="item in ordertypeSelect.select"
                :key="item.text"
                :label="item.text"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="business-type">
        <strong>业务类型</strong>
        <div class="">
          <el-select
              v-model="filters.businessType"
              clearable
              filterable
              placeholder="请选择业务类型"
              size="small"
          >
            <el-option
                v-for="item in businessTypeSelect.select"
                :key="item.value"
                :label="item.value"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="applicant">
        <strong>申请人</strong>
        <div class="">
          <el-select
              v-model="filters.applicant"
              clearable
              filterable
              placeholder="请选择申请人"
              size="small"
          >
            <el-option
                v-for="item in applicantSelect.select"
                :key="item.value"
                :label="item.value"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <el-button
          class="search"
          size="small"
          type="primary"
          @click="perindexList"
      >
        查询
      </el-button>
      <el-button class="reset" size="small" @click="reset"> 重置</el-button>
    </form>
    <div class="mainrb">
      <div class=""><h2>订单信息</h2></div>
      <el-skeleton :count="1" :loading="loading" animated>
        <template slot="template">
          <el-table
              :data="personal"
              style="width: 100%">
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-form class="demo-table-expand" inline label-position="left">
                  <el-form-item label="订单编号">
                    <span>{{ props.row.orderNo }}</span>
                  </el-form-item>
                  <el-form-item label="商标名称">
                    <span>{{ props.row.tradeName }}</span>
                  </el-form-item>
                  <el-form-item label="商标类别">
                    <span>{{ props.row.trademarkCategory }}</span>
                  </el-form-item>
                  <el-form-item label="业务类型">
                    <span>{{ props.row.businessType }}</span>
                  </el-form-item>
                  <el-form-item label="代理人">
                    <span>{{ props.row.adviser }}</span>
                  </el-form-item>
                  <el-form-item label="申请人">
                    <span>{{ props.row.applicant }}</span>
                  </el-form-item>
                  <el-form-item label="订单状态">
                    <span>{{ props.row.paid ? '待支付' : '已支付' }}</span>
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column>
            <el-table-column
                label="订单编号">
              <template slot-scope="scope">
                <el-skeleton-item style="width: 50%" variant="text"/>
              </template>
            </el-table-column>
            <el-table-column
                label="商标名称">
              <template slot-scope="scope">
                <el-skeleton-item style="width: 50%" variant="text"/>
              </template>
            </el-table-column>
            <el-table-column
                label="商标类别">
              <template slot-scope="scope">
                <el-skeleton-item style="width: 50%" variant="text"/>
              </template>
            </el-table-column>
            <el-table-column
                label="业务类型">
              <template slot-scope="scope">
                <el-skeleton-item style="width: 50%" variant="text"/>
              </template>
            </el-table-column>
            <el-table-column
                label="代理人">
              <template slot-scope="scope">
                <el-skeleton-item style="width: 50%" variant="text"/>
              </template>
            </el-table-column>
            <el-table-column
                label="申请人">
              <template slot-scope="scope">
                <el-skeleton-item style="width: 50%" variant="text"/>
              </template>
            </el-table-column>
            <el-table-column
                label="订单状态">
              <template slot-scope="scope">
                <el-skeleton-item style="width: 50%" variant="text"/>
              </template>
            </el-table-column>
            <el-table-column
                class="operation" fixed="right" label="操作" width="220">
              <template slot-scope="scope">
                <el-button size="small" type="primary">查看</el-button>
                <el-button plain size="small" type="primary">{{ scope.row.paid ? '支付' : '合同' }}</el-button>
                <el-button plain size="small">取消</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-table
            :data="personal"
            height="100%"
            style="width: 100%">
          <template slot="empty">
            <el-empty ></el-empty>
          </template>
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form class="demo-table-expand" inline label-position="left">
                <el-form-item label="订单编号">
                  <span>{{ props.row.orderNo }}</span>
                </el-form-item>
                <el-form-item label="商标名称">
                  <span>{{ props.row.tradeName }}</span>
                </el-form-item>
                <el-form-item label="商标类别">
                  <span>{{ props.row.trademarkCategory }}</span>
                </el-form-item>
                <el-form-item label="业务类型">
                  <span>{{ props.row.businessType }}</span>
                </el-form-item>
                <el-form-item label="代理人">
                  <span>{{ props.row.adviser }}</span>
                </el-form-item>
                <el-form-item label="申请人">
                  <span>{{ props.row.applicant }}</span>
                </el-form-item>
                <el-form-item label="订单状态">
                  <span>{{ props.row.paid ? '待支付' : '已支付' }}</span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column
              label="订单编号"
              prop="orderNo">
          </el-table-column>
          <el-table-column
              label="商标名称"
              prop="tradeName">
          </el-table-column>
          <el-table-column
              label="商标类别"
              prop="trademarkCategory">
          </el-table-column>
          <el-table-column
              label="业务类型"
              prop="businessType">
          </el-table-column>
          <el-table-column
              label="代理人"
              prop="adviser">
          </el-table-column>
          <el-table-column
              label="申请人"
              prop="applicant">
          </el-table-column>
          <el-table-column
              label="订单状态">
            <template slot-scope="scope">
              <font :class="scope.row.paid ? 'no' : 'yes'">{{ scope.row.paid ? '待支付' : '已支付' }}</font>
            </template>
          </el-table-column>
          <el-table-column
              class="operation" fixed="right" label="操作" width="220">
            <template slot-scope="scope">
              <el-button size="small" type="primary" @click="see(scope.row.id)">查看</el-button>
              <el-button plain size="small" type="primary">{{ scope.row.paid ? '支付' : '合同' }}</el-button>
              <el-button plain size="small">取消</el-button>
            </template>

          </el-table-column>
        </el-table>
      </el-skeleton>
      <el-pagination
          :current-page="curPage"
          :page-size="pageRowNum"
          :page-sizes="[30, 50, 100]"
          :total="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="changeRowNum"
          @current-change="perindexList"
      >
      </el-pagination>

    </div>
    <el-row v-if="detail" class="information">
      <i class="el-icon-circle-close" @click="close"></i>
      <el-col :span="10" :class="{on:detail}"  class="information-cont">
        <h3 class="btn">
          订单信息
          <p><span>支付</span><span @click="close">取消</span></p>
        </h3>
        <el-row class="cont-infor">
          <el-col class="infor-img">
            <img alt="" src="@/assets/img/icon_services1.png">
          </el-col>
          <el-col :lg="19" class="infor-cont">
            <el-row>
              <el-col :span="6">
                <p>订单编号</p>
                <span>CN2021079863</span>
              </el-col>
              <el-col :span="6">
                <p>订单金额</p>
                <span>￥3000</span>
              </el-col>
              <el-col :span="6">
                <p>业务类型</p>
                <span>自助办理</span>
              </el-col>
              <el-col :span="6">
                <p>订单状态</p>
                <span>待支付</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <p>委托方</p>
                <span>温州艾芮卡电子商务有限公司</span>
              </el-col>
              <el-col :span="12">
                <p>受托方</p>
                <span>江苏省宁海商标事务所有限公司</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <p>代理顾问：老六</p>
              </el-col>
              <el-col :span="12">
                <p>创建时间：2021-07-19</p>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <h3>代理事项</h3>
        <div class="cont-infor">
          <el-row>
            <el-col :span="8">
              <p>申请人</p>
              <span>温州艾芮卡电子商务有限公司</span>
            </el-col>
            <el-col :span="8">
              <p>事项</p>
              <span>商标申请</span>
            </el-col>
            <el-col :span="8">
              <p>商标类别</p>
              <span>25类 服装鞋帽</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <p>商标号</p>
              <span>57804032</span>
            </el-col>
            <el-col :span="8">
              <p>当前进度</p>
              <span>注册申请中</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <p>商品项目</p>
              <span>2501 成品衣    2501 服装    2502 婴儿全套衣    2502 服装    2503 服装    2503 游泳衣
 2504 服装    2505 服装  </span>
            </el-col>
          </el-row>
        </div>
        <el-button class="fr" size="small" type="primary">
          官文
        </el-button>
      </el-col>

    </el-row>

  </div>
</template>

<script>
import storage from '@/utils/storage'

export default {
  name: "personal",
  data() {
    return {
      detail:false,
      //订单信息列表数组
      loading: true,
      personal: [],
      //删除订单的下标
      deletePersonal: "",
      dialog: true, //控制订单删除弹框显示隐藏
      //表单数据字段
      filters: {
        orderNo: "", //订单编号
        ordertype: "", //订单类型
        businessType: "", //业务类型
        tradeName: "", //商标名称
        adviser: "", //顾问
        applicant: "", //申请人
      },
      ordertypeSelect: {
        select: storage.getIntcls(),
      },
      businessTypeSelect: {
        select: [
          {id: "01", value: "自主办理"},
          {
            id: "02",
            value: "线上办理",
          },
          {
            id: "03",
            value: "线下办理",
          },
        ],
      },
      applicantSelect: {
        select: [
          {id: "01", value: "自主办理"},
          {
            id: "02",
            value: "线上办理",
          },
          {
            id: "03",
            value: "线下办理",
          },
        ],
      },
      paidSelect: {
        select: [
          {
            id: "01",
            value: "支付宝",
          },
          {
            id: "02",
            value: "微信",
          },
          {
            id: "03",
            value: "对公转账",
          },
        ],
      },
      payment: "",
      pageSize: 0,
      curPage: 0, //默认显示第一页
      pageRowNum: 30, //显示数量
    };
  },

  created() {
    this.perindexList();
  },

  methods: {
    changeRowNum(val) {
      this.pageRowNum = val;
      this.perindexList();
    },

    async perindexList(index) {
      this.loading = true;
      this.personal = storage.getList()
      await this.$http
          .post("/api/coureslist")
          .then((res) => {
            this.loading = false;
            this.personal = res.data.list;
            this.pageSize = res.data.total;
          })
          .finally(() => {
            this.loading = false;
          });
    },

    see(id){
      this.detail = true
    },
    close(){
      this.detail = false
    },
    // 订单删除弹框显示事件
    cancel(index) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.personal.splice(index, 1);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },

    //订单下载事件
    upDown(index) {
    },

    //表单重置提交事件
    reset() {
      this.filters = this.$options.data().filters;
      this.perindexList();
    },

  },
};
</script>


<style lang="less" scoped>
@import "../../style/perindex.less";
</style>
