<template>
  <div class="">
    <h2>作品著作权信息</h2>
    <el-skeleton :loading="loading" animated>
      <template slot="template">
        <el-form ref="details" :model="details" class="demo-ruleForm" label-width="100px">
          <div class="">
            <el-row>
              <el-col :lg="8" :sm="12" :xs="24">
                <el-form-item label="作品名称:">
                  <el-skeleton-item variant="text"/>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :sm="12" :xs="24">
                <el-form-item label="完成日期:">
                  <el-skeleton-item variant="text"/>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :sm="12" :xs="24">
                <el-form-item label="作品类型:">
                  <el-skeleton-item variant="text"/>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :sm="12" :xs="24">
                <el-form-item label="登记日期:">
                  <el-skeleton-item variant="text"/>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :sm="12" :xs="24">
                <el-form-item label="著作权人:">
                  <el-skeleton-item variant="text"/>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :sm="12" :xs="24">
                <el-form-item label="发表日期:">
                  <el-skeleton-item variant="text"/>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :sm="12" :xs="24">
                <el-form-item label="登记号:">
                  <el-skeleton-item variant="text"/>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="">
            <h2>作品登记证书</h2>
            <div class="">
              <el-skeleton-item style="width: 100px" variant="text"/>
              <el-skeleton-item style="width: 280px" variant="text"/>
              <div class="">
                <el-skeleton-item style="display: block;margin: 10px 0; width: 500px;height: 20px;" variant="h3"/>
                <el-skeleton-item style="width: 500px;height: 20px;" variant="h3"/>
              </div>
            </div>
          </div>
          <div class="">
            <h2>作品样品 <font>仅美术作品、摄影作品</font></h2>
            <div class="">
              <el-skeleton-item style="width: 100px" variant="text"/>
              <el-skeleton-item style="width: 280px" variant="text"/>
              <div class="">
                <el-skeleton-item style="display: block;margin: 10px 0; width: 500px;height: 20px;" variant="h3"/>
                <el-skeleton-item style="width: 500px;height: 20px;" variant="h3"/>
              </div>
            </div>
          </div>
          <div class="btn">
            <el-skeleton-item style="width: 100px" variant="text"/>
            <el-skeleton-item style="width: 100px" variant="text"/>
          </div>
        </el-form>
      </template>
      <el-form ref="details" :model="details" class="demo-ruleForm" label-width="100px">
        <div class="">
          <el-row>
            <el-col :lg="8" :sm="12" :xs="24">
              <el-form-item label="作品名称:">
                {{ details.fullname || '-'}}
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12" :xs="24">
              <el-form-item label="完成日期:">
                {{ details.finish_time || '-'}}
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12" :xs="24">
              <el-form-item label="作品类型:">
                {{ details.type || '-'}}
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12" :xs="24">
              <el-form-item label="登记日期:">
                {{ details.regtime || '-'}}
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12" :xs="24">
              <el-form-item label="著作权人:">
                <router-link v-if=" detail.client_id != 0" :to="{
                  name: 'newlyBuild',
                    params: { id: detail.client_id  },
                }" style="color: #3291F8;" target="_blank">
                  {{ details.author_nationality || '-'}}
                </router-link>
                <template v-else>
                  {{ details.author_nationality || '-'}}
                </template>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12" :xs="24">
              <el-form-item label="发表日期:">
                {{ details.publishtime  || '-' }}
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12" :xs="24">
              <el-form-item label="登记号:">
                {{ details.regnum }}
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="">
          <h2>作品登记证书</h2>
          <div class="">
            <el-upload
                ref="fileupload"
                :before-upload="beforeAvatarUpload"
                :file-list="cert"
                :http-request="uploadImg"
                action=""
                class="upload-demo"
                list-type="picture"
            >
              <el-button icon="el-icon-upload2" size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                <el-alert
                    title="上传文件只能是 PDF,PNG和JPG 格式，且不超过2M"
                    type="info">
                </el-alert>
              </div>
              <div slot="file" slot-scope="{file}">
                <div class="img"  v-if="file.name.split('.').slice().indexOf('pdf') == -1">
                  <el-image
                      :src="file.link ? file.link : file.url"
                      :preview-src-list="file.link ? [file.link] : [file.url]"
                  >
                    <div slot="error" class="image-slot">
                      <img v-if="details.type == '美术作品'" src="@/assets/img/personal/default1.png" alt="">
                      <img v-else src="@/assets/img/personal/default2.png" alt="">
                    </div>
                  </el-image>
                </div>

                <a v-else :href="file.link ? `${file.link}&preview=true` : `${file.url}&preview=true`"
                   target="_blank">
                  <img src="@/assets/img/personal/icon-pdf.jpeg" alt="">
                </a>

                <div class="name">
                  <span class="word-break">{{ file.name }}</span>
                  <i class="el-icon-download" @click="registerDownload(file)"></i>
                </div>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="">
          <h2>作品样品 <font>仅美术作品、摄影作品</font></h2>
          <div class="">
            <el-upload
                ref="sampupload"
                :before-upload="beforeAvataruploadPattern"
                :file-list="sampleFile"
                :http-request="uploadPattern"
                action=""
                class="upload-demo"
                list-type="picture"
                multiple>
              <el-button icon="el-icon-upload2" size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                <el-alert
                    title="只能上传png或jpg文件，且不超过2M"
                    type="info">
                </el-alert>
              </div>
              <div slot="file" slot-scope="{file}">
                <div class="img">
                  <el-image
                      :src="file.link ? file.link : file.url"
                      :preview-src-list="file.link ? [file.link] : [file.url]"
                  >
                    <div slot="error" class="image-slot">
                      <img v-if="details.type == '美术作品'" src="@/assets/img/personal/default1.png" alt="">
                      <img v-else src="@/assets/img/personal/default2.png" alt="">
                    </div>
                  </el-image>
                </div>

                <div class="name">
                  {{ file.name }}
                  <i class="el-icon-download" @click="sampleDownload(file)"></i>
                </div>
                <i v-if="file.is_custom != 0" class="el-icon-close" @click="sampClose(file)"></i>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="btn">
          <el-button :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' : 'el-icon-folder-checked'" size="small" type="primary" @click="submitForm('details')">保存
          </el-button>
          <el-button icon="el-icon-close" size="small" @click="exit">取消</el-button>
        </div>
      </el-form>
    </el-skeleton>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import {fileUpload, workDetail, workSave} from '@/api/personal.js'

export default {
  name: 'WorksDetails',
  data() {
    return {
      loading: true,
      submitDis:false,
      details: {},
      cert: [],
      sampleFile: [],
      typeSelect: [],
      pic: [],
      filters: {
        id: '',
        pic: [],
        cert: [],

      }
    }
  },
  created() {
    this.select();
    this.detail()
  },
  methods: {
//下拉框数据
    async select() {
      let s = await storage.getSelects('copyright_types')
      this.typeSelect = s.copyright_types
    },
    async detail() {
      this.loading = true
      this.loadingBtn = false
      this.filters.id = this.$route.params.id
      try {
        const res = await workDetail({id: this.filters.id})
        if (res && res.err_code == 0) {
          this.loading = false
          this.details = res.data
          this.sampleFile = res.data.pic
          this.cert = [res.data.cert]
        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {
        this.loading = false
      }
    },
    registerDownload(file) {
      if (file.link) {
        location.href = file.link
      } else {
        location.href = file.url
      }

    },
    sampleDownload(file) {
      location.href = file.link
    },
    submitForm(details) {
      this.details.pic = this.details.pic.concat(this.pic)
      this.$refs[details].validate(async (valid) => {

        if (this.details.pic.length !=0 && this.details.cert != {}) {
          try {
           this.submitDis = true
            const res = await workSave(this.details)
            if (res && res.err_code === 0) {
              this.pic = []
              this.$message.success('保存成功');
              this.detail()
              this.submitDis = false
            }else{
              this.$message({
                message: res.err_msg,
                type: 'warning'
              });
            }
          } catch (e) {
            this.submitDis = false
          }
        } else {
          this.$message({
            message: '作品登记证书或作品样品，不能为空！',
            type: 'warning'
          });
          return false;
        }
      });
    },
    sampClose(file) {
      this.sampleFile = this.$refs['sampupload'].uploadFiles;
      let index = this.sampleFile.indexOf(file);
      this.sampleFile.splice(index, 1);
      this.details.pic.forEach((item, i) => {
        if (file.name == item.name) {
          this.$set(item, 'is_deleted', 1)
        }
      })
      this.pic.forEach((itemP, k) => {
        if (file.name == itemP.name) {
          this.pic.splice(k, 1)
        }
      })
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isPDF = file.type === 'application/pdf';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (isPDF || isJPG || isPNG) {
        if (!isLt2M) {
          this.$message.error('上传文件大小不能超过 2MB!');
        } else {
          this.$message.success('上传成功');
          this.cert = []
        }

      } else {
        this.$message.error('上传文件只能是 PDF,PNG和JPG 格式!');
      }

      return (isPDF || isJPG || isPNG) && isLt2M;
    },
    async uploadImg(item) {
      let formData = new FormData();
      formData.append("file", item.file);
      formData.append("is_base64", 0);
      try {
        const res = await fileUpload(formData)
        if (res && res.err_code === 0) {
          this.cert.push(res.data)
          this.cert.forEach((item) => {
            this.details.cert.name = item.name
            this.details.cert.url = item.path
            this.details.cert.link = item.url
            this.details.cert.is_custom = 1
          })
        } else {
          this.$message({
            type: "warning",
            message: res.err_msg,
          })
        }
      } finally {
        this.uploadBtn = true;
      }
    },
    beforeAvataruploadPattern(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (isJPG || isPNG) {
        this.$message.success('上传成功');
      } else {
        this.$message.error('上传文件只能是 JPG或PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 2MB!');
      }
      return (isJPG || isPNG) && isLt2M;
    },
    async uploadPattern(item) {
      let formData = new FormData();
      formData.append("file", item.file);
      try {
        const res = await fileUpload(formData)
        if (res && res.err_code === 0) {
          this.pic.push(res.data)
          this.pic.forEach((item) => {
            if (item.name == res.data.name) {
              this.$set(item, 'link', res.data.url)
              this.$set(item, 'url', res.data.path)
              this.$set(item, 'is_cover', 0)
              this.$set(item, 'is_custom', 1)
            }
          })
        } else {
          this.$message({
            type: "warning",
            message: res.err_msg,
          })
        }
      } finally {
        this.uploadBtn = true;
      }
    },

    exit() {
      window.close()
    },
  },
}
</script>

<style lang="less" scoped>
@import "../../style/worksDetails.less";
</style>