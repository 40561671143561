<template>
  <div class="">

      <h2>我的申请</h2>
      <form v-on:submit.prevent>
        <el-row >
          <el-col :xs="10" :sm="7" :md="7" :lg="7" :xl="4">
            <el-row type="flex" align="middle">
              <el-col :xs="6" :sm="7" :md="7" :lg="5" :xl="6"><strong>业务编号</strong></el-col>
              <el-col :xs="18" :sm="17" :md="17" :lg="18" :xl="18">
                <el-input clearable size="small" v-model="filters.number" placeholder="请输入业务编号"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="10" :sm="7" :md="7" :lg="7" :xl="4">
            <el-row type="flex" align="middle">
              <el-col :xs="6" :sm="7" :md="7" :lg="5" :xl="6"><strong>商标名称</strong></el-col>
              <el-col :xs="18" :sm="17" :md="17" :lg="18" :xl="18">
                <el-input clearable v-model="filters.name" size="small" placeholder="请输入商标名称"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="10" :sm="7" :md="7" :lg="7" :xl="4">
            <el-row type="flex" align="middle">
              <el-col :xs="6" :sm="7" :md="7" :lg="5" :xl="7"><strong>申请/注册号</strong></el-col>
              <el-col :xs="18" :sm="17" :md="17" :lg="18" :xl="17">
                <el-input clearable size="small" v-model="filters.tmcode" placeholder="请输入申请/注册号"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="10" :sm="7" :md="7" :lg="7" :xl="4">
            <el-row type="flex" align="middle">
              <el-col :xs="6" :sm="7" :md="7" :lg="5" :xl="6"><strong>申请人</strong></el-col>
              <el-col :xs="18" :sm="17" :md="17" :lg="18" :xl="18">
                <el-select
                    clearable
                    size="small"
                    v-model="filters.appname"
                    filterable
                    placeholder="请选择申请人"
                >
                  <el-option
                      v-for="item in appnameSelect"
                      :key="item.text"
                      :label="item.text"
                      :value="item.text"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="10" :sm="7" :md="7" :lg="7" :xl="4">
            <el-row type="flex" align="middle">
              <el-col :xs="6" :sm="7" :md="7" :lg="5" :xl="6"><strong>创建日期</strong></el-col>
              <el-col :xs="18" :sm="17" :md="17" :lg="18" :xl="18">
                <el-date-picker
                    size="small"
                    v-model="filters.regtime"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-button
          type="primary"
          @click="myApplicationLists"
          size="small"
          class="search"
        >
          查询
        </el-button>
        <el-button type="reset" @click="reset" size="small" class="reset"> 重置 </el-button>
      </form>
<div class="mainrb">
  <div class="">
    <el-empty v-if="myApplicationList.length == 0"></el-empty>
    <div
        class="myApplication-list"
        v-for="(item, index) in myApplicationList"
        :key="index"
    >
      <el-skeleton :loading="loading" animated >
        <template slot="template" >
          <div class="listl" >
            <el-skeleton-item variant="p" />
            <el-skeleton-item
                variant="image"
                style="width: 80px; height: 80px;margin:auto;"
            />
          </div>
          <div
              class="listr"
              style="
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
              "
          >
            <el-skeleton-item variant="p" style="width: 40%;margin-bottom: 15px;" />
            <el-skeleton-item variant="p" style="width: 40%" />
            <el-skeleton-item variant="p" style="width: 40%;margin-bottom: 15px;" />
            <el-skeleton-item variant="p" style="width: 40%" />
            <el-skeleton-item variant="p" style="width: 40%;margin-bottom: 12px;" />
            <el-skeleton-item variant="p" style="width: 40%" />
            <el-skeleton-item variant="p" style="width: 40%;margin-bottom: 10px;" />
            <el-skeleton-item variant="p" style="width: 40%" />
          </div>
        </template>
          <div class="listl" >
            <p>
              业务编号：<span>{{ item.number }}</span>
            </p>
            <img :src="item.img" alt="" />
          </div>
          <div class="listr">
            <ul>
              <li>
                商标名称：<span>{{ item.tradeName }}</span>
              </li>
              <li>
                当前状态：<span>{{ item.state }}</span>
              </li>
              <li>
                商标类别：<span>{{ item.category }}</span>
              </li>
              <li>
                业务类型：<span>{{ item.type }}</span>
              </li>
              <li>
                申请/注册号：<span>{{ item.registration }}</span>
              </li>
              <li>
                代理人：<span>{{ item.agent }}</span>
              </li>
              <li>
                申请人：<span>{{ item.applicant }}</span>
              </li>
              <li>
                创建时间： <span>{{ item.time }}</span>
              </li>
            </ul>
          </div>
          <router-link
              target="_blank"
              :to="{
                path: '/personal/myApplication/applicationDetails',
                query: { id: item.id },
              }"
          >
            查看详情</router-link
          >
      </el-skeleton>
    </div>
  </div>
</div>


    <!-- 分页 -->
    <div class="">
      <div class="pagesize">
        <el-pagination
            @size-change="changeRowNum"
            @current-change="myApplicationLists"
            :current-page="curPage"
            :page-sizes="[30, 50, 100]"
            :page-size="pageRowNum"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageSize"
        >
        </el-pagination>
      </div>
    </div>


  </div>
</template>

<script>
import storage from '@/utils/storage'
export default {
  data() {
    return {
      pickerOptions: {//时间选择器的便利选择对象
        shortcuts:storage.getShortcuts(),
      },
      filters: {
        number:'',
        name:'',
        tmcode:'',
        regtime: "",
        appname:'',
      },
      loading: true,
      myApplicationList: [],
      appnameSelect:[],

      pageSize: 0, //总条数
      curPage: 0, //默认显示第一页
      pageRowNum: 30, //显示数量
    };
  },
  created() {
    this.myApplicationLists();
    this.select()
  },
  methods: {
    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.pageRowNum = val;
      this.myApplicationLists();
    },
    async myApplicationLists(index) {
      this.loading = true;
      this.myApplicationList = storage.getList()
      await this.$http
        .post("/api/myApplication",{page: index,
          per_page: this.pageRowNum,})
        .then((res) => {
          this.loading = false;
          this.myApplicationList = res.data.list;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 下拉
    async select(){
      let s = await storage.getSelects('app_names')
      this.appnameSelect = s.app_names
    },
    reset(){
      this.filters = this.$options.data().filters;
      this.myApplicationLists()
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/myApplication.less";
</style>
