import { render, staticRenderFns } from "./journalDetail.vue?vue&type=template&id=7cc44106&scoped=true&"
import script from "./journalDetail.vue?vue&type=script&lang=js&"
export * from "./journalDetail.vue?vue&type=script&lang=js&"
import style0 from "./journalDetail.vue?vue&type=style&index=0&id=7cc44106&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cc44106",
  null
  
)

export default component.exports