<template>
  <div class="container main">
    <div class="search-choice">
      <h2 class="search-choice1">
        筛选条件
      </h2>
      <div class="search-choice2">
        <strong>已选条件：</strong>
        <div class="">
          <el-tag
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)">
            {{tag}}
          </el-tag>
          <el-button size="small" type="primary" @click="dynamicTags = []">全部清除</el-button>
        </div>
      </div>
      <div class="search-choice3" :class="{ on: imgOpen }">
        <strong>申请类别：</strong>
        <ul :class="{ on: imgOpen }">
          <li>01类 化学原料 (0)</li>
          <li>01类 化学原料 (0)</li>
          <li>01类 化学原料 (0)</li>
          <li>01类 化学原料 (0)</li>
          <li>01类 化学原料 (0)</li>
          <li>01类 化学原料 (0)</li>
          <li>01类 化学原料 (0)</li>
          <li>01类 化学原料 (0)</li>
          <li>01类 化学原料 (0)</li>
          <li>01类 化学原料 (0)</li>
          <li>01类 化学原料 (0)</li>
          <li>01类 化学原料 (0)</li>
          <li>01类 化学原料 (0)</li>
          <li>01类 化学原料 (0)</li>
          <li>01类 化学原料 (0)</li>
          <li>01类 化学原料 (0)</li>
          <li>01类 化学原料 (0)</li>
          <li>01类 化学原料 (0)</li>
        </ul>
        <span @click="open"
          >{{ msg }}
          <img
            src="@/assets/img/search/icon-open.png"
            alt=""
            :class="{ on: imgOpen }"
        /></span>
      </div>

      <div class="search-choice4">
        <strong>法律状态：</strong>
        <ul>
          <li>已无效 ({{ invalid }})</li>
          <li>申请中 ({{ apply }})</li>
          <li>已注册({{ register }})</li>
        </ul>
      </div>
      <div class="search-choice5">
        <strong>日期范围：</strong>
        <div class="search-time">
          <el-select clearable v-model="filters.date" size="small" placeholder="请选择">
            <el-option
                v-for="item in dateSelect"
                :key="item.value"
                :label="item.value"
                :value="item.value">
            </el-option>
          </el-select>
          <el-date-picker
              size="small"
              v-model="filters.time"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
    </div>

    <div class="search-list">
      <div class="search-listT">
        <div class="search-listTl">
          <strong>共<font>135</font> 个商标</strong>
        </div>
        <div class="search-listTr fr">
          <strong @click="list = 0" :class="{ on: list == 0 }">
            <img
                v-if="list == 0"
                src="@/assets/img/search/icon-list1.png"
                alt=""
            />
            <img v-else src="@/assets/img/search/icon-list1-1.png" alt="" />
          </strong>
          <strong @click="list = 1" :class="{ on: list == 1 }"
          ><img
              v-if="list == 1"
              src="@/assets/img/search/icon-list2-1.png"
              alt="" />
            <img v-else src="@/assets/img/search/icon-list2.png" alt=""
            /></strong>
        </div>
      </div>
      <el-table
          @sort-change="sort_change"
          ref="singleTable"
          :data="searchList"
          v-if="list == 0"
          style="width: 100%">
        <el-table-column
            type="index"
            label="序号"
            width="50">
        </el-table-column>
        <el-table-column
            property="name"
            label="商标名称">
        </el-table-column>
        <el-table-column
            property="number"
            label="商标号">
        </el-table-column>
        <el-table-column
            property="type"
            label="类别">
        </el-table-column>
        <el-table-column
            property="status"
            label="商标状态">
        </el-table-column>
        <el-table-column
            property="applicant"
            label="申请人">
        </el-table-column>
        <el-table-column
            property="time"
            label="申请日期"
            sortable="custom">
        </el-table-column>
        <el-table-column
            label="操作" width="50">
          <template slot-scope="scope">
            <router-link
                :to="{ path: '/search/details', query: { id: scope.row.id } }"
            ><img src="@/assets/img/personal/icon-modify.png" alt="" /></router-link
            >
          </template>
        </el-table-column>
      </el-table>
      <ul v-else>
        <li v-for="(item1, index1) in searchList" :key="index1">
          <img :src="item1.tmimage" alt="" />
          <div class="listr">
            <div class="">
              <strong>商标名称：<span>{{ item1.name }}</span> </strong>
              <strong
                >状态：<span v-if="(item1.status == 1)">已无效</span
                ><span v-else-if="(item1.status == 2)">申请中</span
                ><span v-else>已注册</span></strong
              >
              <strong>商标号：<span>{{ item1.number }}</span> </strong>
              <strong>类别：<span>{{ item1.type }}</span></strong>
            </div>
            <div class="">
              <strong>申请日期：<span>{{ item1.time }}</span></strong>
              <strong>注册日期：<span>{{ item1.time }}</span></strong>
              <strong>申请人：<span>{{ item1.applicant }}</span></strong>
            </div>
            <div class="">
              <strong>商标服务：<span>{{ item1.applicant }}</span></strong>
            </div>
          </div>
          <el-button type="primary" size="small">详情</el-button>
        </li>
      </ul>
      <!-- 分页 -->
      <el-pagination
          @size-change="changeRowNum"
          @current-change="search"
          :current-page="curPage"
          :page-sizes="[30, 50, 100]"
          :page-size="pageRowNum"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageSize"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import storage from '@/utils/storage'
export default {
  data() {
    return {
      filters:{
        time:'',
        date:'',
      },
      dateSelect:[{id:'01',value:'申请日期'},{id:'02',value:'注册日期'},{id:'03',value: '有效日期'}],
      dynamicTags: ['标签一', '标签二', '标签三'],
      pickerOptions: {//时间选择器的便利选择对象
        shortcuts:storage.getShortcuts()
      },
      //商标状态，下拉框的所需内容
      tmlawSelect: {
        select: [
          { id: "01", value: "申请中" },
          { id: "02", value: "已初审" },
          { id: "03", value: "已注册" },
          { id: "04", value: "已销亡" },
          { id: "05", value: "已驳回" },
          { id: "06", value: "待审中" },
        ],
      },
      searchList: [
        {
          id: "",
          name: "",
          number: "",
          type: "",
          status: "",
          applicant: "",
          time: "",
          regdate: "",
          message: "",
          tmimage: "",
        },
      ],
      imgSolt: false,
      searchDis: true, //控制日期，选择时间组件的显示与隐藏
      chooseTime: "",
      imgOpen: false,
      msg: "展开",
      invalid: 0, //无效
      apply: 0, //申请
      register: 0, //注册
      list: 0,
      pageSize: 0, //总条数
      curPage: 0, //默认显示第一页
      pageRowNum: 30, //显示数量
    };
  },
  created() {
    this.search();
  },
  methods: {
    //排序事件
    sort_change(column) {
      if (column.order === "descending") {
        this.filters.sort = "appdate|desc";
      } else {
        this.filters.sort = "appdate|asc";
      }
      this.search();
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
//分页的每页显示多少条的事件
    changeRowNum(val) {
      this.pageRowNum = val;
      this.search();
    },
    async search() {
      const res = await this.$http.post("/search").then((res) => {
        this.searchList = res.data.list;
        this.pageSize = res.data.total
        res.data.list.forEach((item) => {
          if (item.status == 1) {
            this.invalid++;
          } else if (item.status == 2) {
            this.apply++;
          } else {
            this.register++;
          }
        });
      });
    },
    //日期的选择时间组件的显示事件
    searchTimeOn() {
      this.searchDis = false;
    },
    //日期的选择时间组件的隐藏事件
    searchTimeOff() {
      this.chooseTime =
        this.$refs.searchTimeRef.year +
        "-" +
        this.$refs.searchTimeRef.currentDay; //给公开(公告)日期input赋值
      this.searchDis = true;
    },

    open() {
      this.imgOpen = !this.imgOpen;
      if (this.imgOpen) {
        this.msg = "收起";
      } else {
        this.msg = "展开";
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/searchList.less";
</style>