<template>
  <div class="">
    <h2>上传使用证据</h2>
    <div class="mainrb">
      <div class="mainrB-t">
        <el-button
            :disabled="tableData.length == 0"
            icon="el-icon-upload2"
            size="small"
            type="primary"
            @click="chooseUp"
        >
          开始上传
        </el-button>
        <el-button :disabled="disabled" icon="el-icon-delete" size="small" type="danger" @click="remove(deleteArr)">
          删除已选
        </el-button>
      </div>

      <el-table
          ref="multipleTable"
          v-loading="loadingTable"
          :data="tableData"
          :row-class-name='getRow'
          element-loading-text="文件识别中"
          height="100%"
          style="width: 100%"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
      >
        <template slot="empty">
          <el-empty :image-size="81" description="请选择文件进行上传"></el-empty>
          <button class="el-button el-button--primary el-button--small" data-v-5f0a7fba="" type="button"
                  @click="upload">
            <input ref="clearFile" accept="*" class="add-file-right-input" multiple="multiplt"
                   style="margin-left:70px;" type="file" @change="getFile($event)">
            <i class="el-icon-folder-opened"></i>
            <span>选择文件</span>
          </button>
        </template>
        <!--        :selectable="selectable"-->
        <el-table-column

            type="selection"
        >
        </el-table-column>
        <el-table-column
            label="序号"
            type="index"
        >
        </el-table-column>
        <el-table-column
            label="文件名称" width="180">
          <template slot-scope="scope">
            {{ scope.row.name }}
<!--            <a :href="`${scope.row.url}&preview=true`"-->
<!--               class="col-blue "-->
<!--               target="_blank">-->
<!--              {{ scope.row.name }}-->
<!--            </a>-->
          </template>
        </el-table-column>
        <el-table-column width="300">
          <template slot="header"  slot-scope="scope">
            <el-row :gutter="10" align="middle" type="flex">
              <el-col :span="6">
                商标品牌
              </el-col>
              <el-col :span="18">
                <el-input
                    :disabled="tableData.length == 0"
                    v-model="filtersAll.brand"
                    clearable
                    placeholder="请输入商标品牌"
                    @change="brandChange"
                    size="small">

                </el-input>
              </el-col>
            </el-row>
          </template>
          <template slot-scope="scope">
            <el-input :ref="`brandRef${scope.row.index}`"
                      class="borderWarn"
                      v-model="scope.row.brand"
                      :class="{on:!scope.row.brand && (input == scope.row.index) }"
                      clearable
                      placeholder="请输入商标品牌"
                      size="small">
            </el-input>
          </template>
        </el-table-column>


        <el-table-column width="300">
          <template slot="header"  slot-scope="scope">
            <el-row :gutter="10" align="middle" type="flex">
              <el-col :span="6">
                证据类型
              </el-col>
              <el-col :span="18">
                <el-select
                    :disabled="tableData.length == 0"
                    class="borderWarn w100"
                    v-model="filtersAll.type"
                    @change="typeChange"
                    clearable
                    placeholder="请选择证据类型"
                    size="small"
                >
                  <el-option
                      v-for="(item,index) in typeSelect "
                      :key="index + 100"
                      :label="item"
                      :value="item"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </template>
          <template slot-scope="scope">
            <el-select
                :ref="`typeRef${scope.row.index}`"
                v-model="scope.row.type"
                :class="{on:!scope.row.type && (input == scope.row.index)}"
                class="borderWarn"
                clearable
                placeholder="请选择证据类型"
                size="small"
            >
              <el-option
                  v-for="(item,index) in typeSelect "
                  :key="index + 100"
                  :label="item"
                  :value="item"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column width="300">
          <template slot="header"  slot-scope="scope">
            <el-row :gutter="10" align="middle" type="flex">
              <el-col :span="6" class="text-right">
                标签
              </el-col>
              <el-col :span="18">
                <el-select
                    :disabled="tableData.length == 0"
                    v-model="filtersAll.label"
                    @change="labelChange"
                    class="w100"
                    clearable
                    placeholder="请选择标签"
                    size="small"
                >
                  <el-option
                      v-for="(item,index) in tipSelect"
                      :key="index + 100"
                      :label="item"
                      :value="item"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </template>
          <template slot-scope="scope">
            <el-select
                :ref="`labelRef${scope.row.index}`"
                v-model="scope.row.label"
                :class="{on:!scope.row.label && (input == scope.row.index)}"
                class="borderWarn"
                clearable
                placeholder="请选择标签"
                size="small"
            >
              <el-option
                  v-for="(item,index) in tipSelect"
                  :key="index + 100"
                  :label="item"
                  :value="item"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column width="300">
          <template slot="header"  slot-scope="scope">
            <el-row :gutter="10" align="middle" type="flex">
              <el-col :span="6" class="text-right">
                使用日期
              </el-col>
              <el-col :span="18">
                <el-date-picker
                    :disabled="tableData.length == 0"
                    @change="useDateChange"
                    v-model="filtersAll.useDate"
                    type="month"
                    size="small"
                    placeholder="选择使用日期">
                </el-date-picker>

              </el-col>
            </el-row>
          </template>
          <template slot-scope="scope">
            <el-date-picker
                size="small"
                class="borderWarn"
                :ref="`useDateRef${scope.row.index}`"
                :class="{on:!scope.row.useDate && (input == scope.row.index)}"
                v-model="scope.row.useDate"
                type="month"
                placeholder="选择使用日期">
            </el-date-picker>

          </template>
        </el-table-column>

        <el-table-column
            label="上传日期">
          <template slot-scope="scope">
            <span
                v-if="scope.row.created_at != ''">{{
                scope.row.created_at == null ? '未上传' : scope.row.created_at
              }}</span>
            <i v-else class="el-icon-loading"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        title="提示"
        width="30%">
      <span class="body"><i class="el-icon-success"></i> 商标使用证据已上传成功！！</span>
      <span slot="footer" class="dialog-footer">
        <el-button icon="el-icon-folder-opened" size="small" type="primary" @click="uploaded">继续上传</el-button>
        <router-link to="/personal/evidence">
          <el-button icon="el-icon-s-data" plain size="small" type="primary" >返回列表</el-button>
        </router-link>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import {managementMatch, managementTmcodes, managementTMUpload, managementTMUploaded,evidencesUpload,evidencesUploaded,evidencesBatchDownload} from '@/api/personal.js'

export default {
  data() {
    return {
      typeSelect:['销售凭证','广告宣传','销售数据','媒体报道','榜单排行','荣誉奖项','裁判文书'],
      tipSelect:['合同','发票','海报','报告','奖牌','证书','照片','截图','文档','其他'],
      dialogVisible: false,//上传成功后弹框
      loadingTable: false,//控制加载状态
      filtersAll:{
        brand:'',
        type:'',
        label:"",
        useDate:''
      },//表格头部选择
      getMouch: {
        shortcuts: storage.getMouch()
      },
      tableData: [
        // {
        //   name: '梦之蓝广告发票.jpg',
        //   brand: '海之蓝',
        //   type: '',
        //   label: '合同、发票',
        //   size: '873k',
        //   created_at: '2022-02-03',
        //   useDate:''
        // }
      ],//列表数据
      options: [],//商标号下拉
      loading: false,//商标号查询时的加载
      files: [],//记录选择文件的file
      names: [],//记录选择文件的name
      deleteArr: [],//记录选中行id,
      disabled: true,//控制输入框的只读和禁用
      disupload: true,
      btn: true,
      input: -1,
    }
  },

  methods: {
    //修改商标品牌(全部)
    brandChange(value){
      this.tableData.forEach(item =>{
        item.brand = value
      })
    },
    //修改商标类别(全部)
    typeChange(value){
      this.tableData.forEach(item =>{
        item.type = value
      })
    },
    //修改标签(全部)
    labelChange(value){
      this.tableData.forEach(item =>{
        item.label = value
      })
    },
    //修改使用日期(全部)
    useDateChange(value){
      this.tableData.forEach(item =>{
        item.useDate = value
      })
    },

    //记录选中行
    handleSelectionChange(val) {
      this.deleteArr = []
      val.forEach((item) => {
        this.deleteArr.push(item)
      })
      if (val.length > 0) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    // 删除已选
    remove(val) {
      val.forEach((item, i) => {
        if (this.tableData.indexOf(item) != -1) {
          this.tableData.splice(this.tableData.indexOf(item), 1)
        }
        for (let k in this.files) {
          if (this.files[k].name == item.name) {
            this.files.splice(k, 1)
          }
        }

        if (this.names.indexOf(item.name) != -1) {
          this.names.splice(this.names.indexOf(item.name), 1)
        }
      })
    },
    //控制列表中不能被选
    selectable(row, index) {
      // if (row.created_at == null) {
      //   return true
      // } else {
      //   return false
      // }
    },
    upload() {
      this.$refs.clearFile.click();
    },
    //选择文件
    async getFile(event) {
      let file = event.target.files;
      for (let k of file) {
        this.names.push(k.name)
        this.files.push(k)
      }
      try {
        this.loadingTable = true
        const res = await managementMatch({names: this.names})
        if (res && res.err_code === 0) {
          res.data.forEach((item) => {
            this.tableData.push(item)
            this.disupload = false
            this.loadingTable = false
            this.btn = false
          })
        } else {
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }
      } finally {
        this.disupload = false
        this.loadingTable = false
      }
    },

    //上传文件

    getRow(row, rowIndex) {
      this.$set(row.row, 'index', row.rowIndex);
    },
    // 开始上传
    async chooseUp() {
      let cont = 0
      this.btn = true
      let int;
      let k;
      let formData;
      for (k in this.tableData) {
        if (this.tableData[k].brand && this.tableData[k].type && this.tableData[k].label && this.tableData[k].useDate) {
          int = true
        } else {
          if (!this.tableData[k].brand) {
            this.$refs[`brandRef${k}`].focus()
          } else if (!this.tableData[k].type) {
            this.$refs[`typeRef${k}`].focus()
          } else if (!this.tableData[k].label) {
            this.$refs[`labelRef${k}`].focus()
          } else {
            this.$refs[`useDateRef${k}`].focus()
          }
          this.input = k
          this.btn = false
          int = false
          break;
        }
      }
      if (int) {
        for (let i in this.tableData) {
          if (this.tableData[i].created_at == null) {
            formData = new FormData()
            formData.append("doc", JSON.stringify(this.tableData[i]));
            formData.append("file", this.files[i]);
            this.tableData[i].created_at = '2023-2-1'
            this.$message({
              message:'上传成功',
              type: 'success'
            });
            // try {
            //   const res = await managementTMUpload(formData)
            //   if (res && res.err_code === 0) {
            //     this.$set(this.tableData, i, res.data)
            //   } else {
            //     this.btn = true
            //   }
            // } finally {
            // }
          }
          if (this.tableData[i].created_at != null && this.tableData[i].created_at != '') {
            cont++
          }
        }
        if (cont == this.tableData.length) {
          this.dialogVisible = true

        }
      }
    },
    // 继续上传 async
     uploaded() {
      this.dialogVisible = false
      this.tableData = []
      this.files = []
      this.names = []
      this.input = -1
      // try {
      //   const res = await managementTMUploaded(this.tableData)
      //   if (res && res.err_code === 0) {
      //     this.dialogVisible = false
      //     this.tableData = []
      //     this.files = []
      //     this.names = []
      //     this.input = -1
      //   }
      // } finally {
      //
      // }


    }
  }
}
</script>

<style lang="less" scoped>
.mainrb {
  .mainrB-t {
    .el-button {
      margin: 10px 0 10px 10px;
    }
  }

  ::v-deep .el-table {
  .el-empty {
      padding: 0;
      .el-empty__description {
        margin: 0;
      }
    }

     .table__body-wrapper {
      height: calc(100vh - 305px) !important;

    }

 .el-date-editor {
      height: unset;
    }

    .borderWarn.on {
       input {
        border-color: red;
      }
    }

  .el-input {
      width: 100% !important;
    .el-input__inner {
      padding: 0 30px 0px 5px !important;
    }
    }
.el-date-editor{
  .el-input__inner{
    padding: 0 30px  !important;
  }
}
     .el-select__tags {
      flex-wrap: unset;
      left: 5px;
    }

    .el-table__empty-block {
      .el-table__empty-text {
        width: 55%;
      }
    }
  }

  .add-file-right-input {
    display: none;
  }
}

::v-deep .el-dialog {
  margin-top: 35vh !important;
  padding-bottom: 10px;

  .el-dialog__header {
    padding: 15px 15px 10px;
  }

  .el-dialog__body {
    padding: 10px 15px !important;

  }

  .body {
    display: flex;
    align-items: center;

    i {
      color: #67c23a;
      font-size: 24px;
      margin-right: 10px;
    }
  }

  .el-dialog__footer {
    padding: 5px 15px 0;
  }

  .dialog-footer {
    a {
      margin-left: 10px;
    }
  }
}

::v-deep .el-table__body-wrapper {
  height: calc(~"100vh - 268px") !important;
}
</style>
