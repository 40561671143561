<template>
  <div class="detail ba-col-fff">
    <div class="detail_title flex flex-cen p15 bor-bottom m-t15">
      <p class="font-22 font-wbold ">国际商标详情</p>
      <!--      plain-->
      <el-button v-if="!translateBtn" icon="icon-translate" size="mini" type="primary"
                 @click="translate">
        查看译文
      </el-button>
      <el-button v-else icon="icon-translate" size="mini" type="primary" @click="translate">
        查看源语言
      </el-button>

    </div>

    <el-skeleton :loading="loading" animated>
      <template slot="template">
        <div class="plf20 m-b40 col-606">
          <div class=" bor">
            <div class="detail_l ">
              <div class="img w100 flex align-cen ju-con-cen pos-rel">
                <el-skeleton-item
                    class="img-logo"
                    style="width: 190px;height: 190px"
                    variant="image"
                />
              </div>
              <div class="flows p20">
                <h3 class="col-303 font-wbold m-b10">商品/服务项目</h3>
                <p class="line-h30 ellipsis">
                  <el-skeleton-item class="w50" variant="p"/>
                </p>
                <p class="line-h30 ellipsis">
                  <el-skeleton-item class="w50" variant="p"/>
                </p>
                <p class="line-h30 ellipsis">
                  <el-skeleton-item class="w50" variant="p"/>
                </p>
              </div>
            </div>
            <div class="detail_r">
              <div class="r-cont">
                <h3 class="p10 col-303 font-wbold">
                  <el-skeleton-item class="w50" variant="p"/>
                </h3>
                <div class="cont-text p20 line-h30">
                  <div class="flex flex-cen">
                    <p class="w30"><strong>申请号：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p class="w30">
                      <strong>注册号：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p class="w30"><strong>类别：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>

                  </div>
                  <div class="flex flex-cen">
                    <p class="w30"><strong>申请日期：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p class="w30"><strong>注册日期：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p class="w30 word-break">
                      <strong>专用期限至：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                  </div>
                  <div class="flex flex-cen">
                    <p class="w30"><strong>申请途径：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p class="w30"><strong>基础申请：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p class="w30 word-break">
                      <strong>图片要素：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                  </div>
                  <div class="flex flex-cen">
                    <p class="w50"><strong>申请人名称：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p class="w50"><strong>代理机构：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                  </div>
                  <div class="flex flex-cen">
                    <p class="w50"><strong>申请人地址：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                    <p class="w50"><strong>代理机构地址：</strong>
                      <el-skeleton-item class="w50" variant="p"/>
                    </p>
                  </div>
                </div>
                <div class="r-list">
                  <div class="list_t ">
                    <h3 class="col-303 font-wbold clearfix p10">国家地区概况
                      <div class="fr">
                        <el-skeleton-item style="width: 100px" variant="p"/>
                      </div>
                    </h3>
                  </div>
                  <el-table
                      :data="docs"
                      height="400"
                      style="width: 100%"
                      tooltip-effect="dark"
                  >
                    <template slot="empty">
                      <el-empty></el-empty>
                    </template>
                    <el-table-column label="序号" width="50">
                      <template>
                        <el-skeleton-item style="width: 100%" variant="p"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="官文名称">
                      <template>
                        <el-skeleton-item style="width: 50%" variant="p"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="收文日期">
                      <template>
                        <el-skeleton-item style="width: 50%" variant="p"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="上传日期">
                      <template>
                        <el-skeleton-item style="width: 50%" variant="p"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                      <template>
                        <el-skeleton-item style="width: 50%" variant="p"/>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="r-list">
                  <div class="list_t ">
                    <h3 class="col-303 font-wbold clearfix p10">商标官文
                      <div class="fr">
                        <el-skeleton-item style="width: 100px" variant="p"/>

                        <el-skeleton-item style="width: 100px" variant="p"/>
                      </div>
                    </h3>
                  </div>
                  <el-table
                      :data="docs"
                      style="width: 100%"
                      tooltip-effect="dark"
                      @selection-change="handleSelectionChange"
                  >
                    <template slot="empty">
                      <el-empty></el-empty>
                    </template>
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column label="序号" width="50">
                      <template>
                        <el-skeleton-item style="width: 100%" variant="p"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="官文名称">
                      <template>
                        <el-skeleton-item style="width: 50%" variant="p"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="收文日期">
                      <template>
                        <el-skeleton-item style="width: 50%" variant="p"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="上传日期">
                      <template>
                        <el-skeleton-item style="width: 50%" variant="p"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                      <template>
                        <el-skeleton-item style="width: 50%" variant="p"/>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <div class="p-b10">
                  <h3 class="col-303 font-wbold p10" >
                    其他信息
                  </h3>
                  <div class="other">
                    <table >
                      <tr v-for="item in docs">
                        <td class="name w20" ><el-skeleton-item style="width: 100px" variant="p"/></td>
                        <td  class=" w100 flex align-cen ju-con-bet">
                          <div class=" w30"><el-skeleton-item style="width: 100px" variant="p"/></div>
                          <div class=" w70"><el-skeleton-item style="width: 100px" variant="p"/></div>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <h3 class="col-303 font-wbold p10 " >
                    其他流程
                  </h3>
                  <div class="tmExtraFlow">
                    <table class="w100" >

                      <tr v-for="item in docs">
                        <td class="name w20">
                          <div class=" w30"><el-skeleton-item style="width: 100px" variant="p"/></div>
                        </td>
                        <td class="w80">

                          <table class="w100" >
                            <tr class="">
                              <th >
                                <div class=" w30"><el-skeleton-item style="width: 100px" variant="p"/></div>
                              </th>
                            </tr>
                            <tr >
                              <td > <div class=" w30"><el-skeleton-item style="width: 100px" variant="p"/></div></td>
                            </tr>
                          </table>

                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </template>
      <template>
        <div ref="bor" class="plf20 m-b40 col-606">
          <div class=" bor">
            <div class="detail_l ">
              <div class="img w100 flex align-cen ju-con-cen pos-rel">
                <el-image
                    :preview-src-list="[detail.tm_image]"
                    :src="detail.tm_image">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline "></i>
                  </div>
                </el-image>
                <div
                    :class="{flag5:detail.tm_status == '有效',flag6:detail.tm_status == '无效',flag3:detail.tm_status != '无效' || detail.tm_status != '有效'}"
                    class="flag pos-abs pos-l0 pos-t0 ">
                  <span class="ellipsis">
                    {{ detail.tm_status }}
                  </span>

                </div>
              </div>
              <div class="flows p20">
                <h3 class="col-303 font-wbold m-b10">商品/服务项目</h3>
                <div v-for="item in (translateBtn ? replace.translate.tm_goods : replace.origin.tm_goods)" class="">
                  <p class="line-h20">
                    {{ item.code + '类' }}
                  </p>
                  <p>{{ item.name }}</p>
                </div>
              </div>


            </div>
            <div class="detail_r">
              <div class="r-cont">
                <h3 class="p10 col-blue font-wbold  ">
                  {{ detail.tm_name || '图形' }}
                </h3>
                <div class="cont-text p20 line-h30">
                  <div class="flex ju-con-bet">
                    <p class="w30"><strong>申请号：</strong>{{ detail.app_no || '-' }}
                      <i v-if="detail.app_no" v-copy="detail.app_no" class="el-icon-copy-document col-blue pointer m-l5"></i>
                    </p>
                    <p class="w30"><strong>注册号：</strong>{{ detail.reg_no || '-' }}
                      <i v-if="detail.reg_no" v-copy="detail.reg_no" class="el-icon-copy-document col-blue pointer m-l5"></i>
                    </p>
                    <p class="w30"><strong>类别：</strong><font v-for="(int,intInx) in detail.int_cls">{{ int }} <font
                        v-if="intInx != detail.int_cls.length -1">,</font> </font></p>

                  </div>
                  <div class="flex ju-con-bet">
                    <p class="w30"><strong>申请日期：</strong>{{ detail.app_date || '-' }}</p>
                    <p class="w30"><strong>注册日期：</strong>{{ detail.reg_date || '-' }}</p>
                    <p class="w30"><strong>商标专用期限：</strong>{{ detail.end_date || '-' }}</p>
                  </div>
                  <div class="flex ju-con-bet">
                    <p :class="{w30:detail.apply == '马德里',w48:detail.apply != '马德里'}"><strong>申请途径：</strong>{{ detail.apply || '-' }}</p>
                    <p v-if="detail.apply == '马德里'" class="w30"><strong>基础申请：</strong>{{ detail.basicRegNo || '-' }}
                      <i v-if="detail.basicRegNo" v-copy="detail.basicRegNo" class="el-icon-copy-document col-blue pointer m-l5"></i>
                    </p>
                    <p class="word-break" :class="{w30:detail.apply == '马德里',w48:detail.apply != '马德里'}"><strong>图片要素：</strong>{{ detail.tmImageElement || '-' }}</p>
                  </div>
                  <div class="flex ju-con-bet">
                    <p>
                      <strong>申请人名称：</strong>
                      <span :class="{'col-blue':replace.origin.applicant || replace.translate.applicant }"> {{
                          (translateBtn ? replace.translate.applicant : replace.origin.applicant) || '-'
                        }}</span>

                    </p>
                    <p>
                      <strong>代理机构：</strong>
                      <span :class="{'col-blue':replace.origin.agent || replace.translate.agent }"> {{
                          (translateBtn ? replace.translate.agent : replace.origin.agent) || '-'
                        }}</span>

                    </p>
                  </div>
                  <div class="flex ju-con-bet">
                    <p>
                      <strong>申请人地址：</strong>
                      <span> {{
                          (translateBtn ? replace.translate.applicant_address : replace.origin.applicant_address) || '-'
                        }}</span>

                    </p>
                    <p>
                      <strong>代理机构地址：</strong>
                      <span> {{
                          (translateBtn ? replace.translate.agent_address : replace.origin.agent_address) || '-'
                        }}</span>

                    </p>
                  </div>
                </div>
              </div>
              <div class="" v-if="detail.apply != '马德里'">
                <div class=" p10">
                  <h3 class="col-303 font-wbold">
                    商标流程
                  </h3>
                </div>
                <el-table :data="replace.countryFlow" row-key="key" height="300">
                  <template slot="empty">
                    <el-empty :image-size="80"></el-empty>
                  </template>
                  <el-table-column
                      label="序号" type="index">
                  </el-table-column>
                  <el-table-column
                      label="流程状态" >
                    <template slot-scope="scope">
                      <p>{{ scope.row.title  || '-'}}
                        <a v-if="scope.row.pdfUrl" :href="`${scope.row.pdfUrl}&preview=true`"
                           target="_blank">
                          <i class="col-blue el-icon-search"></i>
                        </a>

                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="流程日期" prop="announcementDate">

                  </el-table-column>
                </el-table>

              </div>

              <div class="" v-else>
                <div :class="{'bor-bottom':countryUp}" class="r-country ">
                  <div class=" p10">
                    <h3 class="col-303 font-wbold clearfix">
                      国家地区概况
                      <div class="fr col-blue font-12 pointer" @click="countryUp = !countryUp,menuTable = [] ">
                        <i :class="{'el-icon-arrow-down':!countryUp,'el-icon-arrow-up':countryUp}"></i>
                        {{ countryUp ? '收起' : '全部展开' }}
                      </div>
                    </h3>
                  </div>
                  <el-table
                      :data="(translateBtn ? replace.translate.tm_flow : replace.origin.tm_flow)"
                      :expand-row-keys="countryUp ? replace.tm_flow : menuTable"
                      class="country_list"
                      row-key="key"
                  >
                    <template slot="empty">
                      <el-empty :image-size="80"></el-empty>
                    </template>
                    <el-table-column class="cont" type="expand">
                      <template slot-scope="props" class="cont">
                        <el-table :data="props.row.list">
                          <el-table-column
                              label="#"
                              type="index"
                              width="50">
                          </el-table-column>
                          <el-table-column
                              label="流程状态">
                            <template slot-scope="scope">
                              <p>{{ scope.row.title }}
                                <a v-if="scope.row.pdfUrl" :href="`${scope.row.pdfUrl}&preview=true`"
                                   target="_blank">
                                  <i class="col-blue el-icon-search"></i>
                                </a>

                              </p>
                            </template>
                          </el-table-column>
                          <el-table-column
                              label="公告期号"
                              prop="announcementNo">
                          </el-table-column>
                          <el-table-column
                              label="公告期日"
                              prop="announcementDate">
                          </el-table-column>
                        </el-table>
                      </template>
                    </el-table-column>

                    <el-table-column
                        label="序号" type="index">
                    </el-table-column>
                    <el-table-column
                        label="国家地区" min-width="90" prop="country">
                    </el-table-column>
                    <el-table-column
                        label="最后流程" min-width="90" prop="title">
                    </el-table-column>
                    <el-table-column
                        label="流程日期"
                        min-width="110" prop="announcementDate">
                    </el-table-column>

                  </el-table>
                </div>

              </div>

              <el-tabs v-model="tabNum">
                <el-tab-pane label="商标官文" name="0">
                  <div class="r-list">
                    <div class="list_t ">
                      <el-button :disabled="applyList.length == 0" :loading="!down" icon="el-icon-download" size="small"
                                 @click="download">{{ !down ? '下载中' : '批量下载' }}
                      </el-button>
                    </div>
                    <el-table
                        :data="applyList"
                        @selection-change="handleSelectionChange"
                    >
                      <template slot="empty">
                        <el-empty :image-size="80"></el-empty>
                      </template>
                      <el-table-column type="selection" width="50"></el-table-column>
                      <el-table-column label="序号" type="index" width="50"></el-table-column>
                      <el-table-column :show-overflow-tooltip="true" label="官文类型">
                        <template slot-scope="scope">
                          <a :href="`${scope.row.url}&preview=true`"
                             target="_blank">
                            {{ scope.row.matter }}
                          </a>
                        </template>
                      </el-table-column>
                      <el-table-column label="收文日期" prop="made_at" width="110"></el-table-column>
                      <el-table-column label="上传日期" prop="created_at" width="110"></el-table-column>
                      <el-table-column label="操作" width="140">
                        <template slot-scope="scope">
                          <div class="operation">
                            <el-button icon="el-icon-download" size="small" type="text" @click="open(scope.row)">下载
                            </el-button>
                            <el-button v-if="scope.row.is_custom == 1" class="del" icon="el-icon-delete" size="small"
                                       type="text" @click="deleteFile(scope.row.id)">删除
                            </el-button>

                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="关联业务" name="1">
                  <div class="r-list">
                    <div class="list_t ">
<!--                      <el-button :disabled="applyList.length == 0" :loading="exportDown" icon="el-icon-document"-->
<!--                                 size="small" type="primary" @click="downloadDia = true">-->
<!--                        {{ exportDown ? '导出中' : '导出数据' }}-->
<!--                      </el-button>-->
                    </div>
                    <el-table :data="applyList">
                      <template slot="empty">
                        <el-empty :image-size="80"></el-empty>
                      </template>
                      <el-table-column label="序号" type="index" width="50"></el-table-column>
                      <el-table-column label="商标号" prop="tmcode"></el-table-column>
                      <el-table-column label="商标名称">
                        <template slot-scope="scope">
                          <div class="tmimage flex align-cen ">
                            <div class="">
                              <el-image
                                  :preview-src-list="[scope.row.tmimage]"
                                  :src="scope.row.tmimage"
                              >
                                <div slot="error" class="image-slot">
                                  <i class="el-icon-picture-outline"></i>
                                </div>
                              </el-image>
                            </div>
                            <p class="vertical ver-line3">{{ scope.row.tmname || '图形' }}</p>
                          </div>

                        </template>
                      </el-table-column>
                      <el-table-column label="类别" prop="intcls"></el-table-column>
                      <el-table-column label="申请类型" prop="apptype"></el-table-column>
                      <el-table-column label="金额" width="110">
                        <template slot-scope="scope">
                          ￥{{ scope.row.subtotal || '0' }}

                        </template>
                      </el-table-column>
                      <el-table-column label="申请日期" width="110">
                        <template slot-scope="scope">
                          {{ scope.row.deliver_at || '-' }}

                        </template>
                      </el-table-column>
                      <el-table-column label="操作">
                        <template slot-scope="scope">
                          <router-link
                              :to="{ name:'applyDetail',params: {id: scope.row.id}}" class="disblk"
                              target="_blank"
                          >
                            <el-button icon="el-icon-edit-outline" size="small" type="primary">详情</el-button>
                          </router-link>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-tab-pane>

              </el-tabs>

              <div class="p-b10" v-if="detail.apply != '马德里'">
                <h3 class="col-303 font-wbold p10" :class="{'bor-bottom':replace.other.length == 0}">
                  其他信息
                </h3>
                <div class="other">
                  <el-empty description="暂无数据" v-if="replace.other.length == 0"></el-empty>
                  <table v-else>
                    <tr v-for="item in replace.other">
                      <td class="name w20" >{{ item.key }}</td>
                      <td v-for="item1 in item.value" class=" w100 flex align-cen ju-con-bet">
                        <div class=" w30">{{item1[1]}}</div>
                        <div class=" w70">{{item1[2] || '-'}}</div>
                      </td>
                    </tr>
                  </table>

                </div>
                <h3 class="col-303 font-wbold p10 " :class="{'bor-bottom':replace.tmExtraFlow.length == 0}">
                  其他流程
                </h3>
                <div class="tmExtraFlow">
                  <el-empty description="暂无数据" v-if="replace.tmExtraFlow.length == 0"></el-empty>
                  <table class="w100" v-else>

                    <tr v-for="item in replace.tmExtraFlow">
                      <td class="name w20 word-break">
                        {{item.key}}
                      </td>
                      <td class="w80">

                        <table class="w100" v-if="item.value.data">
                          <tr class="">
                            <th v-for="th in item.value.title">
                              {{th}}
                            </th>
                          </tr>
                          <tr v-for="td in item.value.data">
                            <td v-for="item1 in td">{{item1 || '-'}}</td>
                          </tr>
                        </table>
                        <div class="text-cen"  v-else>
                          暂无数据
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>

              </div>
            </div>
          </div>

        </div>

      </template>
    </el-skeleton>


    <!-- 上传 -->
    <el-dialog
        :closeOnClickModal="false"
        :visible.sync="fileSH"
        class="file"
        title="上传文件"
        width="335px"
        @close="empty"
        @closed="empty"
    >

      <div class="cont-tabc">
        <el-form ref="ruleForm" :model="manual" :rules="manualRules" label-position="right" label-width="80px"
                 size="mini">
          <el-form-item label="官文类型" prop="matter">
            <el-select
                v-model="manual.matter"
                clearable
                filterable
                placeholder="请选择官文类型"
                size="small"
            >
              <el-option
                  v-for="item in typeSelect"
                  :key="item.text"
                  :label="item.text"
                  :value="item.text"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商标号">
            <el-input
                v-model="filters.tmcode"
                clearable
                placeholder="请输入商标号"
                readonly
                size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="收文日期" prop="made_at">
            <el-date-picker
                v-model="manual.made_at"
                placeholder="请选择收文日期"
                size="small"
                type="date"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <div class="tabc-update">

            <el-upload
                ref="upload"
                :before-upload="beforeUpload"
                :file-list="fileList"
                :http-request="uploadImg"
                :limit="1"
                :on-exceed="handleExceed"
                action="/api/upload"
                class="upload-demo"
            >
              <el-form-item prop="name">
                <el-input v-model="manual.name" placeholder="请点击按钮上传文件" readonly size="small"></el-input>
              </el-form-item>
              <el-button
                  v-if="uploadBtn"
                  slot="trigger"
                  :disabled="distinguishDis"
                  circle
                  icon="el-icon-plus"
                  size="mini"
                  type="primary"
              ></el-button>
              <el-button v-else slot="trigger" :loading="true" circle size="mini" type="primary"></el-button>
            </el-upload>

          </div>
          <div class="batch">
            官文太多？马上
            <router-link to="/personal/trademarkAdmin/upload">批量上传</router-link>
          </div>
          <div class="submitBtn">
            <el-button class="cancel" icon="el-icon-close" size="small" @click="fileSH = false">取 消</el-button>
            <el-button :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' :  'el-icon-folder-checked'"
                       class="confirm" size="small"
                       type="primary" @click="submit('ruleForm')">确
              定
            </el-button>
          </div>

        </el-form>
      </div>
      <!--      <div v-show="tabtNum == 2" class="cont-tabc">-->
      <!--        <el-form ref="automatic" :model="automatic" :rules="automaticRules" label-position="right" label-width="80px"-->
      <!--                 size="mini">-->
      <!--          <div v-show="contSH" class="tabc-cont">-->
      <!--            <el-skeleton :count="1" :loading="loadingUp" animated>-->
      <!--              <template slot="template">-->
      <!--                <div class="el-form-item is-required el-form-item&#45;&#45;mini">-->
      <!--                  <label class="el-form-item__label">官文类型</label>-->
      <!--                  <div class="el-form-item__content" style="height: 32px; line-height: 32px">-->
      <!--                    <el-skeleton-item variant="p"/>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--                <div class="el-form-item is-required el-form-item&#45;&#45;mini">-->
      <!--                  <label class="el-form-item__label">商标号</label>-->
      <!--                  <div class="el-form-item__content" style="height: 32px; line-height: 32px">-->
      <!--                    <el-skeleton-item variant="p"/>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--                <div class="el-form-item is-required el-form-item&#45;&#45;mini">-->
      <!--                  <label class="el-form-item__label">收文日期</label>-->
      <!--                  <div class="el-form-item__content" style="height: 32px; line-height: 32px">-->
      <!--                    <el-skeleton-item variant="p"/>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </template>-->
      <!--              <template>-->

      <!--                <el-form-item label="官文类型" prop="matter">-->
      <!--                  <el-select-->
      <!--                      v-model="automatic.matter"-->
      <!--                      clearable-->
      <!--                      filterable-->
      <!--                      placeholder="请选择官文类型"-->
      <!--                      size="small"-->
      <!--                  >-->
      <!--                    <el-option-->
      <!--                        v-for="item in typeSelect"-->
      <!--                        :key="item.text"-->
      <!--                        :label="item.text"-->
      <!--                        :value="item.text"-->
      <!--                    >-->
      <!--                    </el-option>-->
      <!--                  </el-select>-->
      <!--                </el-form-item>-->
      <!--                <el-form-item label="商标号">-->
      <!--                  <el-input-->
      <!--                      v-model="filters.tmcode"-->
      <!--                      clearable-->
      <!--                      readonly-->
      <!--                      placeholder="请输入商标号"-->
      <!--                      size="small"-->
      <!--                  ></el-input>-->
      <!--                </el-form-item>-->
      <!--                <el-form-item label="收文日期" prop="made_at">-->
      <!--                  <el-date-picker-->
      <!--                      v-model="automatic.made_at"-->
      <!--                      placeholder="请选择收文日期"-->
      <!--                      size="small"-->
      <!--                      type="date"-->
      <!--                      value-format="yyyy-MM-dd"-->
      <!--                  >-->
      <!--                  </el-date-picker>-->
      <!--                </el-form-item>-->
      <!--              </template>-->
      <!--            </el-skeleton>-->
      <!--          </div>-->
      <!--          <div class="tabc-update">-->
      <!--            <el-upload-->
      <!--                ref="upload"-->
      <!--                :before-upload="beforeUpload"-->
      <!--                :file-list="fileList"-->
      <!--                :http-request="uploadImg"-->
      <!--                :limit="1"-->
      <!--                :on-exceed="handleExceed"-->
      <!--                action="/api/upload"-->
      <!--                class="upload-demo"-->
      <!--            >-->

      <!--              <el-form-item prop="name">-->
      <!--                <el-input v-model="automatic.name" readonly placeholder="请点击按钮上传文件" size="small"></el-input>-->
      <!--              </el-form-item>-->

      <!--              <el-button-->
      <!--                  v-if="uploadBtn"-->
      <!--                  slot="trigger"-->
      <!--                  :disabled="distinguishDis"-->
      <!--                  circle-->
      <!--                  icon="el-icon-plus"-->
      <!--                  size="mini"-->
      <!--                  type="primary"-->
      <!--              ></el-button>-->
      <!--              <el-button v-else slot="trigger" :loading="true" circle size="mini" type="primary"></el-button>-->
      <!--            </el-upload>-->
      <!--          </div>-->
      <!--          <div v-if="distinguishSh" class="tabc-distinguish">-->
      <!--            <el-button :disabled="distinguishDis" size="small" icon="el-icon-view" type="primary" @click="distinguishBtn('automatic')">识 别-->
      <!--            </el-button>-->
      <!--          </div>-->
      <!--          <el-form-item v-else class="submitBtn">-->
      <!--            <el-button class="cancel" size="small" @click="fileSH = false" icon="el-icon-close">取 消</el-button>-->
      <!--            <el-button :disabled="submitDis" class="confirm" size="small" type="primary"-->
      <!--                       @click="submitAutomatic('automatic')" icon="el-icon-folder-checked">确 定-->
      <!--            </el-button>-->
      <!--          </el-form-item>-->
      <!--        </el-form>-->
      <!--      </div>-->
    </el-dialog>
    <!--    导出-->
    <el-dialog
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        :visible.sync="downloadDia"
        class="downloadDia openDia"
        title="导出信息"
        width="576px"
        @close="openDia">
      <template>
        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="exportArr.fields" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="city in fields" :key="city" :label="city">{{ city }}</el-checkbox>
        </el-checkbox-group>
      </template>
      <span slot="footer" class="dialog-footer">
    <el-button icon="el-icon-close" size="small" @click="downloadDia = false">取 消</el-button>
    <el-button :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' : 'el-icon-folder-checked'" class="confirm"
               size="small" type="primary" @click="exDown">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import {
  batchDownload,
  fileDistinguish,
  fileSubmit,
  fileUpload,
  globalTmsDetail,
  globalTmsExport,
  managementDelete
} from '@/api/personal.js'

const cityOptions = ['商标图样', '商标名称', '商标状态', '国家地区', '申请途径', '申请号', '注册号', '商标类别', '申请日期', '申请人', '注册日期', '专用权期限', '商品项目', '代理机构', '最后流程'];
export default {
  // 使用directives注册v-focus指令

  data() {
    return {
      fields: cityOptions,
      exportDown: false,//控制导出加载的显示与隐藏
      downloadDia: false,//控制导出的对话框
      isIndeterminate: true,//控制全选状态
      checkAll: false,//全选
      exportArr: {
        fields: ['商标名称', '商标状态', '国家地区', '申请途径', '申请号', '注册号', '商标类别', '申请日期', '申请人', '注册日期', '专用权期限', '商品项目', '代理机构'],
      },//默认选中

      translateBtn: false,//控制翻译按钮
      applyList: [],//商标官文数据
      tabNum: 0,//控制tab切换
      countryUp: false,//控制展开与收起
      menuTable: [0],//国家地区概况数据
      replace: {//替代字段集合
        origin: {},
        translate: {},
        tm_flow: [],

        tmcited: [],
        flows: [],
        flowsInit: [],
        law: [],
        tmgoodsdetail: [],
        judgement: {},
        review: '',

        other:[],
        tmExtraFlow:[],

        countryFlow:[],

      },

      loading: true,//控制整个页面的骨架屏
      filters: {//接收列表页传的参数来渲染页面数据
        tmcode: '',//注册商标
        intcls: '',//国际分类
      },
      docs: storage.getList(),//骨架屏的初始数据
      detail: [],//整个详情的数据
      srcList: [],//存储查看大图的数据
      id: {ids: []},//记录批量下载的数据},
      deleteId: {//记录下载id
        id: '',
      },
      downSH: true,//控制批量下载按钮的显示与隐藏
      down: true,//控制批量下载加载中按钮的显示与隐藏

      uploadBtn: true,//控制上传文件加载中按钮的显示与隐藏
      fileList: [],//记录上传文件成功的数组
      loadingUp: true,//控制上传文件中识别文件的骨架屏
      fileSH: false,//控制上传官文弹窗的显示与隐藏
      tabtNum: 1,//控制手动和自动上传的tab切换
      file: {
        name: "",//上传官文的名称
        path: '',//上传官文的路径
      },
      distinguishSh: true,//控制识别按钮显示与隐藏
      distinguishDis: false,
      submitDis: false,
      contSH: false,//控制识别中的骨架屏
      typeSelect: [],//官文类型的下拉数据
      automatic: {//自动上传的字段
        matter: "",
        made_at: "",
        name: "",//上传官文的名称
      },
      automaticRules: {
        matter: [
          {required: true, message: '请选择官文类型', trigger: 'change'},
        ],
        made_at: [
          {required: true, message: '请选择收文日期', trigger: 'change'},
        ],
        name: [
          {required: true, message: '请点击按钮上传文件', trigger: 'change'},
        ],
      },
      manual: {//手动上传的字段
        matter: "", //官文名称
        made_at: "",
        name: ''
      },
      manualRules: {
        matter: [
          {required: true, message: '请选择官文类型', trigger: 'change'},
        ],
        made_at: [
          {required: true, message: '请选择收文日期', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请点击按钮上传文件', trigger: 'change'},
        ],
      },
    };
  },

  created() {

    this.domesticDetails({id: this.$route.params.id});

  },
  methods: {
    // 译文
    translate(){
      this.translateBtn = !this.translateBtn
      if(this.detail.apply != '马德里'){
        let otherArr = []
        this.replace.tmExtraFlow = []
        this.replace.countryFlow  = []
        this.replace.other = []
        if(!this.translateBtn){
          if(this.replace.origin.tm_flow.length != 0){
            this.replace.countryFlow  = this.replace.origin.tm_flow[0].list
          }
          this.replace.origin.tmExtraAttr.forEach(item =>{
            if(otherArr.indexOf(item[0]) == -1){
              otherArr.push(item[0])
            }
          })
          otherArr.forEach(item1 =>{
            let arr = []
            this.replace.origin.tmExtraAttr.forEach(item =>{
              if(item1 == item[0]){
                arr.push(item)
              }
            })
            let obj = {}
            obj.key = item1
            obj.value = arr
            this.replace.other.push(obj)
          })
          for(let i in this.replace.origin.tmExtraFlow){
            let flow = {}
            flow.key = i
            flow.value = this.replace.origin.tmExtraFlow[i]
            this.replace.tmExtraFlow.push(flow)
          }
        }else{
          if(this.replace.translate.tm_flow.length != 0){
            this.replace.countryFlow  = this.replace.translate.tm_flow[0].list
          }
          this.replace.translate.tmExtraAttr.forEach(item =>{
            if(otherArr.indexOf(item[0]) == -1){
              otherArr.push(item[0])
            }
          })
          otherArr.forEach(item1 =>{
            let arr = []
            this.replace.translate.tmExtraAttr.forEach(item =>{
              if(item1 == item[0]){
                arr.push(item)
              }
            })
            let obj = {}
            obj.key = item1
            obj.value = arr
            this.replace.other.push(obj)
          })
          for(let i in this.replace.translate.tmExtraFlow){
            let flow = {}
            flow.key = i
            flow.value = this.replace.translate.tmExtraFlow[i]
            this.replace.tmExtraFlow.push(flow)
          }
        }
      }
    },
    // 全选改变
    handleCheckAllChange(val) {
      this.exportArr.fields = val ? options : [];
      this.isIndeterminate = false;
    },
    // 单选改变
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.fields.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fields.length;
    },
    //导出事件
    openDia() {
      this.exportArr.fields = this.$options.data().exportArr.fields;
      this.checkAll = false
      this.isIndeterminate = true
    },
    // 确认导出
    async exDown() {
      this.exportDown = true
      this.downloadDia = false
      if (this.exportArr.fields.length != 0) {
        try {
          const res = await globalTmsExport()//,fields:this.exportArr.fields
          if (res && res.err_code === 0) {
            location.href = res.data.url
            this.exportDown = false
          } else {
            this.$message({
              message: res.err_msg,
              type: 'warning'
            });
          }

        } finally {
          this.exportDown = false
        }
      } else {
        this.$message({
          message: '至少选择一个',
          type: 'warning'
        });
      }

    },
    // 下拉
    async select() {
      let s = await storage.getSelects('tm_doc_types', 'app_names')
      for (let i in s.app_names) {
        if (s.app_names[i].text == this.detail.appname) {
          this.typeSelect = storage.getTrademarkTypes()
          break
        } else {
          this.typeSelect = storage.getMaintainTypes()
        }
      }
    },
    //返回顶部事件
    backTop() {
      const that = this;
      let ispeed = Math.floor(-that.scrollTop / 5);
      document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
    },
    //渲染详情事件
    async domesticDetails(item) {

      try {
        const res = await globalTmsDetail(item)
        if (res && res.err_code === 0) {
          this.loading = false

          this.detail = res.data;
          res.data.origin.tm_flow.forEach(item => {
            this.replace.tm_flow.push(item.key)
          })
          this.replace.origin = this.detail.origin
          this.replace.translate = this.detail.translate
          if(this.detail.apply != '马德里'){
            if(this.replace.origin.tm_flow.length != 0){
              this.replace.countryFlow  = this.replace.origin.tm_flow[0].list
            }else{
              this.replace.countryFlow  = []
            }
            let otherArr = []
            this.replace.origin.tmExtraAttr.forEach(item =>{
              if(otherArr.indexOf(item[0]) == -1){
                otherArr.push(item[0])
              }
            })
            otherArr.forEach(item1 =>{
              let arr = []
              this.replace.origin.tmExtraAttr.forEach(item =>{
                if(item1 == item[0]){
                  arr.push(item)
                }
              })
              let obj = {}
              obj.key = item1
              obj.value = arr
              this.replace.other.push(obj)
            })
            if(this.replace.origin.tmExtraFlow != null){
              for(let i in this.replace.origin.tmExtraFlow){
                let flow = {}
                flow.key = i
                flow.value = this.replace.origin.tmExtraFlow[i]
                this.replace.tmExtraFlow.push(flow)

              }
            }else{
              this.replace.tmExtraFlow = []
            }
          }

          await this.select()
        }
      } finally {
        this.loading = false
      }
    },

    //记录选择要下载的id事件
    handleSelectionChange(val) {
      this.id.ids = [];
      val.forEach((item) => {
        this.id.ids.push(item.id);
      });
    },
    //批量下载点击事件
    async download() {
      if (this.id.ids.length == 0) {
        this.$confirm("请选中要下载的官文序号", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      } else {

        this.down = false
        try {
          const res = await batchDownload(this.id)
          if (res && res.err_code === 0) {
            window.open(res.data.url)
            this.down = true
          }
        } catch (error) {
          console.log("错误", error)
        } finally {
          this.down = true
        }

      }

    },
    //预览pdf文件
    open(url) {
      let name = this.detail.tmcode + '-' + this.detail.intcls.join('、') + '-' + (this.detail.tm_name ? this.detail.tm_name : '图形')  + '-' + url.matter + '.' + url.name.split('.')[1]
      location.href = url.url.replace(url.name,name)
    },


    //官文删除事件
    deleteFile(item) {
      this.deleteId.id = item
      try {

        this.$confirm('确定要删除该官文吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          await managementDelete(this.deleteId).then(res => {
            if (res && res.err_code === 0) {
              this.$message({
                type: 'success',
                message: "官文已删除！",
              })
              this.domesticDetails()
            } else {
              this.$message({
                type: 'error',
                message: res.err_msg,
              });
              this.domesticDetails()
            }
          })
        })
      } finally {

      }
    },

    upload() {
      this.fileSH = true
      this.$store.commit('setscroll', {
        scroll: this.fileSH,
      });
    },
    //文件上传时事件
    beforeUpload() {
      this.uploadBtn = false
    },
    //文件上传数量超过1个提示事件
    handleExceed(files, fileList) {
      this.$message.warning(
          `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`
      );
    },

    //文件上传后事件(自动上传)
    async uploadImg(item) {
      let formData = new FormData();
      formData.append("file", item.file);
      formData.append("is_base64", 0);
      try {
        const res = await fileUpload(formData)
        if (res && res.err_code === 0) {
          this.uploadBtn = true
          this.file.name = this.automatic.name = this.manual.name = res.data.name;
          this.file.path = res.data.path;
          this.$refs.upload.clearFiles()
        } else {
          this.$message({
            type: "warning",
            message: res.err_msg,
          })
        }
      } finally {
        this.uploadBtn = true;
      }
    },

    //识别按钮点击事件
    distinguishBtn(automatic) {
      this.$refs[automatic].validate(async (valid) => {
        try {
          if (this.automatic.name != '') {
            this.distinguishDis = true
            this.contSH = true;
            this.loadingUp = true
            const res = await fileDistinguish({...this.filters, ...this.file})
            if (res && res.err_code === 0) {
              this.distinguishDis = false
              this.loadingUp = false
              this.automatic.made_at = res.data.made_at;
              this.distinguishSh = false;
              if (this.typeSelect.indexOf(res.data.matter) == -1) {
                this.automatic.matter = res.data.matter;
              } else {
                this.$message({
                  type: "warning",
                  message: '上传文件的官文类型不存在，请选择成已有的官文类型',
                })
                this.automatic.matter = ''
              }
            } else {
              this.$message({
                message: res.err_msg,
                type: 'warning'
              })
              this.contSH = false
              this.fileList = []
              this.distinguishDis = false
              this.distinguishSh = true
            }
          }
        } catch (e) {
          this.contSH = false
          this.fileList = []
          this.distinguishDis = false
          this.distinguishSh = true
        }

      })
    },
    //上传官文提交事件
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.distinguishDis = this.submitDis = true
            const res = await fileSubmit({...this.file, ...this.manual, ...this.filters})
            if (res && res.err_code === 0) {
              this.$refs[formName].resetFields();
              this.success()
            } else {
              this.$message({
                type: "warning",
                message: res.err_msg,
              })
              this.fileList = []
              this.distinguishDis = this.submitDis = false
            }
          } catch (e) {
            this.fileList = []
            this.distinguishDis = this.submitDis = false
          }
        } else {
          return false;
        }
      })


    },
    submitAutomatic(automatic) {
      this.$refs[automatic].validate(async (valid) => {
        if (valid) {
          try {
            this.distinguishDis = this.submitDis = true
            const res = await fileSubmit({...this.file, ...this.automatic, ...this.filters})
            if (res && res.err_code === 0) {
              this.success()
            } else {
              this.$message({
                type: "warning",
                message: res.err_msg,
              })
              this.distinguishDis = this.submitDis = false
            }
          } catch (e) {
            this.fileList = []
            this.distinguishDis = this.submitDis = false
          }
        } else {
          return false
        }
      })


    },
    //上传官文提交成功事件
    success() {
      this.$message({
        type: "success",
        message: "提交成功!",
      });
      this.fileSH = false;
      this.$store.commit('setscroll', {
        scroll: this.fileSH,
      });
      this.manual = this.$options.data().manual; //清空表单
      this.automatic = this.$options.data().automatic; //清空表单
      this.distinguishSh = true;
      this.contSH = false;
      this.tabtNum = 1
      this.fileList = []
      this.file.name = ''
      this.distinguishDis = this.submitDis = false
      this.domesticDetails()
    },
    //上传官文取消事件
    empty() {
      this.fileSH = false;
      this.$store.commit('setscroll', {
        scroll: this.fileSH,
      });
      this.$refs.ruleForm.resetFields()
      // this.$refs.automatic.resetFields()
      this.tabtNum = 1
      this.distinguishSh = true;
      this.contSH = false;
      this.$refs.upload.clearFiles()
      this.file = this.$options.data().file; //清空表单
    },


  },
};
</script>

<style lang="less" scoped>

.detail_title {
  p {
    color: #00244A;
  }

  ::v-deep .el-button {
    i {
      font-family: "iconfont" !important;
    }
  }
}

.r-country {
  ::v-deep .country_list {
    .el-table__expanded-cell {
      padding: 0;
      background: #F4F4F4;
    }

    .el-table {

      margin: auto;

      th, tr {
        background: #F4F4F4;
      }
    }

  }

  .el-table {
    transition: all 1s;
  }

  .el-table.on {
    height: 0 !important;
  }
}

::v-deep .el-tabs {
  .list_t {
    padding: 9px 10px;
  }

  .el-tabs__header {
    margin: 0;

    .el-tabs__nav-wrap {
      padding: 0 10px;

      .el-tabs__item {
        font-size: 16px;
        height: 50px;
        line-height: 50px;
      }
    }

  }

  .el-table {
    .el-button--small {
      padding: 9px 15px !important;
    }

    .el-image {
      width: 70px;
      height: 70px;
      border: 1px solid #DDD;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      img {
        max-height: 68px;
        max-width: 68px;
        min-width: unset;
        width: unset;
        height: unset;
      }
    }
  }
}
.other{
  table{
    border-top: 1px solid #EBEEF5;
    width: 100%;
    tr{

      td{
        border-bottom: 1px solid #EBEEF5;
        word-break: break-all;
        div:nth-of-type(2){
          border-left: 1px solid #EBEEF5;

        }
        div{
          padding: 10px 20px;
        }
      }
    }

    .name{
      padding: 10px 20px;
      background: #F5F7FA;
    }
  }
}
.tmExtraFlow{
  table{
    border-top: 1px solid #EBEEF5;
    td{
      border-bottom: 1px solid #EBEEF5;
    table{
      border: none;
      td,th{
        padding: 10px 20px;
        word-break: break-all;
        border-right: 1px solid #EBEEF5;
      }
      tr{
        td:nth-last-of-type(1),th:nth-last-of-type(1){
          border-right: none;
        }
      }
      tr:nth-last-of-type(1){
        td{
          border-bottom: none;
        }
      }
      th{
        border-bottom: 1px solid #EBEEF5;
        text-align: left;
      }

    }
    }
    .name{
      padding: 10px 20px;
      background: #F5F7FA;
      padding: 10px 20px;
    }
  }

}

</style>
