<template>
  <div class="">
    <h2>账单信息<span v-if="applyList.length != 0">{{ pageSize }} </span></h2>
    <form ref="filters" v-on:submit.prevent>
      <el-row :class="{on:formOpen}" :gutter="10" class="formInput">
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row :gutter="10" justify="space-between" align="middle" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>申请人</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-select
                  v-model="filters.appname"
                  clearable
                  filterable
                  placeholder="请选择申请人"
                  size="small"
              >
                <el-option
                    v-for="item in appnameSelect"
                    :key="item.id + 80"
                    :label="item.text"
                    :value="item.text"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row :gutter="10" justify="space-between" align="middle" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>商标号</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-input v-model="filters.tmcode" clearable placeholder="请填写商标号" size="small"></el-input>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row :gutter="10" justify="space-between" align="middle" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>付款状态</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-select
                  v-model="filters.money_status"
                  clearable
                  filterable
                  placeholder="请选择付款状态"
                  size="small"
              >
                <el-option
                    v-for="item in moneySelect"
                    :key="item.id + 80"
                    :label="item.text"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row :gutter="10" justify="space-between" align="middle" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>开票状态</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-select
                  v-model="filters.invoice_status"
                  clearable
                  filterable
                  placeholder="请选择开票状态"
                  size="small"
              >
                <el-option
                    v-for="item in invoiceSelect"
                    :key="item.id + 80"
                    :label="item.text"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row :gutter="10" justify="space-between" align="middle" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>付款方式</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-select
                  v-model="filters.channel"
                  clearable
                  filterable
                  placeholder="请选择付款方式"
                  size="small"
              >
                <el-option
                    v-for="item in channelSelect"
                    :key="item.id + 80"
                    :label="item.text"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :lg="7" :md="7" :sm="10" :xl="7" :xs="20">
          <el-row :gutter="10" justify="space-between" align="middle" type="flex">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>付款时间</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="17" :xl="20" :xs="19">
              <el-date-picker
                  v-model="filters.money_at"
                  placeholder="选择日期"
                  size="small"
                  type="date">
              </el-date-picker>

            </el-col>
          </el-row>
        </el-col>

      </el-row>
      <el-row class="formOpen">
        <el-col :class="{on:formOpen}" :span="24">
          <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
        </el-col>
      </el-row>
      <el-button class="search" icon="el-icon-search" size="small" type="primary"
                 @click="perindexList(filters.page = 1)"
      >查询
      </el-button>
      <el-button :class="{on:formOpen}" class="reset" icon="el-icon-refresh-left" size="small" type="reset"
                 @click="reset"
      >重置
      </el-button
      >
    </form>
    <div class="mainrb">
      <div class="listT p10">
        <el-button :loading="down" icon="el-icon-document" size="small" :disabled="applyList.length == 0 || loading" type="primary" @click="diaClick">
          {{ down ? '导出中' : '导出数据' }}
        </el-button>

      </div>
      <el-skeleton :count="1" :loading="loading" animated>
        <template slot="template">
          <el-table
              :class="{on:!formOpen,ios:$store.state.heights}"
              :data="applyList"
              style="width: 100%">

            <el-table-column
                label="序号">
              <template>
                <el-skeleton-item variant="p" />
              </template>
            </el-table-column>
            <el-table-column
                label="申请人">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="申请事宜" width="180">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="金额">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="付款状态 / 开票状态" width="180">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="付款方式">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="付款时间">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="创建时间">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column
                label="操作">
              <template slot-scope="scope">
                <template>
                  <el-skeleton-item variant="p" class="w50"/>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-table
            :class="{on:!formOpen,ios:$store.state.heights}"
            :data="applyList"
            height="100%"
            style="width: 100%">
          <template slot="empty">
            <el-empty :image-size="80"></el-empty>
          </template>

          <el-table-column
              label="序号" type="index">
          </el-table-column>
          <el-table-column
              label="申请人"
              min-width="120"
              :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <router-link v-if="scope.row.client_id != 0" class="ellipsis" :to="{
                  name: 'newlyBuild',
                    params: { id:  scope.row.client_id  },
                }" target="_blank">
                {{ scope.row.appname }}
              </router-link>
              <template v-else>
                {{ scope.row.appname }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
              label="申请事宜"  min-width="180" :show-overflow-tooltip="true" prop="apps">

          </el-table-column>

          <el-table-column
              label="金额">
            <template slot-scope="scope">
              ￥{{ scope.row.subtotal }}
            </template>
          </el-table-column>
          <el-table-column
              label="付款状态 / 开票状态" width="180">
            <template slot-scope="scope">
              <span class="money_status">
                 <el-tag :type="scope.row.money_status == 0  ? 'danger' : (scope.row.money_status == 1 ? '' : 'warning')"
                         size="mini">
                  {{ (scope.row.money_status == 0) ? '未付款' : (scope.row.money_status == 1 ? '已付款' : '部分付款') }}
              </el-tag>
              </span>

              <el-tag :type="scope.row.invoice_status == 0  ? 'warning' : 'success'" size="mini">
                {{ (scope.row.invoice_status == 0) ? '未开票' : '已开票' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
              label="付款方式"
              prop="subtotal">
            <template slot-scope="scope">
              {{ scope.row.money_status != 1 ? '-' : (scope.row.channel == 0 ? '线下汇款' : (scope.row.channel == 1 ? '微信扫码' : '支付宝扫码')) }}
            </template>
          </el-table-column>

          <el-table-column
              label="付款时间"
              width="110" prop="money_at">
            <template slot-scope="scope">
              {{ scope.row.money_status == 1 ? (scope.row.money_at) : '-' }}
            </template>
          </el-table-column>
          <el-table-column
              label="创建时间"
              prop="created_at" width="110">
          </el-table-column>
          <el-table-column
              fixed="right" label="操作">
            <template slot-scope="scope">
              <div class="">
                <router-link :to="{
                         name: 'billDetail',
            params: { id: scope.row.id},
            }" class=" disblk" target="_blank">
                  <el-button icon="el-icon-edit-outline" size="small" type="text">详情</el-button>
                </router-link>
                <!--                <el-button size="small"  v-if="scope.row.money_status != 1" type="success" @click="command(scope.row.id)">扫码支付</el-button>-->
              </div>

            </template>
          </el-table-column>
        </el-table>
      </el-skeleton>
      <!-- 分页 -->
      <el-pagination
          :current-page.sync="filters.page"
          :page-size="filters.per_page"
          :page-sizes="[30, 50, 100]"
          :total="pageSize"
          layout="total, sizes, prev, pager, next, jumper,slot"
          @size-change="changeRowNum"
          @current-change="perindexList()"
      >
      </el-pagination>
    </div>

    <el-dialog
        :closeOnClickModal="false"
        :show-close="false"
        :visible.sync="commandDia"
        class="commandDia"
        width="810px">
      <div slot="title" class="">
        <i class="el-icon-error fr pointer" @click="commandDia = false"></i>
      </div>
      <div class="flex flex-cen">
        <div class="">
          <p class="mb20">您的支付订单已生成，请尽快付款！支付订单号：<span class="col-orange">{{ commandItem.pay_number }}</span></p>
          <p>请您在 <span class="col-orange">{{ commandItem.deadline }}</span> 前完成支付，否则需重新发起支付！</p>
        </div>
        <div class="">
          订单金额：<span class="col-orange">￥<font>{{ commandItem.amount }}</font> </span>
        </div>
      </div>
      <div class="flex align-cen flex-evenly">
        <div class="">
          <img :src="commandItem.qr_code" alt="" class="mb20">
          <div :class="{on:commandTxt == '微信'}" class="flex flex-cen">
            <img alt="" src="@/assets/img/personal/icon-sys.png">
            <div class="">
              <p class="mb10">请使用{{ commandTxt }}扫一扫</p>
              <p>扫描二维码支付</p>
            </div>
          </div>
        </div>
        <div class="flex align-cen">
          <img v-if="commandTxt == '支付宝'" alt="" src="@/assets/img/personal/zfbsys.png">
          <img v-else alt="" src="@/assets/img/personal/wxsys.png">
        </div>
      </div>

    </el-dialog>

    <el-dialog
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        :visible.sync="downloadDia"
        title="导出信息"
        width="576px"
        class="downloadDia openDia"
        @open="openDia">
      <template>
        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="filters.fields" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="city in cities" :key="city" :label="city">{{ city }}</el-checkbox>
        </el-checkbox-group>
      </template>
      <span slot="footer" class="dialog-footer">
    <el-button icon="el-icon-close" size="small" @click="downloadDia = false">取 消</el-button>
    <el-button :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' : 'el-icon-folder-checked'" class="confirm"
               size="small" type="primary" @click="download">确 定</el-button>
  </span>
    </el-dialog>

    <el-dialog
        class="editionDia"
        title="提示"
        :visible.sync="editionDia"
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        width="390px">
      <div class="text-cen flex align-cen"><i class="el-icon-warning m-r10"></i> 当前账号没有此操作权限，请升级至<span
          class="font-wbold col-red ">“企业版”</span>！
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" size="small" @click="editionDia = false">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import {applyBills, exportBills, qrCode} from '@/api/personal.js'

const cityOptions = ['申请人', '申请事宜', '金额', '付款状态', '开票状态', '付款方式', '付款时间', '创建时间'];
export default {
  name: "Bill",
  data() {
    return {
      down: false,//控制导出加载的显示与隐藏
      downloadDia: false,//控制导出的对话框
      checkAll: false,
      cities: cityOptions,
      isIndeterminate: true,
      submitDis: false,
      editionDia:false,

      formOpen: false,
      loading: true,//控制列表的骨架屏
      applyList: [],
      pickerOptions: {//日期选择器的便利选择对象
        shortcuts: storage.getShortcuts()
      },
      filters: {
        tmcode:'',
        app: '',//申请事宜
        appname: '',//申请人
        money_status: '',//付款状态
        invoice_status: '',//开票状态
        channel: '',//付款方式
        money_at: '',//付款时间
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
        checkList: [],//记录满三年和可续展选中时的数组
        fields: cityOptions,
      },
      moneySelect: [{
        id: 0,
        text: '未付款'
      }, {
        id: 1,
        text: '已付款'
      }, {
        id: 2,
        text: '部分付款'
      }],
      invoiceSelect: [
        {
          id: 0,
          text: '未开票'
        },
        {
          id: 1,
          text: '已开票'
        },
      ],
      channelSelect: [{
        id: 0,
        text: '线下汇款'
      }, {
        id: 1,
        text: '微信扫码'
      }, {
        id: 2,
        text: '支付宝扫码'
      }],

      appnameSelect: [],
      commandTxt: '付款',

      commandDia: false,
      commandItem: {},
      last_page: 1,
      pageSize: 0, //总条数
    }
  },
  created() {
    this.perindexList()
    this.select()
  },
  methods: {
    handleCheckAllChange(val) {
      this.filters.fields = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    diaClick(){
      if(storage.getUser().type != 0){
        this.downloadDia = true
      }else{
        this.editionDia = true
      }

    },
    //导出事件
    openDia() {
      this.filters.fields = this.$options.data().filters.fields;
      this.checkAll = false
      this.isIndeterminate = true
    },
    async download() {
      if (storage.getUser().type == 0) {
        this.downloadDia = false
        this.$message({
          message: '当然账号没有此操作权限，请升级至“企业版”！',
          type: 'warning'
        });
      } else {
        this.down = true
        this.downloadDia = false
        if (this.filters.fields.length != 0) {
          try {
            const res = await exportBills( {
              app: this.filters.app,
              appname: this.filters.appname,
              money_status: this.filters.money_status == '' ? 0 : this.filters.money_status,
              invoice_status: (this.filters.invoice_status == '') ? 0 : this.filters.invoice_status,
              channel: this.filters.channel,
              money_at: this.filters.money_at,
              page: this.filters.page,
              fields: this.filters.fields,
              per_page: this.filters.per_page
            })
            if(res && res.err_code === 0){
              location.href = res.data.url
              this.down = false
            }else{
              this.$message({
                message: res.err_msg,
                type: 'warning'
              });
            }

          } finally {
            this.down = false
          }
        } else {
          this.$message({
            message: '至少选择一个',
            type: 'warning'
          });
        }
      }
    },

    async command(item) {
      try {
        const res = await qrCode({id: item})
        if (res && res.err_code === 0) {
          console.log(res)
          this.commandDia = true
          res.data.qr_code = 'data:image/png;base64,' + res.data.qr_code
          this.commandItem = res.data

        }else{
          this.$message({
            message: res.err_msg,
            type: 'warning'
          });
        }

      } finally {

      }

    },
    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.perindexList();
    },
    // 下拉
    async select() {
      let s = await storage.getSelects('app_names')
      this.appnameSelect = s.app_names
    },
    //渲染列表事件
    async perindexList() {

      this.loading = true;
      this.applyList = storage.getList()
      try {
        const res = await applyBills(
            {
              app: this.filters.app,
              appname: this.filters.appname,
              money_status: this.filters.money_status == '' ? 0 : this.filters.money_status,
              invoice_status: (this.filters.invoice_status == '') ? 0 : this.filters.invoice_status,
              channel: this.filters.channel,
              money_at: this.filters.money_at,
              page: this.filters.page,
              per_page: this.filters.per_page,
              tmcode: this.filters.tmcode,
            })
        if (res && res.err_code === 0) {
          this.loading = false
          this.applyList = res.data.list
          this.pageSize = res.data.total
        } else {
          this.applyList = []
        }
      } finally {
        this.loading = false
      }
    },

    reset() {
      this.filters = this.$options.data().filters;
      this.perindexList(this.filters.page = 1)
    },

  }
}
</script>

<style lang="less" scoped>
@import "../../style/apply.less";
::v-deep .el-table{
  .el-button{
    padding: 4px 15px;
  }
}
.money_status {
  display: inline-block;
  border-right: 1px solid #ddd;
  padding-right: 10px;
  margin-right: 10px;
}


.el-dropdown-menu {
  width: 73px;
  padding: 0;

  .el-dropdown-menu__item {
    padding: 0;
  }
}

::v-deep .commandDia {
  .el-dialog__header {
    i {
      color: #3894FF;
      font-size: 30px;
    }
  }

  .el-dialog__body {

    font {
      font-size: 26px;
    }

    .flex-evenly {
      margin-top: 60px;

      img {
        width: 100%;
      }
    }

    .flex-evenly > div:nth-of-type(1) {
      width: 280px;

      .flex-cen {
        width: 100%;
        padding: 10px 30px;
        background: #01ADEF;

        img {
          width: 50px;
        }

        p {
          text-align: center;
          color: #fff;
          font-size: 16px;
        }
      }

      .flex-cen.on {
        background: #3DB035;
      }
    }

    .flex-evenly > div:nth-of-type(2) {
      width: 210px;
    }
  }
}

@media screen and (max-width: 1900px) {

  .el-table.on {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 392px") !important;
    }
  }

  .el-table.on.ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 432px") !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .el-dropdown-menu {
    right: 10px;
  }

  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 560px") !important;
  }

  .ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 600px") !important;
    }
  }

  .formInput.on {
    height: 210px;
  }
}

@media screen and (min-width: 768px) {
  .el-dropdown-menu {
    right: 10px;
  }

  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 476px") !important;
  }

  .ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 517px") !important;
    }
  }

  .formInput.on {
    height: 126px;
  }
}

@media screen and (min-width: 992px) {
  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 424px") !important;
  }

  .ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 464px") !important;
    }
  }

  .el-table.on {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 392px") !important;
    }
  }

  .el-table.on.ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 432px") !important;
    }
  }

  .formInput.on {
    height: 74px;
  }
}

@media screen and (min-width: 1200px) {
  .el-dropdown-menu {
    right: 30px;
  }
}

@media screen and (min-width: 1920px) {
  .el-dropdown-menu {
    right: 3%;
  }


  form {

    .el-col-lg-7 {
      width: 29.16667%;
    }

  }
}

</style>



