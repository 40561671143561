<template>
  <div class="p-b40 ">
    <el-skeleton :loading="loading" animated>
      <template slot="template">
        <h2>待办事项详情</h2>
        <div class="ba-col-fff p-20-30">
          <el-row :gutter="20" align="middle" justify="space-between" type="flex" class="m-b15">
            <el-col :span="8"><span class="col-0d9">需求类型：</span><el-skeleton-item variant="text" class="w50"/></el-col>
            <el-col :span="8"><span class="col-0d9" >申请人：</span><el-skeleton-item variant="text" class="w50"/></el-col>
            <el-col :span="8"><span class="col-0d9">知产顾问：</span><el-skeleton-item variant="text" class="w50"/></el-col>
          </el-row>
          <el-row :gutter="20" align="middle" justify="space-between" type="flex" class="m-b15">
            <el-col :span="8"><span class="col-0d9">需求标题：</span><el-skeleton-item variant="text" class="w50"/></el-col>
            <el-col :span="16"><span class="col-0d9">截止日期：</span><el-skeleton-item variant="text" class="w50"/></el-col>
          </el-row>
          <div class="">
            <span class="col-0d9 disinblk">需求描述：</span><el-skeleton-item variant="text" class="w50"/>
          </div>
          <div class="m-tb15 col-0d9">附件</div>
          <el-table
              :data="loadList"
              border
              max-height="285"
          >
            <el-table-column
                label="#"
                type="index"
                width="50">
            </el-table-column>
            <el-table-column
                label="事项">
              <template slot-scope="scope">
                <el-skeleton-item variant="p" />

              </template>
            </el-table-column>
            <el-table-column
                label="文件名称" >
              <template slot-scope="scope">
                <el-skeleton-item variant="p" />
              </template>
            </el-table-column>
            <el-table-column
                label="到期时间">
              <template slot-scope="scope">
                <el-skeleton-item variant="p" />
              </template>
            </el-table-column>
            <el-table-column
                label="上传时间">
              <template slot-scope="scope">
                <el-skeleton-item variant="p" />
              </template>
            </el-table-column>

          </el-table>
          <div class=" col-0d9 m-tb15" >相关商标：</div>
          <div  class="pos-rel trademarks" >
            <div  class="relevant m-b10 flex flex-warp flex-cen font-12 ">
              <div v-for="(item,index) in loadList" class=" p10 m-b10 flex flex-cen">
                <el-skeleton-item
                    style="width: 69px;height:69px;"
                    variant="image"
                />
                <div class="w80">
                  <div class="flex flex-cen m-b5">
                    <div class="w50">
                      <p class="ellipse"><el-skeleton-item variant="text" class="w50"/></p>
                      <p class="blue"><el-skeleton-item variant="text" class="w50"/></p>
                    </div>
                    <div class="w45">

                      <p  class="ellipse w90"><el-skeleton-item variant="text" class="w50"/></p>
                      <p class="blue"><el-skeleton-item variant="text" class="w50"/></p>
                    </div>
                  </div>
                  <div class="goods col-888">
                    <el-skeleton-item variant="text" class="w50"/>
                  </div>
                  <div class="goods col-888">
                    <el-skeleton-item variant="text" class="w50"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div  class="ba-col-fff m-tb10 record">
          <h2>处理记录</h2>
          <div class="">
            <div class="" >
              <div class="" >
                <div class="record-cont " ref="recordUpRef" >
                  <div v-for="(item,index) in loadList" class="border1 bor-rad5 m-b10 p10 pos-rel">
                    <div class="m-b10">
                      <p class="blue"><span class="m-r10"><el-skeleton-item variant="text" class="w50"/></span> <span><el-skeleton-item variant="text" class="w50"/></span></p>
                      <p class=""><el-skeleton-item variant="text" class="w50"/></p>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
        <div  class="ba-col-fff matters" >
          <h2 >申请事宜</h2>
          <div class="" >
            <div class="flex flex-cen list-t" >
              <h3 class="pointer" @click="trademark = !trademark"><i
                  :class="{  'el-icon-arrow-up' : trademark, 'el-icon-arrow-down':!trademark}"></i>商标事宜
                <span>{{ tms.length }}</span>
              </h3>
              <el-button :disabled="tms.length == 0" :loading="down && (type == 'lawsuit')" icon="el-icon-document"
                         size="small" type="primary" @click="diaClick('lawsuit')">
                {{ down ? '导出中' : '导出数据' }}
              </el-button>
            </div>
            <el-table
                ref="multipleTable"
                :data="loadList"
                max-height="500"
                tooltip-effect="dark">
              <template slot="empty">
                <el-empty :image-size="80"></el-empty>
              </template>
              <el-table-column label="#" type="index" width="50">
              </el-table-column>
              <el-table-column label="商标号" >
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>

              </el-table-column>
              <el-table-column label="商标名称">
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
              <el-table-column label="商标类别" prop="">
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
              <el-table-column label="申请类型" prop="">
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
              <el-table-column label="金额">
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
              <el-table-column label="报送日期" >
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
            </el-table>

          </div>
          <div  class="">
            <div class="list-t flex flex-cen">
              <h3 class="pointer" @click="copyright = !copyright"><i
                  :class="{  'el-icon-arrow-up' : copyright, 'el-icon-arrow-down':!copyright}"></i>版权事宜
                <span>{{ copyrights.length }}</span></h3>
              <el-button :disabled="copyrights.length == 0" :loading="down && (type == 'copyright')"
                         icon="el-icon-document" size="small" type="primary" @click="diaClick('copyright')">
                {{ down ? '导出中' : '导出数据' }}
              </el-button>
            </div>
            <el-table
                ref="multipleTable"
                :data="loadList"
                height="100%"
                tooltip-effect="dark"
            >
              <template slot="empty">
                <el-empty :image-size="80"></el-empty>
              </template>
              <el-table-column label="#" type="index" width="50">
              </el-table-column>
              <el-table-column label="登记号">
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
              <el-table-column label="作品名称">
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
              <el-table-column label="作品类型" prop="">
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
              <el-table-column label="申请类型" prop="">
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
              <el-table-column label="金额">
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
              <el-table-column label="报送日期" prop="">
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div  class="">
            <div class="list-t flex flex-cen">
              <h3 class="pointer" @click="lawsuit = !lawsuit"><i
                  :class="{  'el-icon-arrow-up' : lawsuit, 'el-icon-arrow-down':!lawsuit}"></i>诉讼事宜
                <span>{{ lawsuits.length }}</span></h3>
              <el-button :disabled="lawsuits.length == 0" :loading="down && (type == 'lawsuit')" icon="el-icon-document"
                         size="small" type="primary" @click="diaClick('lawsuit')">
                {{ down ? '导出中' : '导出数据' }}
              </el-button>
            </div>

            <el-table
                ref="multipleTable"
                :data="loadList"
                class="mb20"
                height="100%"
                tooltip-effect="dark"
            >
              <template slot="empty">
                <el-empty :image-size="80"></el-empty>
              </template>
              <el-table-column label="#" type="index" width="50">
              </el-table-column>
              <el-table-column label="商标号">
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
              <el-table-column label="商标名称">
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
              <el-table-column label="商标类别" prop="">
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
              <el-table-column label="申请类型" prop="">
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
              <el-table-column label="原告/上诉人" prop="">
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
              <el-table-column label="金额">
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
              <el-table-column label="创建时间" prop="">
                <template slot-scope="scope">
                  <el-skeleton-item variant="text" class="w50"/>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </template>
      <template>
        <div class="" v-if="edit">
          <div class="h2 clearfix ">
            <span class="font-22 font-wbold">待办事项详情</span>
            <div class="fr m-r15" >
              <el-button v-if="filters.state != 1" size="mini" :type="filters.state == 3 ? 'success' : 'info'" :icon="filters.state == 3 ?'el-icon-check' : 'el-icon-close' ">
                {{ filters.state == 3 ? '已完结' : '已关闭' }}
              </el-button>
              <el-button v-if="filters.state == 2 && (filters.member_id == user.id || user.parent_id == 0)" @click="deletes(filters.id)" size="mini" type="danger" icon="el-icon-delete">删除待办</el-button>
            </div>
          </div>


          <div class="ba-col-fff  p-20-30">
            <el-row :gutter="20" align="middle" justify="space-between" type="flex" class="m-b15">
              <el-col :span="8"><span class="col-0d9">需求类型：</span><span class="col-0a6" v-for="item in types.types">{{item}}&nbsp;</span></el-col>
              <el-col :span="8"><span class="col-0d9" >申请人：</span><span class="col-0a6">{{client_id}}</span></el-col>
              <el-col :span="8"><span class="col-0d9">知产顾问：</span><span class="col-0a6">{{agent_id}}</span></el-col>
            </el-row>
            <el-row :gutter="20" align="middle" justify="space-between" type="flex" class="m-b15">
              <el-col :span="8"><span class="col-0d9">需求标题：</span><span class="col-0a6">{{filters.name}}</span></el-col>
              <el-col :span="16"><span class="col-0d9">截止日期：</span><span class="col-0a6">{{filters.deadline}}</span></el-col>
            </el-row>
            <div class="">
              <span class="col-0d9 disinblk">需求描述：</span><span class="col-0a6 disinblk" v-html="filters.summary"></span>
            </div>

            <div class="m-tb15 col-0d9">附件：<span class="col-0a6" v-if="attachments.length == 0">-</span> </div>
            <el-table
                v-if="attachments.length != 0"
                :data="filters.attachments"
                border
                max-height="285"
            >
              <el-table-column
                  label="#"
                  type="index"
                  width="50">
              </el-table-column>
              <el-table-column
                  label="事项">
                <template slot-scope="scope">
                  <p class="ellipse">{{ scope.row.matter || '-' }}</p>

                </template>
              </el-table-column>
              <el-table-column
                  label="文件名称" >
                <template slot-scope="scope">
                  <a v-if="scope.row.url" :href="`${scope.row.url}&preview=true`" class="blue ellipse w100" :title="scope.row.filename"
                     target="_blank">
                    {{ scope.row.filename || '-'}}
                  </a>
                  <template v-else>
                    <p class="ellipse" :title="scope.row.filename">{{ scope.row.filename || '-' }}</p>

                  </template>
                </template>
              </el-table-column>
              <el-table-column
                  label="到期时间">
                <template slot-scope="scope">
                  {{ scope.row.expire_at || '-' }}
                </template>
              </el-table-column>
              <el-table-column
                  label="上传时间">
                <template slot-scope="scope">
                  {{ scope.row.created_at || '-' }}
                </template>
              </el-table-column>

            </el-table>
            <div class=" col-0d9 m-tb15" :class="{'m-tb15':filters.state == 1}">相关商标：<span class="col-0a6" v-if="trademarks.trademarks.length == 0">-</span></div>
            <div  class="pos-rel trademarks" v-if="trademarks.trademarks.length != 0">
              <div :class="{on:relevantOpen}" class="relevant m-b10 flex flex-warp flex-cen font-12 ">
                <div v-for="(item,index) in trademarks.trademarks" class="pos-rel p10 m-b10 ">
                  <el-image
                      :preview-src-list="[item.tm_picture]"
                      :src="item.tm_picture">
                  </el-image>
                  <div class="">
                    <div class="flex flex-cen m-b5">
                      <div class="w50">
                        <p class="ellipse">{{ item.tm_name || '图形' }}</p>
                        <p class="blue">第{{ item.tm_class }}类</p>
                      </div>
                      <div class="w45">

                        <p  class="ellipse w90">{{ item.client_name }}</p>
                        <p class="blue">{{ item.tm_number }}</p>
                      </div>
                    </div>
                    <div class="goods col-888">
                      {{ item.tm_goods }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-cen relevantOpen pos-abs w100"  v-if="(trademarks.trademarks.length >= 10 && bodyWidth > 992) || (trademarks.trademarks.length > 5 && bodyWidth < 992)">
                <i class="font-16 font-wbold pointer" :class="relevantOpen ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" @click="relevantOpen = !relevantOpen"></i>
              </div>
            </div>
            <div class="m-t30 text-cen"  v-if="filters.state == 1">
              <el-button v-if="filters.member_id != 0" size="mini" type="primary" icon="el-icon-edit" @click="edit = !edit">编辑</el-button>
              <el-button  icon="el-icon-document-checked" :loading="endLoad" size="mini" type="success" @click="endDia = true">
                {{ endLoad ? '完结中' : '完结' }}
              </el-button>

            </div>
          </div>

        </div>
        <div class="" v-else>
          <div class="edit ba-col-fff flex flex-cen">
            <span class="font-22 font-wbold">待办事项详情</span>

          </div>
          <el-form ref="ruleForm" :model="filters" :rules="rules" class="demo-ruleForm">
            <el-row :gutter="20" align="middle" justify="space-between" type="flex">
              <el-col :span="8">
                <el-form-item>
                  <div class="col-606">
                    <span class="col-red">*</span>
                    需求类型
                  </div>
                  <el-checkbox-group v-model="types.types" size="small" >
                    <el-checkbox label="商标" ></el-checkbox>
                    <el-checkbox label="版权" ></el-checkbox>
                    <el-checkbox label="法律" ></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="申请人" prop="client_id">
                  <el-select v-model="client_id" clearable placeholder="请选择申请人"   size="small" @change="filters.client_id = client_id">
                    <el-option v-for="item in appnameSelect" :label="item.text" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="知产顾问" prop="agent_id">
                  <el-select v-model="agent_id" clearable disabled placeholder="请选择知产顾问" size="small" >
                    <el-option v-for="item in agentSelect" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" align="middle" justify="space-between" type="flex">
              <el-col :span="12">
                <el-form-item label="需求标题" prop="name">
                  <el-input v-model="filters.name" clearable placeholder="如：评估xx商标在xx类注册几率" size="small"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="截止日期" prop="deadline">

                  <el-date-picker

                      v-model="filters.deadline"
                      clearable
                      placeholder="选择日期"
                      type="date"
                      size="small"
                      value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>


            <el-form-item label="需求描述" prop="summary">
              <el-input v-model="filters.summary"  clearable placeholder="如：xx商标在xx类是否有近似商标，注册成功率如何？" size="small" type="textarea"></el-input>
            </el-form-item>

            <div class="m-tb15">
              <p class="line-hei40"><span class="col-606">附件</span> <span class="col-red m-l5 font-12" >(单个文件大小不超过20M，可上传多个)</span></p>
              <el-form-item v-if="(filters.state == 1 || add == false)" class="">
                <button v-if="uploadLoad " class="el-button el-button--primary el-button--small m-b20" :class="{'is-disabled':filters.state != 1 && add}" :disabled="filters.state != 1 && add" data-v-5f0a7fba=""
                        type="button"
                        @click="upload ">
                  <input ref="clearFile" accept="*" class="disnone" multiple="multiplt"
                         style="margin-left:70px;" type="file" @change="getFile($event)">
                  <i class="el-icon-folder-opened"></i>
                  <span>选择文件</span>
                </button>
                <el-button v-else class="m-b20" loading size="small" type="primary">上传中</el-button>
              </el-form-item>

              <el-table
                  v-if="filters.attachments.length != 0 "
                  :data="filters.attachments"
                  border
                  max-height="285"
              >
                <el-table-column
                    label="#"
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column
                    label="事项">
                  <template slot-scope="scope">
                    {{ scope.row.matter || '-' }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="文件名称">
                  <template slot-scope="scope">
                    <a v-if="scope.row.url" :href="`${scope.row.url}&preview=true`" class="blue ellipse w100" :title="scope.row.filename"
                       target="_blank">
                      {{ scope.row.filename || '-'}}
                    </a>
                    <template v-else>
                      <p :title="scope.row.filename">{{ scope.row.filename || '-' }}</p>

                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                    label="到期时间">
                  <template slot-scope="scope">
                    {{ scope.row.expire_at || '-' }}
                  </template>
                </el-table-column>
                <el-table-column
                    label="上传时间">
                  <template slot-scope="scope">
                    {{ scope.row.created_at || '-' }}
                  </template>
                </el-table-column>
                <el-table-column

                    label="操作" fixed="right">
                  <template slot-scope="scope">
                    <el-button size="mini" type="danger" :disabled="filters.state != 1 && add"
                               @click.native.prevent="deleteAttachments(scope.$index, filters.attachments)">删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="">
              <p class="col-606 line-hei40">相关商标</p>
              <div class="flex align-cen assTms m-b20" v-if="(filters.state == 1 || add == false)">
                <el-form-item class="w45">
                  <el-input v-model="filters.tmcodes" :class="{on:tmsLoadRule && !filters.tmcodes}"  clearable placeholder="请填写商标号，多个商标以“，”相隔" size="small"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button :loading="tmsLoad " icon="el-icon-search" size="small" type="primary" @click="tmsSub('tmcodesRef')" >
                    {{ tmsLoad ? '添加中' : '添加商标' }}
                  </el-button>
                </el-form-item>


              </div>
              <div class="pos-rel trademarks" v-if="trademarks.trademarks.length != 0">
                <div :class="{on:relevantOpen}" class="relevant m-b10 flex flex-warp flex-cen font-12">
                  <div v-for="(item,index) in trademarks.trademarks" class="pos-rel p10 m-b10 ">
                    <el-image
                        :preview-src-list="[item.tm_picture]"
                        :src="item.tm_picture">
                    </el-image>
                    <div class="fl">
                      <div class="flex flex-cen m-b5">
                        <div class="w50">
                          <p class="ellipse">{{ item.tm_name || '图形' }}</p>
                          <p class="blue">第{{ item.tm_class }}类</p>
                        </div>
                        <div class="w45">

                          <p  class="ellipse w90">{{ item.client_name }}</p>
                          <p class="blue">{{ item.tm_number }}</p>
                        </div>
                      </div>
                      <div class="goods col-888">
                        {{ item.tm_goods }}
                      </div>
                    </div>
                    <i class="el-icon-close pos-abs pointer font-16"
                       v-if="(filters.state == 1 || add == false) "
                       @click="deleteTrademarks(index,trademarks.trademarks)"></i>
                  </div>
                </div>
                <div class="text-cen relevantOpen pos-abs w100" v-if="(trademarks.trademarks.length >= 10 && bodyWidth > 992) || (trademarks.trademarks.length > 100 && bodyWidth < 992)">
                  <i class="font-16 font-wbold pointer" :class="relevantOpen ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" @click="relevantOpen = !relevantOpen"></i>
                </div>
              </div>

            </div>
            <el-form-item class="text-cen m-t30">

              <el-button  :loading="subLoad" size="mini" type="primary" icon="el-icon-circle-check" @click="submit('ruleForm')">
                {{ subLoad ? '提交中' : '立即提交' }}
              </el-button>

              <el-button v-if="filters.state == 1" size="mini"  icon="el-icon-close"  @click="edit = !edit">取消</el-button>

            </el-form-item>

          </el-form>
        </div>
        <div v-if="add" class="ba-col-fff m-tb10 record">
          <h2>处理记录</h2>
          <div class="">
            <el-button  v-if="filters.state == 1" :loading="recordLoad" size="small" type="primary" icon="el-icon-circle-plus-outline" :class="{'is-disabled':filters.state != 1 && add,'m-b20':communicates.length != 0}"  @click="recordDia = true">
              {{ recordLoad ? '添加中' : '添加记录' }}
            </el-button>
            <div class="" >
              <el-empty v-if="communicates.length == 0 && filters.state != 1"  :image-size="60" description="暂无处理记录">

              </el-empty>
              <div class="" v-else>
                <div class="record-cont " ref="recordUpRef" :class="{on:recordUp}">
                  <div v-for="(item,index) in communicates" class="border1 bor-rad5 m-b10 p10 pos-rel">
                    <div class="m-b10">
                      <p class="blue"><span class="m-r10">{{ item.contact || '-'}}</span> <span>{{ item.created_at || '-' }}</span></p>
                      <p class="">{{ item.contact_content || '-'}}</p>
                    </div>
                    <div v-if="item.attachments.length !=0"  class="ba-col-light_blue p10 m-l10 w50 bor-rad5">
                      <p class="m-b10">附件({{ item.attachments.length }}个)</p>
                      <p class="">
                        <a v-for="att in item.attachments" :href="`${att.url}&preview=true`" class="m-r5 pointer blue disinblk"
                           target="_blank">{{ att.name }}&nbsp;</a>
                      </p>
                    </div>
                    <i class="el-icon-close pos-abs pointer" v-if="(user.nickname == item.contact || user.parent_id == 0) && filters.state == 1 && item.contact != '系统'" @click="detailRecord(index,filters.communicates,item.id)"></i>
                  </div>
                </div>
                <div class="text-cen recordOpen pos-abs w100" v-if="communicates.length >= 3">
                  <i class="font-16 font-wbold pointer" :class="recordUp ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" @click="recordUp = !recordUp"></i>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div v-if="add" class="ba-col-fff matters" >
          <h2 >申请事宜</h2>
          <el-empty v-if="(tms.length == 0 && copyrights.length == 0 && lawsuits.length == 0)"  :image-size="60" description="暂无申请事宜">

          </el-empty>
          <div class="" v-if="tms.length != 0">
            <div class="flex flex-cen list-t" >
              <h3 class="pointer" @click="trademark = !trademark"><i
                  :class="{  'el-icon-arrow-up' : trademark, 'el-icon-arrow-down':!trademark}"></i>商标事宜
                <span>{{ tms.length }}</span>
              </h3>
              <el-button :disabled="tms.length == 0" :loading="down && (type == 'lawsuit')" icon="el-icon-document"
                         size="small" type="primary" @click="diaClick('lawsuit')">
                {{ down ? '导出中' : '导出数据' }}
              </el-button>
            </div>

            <el-table
                ref="multipleTable"
                :class="{on:!trademark}"
                :data="tms"
                max-height="500"
                tooltip-effect="dark"
            >
              <template slot="empty">
                <el-empty :image-size="80"></el-empty>
              </template>
              <el-table-column label="#" type="index" width="50">
              </el-table-column>
              <el-table-column label="商标号" >
                <template slot-scope="scope">
                  {{scope.row.tmcode || '-'}}
                </template>

              </el-table-column>
              <el-table-column label="商标名称">
                <template slot-scope="scope">
                  <div class="tmimage flex align-cen">
                    <div class="">
                      <el-image
                          :preview-src-list="[scope.row.tmimage]"
                          :src="scope.row.tmimage"
                      >
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                    </div>
                    <span>{{ scope.row.tmname || '图形' }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="商标类别" prop="intcls"></el-table-column>
              <el-table-column label="申请类型" prop="apptype"></el-table-column>
              <el-table-column label="金额">
                <template slot-scope="scope">
                  ￥{{ scope.row.subtotal }}
                </template>
              </el-table-column>
              <el-table-column label="报送日期" prop="deliver_at"></el-table-column>
            </el-table>


          </div>
          <div v-if="copyrights.length != 0" class="">
            <div class="list-t flex flex-cen">
              <h3 class="pointer" @click="copyright = !copyright"><i
                  :class="{  'el-icon-arrow-up' : copyright, 'el-icon-arrow-down':!copyright}"></i>版权事宜
                <span>{{ copyrights.length }}</span></h3>
              <el-button :disabled="copyrights.length == 0" :loading="down && (type == 'copyright')"
                         icon="el-icon-document" size="small" type="primary" @click="diaClick('copyright')">
                {{ down ? '导出中' : '导出数据' }}
              </el-button>
            </div>

            <el-table
                ref="multipleTable"

                :class="{on:!copyright}"
                :data="copyrights"
                height="100%"
                tooltip-effect="dark"
            >
              <template slot="empty">
                <el-empty :image-size="80"></el-empty>
              </template>
              <el-table-column label="#" type="index" width="50">
              </el-table-column>
              <el-table-column label="登记号">
                <template slot-scope="scope">
                  {{ scope.row.register_number || '图形' }}
                </template>
              </el-table-column>
              <el-table-column label="作品名称">
                <template slot-scope="scope">
                  <div class="tmimage flex align-cen">
                    <div class="">
                      <el-image

                          :preview-src-list="[scope.row.picture]"
                          :src="scope.row.picture"
                      >
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                    </div>
                    <span>{{ scope.row.name || '图形' }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="作品类型" prop="type"></el-table-column>
              <el-table-column label="申请类型" prop="apptype"></el-table-column>
              <el-table-column label="金额">
                <template slot-scope="scope">
                  ￥{{ scope.row.subtotal }}
                </template>
              </el-table-column>
              <el-table-column label="报送日期" prop="deliver_at"></el-table-column>
            </el-table>
          </div>
          <div v-if="lawsuits.length != 0" class="">
            <div class="list-t flex flex-cen">
              <h3 class="pointer" @click="lawsuit = !lawsuit"><i
                  :class="{  'el-icon-arrow-up' : lawsuit, 'el-icon-arrow-down':!lawsuit}"></i>诉讼事宜
                <span>{{ lawsuits.length }}</span></h3>
              <el-button :disabled="lawsuits.length == 0" :loading="down && (type == 'lawsuit')" icon="el-icon-document"
                         size="small" type="primary" @click="diaClick('lawsuit')">
                {{ down ? '导出中' : '导出数据' }}
              </el-button>
            </div>

            <el-table
                ref="multipleTable"
                :class="{on:!lawsuit}"
                :data="lawsuits"
                class="mb20"
                height="100%"
                tooltip-effect="dark"
            >
              <template slot="empty">
                <el-empty :image-size="80"></el-empty>
              </template>
              <el-table-column label="#" type="index" width="50">
              </el-table-column>
              <el-table-column label="商标号">
                <template slot-scope="scope">
                  {{ scope.row.tmcode || '-' }}
                </template>
              </el-table-column>
              <el-table-column label="商标名称">
                <template slot-scope="scope">
                  <div class="tmimage flex align-cen">
                    <div class="">
                      <el-image
                          :preview-src-list="[scope.row.tmimage]"
                          :src="scope.row.tmimage"
                      >
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                    </div>
                    <span>{{ scope.row.tmname || '图形' }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="商标类别" prop="intcls"></el-table-column>
              <el-table-column label="申请类型" prop="apptype"></el-table-column>
              <el-table-column label="原告/上诉人" prop="applicant"></el-table-column>
              <el-table-column label="金额">
                <template slot-scope="scope">
                  ￥{{ scope.row.subtotal }}
                </template>
              </el-table-column>
              <el-table-column label="创建时间" prop="created_at"></el-table-column>
            </el-table>
          </div>
        </div>
      </template>
    </el-skeleton>



    <el-dialog
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        :visible.sync="endDia"
        class="endDia"
        title="您确定要完结该事项吗？"
        width="575px"
    @open="endOpen"
    @close="endClose">
      <el-form>
        <el-form-item >
          <p class="col-606"><span class="col-red">*</span>标签 <span class="col-red" v-if="verTag">（请选择一个标签）</span> </p>
          <el-radio-group v-model="tag" @change="endRadioChange">
            <el-radio :label="3">完成</el-radio>
            <el-radio :label="2">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input placeholder="请填写备注" v-model="remark" size="small" type="textarea"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
    <el-button size="small" icon="el-icon-close" @click="endDia = false">取 消</el-button>
    <el-button size="small" icon="el-icon-folder-checked" type="primary" @click="endSub">确 定</el-button>
  </span>
    </el-dialog>

    <el-dialog
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        :visible.sync="recordDia"
        class="recordDia"
        title="添加处理记录"
        width="30%" @open="recordOpen('recordRef')">
      <el-form class="p0" :rules="recordRules" ref="recordRef" :model="process">
        <el-form-item label="提醒顾问">
          <el-radio-group v-model="process.notify" size="small">
            <el-radio :label="0">不提醒</el-radio>
            <el-radio :label="1">短信提醒</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="记录描述" prop="contact_content">
          <el-input placeholder="请输入记录描述" v-model="process.contact_content" size="small" type="textarea"></el-input>
        </el-form-item>
        <div class="enclosure">
          <div class="flex flex-cen m-tb20">
            <p class="font-wbold">附件</p>
            <button v-if="reUpLoad" class="el-button el-button--primary el-button--small is-circle " data-v-5f0a7fba=""
                    type="button"
                    @click="uploadProcess">
              <input ref="processFile" accept="*" class="disnone" multiple="multiplt"
                     style="margin-left:70px;" type="file" @change="processFiles($event)">
              <i class="el-icon-plus"></i>
            </button>
            <el-form-item v-else >
              <el-button loading size="small" type="primary" circle></el-button>
            </el-form-item>

          </div>
          <div class="p10 border1 bor-rad5 flex flex-cen m-b10" v-for="(item,index) in process.attachments" v-if="process.attachments.length != 0">
            <p class="w90 flex align-cen">
              <img alt="" class="m-r10" src="@/assets/img/personal/icon-file.png">
              <a :href="`${item.path}&preview=true`" target="_blank" class="blue pointer ellipsis disinblk w70">{{ item.name }}</a>
            </p>
            <i class="el-icon-close pointer" @click="process.attachments.splice(index,1)"></i>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button size="small" type="primary" :disabled="!reUpLoad" icon="el-icon-folder-checked" @click="recordSub('recordRef')">保存</el-button>
  </span>
    </el-dialog>

    <el-dialog
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        :visible.sync="downloadDia"
        class="downloadDia"
        title="导出信息"
        width="30%"
        @close="openDia">
      <template>
        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="fields" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="city in cities" :key="city" :label="city">{{ city }}</el-checkbox>
        </el-checkbox-group>
      </template>
      <span slot="footer" class="dialog-footer">
    <el-button icon="el-icon-close" size="small" @click="downloadDia = false">取 消</el-button>
    <el-button :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' : 'el-icon-folder-checked'" class="confirm"
               size="small" type="primary" @click="download">确 定</el-button>
  </span>
    </el-dialog>
    <el-dialog
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        :visible.sync="editionDia"
        class="editionDia"
        title="提示"
        width="390px">
      <div class="text-cen flex align-cen"><i class="el-icon-warning m-r10"></i> 当前账号没有此操作权限，请升级至<span
          class="font-wbold col-red ">“企业版”</span>！
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button size="small" type="primary" @click="editionDia = false">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import {schedulesDetail,tmSearch,schedulesSave,fileUpload,saveProcess,schedulesComplete,exportBills,deleteProcess,schedulesDelete} from '@/api/personal.js'
//导出的全部选项
const trademarkOptions = ['商标图样', '商标名称', '商标号', '商品类别', '申请人', '申请类型', '金额', '当前进度', '申请日期', '商品项目'];
const copyrightOptions = ['样品图片', '作品名称', '申请人', '作品类型', '金额', '当前进度', '发表日期', '完成日期', '报送日期', '作品性质'];
const lawsuitOptions = ['商标图样', '商标名称', '商标号', '商品类别', '原告/上诉人', '诉讼类型', '金额', '第三人', '当前进度', '判决结果', '创建日期', '商品项目'];
const trademarkFields = ['商标名称', '商标号', '商品类别', '申请人', '申请类型', '金额', '当前进度', '申请日期']
const copyrightFields = ['作品名称', '申请人', '作品类型', '金额', '当前进度', '报送日期']
const lawsuitFields = ['商标名称', '商标号', '商品类别', '原告/上诉人', '诉讼类型', '金额', '第三人', '当前进度', '判决结果', '创建日期']

export default {
  name: "dealtMatterDetail",
  data() {

    return {
      loading: true,//文章骨架屏
      loadList:storage.getList(),
      user:storage.getUser(),
      bodyWidth:false,
      relevantOpen:false,
      recordUp:false,
      verTag:false,//完结提交验证是否选了必填
      subLoad: false,//提交按钮的加载中
      endLoad: false,//完结按钮的加载中
      recordLoad: false,//添加记录按钮的加载中
      tmsLoad: false,//添加商标按钮的加载中
      tmsLoadRule:false,
      reUpLoad:true,//记录对话框中附件按钮的加载中
      uploadLoad: true,//附件按钮的加载中
      pickerOptions: {//日期选择器的便利选择对象
        shortcuts: storage.getShortcuts()
      },
      endDia: false,//完结的对话框
      recordDia: false,//记录的对话框

      type: '',//记录是那个事宜打开的导出对话框
      down: false,//控制导出加载的显示与隐藏
      downloadDia: false,//控制导出的对话框
      editionDia: false,//权限对话框
      checkAll: false,//全选
      cities: '',//多选数据
      isIndeterminate: true,//控制全选框状态
      submitDis: false,//控制确定导出是按钮不可点
      copyrights: [],//代替作品事宜字段
      tms: [],//代替商标事宜字段
      lawsuits: [],//代替诉讼事宜字段
      communicates:[],//代替诉讼事宜字段
      trademark: true,//商标
      copyright: true,//作品
      lawsuit: true,//诉讼
      fields:'',//默认选中项

      remark:'',//完结的备注
      tag:0,//完结的单选
      detail: {
        types:['商标'],//需求类型
        client_id:'', //申请人
        agent_id:storage.getUser().agent.id,//知产顾问
        name:'',//需求标题
        summary:'',//需求描述
        deadline:'',//截止日期
        attachments:[],//附件
        trademarks:[],//相关商标
      },//代替当前为新建时的表单提交
      attachments: [],//代替附件的字段
      filters: { },//表单提交
      //代替需求类型的字段
      types:{
        types:['商标']
      },
      //代替相关商标的字段
      trademarks:{
        trademarks:[]
      },

      client_id:'',//代替申请人的字段
      agent_id:storage.getUser().agent.name,//代替知产顾问的字段
      appnameSelect: [],//申请人下拉
      agentSelect:[storage.getUser().agent],//知产顾问下拉
      //验证规则
      rules: {
        client_id: [
          {required: true, message: '请选择申请人', trigger: 'change'}
        ],
        agent_id: [
          {required: true, message: '请选择申请人', trigger: 'change'}
        ],
        name: [
          {required: true, message: '请输入需求标题', trigger: 'blur'}
        ],
        summary: [
          {required: true, message: '请填写需求描述', trigger: 'blur'}
        ],
        deadline: [
          { required: true, message: '请选择截止日期', trigger: 'blur'},
        ],
        agent: []
      },

      add:false,//判断是否是新建
      edit:true,//编辑
      //添加记录字段
      process:{
        sale_id:'',//待办事项id
        contact_content:'',//记录描述
        notify:1,//是否通知
        attachments:[],//附件
        contact:storage.getUser().nickname,
        created_at:'',

      },
      recordRules:{
        contact_content: [
          {required: true, message: '请填写需求描述', trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    this.bodyWidth = document.body.offsetWidth
    // 判断是否是新建

    if(this.$route.query.id){
      this.process.sale_id = this.$route.query.id
      this.add = true
      this.edit = true
      this.details(this.$route.query.id)
    }else{
      this.loading = false
      this.filters = this.detail
      this.add = false
      this.edit = false
    }

    this.select()
  },
mounted() {
  window.onresize = () => {
    this.bodyWidth = document.body.offsetWidth
  };
},
  methods: {
    //导出对话框
    diaClick(item) {
      if (storage.getUser().type != 0) {
        this.downloadDia = true
        this.type = item
        if (item == 'trademark') {
          this.cities = trademarkOptions
          this.fields = trademarkFields
        } else if (item == 'copyright') {
          this.cities = copyrightOptions
          this.fields = copyrightFields
        } else {
          this.cities = lawsuitOptions
          this.fields = lawsuitFields
        }
      } else {
        this.editionDia = true
      }


    },
    // 全选
    handleCheckAllChange(val) {
      this.fields = val ? (this.type == 'trademark' ? trademarkOptions : (this.type == 'copyright' ? copyrightOptions : lawsuitOptions)) : [];
      this.isIndeterminate = false;
    },
    // 当选
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    //导出对话框打开前事件
    openDia() {
      this.fields = this.$options.data().fields;
      this.checkAll = false
      this.isIndeterminate = true
    },
    // 导出
    async download() {
      this.down = true
      this.downloadDia = false
      if (this.fields.length != 0) {
        try {
          const res = await exportBills({fields:this.fields})
          if(res && res.err_code === 0){
            location.href = res.data.url
            this.down = false
          }else{
            this.$message({
              message: res.err_msg,
              type: 'warning'
            });
          }

        } finally {
          this.down = false
        }
      } else {
        this.$message({
          message: '至少选择一个',
          type: 'warning'
        });
      }
    },

    //详情数据
    details(item){
      this.loading = true
      schedulesDetail({id:item}).then(res =>{
        this.loading = false
        this.filters = res.data
        let pages = new RegExp("\n","g");
        this.filters.summary  = this.filters.summary.replace(pages,'</br>')
        this.client_id = this.filters.client.name
        this.agent_id = this.filters.agent.name
        this.attachments = res.data.attachments
        this.copyrights = res.data.copyrights
        this.tms = res.data.tms
        this.communicates = res.data.communicates
        this.lawsuits = res.data.lawsuits ? res.data.lawsuits : []
        if(res.data.types){
          this.types.types = res.data.types
          if(this.types.types.indexOf('苏韬') != -1){
            this.types.types[this.types.types.indexOf('苏韬')] = '法律'
          }
        }
        if(res.data.trademarks){
          this.trademarks.trademarks = res.data.trademarks
        }
      }).catch(e =>{
        this.loading = false
      })

    },
    //删除待办
    deletes(item){
      this.$confirm('确定要删除该待办事项吗？删除后将无法恢复！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        schedulesDelete({id:item}).then(res =>{
          window.close()
        })

      })
    },
    // 下拉
    async select() {
      let s = await storage.getSelects('app_names')
      this.appnameSelect = s.app_names
    },

    //待办的上传
    upload() {
      this.$refs.clearFile.click();
    },
    //选择文件
     getFile(event) {
      let fileList = []
      let file = event.target.files;

      for (let k of file) {
        fileList.push(k)
      }
      fileList.forEach(async item =>{
        if(item.size /1024/1024 > 20){
          this.$message.error('单个文件大小不超过20M!');
        }else{
          let formData = new FormData();
          formData.append("file", item);
          formData.append("is_base64", 0);
          this.uploadLoad = false
          try {
            const res = await fileUpload(formData)
            if(res && res.err_code === 0){
              res.data.created_at = new Date().getFullYear() + '-'+ (new Date().getMonth() + 1) + '-' + new Date().getDate()
              res.data.filename =  res.data.name
              res.data.filepath =  res.data.path
              this.filters.attachments.push(res.data)
              this.uploadLoad = true
            }else{
              this.$message({
                message: res.err_msg,
                type: 'warning'
              });
            }
          } finally {

          }
        }

      })

    },
    //删除
    deleteAttachments(index, rows) {
      this.$confirm('确定要删除该附件吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$message({
          type: "success",
          message: "该附件已删除！",
        });
        rows.splice(index, 1);
      })
    },
    deleteTrademarks(index, rows) {
      this.$confirm('确定要删除该相关商标吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$message({
          type: "success",
          message: "该相关商标已删除！",
        });
        rows.splice(index, 1);
      })
    },
    //表单提交
    submit(formName) {
      if(this.types.types.indexOf('法律') != -1){
        this.types.types[this.types.types.indexOf('法律')] = '苏韬'
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.subLoad = true
          schedulesSave({...this.filters,...this.types,...this.trademarks}).then(res =>{
            this.subLoad = false
            this.$router.push({name:"dealtMatterDetail",query:{id:res.data.id}})
            this.details(parseInt(res.data.id))
            this.tmsLoadRule = false
            this.add = true
            this.edit = true
          })
        } else {
          return false;
        }
      })
    },
    //完结对话框打开前
    endOpen(){
      this.remark = ((this.tag == 3) ? '相关事宜已办理完毕' : (this.tag == 2) ?  '暂无明确需求，关闭' : '') + this.remark
    },
    //完结提交
    endSub() {
      if(this.tag == 0){
        this.verTag = true
      }else{
        this.endLoad = true

        schedulesComplete({sale_id:parseInt(this.$route.query.id),tag:this.tag,remark:this.remark}).then(res =>{
          this.$message({
            message: (this.tag == 2 ? '已关闭' : '已完结'),
            type: (this.tag == 2 ?  'info' : 'success')
          })
          this.endDia = false
          this.endLoad = false
          this.details(parseInt(this.$route.query.id))
        })
      }


    },
    //完结对话框里的单选改变
    endRadioChange(e){
      this.verTag = false
      this.remark = (this.tag == 3) ? '相关事宜已办理完毕' : '暂无明确需求，关闭'
    },
    //完结对话框关闭
    endClose(){
      this.verTag = false
      this.remark = ''
      this.tag = 0
    },
    //添加商标
    tmsSub(formName) {

      if(this.filters.tmcodes){
        this.tmsLoadRule = false
        this.tmsLoad = true
        let str = new RegExp("，","g");
        this.filters.tmcodes = this.filters.tmcodes.replace(str,',')
        tmSearch({tmcodes:this.filters.tmcodes.split(',')}).then(res =>{
          this.tmsLoad = false
          this.trademarks.trademarks = res.data
        })
      }else{
        this.tmsLoadRule = true
      }


    },
    //打开记录对话框前
    recordOpen(formName){
      this.$refs[formName].resetFields();
      this.process = this.$options.data().process;
    },
    //删除记录
    detailRecord(index, rows,id){
      this.$confirm('确定要删除该条记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        deleteProcess({id:id}).then(res =>{
          this.$message({
            type: "success",
            message: "该条记录已删除！",
          });
          rows.splice(index, 1);
          this.communicates.splice(index,1)
        })
      })
    },
    //添加记录
    recordSub(formName) {
      this.process.sale_id = this.$route.query.id
      this.process.created_at = new Date().getFullYear() + '-' + (new Date().getMonth()  < 9 ? ('0' + (new Date().getMonth() + 1)) : (new Date().getMonth() + 1) ) + '-' + new Date().getDate() + ' ' +  (new Date().getHours() < 10 ? ('0' + new Date().getHours()) : new Date().getHours()) + ':' + (new Date().getMinutes() < 10 ? ('0' + new Date().getMinutes()) : new Date().getMinutes()) + ':' + (new Date().getSeconds() < 10 ? ('0' + new Date().getSeconds()) : new Date().getSeconds())
      this.$refs[formName].validate((valid) =>{
        if (valid) {
          this.recordLoad = true
          saveProcess(this.process).then(res =>{
            this.filters.communicates.unshift(this.process)
            this.communicates.unshift(this.process)
            this.recordLoad = false
            this.process = this.$options.data().process;
          })
          this.recordDia = false
        } else {
          return false;
        }
      })

    },
    //记录上传
    uploadProcess() {
      this.$refs.processFile.click();
    },
    //选择文件
     processFiles(event) {
      let fileList = []
      let attachments = {}
      let file = event.target.files;
      for (let k of file) {
        fileList.push(k)
      }
      fileList.forEach(async item =>{
        let formData = new FormData();
        formData.append("file", item);
        formData.append("is_base64", 0);
        try {
          this.reUpLoad = false
          const res = await fileUpload(formData)
          if(res && res.err_code === 0){
            this.reUpLoad = true
            attachments.name = res.data.name
            attachments.created_at = new Date().getFullYear() + '-'+ (new Date().getMonth() + 1) + '-' + new Date().getDate()
            attachments.path = res.data.url
            attachments.url = res.data.path
            attachments.creator = storage.getUser().nickname
            this.process.attachments.push(attachments)
          }
        } finally {

        }
      })



    },

  }
}
</script>

<style lang="less" scoped>
.h2{
  padding: 15px 0 8px 15px;
  margin-top: 15px;
  background: #fff;
  border-bottom: 1px solid #E8E8E8;
  border-radius: 5px 5px 0 0;
  .button{
    padding: 7px 15px;
    border-radius: 3px;
  }
  .el-button--info{
    cursor: unset;
  }
}
.p-20-30{
  padding: 20px 30px;
  ::v-deep .el-col>span{
    display: inline-block;
  }
}
::v-deep .el-table{
  th,.el-table__cell{
    padding: 5px 0 !important;
    .el-button--mini{
      padding: 4px 15px;
      border: none;
    }
  }
}
.edit{
  margin-top: 15px;
  padding: 12px 0 12px 15px;
  border-bottom:1px solid #E8E8E8;
  border-radius: 5px 5px 0 0;

  .el-button,.el-icon-close{
    margin-right: 15px;
  }

}

::v-deep form.el-form {
  padding: 20px 30px;

  .el-form-item {
    margin-bottom: 0;

    .el-form-item__label {
      text-align: left;
    }
  }

  .el-form-item__error {
    display: none;
  }

  .assTms {
    .el-form-item {
      margin: 0 10px 0 0;
      .el-input.on{
        .el-input__inner {
            border-color: #f56c6c;
        }
      }
    }
  }

  .el-button {
    position: unset;
  }
  .el-table{
    .el-button--mini{
      padding: 5px 15px;
    }
  }



}
.trademarks{
  .relevantOpen{
    bottom: -25px;
    ::v-deep i{
      width: 120px;

    }
  }
  .relevant {
    overflow: hidden;
    max-height: 495px;

    .p10{
      border: 1px solid #D9D9D9;

      .el-image {
        width: 69px;
        height: 69px;
        border: 1px solid #DDDDDD;
        margin-right: 10px;
        float: left;

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          max-width: 69px;
          max-height: 69px;
          width: unset;
          height: unset;
        }
      }
      .fl{
        width: calc(100% - 90px);
      }
      .goods {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
      }
    }


    i {
      top: 10px;
      right:10px;
    }
  }
  .relevant.on{
    max-height: unset;
  }
}

.record > div {
  padding: 20px 30px;
  .record-cont{
    overflow: hidden;
    max-height: 240px;
    .el-icon-close{
      right: 10px;
      top: 10px;
    }

  }
  .record-cont.on{
    max-height: unset;
  }
}

.matters {
  .list-t {
    padding: 15px 30px;

    h3 {

      font-size: 18px;
      font-weight: bold;

      i {
        color: #3894FF;
        margin-right: 10px;
      }

      span {
        margin-left: 10px;
        font-size: 16px;
        color: #325A99;
      }
    }
  }


  .tmimage {
    .el-image {
      width: 72px;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      border: 1px solid #DDD;
      background: #fff;

      ::v-deep img {
        max-height: 70px;
        max-width: 70px;
        min-width: unset;
        width: unset;
        height: unset;
        margin: 0;
      }
    }

  }

  .el-table {
    //height:100%;
    transition: all 1s;
  }

  .el-table.on {
    height: 0px !important;
  }
}


::v-deep .el-dialog {
  .el-dialog__body {
    padding: 10px 15px;
  }
}

::v-deep .endDia {
  form.el-form{
    padding: 0;
  }
  .el-textarea {
    margin: 10px 0;
  }

}

.recordDia {
  ::v-deep .el-form-item {
    margin-bottom: 10px;
    .el-radio{
      font-weight: normal;
      color: #606266;
    }
  }

  img {
    width: 22px;
  }
}
.relevant::-webkit-scrollbar,.record-cont::-webkit-scrollbar {
  width : 5px;
  height: 5px;
}
.relevant::-webkit-scrollbar-thumb,.record-cont::-webkit-scrollbar-thumb {
  box-shadow   : inset 0 0  5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.relevant::-webkit-scrollbar-track,.record-cont::-webkit-scrollbar-track{
  background: #F4F4F4;
}
@media screen and (min-width: 992px) {
.relevant{
  .p10 {
    width: 49%;
  }
}

}

@media screen and (max-width: 992px) {
  ::v-deep .el-checkbox {
    margin-right: 5px;
  }
.relevant{
  .p10 {
    width: 100%;
  }
}


}

</style>
