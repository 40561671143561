<template>
<div class="client">
  <div class="client-txt">
    <h1>技术为知产赋能</h1>
    <p>我们会同样保持最快速的更新，让你无论何时何地都可以完整的使用知者云</p>
  </div>
  <div class="client-body">
    <div class="bodyL">
      <div class="download">

          <h3>
            <img src="@/assets/img/personal/icon-download1.png" alt="">
            下载 Windows版本
          </h3>
          <p>版本：<font>1.0.0</font></p>
          <p>支持系统:<br> <span>支持 64位Windows10 及以上版本</span></p>
          <el-button type="primary" size="small" @click="open('win')">下载</el-button>

      </div>
      <div class="download">

        <h3>
          <img src="@/assets/img/personal/icon-download2.png" alt="">
          下载 Mac 版本
        </h3>
        <p>版本：<font>1.0.0</font></p>
        <p>支持系统:<br><span>支持 macOS 10.11 及以上的版本</span></p>
        <el-button type="primary" size="small" @click="open('mac')">下载</el-button>

      </div>
      <div class="download">

        <h3>
          <img src="@/assets/img/personal/icon-download3.png" alt="">
          App Store 版本
        </h3>
        <p>版本：<font>1.0.0</font></p>
        <el-button type="primary"  size="small" @click="openIos">下载</el-button>

      </div>
      <div class="download">

        <h3>
          <img src="@/assets/img/personal/icon-download4.png" alt="">
          下载  Android 版本
        </h3>
        <p>版本：<font>1.0.0</font></p>
        <el-button type="primary" size="small" @click="openAnd">下载</el-button>
<!--        <el-button type="info" disabled size="small">敬请期待</el-button>-->

      </div>
    </div>
    <div class="bodyR text-cen">
      <img src="@/assets/img/personal/img-client.png" alt="">
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "client",

  methods:{
    open(os) {
      let file;
      if (os == 'mac') {
        file = '知者云-1.0.0.dmg'
      } else if (os == 'win') {
        file = '知者云 Setup 1.0.0.exe'
      }
      location.href = '/download?name=' + file + '&path=resource/' + file
    },
    openAnd(){
      location.href = "http://zhushou.360.cn/detail/index/soft_id/4645672?recrefer=SE_D_%E7%9F%A5%E8%80%85%E4%BA%91"
    },
    openIos(){
      location.href = "https://apps.apple.com/cn/app/%E7%9F%A5%E8%80%85%E4%BA%91-%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%83%E7%AE%A1%E7%90%86%E5%B9%B3%E5%8F%B0/id6446467821"
    }
  }
}
</script>

<style scoped lang="less">
.client{
  padding-bottom: 40px;
  margin-top: 10px;
  background: url("../../assets/img/personal/ba-client.png");
  background-size: 100% 100%;
  .client-txt{
    margin-bottom: 80px;
    text-align: center;
    padding-top: 80px;
    h1{
      font-size: 48px;
      font-weight: bold;
      margin-bottom: 40px;
    }
    p{
      font-size: 20px;

    }
  }
.client-body{

  margin: auto;
  display: flex;

  .bodyL{

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .download{

      background: #fff;
      border-radius: 5px;
      h3{
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
        img{
          width: 48px;
          height: 48px;
        }
      }
      p{
        margin-bottom: 20px;
        span{
          color: #C1C1C1;
        }
        font{
          font-weight: bold;
          color: #409EFF;
        }
      }
      .el-button--small{
        padding: 9px 40px;
        margin: auto;
        display: block;
      }
    }
  }
}
}
@media screen and (max-width: 768px){
  .client{
    .client-body{
      flex-direction:column-reverse;
      width: 90%;
      .bodyL{
        padding-bottom: 40px;
        width: 95%;
        margin: auto;
        .download{
          margin-bottom: 20px;
          width: 49%;
          padding: 20px 10px;
        }
      }
      .bodyR{
        width: 50%;
        margin: auto;
        img{
          width: 100%;
        }
      }
    }
  }
}
@media screen and (min-width: 768px){
  .client{
    .client-body{
      flex-direction:column-reverse;
      width: 90%;
      .bodyL{
        padding-bottom: 40px;
        width: 95%;
        margin: auto;
        .download{
          margin-bottom: 20px;
          width: 49%;
          padding: 20px 10px;
        }
      }
      .bodyR{
        width: 50%;
        margin: auto;
        img{
          width: 100%;
        }
      }
    }
  }
}
@media screen and (min-width: 992px){
  .client{
    .client-body{

      .bodyL{
        width: 75%;
        .download{
          padding: 20px 20px;
        }
      }

    }
  }

}
@media screen and (min-width: 1200px){
  .client{
    min-height:calc(100vh - 100px);
    .client-body{
      justify-content: space-between;
      flex-direction:unset;
      align-items: center;
      width: 95%;
      .bodyL{
        width: 50%;
        .download{
          margin-bottom: 20px;
          width: 49%;
          padding: 20px 10px;
        }
      }
      .bodyR{
        width: 49%;
      }
    }
  }


}
@media screen and (min-width: 1900px){
  .client{
    .client-body{
      width: 80%;
      .bodyL{
        height: 450px;
        width: 600px;
        .download{
          margin-bottom: 20px;
          width: 280px;
          padding: 20px 30px;
        }
      }
      .bodyR{
        img{
          width: 580px;
          height: 450px;
        }
      }

    }
  }

}
</style>