<template>
  <div class="">
    <div class=" list m-t15">

      <!-- 表单 -->
      <form ref="filters" action="" v-on:submit.prevent>
        <div class="form_inp">
          <div class="inp_tab plf20 flex flex-cen col-606 m-b15">
            <span v-for="item in tabs" :class="{on:item.type == filters.type}" class="pointer"
                  @click="filters.type = item.type">{{ item.name }}</span>

          </div>
          <div :class="{on:searchVid}" class="inp">
            <el-input
                v-model.trim="filters.keyword"
                placeholder="请输入商标名称、商标号或申请人"
                size="small"
            >
              <i
                  slot="prefix"
                  class="el-icon-search el-input__icon">
              </i>
              <el-button slot="suffix" size="small" type="primary" @click="searchKey">搜索</el-button>
            </el-input>
          </div>
        </div>
        <el-row :class="{on:formOpen,ptb10:formOpen}" class="input formInput  ">


          <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="24">
            <el-row :gutter="10" align="middle" justify="space-between" type="flex">
              <el-col :lg="5" :md="6" :sm="6" :xl="3" :xs="3"><strong>商标类别</strong></el-col>
              <el-col :lg="19" :md="18" :sm="18" :xl="21" :xs="21">
                <el-select
                    v-model="filters.intcls"
                    clearable
                    collapse-tags
                    filterable
                    multiple
                    placeholder="请选择商标类别"
                    size="small"
                    @change="searchKey"
                >
                  <el-option
                      v-for="item in intclsSelect.select"
                      :key="item.id"
                      :label="item.text"
                      :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>


          <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="24">
            <el-row :gutter="10" align="middle" justify="space-between" type="flex">
              <el-col :lg="5" :md="6" :sm="6" :xl="3" :xs="3"><strong>法律状态</strong></el-col>
              <el-col :lg="19" :md="18" :sm="18" :xl="21" :xs="21">
                <el-select
                    v-model="filters.tmlaw"
                    clearable
                    filterable
                    placeholder="请选择法律状态"
                    size="small"
                    @change="searchKey"
                >
                  <el-option
                      v-for="item in selects.tmlaw"
                      :key="item.key"
                      :label="item.value"
                      :value="item.key"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>
          <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="24">
            <el-row :gutter="10" align="middle" justify="space-between" type="flex">
              <el-col :lg="5" :md="6" :sm="6" :xl="3" :xs="3"><strong>申请人</strong></el-col>
              <el-col :lg="19" :md="18" :sm="18" :xl="21" :xs="21">
                <el-select
                    v-model="filters.appname"
                    clearable
                    filterable
                    placeholder="请选择申请人"
                    size="small"
                    @change="searchKey"
                >
                  <el-option
                      v-for="item in selects.appname"
                      :key="item"
                      :label="item"
                      :value="item"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>

          <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="24">
            <el-row :gutter="10" align="middle" justify="space-between" type="flex">
              <el-col :lg="5" :md="6" :sm="6" :xl="3" :xs="3"><strong>代理机构</strong></el-col>
              <el-col :lg="19" :md="18" :sm="18" :xl="21" :xs="21">
                <el-select
                    v-model="filters.agentname"
                    clearable
                    filterable
                    placeholder="请选择代理机构"
                    size="small"
                    @change="searchKey"
                >
                  <el-option
                      v-for="item in selects.agency"
                      :key="item"
                      :label="item"
                      :value="item"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>

          <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="24">
            <el-row :gutter="10" align="middle" justify="space-between" type="flex">
              <el-col :lg="5" :md="6" :sm="6" :xl="3" :xs="3"><strong>申请年份</strong></el-col>
              <el-col :lg="19" :md="18" :sm="18" :xl="21" :xs="21">
                <el-select
                    v-model="filters.appdate"
                    clearable
                    filterable
                    placeholder="请选择申请年份"
                    size="small"
                    @change="searchKey"
                >
                  <el-option
                      v-for="item in selects.appdate"
                      :key="item"
                      :label="item"
                      :value="item"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row class="formOpen">
          <el-col :class="{on:formOpen}" :xs="24">
            <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
          </el-col>
        </el-row>
      </form>
      <!-- 列表 -->
      <div class="mainrb">
        <div class="listT clearfix">
          <el-button v-if="down" :disabled="exportDis" icon="el-icon-document" size="small" type="primary"
                     @click="downloadDia = true">导出数据
          </el-button>
          <el-button v-else :loading="true" size="small" type="primary">导出中</el-button>

          <div class="listTr fr">
            <strong :class="{ on: liston == 0 }" @click="liston = 0"
            ><img
                v-if="liston == 0"
                alt=""
                src="@/assets/img/search/icon-list2-1.png"/>
              <img v-else alt="" src="@/assets/img/search/icon-list2.png"
              /></strong>
            <strong :class="{ on: liston == 1 }" @click="liston = 1">
              <img
                  v-if="liston == 1"
                  alt=""
                  src="@/assets/img/search/icon-list1.png"
              />
              <img v-else alt="" src="@/assets/img/search/icon-list1-1.png"/>
            </strong>


          </div>
        </div>
        <!--       表格列表-->
        <el-skeleton v-show="liston == 1" :cont="1" :loading="loading" animated>
          <template slot="template">
            <el-table


                :class="{on:!formOpen,ios:$store.state.heights}"
                :data="loadList"
                height="90%"
                @sort-change="sort_change"

            >
              <template slot="empty">
                <el-empty :image-size="80"></el-empty>
              </template>
              <el-table-column label="序号" width="50">
                <template slot-scope="scope">
                  <el-skeleton-item class="w50" variant="p"/>
                </template>
              </el-table-column>
              <el-table-column label="商标名称" min-width="120">
                <template slot-scope="scope">
                  <el-skeleton-item class="w50" variant="p"/>
                </template>

              </el-table-column>
              <el-table-column label="商标号" width="100">
                <template slot-scope="scope">
                  <el-skeleton-item class="w50" variant="p"/>
                </template>
              </el-table-column>
              <el-table-column label="类别">
                <template slot-scope="scope">
                  <el-skeleton-item class="w50" variant="p"/>
                </template>
              </el-table-column>

              <el-table-column
                  label="申请人" min-width="120"
              >
                <template slot-scope="scope">
                  <el-skeleton-item class="w50" variant="p"/>
                </template>
              </el-table-column>


              <el-table-column label="法律状态" min-width="100">
                <template slot-scope="scope">
                  <el-skeleton-item class="w50" variant="p"/>
                </template>
              </el-table-column>

              <el-table-column
                  label="申请日期"
                  sortable="custom"
                  width="120"
              >
                <template slot-scope="scope">
                  <el-skeleton-item class="w50" variant="p"/>
                </template>
              </el-table-column>
              <el-table-column
                  label="注册日期"
                  sortable="custom"
                  width="120"
              >
                <template slot-scope="scope" class="sort-jub">
                  <el-skeleton-item class="w50" variant="p"/>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="170">
                <template slot-scope="scope">
                  <el-skeleton-item class="w50" variant="p"/>
                </template>
              </el-table-column>
            </el-table>
          </template>
          <el-table


              :class="{on:!formOpen,ios:$store.state.heights}"
              :data="list"
              height="90%"
              @sort-change="sort_change"

          >
            <template slot="empty">
              <el-empty :image-size="80"></el-empty>
            </template>
            <el-table-column label="序号" width="50">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }} </span>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="商标名称" min-width="120">
              <template slot-scope="scope">
                <div class="ellipsis">
                  {{ scope.row.tmname || '图形' }}
                </div>
              </template>

            </el-table-column>
            <el-table-column label="商标号" prop="tmcode" width="100">

            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="类别">
              <template slot-scope="scope">
                <div class="ellipsis">
                  {{ scope.row.clstext || '-' }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
                :show-overflow-tooltip="true" label="申请人"
                min-width="120"
            >
              <template slot-scope="scope">
                  <span :class="{'col-blue':scope.row.appname}">
                  {{ scope.row.appname || '-' }}
                </span>
              </template>
            </el-table-column>


            <el-table-column label="法律状态" min-width="100">
              <template slot-scope="scope">
                <el-tag
                    :class="{tag6:['注册申请中','驳回复审中','行政诉讼中','无效程序中','无效程序中','撤销复审中','撤三程序中','异议程序中'].indexOf(scope.row.tmlaw) != -1 ,tag5:scope.row.tmlaw == '撤三申请不予受理',tag2:scope.row.tmlaw == '初审公告',tag7:scope.row.tmlaw == '已注册',tag4:scope.row.tmlaw == '商标无效',tag1:scope.row.tmlaw == '驳回待复审',tag9:scope.row.tmlaw == '其它状态' || scope.row.tmlaw == '行政诉讼撤诉' || scope.row.tmlaw == '续展不予核准',}"
                    size="mini"
                    type="">
                  {{ scope.row.tmlaw }}
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column
                label="申请日期"
                sortable="custom"
                width="120"
            >
              <template slot-scope="scope">
                {{ scope.row.appdate || '-' }}
              </template>
            </el-table-column>
            <el-table-column
                label="注册日期"
                sortable="custom"
                width="120"
            >
              <template slot-scope="scope" class="sort-jub">
                {{ scope.row.regdate || '-' }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="170">
              <template slot-scope="scope">
                <router-link
                    :to="{ name: 'queryDetail', params: { id: scope.row.id} }"
                    target="_blank">
                  <el-button icon="el-icon-edit-outline" size="small" type="text">详情</el-button>
                </router-link>
              </template>
            </el-table-column>
          </el-table>
        </el-skeleton>

        <!-- 块状列表 empty:list.length == 0,-->
        <div v-show="liston == 0" :class="{empty:list.length == 0,on:!formOpen,ios:$store.state.heights}"
             class="list-list">
          <ul class="list_t flex flex-cen">

            <li>
              <span class="pointer"
                    @click="sort == '' ? (filters.sort = 'appdate|asc',sort = 'appdateAsc') : (sort == 'appdateAsc' ? (filters.sort = 'appdate|desc',sort = 'appdateDes') : ((sort.indexOf('appdate') == -1) ?  (filters.sort = 'appdate|asc',sort = 'appdateAsc') : (filters.sort = '',sort = '')) ),searchKey() ">申请日期</span>
              <span class="caret-wrapper">
              <i :class="{on:sort == 'appdateAsc'}"
                 class="sort-caret ascending"
                 @click.stop="sort == 'appdateAsc' ? (sort = '',filters.sort = '') : (sort = 'appdateAsc',filters.sort = 'appdate|asc'),searchKey() "></i>
              <i :class="{on:sort == 'appdateDes'}"
                 class="sort-caret descending"
                 @click.stop="sort == 'appdateDes' ? (sort = '',filters.sort = '') : (sort = 'appdateDes',filters.sort = 'appdate|desc'),searchKey() "></i>
            </span>
            </li>
            <li>
              <span class="pointer"
                    @click="sort == '' ? (filters.sort = 'csdate|asc',sort = 'csdateAsc') : (sort == 'csdateAsc' ? (filters.sort = 'csdate|desc',sort = 'csdateDes') : ((sort.indexOf('csdate') == -1) ?  (filters.sort = 'csdate|asc',sort = 'csdateAsc') : (filters.sort = '',sort = '')) ),searchKey() ">初审日期</span>
              <span class="caret-wrapper">
              <i :class="{on:sort == 'csdateAsc'}"
                 class="sort-caret ascending"
                 @click.stop="sort == 'csdateAsc' ? (sort = '',filters.sort = '') : (sort = 'csdateAsc',filters.sort = 'csdate|asc'),searchKey() "></i>
              <i :class="{on:sort == 'csdateDes'}"
                 class="sort-caret descending"
                 @click.stop="sort == 'csdateDes' ? (sort = '',filters.sort = '') : (sort = 'csdateDes',filters.sort = 'csdate|desc'),searchKey() "></i>
            </span>
            </li>
            <li>
              <span class="pointer"
                    @click="sort == '' ? (filters.sort = 'regdate|asc',sort = 'regdateAsc') : (sort == 'regdateAsc' ? (filters.sort = 'regdate|desc',sort = 'regdateDes') : ((sort.indexOf('regdate') == -1) ?  (filters.sort = 'regdate|asc',sort = 'regdateAsc') : (filters.sort = '',sort = ''))),searchKey() ">注册日期</span>
              <span class="caret-wrapper">
              <i :class="{on:sort == 'regdateAsc'}"
                 class="sort-caret ascending"
                 @click.stop="sort == 'regdateAsc' ? (sort = '',filters.sort = '') : (sort = 'regdateAsc',filters.sort = 'regdate|asc'),searchKey()  "></i>
              <i :class="{on:sort == 'regdateDes'}"
                 class="sort-caret descending"
                 @click.stop="sort == 'regdateDes' ? (sort = '',filters.sort = '') : (sort = 'regdateDes',filters.sort = 'regdate|desc') ,searchKey() "></i>
            </span>
            </li>

          </ul>
          <el-empty v-if="list.length == 0 && !loading" :image-size="80"></el-empty>
          <div v-else class="list-cont">
            <el-skeleton :class="{on:!formOpen,ios:$store.state.heights}" :cont="1" :loading="loading" animated>
              <template slot="template">
                <div v-for="(item, index) in loadList" :key="index" class="listb">
                  <div class="content">
                    <div class="">
                      <el-skeleton-item
                          style="width: 110px;height:110px"
                          variant="image"
                      />
                    </div>
                    <div class="contentC">
                      <p class="m-b20">
                        <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                        <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                        <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      </p>
                      <p class="m-b20">
                        <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                        <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                        <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>

                      </p>
                      <p class="m-b20">
                        <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                        <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                        <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      </p>
                      <p>
                        <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                        <span><el-skeleton-item style="width: 60%;margin-bottom: 0" variant="text"/></span>
                      </p>
                    </div>
                    <div class="details">
                      <el-skeleton-item
                          style="width: 100px"
                          variant="p"
                      />
                    </div>
                  </div>
                </div>
              </template>

              <div v-for="(item, index) in list" :key="index" class="listb">
                <div class="content">
                  <div class="content-index">
                    {{ index + 1 }}
                  </div>
                  <div class="content-img ">
                    <div class="img-img  flex align-cen ju-con-cen">
                      <el-image :preview-src-list="[item.tmimage]"
                                :src="item.tmimage">
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                    </div>
                    <div :class="{'tag-primary':['驳回复审完成','无效宣告完成','异议程序完成'].indexOf(item.tmlaw) != -1 ,tag6:['注册申请中','驳回复审中','行政诉讼中','无效程序中','无效程序中','撤销复审中','撤三程序中','异议程序中'].indexOf(item.tmlaw) != -1 ,tag5:item.tmlaw == '撤三申请不予受理',tag2:item.tmlaw == '初审公告',tag7:item.tmlaw == '已注册',tag4:item.tmlaw == '商标无效',tag1:item.tmlaw == '驳回待复审',tag9:item.tmlaw == '其它状态' || item.tmlaw == '行政诉讼撤诉' || item.tmlaw == '续展不予核准',}"
                         class="img-tmlaw "
                    >
                      {{ item.tmlaw }}
                    </div>
                  </div>
                  <div class="contentC grid grid-row-gap8">
                    <p>

                      <span class="flex align-cen"><strong
                          class="font-16 font-wbold">{{ item.clstext || '-' }}：</strong><font class="col-blue ellipsis">{{ item.tmname || '图形' }}</font> </span>
                      <span><font>商标号：{{ item.tmcode || '-' }}</font> <i
                          v-copy="item.tmcode" class="el-icon-copy-document col-blue pointer m-l5"></i></span>
                      <span>申请日期：{{ item.appdate || '-' }} </span>

                    </p>
                    <p>
                      <span>初审日期：{{ item.appdate || '-' }}</span>

                      <span>注册日期：{{ item.regdate || '-' }}</span>
                      <span>专用期限至：{{ item.enddate || '-' }}</span>

                    </p>
                    <p>

                    <span class=" flex align-cen">
                      申请人：
                       <el-tooltip v-showtip :content="item.appname" class="item" effect="dark" placement="top-start">
                         <span class="w80 flex align-cen">

                           <font :class="{'col-blue':item.appname }"
                                 class="ellipse-text"> {{ item.appname || '-' }} </font>
                         </span>
                       </el-tooltip>

                    </span>

                      <span class="flex align-cen">代理机构：
                      <el-tooltip v-showtip :content="item.agentname" class="item" effect="dark" placement="top-start">
                        <font class=" ellipsis w80">
                          <font :class="{'col-blue':item.agentname}" class="ellipse-text ">
                            {{ item.agentname || '-' }}
                          </font>
                        </font>
                      </el-tooltip>

                    </span>

                    </p>
                    <p>
                      商品项目：
                      <span v-if="replace.goods[index] == false">-</span>
                      <el-tooltip v-showtip class="item" effect="dark" placement="top-start" popper-class="max-wTip">
                        <span slot="content" v-html="replace.goodsCont[index]"></span>
                        <span class="ellipsis">
                        <font class="ellipse-text">
                          <font v-for="(goods,goodsInx) in replace.goods[index]">
                            <s v-if="goods.status != 0" class="grey">{{ goods.name }}</s>
                            <font v-else>{{ goods.name }}</font>
                            <font v-if="goodsInx != replace.goods[index].length -1">、</font>
                          </font>
                        </font>
                      </span>
                      </el-tooltip>

                    </p>
                  </div>
                  <div class="details">
                    <router-link :to="{name: 'queryDetail',params: { id: item.id},}"
                                 target="_blank">
                      <el-button icon="el-icon-edit-outline" size="small" type="primary">详情</el-button>
                    </router-link>


                  </div>
                </div>
              </div>
            </el-skeleton>
          </div>
        </div>
        <!-- 分页 -->
        <!-- 分页 -->
        <el-pagination
            :current-page.sync="filters.page"
            :page-size="filters.per_page"
            :page-sizes="[30, 50, 100]"
            :total="pageSize"
            layout="total, sizes, prev, pager, next, jumper,slot"
            @size-change="changeRowNum"
            @current-change="searchKey(backTop)"
        >
        </el-pagination>
      </div>


    </div>

    <el-dialog
        :closeOnClickModal="false"
        :modal-append-to-body="false"
        :visible.sync="downloadDia"
        class="openDia"
        title="导出信息"
        width="576px"
        @open="openDia">
      <template>
        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="filters.fields" class="line-h22" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="city in cities" :key="city" :label="city">{{ city }}</el-checkbox>
        </el-checkbox-group>
      </template>
      <span slot="footer" class="dialog-footer">
    <el-button icon="el-icon-close" size="small" @click="downloadDia = false">取 消</el-button>
    <el-button :disabled="submitDis" :icon="submitDis ? 'el-icon-loading' : 'el-icon-folder-checked'" class="confirm"
               size="small" type="primary" @click="download">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import {tmSearchExport, tmSearchKeyword} from '@/api/personal.js'

const cityOptions = ['商标图样', '商标名称', '商标状态', '商标号', '商标类别', '申请日期', '申请人', '申请人地址', '初审公告期号', '初审日期', '注册公告期号', '注册日期', '专用权期限', '类似群组', '商品项目', '代理机构', '最后流程'];

export default {
  name: "keyword",
  data() {
    return {
      replace: {//代替字段
        goods: [],
        goodsCont: []
      },
      exportDis: true,//控制导出按钮不可点
      searchVid: false,
      tabs: [
        {name: '相同', type: 'name'},
        {name: '近似', type: 'all'},
        {name: '商标号', type: 'data_id'},
        {name: '申请人', type: 'applicant'},
      ],
      selects: {
        tmlaw: [],
        appname: [],
        agency: [],
        appdate: [],
      },//关键字搜索相关下拉

      sort: '',//记录排序
      formOpen: false,//控制搜索栏展开或收起

      downloadDia: false,//控制导出对话框的显示与隐藏
      checkAll: false,//全选
      cities: cityOptions,//导出的全部选项
      isIndeterminate: true,//控制全选框状态
      submitDis: false,
      backTop: true,
      down: true,//控制导出加载的显示与隐藏
      loading: false,//控制骨架屏的显示与隐藏

      list: [],//列表
      loadList: storage.getList(),//骨架屏的起始数组（10条数据）
      filters: {
        type: '',//类型
        keyword: "", //关键字
        intcls: [], //类别
        tmlaw: '',//法律状态
        appname: "", //申请人
        agentname: '',//代理机构
        appdate: '',//申请年份
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
        sort: "",//排序
        fields: ['商标名称', '商标状态', '商标号', '商标类别', '申请日期', '申请人', '注册日期', '专用权期限', '商品项目', '代理机构'],
      },
      //商标类别，下拉框的所需内容
      intclsSelect: {
        select: storage.getIntcls(),
      },
      last_page: 1,
      pageSize: 0, //总条数
      liston: 0,//控制是那种列表的tab切换(默认是table列表)
    }
  },
  created() {
    this.filters.type = this.$route.params.type
    this.filters.keyword = this.$route.params.keyword
    this.searchKey()
  },
  methods: {

// 关键字搜索
    async searchKey() {
      if (this.filters.keyword) {
        this.exportDis = true
        this.searchVid = false
        this.loading = true
        this.replace.goods = []
        try {
          const res = await tmSearchKeyword(this.filters)
          if (res && res.err_code === 0) {
            this.loading = false
            this.selects = {...res.data.filter}
            this.pageSize = res.data.total
            this.selects.tmlaw = []
            res.data.filter.tmlaw.forEach(item => {
              let obj = {}
              obj.key = item.split('_')[0]
              obj.value = item.split('_')[1]
              this.selects.tmlaw.push(obj)
            })

            this.selects.appdate = []
            let compare = function (a, b) {
              if (a > b) {
                return -1;
              }
            }
            this.selects.appdate = res.data.filter.appdate.sort(compare)
            res.data.list.forEach(item => {
              let content = '';
              if (item.tmgoods.length == 0) {
                item.tmgoods = false
              } else {
                item.tmgoods.forEach((cont, i) => {
                  let s = (cont.status != 0) ? ('<s>' + cont.name + '</s>') : ('<font>' + cont.name + '</font>')
                  content += s + (i == item.tmgoods.length - 1 ? '' : '、')

                })
              }
              this.replace.goods.push(item.tmgoods)
              this.replace.goodsCont.push(content)
            })
            this.list = res.data.list

            if (this.list.length == 0) {
              this.exportDis = true
            } else {
              this.exportDis = false
            }
          }
        } finally {

        }
      } else {
        this.searchVid = true
        this.loading = false
      }
    },
    //分页的每页显示多少条的事件
    changeRowNum(val) {
      this.filters.per_page = val;
      this.searchKey(this.backTop);
    },
    //导出事件
    openDia() {
      this.filters.fields = this.$options.data().filters.fields;
      this.checkAll = false
      this.isIndeterminate = true
    },
    //确定导出
    async download() {
      this.down = false
      this.downloadDia = false
      if (this.filters.fields.length != 0) {
        try {
          const res = await tmSearchExport(this.filters)
          if (res && res.err_code === 0) {
            location.href = res.data.url
            this.down = true
          } else {
            this.$message({
              message: res.err_msg,
              type: 'warning'
            });
          }

        } finally {
          this.down = true
        }
      } else {
        this.$message({
          message: '至少选择一个',
          type: 'warning'
        });
      }

    },
    //重置事件
    reset() {
      this.filters = this.$options.data().filters;
      this.searchKey()
    },
    //排序事件
    sort_change(column) {
      if (column.column.label == '申请日期') {
        if (column.order === "descending") {
          this.filters.sort = "appdate|desc";
        } else {
          this.filters.sort = "appdate|asc";
        }
      } else {
        if (column.order === "descending") {
          this.filters.sort = "regdate|desc";
        } else {
          this.filters.sort = "regdate|asc";
        }
      }

      this.searchKey();
    },
    // 全选改变
    handleCheckAllChange(val) {
      this.filters.fields = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    // 单选改变
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
  }
}
</script>

<style lang="less" scoped>
@import "../../style/domestic.less";

.list {
  form {
    .formInput {
      height: 0px;
    }

    .form_inp {
      width: 500px;

      .inp_tab {
        span {
          padding-bottom: 6px;
        }

        span.on {
          color: #409EFF;
          border-bottom: 2px solid #409EFF;
        }
      }

      ::v-deep .inp {
        .el-input__inner {
          border-radius: 16px;
          background: #F4F4F5;
          border: none;
        }

        .el-input__suffix {
          right: 0;

          .el-button {
            border-radius: 16px;
            position: unset;
          }
        }
      }
    }
  }

  .mainrb {
    .list-list {
      .listb {
        .content {
          .contentC {

            p:nth-last-of-type(1), p:nth-last-of-type(2) {
              margin-bottom: 0;

            }

            p:nth-last-of-type(1) {
              justify-content: unset;

              span {
                width: 85%;
              }
            }

            p:nth-last-of-type(2) {
              span {
                width: 49%;
              }
            }
          }
        }
      }
    }

  }
}

div {
  .mainrb {
    .el-pagination {
      padding: 5px 0 4px;
    }

    .list-list {
      .listb {
        .content {
          .contentC {

            p:nth-last-of-type(1) {
              margin-bottom: 0;
              justify-content: unset;

              span {
                width: 85%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .list-list > div > div {
    height: calc(~"100vh - 583px");
  }

  .list-list > div > div.on {
    height: calc(~"100vh - 363px");
  }

  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 583px") !important;
  }

  .ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 777px") !important;
    }
  }

  .el-table.on {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 363px") !important;
    }
  }

  .el-table.on.ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 441px") !important;
    }
  }

  .contentC {
    width: 60%;
  }

  .formInput.on {
    height: 220px !important;
  }

  .list-list.empty {
    height: calc(~"100vh - 666px");
  }

  .list-list.empty.ios {
    height: calc(~"100vh - 706px");
  }

  .list-list.empty.on {
    height: calc(~"100vh - 330px");
  }

  .list-list.empty.on.ios {
    height: calc(~"100vh - 370px");
  }
}

@media screen and (min-width: 768px) {
  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 500px") !important;
  }

  .el-table.on {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 363px") !important;
    }
  }

  .el-table.ios.on {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 403px") !important;
    }
  }

  .el-table.ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 559px") !important;
    }
  }

  .list-list > div > div {
    height: calc(~"100vh - 500px");
  }

  .list-list > div > div.ios {
    height: calc(~"100vh - 540px");
  }

  .list-list > div > div.on {
    height: calc(~"100vh - 363px");
  }

  .list-list > div > div.on.ios {
    height: calc(~"100vh - 403px");
  }

  .contentC {
    width: 65%;
  }

  .details {
    line-height: 40px;
    width: 11%;
  }

  .formInput.on {
    height: 137px !important;
  }

  .list-list.empty {
    height: calc(~"100vh - 456px");
  }

  .list-list.empty.ios {
    height: calc(~"100vh - 496px");
  }

  .list-list.empty.on {
    height: calc(~"100vh - 330px");
  }

  .list-list.empty.on.ios {
    height: calc(~"100vh - 370px");
  }
}

@media screen and (min-width: 992px) {

  ::v-deep .el-table__body-wrapper {
    height: calc(~"100vh - 457px") !important;
  }

  .el-table.ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"var(--vh,1vh) * 100 - 497px") !important;
    }
  }

  .el-table.on {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"100vh - 363px") !important;
    }
  }

  .el-table.on.ios {
    ::v-deep .el-table__body-wrapper {
      height: calc(~"var(--vh,1vh) * 100 - 403px") !important;
    }
  }

  .list-list > div > div {
    height: calc(~"100vh - 457px");
  }

  .list-list > div > div.ios {
    height: calc(~"100vh - 497px");
  }

  .list-list > div > div.on {
    height: calc(~"100vh - 363px");
  }

  .list-list > div > div.on.ios {
    height: calc(~"100vh - 403px");
  }

  .list-list.empty {
    height: calc(~"100vh - 414px");
  }

  .list-list.empty.ios {
    height: calc(~"100vh - 474px");
  }

  .contentC {
    width: 70%;
  }

  .formInput.on {
    height: 94px !important;
  }
}


@media screen and (min-width: 1200px) {
  .contentC {
    width: 66%;
  }

  .details {
    line-height: unset;
    display: flex;
    justify-content: space-evenly;
    width: 17%;
  }
}

@media screen and (min-width: 1440px) {
  .contentC {
    width: 72%;
  }

  .details {
    width: 13%;
  }
}

@media screen and (min-width: 1600px) {
  .contentC {
    width: 75%;
  }

  .details {
    width: 12%;
  }
}

@media screen and (min-width: 1920px) {
  .contentC {
    width: 80%;
  }

  .details {
    width: 10%;
  }
}
</style>
