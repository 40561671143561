<template>
  <div class="information">
    <div class="informationT">
      <h2>常用申请人<span v-if="applicantsList.length != 0">{{ pageSize }} </span></h2>
      <form ref="filters" v-on:submit.prevent>
        <el-row class="formInput" :class="{on:formOpen}">
          <el-col :xs="20" :sm="20" :md="9" :lg="9" :xl="10">
            <el-row :gutter="10" align="middle" type="flex" justify="space-between">
              <el-col :xs="4" :sm="4" :md="6" :lg="5" :xl="3" class="text-right">
                <strong>申请人类型 </strong>
              </el-col>
              <el-col :xs="20" :sm="20" :md="18" :lg="19" :xl="21">
                <el-radio-group v-model="filters.client_type" @change="applicantsLists">
                  <el-radio   :label="1">企业单位</el-radio>
                  <el-radio  :label="2">个体经营</el-radio>
                </el-radio-group>

              </el-col>
            </el-row>
          </el-col>
          <el-col :xs="20" :sm="20" :md="9" :lg="9" :xl="10">
            <el-row :gutter="10" align="middle" type="flex" justify="space-between">
              <el-col :xs="4" :sm="4" :md="6" :lg="5" :xl="3" class="text-right"><strong>申请人名称</strong></el-col>
              <el-col :xs="20" :sm="20" :md="18" :lg="19" :xl="21">
                <el-input
                    clearable
                    size="small"
                    v-model="filters.name"
                    placeholder="请输入申请人名称"
                ></el-input>
              </el-col>
            </el-row>

          </el-col>
        </el-row >
        <el-row class="formOpen">
          <el-col :sm="24" :md="0" :xs="24" :class="{on:formOpen}" >
            <i :class="formOpen ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" @click="formOpen = !formOpen"></i>
          </el-col>
        </el-row>
        <el-button size="small" type="primary" @click="applicantsLists(filters.page = 1)" class="search" icon="el-icon-search">查询
      </el-button>
      <el-button size="small" type="reset" @click="reset" :class="{on:formOpen}" class="reset" icon="el-icon-refresh-left">重置</el-button>
      </form>
      <div class="informationTb mainrb">
        <el-skeleton :count="1" :loading="loading" animated>
          <template slot="template">
            <el-table
                :class="{on:!formOpen}"
                :data="lists"
            >
              <el-table-column label="序号" width="">
                <template>
                  <el-skeleton-item variant="p" style="width: 100%"/>
                </template>
              </el-table-column>
              <el-table-column label="类型" width="">
                <template>
                  <el-skeleton-item variant="p" style="width: 100%"/>
                </template>
              </el-table-column>
              <el-table-column label="国家/地区" width="">
                <template>
                  <el-skeleton-item variant="p" style="width: 100%"/>
                </template>
              </el-table-column>
              <el-table-column label="申请人名称" width="">
                <template>
                  <el-skeleton-item variant="p" style="width: 100%"/>
                </template>
              </el-table-column>
              <el-table-column label="联系人">
                <template>
                  <el-skeleton-item variant="p" style="width: 100%"/>
                </template>
              </el-table-column>
              <el-table-column label="电话">
                <template>
                  <el-skeleton-item variant="p" style="width: 100%"/>
                </template>
              </el-table-column>
              <el-table-column label="邮箱">
                <template>
                  <el-skeleton-item variant="p" style="width: 100%"/>
                </template>
              </el-table-column>
              <el-table-column label="短信邮件通知">
                <template>
                  <el-skeleton-item variant="p" style="width: 100%"/>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template>
                  <el-skeleton-item variant="p" style="width: 100%" />
                </template>
              </el-table-column>
            </el-table>
          </template>
          <el-table
              ref="singleTable"
              height="100%"
              :class="{on:formOpen,ios:$store.state.heights}"
              :data="applicantsList"
              style="width: 100%"
          >
            <template slot="empty">
              <el-empty :image-size="80"></el-empty>
            </template>
            <el-table-column type="index" label="序号" width="80">

            </el-table-column>
            <el-table-column label="类型">
              <template slot-scope="scope">
                {{scope.row.client_type == 1 ? '企业单位' : '个体经营'}}

              </template>
            </el-table-column>
            <el-table-column label="国家/地区" prop="country" min-width="90">
              
            </el-table-column>
            <el-table-column label="申请人名称" min-width="120" prop="name" :show-overflow-tooltip="true">

            </el-table-column>
            <el-table-column label="联系人" prop="contact_name">

            </el-table-column>
            <el-table-column label="电话" prop="contact_phone" width="120">

            </el-table-column>
            <el-table-column label="邮箱" prop="contact_email" :show-overflow-tooltip="true">
              
            </el-table-column>
            <el-table-column
                :render-header="renderSms"
                min-width="160"
            >
              <template slot-scope="scope">
                <el-skeleton :loading="loading" animated :count="1">
                  <template slot="template">
                    <el-skeleton-item variant="p" style="width: 100%"/>
                  </template>
                  <el-switch v-model="scope.row.notify_sms"
                             :active-value="1"
                             :inactive-value="0" @change="switchChange(scope.row)"> </el-switch>
                </el-skeleton>
              </template>
            </el-table-column>

            <el-table-column class="mod-del" fixed="right" label="操作" width="120">
              <template slot-scope="scope">
                <el-skeleton :loading="loading" animated :count="1">
                  <template slot="template">
                    <el-skeleton-item variant="p" style="width: 100%"/>
                  </template>
                  <router-link
                      :to="{
                  name: 'newlyBuild',
                  params: { id: scope.row.id },
                }"
                      target="_blank"
                  >
                    <el-button type="text" size="small" icon="el-icon-edit-outline">详情</el-button>
                  </router-link>
                </el-skeleton>
              </template>
            </el-table-column>
          </el-table>
        </el-skeleton>

        <!-- 分页 -->
        <el-pagination
          @size-change="changeRowNum"
          @current-change="applicantsLists"
          :current-page.sync="filters.page"
          :page-sizes="[30, 50, 100]"
          :page-size="filters.per_page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageSize"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import storage from '@/utils/storage'
import { applicants,applicantsSwitch} from '@/api/personal.js'
export default {
  name: 'Applicants',
  data() {
    return {
      lists:storage.getList(),
      formOpen:false,
      //申请人信息列表数组
      smsAllSwitch: false,
      wechatAllSwitch: false,
      applicantsList: [],
      //表单搜索字段
      filters: {
        client_type: 0, //申请人类型
        name: "", //申请人名称
        page: 1, //默认显示第一页
        per_page: 30, //显示数量
      },
      pageSize: 0,
      loading: true,
      listId:[],
    };
  },
  created() {
    this.applicantsLists();
  },
  methods: {
    renderSms(h, { column, $index }) {
      return h("div", {}, [
        h("span", {}, "短信邮件通知"),
        h("el-switch", {
          style: "margin-left: 10px;",
          on: {
            change: this.changeSms,
          },
          props: {
            value: this.smsAllSwitch,
          },
        }),
      ]);
    },
   async changeSms(val) {
     this.smsAllSwitch = val
     if (this.smsAllSwitch) {
       this.applicantsList.forEach((item) => {
         item.notify_sms = 1;
       });
     } else {
       this.applicantsList.forEach((item) => {
         item.notify_sms = 0;
       });
     }
     try {
       const res = await applicantsSwitch({ids:this.listId,type:1,value:(val ? 1 : 0)})
       if(res && res.err_code === 0){
         this.$message({
           message: '修改成功',
           type: 'success'
         });
       }else{
         this.$message({
           message: res.err_msg,
           type: 'warning'
         });
       }

     }finally {

     }


    },
  async  switchChange(val){
try {
  const res = await applicantsSwitch({ids:[val.id],type:1,value:(parseInt(val.notify_sms))})
  if(res && res.err_code === 0){
    this.$message({
      message: '修改成功',
      type: 'success'
    });
  }else{
    this.$message({
      message: res.err_msg,
      type: 'warning'
    });
  }

}finally {

}

    },

    changeRowNum(val) {
      this.filters.per_page = val;
      this.applicantsLists();
    },

    async applicantsLists(index) {
      this.loading = true;
      this.applicantsList = storage.getList()
      try{
        const res = await applicants(this.filters)
        if(res && res.err_code === 0){
          this.applicantsList = res.data.list;
          this.applicantsList.forEach((item) =>{
            this.listId.push(item.id)
          })
          this.pageSize = res.data.total;
          this.loading = false;

        }else {
          this.applicantsList = []
        }
      }finally{
        this.loading = false;
      }

    },

    deleteTob(index) {
      this.applicantsList.splice(index, 1);
    },
    // 申请人信息重置事件
    reset() {
      this.filters = this.$options.data().filters;
      this.applicantsLists();
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/applicants.less";
</style>



