<template>
    <div class="">
        电子发票
    </div>
</template>
<script>
export default {
    
}
</script>

<style lang="less" scoped>
@import "../../style/InvoiceElect.less";
</style>