<template>
  <div class="">
    <!--    <div class="personal-top">-->
    <!--      <div class="container" >-->
    <!--        <img-->
    <!--          src="@/assets/img/personal/logo.png"-->
    <!--          alt=""-->
    <!--          class="personal-logo"-->
    <!--        />-->
    <!--        <div class="containerC" >-->
    <!--          <div class="" v-click-outside="handleClose">-->
    <!--            <p class="nickname" v-if="onEdit" >-->
    <!--              <el-input-->
    <!--                  v-model="getuser.nickname"-->
    <!--                  placeholder="请输入昵称"-->
    <!--                  size="small"-->
    <!--                  @change="saveNickname"-->
    <!--                  v-focus-->
    <!--              >-->
    <!--              </el-input>-->
    <!--              <i class="el-icon-check" @click="saveNickname"></i>-->
    <!--            </p>-->
    <!--            <p class="nickname" v-else >-->
    <!--              <span>{{ getuser.nickname }}</span>-->
    <!--              <i class="el-icon-edit" @click="edit"></i>-->
    <!--            </p>-->
    <!--          </div>-->

    <!--          <p class="member">-->
    <!--            <img src="@/assets/img/personal/icon-member.png" alt=""/> VIP会员-->
    <!--          </p>-->
    <!--          <div class="dot">-->
    <!--            <el-carousel-->
    <!--              height="21px"-->
    <!--              direction="vertical"-->
    <!--              :autoplay="true"-->
    <!--              :interval="2000"-->
    <!--              loop-->
    <!--            >-->
    <!--              <el-carousel-item>-->
    <!--                <p>-->
    <!--                  <img src="@/assets/img/personal/icon-dot.png" alt=""/>您有<span> 0 </span>件商标满可续展-->
    <!--                </p>-->
    <!--              </el-carousel-item>-->
    <!--              <el-carousel-item>-->
    <!--                <p>-->
    <!--                  <img src="@/assets/img/personal/icon-dot.png" alt=""/>您有<span> 0 </span>件商标注册申请已受理-->
    <!--                </p>-->
    <!--              </el-carousel-item>-->
    <!--              <el-carousel-item>-->
    <!--                <p>-->
    <!--                  <img src="@/assets/img/personal/icon-dot.png" alt=""/>您有<span> 0 </span>件商标注册申请已公告-->
    <!--                </p>-->
    <!--              </el-carousel-item>-->
    <!--              <el-carousel-item>-->
    <!--                <p>-->
    <!--                  <img src="@/assets/img/personal/icon-dot.png" alt=""/>您有<span> 0 </span>件商标注册申请已发证-->
    <!--                </p>-->
    <!--              </el-carousel-item>-->
    <!--            </el-carousel>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="containerR">-->
    <!--          <a href="/personal/domestic/">-->
    <!--            <img src="@/assets/img/personal/icon-containerR1.png" alt=""/>-->
    <!--            <span>商标</span>-->
    <!--          </a>-->
    <!--          <a href="/personal/domestic/patent/">-->
    <!--            <img src="@/assets/img/personal/icon-containerR2.png" alt=""/>-->
    <!--            <span>专利</span>-->
    <!--          </a>-->
    <!--          <a href="/personal/domestic/works/">-->
    <!--            <img src="@/assets/img/personal/icon-containerR3.png" alt=""/>-->
    <!--            <span>版权</span>-->
    <!--          </a>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="container main clearfix">

      <div :class="{on:getcollapse}" class="maint">
        <el-row align="middle" class="crumb" type="flex">
          <el-col :lg="9" :md="9" :sm="8" :xl="8" :xs="8">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item :to="routerPath"><i class="el-icon-back"></i></el-breadcrumb-item>
              <el-breadcrumb-item v-for="item in indexBreadcrumbs" v-if="item.meta" :key="item.id" :to="item.path">
                {{ item.meta.breadcrumb }}
              </el-breadcrumb-item>
            </el-breadcrumb>
          </el-col>
          <el-col :lg="6" :md="6" :sm="9" :xl="7" :xs="8">

            <el-input v-model="filters.value" :class="{on:tmsVal}"
                      :placeholder="(selectVal == 1) ? '请输入商标名称或商标号' : '请输入作品名称或登记号'"
                      clearable size="small"
                      @keyup.enter.native="search">
              <i
                  slot="suffix"
                  class="el-icon-search el-input__icon fr"
                  @click="search">
              </i>
              <el-select slot="prepend" v-model="selectVal" placeholder="请选择">
                <el-option label="商标" value="1"></el-option>
                <el-option label="著作权" value="2"></el-option>
              </el-select>
            </el-input>


          </el-col>
          <el-col :lg="9" :md="9" :sm="7" :xl="9" :xs="8" class="users">
            <i class="el-icon-refresh" style="font-size: 18px;cursor: pointer;" @click="update()"></i>
            <el-dropdown trigger="click" >
              <span class="el-dropdown-link">
                <div>
                  <img v-if="!getuser.avatar" alt="" src="@/assets/img/personal/portrait.png">
                  <img v-else :src="getuser.avatar">
                </div>
                {{ getuser.nickname || getuser.mobile }}
                <i class="el-icon-arrow-down el-icon--right"></i>
                <el-dropdown-menu slot="dropdown" class="sculpture">
                 <div class="dropdown-img">
                  <img v-if="!getuser.avatar" alt="" src="@/assets/img/personal/portrait.png">
                  <img v-else :src="getuser.avatar">
                 </div>
                  <h3>{{ getuser.nickname || getuser.mobile }}</h3>
                  <h4>{{ getuser.company }}</h4>
                  <div class="btn">
                    <router-link to="/personal/myMessage/">
                      <el-button icon="el-icon-s-platform" plain size="mini" type="primary">
                        账号设置
                      </el-button>
                    </router-link>
                    <el-button icon="el-icon-switch-button" plain size="mini" type="primary" @click.prevent="doLogout">
                      退出登录
                    </el-button>
                  </div>
              </el-dropdown-menu>
              </span>
            </el-dropdown>
          </el-col>
        </el-row>
      </div>
      <div class="main-cont clearfix">

        <div :class="{on:getcollapse}" :style="{height: bodyHeight + 'px'}" class="mainl">
          <div class="lg">
            <img v-if="!getcollapse" alt="" src="@/assets/img/personal/logo-lg.png">
            <img v-else alt="" src="@/assets/img/personal/logo-sm.png">
          </div>
          <el-menu ref="menu" :class="{on:getcollapse}" :collapse="getcollapse" :default-active="routerPath"
                   :default-openeds="pathIndex"
                   unique-opened
                   router
                   @select="select">
            <template v-for="(item,index) in mainlList">
              <el-submenu v-if="item.secondNav" :index="item.name">
                <template slot="title">
                  <i :class="item.icon"></i>
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item v-for="item1 in item.mainlLists" :key="item1.id" :index="item1.path" >
                    <div class="">
                      <span>{{ item1.name }}</span>
                      <img v-if="item1.new" style="width: 20px; margin: -10px 0 0 5px;" src="@/assets/img/personal/icon-new.png" alt="">
                    </div>

                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item v-else :index="(item.path)">
                <i :class="item.icon" ></i>
                <span >{{ item.name }}</span>
                <img v-if="item.new && !getcollapse" class="icon-new" src="@/assets/img/personal/icon-new.png" alt="">
              </el-menu-item>
            </template>
          </el-menu>
          <div :class="{on:getcollapse}" class="mainbtn">
            <el-button :icon="getcollapse ? 'el-icon-right' : ' el-icon-back'" circle size="small"
                       @click="doMutations()"></el-button>
          </div>
        </div>

        <div :class="{on:getcollapse,ios:getheights,scroll:getscroll}" class="mainr">
          <div class="">
            <router-view ></router-view>

          </div>
          <div v-if="account" class="upgrade" @touchmove.prevent>
            <div class="upgrade-con">
              <div class="con-top clearfix">
                <div class="top-image fl">
                  <img v-if="!getuser.avatar" alt="" src="@/assets/img/personal/portrait.png">
                  <img v-else :src="getuser.avatar">
                </div>
                <div class="top-type fl">
                  <h4>{{ getuser.nickname || getuser.mobile }}</h4>
                  <p :class="{'col-000':getuser.type == 0,blue:getuser.type == 1,golden:getuser.type == 2,purple:getuser.type == 3}">
                    {{ getuser.type == 0 ? '免费版' : (getuser.type == 1 ? '基础版' : (getuser.type == 2 ? '专业版' : '国际版')) }}</p>
                </div>
                <div class="top-phone fr">
                  <p>升级咨询：400 1010 989</p>
                </div>
              </div>
              <div class="con-cen">
                <div class="cen-t">
                  <h3><span>{{ getuser.type == 1 ? '专业' : '国际' }}版</span>专业更高效</h3>
                  <p>适用于任何规模的企业、团队或组织</p>
                </div>
                <ul>
                  <li>
                    <div class="image">
                      <img alt="" src="@/assets/img/personal/upgrade1.png">
                    </div>
                    <p>知产数据全面覆盖</p>
                  </li>
                  <li>
                    <div class="image">
                      <img alt="" src="@/assets/img/personal/upgrade2.png">
                    </div>
                    <p>官方档案随时查看</p>
                  </li>
                  <li>
                    <div class="image">
                      <img alt="" src="@/assets/img/personal/upgrade3.png">
                    </div>
                    <p>线上协作高效便捷</p>
                  </li>
                </ul>

              </div>
              <div class="con-foot">
                升级支付后可开发票
              </div>
            </div>
          </div>

        </div>
        <p :class="{on:getcollapse}" class="footp">Copyright &copy; {{ year }} 南京知者云科技有限公司 All Rights Reserved</p>
      </div>
    </div>


  </div>
</template>

<script>
import moment from 'moment'
import storage from '@/utils/storage'

export default {
  name: 'Personal',
  data() {
    return {

      selectVal: '1',//头部搜索框的选择类别
      tmsVal: false,//验证头部搜索框搜索时是否为空
      filters: {//头部搜索框的字段
        value: '',
      },
      account: true,//控制版本号弹框的显示与隐藏
      year: moment().format('Y'),
      bodyHeight: '',//记录浏览器可视区域的高度
      indexBreadcrumbs: null,//面包屑的数据
      routerPath: '',//当前激活菜单的 index
      pathIndex: [],//当前打开的 sub-menu 的 index 的数组
      mainlList: [
        {
          name: '首页',
          title: '首页',
          path: '/personal/',
          secondNav: false,
          icon: 'el-icon-s-home',
        },
        {
          name: '商标查询',
          title: '商标查询',
          path: '/personal/query',
          secondNav: false,
          icon: 'el-icon-search',
          new:true,
        },
        {
          name: '待办事项',
          title: '待办事项',
          path: '/personal/dealtMatter/',
          secondNav: false,
          icon: 'el-icon-s-release',
        },
        

        {
          name: '委托业务',
          title: '委托业务',
          path: '/personal/apply/',
          secondNav: true,
          icon: 'el-icon-share',
          mainlLists: [
            {
              name: '国内商标申请',
              title: '国内商标申请',
              path: '/personal/apply',
            },
            {
              name: '国际商标申请',
              title: '国际商标申请',
              path: '/personal/apply/international',
            },
            {
              name: '著作权登记',
              title: '著作权登记',
              path: '/personal/apply/works',
            },
            {
              name: '行政诉讼',
              title: '行政诉讼',
              path: '/personal/apply/litigation',
            },
            {
              name: '账单信息',
              title: '账单信息',
              path: '/personal/apply/bills',
            },
          ],
        },

        {
          name: '国内商标',
          title: '国内商标',
          path: '/personal/trademarkAdmin/',
          icon: 'el-icon-collection-tag',
          secondNav: true,
          mainlLists: [
            {
              name: '自有商标',
              title: '自有商标',
              path: '/personal/trademarkAdmin/',
            },
            {
              name: '评审商标',
              title: '评审商标',
              path: '/personal/review/',
              new:true,
            },
            {
              name: '异议商标',
              title: '异议商标',
              path: '/personal/objection/',
              new:true,
            },
            {
              name: '商标图谱',
              title: '商标图谱',
              path: '/personal/atlas',
              new:true,
            },
            {
              name: '商标官文',
              title: '商标官文',
              path: '/personal/trademarkAdmin/trademarkArchives',
            },
            {
              name: '商标监测',
              title: '商标监测',
              path: '/personal/risk/',
              new:true,
            },
            // {
            //   name: '使用证据',
            //   title: '使用证据',
            //   path: '/personal/evidence',
            //   new:true,
            // },
            // {
            //   name: '干预商标',
            //   title: '干预商标',
            //   path: '/personal/trademarkAdmin/maintain',
            // },
            // {
            //   name: '干预商标档案',
            //   title: '干预商标档案',
            //   path: '/personal/trademarkAdmin/maintainArchives',
            // },
          ]
        },

        {
          name: '国际商标',
          title: '国际商标',
          path: '/personal/interMark/',
          secondNav: true,
          icon: 'icon-hulianwang',
          new:true,
          mainlLists:[
            {
              name: '自有商标',
              title: '自有商标',
              path: '/personal/interMark/',
            },
            {
              name: '商标图谱',
              title: '商标图谱',
              path: '/interMark/atlas',
              new:true,
            },
          ]
        },
        {
          name: '专利',
          title: '专利',
          path: '/personal/parentAdmin/',
          icon: 'el-icon-s-opportunity',
          secondNav: false,

        },
        {
          name: '著作权',
          title: '著作权',
          // path: '/personal/domestic/patent',
          icon: 'el-icon-picture-outline',
          secondNav: true,
          mainlLists: [
            {
              name: '作品著作权',
              title: '作品著作权',
              path: '/personal/copyrightAdmin/',
            },

            {
              name: '软件著作权',
              title: '软件著作权',
              path: '/personal/copyrightAdmin/software',
            },
            {
              name: '著作权官文',
              title: '著作权官文',
              path: '/personal/copyrightAdmin/worksArchives',
            },

          ]
        },
        {
          name: '网站备案',
          title: '网站备案',
          path: '/personal/domestic/websiteFiling',
          icon: 'el-icon-s-check',
          secondNav: false,

        },
        {
          name: '流程审批',
          title: '流程审批',
          path: '/personal/approval/',
          icon: 'el-icon-edit-outline',
          secondNav: true,
          mainlLists:[
            {
              name: '流程审批',
              title: '流程审批',
              path: '/personal/approval/',
            },
            {
              name: '表单设置',
              title: '表单设置',
              path: '/personal/approval/form',
            },
            {
              name: '审批人设置',
              title: '审批人设置',
              path: '/personal/approval/contacts',
            },
          ]
        },
        {
          name: '常用申请人',
          title: '常用申请人',
          path: '/personal/applicants/',
          secondNav: false,
          icon: 'el-icon-s-custom',

        },
        {
          name: '账号设置',
          title: '账号设置',
          path: '/personal/myMessage/',
          icon: 'el-icon-monitor',
          secondNav: false,
        },
        {
          name: '消息中心',
          title: '消息中心',
          path: '/personal/myMessageCenter/',
          icon: 'el-icon-chat-square',
          secondNav: false,
        },
        {
          name: '帮助中心',
          title: '帮助中心',
          path: '/personal/download/',
          icon: 'el-icon-question',
          secondNav: true,
          mainlLists: [
            {
              name: '更新日志',
              title: '更新日志',
              path: '/personal/journal/',
            },
            {
              name: '下载客户端',
              title: '下载客户端',
              path: '/personal/client/',
            },

            // {
            //   name: '大数据',
            //   title: '大数据',
            //   path: '/personal/data/',
            // },
          ]
        },
      ],
      user: storage.getUser(),//用户数据
      onEdit: false,
      isRouterAlive: true,//判断菜单是否二次点击
    }
  },
  created() {
    this.$store.commit('setuser',{
      user:storage.getUser(),
    });
    
    if([10071,14258].indexOf(this.user.id) != -1 ||  [10071,14258].indexOf(this.user.parent_id) != -1){
      if(this.user.parent_id === 0 && this.user.type !== 0){
      }else{
        this.mainlList.filter(item => item.title == '流程审批')[0].mainlLists = this.mainlList.filter(item => item.title == '流程审批')[0].mainlLists.filter(item => item.title == '流程审批') 
      }
    }else{
      this.mainlList = this.mainlList.filter(item => item.title != '流程审批')
    }
    this.bodyHeight = document.body.offsetHeight

    this.routerPath = this.$route.path

    let path = this.$route.path.split('/')[3]
    let path2 = this.$route.path.split('/')[2]
    this.vague(this.$route.name)
    if (path == 'applicationDetails') {
      this.pathIndex.push('订单管理')
      this.routerPath = '/personal/personal/myApplication'
      this.indexBreadcrumbs = ['订单管理', '我的申请']
    } else if (path == 'domesticDetails') {
      this.pathIndex.push('商标管理')
      this.routerPath = '/personal/trademarkAdmin/'
    } else if (path == 'patentDetails') {
      this.pathIndex.push('专利管理')
      this.routerPath = '/personal/parentAdmin/'
    } else if (path2 == 'toPaid') {
      this.pathIndex.push('订单管理')
      this.routerPath = '/personal/personal/'
    } else if (path == 'worksDetails') {
      this.pathIndex.push('著作权管理')
      this.routerPath = '/personal/copyrightAdmin/'
    } else if (path == 'applyDetail') {
      this.pathIndex.push('申请管理')
      this.routerPath = '/personal/apply'
    } else if (path == 'worksDetail') {
      this.pathIndex.push('申请管理')
      this.routerPath = '/personal/apply/works'
    } else if (path == 'litigationDetail') {
      this.pathIndex.push('申请管理')
      this.routerPath = '/personal/apply/litigation'
    } else if (path2 == 'newlyBuild') {
      this.pathIndex.push('常用申请人')
      this.routerPath = '/personal/applicants/'
    } else if (path == 'upload') {
      this.pathIndex.push('商标管理')
      this.routerPath = '/personal/trademarkAdmin/trademarkArchives'
    } else if (path2 == 'journalDetail') {
      this.pathIndex.push('帮助中心')
      this.routerPath = '/personal/journal/'
    } else if (path == 'billDetail') {
      this.pathIndex.push('申请管理')
      this.routerPath = '/personal/apply/bills'
    }else if (path == 'tradDetails') {
      this.pathIndex.push('国内商标')
      this.routerPath = '/personal/trademarkAdmin/'
    }else if (path == 'reviewDetails') {
      this.pathIndex.push('国内商标')
      this.routerPath = '/personal/review/'
    }else if (path == 'objectionDetails') {
      this.pathIndex.push('国内商标')
      this.routerPath = '/personal/objection/'
    }else if (path2 == 'interMark') {
      console.log(path2)
      this.pathIndex.push('国际商标')
      this.routerPath = '/personal/interMark/'
    }else if ( ['queryDetail','queryKeyword','queryIntcls','queryClass','queryGoods'].indexOf(path) != -1) {

      this.pathIndex.push('商标查询')
      this.routerPath = '/personal/query'
    }else if (path == 'internationalDetail') {
      this.pathIndex.push('申请管理')
      this.routerPath = '/personal/apply/international'
    }
  },
  watch: {
    $route(to, from) {
      this.getBreadcrumb()
      this.vague(to.name)
    },
  },
  mounted() {
    this.getBreadcrumb()
    window.onresize = () => {
      this.bodyHeight = document.body.offsetHeight
      if( document.body.offsetWidth <= 1280){
        this.$store.commit('setterCollapse', {
          isCollapse: true,
        });
      }else{
        this.$store.commit('setterCollapse', {
          isCollapse: false,
        });
      }
    };
    if (this.getheights) {
      this.safariHacks()
    }

  },
  computed: {

    getheights: function () {
      return this.$store.state.heights;
    },
    getcollapse: function () {
      return this.$store.state.isCollapse;
    },
    getuser: function () {
      return this.$store.state.user;
    },
    getscroll: function () {
      return this.$store.state.scroll;
    },
  },
  methods: {
    doMutations() {
      this.$store.commit('setterCollapse', {
        isCollapse: !this.getcollapse,
      });
    },
    //获取下拉
    select() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    //头部搜索事件
    search() {
      if (this.filters.value) {
        this.pathIndex = []
        this.tmsVal = false
        this.$store.commit('settms', {
          tms: this.filters.value,
        });
        if (this.selectVal == '1') {
          this.pathIndex.push('国内商标')
          this.routerPath = '/personal/trademarkAdmin/'
          this.$router.push({name: 'domestic', params: {tms: this.filters.value}})

        } else {
          this.routerPath = '/personal/copyrightAdmin/'
          this.$router.push({name: 'works', params: {tms: this.filters.value}})
        }
      } else {
        this.tmsVal = true
      }
    },
    //刷新页面
    update() {
      this.$router.go(0)
    },

    safariHacks() {
      let windowsVH = window.innerHeight / 100;
      document.querySelector('.el-table__body-wrapper').style.setProperty('--vh', windowsVH + 'px');
      window.addEventListener('resize', function () {
        document.querySelector('.el-table__body-wrapper').style.setProperty('--vh', windowsVH + 'px');
      });
    },
    //面包屑
    getBreadcrumb() {
      let matched = this.$route.matched.filter((item) => item.meta.breadcrumb)
      this.indexBreadcrumbs = matched;
    },
    //版本号弹框
    vague(item) {
      let free = storage.getFree()
      let base = storage.getBase()
      let pro = storage.getPro()
      if (this.getuser.type === 0) {
        if (free.indexOf(item) != -1) {
          this.account = false
        } else {
          this.account = true
        }
      } else if (this.getuser.type === 1) {
        if (base.concat(free).indexOf(item) != -1) {
          this.account = false
        } else {
          this.account = true
        }
      }  else if (this.getuser.type === 2) {
        if (pro.concat(base).concat(free).indexOf(item) != -1) {
          this.account = false
        } else {
          this.account = true
        }
      } else {
        this.account = false
      }
    },


    async saveNickname(ev) {
      await this.$http.post('/api/user/save', {nickname: this.getuser.nickname}).then(res => {
        if (res && res.err_code === 0) {
          storage.setUser(this.user)
        }
      })
      this.onEdit = false;
    },
    //退出登录
    doLogout() {
      this.$confirm('确定要退出登录吗？', '提示', {
        type: 'warning'
      }).then(() => {
        this.$http.post('/api/logout').then((res) => {
          if (res && res.err_code === 0) {
            storage.clear()
            this.$router.push('/login')
          }
        })
      })
    },
    edit() {
      this.onEdit = true
    },

    handleClose(e) {
      this.onEdit = false;
    },
  },
}
</script>

<style lang="less">
@import '../../style/iconfont.css';
@import '../../style/personal.less';

.crumb .el-col-lg-6 .on > .el-input__inner {
  border-color: red;
}

.crumb .el-col-lg-6 {
  .el-icon-search {
    cursor: pointer;
  }

  .el-input-group__prepend {
    background: unset;
  }
}

.crumb {
  .el-breadcrumb {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .el-breadcrumb__item {
      float: unset;
    }
  }

}

@media screen and (max-width: 768px) {
  .el-input-group__prepend {
    width: 40%;
  }
}

@media screen and (min-width: 768px) {
  .el-input-group__prepend {
    width: 42%;
  }
}

@media screen and (min-width: 992px) {
  .el-input-group__prepend {
    width: 45%;
  }
}

@media screen and (min-width: 1200px) {
  .el-input-group__prepend {
    width: 35%;
  }
}

@media screen and (min-width: 1900px) {
  .el-input-group__prepend {
    width: 20%;
  }
}
</style>

