
<template>
  <div class="">
    <div class="patentServices-ban">
      <div class="container">
        <h1>专利申请<br>保护创新</h1>
        <router-link to="/">在线咨询</router-link>
      </div>
    </div>

    <div class="patentServices-benefit container">
      <div class="patentServices-benefitT">
        <h2>申请专利的好处</h2>
        <div class="line"></div>
      </div>

      <ul>
        <li>
          <h3>独占市场</h3>
          <p>独自拥有产品权利用专利技术 跑马圈地</p>
        </li>
        <li>
          <h3>荣誉提升</h3>
          <p>申请高新技术企业和国家奖项 必备条件</p>
        </li>
        <li>
          <h3>无形资产</h3>
          <p>可以各种形式给他人使用获取费用</p>
        </li>
        <li>
          <h3>税收减免</h3>
          <p>可享受国家新政策政府 专项资金</p>
        </li>
        <li>
          <h3>技术保护</h3>
          <p>防止竞争对手抄袭、员工跳槽 造成技术成果流失</p>
        </li>
        <li>
          <h3>企业宣传</h3>
          <p>高科技含量提升企业形象以及 品牌附加值</p>
        </li>
      </ul>
    </div>
    <div class="patentServices-process">
      <div class="container">
        <h2>专利申请流程</h2>
        <ul>
          <li>
            <h3>
              <img src="@/assets/img/patentServices/process1.png" alt="" />
            </h3>
            <p>提供交底材料</p>
          </li>
          <li>
            <img src="@/assets/img/patentServices/icon-process2.png" alt="" />
            <img src="@/assets/img/patentServices/icon-process3.png" alt="" />
          </li>
          <li>
            <h3>
              <img src="@/assets/img/patentServices/process2.png" alt="" />
            </h3>
            <p>专利代理人撰写</p>
          </li>
          <li>
            <img src="@/assets/img/patentServices/icon-process2.png" alt="" />
            <img src="@/assets/img/patentServices/icon-process3.png" alt="" />
          </li>
          <li>
            <h3>
              <img src="@/assets/img/patentServices/process3.png" alt="" />
            </h3>
            <p>提交申请</p>
          </li>
          <li>
            <img src="@/assets/img/patentServices/icon-process2.png" alt="" />
            <img src="@/assets/img/patentServices/icon-process3.png" alt="" />
          </li>
          <li>
            <h3>
              <img src="@/assets/img/patentServices/process4.png" alt="" />
            </h3>
            <p>官方审查</p>
          </li>
          <li>
            <img src="@/assets/img/patentServices/icon-process2.png" alt="" />
            <img src="@/assets/img/patentServices/icon-process3.png" alt="" />
          </li>
          <li>
            <h3>
              <img src="@/assets/img/patentServices/process5.png" alt="" />
            </h3>
            <p>授权发证</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="patentServices-science">
      <div class="container">
        <h2>办理所需材料</h2>
        <div class="science-cont">
          <div class="science-contL">
            <!-- <img v-for="(item, index) in dataList" :key="index" :src="item.url" /> -->
            <img  :src="dataList[currentIndex]" />
          </div>
          <div class="science-contR">
            <p>1.技术交底书</p>
            <p>2.代理委托书</p>
            <p>3.提供费用减免证明</p>
            <p>4.申请人主体资格证明文件</p>
          </div>
        </div>
      </div>
    </div>

    <div class="patentServices-items container">
      <h2>服务项目</h2>
      <ul>
        <li>
          <h4>发明专利申请</h4>
          <p>保护您的发明创造，资深专家 团队把关，让您申请专利不再 担忧</p>
          <router-link to="/">查看详情</router-link>
        </li>
        <li>
          <div class="items-liT">
            <h4>外观专利申请</h4>
            <router-link to="/">查看详情</router-link>
            <img src="@/assets/img/patentServices/items1.png" alt="" />
          </div>
          <div class="items-liB">
            <h4>实用新型专利申请</h4>
            <router-link to="/">查看详情</router-link>
            <img src="@/assets/img/patentServices/items2.png" alt="" />
          </div>
        </li>
        <li>
          <h4>专利加急申请</h4>
          <p>国家官方渠道实际操作，高 效率全程加急服务，节省您 的宝贵时间</p>
          <router-link to="/">查看详情</router-link>
        </li>
        <li>
          <div class="items-liT">
            <h4>国际专利申请</h4>
            <router-link to="/">查看详情</router-link>
            <img src="@/assets/img/patentServices/items3.png" alt="" />
          </div>
          <div class="items-liB">
            <h4>专利深度检索</h4>
            <router-link to="/">查看详情</router-link>
            <img src="@/assets/img/patentServices/items4.png" alt="" />
          </div>
        </li>
      </ul>
    </div>

  <div class="patentServices-group">
      <div class="container">
        <img src="@/assets/img/trademark/groupPhoto.png" alt="" class="" />
      </div>
    </div>


  </div>
</template>

<script>
import science1 from "@/assets/img/patentServices/science1.png";
import science2 from "@/assets/img/patentServices/science2.png";
import science3 from "@/assets/img/patentServices/science3.png";
export default {
  data() {
    return {
      dataList: [science1,science2,science3
        // {
        //   url: science1,
        // },
        // {
        //   url: science2,
        // },
      ],
      currentIndex: 0, //默认显示图片
      timer: null, //定时器
    };
  },
  created() {
    //定时器
   
      this.timer = setInterval(() => {
        this.gotoPage(this.nextIndex);
      }, 1000);
    
  },
  computed: {
    //下一张
    nextIndex() {
      if (this.currentIndex == this.dataList.length - 1) {
        return 0;
      } else {
        return this.currentIndex + 1;
      }
    },
    
  },
  methods: {
    gotoPage(index) {
      this.currentIndex = index;
    },
  },
};




</script>

<style lang="less" scoped>
@import "../../style/patentServices.less";
</style>