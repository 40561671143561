<template>
  <div class="">
    <div class="banner"></div>
    <div class="introduction container">
      <h2>公司简介</h2>
      <div class="line">
        <span></span>
        <span class="on">About Us</span>
        <span></span>
      </div>

      <div class="content clearfix">
        <img alt="" class="fl" src="../../assets/img/introduction.png"/>
        <div class="fr">
          <p>
            江苏省宁海商标事务所成立于1993年，原名江苏省商标事务所，经过近三十年的发展，业务规模
            稳居全省首位。
          </p>
          <p>
            在国家高质量发展的要求下，江苏省宁海商标事务所组建了汇智百成知识产权集团，致力于为客
            户提供顾问级的全知产服务，现已成为全国知识产权服务品牌机构、全国优秀商标代理组织、中国知
            识产权运营联盟常务理事单位、中华商标协会常务理事单位、国家财政部股权投资试点专利运营机构、
            江苏省技术经理人事务所、省级重合同守信用企业、省市著名商标、江苏省服务名牌等，是江苏省地
            方标准《商标代理服务规范》的制定者，参与起草江苏省地方标准《企业知识产权管理规范》、《企
            业商标管理规范》，参与编写《商标战略与品牌提升》、《企业知识产权管理实务》，主编《商标代
            理人职业技能培训指南》等。
          </p>
          <p>
            高素质知识产权服务团队拥有国家知识产权领军人才1名，知识产权百名高层次人才1名，江苏省
            知识产权领军人才3人，具有资深商标代理人、专利代理师、知识产权律师、品牌管理师、高级口译、
            国际商业设计师、管理体系审核员等各类资质人员等近200人，业务范围涵盖各类海内外商标确权、
            维权案件，各类专利代理、维权案件，以及版权代理、高新技术企业申报、技术转移/知识产权运营、
            品牌策划设计等各类知识产权服务。现已服务30000多家的市场主体，含30多家中国500强企业、100
            多家上市公司、200多家驰著名企业，参与并见证了众多品牌从诞生走向辉煌。
          </p>
          <p>
            我们诚意与您携手共进，从心开始、不断突破、成就未来，一起致敬中国品牌时代！
          </p>
        </div>
      </div>
    </div>

    <div class="qualifications">
      <div class="container">
        <h2>荣誉资质</h2>
        <div class="line">
          <span></span>
          <span class="on">Honor</span>
          <span></span>
        </div>
        <div class="carousel-conten">
          <div class="pre" @click='pre'></div>
          <div class="carousel">
            <ul :class="{directionPre:isdirectionPre,directionNext:isdirectionNext}">
              <li>
                <img alt="" src="../../assets/img/qualifications1.png"/>
                <p>国际商标协会会员</p>
              </li>
              <li>
                <img alt="" src="../../assets/img/qualifications2.png"/>
                <p>中华商标协会副会长单位</p>
              </li>
              <li>
                <img alt="" src="../../assets/img/qualifications3.png"/>
                <p>中华商标江苏工作站</p>
              </li>
              <li>
                <img alt="" src="../../assets/img/qualifications4.png"/>
                <p>南京市商标协会副会长单位</p>
              </li>
              <li>
                <img alt="" src="../../assets/img/qualifications1.png"/>
                <p>国际商标协会会员</p>
              </li>
              <li>
                <img alt="" src="../../assets/img/qualifications2.png"/>
                <p>中华商标协会副会长单位</p>
              </li>
              <li>
                <img alt="" src="../../assets/img/qualifications3.png"/>
                <p>中华商标江苏工作站</p>
              </li>
              <li>
                <img alt="" src="../../assets/img/qualifications4.png"/>
                <p>南京市商标协会副会长单位</p>
              </li>
            </ul>
          </div>
          <div class="next" @click='next'></div>
        </div>
      </div>
    </div>

    <div class="cooperation container">
      <h2>公司简介</h2>
      <div class="line">
        <span></span>
        <span class="on">About Us</span>
        <span></span>
      </div>
      <img alt="" src="../../assets/img/banner-cooperation.png"/>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isdirectionPre: true,
      isdirectionNext: false
    };
  },
  created() {
  },
  methods: {
    pre() {
      this.isdirectionPre = true
      this.isdirectionNext = false
    },
    next() {
      this.isdirectionNext = true
      this.isdirectionPre = false
    },
  },
};
</script>
<style lang='css' scoped>
@import "../../style/about.css";
</style>
